/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { handleRequest, API_BASE } from '../../redux/config/env';
import { Card, Form, Button, Modal, Row, Col, Accordion } from 'react-bootstrap';
import { Card as PrimeCard } from "primereact/card";
import { MultiSelect } from 'primereact/multiselect';
import { Select, InputNumber, Table, message, DatePicker, Checkbox } from 'antd';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import moment from 'moment';
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import {
    FacebookShareButton,
    WhatsappShareButton,
    EmailShareButton,
    FacebookIcon,
    WhatsappIcon,
    EmailIcon
} from "react-share";

const { Option } = Select;
const { Column } = Table;
const { RangePicker } = DatePicker;

var _termCondRows = [];

var _noteRows = [];

export class InvoiceLog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modalShow: false,
            modalShowError: false,
            modalPdfViewer: false,
            pdfFile: "",
            createNewButton: "Create New Invoice",
            createNewButtonVariant: "info",
            hidden: true,
            lastButtonName: "Save",
            lastButtonVariant: "primary",
            invoiceList: [],
            invoiceLogId: null,
            invoiceLogList: [],
            customer: "",
            customerList: [],
            orderList: [],
            orderSelect: [],
            orderSelects: [],
            projectSelect: [],
            projectSelects: [],
            locationSelect: [],
            locationSelects: [],
            startDate: null,
            endDate: null,
            invoiceOrderList: [],
            total: 0,
            discount: 0,
            discountTotal: 0,
            vat: 0,
            vatTotal: 0,
            grandTotal: 0,
            termCondRows: [],
            termCondName: "",
            noteRows: [],
            noteName: "",
            VATTaxCheck: true,
            VATTaxValue: 0,
            invoiceNo: "",
            invoiceDate: null,
            modalShowInvoice: false
        };

        this.onProjectChange = this.onProjectChange.bind(this);
        this.onOrderChange = this.onOrderChange.bind(this);
        this.onLocationChange = this.onLocationChange.bind(this);
    }

    componentDidMount = async () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var dd1 = String(today.getDate() - 1).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;
        var today1 = dd1 + '/' + mm + '/' + yyyy;

        this.setState({
            startDate: today1,
            endDate: today,
            invoiceDate: today,
        });

        const responseCustomer = await handleRequest("GET", "/CustomerLog/getAllByCompany");
        const responseOrders = await handleRequest("GET", "/CompanyOrder/getAllByCompany");

        if (responseOrders.data.length > 0) {
            this.setState({
                orderList: responseOrders.data
            });
        }

        if (responseCustomer.data.length > 0) {
            this.setState({
                customerList: responseCustomer.data
            });
        }

        const responseInvoice = await handleRequest("GET", "/Invoice/getAllByCompany");

        if (responseInvoice.data.length === 0) {
            this.setState({
                invoiceList: []
            });
        }

        if (responseInvoice.data.length !== 0) {
            this.setState({
                invoiceList: responseInvoice.data
            });
        }

        console.log("Invoices");
        console.log(responseInvoice.data);
    }

    fillProjectsAndOrderNos = async () => {
        var customerList = this.state.customerList;
        var customerId = this.state.customer;

        var index = customerList.findIndex((p) => p.id === customerId);

        if (index >= 0 && customerList[index].projects !== null && customerList[index].projects !== undefined && customerList[index].projects.length > 0) {

            const columnsDis = customerList[index].projects;
            const optionsDis = columnsDis.map(function (row) {
                return { label: row.projectName.toString(), value: row.id.toString() }
            })
            this.setState({
                projectSelects: optionsDis,
                projectSelect: [],
                locationSelects: [],
                locationSelect: []
            });
        } else {
            this.setState({
                projectSelects: [],
                projectSelect: [],
                locationSelects: [],
                locationSelect: []
            });
        }

        var flagOrderList = this.state.orderList;
        var filteredOrderList = [];

        for (let index = 0; index < flagOrderList.length; index++) {
            if (flagOrderList[index].customerId === customerId) {
                filteredOrderList.push(flagOrderList[index]);
            }
        }

        if (index >= 0 && filteredOrderList.length > 0) {
            const optionsDis = filteredOrderList.map(function (row) {
                return { label: row.orderNo.toString(), value: row.id.toString() }
            });
            this.setState({
                orderSelects: optionsDis,
                orderSelect: []
            });
        } else {
            this.setState({
                orderSelects: [],
                orderSelect: []
            });
        }

        // setTimeout(() => {
        //     this.refreshOrderTable();
        // }, 200);
    }

    onProjectChange(event) {
        var projectIdValue = event.value;
        var flagCustomerList = this.state.customerList;
        var customerId = this.state.customer;

        this.setState({
            projectSelect: projectIdValue
        });

        if (projectIdValue !== '') {
            const index = flagCustomerList.findIndex((p) => p.id === customerId);
            const selectedCustomer = flagCustomerList[index];
            var locations = [];
            for (let index = 0; index < selectedCustomer.projects.length; index++) {
                if (projectIdValue.includes(selectedCustomer.projects[index].id.toString())) {
                    const selectedProject = selectedCustomer.projects[index];
                    if (selectedProject.locations !== null && selectedProject.locations !== undefined && selectedProject.locations.length > 0)
                        for (let index1 = 0; index1 < selectedProject.locations.length; index1++) {
                            locations.push(selectedProject.locations[index1]);
                        }
                }
            }

            if (locations.length > 0) {
                let options = locations.map(function (row) {
                    return { label: row.locationName.toString(), value: row.id.toString() }
                });

                this.setState({
                    locationSelects: options,
                    locationSelect: []
                });
            } else {
                this.setState({
                    locationSelects: [],
                    locationSelect: []
                });
            }
        } else {
            this.setState({
                locationSelects: [],
                locationSelect: []
            });
        }

        // setTimeout(() => {
        //     this.refreshOrderTable();
        // }, 200);
    }

    onOrderChange(event) {
        var orderIdValue = event.value;

        this.setState({
            orderSelect: orderIdValue
        });

        // setTimeout(() => {
        //     this.refreshOrderTable();
        // }, 200);
    }

    onLocationChange(event) {
        var locationIdValue = event.value;

        this.setState({
            locationSelect: locationIdValue
        });

        // setTimeout(() => {
        //     this.refreshOrderTable();
        // }, 200);
    }

    refreshOrderTable = () => {
        const orders = this.state.orderList;
        const selectedCustomerId = this.state.customer;
        const selectedProjectIds = this.state.projectSelect;
        const selectedLocationIds = this.state.locationSelect;

        const [day, month, year] = this.state.startDate.split("/");
        const flagStartDate = new Date(year, month - 1, day);

        const [day1, month1, year1] = this.state.endDate.split("/");
        const flagEndDate = new Date(year1, month1 - 1, day1);

        var flagInvoiceOrderList = [];

        for (let index = 0; index < orders.length; index++) {
            if (selectedCustomerId === orders[index].customerId)
                if (selectedProjectIds.includes(orders[index].customerProjectId.toString()) || selectedLocationIds.includes(orders[index].customerLocationId.toString())) {

                    const [day2, month2, year2] = orders[index].orderDate.split("/");
                    const flagDate = new Date(year2, month2 - 1, day2);

                    if (flagDate >= flagStartDate && flagDate <= flagEndDate)
                        if (orders[index].orders !== null && orders[index].orders.length !== 0)
                            for (let index1 = 0; index1 < orders[index].orders.length; index1++) {
                                flagInvoiceOrderList.push({
                                    key: orders[index].orders[index1].id,
                                    companyId: orders[index].companyId,
                                    customerId: orders[index].customerId,
                                    customerLog: orders[index].customerLog,
                                    customerProject: orders[index].customerProject,
                                    customerProjectId: orders[index].customerProjectId,
                                    customerProjectLocation: orders[index].customerProjectLocation,
                                    customerLocationId: orders[index].customerLocationId,
                                    deliveryTime1: orders[index].deliveryTime1,
                                    deliveryTime2: orders[index].deliveryTime2,
                                    id: orders[index].id,
                                    index: orders[index].index,
                                    orderNo: orders[index].orderNo,
                                    orderDate: orders[index].orderDate,
                                    order: orders[index].orders[index1],
                                    projectId: orders[index].projectId,
                                    timelines: orders[index].timelines
                                });
                            }
                }
        }

        this.setState({
            invoiceOrderList: flagInvoiceOrderList
        });
    }

    showOrHideModal = () => {
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);

        this.setState({
            modalShow: !this.state.modalShow
        });
    }

    deleteModal = (row) => {
        this.setState({
            invoiceLogId: row.id,
            modalShow: true
        });
    }

    delete = async () => {
        const deletedInvoiceLog = {
            id: this.state.invoiceLogId
        }

        await handleRequest("POST", "/Invoice/delete", deletedInvoiceLog);

        this.restartTable();
        message.success(`The operation is successful.`);
        this.showOrHideModal();
    }

    edit = async (rowData) => {
        this.setState({

            lastButtonName: "Update",
            lastButtonVariant: "warning"
        });
    }

    disabledDate = (current) => {
        return current > moment().endOf('day');
    }

    onChangeDate = (date, dateString) => {

        //console.log(date[0]._d);
        //console.log(date[1]._d);

        if (date !== null) {
            this.setState({
                startDate: dateString[0],
                endDate: dateString[1]
            });
        } else {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var dd1 = String(today.getDate() - 1).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy;
            var today1 = dd1 + '/' + mm + '/' + yyyy;

            this.setState({
                startDate: today1,
                endDate: today
            });
        }
    }

    applyDiscount = () => {
        var flagArray = this.state.invoiceLogList;
        var total = 0;
        var vat = 0;

        var vatPercent = Number(this.state.VATTaxValue);
        var discountPercent = Number(this.state.discount);

        var discount = 0;
        var grandTotal = 0;

        for (let index = 0; index < flagArray.length; index++) {
            total += flagArray[index].order.companyService.price * flagArray[index].order.quantity;
        }

        discount = (total * discountPercent) / 100;
        vat = ((total - discount) * vatPercent) / 100;
        grandTotal = total - discount + vat;

        this.setState({
            total: total,
            vatTotal: vat,
            discountTotal: discount,
            grandTotal: grandTotal
        });
    }

    handleChange = e => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    onChangeVATTaxCB = (e) => {
        this.setState({
            VATTaxCheck: e.target.checked
        });
    }

    addNewTermCond = () => {
        _termCondRows.push(
            {
                title: this.state.termCondName,
                description: "",
                checked: false
            }
        );

        this.setState({
            termCondRows: _termCondRows,
            termCondName: ""
        });
    }

    handleChangeTermCond = (index, e) => {
        e.preventDefault();
        let list = this.state.termCondRows;
        list[index].description = e.target.value;

        this.setState({
            termCondRows: list.slice()
        });
    }

    onChangeTermCond = (index, e) => {
        let list = this.state.termCondRows;
        list[index].checked = e.target.checked;

        this.setState({
            termCondRows: list.slice()
        });
    }

    addNewNote = () => {
        _noteRows.push(
            {
                title: this.state.noteName,
                description: "",
                checked: false
            }
        );

        this.setState({
            noteRows: _noteRows,
            noteName: ""
        });
    }

    handleChangeNote = (index, e) => {
        e.preventDefault();
        let list = this.state.noteRows;
        list[index].description = e.target.value;

        this.setState({
            noteRows: list.slice()
        });
    }

    onChangeNote = (index, e) => {
        let list = this.state.noteRows;
        list[index].checked = e.target.checked;

        this.setState({
            noteRows: list.slice()
        });
    }

    showOrHideModalInvoice = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0");
        var yyyy = today.getFullYear();

        today = dd + "/" + mm + "/" + yyyy;

        this.setState({
            modalShowInvoice: !this.state.modalShowInvoice,
            invoiceDate: today,
            invoiceNo: "",
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    onChangeDateInvoice = async (date, dateString) => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + "/" + mm + "/" + yyyy;

        if (dateString !== "") {
            this.setState({
                invoiceDate: dateString,
            });
        } else {
            this.setState({
                invoiceDate: today,
            });
        }
    }

    createNew = () => {
        if (this.state.createNewButton === "Create New Invoice") {
            this.setState({
                hidden: false,
                createNewButton: "Stop Adding New Invoice",
                createNewButtonVariant: "danger",
                lastButton: "Save",
                lastButtonVariant: "primary"
            });
        } else {
            this.setState({
                hidden: true,
                createNewButton: "Create New Invoice",
                createNewButtonVariant: "info",
                lastButton: "Save",
                lastButtonVariant: "primary"
            });

            this.refreshValues();
        }
    }

    saveInvoiceLog = async () => {
        const invoiceLogList = this.state.invoiceLogList;

        console.log(invoiceLogList);

        const customerId = this.state.customer;
        var invoiceCompanyOrderIds = [];
        var invoiceCompanyOrderListIds = [];

        for (let index = 0; index < invoiceLogList.length; index++) {
            if (!(invoiceCompanyOrderIds.includes(invoiceLogList[index].id)))
                invoiceCompanyOrderIds.push(invoiceLogList[index].id);

            if (!(invoiceCompanyOrderListIds.includes(invoiceLogList[index].order.id)))
                invoiceCompanyOrderListIds.push(invoiceLogList[index].order.id);
        }

        console.log(invoiceCompanyOrderIds);
        console.log(invoiceCompanyOrderListIds);

        if (this.state.invoiceNo !== "" && this.state.grandTotal !== 0 && customerId !== "" && invoiceLogList.length !== 0) {

            const newInvoice = {
                invoiceNo: this.state.invoiceNo,
                invoiceDate: this.state.invoiceDate,
                discountValue: this.state.discount,
                vatCheck: this.state.VATTaxCheck,
                vatValue: this.state.VATTaxValue,
                invoiceTotal: this.state.grandTotal,
                customerId: customerId,
                invoiceCompanyOrderIds: invoiceCompanyOrderIds,
                invoiceCompanyOrderListIds: invoiceCompanyOrderListIds,
                invoiceTermList: this.state.termCondRows,
                invoiceNoteList: this.state.noteRows
            }

            await handleRequest("POST", "/Invoice/add", newInvoice);

            message.success(`Invoice has been created successfully.`);
            this.restartTable();
            this.refreshValues();

        } else {
            this.showOrHideModalError();
        }
    }

    restartTable = async () => {
        const response = await handleRequest("GET", "/Invoice/getAllByCompany");

        console.log("Invoices");
        console.log(response.data);

        if (response.data.length === 0) {
            this.setState({
                invoiceList: []
            });
        }

        if (response.data.length !== 0) {
            this.setState({
                invoiceList: response.data
            });
        }
    }

    refreshValues = () => {
        _termCondRows = [];
        _noteRows = [];

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var dd1 = String(today.getDate() - 1).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;
        var today1 = dd1 + '/' + mm + '/' + yyyy;

        this.setState({
            startDate: today1,
            endDate: today,
            invoiceDate: today,
            modalShow: false,
            modalShowError: false,
            modalPdfViewer: false,
            pdfFile: "",
            createNewButton: "Create New Invoice",
            createNewButtonVariant: "info",
            hidden: true,
            customer: "",
            orderSelect: [],
            orderSelects: [],
            projectSelect: [],
            projectSelects: [],
            locationSelect: [],
            locationSelects: [],
            invoiceOrderList: [],
            total: 0,
            discount: 0,
            discountTotal: 0,
            vat: 0,
            vatTotal: 0,
            grandTotal: 0,
            termCondRows: [],
            termCondName: "",
            noteRows: [],
            noteName: "",
            VATTaxCheck: true,
            VATTaxValue: 0,
            invoiceNo: "",
            invoiceDate: null,
            modalShowInvoice: false,
            lastButtonName: "Save",
            lastButtonVariant: "primary"
        });
    }

    showOrHideModalError = () => {
        this.setState({
            modalShowError: !this.state.modalShowError
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalPdfViewer = () => {
        this.setState({
            modalPdfViewer: !this.state.modalPdfViewer,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showAttachedFile = async (rowData) => {
        try {
            const fileRealUrl = `${API_BASE}/Invoice/download/` + rowData.id;

            console.log("fileUrl: " + fileRealUrl);

            this.setState({
                pdfFile: fileRealUrl,
                modalPdfViewer: true,
            });

        } catch (error) {
            console.log(error);
            message.error(`File can't found.`);
        }
    }

    render() {

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                console.log('selectedRows: ', selectedRows);

                var flagArray = [];
                var total = 0;
                var vat = 0;

                var vatPercent = Number(this.state.VATTaxValue);
                var discountPercent = Number(this.state.discount);

                var discount = 0;
                var grandTotal = 0;

                for (let index = 0; index < selectedRows.length; index++) {
                    flagArray.push({
                        rowNumber: index + 1,
                        key: selectedRows[index].order.id,
                        companyId: selectedRows[index].companyId,
                        customerId: selectedRows[index].customerId,
                        customerLog: selectedRows[index].customerLog,
                        customerProject: selectedRows[index].customerProject,
                        customerProjectId: selectedRows[index].customerProjectId,
                        customerProjectLocation: selectedRows[index].customerProjectLocation,
                        customerLocationId: selectedRows[index].customerLocationId,
                        deliveryTime1: selectedRows[index].deliveryTime1,
                        deliveryTime2: selectedRows[index].deliveryTime2,
                        id: selectedRows[index].id,
                        index: selectedRows[index].index,
                        orderNo: selectedRows[index].orderNo,
                        orderDate: selectedRows[index].orderDate,
                        order: selectedRows[index].order,
                        projectId: selectedRows[index].projectId,
                        timelines: selectedRows[index].timelines
                    });

                    total += selectedRows[index].order.companyService.price * selectedRows[index].order.quantity;
                }

                discount = (total * discountPercent) / 100;
                vat = ((total - discount) * vatPercent) / 100;
                grandTotal = total - discount + vat;

                this.setState({
                    invoiceLogList: flagArray,
                    total: total,
                    vatTotal: vat,
                    discountTotal: discount,
                    grandTotal: grandTotal
                });
            }
        };

        return (
            <div>
                <Modal
                    show={this.state.modalShow}
                    onHide={this.showOrHideModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Delete Invoice Log Data?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>All informations about invoice log data will be deleted. Are you sure?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideModal}>Close</Button>
                        <Button variant="danger" onClick={this.delete} >Delete</Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalShowInvoice}
                    onHide={this.showOrHideModalInvoice}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Invoice Completion Processes
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={4} style={{ textAlign: "right" }}>
                                <Form.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                                    Invoice No:
                                </Form.Label>
                            </Col>
                            <Col xs={8}>
                                <Form.Control name="invoiceNo" value={this.state.invoiceNo} onChange={this.handleChange} />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '1rem' }}>
                            <Col xs={4} style={{ textAlign: "right" }}>
                                <Form.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                                    Date:
                                </Form.Label>
                            </Col>
                            <Col xs={8}>
                                <DatePicker disabledDate={this.disabledDate} value={moment(this.state.invoiceDate, "DD/MM/YYYY")}
                                    format="DD/MM/YYYY" onChange={this.onChangeDateInvoice} />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideModalInvoice}>Close</Button>
                        <Button variant="success" onClick={this.saveInvoiceLog}>Save</Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalShowError}
                    onHide={this.showOrHideModalError}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">There are missing or wrong values.</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{ color: 'black' }}>Please, check all the values you entered.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="info" onClick={this.showOrHideModalError}>Close</Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    size="xl"
                    show={this.state.modalPdfViewer}
                    onHide={this.showOrHideModalPdfViewer}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            PDF Report Viewer
               </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ textAlign: "right", marginBottom: "1rem" }}>
                            <FacebookShareButton url={this.state.pdfFile} quote="Sales Quote PDF File Link">
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <EmailShareButton style={{ marginLeft: "1rem" }} url={this.state.pdfFile} body="Sales Quote PDF File Link is here: " subject="Sales Quote PDF File Link">
                                <EmailIcon size={32} round />
                            </EmailShareButton>
                            <WhatsappShareButton
                                style={{ marginLeft: "1rem" }}
                                url={this.state.pdfFile}
                                title="Sales Quote PDF File Link"
                                separator=": "
                            >
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                        </div>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
                            <div style={{ height: "900px" }}>
                                <Viewer fileUrl={this.state.pdfFile} />
                            </div>
                        </Worker>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.showOrHideModalPdfViewer}
                        >
                            Close
               </Button>
                    </Modal.Footer>
                </Modal>

                <div style={{ padding: "4px 1rem 4px 1rem" }}>
                    <Row>
                        <Col xs={3}></Col>
                        <Col xs={3}></Col>
                        <Col xs={3}></Col>
                        <Col xs={3}><Button onClick={this.createNew} style={{ width: '100%' }} variant={this.state.createNewButtonVariant} >{this.state.createNewButton}</Button></Col>
                    </Row>
                </div>

                <div hidden={this.state.hidden} style={{ marginTop: '1rem' }}>
                    <PrimeCard>
                        <Form>
                            <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                                <Col sm="2"></Col>
                                <Form.Label style={{ color: "black" }} column sm="2">
                                    Customer
                            </Form.Label>
                                <Col sm="3">
                                    <Select style={{ width: "100%" }} placeholder="Customer" value={this.state.customer}
                                        onChange={(value) => {
                                            this.setState({ customer: value, project: "" });
                                            setTimeout(() => {
                                                this.fillProjectsAndOrderNos();
                                            }, 200);
                                        }}
                                    >
                                        {this.state.customerList.map((i) => (
                                            <Option key={i.id} value={i.id}>
                                                {i.fullName}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                            </Form.Group>
                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Order No
                            </Form.Label>
                                <Col sm="3">
                                    <MultiSelect className="p-column-filter" value={this.state.orderSelect}
                                        options={this.state.orderSelects} onChange={this.onOrderChange} />
                                </Col>
                            </Form.Group>
                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Project
                            </Form.Label>
                                <Col sm="3">
                                    <MultiSelect className="p-column-filter" value={this.state.projectSelect}
                                        options={this.state.projectSelects} onChange={this.onProjectChange} />
                                </Col>
                            </Form.Group>
                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Location
                            </Form.Label>
                                <Col sm="3">
                                    <MultiSelect className="p-column-filter" value={this.state.locationSelect}
                                        options={this.state.locationSelects} onChange={this.onLocationChange} />
                                </Col>
                            </Form.Group>
                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Date
                            </Form.Label>
                                <Col sm="3">
                                    <RangePicker value={[moment(this.state.startDate, 'DD/MM/YYYY'), moment(this.state.endDate, 'DD/MM/YYYY')]} disabledDate={this.disabledDate}
                                        format="DD/MM/YYYY" onChange={this.onChangeDate} />
                                </Col>
                            </Form.Group>
                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="4">
                                </Col>
                                <Col sm="3">
                                    <Button style={{ width: '100%' }} onClick={this.refreshOrderTable} type="button">Display Orders</Button>
                                </Col>
                                <Col sm="5">
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Col sm="6">
                                    <Table rowSelection={{
                                        type: "checkbox",
                                        ...rowSelection,
                                    }}
                                        size={`small`} dataSource={this.state.invoiceOrderList} bordered>
                                        <Column title="Order No" key="orderNo" dataIndex="orderNo" />
                                        <Column title="Project" key="project" render={(text, record) => {
                                            if (record.customerProject !== undefined)
                                                if (record.customerProject !== null)
                                                    return record.customerProject.projectName;
                                                else return "";
                                            else return "";
                                        }} />
                                        <Column title="Location" key="location" render={(text, record) => {
                                            if (record.customerProjectLocation !== undefined)
                                                if (record.customerProjectLocation !== null)
                                                    return record.customerProjectLocation.locationName;
                                                else return "";
                                            else return "";
                                        }} />
                                        <Column title="Product Code" key="productCode" render={(text, record) => {
                                            return record.order.companyService.code;
                                        }} />
                                        <Column title="Product" key="product" render={(text, record) => {
                                            return record.order.companyService.description;
                                        }} />
                                        <Column title="Ordered Quantity" key="orderQuantity" render={(text, record) => {
                                            return record.order.quantity;
                                        }} />
                                        <Column title="Delivered" key="deliveredQuantity" render={(text, record) => {
                                            return record.order.deliveredQty;
                                        }} />
                                    </Table>
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>

                        </Form>

                        <Table size={`small`} dataSource={this.state.invoiceLogList} bordered>
                            <Column width='50px' title="S/N" key="sn" dataIndex="rowNumber" />
                            <Column title="Order No" key="id" dataIndex="orderNo" />
                            <Column title="Product" key="product" render={(text, record) => {
                                return record.order.companyService.description;
                            }} />
                            <Column title="Quantity" key="quantity" render={(text, record) => {
                                return record.order.quantity;
                            }} />
                            <Column title="Unit" key="unit" render={(text, record) => {
                                return record.order.companyService.unitCode.name;
                            }} />
                            <Column title="Unit Price" key="unitprice" render={(text, record) => {
                                return record.order.companyService.price;
                            }} />
                            <Column title="Price" key="price" render={(text, record) => {
                                return record.order.companyService.price * record.order.quantity;
                            }} />
                        </Table>

                        <Form>
                            <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                                <Col sm="8">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Total
                            </Form.Label>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    {this.state.total} USR
                            </Form.Label>
                            </Form.Group>

                            <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                                <Col sm="5">
                                </Col>
                                <Col sm="1" style={{ textAlign: 'right' }}>
                                    <InputNumber style={{ marginTop: '0.25rem' }} min={0} value={this.state.discount}
                                        formatter={value => `${value}%`} parser={value => value.replace('%', '')}
                                        onChange={(value) => { this.setState({ discount: value }) }} />
                                </Col>
                                <Col sm="2">
                                    <Button style={{ width: '100%', marginLeft: "5px" }} onClick={this.applyDiscount} type="button">Apply Discount</Button>
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Discount
                            </Form.Label>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    {this.state.discountTotal} USR
                            </Form.Label>
                            </Form.Group>

                            <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                                <Col sm="8">
                                </Col>
                                {/*<Col sm="1" style={{ textAlign: 'right' }}>
                                <InputNumber style={{ marginTop: '0.25rem' }} min={0} value={this.state.vat}
                                    formatter={value => `${value}%`} parser={value => value.replace('%', '')}
                                    onChange={(value) => { this.setState({ vat: value }) }} />
                            </Col>
                            <Col sm="2">
                                <Button style={{ width: '100%', marginLeft: "5px" }} onClick={this.applyVAT} type="button">Apply VAT</Button>
                            </Col>*/}
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    VAT (%{this.state.VATTaxValue})
                            </Form.Label>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    {this.state.vatTotal} USR
                            </Form.Label>
                            </Form.Group>

                            <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                                <Col sm="8">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Grand Total
                            </Form.Label>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    {this.state.grandTotal} USR
                            </Form.Label>
                            </Form.Group>
                        </Form>
                        <Accordion defaultActiveKey="1">
                            <Card>
                                <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                                    <Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Terms and Conditions</Card.Header>
                                </Accordion.Toggle>
                                <Accordion.Collapse id="servicesAccordion" eventKey="0">
                                    <Card.Body>
                                        <Form>
                                            <Form.Group style={{ marginTop: '1rem', marginBottom: '3rem' }} as={Row}>
                                                <Form.Label style={{ color: 'black' }} column sm="3">
                                                    New Terms/Conditions Name:
                                            </Form.Label>
                                                <Col sm="4">
                                                    <Form.Control name="termCondName" value={this.state.termCondName} onChange={this.handleChange} />
                                                </Col>
                                                <Col sm="3">
                                                    <Button style={{ width: '100%', marginLeft: "5px" }} onClick={this.addNewTermCond} type="button">Add</Button>
                                                </Col>
                                                <Col sm="2">
                                                </Col>
                                            </Form.Group>

                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="2">
                                                    <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={this.onChangeVATTaxCB} checked={this.state.VATTaxCheck}>VAT Tax</Checkbox>
                                                </Col>
                                                <Col sm="2" style={{ textAlign: 'right' }}>
                                                    <InputNumber style={{ marginTop: '0.25rem' }} min={0} value={this.state.VATTaxValue}
                                                        formatter={value => `${value}%`} parser={value => value.replace('%', '')}
                                                        onChange={(value) => {
                                                            this.setState({ VATTaxValue: value });
                                                            var flagArray = this.state.invoiceLogList;
                                                            var total = 0;
                                                            var vat = 0;

                                                            var vatPercent = Number(value);
                                                            var discountPercent = Number(this.state.discount);

                                                            var discount = 0;
                                                            var grandTotal = 0;

                                                            for (let index = 0; index < flagArray.length; index++) {
                                                                total += flagArray[index].order.companyService.price * flagArray[index].order.quantity;
                                                            }

                                                            discount = (total * discountPercent) / 100;
                                                            vat = ((total - discount) * vatPercent) / 100;
                                                            grandTotal = total - discount + vat;

                                                            this.setState({
                                                                total: total,
                                                                vatTotal: vat,
                                                                discountTotal: discount,
                                                                grandTotal: grandTotal
                                                            });
                                                        }} />
                                                </Col>
                                                <Col sm="2">
                                                </Col>
                                            </Form.Group>

                                            {this.state.termCondRows.map((item, index) => (
                                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                    <Col sm="3">
                                                        <Checkbox style={{ color: 'black', marginTop: '0.25rem' }}
                                                            onChange={(e) => this.onChangeTermCond(index, e)}
                                                            checked={item.checked}>{item.title}</Checkbox>
                                                    </Col>
                                                    <Col sm="4">
                                                        <Form.Control name={item.title} value={item.description}
                                                            onChange={(e) => this.handleChangeTermCond(index, e)} />
                                                    </Col>
                                                </Form.Group>
                                            ))}
                                        </Form>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        <br />
                        <Accordion defaultActiveKey="1">
                            <Card>
                                <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                                    <Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Notes</Card.Header>
                                </Accordion.Toggle>
                                <Accordion.Collapse id="servicesAccordion" eventKey="0">
                                    <Card.Body>
                                        <Form.Group style={{ marginTop: '1rem', marginBottom: '3rem' }} as={Row}>
                                            <Form.Label style={{ color: 'black' }} column sm="3">
                                                New Note Name:
                                        </Form.Label>
                                            <Col sm="4">
                                                <Form.Control name="noteName" value={this.state.noteName} onChange={this.handleChange} />
                                            </Col>
                                            <Col sm="3">
                                                <Button style={{ width: '100%', marginLeft: "5px" }} onClick={this.addNewNote} type="button">Add</Button>
                                            </Col>
                                            <Col sm="2">
                                            </Col>
                                        </Form.Group>

                                        {this.state.noteRows.map((item, index) => (
                                            <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="3">
                                                    <Checkbox style={{ color: 'black', marginTop: '0.25rem' }}
                                                        onChange={(e) => this.onChangeNote(index, e)}
                                                        checked={item.checked}>{item.title}</Checkbox>
                                                </Col>
                                                <Col sm="4">
                                                    <Form.Control name={item.title} value={item.description}
                                                        onChange={(e) => this.handleChangeNote(index, e)} />
                                                </Col>
                                            </Form.Group>
                                        ))}
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>

                        <Form.Group style={{ marginBottom: '1rem', marginTop: '2rem' }} as={Row} >
                            <Col sm="3">
                            </Col>
                            <Col sm="6">
                                <Button type="button" style={{ width: '100%' }} onClick={this.showOrHideModalInvoice} variant={this.state.lastButtonVariant}>{this.state.lastButton}</Button>
                            </Col>
                            <Col sm="3">
                            </Col>
                        </Form.Group>
                    </PrimeCard>
                </div>

                <PrimeCard style={{ marginTop: "1rem" }}>
                    <Table size={`small`} dataSource={this.state.invoiceList} bordered>
                        <Column
                            width='50px'
                            title="Action"
                            key="action"
                            render={(text, record) => (
                                <div style={{ textAlign: "center" }}>
                                    <React.Fragment key={record.id} > <CustomMaterialMenu row={record}
                                        onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
                                    </React.Fragment>
                                </div>
                            )}
                        />
                        {/*<Column width='50px' title="S/N" key="sn" dataIndex="index" />*/}
                        <Column title="Invoice No" key="invoiceNo" render={(text, record) => {
                            if (record.attachedFilePath !== null && record.attachedFilePath !== "")
                                return (
                                    <React.Fragment>
                                        <Form.Group style={{ marginTop: '0rem', marginBottom: '0rem' }} as={Row}>
                                            <Form.Label style={{
                                                cursor: "pointer",
                                                textDecoration: "underline",
                                                fontWeight: "bold",
                                            }}
                                                onClick={(e) => this.showAttachedFile(record)} column sm="12">
                                                {record.invoiceNo}
                                                </Form.Label>
                                        </Form.Group>
                                    </React.Fragment>
                                );
                            else
                                return record.invoiceNo;
                        }} />
                        <Column title="Date" key="date" dataIndex="invoiceDate" />
                        <Column title="Customer" key="customer" render={(text, record) => {
                            return record.customerLog.shortName;
                        }} />
                        <Column title="Order No" key="orderNo" render={(text, record) => {
                            var orderNos = "";

                            if (record.invoiceCompanyOrderList !== undefined)
                                if (record.invoiceCompanyOrderList !== null && record.invoiceCompanyOrderList.length !== 0)
                                    for (let index = 0; index < record.invoiceCompanyOrderList.length; index++) {
                                        orderNos = orderNos + record.invoiceCompanyOrderList[index].companyOrder.orderNo + ", ";
                                    }

                            orderNos = orderNos.slice(0, -2);

                            return orderNos;
                        }} />
                        <Column title="Project" key="project" render={(text, record) => {
                            var projectNames = "";

                            if (record.invoiceCompanyOrderList !== undefined)
                                if (record.invoiceCompanyOrderList !== null && record.invoiceCompanyOrderList.length !== 0)
                                    for (let index = 0; index < record.invoiceCompanyOrderList.length; index++) {
                                        if (!projectNames.includes(record.invoiceCompanyOrderList[index].companyOrder.customerProject.projectName))
                                            projectNames = projectNames + record.invoiceCompanyOrderList[index].companyOrder.customerProject.projectName + ", ";
                                    }

                            projectNames = projectNames.slice(0, -2);

                            return projectNames;
                        }} />
                        <Column title="Location" key="location" render={(text, record) => {
                            var locationNames = "";

                            if (record.invoiceCompanyOrderList !== undefined)
                                if (record.invoiceCompanyOrderList !== null && record.invoiceCompanyOrderList.length !== 0)
                                    for (let index = 0; index < record.invoiceCompanyOrderList.length; index++) {
                                        if (!locationNames.includes(record.invoiceCompanyOrderList[index].companyOrder.customerProjectLocation.locationName))
                                        locationNames = locationNames + record.invoiceCompanyOrderList[index].companyOrder.customerProjectLocation.locationName + ", ";
                                    }

                            locationNames = locationNames.slice(0, -2);

                            return locationNames;
                        }} />
                        <Column title="Invoice Total" key="invoiceTotal" render={(text, record) => {
                            return (Math.round(record.invoiceTotal * 100) / 100).toFixed(2);
                        }} />
                    </Table>
                </PrimeCard>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceLog)