/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, InputNumber, Checkbox, Radio, Divider, DatePicker, Cascader, TimePicker, Switch, Button as AntButton, Col as ColAnt, Row as RowAnt } from 'antd';
import { Link } from 'react-router-dom';
import { NATIONALITIES, COUNTRIES } from '../settings/JsonDatas/Datas';
import moment from 'moment'
import { PlusOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { setStandardization, getDynamicMenu, getUserSettings } from '../../redux/config/imu.js';


const { RangePicker } = DatePicker;
const { Column, ColumnGroup } = Table;
const { Option } = Select;
const pageName = "ProjectProgress";

export class ProjectProgress extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            id: null,
            tableList: [],
            filePath: '',
            fileList: [],
            procurementPlanned: null,
            procurementActual: null,
            constructionPlanned: null,
            constructionActual: null,


            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: 0
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // this.showSuccess = this.showSuccess.bind(this);
        // this.showError = this.showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();


    componentDidMount = async () => {

        var isAdmin = false;
        let dynamicMenu = await getDynamicMenu(pageName);
        this.getUserSettings(dynamicMenu.formId);
        if (Boolean(this.props.auth) && Boolean(this.props.auth.user) && this.props.auth.user.userType === 2) {
            isAdmin = true;
        }

        let standardization = await setStandardization(pageName);
        this.setState({
            dynamicAttributes: standardization.dynamicAttributes,
            dynamicInputs: standardization.dynamicInputs,
            isAdmin: isAdmin,
            formId: dynamicMenu.formId,
            authorizations: dynamicMenu.authorizations
        })

        this.restartTable();
    }

    getUserSettings = async (formId) => {

        let userSettings = await getUserSettings(formId);
        this.setState(
            {
                howToUseSetting: userSettings.howToUseSetting,
                whatIsSetting: userSettings.whatIsSetting
            }
        )
    }

    save = async () => {


        let obj = {
            id: this.state.id,
            procurementPlanned: this.state.procurementPlanned,
            procurementActual: this.state.procurementActual,
            constructionPlanned: this.state.constructionPlanned,
            constructionActual: this.state.constructionActual,

        };
        console.log(obj);
        if (obj.id === null)
            await handleRequest("POST", "/ProjectProgress/add", obj);
        else
            await handleRequest("POST", "/ProjectProgress/update", obj);

        this.showSuccess();
        this.resetInputs();
        this.restartTable();

    }

    edit = (row) => {

        this.formRef.current.setFieldsValue({
            procurementPlanned: row.procurementPlanned,
            procurementActual: row.procurementActual,
            constructionPlanned: row.constructionPlanned,
            constructionActual: row.constructionActual,

        });

        this.setState({

            hideSave: true,
            hideUpdate: false,
            hideInputs: false,
            id: row.id,
            procurementPlanned: row.procurementPlanned,
            procurementActual: row.procurementActual,
            constructionPlanned: row.constructionPlanned,
            constructionActual: row.constructionActual,


        });

    }


    delete = async () => {

        const obj = {
            id: this.state.id
        }

        await handleRequest("POST", "/ProjectProgress/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;


    }



    restartTable = async () => {
        const response = await handleRequest("GET", "/ProjectProgress/getAllByCompany");
        var tableList = [{
            title: "Procurement",
            weekPlanned: null,
            weekActual: null,
            weekDeviation: null,
            overallPlanned: 0,
            overallActual: 0,
            overallDeviation: 0,
        },
        {
            title: "Construction",
            weekPlanned: null,
            weekActual: null,
            weekDeviation: null,
            overallPlanned: null,
            overallActual: null,
            overallDeviation: null,
        }]
        if (Boolean(response.data) && response.data.length > 0) {
            const list = response.data;
            list.forEach(element => {
                element.key = element.id,
                
                tableList[0].overallPlanned += element.procurementPlanned;
                tableList[0].overallActual += element.procurementActual;

                tableList[1].overallPlanned += element.constructionPlanned;
                tableList[1].overallActual += element.constructionActual;

            });

            tableList[0].weekPlanned =  list[0].procurementPlanned;
            tableList[0].weekActual = list[0].procurementActual;
            tableList[0].weekDeviation = tableList[0].weekActual - tableList[0].weekPlanned; 

            tableList[1].weekPlanned =  list[0].constructionPlanned ;
            tableList[1].weekActual = list[0].constructionActual;
            tableList[1].weekDeviation = tableList[1].weekActual - tableList[1].weekPlanned; 


            tableList[0].overallPlanned = tableList[0].overallPlanned ;
            tableList[0].overallActual = tableList[0].overallActual ;
            tableList[0].overallDeviation = tableList[0].overallActual - tableList[0].overallPlanned; 

            tableList[1].overallPlanned = tableList[1].overallPlanned;
            tableList[1].overallActual = tableList[1].overallActual;
            tableList[1].overallDeviation = tableList[1].overallActual - tableList[1].overallPlanned;   


            this.setState({
                tableList: tableList
            });

        }

        if (response.data.length === 0) {
            this.setState({
                tableList: [],
            });
        }
    }



    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }


    stop = () => {

        this.setState({

            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }



    resetInputs = () => {
        this.formRef.current.setFieldsValue({
            procurementPlanned: null,
            procurementActual: null,
            constructionPlanned: null,
            constructionActual: null,


        });

        this.setState({
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
            id: null,
            procurementPlanned: null,
            procurementActual: null,
            constructionPlanned: null,
            constructionActual: null,



        });
    }


    cancel = () => {

        this.resetInputs();
    }






    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }


    render() {

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        };

        const layout2 = {
            rowSpan: { span: 8 },
            wrapperCol: { span: 16 }
        };

        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };


        return (this.state.formId > 0) ? (
            (this.state.authorizations.length > 0 && this.state.authorizations.findIndex(p => p.action === "view") >= 0)
                ? (
                    <div>
                        <MenuProvider id="menu_id">
                            <Card>
                                <div>
                                    <Row>
                                        <Col sm="1">
                                            <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                        </Col>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="1" style={{ textAlign: "right" }}>
                                            {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>}
                                        </Col>
                                    </Row>
                                </div>

                                <div>
                                    <Row>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="2">
                                            {/*<Button style={{ width: '100%' }} id='create-new' icon="pi pi-plus" onClick={this.createNew} label="Create New" />*/}
                                            {this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                            }

                                            {!this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={this.stop}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                            }

                                        </Col>
                                    </Row>
                                </div>

                                <div hidden={this.state.hideInputs} style={{ marginBottom: '2rem' }}>

                                    <FormAnt
                                        initialValues={{ remember: false }}
                                        onFinish={this.save}
                                        onFinishFailed={onFinishFailed}
                                        ref={this.formRef}
                                    >

                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ProjectProgress.plannedProcurement").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="Title"
                                            >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={8}>

                                                    </ColAnt>
                                                    <ColAnt span={4}>
                                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ProjectProgress.plannedProcurement").visible) || this.state.dynamicAttributes.length === 0) &&
                                                            <FormAnt.Item


                                                                style={{
                                                                    marginBottom: 0,
                                                                }}
                                                                name="titlePlanned"
                                                            >
                                                                <div style={{ fontWeight: 800 }}>{"Planned"}</div>
                                                            </FormAnt.Item>
                                                        }
                                                    </ColAnt>

                                                    <ColAnt span={4}>
                                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ProjectProgress.actualProcurement").visible) || this.state.dynamicAttributes.length === 0) &&
                                                            <FormAnt.Item
                                                                style={{
                                                                    marginBottom: 0,
                                                                }}
                                                                name="titleActual"
                                                            >
                                                                <div style={{ fontWeight: 800 }}>{"Actual"}</div>

                                                            </FormAnt.Item>
                                                        }
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }

                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ProjectProgress.plannedProcurement").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="ProjectProgress.plannedProcurement" defaultMessage="Procurement" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}

                                            >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={6}>
                                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ProjectProgress.plannedProcurement").visible) || this.state.dynamicAttributes.length === 0) &&
                                                            <FormAnt.Item


                                                                style={{
                                                                    marginBottom: 0,
                                                                }}
                                                                name="procurementPlanned"
                                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ProjectProgress.plannedProcurement")) ? this.state.dynamicInputs.find(p => p.inputKey === "ProjectProgress.plannedProcurement").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ProjectProgress.plannedProcurement")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "ProjectProgress.plannedProcurement").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                                <InputNumber style={{ width: "100%" }} id="ProjectProgress.plannedProcurement" placeholder="Procurement (Planned)"
                                                                    min={0} value={this.state.procurementPlanned} onChange={(value) => {
                                                                        this.formRef.current.setFieldsValue({
                                                                            procurementPlanned: value
                                                                        });
                                                                        this.setState({ procurementPlanned: value })
                                                                    }} />
                                                            </FormAnt.Item>
                                                        }
                                                    </ColAnt>

                                                    <ColAnt span={6}>
                                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ProjectProgress.actualProcurement").visible) || this.state.dynamicAttributes.length === 0) &&
                                                            <FormAnt.Item


                                                                style={{
                                                                    marginBottom: 0,
                                                                }}
                                                                name="procurementActual"
                                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ProjectProgress.actualProcurement")) ? this.state.dynamicInputs.find(p => p.inputKey === "ProjectProgress.actualProcurement").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ProjectProgress.actualProcurement")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "ProjectProgress.actualProcurement").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                                <InputNumber style={{ width: "100%" }} id="ProjectProgress.actualProcurement" placeholder="Procurement (Actual)"
                                                                    min={0} value={this.state.procurementActual} onChange={(value) => {
                                                                        this.formRef.current.setFieldsValue({
                                                                            procurementActual: value
                                                                        });
                                                                        this.setState({ procurementActual: value })
                                                                    }} />
                                                            </FormAnt.Item>
                                                        }
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ProjectProgress.plannedConstruction").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="ProjectProgress.plannedConstruction" defaultMessage="Construction" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}

                                            >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={6}>
                                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ProjectProgress.plannedConstruction").visible) || this.state.dynamicAttributes.length === 0) &&
                                                            <FormAnt.Item


                                                                style={{
                                                                    marginBottom: 0,
                                                                }}
                                                                name="constructionPlanned"
                                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ProjectProgress.plannedConstruction")) ? this.state.dynamicInputs.find(p => p.inputKey === "ProjectProgress.plannedConstruction").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ProjectProgress.plannedConstruction")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "ProjectProgress.plannedConstruction").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                                <InputNumber style={{ width: "100%" }} id="ProjectProgress.plannedConstruction" placeholder="Construction (Planned)"
                                                                    min={0} value={this.state.constructionPlanned} onChange={(value) => {
                                                                        this.formRef.current.setFieldsValue({
                                                                            constructionPlanned: value
                                                                        });
                                                                        this.setState({ constructionPlanned: value })
                                                                    }} />
                                                            </FormAnt.Item>
                                                        }
                                                    </ColAnt>

                                                    <ColAnt span={6}>
                                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ProjectProgress.actualConstruction").visible) || this.state.dynamicAttributes.length === 0) &&
                                                            <FormAnt.Item


                                                                style={{
                                                                    marginBottom: 0,
                                                                }}
                                                                name="constructionActual"
                                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ProjectProgress.actualConstruction")) ? this.state.dynamicInputs.find(p => p.inputKey === "ProjectProgress.actualConstruction").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "ProjectProgress.actualConstruction")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "ProjectProgress.actualConstruction").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                                <InputNumber style={{ width: "100%" }} id="ProjectProgress.actualConstruction" placeholder="Construction (Actual)"
                                                                    min={0} value={this.state.constructionActual} onChange={(value) => {
                                                                        this.formRef.current.setFieldsValue({
                                                                            constructionActual: value
                                                                        });
                                                                        this.setState({ constructionActual: value })
                                                                    }} />
                                                            </FormAnt.Item>
                                                        }
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }



                                        <div hidden={this.state.hideSave}>
                                            {
                                                <RowAnt gutter={8}>
                                                    <ColAnt span={8}></ColAnt>

                                                    <ColAnt span={8}>
                                                        {
                                                            <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                                variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                                        }
                                                    </ColAnt>
                                                </RowAnt>

                                            }
                                        </div>



                                        <div hidden={this.state.hideUpdate}>
                                            {
                                                <Row>
                                                    <Col sm="3">
                                                    </Col>
                                                    <Col sm="2">
                                                        {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                        <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                        {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                        <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                    </FormAnt>
                                </div>

                                {this.state.tableList.length > 0 && <Table id={"Table"+this.props.id} style={{ marginTop: '3rem' }} size={`small`}
                                 dataSource={this.state.tableList} bordered>

                                    <Column title="" align="center" dataIndex="title" render={(value, row, index) => {

                                        return <div style={{ fontWeight: 800 }}>{value}</div>;
                                    }} />

                                    <ColumnGroup title="This Week" key="tw" >
                                        <Column title='Planned' dataIndex='weekPlanned' render={(value, row, index) => { return value; }} />
                                        <Column title='Actual' dataIndex='weekActual' render={(value, row, index) => { return value; }} />
                                        <Column title='Deviation' dataIndex='weekDeviation' render={(value, row, index) => { return value; }} />
                                    </ColumnGroup>
                                    <ColumnGroup title="Overall" key="overAll" >
                                        <Column title='Planned' dataIndex='overallPlanned' render={(value, row, index) => { return value; }} />
                                        <Column title='Actual' dataIndex='overallActual' render={(value, row, index) => { return value; }} />
                                        <Column title='Deviation' dataIndex='overallDeviation' render={(value, row, index) => { return value; }} />
                                    </ColumnGroup>

                                    {/* <Column title="Action" dataIndex="index" render={(value, row, index) => {
                                        return this.actionTemplate(row);
                                    }} /> */}

                                </Table>}
                            </Card>

                       </MenuProvider>
                        {!this.state.whatIsSetting && <Whatis  ></Whatis>

                        }

                        <Modal
                            show={this.state.modalDeleteShow}
                            onHide={this.showOrHideDeleteModal}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Are you sure?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                                <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.modalMessage}
                            onHide={this.showOrHideModalMessage}
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered>

                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    <Row >
                                        <Col sm="12">
                                            {this.state.messageTip == "Success" &&
                                                <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                            {this.state.messageTip == "Warning" &&
                                                <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                            {this.state.messageTip == "Error" &&
                                                <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                        </Col>
                                    </Row>
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <Row >
                                    <Col sm="12">
                                        {this.state.message}
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                            </Modal.Footer>
                        </Modal>

                    </div>
                ) : (
                    <div>
                        <Redirect to="/error/error-v6" />
                    </div>
                )
        ) : (
            <div></div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectProgress)
