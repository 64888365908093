import React from 'react';
import { IconButton  } from "@material-ui/core";
import { Menu } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { ListItemIcon } from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Divider} from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

export default ({ row, onDeleteRow, onEditRow, onAddRow, size, editBgColor, deleteBgColor }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const deleteRow = () => {
        if (onDeleteRow) {
            onDeleteRow(row);
            setAnchorEl(null);
        }
    };

    const editRow = () => {
        if (onEditRow) {
            onEditRow(row);
            setAnchorEl(null);
        }
    };

    const addRow = () => {
        if (onAddRow) {
            onAddRow(row);
            setAnchorEl(null);
        }
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                aria-controls="long-menu" // flaticon-layer
                aria-haspopup="true"
                onClick={handleClick}
                size={size}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="menu"
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={addRow} style={{ backgroundColor: '#' + editBgColor }}>
                    <ListItemIcon>
                        <AddIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        <FormattedMessage id="CustomMaterialMenuAddChild" defaultMessage="Add Child" />
                    </Typography>
                </MenuItem>

                <Divider />

                <MenuItem onClick={editRow} style={{ backgroundColor: '#' + editBgColor }}>
                    <ListItemIcon>
                        <EditIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        <FormattedMessage id="CustomMaterialMenuEdit" defaultMessage="Edit" />
                    </Typography>
                </MenuItem>

                <Divider />

                <MenuItem onClick={deleteRow} style={{ backgroundColor: '#' + deleteBgColor }}>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="error" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        <FormattedMessage id="CustomMaterialMenuDelete" defaultMessage="Delete" />
                    </Typography>
                </MenuItem>
            </Menu>
        </div>
    );
};