/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenuDelete';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, Switch, Radio, Timeline, TimePicker, DatePicker } from 'antd';
import moment from 'moment';

const { Column } = Table;
const { Option } = Select;
export class SubmittalLog extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            submittalLogList: [],
            selectedRowKeys: [],

            deliverable: '',
            departmentSelectItems: [],
            departmentId: 0,
            disciplineSelectItems: [],
            disciplineId: 0,
            selectedDisciplineIds: [],
            submittalLogId: 0,
            deliverableId: null,
            transmittalDocumentTypeId: null,

            type: 0,
            submittal: "",
            submittalCode: "",
            revision: true,
            actionTypeSelectItems: [],
            actionTypeId: 0,
            byUser: [],
            toClient: [],
            toType: 1,
            toUSer: [],

            customerList: [],
            userList: [],
            workflowList: [],
            workflowId: null,

            byUserStep: '',
            ccUserStep: [],
            selectedStep: null,
            stepList: [],
            stepList2: [], 
            oldWorkflowList: [],
            //for period
            sequence: 0,
            every: 1,
            period: "",
            monthWeekStyle: 0,
            periodPoint: 1,
            periodTime: null,
            periodTimeMoment: null,
            firstLast: '',
            firstLastPoint: 1,
            startDate: null,
            endDate: null,

            dynamicAttributes: [],
            authorizations: [],
            Inputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,


            formId: null
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // this.showSuccess = this.showSuccess.bind(this);
        // this.showError = this.showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();


    componentDidMount = async () => {
        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {

                this.setState({
                    isAdmin: this.props.auth.user.userType === 2 ? true : false
                });

            }

          
            const responseStep = await handleRequest("GET", "/TransmittalWorkflow/getAllByCompany");

            if (responseStep.data.length !== 0) {
                const list = responseStep.data;
                list.forEach(element => {
                    element.key = element.id;
                    element.value = element.stepName;
                    element.byUserStep = '';
                    element.ccUserStep = [];
                    element.disciplineId=null; 
    
                });
    
                console.log(list); 
    
                this.setState({
                    stepList2: list,
                });
            }
    
            if (responseStep.data.length === 0) {
                this.setState({
                    stepList2: [], 
                });
            }
            
        this.setComboboxes();
        this.setStandardizations();
      
        this.restartTable();

    }

    save = async () => {


        var oldWorkflowList = Boolean(this.state.oldWorkflowList) ? this.state.oldWorkflowList : [];

        if (Boolean(oldWorkflowList)) {
            oldWorkflowList.forEach(wf => {
                wf.active = false;
            });
        }

        const { byUserStep, ccUserStep } = this.state;
        if (Boolean(this.state.selectedStep)) {
            var lastSelected = this.state.stepList.find(p => p.id === this.state.selectedStep.id)
            lastSelected.byUserStep = byUserStep;
            lastSelected.ccUserStep = ccUserStep;
            lastSelected.disciplineId= this.state.disciplineId; 
        }

        this.state.stepList.forEach((element, index) => {

            if (Boolean(element.byUserStep)) {
                let obj = { userId: element.byUserStep, type: 'by', transmittalWorkflowId: element.id , disciplineId : element.disciplineId}; 
                let index = oldWorkflowList.findIndex(p => p.userId === element.byUserStep && p.transmittalWorkflowId === element.id && p.disciplineId===element.disciplineId);
                if (index === -1)
                    oldWorkflowList.push(obj);
                else
                    oldWorkflowList[index].active = true;
            }

            if (Boolean(element.ccUserStep))
                element.ccUserStep.forEach(e => {

                    let obj = { userId: e, type: 'cc', transmittalWorkflowId: element.id , disciplineId : element.disciplineId}; 
                    let index = oldWorkflowList.findIndex(p => p.userId === e && p.transmittalWorkflowId === element.id && p.disciplineId===element.disciplineId);
                    if (index === -1)
                        oldWorkflowList.push(obj);
                    else {
                        oldWorkflowList[index].active = true;

                    }


                });

        });


        console.log("sequence", this.state.sequence);
        console.log("every", this.state.every);
        console.log("period", this.state.period);
        console.log("monthWeekStyle", this.state.monthWeekStyle);
        console.log("periodPoint", this.state.periodPoint);
        console.log("periodTime", this.state.periodTime);
        console.log("firstLast", this.state.firstLast);
        console.log("firstLastPoint", this.state.firstLastPoint);
        console.log("startDate", this.state.startDate);
        console.log("endDate", this.state.endDate);

        const _periods = this.setPeriods();

        const obj = {
            id: this.state.submittalLogId,
            type: this.state.type,
            deliverableId: this.state.deliverableId,
            transmittalDocumentTypeId: this.state.transmittalDocumentTypeId,
            submittalCode: this.state.submittalCode,
            revision: this.state.revision,
            actionTypeId: this.state.actionTypeId,
            byUser: this.state.byUser,
            toType: this.state.toType,
            toUser: this.state.toUser,
            toClient: this.state.toClient,
            workflowList: oldWorkflowList,
            outgoingDeliverableList: _periods,
            outgoingDocumentList: _periods
        }

        console.log(obj);

        await handleRequest("POST", "/SubmittalLog/update", obj);

        this.showSuccess();
        this.resetInputs();
        this.restartTable();

    }

    setPeriods = () => {

        const _periods = [];
        if (this.state.sequence === 1) {
            let obj = { startDate: null };
            _periods.push(obj);
        }
        else
            switch (this.state.period) {
                case 'year':
                    let sd = this.state.startDate.toDate();
                    let first = moment(sd);
                    let end = moment(this.state.endDate.toDate());
                    let every = parseInt(this.state.every);
                    while (first.isSameOrBefore(end)) {
                        let obj = {
                            startDate: first.toDate(),
                        }
                        _periods.push(obj);
                        first.add(every, 'years');

                        console.log(first);

                    }
                    break;
                case 'month':
                    sd = this.state.startDate.toDate();
                    let periodTime = moment(this.state.periodTime).format('HH:mm:ss');
                    if (this.state.monthWeekStyle === '1') {
                        first = moment(sd).date(this.state.periodPoint);
                        if (first.isBefore(sd))
                            first.add(1, 'months');
                    }
                    if (this.state.monthWeekStyle === '2') {
                        if (this.state.firstLast === 'first') {
                            first = moment(sd).startOf('month');
                            first = first.day(parseInt(this.state.firstLastPoint));
                            if (first.date() > 7)
                                first.add(7, 'd');

                            if (first.isBefore(sd)) {
                                first.add(1, 'months');
                                first = first.startOf('month');
                                first = first.day(this.state.firstLastPoint);
                                if (first.date() > 7)
                                    first.add(7, 'd');
                            }


                        }

                        if (this.state.firstLast === 'last') {

                            first = first.endOf('month');
                            first = first.day(this.state.firstLastPoint);
                            if (first.isBefore(sd)) {
                                first.add(1, 'months');
                                first = first.endOf('month');
                                first = first.day(this.state.firstLastPoint);
                            }
                        }

                    }

                    console.log(periodTime);
                    console.log(first);
                    let time = periodTime.split(':');
                    first.set({
                        hours: time[0],
                        minutes: time[1],
                        seconds: time[2]
                    })
                    console.log(first);
                    end = moment(this.state.endDate.toDate());
                    every = parseInt(this.state.every);
                    while (first.isSameOrBefore(end)) {
                        let obj = {
                            startDate: first.toDate(),
                        }
                        _periods.push(obj);
                        first.add(every, 'months');

                        if (this.state.monthWeekStyle === '2') {
                            if (this.state.firstLast === 'first') {
                                first = first.startOf('month');
                                first = first.day(this.state.firstLastPoint);
                                if (first.date() > 7)
                                    first.add(7, 'd');
                            }

                            if (this.state.firstLast === 'last') {

                                first = first.endOf('month');
                                first = first.day(this.state.firstLastPoint);

                            }

                            time = periodTime.split(':');
                            first.set({
                                hours: time[0],
                                minutes: time[1],
                                seconds: time[2]
                            })

                        }


                        console.log(first);

                    }

                    break;
                case 'week':

                    sd = this.state.startDate.toDate();
                    first = moment(sd);
                    periodTime = moment(this.state.periodTime).format('HH:mm:ss');
                    if (this.state.monthWeekStyle === '1') {
                        first = moment(sd).day(this.state.periodPoint);
                        if (first.isBefore(sd))
                            first.add(1, 'weeks');
                    }

                    time = periodTime.split(':');
                    first.set({
                        hours: time[0],
                        minutes: time[1],

                    })

                    if (first.isBefore(sd))
                        first.add(1, 'weeks');

                    end = moment(this.state.endDate.toDate());
                    every = parseInt(this.state.every);
                    while (first.isSameOrBefore(end)) {
                        let obj = {
                            startDate: first.toDate(),
                        }
                        _periods.push(obj);
                        first.add(every, 'weeks');

                        console.log(first);

                    }

                    break;
                case 'day':
                    sd = this.state.startDate.toDate();
                    first = moment(sd);

                    periodTime = moment(this.state.periodTime).format('HH:mm:ss');
                    time = periodTime.split(':');
                    first.set({
                        hours: time[0],
                        minutes: time[1],

                    })

                    if (first.isBefore(sd))
                        first.add(1, 'days');

                    end = moment(this.state.endDate.toDate());
                    every = parseInt(this.state.every);
                    while (first.isSameOrBefore(end)) {
                        let obj = {
                            startDate: first.toDate(),
                        }
                        _periods.push(obj);
                        first.add(every, 'days');

                        console.log(first);

                    }
                    break;

                default:
                    break;
            }

        return _periods;
    }

    edit = (row) => {

        this.setState({
            submittalLogId: row.id,

            deliverable: row.deliverable,
            departmentId: row.departmentId,
            disciplineId: row.disciplineId,
            workflowId: row.workflowId,
            hideSave: true,
            hideUpdate: false,
            hideInputs: false,

        });

    }


    delete = async () => {
        const obj = {
            id: this.state.submittalLogId
        }

        await handleRequest("POST", "/SubmittalLog/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            submittalLogId: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;


    }


    restartTable = async () => {
        const response = await handleRequest("GET", "/SubmittalLog/getAllByCompany");

        if (response.data.length !== 0) {
            const list = response.data;
            list.forEach(element => {
                element.key = element.id;
                if (element.type === 1) {
                    element.description = element.deliverable.deliverable;
                    element.department = element.deliverable.department.departmentName;
                    element.discipline = element.deliverable.discipline.disciplineName;

                }
                if (element.type === 2) {
                    element.description = element.transmittalDocumentType.type;
                    element.department = element.transmittalDocumentType.department.departmentName;

                    let result = [];
                    if (Boolean(element.transmittalDocumentType.disciplineList) && element.transmittalDocumentType.disciplineList.length > 0) {

                        element.transmittalDocumentType.disciplineList.forEach(element => {
                            result.push(element.discipline.disciplineName);
                        });
                    }

                    element.discipline = result.toString();
                }
            });
            this.setState({
                submittalLogList: list,
            });
        }

        if (response.data.length === 0) {
            this.setState({
                submittalLogList: [],
            });
        }
    }


    setComboboxes = async () => {

        const response = await handleRequest("GET", "/CustomerLog/getAllByCompany");

        if (Boolean(response.data) && response.data.length > 0) {
            this.setState({
                customerList: response.data
            });
        }

        const responseUser = await handleRequest("GET", "/User/getCompanyItems");

        if (Boolean(responseUser) && Boolean(responseUser.data) && responseUser.data.length !== 0) {
            var filteredUsers = responseUser.data.filter(p => p.staffId > 0);

            const options = filteredUsers.map(function (row) {
                return { name: row.staff.name.toString() + " " + row.staff.midName.toString() + " " + row.staff.lastName.toString(), id: row.id }
            })

            this.setState({
                userList: options,
            });
        }



        const resActionList = await handleRequest("GET", "/ActionType/getAllByCompany");
        if (resActionList.data.length > 0) {
            this.setState({
                actionTypeSelectItems: resActionList.data
            });
        } else {
            this.setState({
                actionTypeSelectItems: []
            });
        }


        const resDepList = await handleRequest("GET", "/Department/getallByCompany");
        if (resDepList.data.length > 0) {
            this.setState({
                departmentSelectItems: resDepList.data
            });
        } else {
            this.setState({
                departmentSelectItems: []
            });
        }

        const resDisList = await handleRequest("GET", "/Discipline/getallByCompany");
        if (resDisList.data.length > 0) {
            this.setState({
                disciplineSelectItems: resDisList.data
            });
        } else {
            this.setState({
                disciplineSelectItems: []
            });
        }

        const resWfList = await handleRequest("GET", "/WorkFlow/getAll");
        if (resWfList.data.length > 0) {
            this.setState({
                workflowList: resWfList.data
            });
        } else {
            this.setState({
                workflowList: []
            });
        }

       

    }
    setStandardizations = async () => {
        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "Deliverable" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "Deliverable"
        };

        const responseInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseInputs.data.length !== 0) {
            this.setState({
                Inputs: responseInputs.data,
            });
        } else {
            this.setState({
                Inputs: [],
            });
        }

        //const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "Deliverable");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => p.menuId === this.state.formId || p.form === "All")
            });

        } catch (error) {

        }

    }



    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

            this.getUserSettings();
        } else {
            this.setState({
                formId: null
            });
        }
    }





    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            deliverable: '',
            submittalLogId: 0,
            departmentId: 0,
            disciplineId: 0,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });

    }


    stop = () => {

        this.resetInputs();
    }



    resetInputs = () => {
        this.setState({
            selectedRowKeys: [],
            submittalLogId: null,
            type: 0,
            deliverableId: null,
            transmittalDocumentTypeId: null,
            submittal: "",
            submittalCode: "",
            revision: true,
            departmentId: 0,
            disciplineId: 0,
            actionTypeId: 0,
            byUser: [],
            toType: [],
            toUser: [],
            toClient: [],
            workflowId: null,
            selectedDisciplineIds: [],
            hideInputs: true,
            deliverable: '',
            modalDeleteShow: false,
            hideUpdate: true,

        });
    }


    cancel = () => {

        this.resetInputs();
    }





    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }





    getUserSettings = async () => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + this.state.formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({

                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }


    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    onSelectChange = (selectedRowKeys, selectedRows) => {

        const row = selectedRows.pop();
        console.log('selectedRow: ', row);
        var _departmentId, _disciplineId, _selectedDisciplineIds = [];
        const _stepList = []; 
        console.log(this.state.stepList2); 
        const stepList = Boolean(this.state.stepList2) ? this.state.stepList2 : []; 
        stepList.forEach(step => {
            step.byUserStep = '';
            step.ccUserStep = [];
            step.disciplineId=null; 
        });

        if (row.type === 1) {
            _departmentId = row.deliverable.department.id;
            _disciplineId = row.deliverable.discipline.id;
            stepList.forEach(step => {
                let s = step; 
                s.disciplineId=_disciplineId;  
                _stepList.push(s); 
             });
        }
        if (row.type === 2) {
            _departmentId = row.transmittalDocumentType.department.id;
            if (Boolean(row.transmittalDocumentType.disciplineList)) {
                row.transmittalDocumentType.disciplineList.forEach(element => {
                    _selectedDisciplineIds.push(element.disciplineId);
                 
                   stepList.forEach(step => { 
                        let s = Object.assign({}, step);
                        s.disciplineId=element.disciplineId;  
                        _stepList.push(s); 
                     });

                });

                
            }
        }

      console.log(_stepList);
     

        if (Boolean(row.workflowList) && row.workflowList.length > 0) {

            if (Boolean(_stepList) && _stepList.length > 0) {

                _stepList.forEach(step => {
                    var filterResult = row.workflowList.filter(p => p.transmittalWorkflowId === step.id && p.disciplineId===step.disciplineId);
                    if (Boolean(filterResult)) {
                        filterResult.forEach(r => {

                            if (r.type === "by")
                                step.byUserStep = r.userId;
                            if (r.type === "cc")
                                step.ccUserStep.push(r.userId);

                               
                        });
                    }
                });

            }
        }

        console.log(_selectedDisciplineIds);
        this.setState({
            selectedRowKeys: selectedRowKeys,
            submittalLogId: row.id,
            type: row.type,
            stepList: _stepList,
            deliverableId: row.deliverableId,
            transmittalDocumentTypeId: row.transmittalDocumentTypeId,
            submittal: row.description,
            submittalCode: row.submittalCode,
            revision: row.revision,
            departmentId: _departmentId,
            disciplineId: _disciplineId,
            selectedDisciplineIds: _selectedDisciplineIds,
            actionTypeId: row.actionTypeId,
            byUser: Boolean(row.byUser) ? row.byUser : [],
            toType: row.toType,
            toUser: Boolean(row.toUser) ? row.toUser : [],
            toClient: Boolean(row.toClient) ? row.toClient : [],
            workflowId: row.workflowId,
            oldWorkflowList: Boolean(row.workflowList) ? row.workflowList : [],
            hideInputs: false,
            hideUpdate: false,
            selectedStep: null,
        });
    };

    clickStepValue = (step) => {

        console.log(step);
        const { byUserStep, ccUserStep,disciplineId } = this.state;

        if (Boolean(this.state.selectedStep)) {
            this.setState(prevState => ({
                stepList: prevState.stepList.map(
                    el => (el.id === this.state.selectedStep.id && el.disciplineId===disciplineId) ? {
                        ...el,
                        ccUserStep: ccUserStep,
                        byUserStep: byUserStep,
                       

                    } : el
                )
            }))
        }

        this.setState({
            ccUserStep: step.ccUserStep,
            byUserStep: step.byUserStep,
        })

        this.setState({ selectedStep: step });
    }

    onChangeDiscipline = (value) => {

       
        const { byUserStep, ccUserStep,disciplineId } = this.state;

        if (Boolean(this.state.selectedStep)) {
            this.setState(prevState => ({
                stepList: prevState.stepList.map(
                    el =>( el.id === this.state.selectedStep.id && el.disciplineId===disciplineId) ? {
                        ...el,
                        ccUserStep: ccUserStep,
                        byUserStep: byUserStep
                       

                    } : el
                )
            }))
        }

        if(Boolean(this.state.stepList) && this.state.stepList.length>0 && Boolean(this.state.selectedStep)) {
            const step = this.state.stepList.find(p=>p.id==this.state.selectedStep.id && p.disciplineId==value) ;
            if(Boolean(step))
            this.setState({
                ccUserStep: step.ccUserStep,
                byUserStep: step.byUserStep,                
            })
            else 
            this.setState({
                byUserStep: '',
                ccUserStep: [],              
            })
        }
      
        this.setState({ disciplineId : value }); 
    }

    render() {
        const { selectedRowKeys } = this.state;
        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
            type: "radio"
        };

        const list31 = [];
        for (let index = 1; index <= 31; index++) {
            list31.push(index);
        }

        return  <div>

                <MenuProvider id="menu_id">


                    <Modal
                        show={this.state.modalDeleteShow}
                        onHide={this.showOrHideDeleteModal}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Chosen submittal log will be deleted !</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                            <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Card>

                        <div>
                            <Row>
                                <Col sm="1">
                                    <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                </Col>
                                <Col sm="10">
                                </Col>
                                <Col sm="1" style={{ textAlign: "right" }}>
                                    {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>

                                    }
                                </Col>
                            </Row>
                        </div>

                        <div>
                            <Row>
                                <Col sm="10">
                                </Col>
                                <Col sm="2">
                                    {/*<Button style={{ width: '100%' }} id='create-new' icon="pi pi-plus" onClick={this.createNew} label="Create New" />*/}
                                    {/* {this.state.hideInputs &&
                                        <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                    } */}

                                    {!this.state.hideInputs &&
                                        <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={this.stop}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                    }

                                </Col>
                            </Row>
                        </div>

                        <div hidden={this.state.hideInputs} style={{ marginBottom: '2rem' }}>

                            <FormAnt
                                {...layout}
                                initialValues={{ remember: false }}
                                onFinish={this.save}
                                onFinishFailed={onFinishFailed}
                                ref={this.formRef}
                            >



                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Submittal" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type deliverable" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">

                                                <Input id="todo" disabled={true} value={this.state.submittal} />

                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Submittal Code" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type submittal code" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">

                                                <Input id="todo" value={this.state.submittalCode} onChange={(e) => this.setState({ submittalCode: e.target.value })} />

                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Revision" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type submittal code" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">

                                                <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked checked={this.state.revision} onChange={(value) => { this.setState({ revision: value }) }} />

                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }
                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Department" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type deliverable" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Select placeholder="" disabled={true} value={this.state.departmentId}
                                                >
                                                    <Option key={0} value={0}>Select</Option>
                                                    {this.state.departmentSelectItems.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.departmentName}</Option>
                                                    ))}
                                                </Select>


                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }



                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Action Type" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please Select Action Type" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Select placeholder="" value={this.state.actionTypeId} onChange={(value) => {
                                                    this.setState({
                                                        actionTypeId: value
                                                    })
                                                }}
                                                >
                                                    <Option key={null} value={null}>Select</Option>
                                                    {this.state.actionTypeSelectItems.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.type}</Option>
                                                    ))}
                                                </Select>


                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="By" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please Select Action Type" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Select mode="tags" style={{ width: '100%' }} placeholder="Tags Mode" value={this.state.byUser} onChange={(value) => {
                                                    this.setState({
                                                        byUser: value
                                                    })
                                                }}>
                                                    {this.state.userList.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.name}</Option>
                                                    ))}
                                                </Select>

                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }


                                {<FormAnt.Item
                                    label={<FormattedMessage id="todo" defaultMessage="Response Requirement" />}
                                    name="OrganizationalLevelDesignation"
                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select response requirement" /> }]} >
                                    <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                        <Col sm="6">
                                            <Radio.Group onChange={(e) => {
                                                this.setState({
                                                    toType: e.target.value
                                                })
                                            }} value={this.state.toType}>
                                                <Radio value={1}>User</Radio>
                                                <Radio value={2}>Client</Radio>
                                            </Radio.Group>

                                        </Col>
                                        <Col sm="6">
                                        </Col>
                                    </Form.Group>
                                </FormAnt.Item>
                                }

                                {this.state.toType === 1 &&
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="To" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please Select Action Type" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Select
                                                 showSearch
                                                 optionFilterProp="children"
                                                mode="tags" style={{ width: '100%' }} placeholder="Tags Mode" value={this.state.toUser} onChange={(value) => {
                                                    this.setState({
                                                        toUser: value
                                                    })
                                                }}>
                                                    {this.state.userList.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.name}</Option>
                                                    ))}
                                                </Select>

                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {this.state.toType === 2 &&
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="To" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please Select Action Type" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Select mode="tags" style={{ width: '100%' }} placeholder="Tags Mode" value={this.state.toClient} onChange={(value) => {
                                                    this.setState({
                                                        toClient: value
                                                    })
                                                }}>
                                                    {this.state.customerList.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.fullName}</Option>
                                                    ))}
                                                </Select>

                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {this.state.type === 1 &&
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Discipline" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type deliverable" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Select placeholder="" disabled={true} value={this.state.disciplineId}
                                                >
                                                    <Option key={0} value={0}>Select</Option>
                                                    {this.state.disciplineSelectItems.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.disciplineName}</Option>
                                                    ))}
                                                </Select>

                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }
                                {this.state.type === 2 &&
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Disciplines" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type deliverable" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Select placeholder="" value={this.state.disciplineId} onChange={this.onChangeDiscipline}
                                                >
                                                    <Option key={0} value={0}>Select</Option>
                                                    {this.state.disciplineSelectItems.filter(p => this.state.selectedDisciplineIds.includes(p.id)).map(i => (
                                                        <Option key={i.id} value={i.id}>{i.disciplineName}</Option>
                                                    ))}
                                                </Select>

                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item> 
                                }
                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Sequence" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please Select Sequence" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Select style={{ width: '100%' }} placeholder="Workflow" value={this.state.sequence} onChange={(value) => {
                                                    this.setState({
                                                        sequence: value
                                                    })
                                                }}>
                                                    <Option key={0} value={0} >Select Sequence</Option>
                                                    <Option key={1} value={1} >One Time</Option>
                                                    <Option key={2} value={2} >Periodical</Option>
                                                </Select>

                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {this.state.sequence === 2 &&
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Every" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please Select Sequence" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="2">
                                                <Select style={{ width: '100%' }} placeholder="Workflow" value={this.state.every} onChange={(value) => {
                                                    this.setState({
                                                        every: value
                                                    })
                                                }}>
                                                    {list31.map(i => (
                                                        <Option key={i} value={i}>{i}</Option>
                                                    ))}

                                                </Select>

                                            </Col>
                                            <Col sm="2">
                                                <Select style={{ width: '100%' }} placeholder="Workflow" value={this.state.period} onChange={(value) => {
                                                    this.setState({
                                                        period: value
                                                    })
                                                }}>

                                                    <Option key={""} value={""}>{"Select"}</Option>
                                                    <Option key={"year"} value={"year"}>{"Year"}</Option>
                                                    <Option key={"month"} value={"month"}>{"Month"}</Option>
                                                    <Option key={"week"} value={"week"}>{"Week"}</Option>
                                                    <Option key={"day"} value={"day"}>{"Day"}</Option>

                                                </Select>

                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }
                                {this.state.sequence === 2 && this.state.period === "month" &&
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Month Style" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please Select Sequence" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Select style={{ width: '100%' }} placeholder="Workflow" value={this.state.monthWeekStyle} onChange={(value) => {
                                                    this.setState({
                                                        monthWeekStyle: value
                                                    })
                                                }}>
                                                    <Option key={0} value={0} >Select Sequence</Option>
                                                    <Option key={1} value={1} >Day Number</Option>
                                                    <Option key={2} value={2} >Spesific Day</Option>
                                                </Select>

                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {this.state.sequence === 2 && this.state.period === "month" && this.state.monthWeekStyle === 1 &&
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Start Day of Month and Time" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please Select Sequence" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="3">
                                                <Select style={{ width: '100%' }} placeholder="Workflow" value={this.state.periodPoint} onChange={(value) => {
                                                    this.setState({
                                                        periodPoint: value
                                                    })
                                                }}>
                                                    {list31.map(i => (
                                                        <Option key={i} value={i}>{i}</Option>
                                                    ))}
                                                </Select>

                                            </Col>
                                            <Col sm="3">
                                                <TimePicker value={this.state.periodTimeMoment}
                                                    onChange={(time, timeString) => {
                                                        this.setState({
                                                            periodTime: timeString,
                                                            periodTimeMoment: time
                                                        })
                                                    }}
                                                    defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                                                />

                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }


                                {this.state.sequence === 2 && this.state.period === "month" && this.state.monthWeekStyle === 2 &&
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Spesific day and Time" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please Select Sequence" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="3">
                                                <Select style={{ width: '100%' }} placeholder="Workflow" value={this.state.firstLast} onChange={(value) => {
                                                    this.setState({
                                                        firstLast: value
                                                    })
                                                }}>
                                                    <Option key={""} value={""}>{"Select"}</Option>
                                                    <Option key={"first"} value={"first"}>{"First"}</Option>
                                                    <Option key={"last"} value={"last"}>{"Last"}</Option>

                                                </Select>

                                            </Col>
                                            <Col sm="3">
                                                <Select style={{ width: '100%' }} placeholder="Workflow" value={this.state.firstLastPoint} onChange={(value) => {
                                                    this.setState({
                                                        firstLastPoint: value
                                                    })
                                                }}>
                                                    <Option value={1}>Monday</Option>
                                                    <Option value={2}>Tuesday</Option>
                                                    <Option value={3}>Wednesday</Option>
                                                    <Option value={4}>Thursday</Option>
                                                    <Option value={5}>Friday</Option>
                                                    <Option value={6}>Saturday</Option>
                                                    <Option value={0}>Sunday</Option>
                                                    <Option value={7}>Working Day</Option>

                                                </Select>

                                            </Col>
                                            <Col sm="3">
                                                <TimePicker value={this.state.periodTimeMoment}
                                                    onChange={(time, timeString) => {
                                                        this.setState({
                                                            periodTime: timeString,
                                                            periodTimeMoment: time
                                                        })
                                                    }}
                                                    defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                                                />

                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {this.state.sequence === 2 && this.state.period === "week" &&
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Week Style" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please Select Sequence" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Select style={{ width: '100%' }} placeholder="Workflow" value={this.state.monthWeekStyle} onChange={(value) => {
                                                    this.setState({
                                                        monthWeekStyle: value
                                                    })
                                                }}>
                                                    <Option key={0} value={0} >Select Sequence</Option>
                                                    <Option key={1} value={1} >Week Days</Option>
                                                    <Option key={2} value={2} >Spesific Day</Option>
                                                </Select>

                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {this.state.sequence === 2 && this.state.period === "week" && this.state.monthWeekStyle === 1 &&
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Start Day of Week and Time" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please Select Sequence" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="3">
                                                <Select style={{ width: '100%' }} placeholder="Workflow" value={this.state.periodPoint} onChange={(value) => {
                                                    this.setState({
                                                        periodPoint: value
                                                    })
                                                }}>
                                                    <Option value={1}>Monday</Option>
                                                    <Option value={2}>Tuesday</Option>
                                                    <Option value={3}>Wednesday</Option>
                                                    <Option value={4}>Thursday</Option>
                                                    <Option value={5}>Friday</Option>
                                                    <Option value={6}>Saturday</Option>
                                                    <Option value={0}>Sunday</Option>
                                                </Select>

                                            </Col>
                                            <Col sm="3">
                                                <TimePicker value={this.state.periodTimeMoment}
                                                    onChange={(time, timeString) => {
                                                        this.setState({
                                                            periodTime: timeString,
                                                            periodTimeMoment: time
                                                        })
                                                    }}
                                                    defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                                                />

                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {this.state.sequence === 2 && this.state.period === "week" && this.state.monthWeekStyle === 2 &&
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Spesific Day and Time" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please Select Sequence" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="2">
                                                <Select style={{ width: '100%' }} placeholder="Workflow" value={this.state.firstLast} onChange={(value) => {
                                                    this.setState({
                                                        firstLast: value
                                                    })
                                                }}>
                                                    <Option key={""} value={""}>{"Select"}</Option>
                                                    <Option key={"first"} value={"first"}>{"First Working Day"}</Option>
                                                    <Option key={"last"} value={"last"}>{"Last Working Day"}</Option>
                                                </Select>

                                            </Col>
                                            <Col sm="2">
                                                <TimePicker value={this.state.periodTimeMoment}
                                                    onChange={(time, timeString) => {
                                                        this.setState({
                                                            periodTime: timeString,
                                                            periodTimeMoment: time
                                                        })
                                                    }}
                                                    defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                                                />

                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }


                                {this.state.sequence === 2 && this.state.period === "day" &&
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Start Hour" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please Select Sequence" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <TimePicker value={this.state.periodTimeMoment}
                                                    onChange={(time, timeString) => {
                                                        this.setState({
                                                            periodTime: timeString,
                                                            periodTimeMoment: time
                                                        })
                                                    }}
                                                    defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                                                />


                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }
                                {this.state.sequence === 2 &&
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Start Time" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please Select Sequence" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <DatePicker showTime onOk={(value) => {
                                                    this.setState({
                                                        startDate: value
                                                    })
                                                }} value={this.state.startDate}

                                                    format="DD-MM-YYYY HH:mm"
                                                />


                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }
                                {this.state.sequence === 2 &&
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="End Time" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please Select Sequence" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <DatePicker showTime onOk={(value) => {
                                                    this.setState({
                                                        endDate: value
                                                    })
                                                }} value={this.state.endDate}

                                                    format="DD-MM-YYYY HH:mm"
                                                />


                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }



                                {/* {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Workflow" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please Select Action Type" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Select style={{ width: '100%' }} placeholder="Workflow" value={this.state.workflowId} onChange={(value) => {
                                                    this.setState({
                                                        workflowId: value
                                                    })
                                                }}>
                                                    {this.state.workflowList.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.workFlowName}</Option>
                                                    ))}
                                                </Select>

                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                } */}

                                {
                                    <FormAnt.Item
                                        name="OrganizationalLevelRoleGroup"
                                        id="OrganizationalLevelRoleGroup"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type step name" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                            </Col>

                                            <Col sm="6">
                                                <Timeline mode='left'>

                                                    {this.state.stepList.filter(p => p.active === true && p.disciplineId===this.state.disciplineId).map((opt, j) => (
                                                        <Timeline.Item label={opt.label}> <a
                                                            style={{ flex: 'none', display: 'block', cursor: 'pointer' }}
                                                            onClick={() => this.clickStepValue(opt)}
                                                        >
                                                            {opt.value}
                                                        </a>  </Timeline.Item>
                                                    ))}

                                                </Timeline>
                                            </Col>

                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {Boolean(this.state.selectedStep) &&
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Step Name" />}
                                        name="OrganizationalLevelRoleGroup"
                                        id="OrganizationalLevelRoleGroup"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type step name" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                {Boolean(this.state.selectedStep) && <Form.Label style={{ color: 'black', font: 'bold', fontSize: '16pt' }} >
                                                    {this.state.selectedStep.value}
                                                </Form.Label>}

                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }


                                {Boolean(this.state.selectedStep) &&
                                    < FormAnt.Item
                                        label={<FormattedMessage id="todo2" defaultMessage="By" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please Select Action Type" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Select style={{ width: '100%' }} placeholder="Tags Mode" value={this.state.byUserStep} onChange={(value) => {
                                                    this.setState({
                                                        byUserStep: value
                                                    })
                                                }}>
                                                    {this.state.userList.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.name}</Option>
                                                    ))}
                                                </Select>

                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {Boolean(this.state.selectedStep) &&
                                    < FormAnt.Item
                                        label={<FormattedMessage id="todo2" defaultMessage="Cc" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please Select Action Type" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Select mode="tags" style={{ width: '100%' }} placeholder="Tags Mode" value={this.state.ccUserStep} onChange={(value) => {
                                                    this.setState({
                                                        ccUserStep: value
                                                    })
                                                }}>
                                                    {this.state.userList.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.name}</Option>
                                                    ))}
                                                </Select>

                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {/* <div hidden={this.state.hideSave}>
                                    {
                                        <Row>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="5">
                                                <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                    variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Row>
                                    }
                                </div> */}



                                <div hidden={this.state.hideUpdate}>
                                    {
                                        <Row>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="2">
                                                {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                                    <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                                {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                                    <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Row>
                                    }
                                </div>
                                <Modal
                                    show={this.state.modalMessage}
                                    onHide={this.showOrHideModalMessage}
                                    backdrop="static"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered>

                                    <Modal.Header closeButton>
                                        <Modal.Title id="contained-modal-title-vcenter">
                                            <Row >
                                                <Col sm="12">
                                                    {this.state.messageTip == "Success" &&
                                                        <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                                    {this.state.messageTip == "Warning" &&
                                                        <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                                    {this.state.messageTip == "Error" &&
                                                        <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                                </Col>
                                            </Row>
                                        </Modal.Title>
                                    </Modal.Header>

                                    <Modal.Body>
                                        <Row >
                                            <Col sm="12">
                                                {this.state.message}
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                                    </Modal.Footer>
                                </Modal>
                            </FormAnt>
                        </div>

                        {this.state.submittalLogList.length > 0 && <Table rowSelection={rowSelection} style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.submittalLogList} bordered scroll={{ x: 1300, scrollToFirstRowOnChange: true }} >


                            <Column title="S/N" dataIndex="index" />
                            <Column title="Submittal" dataIndex="description" />
                            <Column title="Submittal Code" dataIndex="submittalCode" />
                            <Column title="Revision" render={(value, row, index) => {

                                if (Boolean(row.revision))
                                    return "Yes";
                                else return "No";

                            }} />

                            <Column title="Department" dataIndex="department" />
                            <Column title="Discipline" dataIndex="discipline" />

                            <Column title="Submittal Type" render={(value, row, index) => {

                                if (row.type === 1)
                                    return "Deliverable";
                                else return "Other";

                            }} />

                            <Column title="Action Type" render={(value, row, index) => {

                                if (Boolean(row.actionType))
                                    return row.actionType.type;


                            }} />

                            <Column title="Workflow" render={(value, row, index) => {

                                if (Boolean(row.workflow))
                                    return row.workflow.workFlowName;


                            }} />

                            <Column title="By" render={(value, row, index) => {
                                var result = [];

                                if (Boolean(row.byUser) && row.byUser.length > 0 && this.state.userList.length > 0)
                                    row.byUser.forEach(element => {
                                        let obj = this.state.userList.find(p => p.id == element);
                                        result.push(obj.username);
                                    });

                                return result.toString();

                            }} />

                            <Column title="To" render={(value, row, index) => {

                                var result = [];
                                if (row.toType == 1) {
                                    if (Boolean(row.toUser) && row.toUser.length > 0 && this.state.userList.length > 0)
                                        row.toUser.forEach(element => {
                                            let obj = this.state.userList.find(p => p.id == element);
                                            result.push(obj.username);
                                        });

                                    return result.toString();
                                }

                                if (row.toType == 2) {
                                    if (Boolean(row.toClient) && row.toClient.length > 0 && this.state.customerList.length > 0)
                                        row.toClient.forEach(element => {
                                            let obj = this.state.customerList.find(p => p.id == element);
                                            result.push(obj.fullName);
                                        });

                                    return result.toString();
                                }


                            }} />

                            <Column title="Action" render={(value, row, index) => {

                                return this.actionTemplate(row);

                            }} />
                        </Table>}
                    </Card>

               </MenuProvider>
                {
                    !this.state.whatIsSetting && <Whatis  ></Whatis>


                }
            </div >
        
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(SubmittalLog)