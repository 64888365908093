/* eslint-disable */

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import { handleRequest } from '../../redux/config/env';
import { API_BASE } from '../../redux/config/env';
import * as Mnd from "../../partials/content/CustomComponents";
import MNDTableSettings from '../MND/MNDTableSettings';
import { Col, Form, Row, Modal as ModalReact, Button } from 'react-bootstrap';
import { InputText } from "primereact/inputtext";
import { Cascader, Checkbox } from 'antd';
import { Button as PrimeButton } from "primereact/button";
import { Button as AntdButton } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import CustomMaterialMenuDynamicMenu from '../settings/ForComponents/CustomMaterialMenuDynamicMenu';
import CustomMaterialMenuDynamicMenu2 from '../settings/ForComponents/CustomMaterialMenuDynamicMenu2';
import CustomMaterialMenuDeleteEdit from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import { Column } from "primereact/column";
import { TreeTable } from 'primereact/treetable';
import { InputTextarea } from "primereact/inputtextarea";
import { OrderList } from 'primereact/orderlist';
// import MenuConfig from '../../../_metronic/layout/MenuConfig';
import { userTokn } from "../../modules/Auth/_redux/authRedux";
import axios from "axios";



const API_BASE_SERVER = "https://dynamia.mndsoftware.com"; 
const dataList = [];
var order = 0;
const generateList = data => {
    for (let i = 0; i < data.length; i++) {
        const node = data[i];

        dataList.push({ key: node.data.id, lineage: node.data.lineage });
        if (node.children) {
            generateList(node.children);
        }
    }
};

const expandable = { expandedRowRender: record => <b>{record.disciplineName}</b> };
const title = () => 'Here is title';
var footer = () => 'Here is footer';

class DynamicMenu extends Component {

    constructor(props) {
        super(props)

        this.state = {
            hiddenDataGiris: true,
            hiddenSave: true,
            hiddenUpdate: true,
            hiddenDynamicInputsDataGiris: true,
            hiddenDynamicInputsSave: true,
            hiddenDynamicInputsUpdate: true,
            hiddenDynamicActionDataGiris: true,
            hiddenDynamicActionSave: true,
            hiddenDynamicActionUpdate: true,
            isometricData: [],
            selectedIsometric: "",
            checkedIsometric: [],
            createNewButton: "Create New",
            createNewButtonVariant: "info",
            createNewDynamicInputsButton: "Create New",
            createNewDynamicInputsButtonVariant: "info",
            createNewDynamicActionButton: "Create New",
            createNewDynamicActionButtonVariant: "info",
            dynamicMenuTreeTable: [],
            dynamicMenuCascader: [],
            parentId: [],
            IconUpload: [],
            fileList: [],
            iconPath: "",
            menuTitle: "",
            description: "",
            page: "",
            id: "",
            orderNo: "",
            translate: "",
            showInMenu:true,

            dynamicInputsId: "",
            dynamicMenuId: "",
            inputKey: "",
            inputValue: "",
            inputDescription: "",
            canBeDynamic: "",
            canBeRequired: "",
            dynamicInputsTable: [],
            translateWhatis:"",
            translateRequired:"",

            dynamicActionId: "",
            actionKey: "",
            actionValue: "",
            actionUrl: "",
            dynamicActionTable: [],

            dynamicInputsDelete: true,
            dynamicActionDelete: true,
            dynamicMenuDelete: true,

            orderItemList: [],
            modalOrder: false,

        }

        this.actionTemplate = this.actionTemplate.bind(this);
        this.actionDynamicInputsTemplate = this.actionDynamicInputsTemplate.bind(this);
        this.actionDynamicActionTemplate = this.actionDynamicActionTemplate.bind(this);
        this.renderDownloadButton = this.renderDownloadButton.bind(this);
        this.Order = this.Order.bind(this);
    }

    componentDidMount = async () => {
        this.getDynamicMenu();
        // await this.getTableDynamicInputsProperties();
        // await this.getTableDynamicActionProperties();
    }

    getTableDynamicInputsProperties = async () => {
        //MNDTableSettings.getTableDynamicInputsProperties("dynamicInputs");

        this.dynamicInputs = JSON.parse(localStorage.getItem('dynamicInputs'));
        if (this.dynamicInputs != null && this.dynamicInputs != undefined && this.dynamicInputs != "") {

            await this.setState({
                bordered: this.dynamicInputs.bordered,
                title: this.dynamicInputs.titleDrm == true ? title : undefined,
                titleDrm: this.dynamicInputs.titleDrm,
                showHeader: this.dynamicInputs.showHeader,
                footer: this.dynamicInputs.footerDrm == true ? footer : undefined,
                footerDrm: this.dynamicInputs.footerDrm,
                expandable: this.dynamicInputs.expandableDrm == true ? expandable : {},
                expandableDrm: this.dynamicInputs.expandableDrm,
                rowSelection: this.dynamicInputs.rowSelection,
                ellipsis: this.dynamicInputs.ellipsis,
                tableLayout: this.dynamicInputs.tableLayout,
                size: this.dynamicInputs.size,
                top: this.dynamicInputs.top,
                bottom: this.dynamicInputs.bottom,
                editBgColor: this.dynamicInputs.editBgColor,
                deleteBgColor: this.dynamicInputs.deleteBgColor,
                selectedNodeKey: null,
                yScroll: this.dynamicInputs.yScroll,
                xScroll: this.dynamicInputs.xScroll,
                paginationDisabled: {
                    defaultPageSize: 5, total: this.state.dynamicInputsTable.length,
                    showQuickJumper: true, showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '25', '50'], total: this.state.dynamicInputsTable.length, showQuickJumper: true,
                    position: [this.dynamicInputs.top, this.dynamicInputs.bottom]
                },
                kontrol: true,
            });
        } else {
            await this.setState({
                bordered: false,
                title: undefined,
                titleDrm: false,
                showHeader: true,
                footer: undefined,
                footerDrm: false,
                expandable: false,
                expandableDrm: false,
                rowSelection: undefined,
                ellipsis: false,
                tableLayout: undefined,
                size: 'default',
                top: 'none',
                bottom: 'bottomRight',
                editBgColor: '#fff',
                deleteBgColor: '#fff',
                selectedNodeKey: null,
                xScroll: undefined,
                paginationDisabled: false,
                kontrol: true,
            });
        }
    }

    getTableDynamicActionProperties = async () => {
        //MNDTableSettings.getTableDynamicActionProperties("dynamicAction");

        this.dynamicAction = JSON.parse(localStorage.getItem('dynamicAction'));
        if (this.dynamicAction != null && this.dynamicAction != undefined && this.dynamicAction != "") {

            await this.setState({
                bordered: this.dynamicAction.bordered,
                title: this.dynamicAction.titleDrm == true ? title : undefined,
                titleDrm: this.dynamicAction.titleDrm,
                showHeader: this.dynamicAction.showHeader,
                footer: this.dynamicAction.footerDrm == true ? footer : undefined,
                footerDrm: this.dynamicAction.footerDrm,
                expandable: this.dynamicAction.expandableDrm == true ? expandable : {},
                expandableDrm: this.dynamicAction.expandableDrm,
                rowSelection: this.dynamicAction.rowSelection,
                ellipsis: this.dynamicAction.ellipsis,
                tableLayout: this.dynamicAction.tableLayout,
                size: this.dynamicAction.size,
                top: this.dynamicAction.top,
                bottom: this.dynamicAction.bottom,
                editBgColor: this.dynamicAction.editBgColor,
                deleteBgColor: this.dynamicAction.deleteBgColor,
                selectedNodeKey: null,
                yScroll: this.dynamicAction.yScroll,
                xScroll: this.dynamicAction.xScroll,
                paginationDisabled: {
                    defaultPageSize: 5, total: this.state.dynamicActionTable.length,
                    showQuickJumper: true, showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '25', '50'], total: this.state.dynamicActionTable.length, showQuickJumper: true,
                    position: [this.dynamicAction.top, this.dynamicAction.bottom]
                },
                kontrol: true,
            });
        } else {
           await this.setState({
                bordered: false,
                title: undefined,
                titleDrm: false,
                showHeader: true,
                footer: undefined,
                footerDrm: false,
                expandable: false,
                expandableDrm: false,
                rowSelection: undefined,
                ellipsis: false,
                tableLayout: undefined,
                size: 'default',
                top: 'none',
                bottom: 'bottomRight',
                editBgColor: '#fff',
                deleteBgColor: '#fff',
                selectedNodeKey: null,
                xScroll: undefined,
                paginationDisabled: false,
                kontrol: true,
            });
        }
    }

    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            generateList(responseTree.data.treeTable);
            this.setState({
                dynamicMenuTreeTable: responseTree.data.treeTable,
                dynamicMenuCascader: responseTree.data.cascader
            });
        }
    }

    getDynamicInputs = async (dynamicMenuId) => {
        if (dynamicMenuId == null || dynamicMenuId == undefined || dynamicMenuId == "" || dynamicMenuId == 0) {
            dynamicMenuId = -1;
        }
        const obj = {
            dynamicMenuId: dynamicMenuId,
        };
        const response = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (response.data.length !== 0) {
            this.setState({
                dynamicInputsTable: response.data,
            });
        } else {
            this.setState({
                dynamicInputsTable: [],
            });
        }
    }

    getDynamicAction = async (dynamicMenuId) => {
      
        if (dynamicMenuId == null || dynamicMenuId == undefined || dynamicMenuId == "" || dynamicMenuId == 0) {
            dynamicMenuId = -1;
        }
        const obj = {
            dynamicMenuId: dynamicMenuId,
        };
        const response = await handleRequest("POST", "/DynamicAction/getResult", obj);
        if (response.data.length !== 0) {
            this.setState({
                dynamicActionTable: response.data,
            });
        } else {
            this.setState({
                dynamicActionTable: [],
            });
        }
    }

    showOrHideModalTable = async (tableTip) => {
        this.setState({
            modalTable: !this.state.modalTable,
            tableTip:tableTip,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    createNewClientButton = async () => {
        await this.resetDynamicMenu();

        if (this.state.createNewButton == "Create New") {
            this.setState({
                hiddenDataGiris: false,
                createNewButton: "Cancel",
                createNewButtonVariant: "danger",
                hiddenSave: false,
                hiddenUpdate: true,
            });
        }
        else {
            this.setState({
                hiddenDataGiris: true,
                createNewButton: "Create New",
                createNewButtonVariant: "info",
                hiddenSave: true,
                hiddenUpdate: true,
            });
        }
    }

    createNewDynamicInputsClientButton = async () => {
        await this.resetDynamicInputs();

        if (this.state.createNewDynamicInputsButton == "Create New") {
            this.setState({
                hiddenDynamicInputsDataGiris: false,
                createNewDynamicInputsButton: "Cancel",
                createNewDynamicInputsButtonVariant: "danger",
                hiddenDynamicInputsSave: false,
                hiddenDynamicInputsUpdate: true,
            });
        }
        else {
            this.setState({
                hiddenDynamicInputsDataGiris: true,
                createNewDynamicInputsButton: "Create New",
                createNewDynamicInputsButtonVariant: "info",
                hiddenDynamicInputsSave: true,
                hiddenDynamicInputsUpdate: true,
            });
        }
    }

    createNewDynamicActionClientButton = async () => {
        await this.resetDynamicAction();

        if (this.state.createNewDynamicActionButton == "Create New") {
            this.setState({
                hiddenDynamicActionDataGiris: false,
                createNewDynamicActionButton: "Cancel",
                createNewDynamicActionButtonVariant: "danger",
                hiddenDynamicActionSave: false,
                hiddenDynamicActionUpdate: true,
            });
        }
        else {
            this.setState({
                hiddenDynamicActionDataGiris: true,
                createNewDynamicActionButton: "Create New",
                createNewDynamicActionButtonVariant: "info",
                hiddenDynamicActionSave: true,
                hiddenDynamicActionUpdate: true,
            });
        }
    }

    resetDynamicMenu = () => {
        this.setState({
            id: -1,
            parentId: [],
            menuTitle: "",
            translate: "",
            description: "",
            page: "",
            showInMenu:true,
            orderNo: "",
            IconUpload: [],
            fileList: [],
            iconPath: "",
        });
    }

    resetDynamicInputs = () => {
        this.setState({
            inputKey: "",
            inputValue: "",
            inputDescription: "",
            canBeDynamic: "",
            canBeRequired: "",
            translateWhatis:"",
            translateRequired:"",
        });
    }

    resetDynamicAction = () => {
        this.setState({
            actionKey: "",
            actionValue: "",
            actionUrl: "",
        });
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        }); 
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalDeleteDynamicMenu = () => {
        this.setState({
            deleteModal: !this.state.deleteModal,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    };

    showOrHideModalDynamicInputs = async () => { 
        this.setState({
            modalDynamicInputs: !this.state.modalDynamicInputs,
            dynamicInputsId: "",
            dynamicMenuId: "",
        });

        this.resetDynamicInputs();
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    };

    showOrHideModalDynamicAction = async () => {
        this.setState({
            modalDynamicAction: !this.state.modalDynamicAction,
            dynamicActionId: "",
            dynamicMenuId: "",
        });
        this.resetDynamicAction();
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    };

    showOrHideModalOrder = async () => {
        this.setState({
            modalOrder: !this.state.modalOrder,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    };

    showOrHidePhotoModal = () => {
        this.setState({
            modalPhotoShow: !this.state.modalPhotoShow,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    };

    showReport = async (rowData) => {
        try {
            if (rowData.iconPath != null && rowData.iconPath != undefined && rowData.iconPath != "") {
                const fileRealUrl = rowData.iconPath;

                if (fileRealUrl.endsWith('.png') || fileRealUrl.endsWith('.jpg') || fileRealUrl.endsWith('.tiff') || fileRealUrl.endsWith('.jpeg')
                    || fileRealUrl.endsWith('.PNG') || fileRealUrl.endsWith('.JPG') || fileRealUrl.endsWith('.TIFF') || fileRealUrl.endsWith('.JPEG')) {

                    this.setState({
                        imageUrl: fileRealUrl,
                        modalPhotoShow: true,
                    })
                } else {
                    window.location.href = fileRealUrl;
                }
            } else {
                this.showError(<FormattedMessage id="FileErrorMessage1" defaultMessage="File cant found" />);
            }
        } catch (error) {
            this.showError(<FormattedMessage id="FileErrorMessage1" defaultMessage="File cant found" />);
        }
    };


    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    };

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    };

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };

    actionTemplate(rowData, column) {
        if (rowData.data.parentId == 0) {
            return <React.Fragment>
                <CustomMaterialMenuDynamicMenu2 row={rowData.data}
                    onDeleteRow={this.deleteDynamicMenu.bind(this)}
                    onEditRow={this.changeButtonDynamicMenu.bind(this)}
                    onOrderRow={this.Order.bind(this)}
                />
            </React.Fragment>;
        }
        return <React.Fragment>
            <CustomMaterialMenuDynamicMenu row={rowData.data}
                onDeleteRow={this.deleteDynamicMenu.bind(this)}
                onEditRow={this.changeButtonDynamicMenu.bind(this)}
                onInputsRow={this.inputsDynamicMenu.bind(this)}
                onActionRow={this.actionDynamicMenu.bind(this)}
                onOrderRow={this.Order.bind(this)}
                onSyncMenu={this.syncMenu.bind(this)}   
            />
        </React.Fragment>; 
    };

    actionDynamicInputsTemplate(rowData, column) {
        return <React.Fragment>
            <CustomMaterialMenuDeleteEdit row={rowData}
                onDeleteRow={this.deleteDynamicInputs.bind(this)}
                onEditRow={this.changeButtonDynamicInputs.bind(this)}
            />
        </React.Fragment>;
    };

    actionDynamicActionTemplate(rowData, column) {
        return <React.Fragment>
            <CustomMaterialMenuDeleteEdit row={rowData}
                onDeleteRow={this.deleteDynamicAction.bind(this)}
                onEditRow={this.changeButtonDynamicAction.bind(this)}
            />
        </React.Fragment>;
    };

    inputsDynamicMenu = async (rowData) => {
        await this.getTableDynamicInputsProperties();
        this.setState({
            modalDynamicInputs: true,
            dynamicMenuId: rowData.id,
        });
        this.getDynamicInputs(rowData.id);
    };

    syncMenu = async (rowData) => {

        var flagConfig;

        if(userTokn.authToken === undefined){
            flagConfig = { headers: { 'accessToken': "", 'userId': "" } };
        } else {
            flagConfig = { headers: { 'accessToken': userTokn.authToken.accessToken, 'userId': userTokn.authToken.id } };
        }

        if(API_BASE.includes("localhost")){
            
            const obj = {
                pageName: rowData.page, 
            };

            const obj2 = {
                page : rowData.page
            };
            var serverMenuId = -1 ; 
            const responseDynamicInputsLocal = await handleRequest("POST", "/DynamicInputs/getResult", obj);
            const responseDynamicActionsLocal = await handleRequest("POST", "/DynamicAction/getResult", obj); 
            const responseDynamicInputsServer = await axios.post(API_BASE_SERVER+"/DynamicInputs/getResult", obj,flagConfig); 
            const responseDynamicActionsServer = await axios.post(API_BASE_SERVER+"/DynamicAction/getResult", obj,flagConfig);    
            const responseServerIdObj = await axios.post(API_BASE_SERVER+"/DynamicMenu/getResult", obj2,flagConfig);   
            if(Boolean(responseServerIdObj.data)) 
            serverMenuId = responseServerIdObj.data[0].id; 

            if(Boolean(responseDynamicInputsServer) && Boolean(responseDynamicInputsServer.data)){

                let serverData = responseDynamicInputsServer.data; 
                let localData = []; 
                let list = []; 

                if(Boolean(responseDynamicInputsLocal) && Boolean(responseDynamicInputsLocal.data)){
                    localData = responseDynamicInputsLocal.data; 
                }

                serverData.forEach(element => {
                     if(localData.findIndex(p=>p.inputKey === element.inputKey) === -1) {
                        element.id=0 ;
                        element.dynamicMenuId=rowData.id; 
                        list.push(element); 
                     }
                });

            await handleRequest("POST", "/DynamicInputs/saveAll", list);  


            }

            if(Boolean(responseDynamicInputsLocal) && Boolean(responseDynamicInputsLocal.data)){ 

                let localData = responseDynamicInputsLocal.data; 
                let serverData = []; 
                let list = []; 

                if(Boolean(responseDynamicInputsServer) && Boolean(responseDynamicInputsServer.data)){
                    serverData = responseDynamicInputsServer.data; 
                }

                localData.forEach(element => {
                     if(serverData.findIndex(p=>p.inputKey === element.inputKey) === -1) {
                        element.id=0 ; 
                        element.dynamicMenuId=serverMenuId ;
                        list.push(element);  
                     }
                });

            console.log(localData);
            console.log(serverData); 
              console.log(list); 
              const response =  await axios.post(API_BASE_SERVER+"/DynamicInputs/saveAll", list,flagConfig);   
              
              console.log(response.data); 


            }

            if(Boolean(responseDynamicActionsServer) && Boolean(responseDynamicActionsServer.data)){

                let serverData = responseDynamicActionsServer.data; 
                let localData = []; 
                let list = []; 

                if(Boolean(responseDynamicActionsLocal) && Boolean(responseDynamicActionsLocal.data)){
                    localData = responseDynamicActionsLocal.data;  
                }

                serverData.forEach(element => {
                     if(localData.findIndex(p=>p.actionKey === element.actionKey) === -1) {
                        element.id=0 ;
                        element.dynamicMenuId=rowData.id; 
                        list.push(element); 
                     }
                });

            await handleRequest("POST", "/DynamicAction/saveAll", list);  


            }

            if(Boolean(responseDynamicActionsLocal) && Boolean(responseDynamicActionsLocal.data)){ 

                let localData = responseDynamicActionsLocal.data; 
                let serverData = []; 
                let list = []; 

                if(Boolean(responseDynamicActionsServer) && Boolean(responseDynamicActionsServer.data)){
                    serverData = responseDynamicActionsServer.data; 
                }

                localData.forEach(element => {
                     if(serverData.findIndex(p=>p.actionKey === element.actionKey) === -1) {
                        element.id=0 ; 
                        element.dynamicMenuId=serverMenuId ;
                        list.push(element);  
                     }
                }); 

                await axios.post(API_BASE_SERVER+"/DynamicAction/saveAll", list,flagConfig);   


            }

            this.showSuccess("Sync process completed"); 
        }  
        else this.showError("Sync action should use in localhost.")
      


    };

    actionDynamicMenu = async (rowData) => {
        await this.getTableDynamicActionProperties();
        this.setState({
            modalDynamicAction: true,
            dynamicMenuId: rowData.id,
        });
        this.getDynamicAction(rowData.id);
    };

    deleteDynamicMenu = (rowData) => {
        this.setState({
            deleteModal: true,
            dynamicMenuId: rowData.id,
            dynamicInputsDelete: true,
            dynamicActionDelete: true,
            dynamicMenuDelete: false,
        });
    };

    deleteDynamicAction = (rowData) => {
        this.setState({
            deleteModal: true,
            dynamicActionId: rowData.id,
            dynamicInputsDelete: true,
            dynamicActionDelete: false,
            dynamicMenuDelete: true,
        });
    };

    deleteDynamicInputs = (rowData) => {
        this.setState({
            deleteModal: true,
            dynamicInputsId: rowData.id,
            dynamicInputsDelete: false,
            dynamicMenuDelete: true,
            dynamicActionDelete: true,
        });
    };

    deleteDynamicMenuOK = async () => {
        const deletedDynamicMenu = {
            id: this.state.dynamicMenuId,
        };

        var response = await handleRequest("POST", "/DynamicMenu/delete", deletedDynamicMenu);

        if (response.data !== null && response.data !== undefined) {
            if (response.data === "SUCCESS") {
                this.setState({
                    deleteModal: false,
                });
                this.getDynamicMenu();
                this.showSuccess();
            }
            else {
                this.showError();
            }
        } else {
            this.showError();
        }
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    };

    deleteDynamicInputsOK = async () => {
        const deletedDynamicInputs = {
            id: this.state.dynamicInputsId,
        };

        var response = await handleRequest("POST", "/DynamicInputs/delete", deletedDynamicInputs);

        if (response.data !== null && response.data !== undefined) {
            if (response.data === "SUCCESS") {
                this.setState({
                    deleteModal: false,
                });
                this.getDynamicInputs(this.state.dynamicMenuId);
                this.showSuccess();
            }
            else {
                this.showError();
            }
        } else {
            this.showError();
        }
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    };

    deleteDynamicActionOK = async () => {
        const deletedDynamicAction = {
            id: this.state.dynamicActionId,
        };

        var response = await handleRequest("POST", "/DynamicAction/delete", deletedDynamicAction);

        if (response.data !== null && response.data !== undefined) {
            if (response.data === "SUCCESS") {
                this.setState({
                    deleteModal: false,
                });
                this.getDynamicAction(this.state.dynamicMenuId);
                this.showSuccess();
            }
            else {
                this.showError();
            }
        } else {
            this.showError();
        }
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    };

    changeButtonDynamicMenu = async (row) => {
        var deger = dataList.filter(p => p.key == row.parentId)

        if (deger != null && deger != 'undefined' && deger != '') {
            this.setState({
                parentId: deger[0].lineage
            });
        } else {
            this.setState({
                parentId: []
            });
        }

        await this.setState({
            hiddenDataGiris: false,
            hiddenSave: true,
            hiddenUpdate: false,
            createNewButton: "Cancel",
            createNewButtonVariant: "danger",

            id: row.id,
            menuTitle: row.menuTitle,
            description: row.description,
            page: row.page,            
            showInMenu:row.showInMenu,
            orderNo: row.orderNo,
            iconPath: row.iconPath,
            translate: row.translate,

        });
    };

    changeButtonDynamicInputs = async (row) => {
        await this.setState({
            hiddenDynamicInputsDataGiris: false,
            hiddenDynamicInputsSave: true,
            hiddenDynamicInputsUpdate: false,
            createNewDynamicInputsButton: "Cancel",
            createNewDynamicInputsButtonVariant: "danger",

            dynamicInputsId: row.id,
            dynamicMenuId: row.dynamicMenuId,
            inputKey: row.inputKey,
            inputValue: row.inputValue,
            inputDescription: row.inputDescription,
            canBeDynamic: row.canBeDynamic,
            canBeRequired: row.canBeRequired,
            translateWhatis: row.translateWhatis,
            translateRequired: row.translateRequired, 
        });
    };

    changeButtonDynamicAction = async (row) => {
        await this.setState({
            hiddenDynamicActionDataGiris: false,
            hiddenDynamicActionSave: true,
            hiddenDynamicActionUpdate: false,
            createNewDynamicActionButton: "Cancel",
            createNewDynamicActionButtonVariant: "danger",

            dynamicActionId: row.id,
            dynamicMenuId: row.dynamicMenuId,
            actionKey: row.actionKey,
            actionValue: row.actionValue,
            actionUrl: row.actionUrl,
        });
    };

    changeButtonCancel = () => {
        this.setState({
            hiddenSave: true,
            hiddenUpdate: true,
            createNewButton: "Create New",
            createNewButtonVariant: "info",
            hiddenDataGiris: true,
        });
        this.resetDynamicMenu();
    };

    changeDynamicInputsButtonCancel = () => {
        this.setState({
            hiddenDynamicInputsSave: true,
            hiddenDynamicInputsUpdate: true,
            hiddenDynamicInputsDataGiris: true,
            createNewDynamicInputsButton: "Create New",
            createNewDynamicInputsButtonVariant: "info",
        });
        this.resetDynamicInputs();
    };

    changeDynamicActionButtonCancel = () => {
        this.setState({
            hiddenDynamicActionSave: true,
            hiddenDynamicActionUpdate: true,
            hiddenDynamicActionDataGiris: true,
            createNewDynamicActionButton: "Create New",
            createNewDynamicActionButtonVariant: "info",
        });
        this.resetDynamicAction();
    };

    updateDone = async () => {
        if (this.state.id != null && this.state.id != undefined && this.state.id != "" && this.state.id != 0 &&
            this.state.menuTitle != null && this.state.menuTitle != undefined && this.state.menuTitle != "" &&
            this.state.description != null && this.state.description != undefined && this.state.description != "" &&
            this.state.iconPath != null && this.state.iconPath != undefined && this.state.iconPath != "") {
            var parentId = 0
            if (this.state.parentId.length > 0) {
                parentId = this.state.parentId[this.state.parentId.length - 1];
            }

            //Update de order no değiştirmiyoruz
            // var _orderNo=0;
            // var res = await handleRequest("GET", "/DynamicMenu/getOrder/" + parentId);
            // if (Boolean(res.data))
            //     _orderNo = res.data;

            const updatedDynamicMenu = {
                id: this.state.id,
                parentId: parentId,
                menuTitle: this.state.menuTitle,
                description: this.state.description,
                page: this.state.page,                
                showInMenu:this.state.showInMenu,
                orderNo: this.state.orderNo,
                iconPath: this.state.iconPath,
                translate: this.state.translate,
            }

            var response = await handleRequest("POST", "/DynamicMenu/update", updatedDynamicMenu);
            if (response.data !== null && response.data !== undefined) {
                if (response.data == "SUCCESS") {
                    this.getDynamicMenu();
                    this.createNewClientButton();
                    this.showSuccess();
                    this.resetDynamicMenu();
                }
                else {
                    this.showError();
                }
            }
            else {
                this.showError();
            }
        }
        else {
            this.showWarning();
        }
    };

    updateDynamicInputsDone = async () => {
        if (this.state.dynamicInputsId != null && this.state.dynamicInputsId != undefined && this.state.dynamicInputsId != "" &&
            this.state.inputKey != null && this.state.inputKey != undefined && this.state.inputKey != "" &&
            this.state.inputValue != null && this.state.inputValue != undefined && this.state.inputValue != "" &&
            this.state.dynamicMenuId != null && this.state.dynamicMenuId != undefined && this.state.dynamicMenuId != "" &&
            this.state.inputDescription != null && this.state.inputDescription != undefined && this.state.inputDescription != "") {

            const updatedDynamicInputs = {
                id: this.state.dynamicInputsId,
                dynamicMenuId: this.state.dynamicMenuId,
                inputKey: this.state.inputKey,
                inputValue: this.state.inputValue,
                inputDescription: this.state.inputDescription,
                canBeDynamic: this.state.canBeDynamic,
                canBeRequired: this.state.canBeRequired,
                translateWhatis: this.state.translateWhatis, 
                translateRequired: this.state.translateRequired,
            }

            var response = await handleRequest("POST", "/DynamicInputs/update", updatedDynamicInputs);
            if (response.data !== null && response.data !== undefined) {
                if (response.data == "SUCCESS") {
                    this.getDynamicInputs(this.state.dynamicMenuId);
                    this.createNewDynamicInputsClientButton();
                    this.showSuccess();
                    this.resetDynamicInputs();
                }
                else {
                    this.showError();
                }
            }
            else {
                this.showError();
            }
        }
        else {
            this.showWarning();
        }
    };

    updateDynamicActionDone = async () => {
        if (this.state.dynamicActionId != null && this.state.dynamicActionId != undefined && this.state.dynamicActionId != "" &&
            this.state.dynamicMenuId != null && this.state.dynamicMenuId != undefined && this.state.dynamicMenuId != "" &&
            this.state.actionKey != null && this.state.actionKey != undefined && this.state.actionKey != "" &&
            this.state.actionValue != null && this.state.actionValue != undefined && this.state.actionValue != "" &&
            this.state.actionUrl != null && this.state.actionUrl != undefined && this.state.actionUrl != "") {
            const updatedDynamicAction = {
                id: this.state.dynamicActionId,
                dynamicMenuId: this.state.dynamicMenuId,
                actionKey: this.state.actionKey,
                actionValue: this.state.actionValue,
                actionUrl: this.state.actionUrl,
            }

            var response = await handleRequest("POST", "/DynamicAction/update", updatedDynamicAction);
            if (response.data !== null && response.data !== undefined) {
                if (response.data == "SUCCESS") {
                    this.getDynamicAction(this.state.dynamicMenuId);
                    this.createNewDynamicActionClientButton();
                    this.showSuccess();
                    this.resetDynamicAction();
                }
                else {
                    this.showError();
                }
            }
            else {
                this.showError();
            }
        }
        else {
            this.showWarning();
        }
    };


    addDynamicMenu = async () => {
        if (this.state.menuTitle != null && this.state.menuTitle != undefined && this.state.menuTitle != "" &&
            this.state.description != null && this.state.description != undefined && this.state.description != "" &&
            this.state.iconPath != null && this.state.iconPath != undefined && this.state.iconPath != "") {

            var parentId = 0
            if (this.state.parentId.length > 0) {
                parentId = this.state.parentId[this.state.parentId.length - 1];
            }

            var _orderNo = 0;
            var res = await handleRequest("GET", "/DynamicMenu/getOrder/" + parentId);
            if (Boolean(res.data))
                _orderNo = res.data;

            const prm = {
                parentId: parentId,
                menuTitle: this.state.menuTitle,
                description: this.state.description,
                page: this.state.page,                
                showInMenu:this.state.showInMenu,
                orderNo: _orderNo,
                iconPath: this.state.iconPath,
                translate: this.state.translate,
            }

            const response = await handleRequest("POST", "/DynamicMenu/add", prm);
            if (response.data !== null && response.data !== undefined) {
                if (response.data == "SUCCESS") {
                    this.getDynamicMenu();
                    this.createNewClientButton();
                    this.showSuccess();
                    this.resetDynamicMenu();
                } else {
                    this.showError();
                }
            } else {
                this.showError();
            }
        } else {
            this.showWarning();
        }
    }

    addDynamicInputsMenu = async () => {
        if (this.state.inputKey != null && this.state.inputKey != undefined && this.state.inputKey != "" &&
            this.state.inputValue != null && this.state.inputValue != undefined && this.state.inputValue != "" &&
            this.state.dynamicMenuId != null && this.state.dynamicMenuId != undefined && this.state.dynamicMenuId != "" &&
            this.state.inputDescription != null && this.state.inputDescription != undefined && this.state.inputDescription != "") {

            const prm = {
                dynamicMenuId: this.state.dynamicMenuId,
                inputKey: this.state.inputKey,
                inputValue: this.state.inputValue,
                inputDescription: this.state.inputDescription,
                canBeDynamic: this.state.canBeDynamic,
                canBeRequired: this.state.canBeRequired,
                translateWhatis: this.state.translateWhatis,  
                translateRequired: this.state.translateRequired,
            }

            const response = await handleRequest("POST", "/DynamicInputs/add", prm);
            if (response.data !== null && response.data !== undefined) {
                if (response.data == "SUCCESS") {
                    this.getDynamicInputs(this.state.dynamicMenuId);
                    this.createNewDynamicInputsClientButton();
                    this.showSuccess();
                    this.resetDynamicInputs();
                } else {
                    this.showError();
                }
            } else {
                this.showError();
            }
        } else {
            this.showWarning();
        }
    }

    addDynamicActionMenu = async () => {
        if (this.state.dynamicMenuId != null && this.state.dynamicMenuId != undefined && this.state.dynamicMenuId != "" &&
            this.state.actionKey != null && this.state.actionKey != undefined && this.state.actionKey != "" &&
            this.state.actionValue != null && this.state.actionValue != undefined && this.state.actionValue != "" &&
            this.state.actionUrl != null && this.state.actionUrl != undefined && this.state.actionUrl != "") {
            const prm = {
                dynamicMenuId: this.state.dynamicMenuId,
                actionKey: this.state.actionKey,
                actionValue: this.state.actionValue,
                actionUrl: this.state.actionUrl,
            }

            const response = await handleRequest("POST", "/DynamicAction/add", prm);
            if (response.data !== null && response.data !== undefined) {
                if (response.data == "SUCCESS") {
                    this.getDynamicAction(this.state.dynamicMenuId);
                    this.createNewDynamicActionClientButton();
                    this.showSuccess();
                    this.resetDynamicAction();
                } else {
                    this.showError();
                }
            } else {
                this.showError();
            }
        } else {
            this.showWarning();
        }
    }


    onDynamicMenuCascaderChange = (value) => {
        this.setState({
            parentId: value
        })
    }

    renderDownloadButton(rowData, column) {
        if (rowData.data.iconPath != null && rowData.data.iconPath != undefined && rowData.data.iconPath != "") {
            return <AntdButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.showReport(rowData.data)} type="primary" shape="round" icon={<DownloadOutlined />} >
            </AntdButton>
        }
        return "";
    };

    renderCanBeDynamic(rowData, column) {
        if (rowData) {
            return <FormattedMessage id="GeneralTextYes" defaultMessage="Yes" />;
        } else {
            return <FormattedMessage id="GeneralTextNo" defaultMessage="No" />;
        }
    };

    renderCanBeRequired(rowData, column) {
        if (rowData) {
            return <FormattedMessage id="GeneralTextYes" defaultMessage="Yes" />;
        } else {
            return <FormattedMessage id="GeneralTextNo" defaultMessage="No" />;
        }
    };

    Order = async (rowData) => {
        const obj = {
            parentId: rowData.parentId == 0 ? -1 : rowData.parentId
        };
        const response = await handleRequest("POST", "/DynamicMenu/getResult", obj);

        this.setState({
            orderItemList: response.data,
            modalOrder: true
        })
    }

    template(item) {
        return (
            <div className="p-clearfix">
                <div style={{ fontSize: '12px', margin: '5px 5px 0 0' }}>{item.menuTitle}</div>
            </div>
        );
    }

    saveOrder = async () => {
        const list = this.state.orderItemList;
        list.forEach((element, i) => {
            element.orderNo = i
        });

        await handleRequest("POST", "/DynamicMenu/updateOrder", list);

        this.getDynamicMenu();
        this.resetDynamicInputs();
        this.showSuccess();
    }

    // createAuto = async () => {
    //     var items = MenuConfig.aside.items;

    //     var root = [{ key: "All", title: "All", label: "All", children: [], disabled: true }];

    //     items.forEach((element, i) => {

    //         element.disabled = true;
    //         this.getMenu(element, root[0].children, 0);


    //     });

    // }

    getMenu = async (element, list, parentId) => {

        if (element.title) {
            var obj = { key: element.title, url: Boolean(element.url) ? element.url : '', title: element.title, label: element.title, data: "", children: [], disabled: true }


            list.push(obj);

            const prm = {
                parentId: parentId,
                menuTitle: element.title,
                description: element.desc,
                page: element.page,           
              
                orderNo: order,
                iconPath: element.icon,
                translate: "",
            }

            const response = await handleRequest("POST", "/DynamicMenu/add2", prm);
            let responseId = response.data;
            order = order + 1;
            if (element.submenu) {
                element.submenu.forEach(el => {
               
                        this.getMenu(el, obj.children, responseId);
                    //  list.push(obj);
                });

            }


        }


    }


    render() {
        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };

        const columnsDynamicInputs = [
            {
                key: "key",
                title: "",
                render: this.actionDynamicInputsTemplate
            },
            {
                dataIndex: "index",
                key: "key",
                title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("dynamicInputs")} icon="pi pi-cog"></PrimeButton>,
            },
            {
                dataIndex: "inputKey",
                key: "key",
                title: <FormattedMessage id="DYNMKey" defaultMessage="Key" />,
            },
            {
                dataIndex: "inputValue",
                key: "key",
                title: <FormattedMessage id="DYNMValue" defaultMessage="Value" />,
            },
            {
                dataIndex: "inputDescription",
                key: "key",
                title: <FormattedMessage id="DYNMWhatDescription" defaultMessage="What is Description" />,
            },
            {
                dataIndex: "translateWhatis",
                key: "key",
                title: <FormattedMessage id="DYNMLanguageKeyWhatis" defaultMessage="What is Language Key" />,
            },
            {
                dataIndex: "translateRequired",
                key: "key",
                title: <FormattedMessage id="DYNMLanguageKeyRequired" defaultMessage="Required Language Key" />,
            },
            {
                dataIndex: "canBeDynamic",
                key: "key",
                title: <FormattedMessage id="DYNMCanBeDynamic" defaultMessage="Can Be Dynamic" />,
                render: this.renderCanBeDynamic
            },
            {
                dataIndex: "canBeRequired",
                key: "key",
                title: <FormattedMessage id="DYNMCanBeRequired" defaultMessage="Required" />,
                render: this.renderCanBeRequired
            },
        ];

        const tableDynamicInputsColumns = columnsDynamicInputs.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
        if (this.state.xScroll === 'fixed') {
            tableDynamicInputsColumns[0].fixed = true;
            tableDynamicInputsColumns[tableDynamicInputsColumns.length - 1].fixed = 'right';
        }

        const columnsDynamicAction = [
            {
                key: "key",
                title: "",
                render: this.actionDynamicActionTemplate
            },
            {
                dataIndex: "index",
                key: "key",
                title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("dynamicAction")} icon="pi pi-cog"></PrimeButton>,
            },
            {
                dataIndex: "actionKey",
                key: "key",
                title: <FormattedMessage id="DYNMKey" defaultMessage="Key" />,
            },
            {
                dataIndex: "actionValue",
                key: "key",
                title: <FormattedMessage id="DYNMValue" defaultMessage="Value" />,
            },
            {
                dataIndex: "actionUrl",
                key: "key",
                title: <FormattedMessage id="DYNMActionUrl" defaultMessage="Action Url" />,
            },
        ];

        const tableDynamicActionColumns = columnsDynamicAction.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
        if (this.state.xScroll === 'fixed') {
            tableDynamicActionColumns[0].fixed = true;
            tableDynamicActionColumns[tableDynamicActionColumns.length - 1].fixed = 'right';
        }

        return (
            <div>
                <Form>
                    <Row>
                        <Col xs={3}>
                            {/*<Button onClick={this.createAuto} style={{ width: '100%' }}
                                variant={this.state.createNewButtonVariant} > Create Menu Auto
                            </Button>*/}
                        </Col>
                        <Col xs={6}></Col>
                        <Col xs={3}>
                            <Button onClick={this.createNewClientButton} style={{ width: '100%' }}
                                variant={this.state.createNewButtonVariant} >{this.state.createNewButton}
                            </Button>
                        </Col>
                    </Row>

                    <div hidden={this.state.hiddenDataGiris}>
                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                            <Col sm="2"></Col>
                            <Form.Label style={{ color: 'black' }} column sm="2">
                                <FormattedMessage id="DYNMParentMenu" defaultMessage="Parent Menu" />
                            </Form.Label>
                            <Col sm="4">
                                <Cascader style={{ width: '100%' }} value={this.state.parentId}
                                    options={this.state.dynamicMenuCascader}
                                    onChange={this.onDynamicMenuCascaderChange} changeOnSelect />
                            </Col>
                        </Form.Group>
                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                            <Col sm="2"></Col>
                            <Form.Label style={{ color: 'black' }} column sm="2">
                              
                            </Form.Label>
                            <Col sm="4">
                            <Checkbox checked={this.state.showInMenu} onChange={(e) => {
                                                        this.setState({
                                                            showInMenu: e.target.checked,
                                                        });
                                                    }}><FormattedMessage id="DYNMShowInMenu" defaultMessage="Show in menu" /></Checkbox> 
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                            <Col sm="2"></Col>
                            <Form.Label style={{ color: 'black' }} column sm="2">
                                <FormattedMessage id="DYNMTitle" defaultMessage="Title" />
                            </Form.Label>
                            <Col sm="4">
                                <InputText style={{ width: '100%' }} value={this.state.menuTitle}
                                    onChange={(e) =>
                                        this.setState({
                                            menuTitle: e.target.value,
                                        })} />
                            </Col>
                        </Form.Group>


                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                            <Col sm="2"></Col>
                            <Form.Label style={{ color: 'black' }} column sm="2">
                                <FormattedMessage id="DYNMDescription" defaultMessage="Description" />
                            </Form.Label>
                            <Col sm="4">
                                <InputText style={{ width: '100%' }} value={this.state.description}
                                    onChange={(e) =>
                                        this.setState({
                                            description: e.target.value,
                                        })} />
                            </Col>
                        </Form.Group>

                        <Form.Group hidden={this.state.parentId.length == 0} style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                            <Col sm="2"></Col>
                            <Form.Label style={{ color: 'black' }} column sm="2">
                                <FormattedMessage id="DYNMPage" defaultMessage="Page" />
                            </Form.Label>
                            <Col sm="4">
                                <InputText style={{ width: '100%' }} value={this.state.page}
                                    onChange={(e) =>
                                        this.setState({
                                            page: e.target.value,
                                        })} />
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}>
                            <Col sm="2"></Col>
                            <Form.Label style={{ color: 'black' }} column sm="2">
                                <FormattedMessage id="DYNMIcon" defaultMessage="Icon" />
                            </Form.Label>
                            {/* <Col sm="1">
                    {(this.state.iconPath!=null && this.state.iconPath!=undefined && this.state.iconPath!="") ? 
                    <img src={this.state.iconPath} style={{ width: '100%' }} /> : 
                    <div style={{ width: '100%', color: 'black' }}>No Icon</div>}
                    </Col> */}
                            <Col sm="4">
                                <InputText style={{ width: '100%' }} value={this.state.iconPath}
                                    onChange={(e) =>
                                        this.setState({
                                            iconPath: e.target.value,
                                        })} />

                                {/* <Upload {...props}
                        showUploadList={true}
                        fileList={this.state.IconUpload}
                        onChange={({ fileList }) => {
                            if (fileList.length == 1) {
                            var iconPath = "";
                            if (Boolean(fileList[0].response)) {
                                iconPath = fileList[0].response.url;
                            }
                            this.setState({
                                IconUpload: fileList,
                                iconPath: iconPath,
                            });
                            }
                        }}
                        beforeUpload={(fileList) => {
                            if (this.state.IconUpload.length > 0) {
                                this.showWarning("GeneralUploadMessageWarning1");
                            return false;
                            }
                            return true;
                        }}
                        onRemove={({ fileList }) => {
                            this.setState({
                            IconUpload: [],
                            iconPath: "",
                            })
                        }
                        }>
                        <AntdButton>
                            <UploadOutlined /> <FormattedMessage id="DYNMAttachIcon" defaultMessage="Icon"/> 
                        </AntdButton>
                        </Upload>
                    */}
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                            <Col sm="2"></Col>
                            <Form.Label style={{ color: 'black' }} column sm="2">
                                <FormattedMessage id="DYNMLanguageKey" defaultMessage="Language Key" />
                            </Form.Label>
                            <Col sm="4">
                                <InputText style={{ width: '100%' }} value={this.state.translate}
                                    onChange={(e) =>
                                        this.setState({
                                            translate: e.target.value,
                                        })} />
                            </Col>
                        </Form.Group>


                    </div>

                    <div hidden={this.state.hiddenSave}>
                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} controlId="formGridDocCode">
                            <Col sm="4">
                            </Col>
                            <Col sm="4">
                                <Button onClick={this.addDynamicMenu}
                                    style={{ width: '100%' }}
                                    variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
                            </Col>
                            <Col sm="4">
                            </Col>
                        </Form.Group>
                    </div>

                    <div hidden={this.state.hiddenUpdate}>
                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} controlId="formGridDocCode">
                            <Col sm="4">
                            </Col>
                            <Col sm="2">
                                <Button onClick={this.changeButtonCancel} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></Button>
                            </Col>
                            <Col sm="2">
                                <Button onClick={this.updateDone} style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></Button>
                            </Col>
                            <Col sm="4">
                            </Col>
                        </Form.Group>
                    </div>
                    <br />
                    <br />
                    {/* {this.state.kontrol===true && <Mnd.MNDTable
                            state={this.state}
                            columns={tableColumns} dataSource={this.state.dynamicMenuTreeTable}
                            pagination={this.state.paginationDisabled} 
                            expandable={this.state.expandable}
                            footer={this.state.footer}
                            title={this.state.title}
                            scroll= { this.state.xScroll=="scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                        />} */}

                    <TreeTable value={this.state.dynamicMenuTreeTable}>
                        <Column field="menuTitle" header={<FormattedMessage id="DYNMTitle" defaultMessage="Title" />} expander filter sortable />
                        <Column field="description" header={<FormattedMessage id="DYNMDescription" defaultMessage="Description" />} filter sortable />
                        <Column field="page" header={<FormattedMessage id="DYNMPage" defaultMessage="Page" />} filter sortable />
                        <Column field="iconPath" header={<FormattedMessage id="DYNMIcon" defaultMessage="Icon" />} filter sortable />
                        <Column field="translate" header={<FormattedMessage id="DYNMLanguageKey" defaultMessage="Language Key" />} filter sortable />
                        {/* <Column body={this.renderDownloadButton} header={<FormattedMessage id="DYNMIcon" />} filter ></Column> */}
                        <Column header="" body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />
                    </TreeTable>




                    <ModalReact
                        show={this.state.modalTable}
                        onHide={this.showOrHideModalTable}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered size="lg">

                        <ModalReact.Header closeButton>
                            <ModalReact.Title id="contained-modal-title-vcenter">
                                <Row >
                                    <Col sm="12">
                                        <FormattedMessage id="GeneralTableSettings" defaultMessage="Table Settings" />
                                    </Col>
                                </Row>
                            </ModalReact.Title>
                        </ModalReact.Header>

                        <ModalReact.Body>
                            <Row >
                                <Col sm="12">
                                    <MNDTableSettings id={this.state.tableTip} />
                                </Col>
                            </Row>
                        </ModalReact.Body>
                    </ModalReact>

                    <ModalReact
                        show={this.state.modalMessage}
                        onHide={this.showOrHideModalMessage}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>

                        <ModalReact.Header closeButton>
                            <ModalReact.Title id="contained-modal-title-vcenter">
                                <Row >
                                    <Col sm="12">
                                        {this.state.messageTip == "Success" &&
                                            <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                        {this.state.messageTip == "Warning" &&
                                            <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                        {this.state.messageTip == "Error" &&
                                            <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                    </Col>
                                </Row>
                            </ModalReact.Title>
                        </ModalReact.Header>

                        <ModalReact.Body>
                            <Row >
                                <Col sm="12">
                                    {this.state.message}
                                </Col>
                            </Row>
                        </ModalReact.Body>
                        <ModalReact.Footer>
                            <Button variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
                        </ModalReact.Footer>
                    </ModalReact>

                    <ModalReact
                        show={this.state.deleteModal}
                        onHide={this.showOrHideModalDeleteDynamicMenu}
                        aria-labelledby="contained-modal-title-vcenter"
                        backdrop="static"
                        centered
                    >
                        <ModalReact.Header closeButton>
                            <ModalReact.Title id="contained-modal-title-vcenter">
                                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                            </ModalReact.Title>
                        </ModalReact.Header>
                        <ModalReact.Body>
                            <p style={{ textAlign: "center", color: "black" }}>
                                <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
                            </p>
                            <p style={{ textAlign: "center", color: "black" }}>
                                <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
                            </p>
                        </ModalReact.Body>
                        <ModalReact.Footer>
                            <Button variant="secondary" onClick={this.showOrHideModalDeleteDynamicMenu}>
                                <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                            </Button>
                            <div hidden={this.state.dynamicMenuDelete}>
                                <Button variant="danger" onClick={this.deleteDynamicMenuOK}>
                                    <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                                </Button>
                            </div>

                            <div hidden={this.state.dynamicInputsDelete}>
                                <Button variant="danger" onClick={this.deleteDynamicInputsOK}>
                                    <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                                </Button>
                            </div>

                            <div hidden={this.state.dynamicActionDelete}>
                                <Button variant="danger" onClick={this.deleteDynamicActionOK}>
                                    <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                                </Button>
                            </div>
                        </ModalReact.Footer>
                    </ModalReact>

                    <ModalReact
                        show={this.state.modalPhotoShow}
                        onHide={this.showOrHidePhotoModal}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered >
                        <ModalReact.Header closeButton>
                            <ModalReact.Title id="contained-modal-title-vcenter"><FormattedMessage id="ModalPhotoText" defaultMessage="Photo" /></ModalReact.Title>
                        </ModalReact.Header>
                        <ModalReact.Body>
                            {this.state.imageUrl !== "" ? (
                                <img
                                    src={this.state.imageUrl}
                                    alt="avatar"
                                    style={{ width: "100%" }}
                                />
                            ) : (
                                    <p> <FormattedMessage id="UploadedPhotoMessage1" defaultMessage="Photo has not been uploaded." /></p>
                                )}
                        </ModalReact.Body>
                        <ModalReact.Footer>
                            <AntdButton variant="info" onClick={this.showOrHidePhotoModal}>
                                <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                            </AntdButton>
                        </ModalReact.Footer>
                    </ModalReact>

                    <ModalReact
                        show={this.state.modalDynamicInputs}
                        onHide={this.showOrHideModalDynamicInputs}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered size="xl">

                        <ModalReact.Header closeButton>
                            <ModalReact.Title id="contained-modal-title-vcenter">
                                <Row >
                                    <Col sm="12">
                                        <FormattedMessage id="DYNMDynamicInputsSettings" defaultMessage="Dynamic Inputs Settings" />
                                    </Col>
                                </Row>
                            </ModalReact.Title>
                        </ModalReact.Header>

                        <ModalReact.Body>
                            <Row >
                                <Col sm="12">
                                    <Form>
                                        <Row>
                                            <Col xs={8}></Col>
                                            <Col xs={3}>
                                                <Button onClick={this.createNewDynamicInputsClientButton} style={{ width: '100%' }}
                                                    variant={this.state.createNewDynamicInputsButtonVariant} >{this.state.createNewDynamicInputsButton}
                                                </Button>
                                            </Col>
                                            <Col xs={1}>
                                                <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable("dynamicInputs")}
                                                    icon="pi pi-cog"></PrimeButton>
                                            </Col>
                                        </Row>

                                        <div hidden={this.state.hiddenDynamicInputsDataGiris}>
                                            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                                <Col sm="2"></Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    <FormattedMessage id="DYNMKey" defaultMessage="Key" />
                                                </Form.Label>
                                                <Col sm="4">
                                                    <InputText style={{ width: '100%' }} value={this.state.inputKey}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                inputKey: e.target.value,
                                                            })} />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                                <Col sm="2"></Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    <FormattedMessage id="DYNMValue" defaultMessage="Value" />
                                                </Form.Label>
                                                <Col sm="4">
                                                    <InputText style={{ width: '100%' }} value={this.state.inputValue}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                inputValue: e.target.value,
                                                            })} />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                                <Col sm="2"></Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    <FormattedMessage id="DYNMWhatDescription" defaultMessage="What is Description" />
                                                </Form.Label>
                                                <Col sm="4">
                                                    <InputTextarea style={{ width: '100%' }}
                                                        onChange={(e) => this.setState({ inputDescription: e.target.value })}
                                                        value={this.state.inputDescription} rows={3} />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                                <Col sm="2"></Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    <FormattedMessage id="DYNMLanguageKeyWhatis" defaultMessage="What is Language Key" />
                                                </Form.Label>
                                                <Col sm="4">
                                                    <InputText style={{ width: '100%' }} value={this.state.translateWhatis}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                translateWhatis: e.target.value,
                                                        })} />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                                <Col sm="2"></Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    <FormattedMessage id="DYNMLanguageKeyRequired" defaultMessage="Required Language Key" />
                                                </Form.Label>
                                                <Col sm="4">
                                                    <InputText style={{ width: '100%' }} value={this.state.translateRequired}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                translateRequired: e.target.value,
                                                        })} />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                                <Col sm="4"></Col>
                                                <Col sm="4">
                                                    <Checkbox checked={this.state.canBeDynamic} onChange={(e) => {
                                                        this.setState({
                                                            canBeDynamic: e.target.checked,
                                                        });
                                                    }}><FormattedMessage id="DYNMCanBeDynamic" defaultMessage="Can Be Dynamic" /></Checkbox>
                                                </Col>
                                            </Form.Group>

                                            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                                <Col sm="4"></Col>
                                                <Col sm="4">
                                                    <Checkbox checked={this.state.canBeRequired} onChange={(e) => {
                                                        this.setState({
                                                            canBeRequired: e.target.checked,
                                                        });
                                                    }}><FormattedMessage id="DYNMCanBeRequired" defaultMessage="Required" /></Checkbox>
                                                </Col>
                                            </Form.Group>
                                        </div>

                                        <div hidden={this.state.hiddenDynamicInputsSave}>
                                            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} controlId="formGridDocCode">
                                                <Col sm="4">
                                                </Col>
                                                <Col sm="4">
                                                    <Button onClick={this.addDynamicInputsMenu}
                                                        style={{ width: '100%' }}
                                                        variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
                                                </Col>
                                                <Col sm="4">
                                                </Col>
                                            </Form.Group>
                                        </div>

                                        <div hidden={this.state.hiddenDynamicInputsUpdate}>
                                            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} controlId="formGridDocCode">
                                                <Col sm="4">
                                                </Col>
                                                <Col sm="2">
                                                    <Button onClick={this.changeDynamicInputsButtonCancel} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></Button>
                                                </Col>
                                                <Col sm="2">
                                                    <Button onClick={this.updateDynamicInputsDone} style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></Button>
                                                </Col>
                                                <Col sm="4">
                                                </Col>
                                            </Form.Group>
                                        </div>
                                        <br />
                                        <br />
                                        {this.state.kontrol === true && <Mnd.MNDTable
                                            state={this.state}
                                            columns={tableDynamicInputsColumns} dataSource={this.state.dynamicInputsTable}
                                            pagination={this.state.paginationDisabled}
                                            expandable={this.state.expandable}
                                            footer={this.state.footer}
                                            title={this.state.title}
                                            scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                                        />}
                                    </Form>
                                </Col>
                            </Row>
                        </ModalReact.Body>
                        <ModalReact.Footer>
                            <Button
                                variant="secondary"
                                onClick={this.showOrHideModalDynamicInputs} >
                                <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                            </Button>
                        </ModalReact.Footer>
                    </ModalReact>

                    <ModalReact
                        show={this.state.modalDynamicAction}
                        onHide={this.showOrHideModalDynamicAction}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered size="xl">

                        <ModalReact.Header closeButton>
                            <ModalReact.Title id="contained-modal-title-vcenter">
                                <Row >
                                    <Col sm="12">
                                        <FormattedMessage id="DYNMDynamicActionSettings" defaultMessage="Dynamic Action Settings" />
                                    </Col>
                                </Row>
                            </ModalReact.Title>
                        </ModalReact.Header>

                        <ModalReact.Body>
                            <Row >
                                <Col sm="12">
                                    <Form>
                                        <Row>
                                            <Col xs={8}></Col>
                                            <Col xs={3}>
                                                <Button onClick={this.createNewDynamicActionClientButton} style={{ width: '100%' }}
                                                    variant={this.state.createNewDynamicActionButtonVariant} >{this.state.createNewDynamicActionButton}
                                                </Button>
                                            </Col>
                                            <Col xs={1}>
                                                <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable("dynamicAction")}
                                                    icon="pi pi-cog"></PrimeButton>
                                            </Col>
                                        </Row>

                                        <div hidden={this.state.hiddenDynamicActionDataGiris}>
                                            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                                <Col sm="2"></Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    <FormattedMessage id="DYNMKey" defaultMessage="Key" />
                                                </Form.Label>
                                                <Col sm="4">
                                                    <InputText style={{ width: '100%' }} value={this.state.actionKey}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                actionKey: e.target.value,
                                                            })} />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                                <Col sm="2"></Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    <FormattedMessage id="DYNMValue" defaultMessage="Value" />
                                                </Form.Label>
                                                <Col sm="4">
                                                    <InputText style={{ width: '100%' }} value={this.state.actionValue}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                actionValue: e.target.value,
                                                            })} />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                                                <Col sm="2"></Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    <FormattedMessage id="DYNMActionUrl" defaultMessage="Action Url" />
                                                </Form.Label>
                                                <Col sm="4">
                                                    <InputText style={{ width: '100%' }} value={this.state.actionUrl}
                                                        onChange={(e) =>
                                                            this.setState({
                                                                actionUrl: e.target.value,
                                                            })} />
                                                </Col>
                                            </Form.Group>
                                        </div>

                                        <div hidden={this.state.hiddenDynamicActionSave}>
                                            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} controlId="formGridDocCode">
                                                <Col sm="4">
                                                </Col>
                                                <Col sm="4">
                                                    <Button onClick={this.addDynamicActionMenu}
                                                        style={{ width: '100%' }}
                                                        variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
                                                </Col>
                                                <Col sm="4">
                                                </Col>
                                            </Form.Group>
                                        </div>

                                        <div hidden={this.state.hiddenDynamicActionUpdate}>
                                            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} controlId="formGridDocCode">
                                                <Col sm="4">
                                                </Col>
                                                <Col sm="2">
                                                    <Button onClick={this.changeDynamicActionButtonCancel} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel"/></Button>
                                                </Col>
                                                <Col sm="2">
                                                    <Button onClick={this.updateDynamicActionDone} style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update"/></Button>
                                                </Col>
                                                <Col sm="4">
                                                </Col>
                                            </Form.Group>
                                        </div>
                                        <br />
                                        <br />
                                        {this.state.kontrol === true && <Mnd.MNDTable
                                            state={this.state}
                                            columns={tableDynamicActionColumns} dataSource={this.state.dynamicActionTable}
                                            pagination={this.state.paginationDisabled}
                                            expandable={this.state.expandable}
                                            footer={this.state.footer}
                                            title={this.state.title}
                                            scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                                        />}
                                    </Form>
                                </Col>
                            </Row>
                        </ModalReact.Body>
                        <ModalReact.Footer>
                            <Button
                                variant="secondary"
                                onClick={this.showOrHideModalDynamicAction} >
                                <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                            </Button>
                        </ModalReact.Footer>
                    </ModalReact>

                    <ModalReact
                        show={this.state.modalOrder}
                        onHide={this.showOrHideModalOrder}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter" >

                        <ModalReact.Header closeButton>
                            <ModalReact.Title id="contained-modal-title-vcenter">
                                <Row >
                                    <Col sm="12">
                                        <FormattedMessage id="DYNMOrderSettings" defaultMessage="Order Settings" />
                                    </Col>
                                </Row>
                            </ModalReact.Title>
                        </ModalReact.Header>

                        <ModalReact.Body>
                            <Row >
                                <Col sm="12">
                                    <Form>
                                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} controlId="formGridDocCode">
                                            <Col sm="12">
                                                {this.state.orderItemList.length > 0 &&
                                                    <OrderList value={this.state.orderItemList} responsive={true}
                                                        itemTemplate={this.template}
                                                        onChange={(e) => this.setState({ orderItemList: e.value })}></OrderList>}
                                            </Col>
                                        </Form.Group>
                                    </Form>
                                </Col>
                            </Row>
                        </ModalReact.Body>
                        <ModalReact.Footer>
                            <Button
                                variant="secondary"
                                onClick={this.showOrHideModalOrder} >
                                <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                            </Button>
                            <Button variant="success" onClick={this.saveOrder}>
                                <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                            </Button>
                        </ModalReact.Footer>
                    </ModalReact>

                </Form>
            </div>
        )
    }
}

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(DynamicMenu)
