/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import * as Mnd from "../../partials/content/CustomComponents";
import MNDTableSettings from '../MND/MNDTableSettings';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button as PrimeButton } from "primereact/button";
import { Card, Button } from 'react-bootstrap';
import { Container, Col, Form, Row, Modal } from 'react-bootstrap';
import "react-toggle/style.css";
import { handleRequest } from '../../redux/config/env';
import "../../pages/settings/css/DataTable.scss";
import $ from 'jquery';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import { Checkbox as AntCheckbox } from 'antd';

const expandable = { expandedRowRender: record => <b>{record.id}</b> };
const title = () => 'Here is title';
var footer = () => 'Here is footer';

class MaintenancePlan extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hidden: true,
      hiddenSave: false,
      hiddenUpdate: true,
      tablehidden: false,
      createNewButton: "Create New",
      createNewButtonActivity: "Create New",
      hiddenAmendDocument: false,
      lastButton: "Save",
      createNewButtonVariant: "info",
      createNewButtonActivityVariant: "info",
      Activities: [],
      projectPhaseActivityCode: "",
      taskActivity: [],
      referenceProcedure: "",
      record: "",
      remarks: "",
      itemsLegends: [],
      itemsProcedureRecords: [],

      discipline: "",
      disciplineVal: 0,

      itpname: "",
      itpcode: "",
      approvaldate: "",
      approvalDateVal: null,
      revno: 0,

      legendAddItems: false,
      activityAddItems: false,
      datatableValue: [],

      documentLogid: -1,

      itemNo: "",
      maintenancePlanTreeTable: [],
      referenceProcedureAck: "",
      recordAck: "",
      id: -1,

      popup: false,
      messageTip: "",

      itemGroup: false,
      hiddenItemGroup: false,
      redirect: false,
      selectMaintenancePlan: [],
      MaintenancePlanTreeTableKayit: 0,
    }

    this.actionTemplate = this.actionTemplate.bind(this);
  }

  componentDidMount = async () => {
    var deger = 0;
    if (this.props.location != null && this.props.location != undefined && this.props.location != "") {
      deger = this.props.location.pathname.split('/')[2];
      this.setState({
        popup: false
      });
    } else if (this.props.id != null && this.props.id != 0 && this.props.id != "") {
      deger = this.props.id;
      this.setState({
        popup: true
      });
    } else {
      deger = -1;
      this.setState({
        popup: true
      });
    }
    await this.setState({
      documentLogid: deger,
      hiddenSave: false,
      hiddenUpdate: true,
      hiddenAmendDocument: false,
    });

    // await this.getMaintenancePlan();

    this.deleteMaintenancePlanTemp();
    this.getMaintenancePlan(deger);
    this.createNewClientButton();
    this.getDocumentLog(deger)
    this.getProcedureRecord();
    this.getLegend();
    this.getTableProperties();

    $(".p-filter-column").on("change paste keyup", function () {

      $("tr[id^='idd']").remove();
    });

    $(".p-sortable-column").on("click", function () {

      $("tr[id^='idd']").remove();
    });
  }

  deleteMaintenancePlanTemp = async () => {
    await handleRequest("GET", "/MaintenancePlan/deleteTemp");
  };

  getMaintenancePlan = async (deger) => {
    const newObj = {
      documentLogid: deger
    }
    const responseTree = await handleRequest("POST", "/MaintenancePlan/getTreeMaintenancePlan", newObj);
    if (responseTree.data.length !== 0 && responseTree.data.treeTable.length !== 0) {

      responseTree.data.treeTable.forEach(record => {
        this.deleteChildren(record);
      });
      this.setState({
        maintenancePlanTreeTable: responseTree.data.treeTable,
        MaintenancePlanTreeTableKayit: responseTree.data.treeTable[responseTree.data.treeTable.length - 1].data.key,
      });
    } else {
      this.setState({
        maintenancePlanTreeTable: [],
        MaintenancePlanTreeTableKayit: 0,
      });
    }
  }

  getTableProperties = async () => {
    this.MaintenancePlan = JSON.parse(localStorage.getItem('MaintenancePlan'));
    if (this.MaintenancePlan != null && this.MaintenancePlan != undefined && this.MaintenancePlan != "") {

      await this.setState({
        bordered: this.MaintenancePlan.bordered,
        title: this.MaintenancePlan.titleDrm == true ? title : undefined,
        titleDrm: this.MaintenancePlan.titleDrm,
        showHeader: this.MaintenancePlan.showHeader,
        footer: this.MaintenancePlan.footerDrm == true ? footer : undefined,
        footerDrm: this.MaintenancePlan.footerDrm,
        expandable: this.MaintenancePlan.expandableDrm == true ? expandable : {},
        expandableDrm: this.MaintenancePlan.expandableDrm,
        rowSelection: this.MaintenancePlan.rowSelection,
        ellipsis: this.MaintenancePlan.ellipsis,
        tableLayout: this.MaintenancePlan.tableLayout,
        size: this.MaintenancePlan.size,
        top: this.MaintenancePlan.top,
        bottom: this.MaintenancePlan.bottom,
        editBgColor: this.MaintenancePlan.editBgColor,
        deleteBgColor: this.MaintenancePlan.deleteBgColor,
        selectedNodeKey: null,
        yScroll: this.MaintenancePlan.yScroll,
        xScroll: this.MaintenancePlan.xScroll,
        paginationDisabled: {
          defaultPageSize: 5, total: this.state.maintenancePlanTreeTable.length,
          showQuickJumper: true, showSizeChanger: true,
          pageSizeOptions: ['5', '10', '25', '50'], total: this.state.maintenancePlanTreeTable.length, showQuickJumper: true,
          position: [this.MaintenancePlan.top, this.MaintenancePlan.bottom]
        },
        kontrol: true,
      });
    } else {
      await this.setState({
        bordered: false,
        title: undefined,
        titleDrm: false,
        showHeader: true,
        footer: undefined,
        footerDrm: false,
        expandable: false,
        expandableDrm: false,
        rowSelection: undefined,
        ellipsis: false,
        tableLayout: undefined,
        size: 'default',
        top: 'none',
        bottom: 'bottomRight',
        editBgColor: '#fff',
        deleteBgColor: '#fff',
        selectedNodeKey: null,
        xScroll: undefined,
        paginationDisabled: false,
        kontrol: true,
      });
    }
  }

  deleteChildren = (element) => {
    if (Boolean(element.children) && element.children.length > 0) {
      element.children.forEach(el => {
        this.deleteChildren(el);
      });
    } else {
      delete element["children"];
    }
  }

  getDocumentLog = async (deger) => {
    const newObj = {
      id: deger
    }
    const response = await handleRequest("POST", "/DocumentLog/getDocumentLogResult", newObj);
    if (response.data.length !== 0) {
      var revno = 0;
      if (this.state.hiddenAmendDocument == true) {
        if (this.state.popup == false) {
          revno = response.data[0].revno;
        } else {
          revno = response.data[0].revno + 1;
        }
      } else {
        revno = response.data[0].revno;
      }

      this.setState({
        revno: revno,
      });
    }
    else {
      this.setState({
        revno: 0,
      })
    }
  }

  getLegend = async () => {
    const response = await handleRequest("GET", "/IncpectionLevel/getAll");
    if (response.data.length !== 0) {
      this.setState({ itemsLegends: response.data });
    } else { this.setState({ itemsLegends: [] }); }
  }

  getProcedureRecord = async () => {
    const response = await handleRequest("GET", "/Itp/getProcedureRecord");
    if (response.data.length !== 0) {
      this.setState({ itemsProcedureRecords: response.data });
    } else { this.setState({ itemsProcedureRecords: [] }); }
  }

  showSuccess(msg) {
    if (!msg) {
      msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Success"
    });
  }

  showError(msg) {
    if (!msg) {
      msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Error"
    });
  }

  showWarning(msg) {
    if (!msg) {
      msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Warning"
    });
  };

  showOrHideModalMessage = () => {
    this.setState({
      modalMessage: !this.state.modalMessage,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);

    if (this.state.redirect) {
      this.setState({
        redirect: false,
      });
      window.location.href = "/DocumentLog";
    }
  }

  handleChange = e => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleQProcedureChange = e => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
      referenceProcedureAck: e.target.selectedOptions[0].text
    });
  };

  handleQRecordChange = e => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
      recordAck: e.target.selectedOptions[0].text
    });
  };

  resetInputs = () => {
    this.setState({
      Activities: [],
      selectMaintenancePlan: [],
      itemNo: "",
      itemGroup: false,
      taskActivity: [],
      projectPhaseActivityCode: "",
      referenceProcedure: "",
      record: "",
      remarks: "",
      referenceProcedureAck: "",
      recordAck: "",
    });
  }

  createNewClientButton = async () => {
    await this.resetInputs();

    if (this.state.createNewButton == "Create New") {
      this.setState({
        hidden: false,
        createNewButton: "Cancel",
        lastButton: "Save",
        createNewButtonVariant: "danger"
      });
      this.refreshAccordions();
    }
    else {
      this.setState({
        hidden: true,
        createNewButton: "Create New",
        createNewButtonActivity: "Create New",
        activityAddItems: false,
        lastButton: "Save",
        createNewButtonVariant: "info",
        createNewButtonActivityVariant: "info"

      });
      this.refreshAccordions();
    }
  }

  refreshAccordions = () => {
    //document.getElementById('activityAcord').className = "collapse show";
  }

  onEditClick(e) {
    if (e.target.value === undefined)
      e.stopPropagation();
  }

  addMaintenancePlan = async () => {
    if (this.state.itemNo != '') {
      this.addActivityItem();

      await this.setState({
        itemNo: "",
        projectPhaseActivityCode: "",
        taskActivity: [],
        referenceProcedure: "",
        record: "",
        remarks: "",
        referenceProcedureAck: "",
        recordAck: "",
      });
    } else {
      this.showWarning();
    }
  }

  addActivityItem = async () => {
    await this.setState(state => ({
      MaintenancePlanTreeTableKayit: this.state.MaintenancePlanTreeTableKayit + 1,
      Activities: [...state.Activities, {
        key: this.state.MaintenancePlanTreeTableKayit,
        itemNo: this.state.itemNo,
        itemGroup: this.state.itemGroup,
        taskActivity: this.state.taskActivity,
        projectPhaseActivityCode: this.state.projectPhaseActivityCode,
        referenceProcedure: this.state.referenceProcedure,
        record: this.state.record,
        remarks: this.state.remarks,
        referenceProcedureAck: this.state.referenceProcedureAck,
        recordAck: this.state.recordAck,
        documentLogid: this.state.documentLogid,
        revno: this.state.revno
      }]
    }));
    this.setState(prevState => ({
      maintenancePlanTreeTable: [...prevState.maintenancePlanTreeTable, { key: this.state.MaintenancePlanTreeTableKayit, data: this.state.Activities[this.state.Activities.length - 1] }]
    }));
  }

  addActivityShow = () => {
    if (this.state.createNewButtonActivity == "Create New") {
      this.setState({
        createNewButtonActivity: "Cancel",
        createNewButtonActivityVariant: "danger",
        activityAddItems: true,
      });
    } else {
      this.setState({
        createNewButtonActivity: "Create New",
        createNewButtonActivityVariant: "info",
        activityAddItems: false,
      });
    }
  }

  saveMaintenancePlan = async () => {
    var maintenancePlans = [];
    this.state.maintenancePlanTreeTable.forEach(element => {
      if (element.data != null && element.data != undefined) {
        maintenancePlans.push(element.data);
      }
    });
    const response = await handleRequest("POST", "/MaintenancePlan/saveAll", maintenancePlans);
    if (response.data !== null && response.data !== undefined) {
      if (response.data == "SUCCESS") {
        if (this.state.popup == false) {
          this.setState({
            redirect: true,
          });
        }
        this.createNewClientButton();
        await this.setState({
          hiddenSave: false,
          hiddenUpdate: true,
          hiddenAmendDocument: false,
        });
        // this.getMaintenancePlan();
        this.showSuccess();
      } else {
        this.showError();
      }
    } else {
      this.showError();
    }
  }

  setDateFormat(d) { //01/02/2011 ->2011-02-01
    if (d != null) {
      var dt = new Date(d);
      return ('' + dt.getFullYear().toString().padStart(4, '0') + '-' + (dt.getMonth() + 1).toString().padStart(2, '0') + '-' + dt.getDate().toString().padStart(2, '0'));
    } else return '';
  }

  actionTemplate(rowData, column) {
    return <React.Fragment>
      <CustomMaterialMenu row={rowData}
        onDeleteRow={this.changeButtonDeleteModal.bind(this)}
        onEditRow={this.changeButtonUpdate.bind(this)} />
    </React.Fragment>;
  };

  showOrHideModalDelete = () => {
    this.setState({
      deleteModal: !this.state.deleteModal
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  changeButtonDeleteModal = (row) => {
    this.setState({
      deleteModal: true,
      selectMaintenancePlan: row
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  deleteMaintenancePlanOK = async () => {
    if (this.state.selectMaintenancePlan != null && this.state.selectMaintenancePlan != undefined && this.state.selectMaintenancePlan != "" && this.state.selectMaintenancePlan.key > 0) {
      var MaintenancePlan = this.state.maintenancePlanTreeTable.filter(p => p.key != this.state.selectMaintenancePlan.key);

      this.setState({
        maintenancePlanTreeTable: MaintenancePlan,
        deleteModal: false,
      });
    } else {
      this.showWarning();
    }
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  changeButtonUpdate = (rowData) => {
    if (rowData.data.itemGroup) {
      this.setState({
        hiddenItemGroup: true,
      });
    } else {
      this.setState({
        hiddenItemGroup: false,
      })
    }
    this.setState({
      hidden: false,
      hiddenUpdate: false,
      hiddenSave: true,

      selectMaintenancePlan: rowData.data,
      itemNo: rowData.data.itemNo,
      itemGroup: rowData.data.itemGroup,
      taskActivity: rowData.data.taskActivity,
      projectPhaseActivityCode: rowData.data.projectPhaseActivityCode,
      referenceProcedure: rowData.data.referenceProcedure,
      record: rowData.data.record,
      remarks: rowData.data.remarks,
      referenceProcedureAck: rowData.data.referenceProcedureAck,
      recordAck: rowData.data.recordAck,
      documentLogid: rowData.data.documentLogid,
      revno: rowData.revno,

      createNewButtonActivity: "Cancel",
      createNewButtonActivityVariant: "danger",
      activityAddItems: true,
    });
  };

  changeButtonCancel = () => {
    this.setState({
      hidden: false,
      hiddenUpdate: true,
      hiddenSave: true,
      createNewButtonActivity: "Create New",
      createNewButtonActivityVariant: "info",
      activityAddItems: false,
    });
  };

  updateDone = async () => {
    // if (this.state.itpname.trim() != "" && this.state.documentLogid != 0) {
    var MaintenancePlan = this.state.maintenancePlanTreeTable.find(p => p.key == this.state.selectMaintenancePlan.key);

    if (MaintenancePlan != null && MaintenancePlan != undefined && MaintenancePlan.data != null && MaintenancePlan.data != undefined) {
      MaintenancePlan.data.itemNo = this.state.itemNo;
      MaintenancePlan.data.itemGroup = this.state.itemGroup;
      MaintenancePlan.data.taskActivity = this.state.taskActivity;
      MaintenancePlan.data.projectPhaseActivityCode = this.state.projectPhaseActivityCode;
      MaintenancePlan.data.referenceProcedure = this.state.referenceProcedure;
      MaintenancePlan.data.record = this.state.record;
      MaintenancePlan.data.remarks = this.state.remarks;
      MaintenancePlan.data.referenceProcedureAck = this.state.referenceProcedureAck;
      MaintenancePlan.data.recordAck = this.state.recordAck;
      MaintenancePlan.data.documentLogid = this.state.documentLogid;
      MaintenancePlan.data.revno = this.state.revno;

      this.setState({
        hidden: false,
        hiddenUpdate: true,
        hiddenSave: true,
        createNewButtonActivity: "Create New",
        createNewButtonActivityVariant: "info",
        activityAddItems: false,
      });
      this.resetInputs();
    } else {
      this.showWarning();
    }

    // var response = await handleRequest("POST", "/MaintenancePlan/update", updatedMaintenancePlanLog);
    // if (response.data !== null && response.data !== undefined) {
    //   if (response.data == "SUCCESS") {
    //     this.createNewClientButton();
    //     this.getMaintenancePlan();
    //     this.showSuccess();
    //   }
    //   else {
    //     this.showError();
    //   }
    // }
    // else {
    //   this.showError();
    // }

    // }
    // else {
    //   this.showWarning();
    // }
  };

  showOrHideModalTable = async (tableTip) => {
    this.setState({
      modalTable: !this.state.modalTable,
      tableTip: tableTip,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  render() {
    const columns = [
      {
        key: "key",
        title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("MaintenancePlan")} icon="pi pi-cog"></PrimeButton>,
        render: this.actionTemplate
      },
      {
        key: "key",
        title: <FormattedMessage id="MaintenancePlanItemNo" defaultMessage="Item No" />,
        render: (record) => {
          console.log("XXX", record);
          if (record != null && record != undefined && record.data != null && record.data != undefined) {
            return record.data.itemNo;
          }
          return "";
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="MaintenancePlanActivityCode" defaultMessage="Project Phase , Activity Code" />,
        render: (record) => {
          if (record != null && record != undefined && record.data != null && record.data != undefined) {
            return record.data.projectPhaseActivityCode;
          }
          return "";
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="MaintenancePlanActivityCode" defaultMessage="Task / Activity" />,
        render: (record) => {
          if (record != null && record != undefined && record.data != null && record.data != undefined) {
            return record.data.taskActivity;
          }
          return "";
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="MaintenancePlanReferenceProcedure" defaultMessage="Reference Procedure" />,
        render: (record) => {
          if (record != null && record != undefined && record.data != null && record.data != undefined) {
            return record.data.referenceProcedureAck;
          }
          return "";
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="MaintenancePlanRecord" defaultMessage="Record" />,
        render: (record) => {
          if (record != null && record != undefined && record.data != null && record.data != undefined) {
            return record.data.recordAck;
          }
          return "";
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="MaintenancePlanRemarks" defaultMessage="Remarks" />,
        render: (record) => {
          if (record != null && record != undefined && record.data != null && record.data != undefined) {
            return record.data.remarks;
          }
          return "";
        }
      },
    ];

    const tableColumns = columns.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
    if (this.state.xScroll === 'fixed') {
      tableColumns[0].fixed = true;
      tableColumns[tableColumns.length - 1].fixed = 'right';
    }

    let ProcedureRecordOpt = this.state.itemsProcedureRecords.map((data) =>
      <option key={data.id} value={data.id} >  {data.description} </option>);

    return (
      <div>
        <Row>
          <Col xs={9}></Col>
          <Col xs={3}>
            {/* <Button hidden={this.state.hiddenAmendDocument} onClick={this.createNewClientButton} style={{ width: '100%' }} 
            variant={this.state.createNewButtonVariant} >{this.state.createNewButton}
            </Button> */}
          </Col>
        </Row>
        <div hidden={this.state.hidden} className="col-md-12">
          <br />
          {/* <Accordion defaultActiveKey="0">
            <Card>
              <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row>
                  <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }}
                    as="h5">&#x25bc; Activity</Card.Header></Col>
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse id="activityAcord" eventKey="0"> */}
          <Card.Body>
            <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
              <Row>
                <Col xs={8}></Col>
                <Col xs={3}>
                  <Button onClick={this.addActivityShow} style={{ width: '100%' }}
                    variant={this.state.createNewButtonActivityVariant}> {this.state.createNewButtonActivity}</Button>
                </Col>
                <Col xs={1}>
                  <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable("MaintenancePlan")} icon="pi pi-cog"></PrimeButton>
                </Col>
              </Row>
              <br />

              <div style={{ display: this.state.activityAddItems ? 'block' : 'none' }}>
                <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridItemno">
                  <Form.Label column sm="2" >Item No</Form.Label>
                  <Col sm="4">
                    <Form.Control name="itemNo" value={this.state.itemNo}
                      onChange={this.handleChange} />
                  </Col>
                  <Col sm="6">
                    <AntCheckbox checked={this.state.itemGroup} onChange={(e) => {
                      if (e.target.checked) {
                        this.setState({
                          itemGroup: e.target.checked,
                          hiddenItemGroup: true,
                          taskActivity: "",
                          referenceProcedure: "",
                          referenceProcedureAck: "",
                          record: "",
                          recordAck: "",
                          remarks: "",

                        });
                      } else {
                        this.setState({
                          itemGroup: e.target.checked,
                          hiddenItemGroup: false,
                          projectPhaseActivityCode: "",
                        })
                      }
                    }}>Item Group</AntCheckbox>
                  </Col>
                </Form.Group>

                <Form.Group hidden={!this.state.hiddenItemGroup} style={{ marginBottom: '1rem' }} as={Row} controlId="formGridprojectphaseactivitycode">
                  <Form.Label column sm="2" >Project Phase , Activity Code</Form.Label>
                  <Col sm="4">
                    <Form.Control name="projectPhaseActivityCode" value={this.state.projectPhaseActivityCode}
                      onChange={this.handleChange} />
                  </Col>
                  <Col sm="2">
                  </Col>
                </Form.Group>

                <Form.Group hidden={this.state.hiddenItemGroup} style={{ marginBottom: '1rem' }} as={Row} controlId="formGridTaskActivity">
                  <Form.Label column sm="2" >Task / Activity</Form.Label>
                  <Col sm="4">
                    <Form.Control name="taskActivity" value={this.state.taskActivity}
                      onChange={this.handleChange} />
                  </Col>
                  <Col sm="2">
                  </Col>
                </Form.Group>

                <Form.Group hidden={this.state.hiddenItemGroup} style={{ marginBottom: '1rem' }} as={Row} controlId="formGridReferenceprocedure">
                  <Form.Label column sm="2" >Reference Procedure</Form.Label>
                  <Col sm="4">
                    <Form.Control name="referenceProcedure" value={this.state.referenceProcedure}
                      onChange={this.handleQProcedureChange} as="select"   >
                      <option value="0">Select ..</option>
                      {ProcedureRecordOpt}
                    </Form.Control>
                  </Col>
                </Form.Group>

                <Form.Group hidden={this.state.hiddenItemGroup} style={{ marginBottom: '1rem' }} as={Row} controlId="formGridRecord">
                  <Form.Label column sm="2" >Record</Form.Label>
                  <Col sm="4">
                    <Form.Control name="record" value={this.state.record}
                      onChange={this.handleQRecordChange} as="select"   >
                      <option value="0">Select ..</option>
                      {ProcedureRecordOpt}
                    </Form.Control>
                  </Col>
                </Form.Group>

                <Form.Group hidden={this.state.hiddenItemGroup} style={{ marginBottom: '1rem' }} as={Row} controlId="formGridIRemarks">
                  <Form.Label column sm="2">Remarks</Form.Label>
                  <Col sm="4">
                    <Form.Control name="remarks" value={this.state.remarks}
                      onChange={this.handleChange} />
                  </Col>
                  <Col sm="2">
                  </Col>
                </Form.Group>

                <div hidden={this.state.hiddenSave}>
                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridAdds">
                    <Col sm="2">
                    </Col>
                    <Col sm="4">
                      <Button onClick={this.addMaintenancePlan} style={{ width: '100%' }} variant="success">Add</Button>
                    </Col>
                    <Col sm="2">
                    </Col>
                  </Form.Group>
                </div>

                <div hidden={this.state.hiddenUpdate}>
                  <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                    <Row>
                      <Col sm="2">
                      </Col>
                      <Col sm="2">
                        <Button onClick={this.changeButtonCancel} style={{ width: '100%' }} variant="danger">Cancel</Button>
                      </Col>
                      <Col sm="2">
                        <Button onClick={this.updateDone} style={{ width: '100%' }} variant="warning">Update</Button>
                      </Col>
                    </Row>
                  </Container>
                </div>

              </div>
              <br />
              <div className="content-section implementation">
                <Mnd.MNDTable
                  state={this.state}
                  columns={tableColumns} dataSource={this.state.maintenancePlanTreeTable}
                  pagination={this.state.paginationDisabled}
                  expandable={this.state.expandable}
                  footer={this.state.footer}
                  title={this.state.title}
                  scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                />
              </div>
            </Container>
          </Card.Body>
          {/* </Accordion.Collapse>
            </Card>
          </Accordion> */}

          <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
            <Row>
              <Col sm="2"></Col>
              <Col sm="4">
                <Button onClick={this.saveMaintenancePlan} style={{ width: '100%' }} variant="success">{this.state.lastButton}</Button>
              </Col>
            </Row>
          </Container>

          {/* <div hidden={this.state.hiddenUpdate}>
            <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
              <Row>
                <Col sm="2">
                </Col>
                <Col sm="2">
                  <Button onClick={this.changeButtonCancel} style={{ width: '100%' }} variant="danger">Cancel</Button>
                </Col>
                <Col sm="2">
                  <Button onClick={this.updateDone} style={{ width: '100%' }} variant="warning">Update</Button>
                </Col>
              </Row>
            </Container>
          </div>*/}
        </div>

        <Modal
          show={this.state.modalMessage}
          onHide={this.showOrHideModalMessage}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered >

          <Modal.Header style={{ backgroundColor: "#f3f3f3" }} closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  {this.state.messageTip == "Success" &&
                    <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                  {this.state.messageTip == "Warning" &&
                    <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                  {this.state.messageTip == "Error" &&
                    <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body style={{ backgroundColor: "#f3f3f3" }}>
            <Row >
              <Col sm="12">
                {this.state.message}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer style={{ backgroundColor: "#f3f3f3" }}>
            <Button style={{ color: "#646c9a", backgroundColor: "#ffffff", borderColor: "#ffffff" }} variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.deleteModal}
          onHide={this.showOrHideModalDelete}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
            </p>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalDelete}>
              <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
            </Button>
            <Button variant="danger" onClick={this.deleteMaintenancePlanOK}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalTable}
          onHide={this.showOrHideModalTable}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered size="lg">

          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  <FormattedMessage id="GeneralTableSettings" defaultMessage="Table Settings" />
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row >
              <Col sm="12">
                <MNDTableSettings id={this.state.tableTip} />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

      </div>
    )
  }
}

const mapStateToProps = () => {
  return {

  }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(MaintenancePlan)
