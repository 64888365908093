/* eslint-disable */

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { FrappeGantt } from 'frappe-gantt-react' 
import { handleRequest } from '../../redux/config/env';
import moment from 'moment';
import { Form, Col, Row } from "react-bootstrap";


var tasks2 = [
    {
        start: '2018-10-01',
        end: '2018-10-08',
        name: 'Redesign website',
        id: "Task 0",
        progress: 20
    },
    {
        start: '2018-10-03',
        end: '2018-10-06',
        name: 'Write new content',
        id: "Task 1",
        progress: 5,
        dependencies: 'Task 0'
    },
    {
        start: '2018-10-04',
        end: '2018-10-08',
        name: 'Apply new styles',
        id: "Task 2",
        progress: 10,
        dependencies: 'Task 1'
    },
    {
        start: '2018-10-08',
        end: '2018-10-09',
        name: 'Review',
        id: "Task 3",
        progress: 5,
        dependencies: 'Task 2'
    },
    {
        start: '2018-10-08',
        end: '2018-10-10',
        name: 'Deploy',
        id: "Task 4",
        progress: 0,
        dependencies: 'Task 2'
    },
    {
        start: '2018-10-11',
        end: '2018-10-11',
        name: 'Go Live!',
        id: "Task 5",
        progress: 0,
        dependencies: 'Task 4',
        custom_class: 'bar-milestone'
    },
    {
        start: '2014-01-05',
        end: '2019-10-12',
        name: 'Long term task',
        id: "Task 6",
        progress: 0
    }
  ]


export class ActivityEvent extends Component {

    constructor(props) {
        super(props)

        this.state = {

            mode:'Month' ,
            tasks:[] 

        }
    }

    componentDidMount=async()=> {
        const response = await handleRequest("GET", "/WFActivity/getAll");
    
        if (response.data.length !== 0) {
            const result = response.data;
            let _tasks = []; 
           
            result.forEach((element, i) => {
                let obj = {
                    id: element.id.toString(),
                    start: moment().format('YYYY-MM-DD HH:mm a'), 
                    end: this.getEndTime(element.duration),
                    name:element.activityName,              
                    progress: 50
                                                

                };
               
                if(Boolean(element.parentId) && element.parentId!='0') 
                obj.dependencies= element.parentId; 

                _tasks.push(obj); 


            });
           
          
            this.setState({               
                tasks:_tasks ,             

            });

        }
    }

    getEndTime = (duration) => {

        console.log(moment());
        console.log(duration);
        var startDate = moment();

        if (Boolean(duration)) {
            if (Boolean(duration.year)) {

                startDate = startDate.add(duration.year, 'years');
            }

            if (Boolean(duration.month)) {

                startDate = startDate.add(duration.month, 'months');
            }
            if (Boolean(duration.day)) {

                startDate = startDate.add(duration.day, 'days');
            }
            if (Boolean(duration.hour)) {

                startDate = startDate.add(duration.hour, 'hours');
            }
            if (Boolean(duration.minute)) {

                startDate = startDate.add(duration.minute, 'minutes');
            }

            if (Boolean(duration.second)) {

                startDate = startDate.add(duration.second, 'seconds');
            }
        }

        console.log(startDate);
       
        return startDate.format('YYYY-MM-DD') ; 


    }

    render() {


        return (
            // <div className="App">
            //     <GSTC config={config} onState={onState} />
            // </div>
            <div>
         <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
              
                <Col xs="3">
                  <Form.Control name="type" value={this.state.mode}
                    onChange={(e) => {
                            
                        this.setState({ mode: e.target.value }) 
                      }}  as="select"  >
                    <option value="Month ">Month</option>
                    <option value="Week">Week</option> 
                    <option value="Day">Day</option>
                    <option value="Half Day">Half Day</option>
                    <option value="Quarter Day">Quarter Day </option> 
                 
                  </Form.Control>

                </Col>
              </Form.Group>
         {this.state.tasks.length>0 &&   <FrappeGantt 
                tasks={this.state.tasks}
                viewMode={this.state.mode}
                onClick={task => console.log(task)}
                onDateChange={(task, start, end) => console.log(task, start, end)}
                onProgressChange={(task, progress) => console.log(task, progress)}
                onTasksChange={tasks => console.log(tasks)}
            />}
        </div>

        );
    }


}




const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}



export default connect(mapStateToProps, mapDispatchToProps)(ActivityEvent) 