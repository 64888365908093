/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import classes from './css/CarouselDemo.scss';
import { Carousel } from 'primereact/carousel';
import { Button } from 'primereact/button';
import { handleRequest } from '../../redux/config/env';
import { API_BASE } from '../../redux/config/env';
import classNames from 'classnames';

export class CompanyProjects extends Component {

    constructor() {
        super();
        this.state = {
            hiddenCarousel:true,
            companyList: [],
            projectList: []
        };

        this.companyTemplate = this.companyTemplate.bind(this);

        this.responsiveSettings = [
            {
                breakpoint: '1024px',
                numVisible: 3,
                numScroll: 3
            },
            {
                breakpoint: '768px',
                numVisible: 2,
                numScroll: 2
            },
            {
                breakpoint: '560px',
                numVisible: 1,
                numScroll: 1
            }
        ];
    }

    componentDidMount() {
        this.restartTable();

    }

    restartTable = async () => {

        const response = await handleRequest("GET", "/Company/getCompanyByUser");

        if (response.data.length === 0) {
            this.setState({
                companyList: []
            });
        }

        if (response.data.length !== 0) {

            this.setState({
                companyList: response.data
            });
        }
    }

    getProjects = async () => {

        const response = await handleRequest("GET", "/CompanyProjects/getAllByCompany");

        if (response.data.length === 0) {
            this.setState({
                projectList: []
            });
        }

        if (response.data.length !== 0) {

            this.setState({
                projectList: response.data
            });
        }
        console.log(this.state.projectList);
    }

    showProjects = (company) => {
        this.props.history.push('/' + company.shortName);
        //return <Redirect to="/dashboard" />
    }

    showProjects = (company) => {
        this.props.history.push('/' + company.shortName);
        //return <Redirect to="/dashboard" />
    }

    companyTemplate(company) {
        return (
            <div className={classNames(classes.cardetails)}>
                <div className={classNames(classes.p_grid), classNames(classes.p_nogutter)}>
                    <div className="p-col-12">
                        {company.logoPath ? <img src={`${API_BASE}/Company/download/` + company.id} width={100} height={100} alt={company.logoPath} /> : <img src="" width={100} height={100} alt="NO Image" />}
                    </div>
                    <div className="p-col-12 {classNames(classes.car_data)}">
                        <div className={classNames(classes.car_title)}>{company.shortName}</div>

                        <div className={classNames(classes.car_buttons)}>
                            <Button onClick={this.showProjects} label="Show Projects" className="p-button-secondary" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    projectsTemplate(projects) {
        return (
            <div className={classNames(classes.cardetails)}>
                <div className={classNames(classes.p_grid), classNames(classes.p_nogutter)}>
                    <div className="p-col-12">
                        {projects.logoPath ? <img src={`${API_BASE}/Company/download/` + projects.id} width={100} height={100} alt={projects.logoPath} /> : <img src="" width={100} height={100} alt="NO Image" />}
                    </div>
                    <div className="p-col-12 {classNames(classes.car_data)}">
                        <div className={classNames(classes.car_title)}>{projects.shortName}</div>

                        <div className={classNames(classes.car_buttons)}>
                            <Button onClick={this.showProjects} className="p-button-secondary">Show Projects</Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const customHeader = <h2>{this.state.companyList.fullName}</h2>

        return (
            <div className={classNames(classes.carousel_demo)}>
                <div className={classNames(classes.content_section), classNames(classes.introduction)}>
                    <div className={classNames(classes.feature_intro)}>
                        <h1>Companies</h1>
                        <p>Projects will be listed after company choosed.</p>
                    </div>
                </div>

                <div className={classNames(classes.content_section), classNames(classes.implementation)}>

                    <Carousel value={this.state.companyList} itemTemplate={this.companyTemplate} numVisible={4} numScroll={1} className={classNames(classes.custom_carousel)}
                        responsive={this.responsiveSettings} header={customHeader} circular={true} autoplayInterval={3000}></Carousel>

                    <div hidden={this.state.hiddenCarousel}>
                        <Carousel value={this.state.projectList} itemTemplate={this.projectsTemplate} numVisible={4} numScroll={1} className={classNames(classes.custom_carousel)}
                            responsive={this.responsiveSettings} header={customHeader} circular={true} autoplayInterval={3000}></Carousel>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyProjects)