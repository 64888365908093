import React from 'react';
import { IconButton  } from "@material-ui/core";
import { Menu } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { ListItemIcon } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import { Typography } from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { FormattedMessage } from "react-intl";

export default ({ row, onDeleteRow, size, editBgColor, deleteBgColor }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteRow = () => {
    if (onDeleteRow) {
      onDeleteRow(row);
      setAnchorEl(null);
    }
  };

  return (
    <div>
    <IconButton
      aria-label="more"
      aria-controls="long-menu"
      aria-haspopup="true"
      onClick={handleClick}
      size={size}
    >
    <MoreVertIcon />    
    </IconButton>
    <Menu
      id="menu"
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose} >

      <MenuItem onClick={deleteRow}>
        <ListItemIcon>
          <DeleteIcon fontSize="small" color="error" />
        </ListItemIcon>
        <Typography variant="inherit">
        <FormattedMessage id="CustomMaterialMenuDelete" defaultMessage="Delete"/>
        </Typography>
      </MenuItem>
    </Menu>
  </div>

  );
};