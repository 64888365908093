/* eslint-disable */

import React, { Component } from 'react'
import { connect } from 'react-redux';
// import GSTC from "react-gantt-schedule-timeline-calendar";
import { handleRequest } from '../../redux/config/env';
import moment from 'moment';
import { Toast } from 'primereact/toast';
import { Form, Col, Row, Button } from "react-bootstrap";
// import CalendarScroll from "gantt-schedule-timeline-calendar/dist/CalendarScroll.plugin.js";
// import ItemMovement from "gantt-schedule-timeline-calendar/dist/ItemMovement.plugin.js";
// import Selection from "gantt-schedule-timeline-calendar/dist/Selection.plugin.js";
// import WeekendHighlight from "gantt-schedule-timeline-calendar/dist/WeekendHighlight.plugin.js";
// import index from "gantt-schedule-timeline-calendar/dist/index.umd";
// import "gantt-schedule-timeline-calendar/dist/style.css";


function getDurationObject (pId, pStart, pEnd)  {

    var duration = { id: pId };
    let start = moment(pStart);
    let end = moment(pEnd);

    console.log(start);
    console.log(end);

    let yearDif = end.diff(start, 'years');
    start = start.add(yearDif, 'years');


    let monthDif = end.diff(start, 'months');
    start = start.add(monthDif, 'months');


    let dayDif = end.diff(start, 'days');
    start = start.add(dayDif, 'days');


    let hourDif = end.diff(start, 'hours');
    start = start.add(hourDif, 'hours');

    let minuteDif = end.diff(start, 'minutes');
    start = start.add(minuteDif, 'minutes');


    let secondDif = end.diff(start, 'seconds');

    duration.year = yearDif;
    duration.month = monthDif;
    duration.day = dayDif;
    duration.hour = hourDif;
    duration.minute = minuteDif;
    duration.second = secondDif;

    console.log(duration);

    return duration;


}

function   getDuration(pDuration) {
    var result = "";
    var pObj = pDuration;
    if (pObj !== undefined && pObj !== null) {
        if (pObj.year > 0)
            result = result + pObj.year + "Years ";
        if (pObj.month > 0)
            result = result + pObj.month + "Months ";
        if (pObj.day > 0)
            result = result + pObj.day + "Days ";
        if (pObj.hour > 0)
            result = result + pObj.hour + "Hours ";
        if (pObj.minute > 0)
            result = result + pObj.minute + "Minutes ";
        if (pObj.second > 0)
            result = result + pObj.second + "Seconds ";
    }

    return result;
}


const pallete = [
    '#E74C3C',
    '#DA3C78',
    '#7E349D',
    '#0077C0',
    '#07ABA0',
    '#0EAC51',
    '#F1892D',
    '#E3724B',
    '#AE7C5B',
    '#6C7A89',
    '#758586',
    '#707070',
    '#E3F4BF',
    '#BEF7C8',
    '#86E6C8',
    '#36CFC9',
    '#209BDD',
    '#1581E6',
    '#0860BF'
];

var sonuc = null;

export class ActivityEvent extends Component {

    constructor(props) {
        super(props)

        this.state = {
            activityList: [],


            config: {
                plugins: [ItemMovement({
                    moveable: false, 
                    resizable: true,
                    collisionDetection: true
                }),
                Selection({
                    selected(data, type) {
                        console.log(data, type);
                    }
                }),
                CalendarScroll(),
                WeekendHighlight()],
                height: 500,
                list: {
                    rows: {},
                    columns: {
                        data: {
                            id: {
                                id: "id",
                                data: "id",
                                width: 50,
                                header: {
                                    content: "ID"
                                }
                            },
                            label: {
                                id: "label",
                                data: "label",
                                width: 200,
                                header: {
                                    content: "Activity Name"
                                },
                                expander: true,
                            },

                            durationStr: {
                                id: "durationStr",
                                data: "durationStr",
                                width: 150,
                                header: {
                                    content: "Duration"
                                }
                            },

                            startingPoint: {
                                id: "startingPoint",
                                data: "startingPoint",
                                width: 150,
                                header: {
                                    content: "Starting Point"
                                }
                            },

                          

                        }
                    }
                },
                chart: {
                    time: {
                        period: 'day',
                        additionalSpaces: {
                            hour: { before: 24, after: 24, period: 'hour' },
                            day: { before: 1, after: 20, period: 'day' },
                            week: { before: 2, after: 10, period: 'week' },
                            month: { before: 6, after: 6, period: 'month' },
                            year: { before: 1, after: 2, period: 'year' }
                        }
                    }
                }
            }
        }
    }

    componentWillMount = () => {

        var pathArray = this.props.location.pathname.split('/');

        if (Boolean(pathArray) && pathArray.length >= 3) {
            const config = this.state.config;

            config.chart.time.period = pathArray[2];
            this.setState({ config: config })


        }
    }

   

    saveChanges = async () => {

        var list = this.state.activityList;
        var durationList = [];
        if (sonuc !== null) {

            var items = sonuc; // this.state.config.chart.items; 

            list.forEach(element => {
                let id = element.id.toString();
                let obj = {
                    duration: getDurationObject(element.duration.id, items[id].time.start, items[id].time.end)
                }
                durationList.push(obj);
            });

            console.log(list);
            await handleRequest("POST", "/WFActivity/updateDurations", durationList);

            this.showSuccess();
            sonuc = null;

        }
        else this.showError("No changes was detected.");

    }

    showSuccess() {
        this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Successful' });
    };

    showError(message) {
        this.toast.show({ severity: 'error', summary: 'Error Message', detail: message });
    };

    getStartTime = (duration) => {

       
        var startDate = moment(); 

        if (Boolean(duration)) {
            if (Boolean(duration.year)) {

                startDate = startDate.add(duration.year, 'years');
            }

            if (Boolean(duration.month)) {

                startDate = startDate.add(duration.month, 'months');
            }
            if (Boolean(duration.day)) {

                startDate = startDate.add(duration.day, 'days');
            }
            if (Boolean(duration.hour)) {

                startDate = startDate.add(duration.hour, 'hours');
            }
            if (Boolean(duration.minute)) {

                startDate = startDate.add(duration.minute, 'minutes');
            }

            if (Boolean(duration.second)) {

                startDate = startDate.add(duration.second, 'seconds');
            }
        }

        console.log(startDate);
        console.log(startDate.toDate().getTime());
        return startDate;


    }

    componentDidMount = async () => {

        const response = await handleRequest("GET", "/WFActivity/getAll");
        const config = this.state.config;

        if (response.data.length !== 0) {
            const result = response.data;
            let _items = {};
            let _rows = {};
            result.forEach((element, i) => {


                let obj = {
                    id: element.id,
                    rowId: element.id.toString(),
                    label: element.activityName,
                    moveable: true,
                    resizeable: true,

                    style: { background: pallete[Math.floor(Math.random() * pallete.length)] }

                };
                if (!Boolean(element.parentId) || element.parentId == '0') {
                    delete element["parentId"];
                    obj.time = {
                        start: new Date().getTime(),
                        end: this.getEndTime(moment(), element.duration)
                    };
                }
                else {
                    obj.time = {
                        start:this.getStartTime(element.startDuration).toDate().getTime() ,
                        end: this.getEndTime(this.getStartTime(element.startDuration), element.duration) 
                    }; 
                }

                element.startingPoint = this.getDuration(element.startDuration); 
                element.durationStr = this.getDuration(element.duration);
              

                let attr = element.id.toString();
                _items[attr] = obj;


                element.label = element.activityName;
                element.id = element.id.toString();



                _rows[attr] = element;


            });
            config.chart.items = _items;
            config.list.rows = _rows;

            console.log(result);

            this.setState({

                config: config,
                activityList: result

            });

        }
    }

    getEndTime = (start, duration) => {

        console.log(moment());
        console.log(duration);
        var startDate = start;

        if (Boolean(duration)) {
            if (Boolean(duration.year)) {

                startDate = startDate.add(duration.year, 'years');
            }

            if (Boolean(duration.month)) {

                startDate = startDate.add(duration.month, 'months');
            }
            if (Boolean(duration.day)) {

                startDate = startDate.add(duration.day, 'days');
            }
            if (Boolean(duration.hour)) {

                startDate = startDate.add(duration.hour, 'hours');
            }
            if (Boolean(duration.minute)) {

                startDate = startDate.add(duration.minute, 'minutes');
            }

            if (Boolean(duration.second)) {

                startDate = startDate.add(duration.second, 'seconds');
            }
        }

        console.log(startDate);
        console.log(startDate.toDate().getTime());
        return startDate.toDate().getTime();


    }

 

    getDuration(pDuration) {
        var result = "";
        var pObj = pDuration;
        if (pObj !== undefined && pObj !== null) {
            if (pObj.year > 0)
                result = result + pObj.year + "Years ";
            if (pObj.month > 0)
                result = result + pObj.month + "Months ";
            if (pObj.day > 0)
                result = result + pObj.day + "Days ";
            if (pObj.hour > 0)
                result = result + pObj.hour + "Hours ";
            if (pObj.minute > 0)
                result = result + pObj.minute + "Minutes ";
            if (pObj.second > 0)
                result = result + pObj.second + "Seconds ";
        }

        return result;
    }


    onState(state) {


        console.log(state);

        let subs = [];
        // state.update("config.chart.items.1", item1 => {
        //     item1.label = "Gantt schedule timeline calendar";
        //     item1.time.end = item1.time.end + 2 * 24 * 60 * 60 * 1000;
        //     return item1;
        // });
        subs.push(
            state.subscribe("config.chart.items", items => {
                console.log(state);
                console.log("items changed", items);
                sonuc = items;
                state.subscribe("config.list.rows", rows => {
                    var rowIds = Object.keys(rows);
                    rowIds.forEach(element => {
                        var obj = items[element.toString()].time;
                        let durationObj = getDurationObject(0,new Date(obj.start),new Date(obj.end));
                        rows[element].durationStr = getDuration(durationObj);   
                       
                    });
                   
                });
            })
        );
        subs.push(
            state.subscribe("config.list.rows", rows => {
                // console.log("rows changed", rows);
            })
        );
        subs.push(
            state.subscribe('config.chart.time.period', period => {
                //  console.log("period changed", period);


            }));

        subs.push(
            state.subscribe('config.chart.time.zoom', zoom => {
                //  console.log("zoom changed", zoom);

            }));


        subs.push(
            state.subscribe('config.plugin.ItemMovement', itemMovement => {
                //  console.log("itemMovement changed", itemMovement); 
                if (!itemMovement || !itemMovement.item) return;
                state.update(`config.chart.items.${itemMovement.item.id}.isResizing`, itemMovement.item.resizing);
            }))

        console.log(subs);
        // return () => {
        //     subs.forEach(unsub => unsub());
        // };


    }

    // useEffect = () => {
    //     return () => {
    //       subs.forEach(unsub => unsub());
    //     };
    //   };



    render() {
        // let subs = [];
        // const useEffect = (() => {
        //     return () => {
        //         subs.forEach(unsub => unsub());
        //     };
        // });

        return (
            <div>
                <Toast ref={(el) => this.toast = el} />
                <Form.Group style={{ marginBottom: "1rem" }} as={Row}>

                    <Col xs="2">
                        <Form.Control name="type" value={this.state.config.chart.time.period}
                            onChange={(e) => {
                                e.persist();
                                const config = this.state.config;

                                config.chart.time.period = e.target.value;
                                this.setState({ config: config })

                                window.location.href = '/GSTCActivityEvent/' + e.target.value;

                            }} as="select"  >
                            <option value="year ">Year</option>
                            <option value="month">Month</option>
                            <option value="week">Week</option>
                            <option value="day">Day</option>
                            <option value="hour">Hour</option>

                        </Form.Control>

                    </Col>
                    <Col xs="2">
                        <Button type="button" style={{ width: '100%' }} onClick={this.saveChanges} variant="success" >Save Changes</Button>

                    </Col>
                </Form.Group>

                {/* { this.state.activityList.length > 0 && <GSTC config={this.state.config} onState={this.onState} />} */}
            </div>


        );
    }


}




const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}



export default connect(mapStateToProps, mapDispatchToProps)(ActivityEvent) 