/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Chips } from 'primereact/chips';
import { Card, Button, Button as ReactButton, Col, Form, Row, Modal, Form as FormBoot } from 'react-bootstrap';
import { CloseOutlined, LoadingOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Checkbox, Button as AntButton, DatePicker, message, Input, InputNumber, Upload, Space, Select, Table } from 'antd';
import { API_BASE, handleRequest } from '../../redux/config/env';
import { Redirect } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { IconButton  } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';
import SettingsIcon from '@material-ui/icons/Settings';
import 'react-contexify/dist/ReactContexify.min.css';
import { Menu as RCMenu, Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from './ForComponents/CustomMaterialMenu';
import { COUNTRIES } from '../settings/JsonDatas/Datas';
import moment from 'moment';
import $ from "jquery";

import { Menu } from 'antd';
import { MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons';
import { stubTrue } from 'lodash';

const { SubMenu } = Menu;
const { Column } = Table;
const { Option } = Select;
const { TextArea } = Input;

var _brancheRows = [];
var _manufacturingRows = [];
var _projectRows = [];
var _financialPositionRows = [
    { financialPositionIndex: 1, description: "Authorized Capital", value: "" },
    { financialPositionIndex: 2, description: "Present paid-up Capital", value: "" },
    { financialPositionIndex: 3, description: "Accumulated Capital", value: "" },
];
var _bankersInformationRows = [
    { bankersInformationIndex: 1, bankName: "", IBANNo: "" },
];
var _completedProjectsRows = [
    {
        completedProjectsIndex: 1,
        nameOfCustomer: "",
        projectName: "",
        location: "",
        years: "",
        totalValue: "",
        totalValueCurrency: ""
    }
];

export class VendorPortal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            current: 'mail',
            vendorPages: ["Follow Ups", "About", "Organization", "Employee List",
                "Head Office", "Branches", "Manufacturing", "Projects", "Products", "Services",
                "Financial Position", "Bank Accounts", "Questionnarie", "References", "History"],
            vendorPagesHidden: [false, true, true, true, true, true, true, true, true,
                true, true, true, true, true, true,],


            fullnameCompany: "",
            shortnameCompany: "",
            loading: false,
            imageUrl: null,
            logoPath: "",
            aboutCompany: "",
            vision: "",
            mission: "",
            webPage: "",
            headOfficeAddress: "",
            headOfficeLocation: "",
            headOfficeCountry: "",
            headOfficeCity: "",
            headOfficePhone: [],
            headOfficeFax: [],
            headOfficeEmail: [],
            headOfficeKeyName: "",
            headOfficeKeyEmail: "",
            headOfficeKeyPhone: "",
            branche: false,
            brancheAddress: "",
            brancheLocation: "",
            brancheCountry: "",
            brancheCity: "",
            branchePhone: [],
            brancheFax: [],
            brancheEmail: [],
            brancheKeyName: "",
            brancheKeyEmail: "",
            brancheKeyPhone: "",
            brancheRows: [],
            manufacturing: false,
            manufacturingAddress: "",
            manufacturingLocation: "",
            manufacturingCountry: "",
            manufacturingCity: "",
            manufacturingPhone: [],
            manufacturingFax: [],
            manufacturingEmail: [],
            manufacturingKeyName: "",
            manufacturingKeyEmail: "",
            manufacturingKeyPhone: "",
            manufacturingRows: [],
            project: false,
            projectAddress: "",
            projectLocation: "",
            projectCountry: "",
            projectCity: "",
            projectPhone: [],
            projectFax: [],
            projectEmail: [],
            projectKeyName: "",
            projectKeyEmail: "",
            projectKeyPhone: "",
            projectRows: [],
            productList: [],
            productSave: true,
            productId: null,
            productCode: "",
            productDescription: "",
            productPrice: "",
            productPriceCurrency: "",
            modalDeleteProduct: false,
            serviceList: [],
            serviceSave: true,
            serviceId: null,
            serviceCode: "",
            serviceDescription: "",
            modalDeleteService: false,
            financialPosition: false,
            financialPositionRows: [],
            bankersInformation: false,
            bankersInformationRows: [],
            completedProjectsRows: [],

            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            modalHowToUse: false,
            howToUseThis: "",
            modalMNDSettings: false,
            modalSettings: false,
            howToUseSetting: false,
            whatIsSetting: false,
            visibleWhatis: false,
            visibleHowToUse: false,
            modalShowWhatIs: false,
            topX: 0,
            leftY: 0,
            selectID: "",
            selectURI: "",
            inputDescription: "",
            userSettings: null,
            formId: null,
            deleteModal: false
        };

        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);
        this.showWarning = this.showWarning.bind(this);
        this.dynamicInputs = this.dynamicInputs.bind(this);
    }

    componentDidMount = async () => {
        document.body.className = document.body.className + " kt-aside--minimize";
        document.getElementById("kt_aside").remove();
        document.getElementById("kt_header").style.left = "0px";
        document.getElementById("kt_subheader").style.left = "0px";
        document.getElementById("kt_footer").style.left = "0px";
        document.getElementById("kt_wrapper").style.paddingLeft = "0px";

        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {
                var flagEmail = this.props.auth.user.email.split('@')[0].split('_');
                var flagEmail2 = this.props.auth.user.email.split('@')[1].split('.');

                var adminControl1 = "";
                var adminControl2 = "";

                if (flagEmail.length > 0)
                    adminControl1 = flagEmail[flagEmail.length - 1];

                if (flagEmail2.length > 0)
                    adminControl2 = flagEmail2[0];

                if (adminControl1 === "99" && adminControl2 === "mnd") {
                    this.setState({
                        isAdmin: true
                    });
                }
            }

        document.addEventListener('contextmenu', this._handleContextMenu);

        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "VendorPortal" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "VendorPortal",
        };

        const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseDynamicInputs.data.length !== 0) {
            this.setState({
                dynamicInputs: responseDynamicInputs.data,
            });
        } else {
            this.setState({
                dynamicInputs: [],
            });
        }

        const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "VendorPortal");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
            });

        } catch (error) {

        }

        this.setState({
            brancheRows: _brancheRows,
            manufacturingRows: _manufacturingRows,
            projectRows: _projectRows,
            financialPositionRows: _financialPositionRows,
            bankersInformationRows: _bankersInformationRows,
            completedProjectsRows: _completedProjectsRows,

        });
    }

    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later!" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    }

    componentWillUnmount() {
        document.removeEventListener('contextmenu', this._handleContextMenu);
    }

    _handleContextMenu = (event) => {
        this.setState({
            inputDescription: "",
        });

        const clickX = event.clientX - 260;
        const clickY = event.clientY - 180;

        this.setState({ topX: clickY, leftY: clickX });

        console.log('basti ' + clickX + ' ' + clickY);

        try {
            this.setState({ selectID: event.toElement.id, selectURI: event.toElement.baseURI });
        }
        catch (ex) {
            this.setState({ selectID: event.srcElement.id, selectURI: event.srcElement.baseURI });
        }
    }

    dynamicInputs = async (e) => {
        const { selectID } = this.state;
        const { selectURI } = this.state;
        this.setState({
            modalShowWhatIs: true,
            inputDescription: "",
        });

        if (selectURI != null && selectURI.length > 0) {
            var s = selectURI.split('/');
            const newselectURI = s[s.length - 1];

            const prm = {
                inputKey: selectID,
                pageName: newselectURI,
            }

            try {
                const response = await handleRequest("POST", "/DynamicInputs/getResult", prm);

                if (response.data.length > 0) {
                    this.setState({
                        inputDescription: <FormattedMessage id={response.data[0].translateWhatis} defaultMessage={response.data[0].inputDescription} />,
                    });
                } else {
                    this.setState({
                        inputDescription: <FormattedMessage id="GeneralMessageNoDataFound" defaultMessage="No data found" />,
                    });
                }
            } catch (error) {
                console.log('hata' + error)
            }
        }
    }

    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

            this.getUserSettings(result.data.id);
        } else {
            this.setState({
                formId: null
            });
        }
    }

    showHowToUse = async () => {
        if (this.state.formId !== null) {
            const responseHowToUse = await handleRequest("GET", "/HowToUse/getByFormId/" + this.state.formId);

            if (responseHowToUse.data.length !== 0) {
                this.setState({
                    howToUseThis: responseHowToUse.data.howToUse
                });
            } else {
                this.setState({
                    howToUseThis: ""
                });
            }
        }

        this.setState({
            modalHowToUse: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showSettings = () => {
        this.setState({
            modalSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showMNDSettings = () => {
        this.setState({
            modalMNDSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalWhatIs = () => {
        this.setState({
            modalShowWhatIs: !this.state.modalShowWhatIs,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalHowToUse = () => {
        this.setState({
            modalHowToUse: !this.state.modalHowToUse
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalSettings = () => {
        this.setState({
            modalSettings: !this.state.modalSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalMNDSettings = () => {
        this.setState({
            modalMNDSettings: !this.state.modalMNDSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    goLanguage = () => {
        window.location.href = '/LanguageSettings';
    }

    goHowToUse = () => {
        window.location.href = '/HowToUse';
    }

    goWhatIs = () => {
        window.location.href = '/DynamicMenu';
    }

    goDynamicPage = () => {
        window.location.href = '/DynamicPage';
    }

    getUserSettings = async (formId) => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({
                userSettings: null,
                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }

    setUserSettings = async () => {
        if (this.state.userSettings === null) {
            const newUserSettings = {
                formId: this.state.formId,
                howToUseSetting: this.state.howToUseSetting,
                whatIsSetting: this.state.whatIsSetting
            }

            await handleRequest("POST", "/UserPageSettings/add", newUserSettings);
        } else {
            const updatedUserSettings = {
                id: this.state.userSettings.id,
                formId: this.state.formId,
                howToUseSetting: this.state.howToUseSetting,
                whatIsSetting: this.state.whatIsSetting
            }

            await handleRequest("POST", "/UserPageSettings/update", updatedUserSettings);
        }

        this.setState({
            modalSettings: false
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);

        this.showSuccess("All data has been saved successfully.");
    }

    resetInputs = () => {


    }

    saveVendorRecord = async () => {
        if (this.state.rfqNo !== "") {

            var flagGeneralTerms = [];
            var flagTechReqs = [];
            var flagPrices = [];
            var flagPayments = [];
            var flagEvaCriterias = [];
            var flagWarranties = [];
            var flagLanguages = [];
            var flagRequestedDocuments = [];
            var flagOthers = [];

            if (this.state.generalTerms)
                for (let index = 0; index < this.state.generalTermsRows.length; index++) {
                    flagGeneralTerms.push({
                        rfqGeneralTermCheck: this.state.generalTermsRows[index].checked,
                        rfqGeneralTerm: this.state.generalTermsRows[index].description
                    });
                }

            if (this.state.techReqs)
                for (let index = 0; index < this.state.techReqsRows.length; index++) {
                    flagTechReqs.push({
                        rfqTechRequirementCheck: this.state.techReqsRows[index].checked,
                        rfqTechRequirement: this.state.techReqsRows[index].description
                    });
                }

            if (this.state.price)
                for (let index = 0; index < this.state.priceRows.length; index++) {
                    flagPrices.push({
                        rfqPriceCheck: this.state.priceRows[index].checked,
                        rfqPrice: this.state.priceRows[index].description
                    });
                }

            if (this.state.payment)
                for (let index = 0; index < this.state.paymentRows.length; index++) {
                    flagPayments.push({
                        rfqPaymentTermCheck: this.state.paymentRows[index].checked,
                        rfqPaymentTerm: this.state.paymentRows[index].description
                    });
                }

            if (this.state.evaluationCriterias)
                for (let index = 0; index < this.state.evaCriteriasRows.length; index++) {
                    flagEvaCriterias.push({
                        rfqEvaCriteriaCheck: this.state.evaCriteriasRows[index].checked,
                        rfqEvaCriteria: this.state.evaCriteriasRows[index].description
                    });
                }

            if (this.state.warranty)
                for (let index = 0; index < this.state.warrantyRows.length; index++) {
                    flagWarranties.push({
                        rfqWarrantyCheck: this.state.warrantyRows[index].checked,
                        rfqWarranty: this.state.warrantyRows[index].description
                    });
                }

            if (this.state.language)
                for (let index = 0; index < this.state.languageRows.length; index++) {
                    flagLanguages.push({
                        rfqLanguageCheck: this.state.languageRows[index].checked,
                        rfqLanguage: this.state.languageRows[index].description
                    });
                }

            if (this.state.requestedDocuments)
                for (let index = 0; index < this.state.requestedDocumentsRows.length; index++) {
                    flagRequestedDocuments.push({
                        rfqRequestedDocumentCheck: this.state.requestedDocumentsRows[index].checked,
                        rfqRequestedDocument: this.state.requestedDocumentsRows[index].description
                    });
                }

            if (this.state.other)
                for (let index = 0; index < this.state.otherRows.length; index++) {
                    flagOthers.push({
                        rfqOtherCheck: this.state.otherRows[index].checked,
                        rfqOther: this.state.otherRows[index].description
                    });
                }

            const newRFQ = {
                rfqNo: this.state.rfqNo,
                generalTermCheck: this.state.generalTerms,
                generalTermFiles: this.state.generalTermFiles,
                generalTerms: flagGeneralTerms,
                techRequirementCheck: this.state.techReqs,
                techRequirementFiles: this.state.techReqFiles,
                techRequirements: flagTechReqs,
                priceCheck: this.state.price,
                prices: flagPrices,
                paymentTermCheck: this.state.payment,
                paymentTerms: flagPayments,
                deliveryPlaceCheck: this.state.deliveryPlace,
                deliveryPlace: this.state.deliveryPlaceText,
                deliveryPlaceLat: this.state.markerPosition.lat,
                deliveryPlaceLng: this.state.markerPosition.lng,
                deliveryDateCheck: this.state.deliveryDate,
                deliveryDate: this.state.date,
                quoteValidityCheck: this.state.validityQuotation,
                quoteValidityValue: this.state.validityQuotationValue,
                quoteValidityDateType: this.state.validityQuotationType,
                evaCriteriaCheck: this.state.evaluationCriterias,
                evaCriteriaDeliveryDateCheck: this.state.evaDeliveryDate,
                evaCriteriaPriceCheck: this.state.evaPrice,
                evaCriterias: flagEvaCriterias,
                receptionForQuotationCheck: this.state.receptionOfQuotation,
                receptionForQuotationOptions: this.state.selectedReception.join(),
                receptionForQuotationOtherCheck: this.state.receptionOtherCheck,
                receptionForQuotationOtherOption: this.state.receptionOther,
                modeOfTransportCheck: this.state.modeOfTransport,
                modeOfTranportOptions: this.state.selectedTransport.join(),
                modeOfTranportOtherCheck: this.state.transportOtherCheck,
                modeOfTranportOtherOption: this.state.transportOther,
                warrantyCheck: this.state.warranty,
                warranties: flagWarranties,
                languageCheck: this.state.language,
                languages: flagLanguages,
                requestedDocumentCheck: this.state.requestedDocuments,
                requestedDocuments: flagRequestedDocuments,
                otherCheck: this.state.other,
                others: flagOthers
            }

            var response = await handleRequest("POST", "/RequestForQuotation/add", newRFQ);
            if (response.data !== null && response.data !== undefined) {
                if (response.data === "SUCCESS") {
                    this.resetInputs();
                    this.restartTable();
                    this.showSuccess("All data has been saved successfully.");
                }
                else {
                    this.showError();
                }
            }
            else {
                this.showError();
            }
        } else {
            this.showWarning();
        }
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalEdit = () => {
        this.resetInputs();
        this.setState({
            modalEdit: !this.state.modalEdit,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    handleClick = e => {
        console.log('click ', e);
        this.setState({ current: e.key });

        var flagVendorPagesHidden = [];

        if (e.key === "followUps") {
            flagVendorPagesHidden = [false, true, true, true, true, true, true, true, true,
                true, true, true, true, true, true,];

            this.setState({ vendorPagesHidden: flagVendorPagesHidden });
        }

        if (e.key === "companyAbout") {
            flagVendorPagesHidden = [true, false, true, true, true, true, true, true, true,
                true, true, true, true, true, true,];

            this.setState({ vendorPagesHidden: flagVendorPagesHidden });
        }

        if (e.key === "companyOrganization") {
            flagVendorPagesHidden = [true, true, false, true, true, true, true, true, true,
                true, true, true, true, true, true,];

            this.setState({ vendorPagesHidden: flagVendorPagesHidden });
        }

        if (e.key === "companyEmployeeList") {
            flagVendorPagesHidden = [true, true, true, false, true, true, true, true, true,
                true, true, true, true, true, true,];

            this.setState({ vendorPagesHidden: flagVendorPagesHidden });
        }

        if (e.key === "contactsHeadOffice") {
            flagVendorPagesHidden = [true, true, true, true, false, true, true, true, true,
                true, true, true, true, true, true,];

            this.setState({ vendorPagesHidden: flagVendorPagesHidden });
        }

        if (e.key === "contactsBranches") {
            flagVendorPagesHidden = [true, true, true, true, true, false, true, true, true,
                true, true, true, true, true, true,];

            this.setState({ vendorPagesHidden: flagVendorPagesHidden });
        }

        if (e.key === "contactsManufacturing") {
            flagVendorPagesHidden = [true, true, true, true, true, true, false, true, true,
                true, true, true, true, true, true,];

            this.setState({ vendorPagesHidden: flagVendorPagesHidden });
        }

        if (e.key === "contactsProjects") {
            flagVendorPagesHidden = [true, true, true, true, true, true, true, false, true,
                true, true, true, true, true, true,];

            this.setState({ vendorPagesHidden: flagVendorPagesHidden });
        }

        if (e.key === "businessScopeProducts") {
            flagVendorPagesHidden = [true, true, true, true, true, true, true, true, false,
                true, true, true, true, true, true,];

            this.setState({ vendorPagesHidden: flagVendorPagesHidden });
        }

        if (e.key === "businessScopeServices") {
            flagVendorPagesHidden = [true, true, true, true, true, true, true, true, true,
                false, true, true, true, true, true,];

            this.setState({ vendorPagesHidden: flagVendorPagesHidden });
        }

        if (e.key === "financialFinancialPosition") {
            flagVendorPagesHidden = [true, true, true, true, true, true, true, true, true,
                true, false, true, true, true, true,];

            this.setState({ vendorPagesHidden: flagVendorPagesHidden });
        }

        if (e.key === "financialBankAccounts") {
            flagVendorPagesHidden = [true, true, true, true, true, true, true, true, true,
                true, true, false, true, true, true,];

            this.setState({ vendorPagesHidden: flagVendorPagesHidden });
        }

        if(e.key === "qualificationsQuestionnaire"){
            flagVendorPagesHidden = [true, true, true, true, true, true, true, true, true,
                true, true, true, false, true, true,];

            this.setState({ vendorPagesHidden: flagVendorPagesHidden });
        }

        if (e.key === "references") {
            flagVendorPagesHidden = [true, true, true, true, true, true, true, true, true,
                true, true, true, true, false, true,];

            this.setState({ vendorPagesHidden: flagVendorPagesHidden });
        }

        if (e.key === "history") {
            flagVendorPagesHidden = [true, true, true, true, true, true, true, true, true,
                true, true, true, true, true, false,];

            this.setState({ vendorPagesHidden: flagVendorPagesHidden });
        }
    }

    goNewPage = () => {
        var win = window.open('/VendorPortalTest', '_blank');
        win.focus();
    }

    goNewPage2 = () => {
        var win = window.open('/VendorPortalTest2', '_blank');
        win.focus();
    }

    beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 4;
        if (!isLt2M) {
            message.error('Image must smaller than 4MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    handlePhotoChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {

            if (info.file.response.length > 0) {
                this.setState({
                    logoPath: info.file.response[0]
                })
            }

            this.getBase64(info.file.originFileObj, imageUrl =>
                this.setState({
                    imageUrl,
                    loading: false,
                }),
            );
        }
    }

    handleChange = e => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    addNewBrancheLine = () => {
        _brancheRows.push(
            {
                brancheIndex: _brancheRows.length + 1,
                brancheAddress: "",
                brancheLocation: "",
                brancheCountry: "",
                brancheCity: "",
                branchePhone: [],
                brancheFax: [],
                brancheEmail: [],
                brancheKeyName: "",
                brancheKeyPhone: "",
                brancheKeyEmail: "",
                checked: false
            }
        );

        this.setState({
            brancheRows: _brancheRows
        });
    }

    removeBrancheLine = (index, e) => {
        _brancheRows.splice(index, 1);

        this.setState({
            brancheRows: _brancheRows
        });
    }

    handleChangeBrancheChekbox = (index, e) => {
        let list = this.state.brancheRows;
        list[index].checked = e.target.checked;

        this.setState({
            brancheRows: list.slice()
        });
    }

    handleChangeBrancheAddress = (index, e) => {
        let list = this.state.brancheRows;
        list[index].brancheAddress = e.target.value;

        this.setState({
            brancheRows: list.slice()
        });
    }

    handleChangeBrancheLocation = (index, e) => {
        let list = this.state.brancheRows;
        list[index].brancheLocation = e.target.value;

        this.setState({
            brancheRows: list.slice()
        });
    }

    handleChangeBrancheCountry = (index, e) => {
        e.preventDefault();
        let list = this.state.brancheRows;
        list[index].brancheCountry = e.target.value;

        this.setState({
            brancheRows: list.slice()
        });
    }

    handleChangeBrancheCity = (index, e) => {
        let list = this.state.brancheRows;
        list[index].brancheCity = e.target.value;

        this.setState({
            brancheRows: list.slice()
        });
    }

    handleChangeBranchePhone = (index, e) => {
        let list = this.state.brancheRows;
        list[index].branchePhone = e.value;

        this.setState({
            brancheRows: list.slice()
        });
    }

    handleChangeBrancheFax = (index, e) => {
        let list = this.state.brancheRows;
        list[index].brancheFax = e.value;

        this.setState({
            brancheRows: list.slice()
        });
    }

    handleChangeBrancheEmail = (index, e) => {
        let list = this.state.brancheRows;
        list[index].brancheEmail = e.value;

        this.setState({
            brancheRows: list.slice()
        });
    }

    handleChangeBrancheKeyName = (index, e) => {
        let list = this.state.brancheRows;
        list[index].brancheKeyName = e.target.value;

        this.setState({
            brancheRows: list.slice()
        });
    }

    handleChangeBrancheKeyPhoneNo = (index, e) => {
        let list = this.state.brancheRows;
        list[index].brancheKeyPhone = e.target.value;

        this.setState({
            brancheRows: list.slice()
        });
    }

    handleChangeBrancheKeyEmail = (index, e) => {
        let list = this.state.brancheRows;
        list[index].brancheKeyEmail = e.target.value;

        this.setState({
            brancheRows: list.slice()
        });
    }

    addNewManufacturingLine = () => {
        _manufacturingRows.push(
            {
                manufacturingIndex: _manufacturingRows.length + 1,
                manufacturingAddress: "",
                manufacturingLocation: "",
                manufacturingCountry: "",
                manufacturingCity: "",
                manufacturingPhone: [],
                manufacturingFax: [],
                manufacturingEmail: [],
                manufacturingKeyName: "",
                manufacturingKeyPhone: "",
                manufacturingKeyEmail: "",
                checked: false
            }
        );

        this.setState({
            manufacturingRows: _manufacturingRows
        });
    }

    removeManufacturingLine = (index, e) => {
        _manufacturingRows.splice(index, 1);

        this.setState({
            manufacturingRows: _manufacturingRows
        });
    }

    handleChangeManufacturingChekbox = (index, e) => {
        let list = this.state.manufacturingRows;
        list[index].checked = e.target.checked;

        this.setState({
            manufacturingRows: list.slice()
        });
    }

    handleChangeManufacturingAddress = (index, e) => {
        let list = this.state.manufacturingRows;
        list[index].manufacturingAddress = e.target.value;

        this.setState({
            manufacturingRows: list.slice()
        });
    }

    handleChangeManufacturingLocation = (index, e) => {
        let list = this.state.manufacturingRows;
        list[index].manufacturingLocation = e.target.value;

        this.setState({
            manufacturingRows: list.slice()
        });
    }

    handleChangeManufacturingCountry = (index, e) => {
        e.preventDefault();
        let list = this.state.manufacturingRows;
        list[index].manufacturingCountry = e.target.value;

        this.setState({
            manufacturingRows: list.slice()
        });
    }

    handleChangeManufacturingCity = (index, e) => {
        let list = this.state.manufacturingRows;
        list[index].manufacturingCity = e.target.value;

        this.setState({
            manufacturingRows: list.slice()
        });
    }

    handleChangeManufacturingPhone = (index, e) => {
        let list = this.state.manufacturingRows;
        list[index].manufacturingPhone = e.value;

        this.setState({
            manufacturingRows: list.slice()
        });
    }

    handleChangeManufacturingFax = (index, e) => {
        let list = this.state.manufacturingRows;
        list[index].manufacturingFax = e.value;

        this.setState({
            manufacturingRows: list.slice()
        });
    }

    handleChangeManufacturingEmail = (index, e) => {
        let list = this.state.manufacturingRows;
        list[index].manufacturingEmail = e.value;

        this.setState({
            manufacturingRows: list.slice()
        });
    }

    handleChangeManufacturingKeyName = (index, e) => {
        let list = this.state.manufacturingRows;
        list[index].manufacturingKeyName = e.target.value;

        this.setState({
            manufacturingRows: list.slice()
        });
    }

    handleChangeManufacturingKeyPhoneNo = (index, e) => {
        let list = this.state.manufacturingRows;
        list[index].manufacturingKeyPhone = e.target.value;

        this.setState({
            manufacturingRows: list.slice()
        });
    }

    handleChangeManufacturingKeyEmail = (index, e) => {
        let list = this.state.manufacturingRows;
        list[index].manufacturingKeyEmail = e.target.value;

        this.setState({
            manufacturingRows: list.slice()
        });
    }

    addNewProjectLine = () => {
        _projectRows.push(
            {
                projectIndex: _projectRows.length + 1,
                projectAddress: "",
                projectLocation: "",
                projectCountry: "",
                projectCity: "",
                projectPhone: [],
                projectFax: [],
                projectEmail: [],
                projectKeyName: "",
                projectKeyPhone: "",
                projectKeyEmail: "",
                checked: false
            }
        );

        this.setState({
            projectRows: _projectRows
        });
    }

    removeProjectLine = (index, e) => {
        _projectRows.splice(index, 1);

        this.setState({
            projectRows: _projectRows
        });
    }

    handleChangeProjectChekbox = (index, e) => {
        let list = this.state.projectRows;
        list[index].checked = e.target.checked;

        this.setState({
            projectRows: list.slice()
        });
    }

    handleChangeProjectAddress = (index, e) => {
        let list = this.state.projectRows;
        list[index].projectAddress = e.target.value;

        this.setState({
            projectRows: list.slice()
        });
    }

    handleChangeProjectLocation = (index, e) => {
        let list = this.state.projectRows;
        list[index].projectLocation = e.target.value;

        this.setState({
            projectRows: list.slice()
        });
    }

    handleChangeProjectCountry = (index, e) => {
        e.preventDefault();
        let list = this.state.projectRows;
        list[index].projectCountry = e.target.value;

        this.setState({
            projectRows: list.slice()
        });
    }

    handleChangeProjectCity = (index, e) => {
        let list = this.state.projectRows;
        list[index].projectCity = e.target.value;

        this.setState({
            projectRows: list.slice()
        });
    }

    handleChangeProjectPhone = (index, e) => {
        let list = this.state.projectRows;
        list[index].projectPhone = e.value;

        this.setState({
            projectRows: list.slice()
        });
    }

    handleChangeProjectFax = (index, e) => {
        let list = this.state.projectRows;
        list[index].projectFax = e.value;

        this.setState({
            projectRows: list.slice()
        });
    }

    handleChangeProjectEmail = (index, e) => {
        let list = this.state.projectRows;
        list[index].projectEmail = e.value;

        this.setState({
            projectRows: list.slice()
        });
    }

    handleChangeProjectKeyName = (index, e) => {
        let list = this.state.projectRows;
        list[index].projectKeyName = e.target.value;

        this.setState({
            projectRows: list.slice()
        });
    }

    handleChangeProjectKeyPhoneNo = (index, e) => {
        let list = this.state.projectRows;
        list[index].projectKeyPhone = e.target.value;

        this.setState({
            projectRows: list.slice()
        });
    }

    handleChangeProjectKeyEmail = (index, e) => {
        let list = this.state.projectRows;
        list[index].projectKeyEmail = e.target.value;

        this.setState({
            projectRows: list.slice()
        });
    }

    addProduct = () => {
        var list = this.state.productList;

        if (this.state.productCode !== "" && this.state.productDescription !== "") {
            const newItem = {
                productIndex: Math.floor(Math.random() * 10001),
                productCode: this.state.productCode,
                productDescription: this.state.productDescription,
                productPrice: this.state.productPrice,
                productPriceCurrency: this.state.productPriceCurrency,
                productPhoto: this.state.productPhotoPath
            }

            list.push(newItem);

            this.setState({
                productList: list.slice(),
                productCode: "",
                productDescription: "",
                productPrice: "",
                productPriceCurrency: "",
                productPhoto: ""
            })
        } else {
            this.showWarning();
        }
    }

    updateProduct = () => {
        let list = this.state.productList;
        let objIndex = list.findIndex((obj => obj.productIndex === this.state.productId));

        list[objIndex].productCode = this.state.productCode;
        list[objIndex].productDescription = this.state.productDescription;
        list[objIndex].productPrice = this.state.productPrice;
        list[objIndex].productPriceCurrency = this.state.productPriceCurrency;
        list[objIndex].productPhoto = this.state.productPhoto;

        this.setState({
            productList: list.slice(),
            productCode: "",
            productDescription: "",
            productPrice: "",
            productPriceCurrency: "",
            productPhoto: "",
            productSave: true
        });
    }

    cancelProduct = () => {
        this.setState({
            productId: null,
            productCode: "",
            productDescription: "",
            productPrice: "",
            productPriceCurrency: "",
            productPhoto: "",
            productSave: true
        });
    }

    deleteProductModal = (row) => {
        this.setState({
            productId: row.key,
            modalDeleteProduct: true
        });
    }

    deleteProductModalOK = () => {
        let list = this.state.productList;
        let flagProductId = this.state.productId;

        list.splice(list.findIndex(function (i) {
            return i.productIndex === flagProductId;
        }), 1);

        this.setState({
            productList: list.slice(),
            productId: null,
            modalDeleteProduct: false,
            productCode: "",
            productDescription: ""
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    editProduct = (row) => {
        this.setState({
            productId: row.productIndex,
            productCode: row.productCode,
            productDescription: row.productDescription,
            productPrice: row.productPrice,
            productPriceCurrency: row.productPriceCurrency,
            productPhoto: row.productPhotoPath,
            productSave: false
        });
    }

    showOrHideModalDeleteProduct = () => {
        this.setState({
            modalDeleteProduct: !this.state.modalDeleteProduct
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    handleProductPhotoChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {

            if (info.file.response.length > 0) {
                this.setState({
                    productPhotoPath: info.file.response[0]
                })
            }

            this.getBase64(info.file.originFileObj, imageUrl2 =>
                this.setState({
                    imageUrl2,
                    loading: false,
                }),
            );
        }
    }

    addService = () => {
        var list = this.state.serviceList;

        if (this.state.serviceCode !== "" && this.state.serviceDescription !== "") {
            const newItem = {
                serviceIndex: Math.floor(Math.random() * 10001),
                serviceCode: this.state.serviceCode,
                serviceDescription: this.state.serviceDescription,
            }

            list.push(newItem);

            this.setState({
                serviceList: list.slice(),
                serviceCode: "",
                serviceDescription: ""
            })
        } else {
            this.showWarning();
        }
    }

    updateService = () => {
        let list = this.state.serviceList;
        let objIndex = list.findIndex((obj => obj.serviceIndex === this.state.serviceId));

        list[objIndex].serviceCode = this.state.serviceCode;
        list[objIndex].serviceDescription = this.state.serviceDescription;

        this.setState({
            serviceList: list.slice(),
            serviceCode: "",
            serviceDescription: "",
            serviceSave: true
        });
    }

    cancelService = () => {
        this.setState({
            serviceId: null,
            serviceCode: "",
            serviceDescription: "",
            serviceSave: true
        });
    }

    deleteServiceModal = (row) => {
        this.setState({
            serviceId: row.key,
            modalDeleteService: true
        });
    }

    deleteServiceModalOK = () => {
        let list = this.state.serviceList;
        let flagServiceId = this.state.serviceId;

        list.splice(list.findIndex(function (i) {
            return i.serviceIndex === flagServiceId;
        }), 1);

        this.setState({
            serviceList: list.slice(),
            serviceId: null,
            modalDeleteService: false,
            serviceCode: "",
            serviceDescription: ""
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    editService = (row) => {
        this.setState({
            serviceId: row.serviceIndex,
            serviceCode: row.serviceCode,
            serviceDescription: row.serviceDescription,
            serviceSave: false,
        });
    }

    showOrHideModalDeleteService = () => {
        this.setState({
            modalDeleteService: !this.state.modalDeleteService
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    handleChangeFinancialPositionDescription = (index, e) => {
        let list = this.state.financialPositionRows;
        list[index].description = e.target.value;

        this.setState({
            financialPositionRows: list.slice()
        });
    }

    handleChangeFinancialPositionValue = (index, e) => {
        let list = this.state.financialPositionRows;
        list[index].value = e.target.value;

        this.setState({
            financialPositionRows: list.slice()
        });
    }

    addNewFinancialPositionLine = () => {
        _financialPositionRows.push(
            {
                financialPositionIndex: _financialPositionRows.length + 1,
                description: "",
                checked: false
            }
        );

        this.setState({
            financialPositionRows: _financialPositionRows
        });
    }

    removeFinancialPositionLine = (index, e) => {
        _financialPositionRows.splice(index, 1);

        this.setState({
            financialPositionRows: _financialPositionRows
        });
    }

    handleChangeBankersInformationBankName = (index, e) => {
        let list = this.state.bankersInformationRows;
        list[index].bankName = e.target.value;

        this.setState({
            bankersInformationRows: list.slice()
        });
    }

    handleChangeBankersInformationIBANNo = (index, e) => {
        let list = this.state.bankersInformationRows;
        list[index].IBANNo = e.target.value;

        this.setState({
            bankersInformationRows: list.slice()
        });
    }

    addNewBankersInformationLine = () => {
        _bankersInformationRows.push(
            {
                bankersInformationIndex: _bankersInformationRows.length + 1,
                bankName: "",
                IBANNo: ""
            }
        );

        this.setState({
            bankersInformationRows: _bankersInformationRows
        });
    }

    removeBankersInformationLine = (index, e) => {
        _bankersInformationRows.splice(index, 1);

        this.setState({
            bankersInformationRows: _bankersInformationRows
        });
    }

    addNewCompletedProjectsLine = () => {
        _completedProjectsRows.push(
            {
                completedProjectsIndex: _completedProjectsRows.length + 1,
                nameOfCustomer: "",
                projectName: "",
                location: "",
                years: "",
                totalValue: "",
                totalValueCurrency: ""
            }
        );

        this.setState({
            completedProjectsRows: _completedProjectsRows
        });
    }

    removeCompletedProjectsLine = (index, e) => {
        _completedProjectsRows.splice(index, 1);

        this.setState({
            completedProjectsRows: _completedProjectsRows
        });
    }

    handleChangeCompletedProjectsNameOfCustomer = (index, e) => {
        let list = this.state.completedProjectsRows;
        list[index].nameOfCustomer = e.target.value;

        this.setState({
            completedProjectsRows: list.slice()
        });
    }

    handleChangeCompletedProjectsProjectName = (index, e) => {
        let list = this.state.completedProjectsRows;
        list[index].projectName = e.target.value;

        this.setState({
            completedProjectsRows: list.slice()
        });
    }

    handleChangeCompletedProjectsLocation = (index, e) => {
        let list = this.state.completedProjectsRows;
        list[index].location = e.target.value;

        this.setState({
            completedProjectsRows: list.slice()
        });
    }

    handleChangeCompletedProjectsYears = (index, e) => {
        let list = this.state.completedProjectsRows;
        list[index].years = e.target.value;

        this.setState({
            completedProjectsRows: list.slice()
        });
    }

    handleChangeCompletedProjectsTotalValue = (index, e) => {
        let list = this.state.completedProjectsRows;
        list[index].totalValue = e.target.value;

        this.setState({
            completedProjectsRows: list.slice()
        });
    }

    handleChangeCompletedProjectsTotalValueCurrency = (index, value) => {
        let list = this.state.completedProjectsRows;
        list[index].totalValueCurrency = value;

        this.setState({
            completedProjectsRows: list.slice()
        });
    }

    render() {
        const { selectID } = this.state;
        const howToUseThis = this.state.howToUseThis;

        const { current } = this.state;

        let optionsCountry = COUNTRIES.map((data) =>
            <option
                key={data.value}
                value={data.name}
            >
                {data.name}
            </option>
        );

        const uploadButton = (
            <div>
                {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div className="ant-upload-text">Upload</div>
            </div>
        );

        return this.state.authorizations.length > 0 && (this.state.authorizations.findIndex(p => p.action === "view") === -1 ? (<div><Redirect to="/error/error-v6" /></div>) : (
            <div>
                <MenuProvider id="menu_id">

                    <Modal
                        show={this.state.modalShowWhatIs}
                        onHide={this.showOrHideModalWhatIs}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered  >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">{<FormattedMessage id="RightClickModalTitle" defaultMessage="What is This?" />}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Form.Label style={{ color: 'black' }} column sm="12">
                                        {this.state.inputDescription}
                                    </Form.Label>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showOrHideModalWhatIs} title={selectID}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalMessage}
                        onHide={this.showOrHideModalMessage}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>

                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <Row >
                                    <Col sm="12">
                                        {this.state.messageTip == "Success" &&
                                            <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                        {this.state.messageTip == "Warning" &&
                                            <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                        {this.state.messageTip == "Error" &&
                                            <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                    </Col>
                                </Row>
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <Row >
                                <Col sm="12">
                                    {this.state.message}
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalMNDSettings}
                        onHide={this.showOrHideModalMNDSettings}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">MND Settings</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="3">
                                        <Button variant="outline-primary" style={{ marginTop: "1rem" }} block onClick={this.goLanguage}>Language</Button>
                                    </Col>
                                    <Col sm="2">
                                        <Button variant="outline-dark" style={{ marginTop: "1rem" }} block onClick={this.goHowToUse}>How To Use</Button>
                                    </Col>
                                    <Col sm="2">
                                        <Button variant="outline-warning" style={{ marginTop: "1rem" }} block onClick={this.goWhatIs}>What is</Button>
                                    </Col>
                                    <Col sm="3">
                                        <Button variant="outline-danger" style={{ marginTop: "1rem" }} block onClick={this.goDynamicPage}>Dynamic Page</Button>
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="info" onClick={this.showOrHideModalMNDSettings}>OK</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalSettings}
                        onHide={this.showOrHideModalSettings}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Settings</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Form.Label style={{ color: 'black' }} column sm="4">
                                        How To Use:
                            </Form.Label>
                                    <Col sm="4">
                                        <Checkbox style={{ marginTop: '1rem' }} checked={this.state.howToUseSetting} onChange={(e) => {
                                            this.setState({
                                                howToUseSetting: e.target.checked,
                                            });
                                        }}>Don't Show Again</Checkbox>
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Form.Label style={{ color: 'black' }} column sm="4">
                                        What is:
                            </Form.Label>
                                    <Col sm="4">
                                        <Checkbox style={{ marginTop: '1rem' }} checked={this.state.whatIsSetting} onChange={(e) => {
                                            this.setState({
                                                whatIsSetting: e.target.checked,
                                            });
                                        }}>Don't Show Again</Checkbox>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showOrHideModalSettings}>Cancel</Button>
                            <Button variant="primary" onClick={this.setUserSettings}>Save Changes</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalHowToUse}
                        onHide={this.showOrHideModalHowToUse}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="xl"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">How To Use This Page?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ backgroundColor: "white", color: "black", padding: "10px" }} dangerouslySetInnerHTML={{ __html: howToUseThis }}></div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="info" onClick={this.showOrHideModalHowToUse}>OK</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.deleteModal}
                        onHide={this.showOrHideModalDelete}
                        aria-labelledby="contained-modal-title-vcenter"
                        backdrop="static"
                        centered >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p style={{ textAlign: "center", color: "black" }}>
                                <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this data?" />
                            </p>
                            <p style={{ textAlign: "center", color: "black" }}>
                                <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalDelete}>
                                <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                            </ReactButton>
                            <ReactButton variant="danger" onClick={this.deleteRFQ}>
                                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                            </ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalDeleteProduct}
                        onHide={this.showOrHideModalDeleteProduct}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Delete Product Record?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showOrHideModalDeleteProduct}>Close</Button>
                            <Button variant="danger" onClick={this.deleteProductModalOK} >Delete</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalDeleteService}
                        onHide={this.showOrHideModalDeleteService}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Delete Service Record?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.showOrHideModalDeleteService}>Close</Button>
                            <Button variant="danger" onClick={this.deleteServiceModalOK} >Delete</Button>
                        </Modal.Footer>
                    </Modal>

                    <Card>

                        <div>
                            <Row>
                                <Col sm="1">
                                    {this.state.isAdmin ?
                                        <IconButton color="primary" onClick={this.showMNDSettings}>
                                            <SettingsIcon />
                                        </IconButton>
                                        :
                                        <IconButton color="primary" onClick={this.showSettings}>
                                            <SettingsIcon />
                                        </IconButton>
                                    }
                                </Col>
                                <Col sm="10">
                                </Col>
                                <Col sm="1" style={{ textAlign: "right" }}>
                                    {!this.state.howToUseSetting && <IconButton color="primary" onClick={this.showHowToUse}>
                                        <HelpIcon />
                                    </IconButton>
                                    }
                                </Col>
                            </Row>
                        </div>

                        <div style={{ padding: "10px" }}>
                            <Menu style={{ textAlign: "center" }} onClick={this.handleClick} selectedKeys={[current]} theme="dark" mode="horizontal">
                                <Menu.Item key="followUps">
                                    Follow Ups
                                </Menu.Item>
                                <SubMenu key="company" title="Company">
                                    <Menu.Item key="companyAbout">About</Menu.Item>
                                    <Menu.Item key="companyOrganization">Organization</Menu.Item>
                                    <Menu.Item key="companyEmployeeList">Employee List</Menu.Item>
                                </SubMenu>
                                <SubMenu key="contacts" title="Contacts">
                                    <Menu.Item key="contactsHeadOffice">Head Office</Menu.Item>
                                    <Menu.Item key="contactsBranches">Branches</Menu.Item>
                                    <Menu.Item key="contactsManufacturing">Manufacturing</Menu.Item>
                                    <Menu.Item key="contactsProjects">Projects</Menu.Item>
                                </SubMenu>
                                <SubMenu key="businessScope" title="Business Scope">
                                    <Menu.Item key="businessScopeProducts">Products</Menu.Item>
                                    <Menu.Item key="businessScopeServices">Services</Menu.Item>
                                </SubMenu>
                                <SubMenu key="financial" title="Financial">
                                    <Menu.Item key="financialFinancialPosition">Financial Position</Menu.Item>
                                    <Menu.Item key="financialBankAccounts">Bank Accounts</Menu.Item>
                                </SubMenu>
                                <SubMenu key="qualifications" title="Qualifications">
                                    <Menu.Item key="qualificationsQuestionnaire">Questionnaire</Menu.Item>
                                </SubMenu>
                                <Menu.Item key="references">
                                    References
                                </Menu.Item>
                                <Menu.Item key="history">
                                    History
                                </Menu.Item>
                                <Menu.Item onClick={this.goNewPage} key="newPage">
                                    NCC Sample
                                </Menu.Item>
                                <Menu.Item onClick={this.goNewPage2} key="newPage2">
                                    Sugan Template Sample
                                </Menu.Item>
                            </Menu>
                        </div>

                        <div hidden={this.state.vendorPagesHidden[0]} style={{ padding: "10px", marginTop: '2rem' }}>
                            Follow Ups Page Content
                        </div>

                        <div hidden={this.state.vendorPagesHidden[1]} style={{ padding: "10px", marginTop: '2rem' }}>
                            <Form.Group as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Full  name of Company:
                                </Form.Label>
                                <Col sm="3">
                                    <Input value={this.state.fullnameCompany} style={{ width: "100%" }} onChange={(e) => { this.setState({ fullnameCompany: e.target.value }) }} />
                                </Col>
                                <Col sm="3">
                                </Col>
                                <Col sm="2">
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Short name of Company:
                                </Form.Label>
                                <Col sm="3">
                                    <Input value={this.state.shortnameCompany} style={{ width: "100%" }} onChange={(e) => { this.setState({ shortnameCompany: e.target.value }) }} />
                                </Col>
                                <Col sm="3">
                                </Col>
                                <Col sm="2">
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Logo:
                                </Form.Label>
                                <Col sm="3">
                                    <Upload
                                        name="avatar"
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        action={`${API_BASE}/VendorRecord/upload`}
                                        beforeUpload={this.beforeUpload}
                                        onChange={this.handlePhotoChange}
                                    >
                                        {this.state.imageUrl ? <img src={this.state.imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                    </Upload>
                                </Col>
                                <Col sm="3">
                                </Col>
                                <Col sm="2">
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    About Company:
                                </Form.Label>
                                <Col sm="3">
                                    <TextArea rows={4} value={this.state.aboutCompany} style={{ width: "100%" }} onChange={(e) => { this.setState({ aboutCompany: e.target.value }) }} />
                                </Col>
                                <Col sm="3">
                                </Col>
                                <Col sm="2">
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Vision:
                                </Form.Label>
                                <Col sm="3">
                                    <TextArea rows={4} value={this.state.vision} style={{ width: "100%" }} onChange={(e) => { this.setState({ vision: e.target.value }) }} />
                                </Col>
                                <Col sm="3">
                                </Col>
                                <Col sm="2">
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Mission:
                                </Form.Label>
                                <Col sm="3">
                                    <TextArea rows={4} value={this.state.mission} style={{ width: "100%" }} onChange={(e) => { this.setState({ mission: e.target.value }) }} />
                                </Col>
                                <Col sm="3">
                                </Col>
                                <Col sm="2">
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Web page:
                                </Form.Label>
                                <Col sm="3">
                                    <Input value={this.state.webPage} style={{ width: "100%" }} onChange={(e) => { this.setState({ webPage: e.target.value }) }} />
                                </Col>
                                <Col sm="3">
                                </Col>
                                <Col sm="2">
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Col sm="2">
                                </Col>
                                <Col sm="3">
                                    <ReactButton id="VendorPortalAboutSaveButton"
                                        style={{ width: '100%' }} onClick={this.saveChangesAbout} variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save Changes" />
                                    </ReactButton>
                                </Col>
                                <Col sm="3">
                                </Col>
                                <Col sm="2">
                                </Col>
                            </Form.Group>
                        </div>

                        <div hidden={this.state.vendorPagesHidden[2]} style={{ padding: "10px", marginTop: '2rem' }}>
                            Organization Page Content
                        </div>

                        <div hidden={this.state.vendorPagesHidden[3]} style={{ padding: "10px", marginTop: '2rem' }}>
                            Employee List Page Content
                        </div>

                        <div hidden={this.state.vendorPagesHidden[4]} style={{ padding: "10px", marginTop: '2rem' }}>

                            <Form.Group as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Address:
                                </Form.Label>
                                <Col sm="3">
                                    <Input value={this.state.headOfficeAddress} style={{ width: "100%" }} onChange={(e) => { this.setState({ headOfficeAddress: e.target.value }) }} />
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Location:
                                </Form.Label>
                                <Col sm="3">
                                    <Input value={this.state.headOfficeLocation} style={{ width: "100%" }} onChange={(e) => { this.setState({ headOfficeLocation: e.target.value }) }} />
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Country:
                                </Form.Label>
                                <Col sm="3">
                                    <Form.Control name="headOfficeCountry" value={this.state.headOfficeCountry}
                                        onChange={this.handleChange} as="select">
                                        <option value="">Select Country</option>
                                        {optionsCountry}
                                    </Form.Control>
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    City:
                                </Form.Label>
                                <Col sm="3">
                                    <Input value={this.state.headOfficeCity} style={{ width: "100%" }} onChange={(e) => { this.setState({ headOfficeCity: e.target.value }) }} />
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Phone No:
                                </Form.Label>
                                <Col sm="3">
                                    <div className="content-section implementation p-fluid">
                                        <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.headOfficePhone} onChange={(e) => this.setState({ headOfficePhone: e.value })}></Chips>
                                    </div>
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Fax:
                                </Form.Label>
                                <Col sm="3">
                                    <div className="content-section implementation p-fluid">
                                        <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.headOfficeFax} onChange={(e) => this.setState({ headOfficeFax: e.value })}></Chips>
                                    </div>
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Email:
                                </Form.Label>
                                <Col sm="3">
                                    <div className="content-section implementation p-fluid">
                                        <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.headOfficeEmail} onChange={(e) => this.setState({ headOfficeEmail: e.value })}></Chips>
                                    </div>
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Key Contact
                                </Form.Label>
                            </Form.Group>

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                    Name:
                                </Form.Label>
                                <Col sm="3">
                                    <Input value={this.state.headOfficeKeyName} style={{ width: "100%" }} onChange={(e) => { this.setState({ headOfficeKeyName: e.target.value }) }} />
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                    Phone No:
                                </Form.Label>
                                <Col sm="3">
                                    <Input value={this.state.headOfficeKeyPhone} style={{ width: "100%" }} onChange={(e) => { this.setState({ headOfficeKeyPhone: e.target.value }) }} />
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                    Email:
                                </Form.Label>
                                <Col sm="3">
                                    <Input value={this.state.headOfficeKeyEmail} style={{ width: "100%" }} onChange={(e) => { this.setState({ headOfficeKeyEmail: e.target.value }) }} />
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Col sm="2">
                                </Col>
                                <Col sm="3">
                                    <ReactButton id="VendorPortalHeadOfficeSaveButton"
                                        style={{ width: '100%' }} onClick={this.saveChangesHeadOffice} variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save Changes" />
                                    </ReactButton>
                                </Col>
                                <Col sm="3">
                                </Col>
                                <Col sm="2">
                                </Col>
                            </Form.Group>

                        </div>

                        <div hidden={this.state.vendorPagesHidden[5]} style={{ padding: "10px", marginTop: '2rem' }}>
                            <Form.Group as={Row}>
                                <Col sm="2">
                                </Col>
                                <Col sm="2">
                                    <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ branche: e.target.checked }) }} checked={this.state.branche}>Branche - 1</Checkbox>
                                </Col>
                                {(this.state.branche) && (
                                    <Col sm="4">
                                    </Col>
                                )}
                                {(this.state.branche) && (this.state.brancheRows.length === 0) && (
                                    <Col sm="3">
                                        <AntButton type="primary" onClick={this.addNewBrancheLine} icon={<PlusOutlined />}></AntButton>
                                    </Col>
                                )}
                            </Form.Group>

                            {(this.state.branche) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Address:
                                        </Form.Label>
                                <Col sm="3">
                                    <Input value={this.state.brancheAddress} style={{ width: "100%" }} onChange={(e) => { this.setState({ brancheAddress: e.target.value }) }} />
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>
                            )}

                            {(this.state.branche) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Location:
                                        </Form.Label>
                                <Col sm="3">
                                    <Input value={this.state.brancheLocation} style={{ width: "100%" }} onChange={(e) => { this.setState({ brancheLocation: e.target.value }) }} />
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>
                            )}

                            {(this.state.branche) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Country:
                                        </Form.Label>
                                <Col sm="3">
                                    <Form.Control name="brancheCountry" value={this.state.brancheCountry}
                                        onChange={this.handleChange} as="select">
                                        <option value="">Select Country</option>
                                        {optionsCountry}
                                    </Form.Control>
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>
                            )}

                            {(this.state.branche) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    City:
                                        </Form.Label>
                                <Col sm="3">
                                    <Input value={this.state.brancheCity} style={{ width: "100%" }} onChange={(e) => { this.setState({ brancheCity: e.target.value }) }} />
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>
                            )}

                            {(this.state.branche) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Phone No:
                                        </Form.Label>
                                <Col sm="3">
                                    <div className="content-section implementation p-fluid">
                                        <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.branchePhone} onChange={(e) => this.setState({ branchePhone: e.value })}></Chips>
                                    </div>
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>
                            )}

                            {(this.state.branche) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Fax:
                                        </Form.Label>
                                <Col sm="3">
                                    <div className="content-section implementation p-fluid">
                                        <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.brancheFax} onChange={(e) => this.setState({ brancheFax: e.value })}></Chips>
                                    </div>
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>
                            )}

                            {(this.state.branche) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Email:
                                        </Form.Label>
                                <Col sm="3">
                                    <div className="content-section implementation p-fluid">
                                        <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.brancheEmail} onChange={(e) => this.setState({ brancheEmail: e.value })}></Chips>
                                    </div>
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>
                            )}

                            {(this.state.branche) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Key Contact
                                        </Form.Label>
                            </Form.Group>
                            )}

                            {(this.state.branche) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                    Name:
                                        </Form.Label>
                                <Col sm="3">
                                    <Input value={this.state.brancheKeyName} style={{ width: "100%" }} onChange={(e) => { this.setState({ brancheKeyName: e.target.value }) }} />
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>
                            )}

                            {(this.state.branche) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                    Phone No:
                                        </Form.Label>
                                <Col sm="3">
                                    <Input value={this.state.brancheKeyPhone} style={{ width: "100%" }} onChange={(e) => { this.setState({ brancheKeyPhone: e.target.value }) }} />
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>
                            )}

                            {(this.state.branche) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                    Email:
                                        </Form.Label>
                                <Col sm="3">
                                    <Input value={this.state.brancheKeyEmail} style={{ width: "100%" }} onChange={(e) => { this.setState({ brancheKeyEmail: e.target.value }) }} />
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>
                            )}

                            {this.state.brancheRows.map((item, index) => (
                                <div>
                                    <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Col sm="2">
                                            <Checkbox onChange={(e) => this.handleChangeBrancheChekbox(index, e)} checked={item.checked}>Branche - {index + 2}</Checkbox>
                                        </Col>
                                        <Col sm="4">
                                        </Col>
                                        <Col sm="3">
                                            <Space>
                                                <AntButton type="primary" danger onClick={(e) => this.removeBrancheLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                                {(this.state.brancheRows.length === (index + 1)) && (<AntButton type="primary" onClick={this.addNewBrancheLine} icon={<PlusOutlined />}></AntButton>)}
                                            </Space>
                                        </Col>
                                    </Form.Group>

                                    {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Address:
                                                </Form.Label>
                                        <Col sm="3">
                                            <Input value={item.brancheAddress} style={{ width: "100%" }} onChange={(e) => this.handleChangeBrancheAddress(index, e)} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Location:
                                                </Form.Label>
                                        <Col sm="3">
                                            <Input value={item.brancheLocation} style={{ width: "100%" }} onChange={(e) => this.handleChangeBrancheLocation(index, e)} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Country:
                                                </Form.Label>
                                        <Col sm="3">
                                            <Form.Control name="brancheCountry" value={item.brancheCountry}
                                                onChange={(e) => this.handleChangeBrancheCountry(index, e)} as="select">
                                                <option value="">Select Country</option>
                                                {optionsCountry}
                                            </Form.Control>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            City:
                                                </Form.Label>
                                        <Col sm="3">
                                            <Input value={item.brancheCity} style={{ width: "100%" }} onChange={(e) => this.handleChangeBrancheCity(index, e)} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Phone No:
                                                </Form.Label>
                                        <Col sm="3">
                                            <div className="content-section implementation p-fluid">
                                                <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={item.branchePhone} onChange={(e) => this.handleChangeBranchePhone(index, e)}></Chips>
                                            </div>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Fax:
                                                </Form.Label>
                                        <Col sm="3">
                                            <div className="content-section implementation p-fluid">
                                                <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={item.brancheFax} onChange={(e) => this.handleChangeBrancheFax(index, e)}></Chips>
                                            </div>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Email:
                                                </Form.Label>
                                        <Col sm="3">
                                            <div className="content-section implementation p-fluid">
                                                <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={item.brancheEmail} onChange={(e) => this.handleChangeBrancheEmail(index, e)}></Chips>
                                            </div>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Key Contact
                                            </Form.Label>
                                    </Form.Group>
                                    )}

                                    {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                            Name:
                                                </Form.Label>
                                        <Col sm="3">
                                            <Input value={item.brancheKeyName} style={{ width: "100%" }} onChange={(e) => this.handleChangeBrancheKeyName(index, e)} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                            Phone No:
                                                </Form.Label>
                                        <Col sm="3">
                                            <Input value={item.brancheKeyPhone} style={{ width: "100%" }} onChange={(e) => this.handleChangeBrancheKeyPhoneNo(index, e)} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                            Email:
                                                </Form.Label>
                                        <Col sm="3">
                                            <Input value={item.brancheKeyEmail} style={{ width: "100%" }} onChange={(e) => this.handleChangeBrancheKeyEmail(index, e)} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                </div>
                            ))}

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Col sm="2">
                                </Col>
                                <Col sm="3">
                                    <ReactButton id="VendorPortalBrancheSaveButton"
                                        style={{ width: '100%' }} onClick={this.saveChangesBranche} variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save Changes" />
                                    </ReactButton>
                                </Col>
                                <Col sm="3">
                                </Col>
                                <Col sm="2">
                                </Col>
                            </Form.Group>

                        </div>

                        <div hidden={this.state.vendorPagesHidden[6]} style={{ padding: "10px", marginTop: '2rem' }}>

                            <Form.Group as={Row}>
                                <Col sm="2">
                                </Col>
                                <Col sm="2">
                                    <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ manufacturing: e.target.checked }) }} checked={this.state.manufacturing}>Manufacturing - 1</Checkbox>
                                </Col>
                                {(this.state.manufacturing) && (
                                    <Col sm="4">
                                    </Col>
                                )}
                                {(this.state.manufacturing) && (this.state.manufacturingRows.length === 0) && (
                                    <Col sm="3">
                                        <AntButton type="primary" onClick={this.addNewManufacturingLine} icon={<PlusOutlined />}></AntButton>
                                    </Col>
                                )}
                            </Form.Group>

                            {(this.state.manufacturing) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Address:
                    </Form.Label>
                                <Col sm="3">
                                    <Input value={this.state.manufacturingAddress} style={{ width: "100%" }} onChange={(e) => { this.setState({ manufacturingAddress: e.target.value }) }} />
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>
                            )}

                            {(this.state.manufacturing) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Location:
                    </Form.Label>
                                <Col sm="3">
                                    <Input value={this.state.manufacturingLocation} style={{ width: "100%" }} onChange={(e) => { this.setState({ manufacturingLocation: e.target.value }) }} />
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>
                            )}

                            {(this.state.manufacturing) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Country:
                    </Form.Label>
                                <Col sm="3">
                                    <Form.Control name="manufacturingCountry" value={this.state.manufacturingCountry}
                                        onChange={this.handleChange} as="select">
                                        <option value="">Select Country</option>
                                        {optionsCountry}
                                    </Form.Control>
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>
                            )}

                            {(this.state.manufacturing) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    City:
                    </Form.Label>
                                <Col sm="3">
                                    <Input value={this.state.manufacturingCity} style={{ width: "100%" }} onChange={(e) => { this.setState({ manufacturingCity: e.target.value }) }} />
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>
                            )}

                            {(this.state.manufacturing) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Phone No:
                    </Form.Label>
                                <Col sm="3">
                                    <div className="content-section implementation p-fluid">
                                        <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.manufacturingPhone} onChange={(e) => this.setState({ manufacturingPhone: e.value })}></Chips>
                                    </div>
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>
                            )}

                            {(this.state.manufacturing) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Fax:
                    </Form.Label>
                                <Col sm="3">
                                    <div className="content-section implementation p-fluid">
                                        <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.manufacturingFax} onChange={(e) => this.setState({ manufacturingFax: e.value })}></Chips>
                                    </div>
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>
                            )}

                            {(this.state.manufacturing) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Email:
                    </Form.Label>
                                <Col sm="3">
                                    <div className="content-section implementation p-fluid">
                                        <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.manufacturingEmail} onChange={(e) => this.setState({ manufacturingEmail: e.value })}></Chips>
                                    </div>
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>
                            )}

                            {(this.state.manufacturing) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Key Contact
                    </Form.Label>
                            </Form.Group>
                            )}

                            {(this.state.manufacturing) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                    Name:
                    </Form.Label>
                                <Col sm="3">
                                    <Input value={this.state.manufacturingKeyName} style={{ width: "100%" }} onChange={(e) => { this.setState({ manufacturingKeyName: e.target.value }) }} />
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>
                            )}

                            {(this.state.manufacturing) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                    Phone No:
                    </Form.Label>
                                <Col sm="3">
                                    <Input value={this.state.manufacturingKeyPhone} style={{ width: "100%" }} onChange={(e) => { this.setState({ manufacturingKeyPhone: e.target.value }) }} />
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>
                            )}

                            {(this.state.manufacturing) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                    Email:
                    </Form.Label>
                                <Col sm="3">
                                    <Input value={this.state.manufacturingKeyEmail} style={{ width: "100%" }} onChange={(e) => { this.setState({ manufacturingKeyEmail: e.target.value }) }} />
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>
                            )}

                            {this.state.manufacturingRows.map((item, index) => (
                                <div>
                                    <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Col sm="2">
                                            <Checkbox onChange={(e) => this.handleChangeManufacturingChekbox(index, e)} checked={item.checked}>Manufacturing - {index + 2}</Checkbox>
                                        </Col>
                                        <Col sm="4">
                                        </Col>
                                        <Col sm="3">
                                            <Space>
                                                <AntButton type="primary" danger onClick={(e) => this.removeManufacturingLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                                {(this.state.manufacturingRows.length === (index + 1)) && (<AntButton type="primary" onClick={this.addNewManufacturingLine} icon={<PlusOutlined />}></AntButton>)}
                                            </Space>
                                        </Col>
                                    </Form.Group>

                                    {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Address:
                                </Form.Label>
                                        <Col sm="3">
                                            <Input value={item.manufacturingAddress} style={{ width: "100%" }} onChange={(e) => this.handleChangeManufacturingAddress(index, e)} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Location:
                                </Form.Label>
                                        <Col sm="3">
                                            <Input value={item.manufacturingLocation} style={{ width: "100%" }} onChange={(e) => this.handleChangeManufacturingLocation(index, e)} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Country:
                                </Form.Label>
                                        <Col sm="3">
                                            <Form.Control name="manufacturingCountry" value={item.manufacturingCountry}
                                                onChange={(e) => this.handleChangeManufacturingCountry(index, e)} as="select">
                                                <option value="">Select Country</option>
                                                {optionsCountry}
                                            </Form.Control>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            City:
                                </Form.Label>
                                        <Col sm="3">
                                            <Input value={item.manufacturingCity} style={{ width: "100%" }} onChange={(e) => this.handleChangeManufacturingCity(index, e)} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Phone No:
                                </Form.Label>
                                        <Col sm="3">
                                            <div className="content-section implementation p-fluid">
                                                <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={item.manufacturingPhone} onChange={(e) => this.handleChangeManufacturingPhone(index, e)}></Chips>
                                            </div>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Fax:
                            </Form.Label>
                                        <Col sm="3">
                                            <div className="content-section implementation p-fluid">
                                                <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={item.manufacturingFax} onChange={(e) => this.handleChangeManufacturingFax(index, e)}></Chips>
                                            </div>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Email:
                                </Form.Label>
                                        <Col sm="3">
                                            <div className="content-section implementation p-fluid">
                                                <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={item.manufacturingEmail} onChange={(e) => this.handleChangeManufacturingEmail(index, e)}></Chips>
                                            </div>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Key Contact
                                </Form.Label>
                                    </Form.Group>
                                    )}

                                    {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                            Name:
                                </Form.Label>
                                        <Col sm="3">
                                            <Input value={item.manufacturingKeyName} style={{ width: "100%" }} onChange={(e) => this.handleChangeManufacturingKeyName(index, e)} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                            Phone No:
                                </Form.Label>
                                        <Col sm="3">
                                            <Input value={item.manufacturingKeyPhone} style={{ width: "100%" }} onChange={(e) => this.handleChangeManufacturingKeyPhoneNo(index, e)} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                            Email:
                                </Form.Label>
                                        <Col sm="3">
                                            <Input value={item.manufacturingKeyEmail} style={{ width: "100%" }} onChange={(e) => this.handleChangeManufacturingKeyEmail(index, e)} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                </div>
                            ))}

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Col sm="2">
                                </Col>
                                <Col sm="3">
                                    <ReactButton id="VendorPortalManufacturingSaveButton"
                                        style={{ width: '100%' }} onClick={this.saveChangesManufacturing} variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save Changes" />
                                    </ReactButton>
                                </Col>
                                <Col sm="3">
                                </Col>
                                <Col sm="2">
                                </Col>
                            </Form.Group>

                        </div>

                        <div hidden={this.state.vendorPagesHidden[7]} style={{ padding: "10px", marginTop: '2rem' }}>

                            <Form.Group as={Row}>
                                <Col sm="2">
                                </Col>
                                <Col sm="2">
                                    <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ project: e.target.checked }) }} checked={this.state.project}>Project - 1</Checkbox>
                                </Col>
                                {(this.state.project) && (
                                    <Col sm="4">
                                    </Col>
                                )}
                                {(this.state.project) && (this.state.projectRows.length === 0) && (
                                    <Col sm="3">
                                        <AntButton type="primary" onClick={this.addNewProjectLine} icon={<PlusOutlined />}></AntButton>
                                    </Col>
                                )}
                            </Form.Group>

                            {(this.state.project) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Address:
                                        </Form.Label>
                                <Col sm="3">
                                    <Input value={this.state.projectAddress} style={{ width: "100%" }} onChange={(e) => { this.setState({ projectAddress: e.target.value }) }} />
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>
                            )}

                            {(this.state.project) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Location:
                                        </Form.Label>
                                <Col sm="3">
                                    <Input value={this.state.projectLocation} style={{ width: "100%" }} onChange={(e) => { this.setState({ projectLocation: e.target.value }) }} />
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>
                            )}

                            {(this.state.project) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Country:
                                        </Form.Label>
                                <Col sm="3">
                                    <Form.Control name="projectCountry" value={this.state.projectCountry}
                                        onChange={this.handleChange} as="select">
                                        <option value="">Select Country</option>
                                        {optionsCountry}
                                    </Form.Control>
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>
                            )}

                            {(this.state.project) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    City:
                                        </Form.Label>
                                <Col sm="3">
                                    <Input value={this.state.projectCity} style={{ width: "100%" }} onChange={(e) => { this.setState({ projectCity: e.target.value }) }} />
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>
                            )}

                            {(this.state.project) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Phone No:
                                        </Form.Label>
                                <Col sm="3">
                                    <div className="content-section implementation p-fluid">
                                        <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.projectPhone} onChange={(e) => this.setState({ projectPhone: e.value })}></Chips>
                                    </div>
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>
                            )}

                            {(this.state.project) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Fax:
                                        </Form.Label>
                                <Col sm="3">
                                    <div className="content-section implementation p-fluid">
                                        <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.projectFax} onChange={(e) => this.setState({ projectFax: e.value })}></Chips>
                                    </div>
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>
                            )}

                            {(this.state.project) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Email:
                                        </Form.Label>
                                <Col sm="3">
                                    <div className="content-section implementation p-fluid">
                                        <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.projectEmail} onChange={(e) => this.setState({ projectEmail: e.value })}></Chips>
                                    </div>
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>
                            )}

                            {(this.state.project) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Key Contact
                                        </Form.Label>
                            </Form.Group>
                            )}

                            {(this.state.project) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                    Name:
                                        </Form.Label>
                                <Col sm="3">
                                    <Input value={this.state.projectKeyName} style={{ width: "100%" }} onChange={(e) => { this.setState({ projectKeyName: e.target.value }) }} />
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>
                            )}

                            {(this.state.project) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                    Phone No:
                                        </Form.Label>
                                <Col sm="3">
                                    <Input value={this.state.projectKeyPhone} style={{ width: "100%" }} onChange={(e) => { this.setState({ projectKeyPhone: e.target.value }) }} />
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>
                            )}

                            {(this.state.project) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                    Email:
                                        </Form.Label>
                                <Col sm="3">
                                    <Input value={this.state.projectKeyEmail} style={{ width: "100%" }} onChange={(e) => { this.setState({ projectKeyEmail: e.target.value }) }} />
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>
                            )}

                            {this.state.projectRows.map((item, index) => (
                                <div>
                                    <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Col sm="2">
                                            <Checkbox onChange={(e) => this.handleChangeProjectChekbox(index, e)} checked={item.checked}>Project - {index + 2}</Checkbox>
                                        </Col>
                                        <Col sm="4">
                                        </Col>
                                        <Col sm="3">
                                            <Space>
                                                <AntButton type="primary" danger onClick={(e) => this.removeProjectLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                                {(this.state.projectRows.length === (index + 1)) && (<AntButton type="primary" onClick={this.addNewProjectLine} icon={<PlusOutlined />}></AntButton>)}
                                            </Space>
                                        </Col>
                                    </Form.Group>

                                    {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Address:
                                                </Form.Label>
                                        <Col sm="3">
                                            <Input value={item.projectAddress} style={{ width: "100%" }} onChange={(e) => this.handleChangeProjectAddress(index, e)} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Location:
                                                </Form.Label>
                                        <Col sm="3">
                                            <Input value={item.projectLocation} style={{ width: "100%" }} onChange={(e) => this.handleChangeProjectLocation(index, e)} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Country:
                                                </Form.Label>
                                        <Col sm="3">
                                            <Form.Control name="projectCountry" value={item.projectCountry}
                                                onChange={(e) => this.handleChangeProjectCountry(index, e)} as="select">
                                                <option value="">Select Country</option>
                                                {optionsCountry}
                                            </Form.Control>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            City:
                                                </Form.Label>
                                        <Col sm="3">
                                            <Input value={item.projectCity} style={{ width: "100%" }} onChange={(e) => this.handleChangeProjectCity(index, e)} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Phone No:
                                                </Form.Label>
                                        <Col sm="3">
                                            <div className="content-section implementation p-fluid">
                                                <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={item.projectPhone} onChange={(e) => this.handleChangeProjectPhone(index, e)}></Chips>
                                            </div>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Fax:
                                                </Form.Label>
                                        <Col sm="3">
                                            <div className="content-section implementation p-fluid">
                                                <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={item.projectFax} onChange={(e) => this.handleChangeProjectFax(index, e)}></Chips>
                                            </div>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Email:
                                                </Form.Label>
                                        <Col sm="3">
                                            <div className="content-section implementation p-fluid">
                                                <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={item.projectEmail} onChange={(e) => this.handleChangeProjectEmail(index, e)}></Chips>
                                            </div>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Key Contact
                                                </Form.Label>
                                    </Form.Group>
                                    )}

                                    {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                            Name:
                                                </Form.Label>
                                        <Col sm="3">
                                            <Input value={item.projectKeyName} style={{ width: "100%" }} onChange={(e) => this.handleChangeProjectKeyName(index, e)} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                            Phone No:
                                                </Form.Label>
                                        <Col sm="3">
                                            <Input value={item.projectKeyPhone} style={{ width: "100%" }} onChange={(e) => this.handleChangeProjectKeyPhoneNo(index, e)} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                    {(item.checked) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black', textAlign: 'right' }} column sm="2">
                                            Email:
                                                </Form.Label>
                                        <Col sm="3">
                                            <Input value={item.projectKeyEmail} style={{ width: "100%" }} onChange={(e) => this.handleChangeProjectKeyEmail(index, e)} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>
                                    )}

                                </div>
                            ))}

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Col sm="2">
                                </Col>
                                <Col sm="3">
                                    <ReactButton id="VendorPortalProjectsSaveButton"
                                        style={{ width: '100%' }} onClick={this.saveChangesProjects} variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save Changes" />
                                    </ReactButton>
                                </Col>
                                <Col sm="3">
                                </Col>
                                <Col sm="2">
                                </Col>
                            </Form.Group>

                        </div>

                        <div hidden={this.state.vendorPagesHidden[8]} style={{ padding: "10px", marginTop: '2rem' }}>

                            <Form.Group as={Row}>
                                <Col sm="3">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Code:
                                </Form.Label>
                                <Col sm="2">
                                    <Input value={this.state.productCode} style={{ width: "100%" }} onChange={(e) => { this.setState({ productCode: e.target.value }) }} />
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="3">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Description:
                                </Form.Label>
                                <Col sm="2">
                                    <Input value={this.state.productDescription} style={{ width: "100%" }} onChange={(e) => { this.setState({ productDescription: e.target.value }) }} />
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="3">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Price
                                </Form.Label>
                                <Col sm="2">
                                    <Space>
                                        <InputNumber value={this.state.productPrice} onChange={(value) => { this.setState({ productPrice: value }) }} />
                                        <Select placeholder="Select a Currency" value={this.state.productPriceCurrency} onChange={(value) => { this.setState({ productPriceCurrency: value }) }} >
                                            <Option value="$">$</Option>
                                            <Option value="€">€</Option>
                                            <Option value="SR">SR</Option>
                                        </Select>
                                    </Space>
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="3">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Product Photo
                                </Form.Label>
                                <Col sm="2">
                                    <Upload
                                        name="avatar"
                                        listType="picture-card"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        action={`${API_BASE}/VendorRecord/upload`}
                                        beforeUpload={this.beforeUpload}
                                        onChange={this.handleProductPhotoChange}
                                    >
                                        {this.state.imageUrl2 ? <img src={this.state.imageUrl2} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                    </Upload>
                                </Col>
                            </Form.Group>

                            {(this.state.productSave) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="5">
                                </Col>
                                <Col sm="3">
                                    <Button onClick={this.addProduct} type="button">Add</Button>
                                </Col>
                                <Col sm="2">
                                </Col>
                            </Form.Group>
                            )}

                            {(!this.state.productSave) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="5">
                                </Col>
                                <Col sm="3">
                                    <Space>
                                        <Button variant="warning" onClick={this.updateProduct} type="button">Update</Button>
                                        <Button variant="secondary" onClick={this.cancelProduct} type="button">Cancel</Button>
                                    </Space>
                                </Col>
                                <Col sm="2">
                                </Col>
                            </Form.Group>
                            )}

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Col sm="8">
                                    <Table style={{ marginTop: '1rem' }} size={`small`} dataSource={this.state.productList} bordered >
                                        <Column
                                            width='50px'
                                            title="Action"
                                            key="action"
                                            render={(text, record) => (
                                                <React.Fragment key={record.id}>
                                                    <CustomMaterialMenu row={record} onDeleteRow={this.deleteProductModal.bind(this)} onEditRow={this.editProduct.bind(this)} />
                                                </React.Fragment>
                                            )}
                                        />
                                        <Column
                                            width='50px'
                                            title="S/N"
                                            key="sn"
                                            render={(text, record) => {
                                                let objIndex = this.state.productList.findIndex((obj => obj.productIndex === record.productIndex));
                                                return (<div style={{ textAlign: "center" }}>{objIndex + 1}</div>);
                                            }}
                                        />
                                        <Column title="Code" key="productCode" dataIndex="productCode" />
                                        <Column title="Description" key="productDescription" dataIndex="productDescription" />
                                        <Column title="Price" key="productPrice"
                                            render={(text, record) => {
                                                return (<div style={{ textAlign: "center" }}>{record.productPrice} {record.productPriceCurrency}</div>);
                                            }}
                                        />
                                        <Column title="Product Photo" key="productPhoto"
                                            render={(text, record) => {
                                                return (<div style={{ textAlign: "center" }}></div>);
                                            }}
                                        />
                                    </Table>
                                </Col>
                                <Col sm="2">
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Col sm="2">
                                </Col>
                                <Col sm="3">
                                    <ReactButton id="VendorPortalProductsSaveButton"
                                        style={{ width: '100%' }} onClick={this.saveChangesProducts} variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save Changes" />
                                    </ReactButton>
                                </Col>
                                <Col sm="3">
                                </Col>
                                <Col sm="2">
                                </Col>
                            </Form.Group>

                        </div>

                        <div hidden={this.state.vendorPagesHidden[9]} style={{ padding: "10px", marginTop: '2rem' }}>
                            <Form.Group as={Row}>
                                <Col sm="3">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Code:
                                        </Form.Label>
                                <Col sm="2">
                                    <Input value={this.state.serviceCode} style={{ width: "100%" }} onChange={(e) => { this.setState({ serviceCode: e.target.value }) }} />
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="3">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Description:
                                        </Form.Label>
                                <Col sm="2">
                                    <Input value={this.state.serviceDescription} style={{ width: "100%" }} onChange={(e) => { this.setState({ serviceDescription: e.target.value }) }} />
                                </Col>
                            </Form.Group>

                            {(this.state.serviceSave) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="5">
                                </Col>
                                <Col sm="3">
                                    <Button onClick={this.addService} type="button">Add</Button>
                                </Col>
                                <Col sm="2">
                                </Col>
                            </Form.Group>
                            )}

                            {(!this.state.serviceSave) && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="5">
                                </Col>
                                <Col sm="3">
                                    <Space>
                                        <Button variant="warning" onClick={this.updateService} type="button">Update</Button>
                                        <Button variant="secondary" onClick={this.cancelService} type="button">Cancel</Button>
                                    </Space>
                                </Col>
                                <Col sm="2">
                                </Col>
                            </Form.Group>
                            )}

                            <Form.Group as={Row}>
                                <Col sm="2">
                                </Col>
                                <Col sm="8">
                                    <Table style={{ marginTop: '1rem' }} size={`small`} dataSource={this.state.serviceList} bordered >
                                        <Column
                                            width='50px'
                                            title="Action"
                                            key="action"
                                            render={(text, record) => (
                                                <React.Fragment key={record.id}>
                                                    <CustomMaterialMenu row={record} onDeleteRow={this.deleteServiceModal.bind(this)} onEditRow={this.editService.bind(this)} />
                                                </React.Fragment>
                                            )}
                                        />
                                        <Column
                                            width='50px'
                                            title="S/N"
                                            key="sn"
                                            render={(text, record) => {
                                                let objIndex = this.state.serviceList.findIndex((obj => obj.serviceIndex === record.serviceIndex));
                                                return (<div style={{ textAlign: "center" }}>{objIndex + 1}</div>);
                                            }}
                                        />
                                        <Column title="Code" key="serviceCode" dataIndex="serviceCode" />
                                        <Column title="Description" key="serviceDescription" dataIndex="serviceDescription" />
                                    </Table>
                                </Col>
                                <Col sm="2">
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Col sm="2">
                                </Col>
                                <Col sm="3">
                                    <ReactButton id="VendorPortalServicesSaveButton"
                                        style={{ width: '100%' }} onClick={this.saveChangesServices} variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save Changes" />
                                    </ReactButton>
                                </Col>
                                <Col sm="3">
                                </Col>
                                <Col sm="2">
                                </Col>
                            </Form.Group>


                        </div>

                        <div hidden={this.state.vendorPagesHidden[10]} style={{ padding: "10px", marginTop: '2rem' }}>
                            <Form.Group as={Row}>
                                <Col sm="2">
                                </Col>
                                <Col sm="2">
                                    <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ financialPosition: e.target.checked }) }} checked={this.state.financialPosition}>Financial Position</Checkbox>
                                </Col>
                                {(this.state.financialPosition) && (
                                    <Col sm="3">
                                    </Col>)}
                                {(this.state.financialPosition) && (
                                    <Col sm="1">
                                        <AntButton type="primary" onClick={this.addNewFinancialPositionLine} icon={<PlusOutlined />}></AntButton>
                                    </Col>
                                )}
                            </Form.Group>

                            {this.state.financialPositionRows.map((item, index) => (
                                (index < 3) && (this.state.financialPosition) && (
                                    <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            {item.description}
                                        </Form.Label>
                                        <Col sm="2">
                                            <Input value={item.value} style={{ width: "100%" }} onChange={(e) => this.handleChangeFinancialPositionValue(index, e)} />
                                        </Col>
                                    </Form.Group>
                                )
                            ))}

                            {this.state.financialPositionRows.map((item, index) => (
                                (index >= 3) && (this.state.financialPosition) && (
                                    <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Col sm="2">
                                            <Input value={item.description} style={{ width: "100%" }} onChange={(e) => this.handleChangeFinancialPositionDescription(index, e)} />
                                        </Col>
                                        <Col sm="2">
                                            <Input value={item.value} style={{ width: "100%" }} onChange={(e) => this.handleChangeFinancialPositionValue(index, e)} />
                                        </Col>
                                        <Col sm="1">
                                            <AntButton type="primary" danger onClick={(e) => this.removeFinancialPositionLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                        </Col>
                                    </Form.Group>
                                )
                            ))}

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Col sm="2">
                                </Col>
                                <Col sm="3">
                                    <ReactButton id="VendorPortalFinancialPositionSaveButton"
                                        style={{ width: '100%' }} onClick={this.saveChangesFinancialPosition} variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save Changes" />
                                    </ReactButton>
                                </Col>
                                <Col sm="3">
                                </Col>
                                <Col sm="2">
                                </Col>
                            </Form.Group>

                        </div>

                        <div hidden={this.state.vendorPagesHidden[11]} style={{ padding: "10px", marginTop: '2rem' }}>
                            <Form.Group as={Row}>
                                <Col sm="2">
                                </Col>
                                <Col sm="2">
                                    <Checkbox style={{ color: 'black', marginTop: '0.25rem' }} onChange={(e) => { this.setState({ bankersInformation: e.target.checked }) }} checked={this.state.bankersInformation}>Bankers Information</Checkbox>
                                </Col>
                                {(this.state.bankersInformation) && (
                                    <Col sm="3">
                                    </Col>)}
                                {(this.state.bankersInformation) && (
                                    <Col sm="1">
                                        <AntButton type="primary" onClick={this.addNewBankersInformationLine} icon={<PlusOutlined />}></AntButton>
                                    </Col>
                                )}
                            </Form.Group>

                            {this.state.bankersInformationRows.map((item, index) => (
                                (index < 1) && (this.state.bankersInformation) && (
                                    <div>
                                        <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="2">
                                            </Col>
                                            <Form.Label style={{ color: 'black' }} column sm="2">
                                                Bank Name
                                    </Form.Label>
                                            <Col sm="2">
                                                <Input value={item.bankName} style={{ width: "100%" }} onChange={(e) => this.handleChangeBankersInformationBankName(index, e)} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="2">
                                            </Col>
                                            <Form.Label style={{ color: 'black' }} column sm="2">
                                                IBAN No
                                    </Form.Label>
                                            <Col sm="2">
                                                <Input value={item.IBANNo} style={{ width: "100%" }} onChange={(e) => this.handleChangeBankersInformationIBANNo(index, e)} />
                                            </Col>
                                        </Form.Group>
                                    </div>
                                )
                            ))}

                            {this.state.bankersInformationRows.map((item, index) => (
                                (index >= 1) && (this.state.bankersInformation) && (
                                    <div>
                                        <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="2">
                                            </Col>
                                            <Form.Label style={{ color: 'black' }} column sm="2">
                                                Bank Name
                                    </Form.Label>
                                            <Col sm="2">
                                                <Input value={item.bankName} style={{ width: "100%" }} onChange={(e) => this.handleChangeBankersInformationBankName(index, e)} />
                                            </Col>
                                            <Col sm="1">
                                                <AntButton type="primary" danger onClick={(e) => this.removeBankersInformationLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="2">
                                            </Col>
                                            <Form.Label style={{ color: 'black' }} column sm="2">
                                                IBAN No
                                    </Form.Label>
                                            <Col sm="2">
                                                <Input value={item.IBANNo} style={{ width: "100%" }} onChange={(e) => this.handleChangeBankersInformationIBANNo(index, e)} />
                                            </Col>
                                        </Form.Group>
                                    </div>
                                )
                            ))}

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Col sm="2">
                                </Col>
                                <Col sm="3">
                                    <ReactButton id="VendorPortalBankersInformationSaveButton"
                                        style={{ width: '100%' }} onClick={this.saveChangesBankersInformation} variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save Changes" />
                                    </ReactButton>
                                </Col>
                                <Col sm="3">
                                </Col>
                                <Col sm="2">
                                </Col>
                            </Form.Group>

                        </div>

                        <div hidden={this.state.vendorPagesHidden[12]} style={{ padding: "10px", marginTop: '2rem' }}>
                            Questionnary Page Content
                        </div>

                        <div hidden={this.state.vendorPagesHidden[13]} style={{ padding: "10px", marginTop: '2rem' }}>

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Form.Label column sm="2" style={{ fontSize: '14px', fontWeight: 'bold', color: '#d35400' }} >
                                    Completed Projects
                                </Form.Label>
                                <Col sm="4">
                                </Col>
                                <Col sm="1">
                                    {(this.state.completedProjectsRows.length === 0) && (<AntButton type="primary" onClick={this.addNewCompletedProjectsLine} icon={<PlusOutlined />}></AntButton>)}
                                </Col>
                            </Form.Group>

                            {this.state.completedProjectsRows.map((item, index) => (
                                <div>
                                    <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Project - {index + 1}
                                        </Form.Label>
                                        <Col sm="2">
                                        </Col>
                                        <Col sm="1">
                                            <Space>
                                                <AntButton type="primary" danger onClick={(e) => this.removeCompletedProjectsLine(index, e)} icon={<CloseOutlined />}></AntButton>
                                                {(this.state.completedProjectsRows.length > 0) && (this.state.completedProjectsRows.length === (index + 1)) && (<AntButton type="primary" onClick={this.addNewCompletedProjectsLine} icon={<PlusOutlined />}></AntButton>)}
                                            </Space>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Name of Customer/Client:
                                        </Form.Label>
                                        <Col sm="2">
                                            <Input value={item.nameOfCustomer} style={{ width: "100%" }} onChange={(e) => this.handleChangeCompletedProjectsNameOfCustomer(index, e)} />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Project Name
                                        </Form.Label>
                                        <Col sm="2">
                                            <Input value={item.projectName} style={{ width: "100%" }} onChange={(e) => this.handleChangeCompletedProjectsProjectName(index, e)} />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Location
                                        </Form.Label>
                                        <Col sm="2">
                                            <Input value={item.location} style={{ width: "100%" }} onChange={(e) => this.handleChangeCompletedProjectsLocation(index, e)} />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Years
                                        </Form.Label>
                                        <Col sm="2">
                                            <Input value={item.years} style={{ width: "100%" }} onChange={(e) => this.handleChangeCompletedProjectsYears(index, e)} />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                            Total Value
                                        </Form.Label>
                                        <Col sm="2">
                                            <Space>
                                                <Input value={item.totalValue} style={{ width: "100%" }} onChange={(e) => this.handleChangeCompletedProjectsTotalValue(index, e)} />
                                                <Select placeholder="Select a Currency" onChange={(value) => this.handleChangeCompletedProjectsTotalValueCurrency(index, value)} >
                                                    <Option value="$">$</Option>
                                                    <Option value="€">€</Option>
                                                    <Option value="SR">SR</Option>
                                                </Select>
                                            </Space>
                                        </Col>
                                    </Form.Group>

                                </div>
                            ))}

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2">
                                </Col>
                                <Col sm="2">
                                </Col>
                                <Col sm="3">
                                    <ReactButton id="VendorPortalCompletedProjectsSaveButton"
                                        style={{ width: '100%' }} onClick={this.saveChangesCompletedProjects} variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save Changes" />
                                    </ReactButton>
                                </Col>
                                <Col sm="3">
                                </Col>
                                <Col sm="2">
                                </Col>
                            </Form.Group>

                        </div>

                        <div hidden={this.state.vendorPagesHidden[14]} style={{ padding: "10px", marginTop: '2rem' }}>
                            History Page Content
                        </div>
                    </Card>

               </MenuProvider>
                {
                    !this.state.whatIsSetting &&
                    <RCMenu id='menu_id'>
                        <Item onClick={this.dynamicInputs}>What is This?</Item>
                    </RCMenu>
                }
            </div>
        )
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(VendorPortal)