import React, { Component } from 'react'
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import { handleRequest } from '../../redux/config/env';
import * as Mnd from "../../partials/content/CustomComponents";
import MNDTableSettings from '../MND/MNDTableSettings';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { Form } from 'antd';
import { Button as PrimeButton } from "primereact/button";
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import 'react-pdfjs-multi/dist/react-pdfjs-multi.css';
import { Redirect } from "react-router-dom";
import { ExcelReader } from '../excel/ExcelReader';

const expandable = { expandedRowRender: record => <b>{record.id}</b> };
const title = () => 'Here is title';
var footer = () => 'Here is footer';

class SatipLog extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hiddenUpdate: true,
      pdfFiles: [],
      satipLogTable: [],
      dynamicAttributes: [],
      dynamicInputs: [],
      authorizations: [],
      listSatipLog: [],
      modalPdfViewer: false
    };
  }

  formRef = React.createRef();

  componentDidMount = async () => {
    const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "SatipLog" });
    if (responseDP.data.length > 0) {
      this.setState({
        dynamicAttributes: responseDP.data
      });
    } else {
      this.setState({
        dynamicAttributes: []
      });
    }

    const obj = {
      pageName: "SatipLog",
    };
    const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
    if (responseDynamicInputs.data.length !== 0) {
      this.setState({
        dynamicInputs: responseDynamicInputs.data,
      });
    } else {
      this.setState({
        dynamicInputs: [],
      });
    }

    const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "SatipLog");
    try {
      this.setState({
        authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
      });
    } catch (error) {
    }

    this.getTableProperties();
    this.getSatipLog();
  }

  getTableProperties = async () => {
    this.satipLog = JSON.parse(localStorage.getItem('satipLog'));
    if (this.satipLog != null && this.satipLog != undefined && this.satipLog != "") {

      await this.setState({
        bordered: this.satipLog.bordered,
        title: this.satipLog.titleDrm == true ? title : undefined,
        titleDrm: this.satipLog.titleDrm,
        showHeader: this.satipLog.showHeader,
        footer: this.satipLog.footerDrm == true ? footer : undefined,
        footerDrm: this.satipLog.footerDrm,
        expandable: this.satipLog.expandableDrm == true ? expandable : {},
        expandableDrm: this.satipLog.expandableDrm,
        rowSelection: this.satipLog.rowSelection,
        ellipsis: this.satipLog.ellipsis,
        tableLayout: this.satipLog.tableLayout,
        size: this.satipLog.size,
        top: this.satipLog.top,
        bottom: this.satipLog.bottom,
        editBgColor: this.satipLog.editBgColor,
        deleteBgColor: this.satipLog.deleteBgColor,
        selectedNodeKey: null,
        yScroll: this.satipLog.yScroll,
        xScroll: this.satipLog.xScroll,
        paginationDisabled: {
          defaultPageSize: 5, total: this.state.satipLogTable.length,
          showQuickJumper: true, showSizeChanger: true,
          pageSizeOptions: ['5', '10', '25', '50'], total: this.state.satipLogTable.length, showQuickJumper: true,
          position: [this.satipLog.top, this.satipLog.bottom]
        },
        kontrol: true,
      });
    } else {
      await this.setState({
        bordered: false,
        title: undefined,
        titleDrm: false,
        showHeader: true,
        footer: undefined,
        footerDrm: false,
        expandable: false,
        expandableDrm: false,
        rowSelection: undefined,
        ellipsis: false,
        tableLayout: undefined,
        size: 'default',
        top: 'none',
        bottom: 'bottomRight',
        editBgColor: '#fff',
        deleteBgColor: '#fff',
        selectedNodeKey: null,
        xScroll: undefined,
        paginationDisabled: false,
        kontrol: true,
      });
    }
  }

  getSatipLog = async () => {
    const response = await handleRequest("GET", "/SatipLog/getallByCompany");
    if (response.data.length === 0) {
      this.setState({
        satipLogTable: [],
        modalHistory: false,
        modalImportfromExcel: false,
      });
    }

    if (response.data.length !== 0) {
      this.setState({
        satipLogTable: response.data,
        modalHistory: false,
        modalImportfromExcel: false,
      });
    }
  }

  showSuccess(msg) {
    if (!msg) {
      msg = 'Successful';
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Success"
    });
  };

  showError(msg) {
    if (!msg) {
      msg = 'An error was occured please try again later !';
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Error"
    });
  };

  showWarning(msg) {
    if (!msg) {
      msg = 'Something went wrong. Please check inputs.';
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Warning"
    });
  };

  showOrHideModalMessage = () => {
    this.setState({
      modalMessage: !this.state.modalMessage,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showOrHideWarningMesaj = () => {
    this.setState({
      modalWarningMesaj: !this.state.modalWarningMesaj,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  showOrHidePhotoModal = () => {
    this.setState({
      modalPhotoShow: !this.state.modalPhotoShow,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  showOrHideModalTable = async (tableTip) => {
    this.setState({
      modalTable: !this.state.modalTable,
      tableTip: tableTip,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  handleExcelData = async (excelJson) => {
    var list = [];
    if (excelJson === null)
      this.showError("Select an excel file");
    else {
      excelJson.forEach(element => {
        const obj = {
          discipline1: element["Discipline-1"],
          discipline2: element["Discipline-2"],
          satipNo: element["SATIP no"],
          revNo: element["Rev no"],
          satipDescription: element["SATIP Description"],
          approvedDate: element["Approved Date"],
          itemNo: element["Item No"],
          projectPhaseActivityCode: element["Project Phase Activity Code"],
          taskActivity: element["Task Activity"],
          qualityProcedure: element["Quality Procedure"],
          qualityRecord: element["Quality Record"],
          xxIr: element["xx IR"],
          contractorIr: element["Contractor IR"],
          saudiAramcoIr: element["SAUDI ARAMCO IR"],
          sapmtCommTeam: element["SAPMT COMM TEAM"],
          remarks: element["Remarks"],
          eskiyiSil: 0,
        }
        list.push(obj);
      });
      if (Boolean(list) && list.length > 0) {
        try {
          var response = await handleRequest("POST", "/SatipLog/importSatipLog", list);
          if (response.data) {
            if (response.data === "WARNING") {
              this.setState({
                listSatipLog: list,
              });
              this.showOrHideWarningMesaj();
            }
            else if (response.data === "ERROR") {
              this.showWarning(<FormattedMessage id="GeneralMessageNoRecordsAdd" defaultMessage="No records to add" />);
            } else if (response.data === "SUCCESS") {
              this.showSuccess();
              this.getSatipLog();
            }
          }
        } catch (error) {
          this.showError();
        }
      } else {
        this.showError();
      }
    }
  }

  aktarExcel = async (eskiyiSil) => {
    if (Boolean(this.state.listSatipLog) && this.state.listSatipLog.length > 0) {
      this.state.listSatipLog.forEach(element => {
        element.eskiyiSil = eskiyiSil;
      });
      var response = await handleRequest("POST", "/SatipLog/importSatipLog", this.state.listSatipLog);
      if (response.data) {
        if (response.data === "ERROR") {
          this.showWarning(<FormattedMessage id="GeneralMessageNoRecordsAdd" defaultMessage="No records to add" />);
        } else if (response.data === "SUCCESS") {
          this.showSuccess();
          this.getSatipLog();
        }
      }
    } else {
      this.showError();
    }
    this.showOrHideWarningMesaj();
  }

  exportSatipLog = async () => {
    var response = await handleRequest("GET", "/SatipLog/exportSatipLog");
    if (response.data != null && response.data != undefined && response.data.url != null && response.data.url != undefined && response.data.url != "") {
      window.location.href = response.data.url;
    } else {
      this.showError(<FormattedMessage id="GeneralMessageFileCantFound" defaultMessage="File cant found." />);
    }
  }

  showReport = async (record) => {

    //() => axios.get(`${API_BASE}/SatipLog/getSatipLog/` + this.props.auth.user.id + `/` + record.satipNo)

    var response = await handleRequest("GET", "/SatipLog/getSatipLog/" + this.props.auth.user.id + "/" + record.satipNo);
    if (response.type === "ERROR") {
      this.showError("An error occurred while generating the report.")
    }
    else {
      const fileRealUrl = response.data;
      this.setState({
        pdfFile: fileRealUrl,
        modalPdfViewer: true,
      });
    }

  }

  render() {
    const columns = [
      {
        dataIndex: "index",
        key: "key",
        title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("satipLog")} icon="pi pi-cog"></PrimeButton>,
      },
      {
        dataIndex: "discipline1",
        key: "key",
        title: <FormattedMessage id="SatipLogDiscipline1" defaultMessage="Discipline-1" />,
      },
      {
        dataIndex: "discipline2",
        key: "key",
        title: <FormattedMessage id="SatipLogDiscipline2" defaultMessage="Discipline-2" />,
      },
      {
        dataIndex: "satipNo",
        key: "key",
        title: <FormattedMessage id="SatipLogSatipNo" defaultMessage="SATIP no" />,
        render: (text, record) => {
          return (<React.Fragment>
            <div style={{ cursor: "pointer", textDecoration: "underline", fontWeight: "bold" }}
              onClick={() => this.showReport(record)}>
              {record.satipNo}
            </div>
          </React.Fragment>);
        }
      },
      {
        dataIndex: "revNo",
        key: "key",
        title: <FormattedMessage id="SatipLogRevNo" defaultMessage="Rev no" />,
      },
      {
        dataIndex: "satipDescription",
        key: "key",
        title: <FormattedMessage id="SatipLogSatipDescription" defaultMessage="SATIP Description" />,
      },
      {
        dataIndex: "approvedDate",
        key: "key",
        title: <FormattedMessage id="SatipLogApprovedDate" defaultMessage="Approved Date" />,
      },
      {
        dataIndex: "itemNo",
        key: "key",
        title: <FormattedMessage id="SatipLogItemNo" defaultMessage="Item No" />,
      },
      {
        dataIndex: "projectPhaseActivityCode",
        key: "key",
        title: <FormattedMessage id="SatipLogProjectPhaseActivityCode" defaultMessage="Project Phase & Activity Code" />,
      },
      {
        dataIndex: "taskActivity",
        key: "key",
        title: <FormattedMessage id="SatipLogTaskActivity" defaultMessage="Task/Activity" />,
      },
      {
        dataIndex: "qualityProcedure",
        key: "key",
        title: <FormattedMessage id="SatipLogQualityProcedure" defaultMessage="Quality Procedure" />,
      },
      {
        dataIndex: "qualityRecord",
        key: "key",
        title: <FormattedMessage id="SatipLogQualityRecord" defaultMessage="Quality Record" />,
      },
      {
        dataIndex: "xxIr",
        key: "key",
        title: <FormattedMessage id="SatipLogXXIr" defaultMessage="xx IR" />,
      },
      {
        dataIndex: "contractorIr",
        key: "key",
        title: <FormattedMessage id="SatipLogContractorIr" defaultMessage="Contractor IR" />,
      },
      {
        dataIndex: "saudiAramcoIr",
        key: "key",
        title: <FormattedMessage id="SatipLogSaudiAramcoIr" defaultMessage="SAUDI ARAMCO IR" />,
      },
      {
        dataIndex: "sapmtCommTeam",
        key: "key",
        title: <FormattedMessage id="SatipLogSapmtCommTeam" defaultMessage="SAPMT COMM TEAM" />,
      },
      {
        dataIndex: "remarks",
        key: "key",
        title: <FormattedMessage id="SatipLogRemarks" defaultMessage="Remarks" />,
      },
    ];

    const tableColumns = columns.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
    if (this.state.xScroll === 'fixed') {
      tableColumns[0].fixed = true;
      tableColumns[tableColumns.length - 1].fixed = 'right';
    }

    const layoutView = (
      isSidebarOpened,
      container,
      main,
      toolbar,
      sidebar,
    ) => {
      return defaultLayout(
        isSidebarOpened,
        container,
        main,
        toolbar(renderToolbar),
        sidebar,
      );
    };

    const onFinish = values => {
      console.log('Success:', values);
    };

    const onFinishFailed = errorInfo => {
      console.log('Failed:', errorInfo);
    };

    const layout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 8 },
    };

    const renderToolbar = (toolbarSlot) => {
      return (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            width: '100%',
          }}
        >
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexGrow: 1,
              flexShrink: 1,
              justifyContent: 'center',
            }}
          >
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.previousPageButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.nextPageButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomOutButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomPopover}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.zoomInButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.downloadButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.moreActionsPopover}
            </div>
          </div>
        </div>
      );
    };

    return this.state.authorizations.length > 0 && (this.state.authorizations.findIndex(p => p.action === "view") == -1 ? (<div><Redirect to="/error/error-v6" /></div>) : (
      <div>
        <Form
          {...layout}
          initialValues={{ remember: false }}
          onFinish={this.state.hiddenUpdate == false ? this.updateSatipLog : this.saveSatipLog}
          onFinishFailed={onFinishFailed}
          ref={this.formRef} >

          <Row>
            <Col xs={6}></Col>
            <Col xs={2}>
              <Button variant="success" block onClick={this.exportSatipLog}><FormattedMessage id="GeneralTextExportData" defaultMessage="Export Data" /></Button>
            </Col>
            <Col xs={3}>
              <ExcelReader onCreateJson={this.handleExcelData.bind(this)}></ExcelReader>
            </Col>
            <Col xs={1}>
              <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable("satipLog")} icon="pi pi-cog"></PrimeButton>
            </Col>
          </Row>
          <br />
          <br />

          <div className="datatable-doc-demo">
            <div className="content-section implementation">
              <Mnd.MNDTable
                state={this.state}
                columns={tableColumns} dataSource={this.state.satipLogTable}
                pagination={true}
                expandable={this.state.expandable}
                footer={this.state.footer}
                title={this.state.title}
                scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
              />
            </div>
          </div>

        </Form>

        <Modal
          show={this.state.modalWarningMesaj}
          onHide={this.showOrHideWarningMesaj}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered size="md">

          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  <FormattedMessage id="SatipLogGeneralWarningMesaj" defaultMessage="There is same record in database. Do you want it replace?" />
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row >
              <Col sm="3">
              </Col>
              <Col sm="3">
                <Button variant="success" onClick={(e) => this.aktarExcel(1)} style={{ width: "100%" }}><FormattedMessage id="GeneralTextYes" defaultMessage="Yes" /></Button>
              </Col>
              <Col sm="3">
                <Button variant="warning" onClick={(e) => this.aktarExcel(2)} style={{ width: "100%" }}><FormattedMessage id="GeneralTextNo" defaultMessage="No" /></Button>
              </Col>
              <Col sm="3">
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.modalMessage}
          onHide={this.showOrHideModalMessage}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >

          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  {this.state.messageTip == "Success" &&
                    <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                  {this.state.messageTip == "Warning" &&
                    <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                  {this.state.messageTip == "Error" &&
                    <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row >
              <Col sm="12">
                {this.state.message}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="xl"
          show={this.state.modalPhotoShow}
          onHide={this.showOrHidePhotoModal}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Photo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.imageUrl !== "" ? (
              <img
                src={this.state.imageUrl}
                alt="avatar"
                style={{ width: "100%" }}
              />
            ) : (
              <p>Photo has not been uploaded.</p>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={this.showOrHidePhotoModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="xl"
          show={this.state.modalPdfViewer}
          onHide={() => {
            this.setState({
              modalPdfViewer: false
            })
          }}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              PDF Report Viewer
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
              <div style={{ height: "900px" }}>
                <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
              </div>
            </Worker>

          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({
                  modalPdfViewer: false
                })
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalTable}
          onHide={this.showOrHideModalTable}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered size="lg">

          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  <FormattedMessage id="GeneralTableSettings" defaultMessage="Table Settings" />
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row >
              <Col sm="12">
                <MNDTableSettings id={this.state.tableTip} />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.deleteModal}
          onHide={this.showOrHideModalDelete}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
            </p>
            <p style={{ textAlign: "center", color: "black" }}>
              <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalDelete}>
              <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
            </Button>
            <Button variant="danger" onClick={this.deleteSatipLogOK}>
              <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    ));
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    i18n: state.i18n
  }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(SatipLog)