import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Col, Form, Row } from 'react-bootstrap';
//import CustomMaterialMenu from './ForComponents/CustomMaterialMenu';
import { handleRequest, API_BASE } from '../../redux/config/env';
import { Dropdown as PrimeDropdown } from 'primereact/dropdown';
import { Upload, message, Button as AntButton, Table } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import Loader from 'react-loader-spinner';
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import {
    FacebookShareButton,
    WhatsappShareButton,
    EmailShareButton,
    FacebookIcon,
    WhatsappIcon,
    EmailIcon
} from "react-share";
import $ from 'jquery';

const { Column } = Table;

const renderToolbar = (toolbarSlot) => {
    return (
        <div
            style={{
                alignItems: 'center',
                display: 'flex',
                width: '100%',
            }}
        >
            <div
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexGrow: 1,
                    flexShrink: 1,
                    justifyContent: 'center',
                }}
            >
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.previousPageButton}
                </div>
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
                </div>
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.nextPageButton}
                </div>
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.zoomOutButton}
                </div>
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.zoomPopover}
                </div>
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.zoomInButton}
                </div>
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.downloadButton}
                </div>
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.moreActionsPopover}
                </div>
            </div>
        </div>
    );
};

const layout = (
    isSidebarOpened,
    container,
    main,
    toolbar,
    sidebar,
) => {
    return defaultLayout(
        isSidebarOpened,
        container,
        main,
        toolbar(renderToolbar),
        sidebar,
    );
};

export class Complaints extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isGetting: true,
            customerId: null,
            customerProjectId: null,
            complaintList: [],
            createNewButton: "Create New",
            createNewButtonVariant: "info",
            lastButton: "Send",
            lastButtonVariant: "success",
            hidden: true,
            complaintNo: "",
            titleOfComplaint: "",
            message: "",
            complaintGroup: null,
            complaintGroups: [],
            attachedFile: null,
            complaintPhoto: null,
            complaintId: null,
            pdfFile: "",
            modalPdfViewer: false,
            modalPhotoShow: false,
            imageUrl: "",
        };

        this.onComplaintGroupChange = this.onComplaintGroupChange.bind(this);
    }

    componentDidMount = async () => {

        this.setState({
            isGetting: true
        });

        const responseCustomer = await handleRequest("GET", "/CustomerLog/getAllByCompany");

        let customerId = 0;
        let customerProjectId = 0;

        if (responseCustomer.data.length > 0) {
            this.setState({
                customerList: responseCustomer.data
            });

            let customerDatas = responseCustomer.data;

            for (let index = 0; index < customerDatas.length; index++) {
                if (customerDatas[index].projects !== null)
                    if (customerDatas[index].projects.length > 0) {
                        for (let index1 = 0; index1 < customerDatas[index].projects.length; index1++) {
                            if (customerDatas[index].projects[index1].representatives !== null)
                                if (customerDatas[index].projects[index1].representatives.length > 0) {
                                    for (let index2 = 0; index2 < customerDatas[index].projects[index1].representatives.length; index2++) {
                                        if (this.props.auth.user.email === customerDatas[index].projects[index1].representatives[index2].pRepEmail) {
                                            customerId = customerDatas[index].id;
                                            customerProjectId = customerDatas[index].projects[index1].id;

                                            this.setState({
                                                customerId: customerId,
                                                customerProjectId: customerProjectId
                                            });
                                        }
                                    }
                                }
                        }
                    }
            }
        }

        const response = await handleRequest("GET", "/Complaint/getAllByCompany");

        if (response.data.length === 0) {
            this.setState({
                complaintList: []
            });
        }

        if (response.data.length !== 0) {

            let mainComplaintList = response.data;
            let customerComplaintList = [];

            //sadece yetkili kullanıcı ile alakalı siparişler görüntüleniyor.

            for (let index = 0; index < mainComplaintList.length; index++) {
                if (mainComplaintList[index].customerId === customerId && mainComplaintList[index].customerProjectId === customerProjectId)
                    customerComplaintList.push(mainComplaintList[index]);
            }

            this.setState({
                complaintList: customerComplaintList
            });
        }

        console.log(response.data);

        const responseCode = await handleRequest("GET", "/Code/getAll");

        if (responseCode.data.length !== 0) {

            const columns = responseCode.data;

            var options = [];

            for (let index = 0; index < columns.length; index++) {
                if (columns[index].type === "complaintGroup") {
                    options.push({ label: columns[index].name.toString(), value: columns[index].name.toString() });
                }
            }

            this.setState({
                complaintGroups: options
            });
        }

        this.setState({
            isGetting: false
        });
    }

    createNew = () => {
        if (this.state.createNewButton === "Create New") {
            this.setState({
                hidden: false,
                createNewButton: "Stop Adding New",
                createNewButtonVariant: "danger",
                lastButton: "Send",
                lastButtonVariant: "success"
            });
        } else {
            this.resetInputs();
        }
    }

    resetInputs = () => {
        this.setState({
            hidden: true,
            createNewButton: "Create New",
            createNewButtonVariant: "info",
            lastButton: "Send",
            lastButtonVariant: "success",
            complaintNo: "",
            titleOfComplaint: "",
            message: "",
            complaintGroup: null,
            attachedFile: "",
            complaintPhoto: "",
        });

        setTimeout(() => {
            $(".ant-upload-list").empty();
        }, 200);
    }

    restartTable = async () => {

        this.setState({
            isGetting: true
        });

        const response = await handleRequest("GET", "/Complaint/getAllByCompany");

        if (response.data.length === 0) {
            this.setState({
                complaintList: []
            });
        }

        if (response.data.length !== 0) {

            let mainComplaintList = response.data;
            let customerComplaintList = [];

            for (let index = 0; index < mainComplaintList.length; index++) {
                if (mainComplaintList[index].customerId === this.state.customerId && mainComplaintList[index].customerProjectId === this.state.customerProjectId)
                    customerComplaintList.push(mainComplaintList[index]);
            }

            this.setState({
                complaintList: customerComplaintList
            });
        }

        this.setState({
            isGetting: false
        });
    }

    saveComplaint = async () => {

        var dbComplaintNo;

        if (this.state.lastButton === "Send") {
            if (this.state.complaintList.length > 0)
                dbComplaintNo = Number(this.state.complaintList[this.state.complaintList.length - 1].complaintNo) + 1;
            else
                dbComplaintNo = 1;

            const newComplaint = {
                complaintNo: dbComplaintNo,
                complaintGroup: this.state.complaintGroup,
                titleOfComplaint: this.state.titleOfComplaint,
                message: this.state.message,
                attachedFilePath: this.state.attachedFile,
                complaintPhotoPath: this.state.complaintPhoto,
                status: "Open",
                customerId: this.state.customerId,
                customerProjectId: this.state.customerProjectId
            }

            await handleRequest("POST", "/Complaint/add", newComplaint);

            message.success(`Complaint has been created successfully.`);
            this.restartTable();
            this.resetInputs();
        }
    }

    handleChange = e => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    onComplaintGroupChange(e) {
        this.setState({ complaintGroup: e.value });
    }

    beforeUpload = (file) => {
        const isPDF = file.type === 'application/pdf';

        if (!isPDF) {
            message.error('You can only upload PDF file!');
            console.log("OK1");

            setTimeout(() => {
                $(".ant-upload-list").empty();
            }, 200);
        }
        const isLt4M = file.size / 1024 / 1024 < 4;
        if (!isLt4M) {
            message.error('File must smaller than 4MB!');
            console.log("OK2");

            setTimeout(() => {
                $(".ant-upload-list").empty();
            }, 200);
        }
        return isPDF && isLt4M;
    }

    handleAttachChange = info => {
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);

            console.log(info.file.response[0]);

            if (info.file.response.length > 0) {
                this.setState({
                    attachedFile: info.file.response[0]
                })
            }
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
            setTimeout(() => {
                $(".ant-upload-list").empty();
            }, 200);
        }
    }

    beforeUpload1 = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');

            setTimeout(() => {
                $(".ant-upload-list").empty();
            }, 200);
        }
        const isLt2M = file.size / 1024 / 1024 < 4;
        if (!isLt2M) {
            message.error('Image must smaller than 4MB!');
            setTimeout(() => {
                $(".ant-upload-list").empty();
            }, 200);
        }
        return isJpgOrPng && isLt2M;
    }

    transformFile = (file) => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const canvas = document.createElement('canvas');
                const img = document.createElement('img');
                img.src = reader.result;
                img.onload = () => {
                    let width = img.width
                    let height = img.height
                    canvas.width = width
                    canvas.height = height

                    const ctx = canvas.getContext('2d')
                    ctx.drawImage(img, 0, 0, width, height)

                    canvas.toBlob(resolve, 'image/jpeg', 0.5);
                };
            }
        });
    }

    handlePhotoChange = info => {
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);

            // console.log(info.file);

            console.log(info.file.response[0]);

            if (info.file.response.length > 0) {
                this.setState({
                    complaintPhoto: info.file.response[0]
                })
            }
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
            setTimeout(() => {
                $(".ant-upload-list").empty();
            }, 200);
        }
    }

    deleteModal = (row) => {
        this.setState({
            modalDelete: true,
            complaintId: row.id
        });
    }

    deleteModalOK = async () => {
        if (this.state.complaintId !== null) {
            const deletedItem = {
                id: this.state.complaintId
            }

            await handleRequest("POST", "/Complaint/delete", deletedItem);

            this.setState({
                modalDelete: false,
                complaintId: null
            });

            setTimeout(() => {
                document.body.style.overflow = "auto";
            }, 500);

            this.restartTable();
        }
    }

    edit = (row) => {

        // let selectedRow = null;

        // for (let index = 0; index < this.state.immutableOrderList.length; index++) {
        //     if (row.id === this.state.immutableOrderList[index].id) {
        //         selectedRow = this.state.immutableOrderList[index];
        //     }
        // }

        // if (selectedRow !== null) {

        //     this.setState({
        //         customerId: selectedRow.customerId,
        //         projectId: selectedRow.customerProjectId
        //     });

        //     let orderList = [];
        //     let oldOrderList = [];

        //     for (let index = 0; index < selectedRow.orders.length; index++) {
        //         const newItem = {
        //             key: selectedRow.orders[index].id,
        //             id: selectedRow.orders[index].id,
        //             productId: selectedRow.orders[index].companyService.id,
        //             product: selectedRow.orders[index].companyService.description,
        //             productCode: selectedRow.orders[index].companyService.code,
        //             quantity: selectedRow.orders[index].quantity,
        //             productUnit: selectedRow.orders[index].companyService.unitCode.name
        //         }

        //         const newItem1 = {
        //             key: selectedRow.orders[index].id,
        //             id: selectedRow.orders[index].id,
        //             active: false,
        //             productId: selectedRow.orders[index].companyService.id,
        //             product: selectedRow.orders[index].companyService.description,
        //             productCode: selectedRow.orders[index].companyService.code,
        //             quantity: selectedRow.orders[index].quantity,
        //             productUnit: selectedRow.orders[index].companyService.unitCode.name
        //         }

        //         orderList.push(newItem);
        //         oldOrderList.push(newItem1);
        //     }

        //     this.setState({
        //         hidden: false,
        //         orderId: selectedRow.id,
        //         createNewButton: "Stop Updating Order",
        //         createNewButtonVariant: "warning",
        //         lastButton: "Update",
        //         lastButtonVariant: "warning",
        //         complaintNo: selectedRow.complaintNo,
        //     });

        //     document.getElementById('kt_scrolltop').click();
        // }
    }

    showAttachedFile = async (rowData) => {
        try {
            const fileRealUrl = `${API_BASE}/Complaint/download1/` + rowData.id;

            console.log("fileUrl: " + fileRealUrl);

            this.setState({
                pdfFile: fileRealUrl,
                modalPdfViewer: true,
            });

        } catch (error) {
            console.log(error);
            message.error(`File can't found.`);
        }
    }

    showOrHideModalPdfViewer = () => {
        this.setState({
            modalPdfViewer: !this.state.modalPdfViewer,
        });
    }

    printPdf = () => {
        window.open(this.state.pdfFile);
    }

    showOrHidePhotoModal = () => {
        this.setState({
            modalPhotoShow: !this.state.modalPhotoShow,
        });
    }

    render() {
        return (
            <div>

                <Modal
                    size="xl"
                    show={this.state.modalPdfViewer}
                    onHide={this.showOrHideModalPdfViewer}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            PDF Viewer
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ textAlign: "right", marginBottom: "1rem" }}>
                            <Button style={{ marginLeft: "1rem" }}
                                onClick={this.printPdf}
                            >
                                Print
                            </Button>
                            <FacebookShareButton style={{ marginLeft: "1rem" }} url={this.state.pdfFile} quote="Complaint PDF File Link">
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <EmailShareButton style={{ marginLeft: "1rem" }} url={this.state.pdfFile} body="Complaint PDF File Link is here: " subject="Complaint PDF File Link">
                                <EmailIcon size={32} round />
                            </EmailShareButton>
                            <WhatsappShareButton
                                style={{ marginLeft: "1rem" }}
                                url={this.state.pdfFile}
                                title="Complaint PDF File Link"
                                separator=": "
                            >
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                        </div>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
                            <div style={{ height: "900px" }}>
                                <Viewer fileUrl={this.state.pdfFile} layout={layout} />
                            </div>
                        </Worker>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.showOrHideModalPdfViewer}
                        >
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalPhotoShow}
                    onHide={this.showOrHidePhotoModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Photo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.imageUrl !== "" ? (
                            <img
                                src={this.state.imageUrl}
                                alt="avatar"
                                style={{ width: "100%" }}
                            />
                        ) : (
                                <p>Photo has not been uploaded.</p>
                            )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="info" onClick={this.showOrHidePhotoModal}>
                            Close
            </Button>
                    </Modal.Footer>
                </Modal>

                {this.state.isGetting ? <div
                    style={{
                        width: "100%",
                        height: "100",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <Loader
                        type="Oval"
                        color="#00BFFF"
                        height={100}
                        width={100}
                    />
                </div> :
                    <div>
                        <div style={{ padding: "4px 16px 4px 16px" }}>
                            <Row>
                                <Col xs={3}></Col>
                                <Col xs={3}></Col>
                                <Col xs={3}></Col>
                                <Col xs={3}><Button onClick={this.createNew} style={{ width: '100%' }} variant={this.state.createNewButtonVariant} >{this.state.createNewButton}</Button></Col>
                            </Row>
                        </div>

                        <div hidden={this.state.hidden} style={{ marginTop: '1rem' }}>

                            <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                                <Col sm="3">
                                </Col>
                                <Form.Label style={{ color: 'black', fontWeight: 'bold' }} column sm="3">
                                    Complaint Group:
                                </Form.Label>
                                <Col sm="3">
                                    <PrimeDropdown style={{ width: '100%' }} value={this.state.complaintGroup} options={this.state.complaintGroups} onChange={this.onComplaintGroupChange}
                                        editable={true} placeholder="Select Complaint Group" />
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                                <Col sm="3">
                                </Col>
                                <Form.Label style={{ color: 'black', fontWeight: 'bold' }} column sm="3">
                                    Title of Complaint:
                                </Form.Label>
                                <Col sm="3">
                                    <Form.Control name="titleOfComplaint" value={this.state.titleOfComplaint}
                                        onChange={this.handleChange} />
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                                <Col sm="3">
                                </Col>
                                <Form.Label style={{ color: 'black', fontWeight: 'bold' }} column sm="3">
                                    Message:
                                </Form.Label>
                                <Col sm="3">
                                    <Form.Control name="message" value={this.state.message}
                                        onChange={this.handleChange} />
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                                <Col sm="3">
                                </Col>
                                <Form.Label style={{ color: 'black', fontWeight: 'bold' }} column sm="3">
                                    Attach:
                            </Form.Label>
                                <Col sm="3">
                                    <Upload
                                        name="avatar"
                                        className="avatar-uploader"
                                        action={`${API_BASE}/Complaint/upload`}
                                        beforeUpload={this.beforeUpload}
                                        onChange={this.handleAttachChange}
                                        style={{ width: '100%' }}
                                    >
                                        <AntButton>
                                            <UploadOutlined /> Upload File
                                    </AntButton>
                                    </Upload>
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                                <Col sm="3">
                                </Col>
                                <Form.Label style={{ color: 'black', fontWeight: 'bold' }} column sm="3">
                                    Photo:
                            </Form.Label>
                                <Col sm="3">
                                    <Upload
                                        name="avatar"
                                        className="avatar-uploader"
                                        action={`${API_BASE}/Complaint/upload1`}
                                        beforeUpload={this.beforeUpload1}
                                        transformFile={this.transformFile}
                                        onChange={this.handlePhotoChange}
                                        style={{ width: '100%' }}
                                    >
                                        <AntButton>
                                            <UploadOutlined /> Upload Photo
                                    </AntButton>
                                    </Upload>
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>


                            <Form.Group style={{ marginBottom: '1rem', marginTop: '2rem' }} as={Row} >
                                <Col sm="3">
                                </Col>
                                <Col sm="6">
                                    <Button type="button" style={{ width: '100%' }} onClick={this.saveComplaint} variant={this.state.lastButtonVariant}>{this.state.lastButton}</Button>
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>
                        </div>

                        <Table
                            dataSource={this.state.complaintList}
                            style={{ marginTop: "2rem" }}
                            scroll={{ x: 1300 }}
                            bordered
                        >
                            {/*<Column
                                width='50px'
                                title="Action"
                                key="action"
                                render={(text, record) => (
                                    <div style={{ textAlign: "center" }}>
                                        <React.Fragment key={record.id} > <CustomMaterialMenu row={record}
                                            onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
                                        </React.Fragment>
                                    </div>
                                )}
                            />*/}
                            <Column title="S/N" key="sn" dataIndex="index" />
                            <Column title="Date" key="date" render={(text, record) => {
                                var myDate = new Date(record.time);
                                return myDate.getDate() + '/' + (myDate.getMonth() + 1) + '/' + myDate.getFullYear();

                            }} />
                            <Column title="Project" key="project" render={(text, record) => {
                                return record.customerProject.projectName;
                            }} />
                            <Column title="Complaint No" key="complaintNo" dataIndex="complaintNo" />
                            <Column title="Complaint By" key="complaintBy" render={(text, record) => {
                                return record.user.username;
                            }} />
                            <Column title="Group" key="group" render={(text, record) => {
                                return record.complaintGroupTypeCode.name;
                            }} />
                            <Column title="Complaint Title" key="complaintTitle" dataIndex="titleOfComplaint" />
                            <Column title="Message" key="message" dataIndex="message" />
                            <Column title="Attachment" key="attachment" width='140px' render={(text, record) => {
                                if (record.attachedFilePath !== null && record.attachedFilePath !== ""
                                    && record.complaintPhotoPath !== null && record.complaintPhotoPath !== "") {
                                    return (
                                        <React.Fragment>
                                            <Form.Group style={{ marginTop: '0rem', marginBottom: '0rem' }} as={Row}>
                                                <Form.Label style={{
                                                    cursor: "pointer",
                                                    textDecoration: "underline",
                                                    fontWeight: "bold",
                                                }}
                                                    onClick={(e) => this.showAttachedFile(record)} column sm="6">
                                                    File
                                                </Form.Label>
                                                <Form.Label style={{
                                                    cursor: "pointer",
                                                    textDecoration: "underline",
                                                    fontWeight: "bold"
                                                }}
                                                    onClick={() =>
                                                        this.setState({
                                                            imageUrl: `${API_BASE}/Complaint/download/` + record.id,
                                                            modalPhotoShow: true,
                                                        })
                                                    } column sm="6">
                                                    Photo
                                                </Form.Label>
                                            </Form.Group>
                                        </React.Fragment>
                                    );
                                } else if (record.attachedFilePath !== null && record.attachedFilePath !== "") {
                                    return (
                                        <React.Fragment>
                                            <Form.Group style={{ marginTop: '0rem', marginBottom: '0rem' }} as={Row}>
                                                <Form.Label style={{
                                                    cursor: "pointer",
                                                    textDecoration: "underline",
                                                    fontWeight: "bold",
                                                }}
                                                    onClick={(e) => this.showAttachedFile(record)} column sm="12">
                                                    File
                                                </Form.Label>
                                            </Form.Group>
                                        </React.Fragment>
                                    );
                                } else if (record.complaintPhotoPath !== null && record.complaintPhotoPath !== "") {
                                    return (
                                        <React.Fragment>
                                            <Form.Group style={{ marginTop: '0rem', marginBottom: '0rem' }} as={Row}>
                                                <Form.Label style={{
                                                    cursor: "pointer",
                                                    textDecoration: "underline",
                                                    fontWeight: "bold"
                                                }}
                                                    onClick={() =>
                                                        this.setState({
                                                            imageUrl: `${API_BASE}/Complaint/download/` + record.id,
                                                            modalPhotoShow: true,
                                                        })
                                                    } column sm="12">
                                                    Photo
                                            </Form.Label>
                                            </Form.Group>
                                        </React.Fragment>
                                    );
                                }
                                else
                                    return "";
                            }} />
                            <Column title="To" key="to" />
                            <Column title="Respond Date" key="respondTime" render={(text, record) => {
                                if (record.respondTime !== null) {
                                    var myDate = new Date(record.respondTime);
                                    return myDate.getDate() + '/' + (myDate.getMonth() + 1) + '/' + myDate.getFullYear() + ' ' + String(myDate.getHours()).padStart(2, "0") + ':' + String(myDate.getMinutes()).padStart(2, "0");
                                } else return "";
                            }} />
                            <Column title="Respond" key="respond" dataIndex="respond" />
                            <Column title="Status" key="status" dataIndex="status" />
                        </Table>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Complaints)