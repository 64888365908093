/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button as ReactButton, Card, Button, Container, Col, Row } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { OrganizationChart } from 'primereact/organizationchart';
import { Link } from 'react-router-dom';
import { Checkbox, Avatar, Radio } from 'antd';
import { API_BASE } from '../../redux/config/env';

class OrganizationalChart extends Component {

    constructor(props) {
        super(props)
        this.state = {
            orgChartNodes: [{
                label: '',
                className: "",
                expanded: true,
                children: []
            }],
            photoLocation: "normal",
            photoShow: false,
            telShow: false,
            designation: false,
            name: false,
            showOptions: false,
            modalMessage: false,
            modalEdit: false,
            selectedNode2: null
        }

        this.nodeTemplate = this.nodeTemplate.bind(this);
    }

    componentDidMount = async () => {
        const responseTree = await handleRequest("GET", "/Department/getTreeModel");

        console.log(responseTree);
        if (responseTree.data !== null && responseTree.data !== undefined && responseTree.data.cascader != null && responseTree.data.cascader !== undefined && responseTree.data.cascader.length > 0) {
            this.setState({
                orgChartNodes: responseTree.data.cascader
            });
        }
    }

    nodeTemplate(node) {
        if (this.state.photoLocation === "normal")
            return (
                <div>
                    <div className="node-header">{node.label}</div>
                    <div className="node-content">
                        {/*{(this.state.photoShow) && <img alt="test"
                src={Boolean(node.staffId) ? `${API_BASE}/Staff/download/photo/` + node.staffId : `https://vignette.wikia.nocookie.net/trbreakingbad/images/d/d9/Walterwhite.JPG/revision/latest/top-crop/width/360/height/450?cb=20160924184343`}
                style={{ width: '32px' }} />
            }*/}
                        {(this.state.photoShow) &&
                            <Avatar size={64} src={Boolean(node.staffId) ? `${API_BASE}/Staff/download/photo/` + node.staffId : `https://i.ibb.co/sFsqBht/person1.png`} />
                        }
                        {(this.state.name) && <div>{Boolean(node.staffId) ? node.staffName : "N/A"}</div>}
                        {(this.state.telShow) && <div>{Boolean(node.staffId) ? node.staffPhoneNo : "N/A"}</div>}
                        {(this.state.designation) && <div>{node.designation}</div>}
                        {/*<div style={{ color: 'green', cursor: 'pointer' }} onClick={this.showOK}>Edit</div>*/}
                    </div>
                </div>
            );
        else if (this.state.photoLocation === "left") {
            return (
                <div>
                    <div className="node-header">{node.label}</div>
                    <div className="node-content" style={{ width: "200px" }}>
                        <Row>
                            <Col sm={6}>
                                {(this.state.photoShow) &&
                                    <Avatar size={64} src={Boolean(node.staffId) ? `${API_BASE}/Staff/download/photo/` + node.staffId : `https://i.ibb.co/sFsqBht/person1.png`} />
                                }
                            </Col>
                            <Col sm={6}>
                                {(this.state.name) && <div>{Boolean(node.staffId) ? node.staffName : "N/A"}</div>}
                                {(this.state.telShow) && <div>{Boolean(node.staffId) ? node.staffPhoneNo : "N/A"}</div>}
                                {(this.state.designation) && <div>{node.designation}</div>}
                            </Col>
                        </Row>
                    </div>
                </div>
            );
        } else if (this.state.photoLocation === "right") {
            return (
                <div>
                    <div className="node-header">{node.label}</div>
                    <div className="node-content" style={{ width: "200px" }}>
                        <Row>
                            <Col sm={6}>
                                {(this.state.name) && <div>{Boolean(node.staffId) ? node.staffName : "N/A"}</div>}
                                {(this.state.telShow) && <div>{Boolean(node.staffId) ? node.staffPhoneNo : "N/A"}</div>}
                                {(this.state.designation) && <div>{node.designation}</div>}
                            </Col>
                            <Col sm={6}>
                                {(this.state.photoShow) &&
                                    <Avatar size={64} src={Boolean(node.staffId) ? `${API_BASE}/Staff/download/photo/` + node.staffId : `https://i.ibb.co/sFsqBht/person1.png`} />
                                }
                            </Col>
                        </Row>
                    </div>
                </div>
            );
        }
    }

    onChangePhotoShow = e => {
        this.setState({
            photoShow: e.target.checked
        });
    }

    onChangeTelShow = e => {
        this.setState({
            telShow: e.target.checked
        });
    }

    onChangeDesignationShow = e => {
        this.setState({
            designation: e.target.checked
        });
    }

    onChangeNameShow = e => {
        this.setState({
            name: e.target.checked
        });
    }

    showOrHideModalEdit = () => {
        this.setState({
            modalEdit: !this.state.modalEdit,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOptions = () => {
        this.setState({
            showOptions: !this.state.showOptions,
        });
    }

    onChangePhotoLocation = e => {
        this.setState({
            photoLocation: e.target.value,
        });
    }

    render() {
        return (
            <div>

                <Modal
                    show={this.state.modalEdit}
                    onHide={this.showOrHideModalEdit}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Edit Selected Department</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {(this.state.selectedNode2 !== undefined) && (this.state.selectedNode2 !== null) && <p> You can edit this record for <b>{this.state.selectedNode2.label}</b>... </p>}
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="info" onClick={this.showOrHideModalEdit}>OK</ReactButton>
                    </Modal.Footer>
                </Modal>

                <div>
                    {/*<Row>
                        <Col xs={12}>
                            <OrganizationChart value={this.state.orgChartNodes} selectionMode="single" selection={this.state.selectedNode} onSelectionChange={event => this.setState({ selectedNode: event.data })}></OrganizationChart>
                        </Col>
                    </Row>*/}

                    <Row style={{ marginBottom: '1rem' }}>
                        <Col sm={1}>
                        </Col>
                        <Col sm={4}>
                            <Button style={{ backgroundColor: "#138496", borderColor: "#138496"}} onClick={this.showOptions}>{Boolean(this.state.showOptions) ? "Hide Options" : "Show Options"}</Button>
                        </Col>
                        <Col sm={7}>
                        </Col>
                    </Row>

                    {(this.state.showOptions) && <div>
                        <Row>
                            <Col sm={1}>
                            </Col>
                            <Col sm={11}>
                                <Checkbox checked={this.state.photoShow} onChange={this.onChangePhotoShow}>Photo</Checkbox>
                                {(this.state.photoShow) && <Radio.Group onChange={this.onChangePhotoLocation} value={this.state.photoLocation}>
                                    <Radio value="normal">Top</Radio>
                                    <Radio value="left">Left</Radio>
                                    <Radio value="right">Right</Radio>
                                </Radio.Group>}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={1}>
                            </Col>
                            <Col sm={11}>
                                <Checkbox checked={this.state.telShow} onChange={this.onChangeTelShow}>GSM</Checkbox>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={1}>
                            </Col>
                            <Col sm={11}>
                                <Checkbox checked={this.state.designation} onChange={this.onChangeDesignationShow}>Designation</Checkbox>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: '1rem' }}>
                            <Col sm={1}>
                            </Col>
                            <Col sm={11}>
                                <Checkbox checked={this.state.name} onChange={this.onChangeNameShow}>Name</Checkbox>
                            </Col>
                        </Row>
                    </div>}

                    <Row>
                        <Col sm={12}>
                            <OrganizationChart value={this.state.orgChartNodes} nodeTemplate={this.nodeTemplate}
                                selectionMode="single" selection={this.state.selectedNode2}
                                onNodeSelect={event => this.setState({ selectedNode2: event.node, modalEdit: true })}></OrganizationChart>
                        </Col>
                    </Row>

                    {/*<Row>
                        <Col xs={3}></Col>
                        <Col xs={6}>
                            <Link
                                to={{
                                    pathname: "/Department"
                                }}
                            >     <Button style={{ width: '100%' }} variant="info"> Create Organization Chart</Button>
                            </Link>
                        </Col>
                        <Col xs={3}></Col>
                    </Row>*/}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationalChart);


