/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Modal, Form, Col, Row, Button as ReactButton } from 'react-bootstrap';
import { handleRequest } from '../../redux/config/env';
import { Form as FormAnt } from 'antd';
import { FormattedMessage } from "react-intl";
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Table, Input, Select, InputNumber, Checkbox, Radio, Divider, DatePicker, Cascader, TimePicker, Switch, Button as AntButton, Col as ColAnt, Row as RowAnt } from 'antd';
import { Link } from 'react-router-dom';
import { NATIONALITIES, COUNTRIES } from '../settings/JsonDatas/Datas';
import moment from 'moment'
import { PlusOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { setStandardization, getDynamicMenu, getUserSettings } from '../../redux/config/imu.js';
import domtoimage from 'dom-to-image';
import classes from './quality.css';
import classNames from 'classnames';
import { jsx, css } from '@emotion/react'
import styles from './component.module.less'

const { RangePicker } = DatePicker;
const { Column } = Table;
const { Option } = Select;
const pageName = "pqi";


const tableCSS = css({
    backgroundColor: 'green',
    '.ant-table-small  .ant-table-thead > tr > th': {
        backgroundColor: 'blue'
    }
});




export class Pqi extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            id: null,
            tableList: [],
            filePath: '',
            fileList: [],

            month: null,
            columns: [],
            monthDate: null,
            procurement: null,
            construction: null,
            overallOfMonth: null,
            cumulative: null,


            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: 0
        };
        // this.actionTemplate = this.actionTemplate.bind(this);
        // this.showSuccess = this.showSuccess.bind(this);
        // this.showError = this.showError.bind(this);
        // this.showWarning = this.showWarning.bind(this);

    }

    formRef = React.createRef();


    componentDidMount = async () => {

        var isAdmin = false;
        let dynamicMenu = await getDynamicMenu(pageName);
        this.getUserSettings(dynamicMenu.formId);
        if (Boolean(this.props.auth) && Boolean(this.props.auth.user) && this.props.auth.user.userType === 2) {
            isAdmin = true;
        }

        let standardization = await setStandardization(pageName);
        this.setState({
            dynamicAttributes: standardization.dynamicAttributes,
            dynamicInputs: standardization.dynamicInputs,
            isAdmin: isAdmin,
            formId: dynamicMenu.formId,
            authorizations: dynamicMenu.authorizations
        })

        this.restartTable();
    }

    getUserSettings = async (formId) => {

        let userSettings = await getUserSettings(formId);
        this.setState(
            {
                howToUseSetting: userSettings.howToUseSetting,
                whatIsSetting: userSettings.whatIsSetting
            }
        )
    }

    save = async () => {


        let obj = {
            id: this.state.id,
            month: this.state.month,
            montDate: this.state.monthDate,
            year: this.state.monthDate.year(),
            monthNumber: this.state.monthDate.month(),
            procurement: this.state.procurement,
            construction: this.state.construction,
            overallOfMonth: this.state.overallOfMonth,
            cumulative: this.state.cumulative,

        };
        console.log(obj);
        if (obj.id === null)
            await handleRequest("POST", "/Pqi/add", obj);
        else
            await handleRequest("POST", "/Pqi/update", obj);

        this.showSuccess();
        this.resetInputs();
        this.restartTable();

    }

    edit = (row) => {

        this.formRef.current.setFieldsValue({
            month: row.month,
            monthDate: moment(row.month),
            procurement: row.procurement,
            construction: row.construction,
            overallOfMonth: row.overallOfMonth,
            cumulative: row.cumulative,

        });

        this.setState({

            hideSave: true,
            hideUpdate: false,
            hideInputs: false,
            id: row.id,
            month: row.month,
            monthDate: moment(row.month),
            procurement: row.procurement,
            construction: row.construction,
            overallOfMonth: row.overallOfMonth,
            cumulative: row.cumulative,


        });

    }


    delete = async () => {

        const obj = {
            id: this.state.id
        }

        await handleRequest("POST", "/Pqi/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            id: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;


    }



    restartTable = async () => {
        const response = await handleRequest("GET", "/Pqi/getAllByCompany");
        let columns = [
            {
                title: "", key: 'title', align: "center", dataIndex: "title", render: (value, row, index) => {
                    return <div style={{ fontWeight: 800, fontFamily: "bold" }}>{value}</div>;
                }
            }
        ];

        let data = [
            {
                title: "Procurement"
            },
            {
                title: "Construction"
            },
            {
                title: "Overall"
            },
            {
                title: "Cumulative"
            }
        ];

        if (response.data.length !== 0) {
            const list = response.data;
            list.forEach(e => {
                e.key = e.id;
                let attribute = e.month;
                data[0][attribute] = e.procurement;
                data[1][attribute] = e.construction;
                data[2][attribute] = e.overallOfMonth;
                data[3][attribute] = e.cumulative;
                let col = {
                    title: e.month, key: e.id, align: "center", dataIndex: attribute, render: (value, row, index) => {
                        return value;
                    }
                }

                columns.push(col);

            });
            this.setState({
                tableList: data,
                columns: columns
            });
        }

        if (response.data.length === 0) {
            this.setState({
                tableList: [],
                columns: columns

            });
        }
    }

    fillInputs = async () => {

        const { monthDate } = this.state;
        if (Boolean(monthDate)) {
            let obj = {
                year: monthDate.year(),
                monthNumber: monthDate.month()
            }
            const response = await handleRequest("POST", "/Pqi/getByMonth", obj);

            if (Boolean(response.data)) {
                const row = response.data;

                this.setState({
                    id: row.id,
                    //month: row.month,
                    //monthDate : moment(row.month),
                    procurement: row.procurement,
                    construction: row.construction,
                    overallOfMonth: row.overallOfMonth,
                    cumulative: row.cumulative,
                });
            }
            else
                this.setState({
                    id: null,
                    //monthDate : moment(row.month),
                    procurement: null,
                    construction: null,
                    overallOfMonth: null,
                    cumulative: null,
                });
        }


    }

    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }


    stop = () => {

        this.setState({

            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });
        this.resetInputs();

    }



    resetInputs = () => {
        this.formRef.current.setFieldsValue({
            month: null,
            procurement: null,
            construction: null,
            overallOfMonth: null,
            cumulative: null,


        });

        this.setState({
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
            id: null,
            month: null,
            procurement: null,
            construction: null,
            overallOfMonth: null,
            cumulative: null,



        });
    }


    cancel = () => {

        this.resetInputs();
    }


    convertToCanvas = () => {

        var node = document.getElementById('pqiTable');

        domtoimage.toPng(node)
            .then(function (dataUrl) {
                var img = new Image();
                img.src = dataUrl;

                document.getElementById("canvasTable").appendChild(img);
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });


    }





    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }


    render() {

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 }
        };

        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };


        return (this.state.formId > 0) ? (
            (this.state.authorizations.length > 0 && this.state.authorizations.findIndex(p => p.action === "view") >= 0)
                ? (
                    <div>
                        <MenuProvider id="menu_id">
                            <Card>
                                <div>
                                    <Row>
                                        <Col sm="1">
                                            <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                        </Col>
                                        <Col sm="10">
                                        </Col>
                                        <Col sm="1" style={{ textAlign: "right" }}>
                                            {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>}
                                        </Col>
                                    </Row>
                                </div>

                                <div>
                                    <Row>
                                        <Col sm="10">

                                        </Col>
                                        <Col sm="2">
                                            {/*<Button style={{ width: '100%' }} id='create-new' icon="pi pi-plus" onClick={this.createNew} label="Create New" />*/}
                                            {this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                            }

                                            {!this.state.hideInputs &&
                                                <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={this.stop}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                            }

                                        </Col>
                                    </Row>
                                </div>

                                <div hidden={this.state.hideInputs} style={{ marginBottom: '2rem' }}>

                                    <FormAnt
                                        initialValues={{ remember: false }}
                                        onFinish={this.save}
                                        onFinishFailed={onFinishFailed}
                                        ref={this.formRef}
                                    >

                                        {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "pqi.month").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="pqi.month" defaultMessage="Month" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="month"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "pqi.month")) ? this.state.dynamicInputs.find(p => p.inputKey === "pqi.month").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "pqi.month")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "pqi.month").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <DatePicker id="pqi.month" picker="month" format={"MMM-YY"} value={this.state.monthDate} onChange={(date, dateString) => {
                                                            this.formRef.current.setFieldsValue({
                                                                month: dateString

                                                            });
                                                            this.setState({
                                                                month: dateString,
                                                                monthDate: date
                                                            }, this.fillInputs)
                                                        }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "pqi.procurement").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="pqi.procurement" defaultMessage="Procurement" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="procurement"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "pqi.procurement")) ? this.state.dynamicInputs.find(p => p.inputKey === "pqi.procurement").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "pqi.procurement")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "pqi.procurement").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <InputNumber style={{ width: "100%" }} id="pqi.procurement" placeholder="Procurement"
                                                            min={0} value={this.state.procurement} onChange={(value) => {
                                                                this.formRef.current.setFieldsValue({
                                                                    procurement: value
                                                                });
                                                                this.setState({ procurement: value })
                                                            }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "pqi.Construction").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="pqi.Construction" defaultMessage="Construction" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="construction"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "pqi.Construction")) ? this.state.dynamicInputs.find(p => p.inputKey === "pqi.Construction").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "pqi.Construction")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "pqi.Construction").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <InputNumber style={{ width: "100%" }} id="pqi.Construction" placeholder="Construction"
                                                            min={0} value={this.state.construction} onChange={(value) => {
                                                                this.formRef.current.setFieldsValue({
                                                                    construction: value
                                                                });
                                                                this.setState({ construction: value })
                                                            }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "pqi.OverallOfMonth").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="pqi.OverallOfMonth" defaultMessage="Overall of Month" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="overallOfMonth"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "pqi.OverallOfMonth")) ? this.state.dynamicInputs.find(p => p.inputKey === "pqi.OverallOfMonth").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "pqi.OverallOfMonth")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "pqi.OverallOfMonth").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <InputNumber style={{ width: "100%" }} id="pqi.OverallOfMonth" placeholder="Overall of Month"
                                                            min={0} value={this.state.overallOfMonth} onChange={(value) => {
                                                                this.formRef.current.setFieldsValue({
                                                                    overallOfMonth: value
                                                                });
                                                                this.setState({ overallOfMonth: value })
                                                            }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }  {((this.state.dynamicAttributes.length > 0 && this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "pqi.Cumulative").visible) || this.state.dynamicAttributes.length === 0) &&
                                            <FormAnt.Item
                                                {...layout}

                                                label={<FormattedMessage id="pqi.Cumulative" defaultMessage="Cumulative" />}

                                                style={{
                                                    marginBottom: 0,
                                                }}
                                                name="cumulative"
                                                rules={[{ required: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "pqi.Cumulative")) ? this.state.dynamicInputs.find(p => p.inputKey === "pqi.Cumulative").canBeRequired : false, message: Boolean(this.state.dynamicInputs.find(p => p.inputKey === "pqi.Cumulative")) ? <FormattedMessage id={this.state.dynamicInputs.find(p => p.inputKey === "pqi.Cumulative").translateRequired} defaultMessage="This field is required" /> : '' }]} >
                                                <RowAnt gutter={[8, 8]}>
                                                    <ColAnt span={12}>
                                                        <InputNumber style={{ width: "100%" }} id="pqi.Cumulative" placeholder="Cumulative"
                                                            min={0} value={this.state.cumulative} onChange={(value) => {
                                                                this.formRef.current.setFieldsValue({
                                                                    cumulative: value
                                                                });
                                                                this.setState({ cumulative: value })
                                                            }} />
                                                    </ColAnt>
                                                </RowAnt>

                                            </FormAnt.Item>
                                        }



                                        <div hidden={this.state.hideSave}>
                                            {
                                                <RowAnt gutter={8}>
                                                    <ColAnt span={8}></ColAnt>

                                                    <ColAnt span={8}>
                                                        {
                                                            <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%' }}
                                                                variant="success" ><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></ReactButton>
                                                        }
                                                    </ColAnt>
                                                </RowAnt>

                                            }
                                        </div>



                                        <div hidden={this.state.hideUpdate}>
                                            {
                                                <Row>
                                                    <Col sm="3">

                                                    </Col>
                                                    <Col sm="2">
                                                        {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                        <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                                            <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                        {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                        <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                                            <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                                    </Col>
                                                    <Col sm="3">
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                    </FormAnt>
                                </div>
                                <Row>
                                  
                                    <Col sm="12">
                                        {this.state.tableList.length > 0 && <Table id={"Table" + this.props.id}  className={styles.booking_information_table} style={{ marginTop: '3rem',fontSize:50 }} columns={this.state.columns} dataSource={this.state.tableList} bordered >

                                            
                                        </Table>}
                                    </Col>
                                   
                                </Row>




                            </Card>

                       </MenuProvider>
                        {!this.state.whatIsSetting && <Whatis  ></Whatis>

                        }

                        <Modal
                            show={this.state.modalDeleteShow}
                            onHide={this.showOrHideDeleteModal}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Chosen record will be deleted !</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Are you sure?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                                <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                            </Modal.Footer>
                        </Modal>

                        <Modal
                            show={this.state.modalMessage}
                            onHide={this.showOrHideModalMessage}
                            backdrop="static"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered>

                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    <Row >
                                        <Col sm="12">
                                            {this.state.messageTip == "Success" &&
                                                <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                            {this.state.messageTip == "Warning" &&
                                                <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                            {this.state.messageTip == "Error" &&
                                                <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                        </Col>
                                    </Row>
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <Row >
                                    <Col sm="12">
                                        {this.state.message}
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                            </Modal.Footer>
                        </Modal>

                    </div>
                ) : (
                    <div>
                        <Redirect to="/error/error-v6" />
                    </div>
                )
        ) : (
            <div></div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Pqi)
