/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'antd/dist/antd.css';
import 'antd/es/cascader/style/index.css';
import { handleRequest } from '../../redux/config/env';
import { Form, Col, Row } from 'react-bootstrap';
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from "primereact/dialog";
import { Steps } from 'antd';
import { Progress } from 'antd';
import { RightCircleOutlined, HistoryOutlined, InfoOutlined, ReadOutlined, CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import { Toast } from 'primereact/toast';
import { Card } from 'react-bootstrap';
import { red } from '@material-ui/core/colors';

const { Step } = Steps;

function onChange(value) {
    console.log(value);
}

function setDateFormat(d) { //01/02/2011 ->2011-02-01
    if (d != null) {
        var dt = new Date(d);
        return ('' + dt.getDate().toString().padStart(2, '0') + '/' + (dt.getMonth() + 1).toString().padStart(2, '0') + '/' + dt.getFullYear().toString().padStart(4, '0'));
    } else return '';
}

export class WFInformative extends Component {

    constructor() {
        super();
        this.state = {
            startModalHidden: false,
            preparationModalHidden: false,
            informativeModalHidden: false,
            reviewModalHidden: false,
            confirmativeModalHidden: false,

            current: 2,
            action: "",
            deadline: "",
            remindme: false,
            reminderDay: "",
            period: "",

            chkCommentPreparation: false,
            commentPreparation: "",

            chkCommentInformative: false,
            commentInformative: "",

            chkJustificationReview: false,
            justificationReview: "",

            chkJustificationConfirmation: false,
            justificationConfirmation: "",

            hiddenRemindme: true,
            hiddenCommentPreparation: true,
            hiddenCommentInformative: true,
            hiddenJustificationReview: true,
            hiddenJustificationConfirmation: true,

            WorkFlowStepAll: [],

        };
    }

    componentDidMount = () => {
        this.getWorkFlowStep();
        console.log(this.state.WorkFlowStepAll);

    };

    getWorkFlowStep = async () => {
        const response = await handleRequest("GET", "/WorkFlowStep/getAll");
        if (response.data.length !== 0) {
            // console.log(response.data);
            // this.setState({ WorkFlowStepAll: response.data });
            this.setState({ WorkFlowStepAll: response.data.filter(p => p.workFlowId === 1) });
        } else {
            this.setState({ WorkFlowStepAll: [] });
        }
    }

    handleChange = e => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        });
        console.log(e.target.value);
    };

    handleModalHide = async () => {
        this.setState({
            startModalHidden: true,
            preparationModalHidden: true,
            informativeModalHidden: true,
            reviewModalHidden: true,
            confirmativeModalHidden: true,
        });
    }

    onStepChange(value) {
        this.setState({ current: value });
        if (value === 0) {
            this.props.history.push('/WFStart');;
        }
        if (value === 1) {
            this.props.history.push('/WFPreparation');;
        }
        if (value === 2) {
            //this.props.history.push('/WFInformative');;
        }
        if (value === 3) {
            this.props.history.push('/WFReview');;
        }
        if (value === 4) {
            this.props.history.push('/WFConfirmation');;
        }

        console.log(value);
    }


    render() {
        const { current } = this.state;
        let cardHeader = <div style={{ color: "red", fontWeight: "bold" }}>
            Step 1: {this.state.WorkFlowStepAll.actiontype}
        </div>;
        let dialogModalFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button id='cancel-start' className="p-button-danger" label="Close" onClick={this.handleModalHide} />
        </div>;
        return (
            <div style={{ backgroundColor: "white", padding: "10px" }}>
                <Steps current={current} size="small" onChange={e => this.onStepChange(e)} >
                    {
                        this.state.WorkFlowStepAll.map(function (row) {
                            const sDate = setDateFormat(row.startdate);
                            const eDate = setDateFormat(row.duedate);
                            return <Step title={row.actiontype} subTitle={"Start Date: " + sDate} description={"End Date: " + eDate} />
                        })
                    }
                    {/* <Step title="Start" subTitle="Start Date: " description="Start Step Of >Workflow" />
                    <Step title="Preparation" description="Preparation Step Of Workflow" />
                    <Step title="Informative" description="Informative Step Of Workflow" />
                    <Step title="Review" description="Review Step Of Workflow" />
                    <Step title="Confirmation" description="Confirmation Step Of Workflow" /> */}

                </Steps>
                <Progress percent={100 / this.state.WorkFlowStepAll.length * (current + 1)} successPercent={100 / this.state.WorkFlowStepAll.length * (current + 1)} />

                <br />
                <Card hidden={this.state.informativeModalHidden} style={{ width: "100%" }}>
                    <Toast ref={(el) => this.toast = el} />
                    <Card.Header>
                        {cardHeader}
                    </Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Row>
                                <Form.Group as={Col} sm="12" md="12" lg="12">
                                    <Form.Label>
                                        There is comment of xxxx
                                </Form.Label>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} sm="4" md="3" lg="2">
                                    <Checkbox onChange={(e) => this.setState({ chkCommentInformative: e.checked, hiddenCommentInformative: e.checked ? false : true })} checked={this.state.chkCommentInformative}></Checkbox>
                                    <label htmlFor="cb2" className="p-checkbox-label">Comments</label>
                                </Form.Group>
                                <Form.Group as={Col} sm="8" md="9" lg="10">
                                    <InputText hidden={this.state.hiddenCommentInformative} onChange={(e) => this.setState({ commentInformative: e.target.value })} value={this.state.commentInformative} style={{ width: "100%" }} placeholder="" />
                                </Form.Group>
                            </Form.Row>
                        </Form>
                        <Row>
                            <Col sm="12">
                                <Button id='save-start' style={{ width: "100%" }} className="p-button-success" label="Post" />
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Footer>
                        {dialogModalFooter}
                    </Card.Footer>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(WFInformative)