import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Accordion, Modal, Button as ReactButton, Container, Col, Form, Row } from 'react-bootstrap';
import { CloseOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { InputNumber, Checkbox, DatePicker } from 'antd';
import { Select, Table, Button as AntButton, Upload, message, Switch } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import CustomMaterialMenuSQ from './ForComponents/CustomMaterialMenuSQ';
import { API_BASE, handleRequest } from '../../redux/config/env';
import Viewer, { Worker } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import { FacebookShareButton, WhatsappShareButton, EmailShareButton, FacebookIcon, WhatsappIcon, EmailIcon } from "react-share";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import $ from "jquery";

const { Column } = Table;
const { Option } = Select;

var _paymentRows = [
    {
        paymentIndex: 1,
        title: "1. payment",
        description: "",
        paymentValue: "",
        paymentValueType: "Select"
    }
];

var _termCondRows = [];

var _noteRows = [];
var _requiredDocumentRows = [];

export class PurchaseOrderSub extends Component {
    constructor() {
        super();
        this.state = {
            priceDiscountModal: false,
            selectedPriceDiscountRow: null,
            selectedPriceDiscountRowIndex: null,

            vendorEvaluations: [],
            vendorEvaluation: "",
            rfqItemList: [],
            rfqList: [],
            purchaseOrders: [],
            quoteId: null,
            modalDelete: false,
            modalEdit: false,
            products: [],
            unitCodes: [],
            currencyCodes: [],
            serviceList: [],
            imageUrl: "",
            address: "",
            country: "",
            name: "",
            midname: "",
            surname: "",
            position: "",
            rPhone: "",
            rEmail: "",
            quoteValidityCheck: false,
            quoteValidityValue: "",
            quoteValidityTimeType: "Select",
            paymentCheck: false,
            VATTaxCheck: false,
            VATTaxValue: "",
            paymentRows: [
                {
                    paymentIndex: 1,
                    title: "1. payment",
                    description: "",
                    paymentValue: "",
                    paymentValueType: "Select",
                },
            ],
            termCondRows: [],
            termCondName: "",
            noteRows: [],
            noteName: "",
            totalDiscountInput: "",
            phone: [],
            fax: [],
            email: [],
            web: "",
            modalError: false,
            price: "",
            unit: "",
            quantity: 1,
            currency: "",
            discount: "",
            deliverySchedule: "",
            modalDeleteProduct: false,
            productId: null,
            productUpdateButtonHidden: "none",
            productAddButtonHidden: "block",
            subTotals: [],
            subTotalCheck: false,
            discountCheck: false,
            totalCheck: false,
            subDiscount: 0,
            subTotalDiscount: [],
            total: [],
            selectedRow: null,
            modalShowResponse: false,
            responseRow: null,
            modalShowApprovedAsIs: false,
            approvedAsIsPath: null,
            approvedAsIsDate: null,
            approvedAsIsNeedContract: true,
            modalShowApprovedAsChanges: false,
            approvedAsChangesPath: null,
            approvedAsChangesDate: null,
            approvedAsChangesNeedContract: true,
            isRevise: false,
            rejectionDate: null,
            quoteNo: "",
            isApprovedAsChanges: false,
            modalPdfViewer: false,
            pdfFile: "",
            material: "",
            materials: [],
            date: null,
            requiredDocumentRows: [],
            invoiceCheck: false,
            deliveryNoteCheck: false,
            packingListCheck: false,
            testReportCheck: false,
            manufacturerManualCheck: false
        };
        this.checkReport = this.checkReport.bind(this);
    }

    checkReport(rowData) {
        return (
            <React.Fragment>
                <Button onClick={this.showReport(rowData)} type="button">
                    File
                </Button>
            </React.Fragment>
        );
    }

    showReport = async (rowData) => {
        try {
            const fileRealUrl = `${API_BASE}/PurchaseOrder/download1/` + rowData.id;

            console.log("fileUrl: " + fileRealUrl);

            this.setState({
                pdfFile: fileRealUrl,
                modalPdfViewer: true,
            });

        } catch (error) {
            console.log(error);
        }
    }

    componentDidMount = async () => {
        //console.log(this.props.row);  //Expand ettiğimizde expand edilen satır gelir.
        //alttaki şekilde row okunacak.

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0");
        var yyyy = today.getFullYear();

        today = dd + "/" + mm + "/" + yyyy;

        const _contactDefault = { id: 0, location: "", country: "", address: "", web: "", phones: [], faxes: [], mails: [] };
        const _representativeDefault = {
            id: 0,
            name: "",
            midname: "",
            lastname: "",
            position: "",
            phone: "",
            email: "",
        };
        var _representative;
        var _contact;
        var _phones = [];
        var _faxes = [];
        var _mails = [];
        if (
            this.props.row.contact !== null &&
            this.props.row.contact !== undefined
        ) {
            _contact = this.props.row.contact;
            if (_contact.phones !== null && _contact.phones !== undefined) {
                _phones = _contact.phones.replace(",", ", ");
            }
            if (_contact.faxes !== null && _contact.faxes !== undefined)
                _faxes = _contact.faxes.replace(",", ", ");
            if (_contact.mails !== null && _contact.mails !== undefined)
                _mails = _contact.mails.replace(",", ", ");
        } else _contact = _contactDefault;

        if (
            this.props.row.representative !== null &&
            this.props.row.representative !== undefined
        )
            _representative = this.props.row.representative;
        else _representative = _representativeDefault;

        var _imageUrl = "";

        if (this.props.row.logoPath !== null && this.props.row.logoPath !== undefined && this.props.row.logoPath.length > 0) {
            _imageUrl = `${API_BASE}/VendorLog/download/` + this.props.row.id;
        }

        this.setState({
            paymentRows: _paymentRows,
            imageUrl: _imageUrl,
            address: _contact.address,
            country: _contact.country,
            phone: _phones,
            fax: _faxes,
            email: _mails,
            web: _contact.web,
            name: _representative.name,
            midname: _representative.midname,
            surname: _representative.lastname,
            position: _representative.position,
            rPhone: _representative.phone,
            rEmail: _representative.email,
            approvedAsIsDate: today,
            approvedAsChangesDate: today,
            rejectionDate: today,
            date: today
        });

        this.restartTable();
        //this.fillMaterials();
    }

    fillMaterials = async () => {
        const responseMaterialLogs = await handleRequest("GET", "/MaterialIdentification/getAll");

        var columns = responseMaterialLogs.data;
        var options = [];
        for (let index = 0; index < columns.length; index++) {
            options.push({
                text: columns[index].materialName,
                value: columns[index].id,
                materialName: columns[index].materialName,
                materialCode: columns[index].materialCode
            });
        }

        if (responseMaterialLogs.data.length === 0) {
            this.setState({
                materials: [],
            });
        }

        if (responseMaterialLogs.data.length > 0) {
            this.setState({
                materials: options,
            });
        }
    }

    restartTable = async () => {
        const response = await handleRequest("GET", "/PurchaseOrder/getAllByCompany");

        if (response.data.length === 0) {
            this.setState({
                purchaseOrders: [],
            });
        }

        if (response.data.length !== 0) {
            let flagSalesQuote = [];
            for (let index = 0; index < response.data.length; index++) {
                if (response.data[index].vendorLogId === this.props.row.id) {
                    flagSalesQuote.push(response.data[index]);
                }
            }
            this.setState({
                purchaseOrders: flagSalesQuote,
            });

            console.log(flagSalesQuote);
        }

        const responseEvaluations = await handleRequest("GET", "/VendorEvaluationSelected/getAllByCompany");

        if (responseEvaluations.data.length === 0) {
            this.setState({
                vendorEvaluations: []
            });
        } else {
            let flagVendorEva = [];
            for (let index = 0; index < responseEvaluations.data.length; index++) {
                if (responseEvaluations.data[index].selected !== undefined)
                    if (responseEvaluations.data[index].selected && (responseEvaluations.data[index].vendorLogId === this.props.row.id))
                        flagVendorEva.push(responseEvaluations.data[index]);
            }
            this.setState({
                vendorEvaluations: flagVendorEva
            });
        }

        const responseRFQ = await handleRequest("GET", "/RequestForQuotation/getAllByCompany");

        if (responseRFQ.data.length === 0) {
            this.setState({
                rfqList: []
            });
        } else {
            this.setState({
                rfqList: responseRFQ.data
            });
        }
    }

    resetInputs = () => {
        _requiredDocumentRows = [];

        this.setState({
            serviceList: [],
            material: "",
            quoteValidityCheck: false,
            quoteValidityValue: "",
            quoteValidityTimeType: "Select",
            paymentCheck: false,
            VATTaxCheck: false,
            VATTaxValue: "",
            paymentRows: [
                {
                    paymentIndex: 1,
                    title: "1. payment",
                    description: "",
                    paymentValue: "",
                    paymentValueType: "Select",
                },
            ],
            termCondRows: [],
            termCondName: "",
            noteRows: [],
            noteName: "",
            totalDiscountInput: "",
            modalError: false,
            price: "",
            unit: "",
            quantity: 1,
            currency: "",
            discount: "",
            deliverySchedule: "",
            modalDeleteProduct: false,
            productId: null,
            productUpdateButtonHidden: "none",
            productAddButtonHidden: "block",
            subTotals: [],
            subTotalCheck: false,
            subDiscount: 0,
            subTotalDiscount: [],
            total: [],
            quoteNo: "",
            responseRow: null,
            requiredDocumentRows: [],
            requiredDocumentRowsBackup: [],
            invoiceCheck: false,
            deliveryNoteCheck: false,
            packingListCheck: false,
            testReportCheck: false,
            manufacturerManualCheck: false
        });
        $(".ant-upload-list").empty();
    }

    showOrHideModalEdit = () => {
        this.resetInputs();
        this.setState({
            modalEdit: !this.state.modalEdit,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    onApprovedAsChanges = () => {
        let row = this.state.responseRow;

        let flagIndex = -1;
        for (let index = 0; index < this.state.vendorEvaluations.length; index++) {
            if (this.state.vendorEvaluations[index].id === row.vendorEvaluationSelectedId)
                flagIndex = index;
        }

        let flagVendorEvaluation = null;

        if (flagIndex !== -1)
            if (this.state.vendorEvaluations[flagIndex] !== undefined)
                if (this.state.vendorEvaluations[flagIndex] !== null)
                    flagVendorEvaluation = this.state.vendorEvaluations[flagIndex];

        var _rfqItemList = [];

        if (flagIndex !== -1) {
            const _contactDefault = { id: 0, location: "", country: "", address: "", web: "", phones: [], faxes: [], mails: [] };
            const _representativeDefault = { id: 0, name: "", midname: "", lastname: "", position: "", phone: "", email: "" };
            var _representative;
            var _contact;
            var _phones = [];
            var _faxes = [];
            var _mails = [];
            var _rfqItemList = [];

            if (flagVendorEvaluation.vendorLog.contact !== null && flagVendorEvaluation.vendorLog.contact !== undefined) {
                _contact = flagVendorEvaluation.vendorLog.contact;
                if (_contact.phones !== null && _contact.phones !== undefined) {
                    _phones = _contact.phones.replace(",", ", ");
                }
                if (_contact.faxes !== null && _contact.faxes !== undefined)
                    _faxes = _contact.faxes.replace(",", ", ");
                if (_contact.mails !== null && _contact.mails !== undefined)
                    _mails = _contact.mails.replace(",", ", ");
            }
            else _contact = _contactDefault;

            if (flagVendorEvaluation.vendorLog.representative !== null && flagVendorEvaluation.vendorLog.representative !== undefined)
                _representative = flagVendorEvaluation.vendorLog.representative;
            else _representative = _representativeDefault;

            var _imageUrl = "";

            if (flagVendorEvaluation.vendorLog.logoPath !== null && flagVendorEvaluation.vendorLog.logoPath !== undefined && flagVendorEvaluation.vendorLog.logoPath.length > 0) {
                _imageUrl = `${API_BASE}/VendorLog/download/` + flagVendorEvaluation.vendorLog.id
            }

            if (flagVendorEvaluation.requestForQuotation !== undefined) {
                if (flagVendorEvaluation.requestForQuotation !== null) {
                    if (flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs.length > 0) {
                        let a = 0;
                        for (let index1 = 0; index1 < flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs.length; index1++) {
                            if (flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs[index1].quantity.includes(",")) {
                                var flagQuantityLength = flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs[index1].quantity.split(",").length;
                                var flagQuantity = flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs[index1].quantity.split(",");
                                var flagDeliveryDate = flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs[index1].deliveryDate.split(",");
                                for (let index2 = 0; index2 < flagQuantityLength; index2++) {
                                    _rfqItemList.push({
                                        id: row.purchaseOrderItemListSub[a].id,
                                        active: true,
                                        materialName: flagVendorEvaluation.requestForQuotation.projectTakeOffList.projectTakeOffListSubs[index1].description,
                                        materialCode: flagVendorEvaluation.requestForQuotation.projectTakeOffList.projectTakeOffListSubs[index1].code,
                                        unit: "",
                                        price: row.purchaseOrderItemListSub[a].price,
                                        priceCurrency: row.purchaseOrderItemListSub[a].currency,
                                        discount: row.purchaseOrderItemListSub[a].discount,
                                        projectTakeOffList: flagVendorEvaluation.requestForQuotation.projectTakeOffLists[0],
                                        projectTakeOffListSub: flagVendorEvaluation.requestForQuotation.projectTakeOffList.projectTakeOffListSubs[index1],
                                        quantity: flagQuantity[index2],
                                        deliveryDate: flagDeliveryDate[index2]
                                    });
                                    a = a + 1;
                                    console.log(a);
                                }
                            } else {
                                _rfqItemList.push({
                                    id: row.purchaseOrderItemListSub[index1].id,
                                    active: true,
                                    materialName: flagVendorEvaluation.requestForQuotation.projectTakeOffList.projectTakeOffListSubs[index1].description,
                                    materialCode: flagVendorEvaluation.requestForQuotation.projectTakeOffList.projectTakeOffListSubs[index1].code,
                                    unit: "",
                                    price: row.purchaseOrderItemListSub[index1].price,
                                    priceCurrency: row.purchaseOrderItemListSub[index1].currency,
                                    discount: row.purchaseOrderItemListSub[index1].discount,
                                    projectTakeOffList: flagVendorEvaluation.requestForQuotation.projectTakeOffLists[0],
                                    projectTakeOffListSub: flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs[index1],
                                    quantity: flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs[index1].quantity,
                                    deliveryDate: flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs[index1].deliveryDate
                                });
                            }
                        }
                    } else if (flagVendorEvaluation.requestForQuotation.projectTakeOffLists.length > 0) {
                        let a = 0;
                        if (flagVendorEvaluation.requestForQuotation.projectTakeOffLists[0].quantity.includes(",")) {
                            var flagQuantityLength = flagVendorEvaluation.requestForQuotation.projectTakeOffLists[0].quantity.split(",").length;
                            var flagQuantity = flagVendorEvaluation.requestForQuotation.projectTakeOffLists[0].quantity.split(",");
                            var flagDeliveryDate = flagVendorEvaluation.requestForQuotation.projectTakeOffLists[0].deliveryDate.split(",");
                            for (let index2 = 0; index2 < flagQuantityLength; index2++) {
                                _rfqItemList.push({
                                    id: row.purchaseOrderItemList[a].id,
                                    active: true,
                                    materialName: flagVendorEvaluation.requestForQuotation.projectTakeOffList.description,
                                    materialCode: flagVendorEvaluation.requestForQuotation.projectTakeOffList.code,
                                    unit: "",
                                    price: row.purchaseOrderItemList[a].price,
                                    priceCurrency: row.purchaseOrderItemList[a].currency,
                                    discount: row.purchaseOrderItemList[a].discount,
                                    projectTakeOffList: flagVendorEvaluation.requestForQuotation.projectTakeOffList,
                                    projectTakeOffListSub: null,
                                    quantity: flagQuantity[index2],
                                    deliveryDate: flagDeliveryDate[index2]
                                });
                                a = a + 1;
                            }
                        } else {
                            _rfqItemList.push({
                                id: row.purchaseOrderItemList[0].id,
                                active: true,
                                materialName: flagVendorEvaluation.requestForQuotation.projectTakeOffList.description,
                                materialCode: flagVendorEvaluation.requestForQuotation.projectTakeOffList.code,
                                unit: "",
                                price: row.purchaseOrderItemList[0].price,
                                priceCurrency: row.purchaseOrderItemList[0].currency,
                                discount: row.purchaseOrderItemList[0].discount,
                                projectTakeOffList: flagVendorEvaluation.requestForQuotation.projectTakeOffList,
                                projectTakeOffListSub: null,
                                quantity: flagVendorEvaluation.requestForQuotation.projectTakeOffLists[0].quantity,
                                deliveryDate: flagVendorEvaluation.requestForQuotation.projectTakeOffLists[0].deliveryDate
                            });
                        }
                    } else
                        _rfqItemList = [];
                }
                else
                    _rfqItemList = [];
            }

            let qvCheck = false;
            if (row.quoteValidityCheck === 1) {
                qvCheck = true;
            }

            let vCheck = false;
            if (row.vatCheck === 1) {
                vCheck = true;
            }

            _termCondRows = row.purchaseOrderNewTermList;
            _noteRows = row.purchaseOrderNoteList;

            let diffCurrencies = [];

            for (let index = 0; index < _rfqItemList.length; index++) {
                if ((!diffCurrencies.includes(_rfqItemList[index].priceCurrency)) && _rfqItemList[index].priceCurrency !== "" && _rfqItemList[index].priceCurrency !== null) {
                    diffCurrencies.push(_rfqItemList[index].priceCurrency);
                }
            }

            let flagSubTotals = Array(diffCurrencies.length).fill(0);

            for (let index = 0; index < diffCurrencies.length; index++) {
                for (let index1 = 0; index1 < _rfqItemList.length; index1++) {
                    if (diffCurrencies[index] === _rfqItemList[index1].priceCurrency) {
                        flagSubTotals[index] += ((_rfqItemList[index1].price * _rfqItemList[index1].quantity) - (((_rfqItemList[index1].price * _rfqItemList[index1].quantity) * _rfqItemList[index1].discount) / 100));
                    }
                }
            }

            let subTotalsState = [];
            let subDiscountTotalState = [];
            let totalState = [];

            for (let index = 0; index < diffCurrencies.length; index++) {
                subTotalsState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] });
                if (row.discount !== "") {
                    let discountValues = (flagSubTotals[index] * row.discount) / 100;
                    subDiscountTotalState.push({ index: index + 1, total: discountValues.toFixed(2), currency: diffCurrencies[index] })
                    totalState.push({ index: index + 1, total: (flagSubTotals[index] - discountValues).toFixed(2), currency: diffCurrencies[index] })
                } else {
                    subDiscountTotalState.push({ index: index + 1, total: "0", currency: diffCurrencies[index] })
                    totalState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] })
                }
            }

            let stCheck = false;
            if (row.subTotalCheck === 1) {
                stCheck = true;
            }

            let dCheck = false;
            if (row.discountCheck === 1) {
                dCheck = true;
            }

            let tCheck = false;
            if (row.totalCheck === 1) {
                tCheck = true;
            }

            let pCheck = false;
            if (row.paymentCheck === 1) {
                pCheck = true;
            }

            var today = new Date();
            var dd = String(today.getDate()).padStart(2, "0");
            var mm = String(today.getMonth() + 1).padStart(2, "0");
            var yyyy = today.getFullYear();

            today = dd + "/" + mm + "/" + yyyy;

            _paymentRows = row.purchaseOrderPaymentList;

            var updatedRequestedDocuments = [];

            for (let index = 0; index < row.purchaseOrderRequiredDocuments.length; index++) {
                updatedRequestedDocuments.push({
                    id: row.purchaseOrderRequiredDocuments[index].id,
                    active: row.purchaseOrderRequiredDocuments[index].active,
                    requiredDocumentIndex: index + 1,
                    checked: row.purchaseOrderRequiredDocuments[index].checked,
                    description: row.purchaseOrderRequiredDocuments[index].description
                });
            }

            _requiredDocumentRows = updatedRequestedDocuments;

            this.setState({
                modalShowResponse: false,
                isRevise: false,
                isApprovedAsChanges: true,
                modalShowApprovedAsChanges: true,
                selectedRow: row,
                quoteId: row.id,
                subTotals: subTotalsState,
                subTotalDiscount: subDiscountTotalState,
                total: totalState,
                subTotalCheck: stCheck,
                discountCheck: dCheck,
                subDiscount: row.discount,
                totalCheck: tCheck,
                quoteValidityCheck: qvCheck,
                quoteValidityValue: row.quoteValidityValue,
                quoteValidityTimeType: row.quoteValidityDateType,
                paymentCheck: pCheck,
                paymentRows: row.purchaseOrderPaymentList,
                VATTaxCheck: vCheck,
                VATTaxValue: row.vatValue,
                termCondRows: row.purchaseOrderNewTermList,
                noteRows: row.purchaseOrderNoteList,
                quoteNo: row.quoteNo,
                approvedAsChangesPath: null,
                approvedAsChangesDate: today,
                approvedAsChangesNeedContract: false,

                vendorEvaluation: row.vendorEvaluationSelectedId,
                vendorFullName: flagVendorEvaluation.vendorLog.fullName,
                vendorShortName: flagVendorEvaluation.vendorLog.shortName,
                imageUrl: _imageUrl,
                address: _contact.address,
                country: _contact.country,
                phone: _phones,
                fax: _faxes,
                email: _mails,
                web: _contact.web,
                name: _representative.name,
                midname: _representative.midname,
                surname: _representative.lastname,
                position: _representative.position,
                rPhone: _representative.phone,
                rEmail: _representative.email,
                rfqItemList: _rfqItemList,
                requiredDocumentRows: updatedRequestedDocuments,
                requiredDocumentRowsBackup: row.purchaseOrderRequiredDocuments,
                invoiceCheck: row.invoice,
                deliveryNoteCheck: row.deliveryNote,
                packingListCheck: row.packingList,
                testReportCheck: row.testReport,
                manufacturerManualCheck: row.manufacturerManual
            });

            setTimeout(() => {
                document.body.style.overflow = "auto";
            }, 500);
        }
    }

    onRevise = () => {
        var row = this.state.responseRow;

        let flagIndex = -1;
        for (let index = 0; index < this.state.vendorEvaluations.length; index++) {
            if (this.state.vendorEvaluations[index].id === row.vendorEvaluationSelectedId)
                flagIndex = index;
        }

        let flagVendorEvaluation = null;

        if (flagIndex !== -1)
            if (this.state.vendorEvaluations[flagIndex] !== undefined)
                if (this.state.vendorEvaluations[flagIndex] !== null)
                    flagVendorEvaluation = this.state.vendorEvaluations[flagIndex];

        var _rfqItemList = [];

        if (flagIndex !== -1) {
            const _contactDefault = { id: 0, location: "", country: "", address: "", web: "", phones: [], faxes: [], mails: [] };
            const _representativeDefault = { id: 0, name: "", midname: "", lastname: "", position: "", phone: "", email: "" };
            var _representative;
            var _contact;
            var _phones = [];
            var _faxes = [];
            var _mails = [];
            var _rfqItemList = [];

            if (flagVendorEvaluation.vendorLog.contact !== null && flagVendorEvaluation.vendorLog.contact !== undefined) {
                _contact = flagVendorEvaluation.vendorLog.contact;
                if (_contact.phones !== null && _contact.phones !== undefined) {
                    _phones = _contact.phones.replace(",", ", ");
                }
                if (_contact.faxes !== null && _contact.faxes !== undefined)
                    _faxes = _contact.faxes.replace(",", ", ");
                if (_contact.mails !== null && _contact.mails !== undefined)
                    _mails = _contact.mails.replace(",", ", ");
            }
            else _contact = _contactDefault;

            if (flagVendorEvaluation.vendorLog.representative !== null && flagVendorEvaluation.vendorLog.representative !== undefined)
                _representative = flagVendorEvaluation.vendorLog.representative;
            else _representative = _representativeDefault;

            var _imageUrl = "";

            if (flagVendorEvaluation.vendorLog.logoPath !== null && flagVendorEvaluation.vendorLog.logoPath !== undefined && flagVendorEvaluation.vendorLog.logoPath.length > 0) {
                _imageUrl = `${API_BASE}/VendorLog/download/` + flagVendorEvaluation.vendorLog.id
            }

            if (flagVendorEvaluation.requestForQuotation !== undefined) {
                if (flagVendorEvaluation.requestForQuotation !== null) {
                    if (flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs.length > 0) {
                        let a = 0;
                        for (let index1 = 0; index1 < flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs.length; index1++) {
                            if (flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs[index1].quantity.includes(",")) {
                                var flagQuantityLength = flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs[index1].quantity.split(",").length;
                                var flagQuantity = flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs[index1].quantity.split(",");
                                var flagDeliveryDate = flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs[index1].deliveryDate.split(",");
                                for (let index2 = 0; index2 < flagQuantityLength; index2++) {
                                    _rfqItemList.push({
                                        id: row.purchaseOrderItemListSub[a].id,
                                        active: true,
                                        materialName: flagVendorEvaluation.requestForQuotation.projectTakeOffList.projectTakeOffListSubs[index1].description,
                                        materialCode: flagVendorEvaluation.requestForQuotation.projectTakeOffList.projectTakeOffListSubs[index1].code,
                                        unit: "",
                                        price: row.purchaseOrderItemListSub[a].price,
                                        priceCurrency: row.purchaseOrderItemListSub[a].currency,
                                        discount: row.purchaseOrderItemListSub[a].discount,
                                        projectTakeOffList: flagVendorEvaluation.requestForQuotation.projectTakeOffLists[0],
                                        projectTakeOffListSub: flagVendorEvaluation.requestForQuotation.projectTakeOffList.projectTakeOffListSubs[index1],
                                        quantity: flagQuantity[index2],
                                        deliveryDate: flagDeliveryDate[index2]
                                    });
                                    a = a + 1;
                                    console.log(a);
                                }
                            } else {
                                _rfqItemList.push({
                                    id: row.purchaseOrderItemListSub[index1].id,
                                    active: true,
                                    materialName: flagVendorEvaluation.requestForQuotation.projectTakeOffList.projectTakeOffListSubs[index1].description,
                                    materialCode: flagVendorEvaluation.requestForQuotation.projectTakeOffList.projectTakeOffListSubs[index1].code,
                                    unit: "",
                                    price: row.purchaseOrderItemListSub[index1].price,
                                    priceCurrency: row.purchaseOrderItemListSub[index1].currency,
                                    discount: row.purchaseOrderItemListSub[index1].discount,
                                    projectTakeOffList: flagVendorEvaluation.requestForQuotation.projectTakeOffLists[0],
                                    projectTakeOffListSub: flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs[index1],
                                    quantity: flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs[index1].quantity,
                                    deliveryDate: flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs[index1].deliveryDate
                                });
                            }
                        }
                    } else if (flagVendorEvaluation.requestForQuotation.projectTakeOffLists.length > 0) {
                        let a = 0;
                        if (flagVendorEvaluation.requestForQuotation.projectTakeOffLists[0].quantity.includes(",")) {
                            var flagQuantityLength = flagVendorEvaluation.requestForQuotation.projectTakeOffLists[0].quantity.split(",").length;
                            var flagQuantity = flagVendorEvaluation.requestForQuotation.projectTakeOffLists[0].quantity.split(",");
                            var flagDeliveryDate = flagVendorEvaluation.requestForQuotation.projectTakeOffLists[0].deliveryDate.split(",");
                            for (let index2 = 0; index2 < flagQuantityLength; index2++) {
                                _rfqItemList.push({
                                    id: row.purchaseOrderItemList[a].id,
                                    active: true,
                                    materialName: flagVendorEvaluation.requestForQuotation.projectTakeOffList.description,
                                    materialCode: flagVendorEvaluation.requestForQuotation.projectTakeOffList.code,
                                    unit: "",
                                    price: row.purchaseOrderItemList[a].price,
                                    priceCurrency: row.purchaseOrderItemList[a].currency,
                                    discount: row.purchaseOrderItemList[a].discount,
                                    projectTakeOffList: flagVendorEvaluation.requestForQuotation.projectTakeOffList,
                                    projectTakeOffListSub: null,
                                    quantity: flagQuantity[index2],
                                    deliveryDate: flagDeliveryDate[index2]
                                });
                                a = a + 1;
                            }
                        } else {
                            _rfqItemList.push({
                                id: row.purchaseOrderItemList[0].id,
                                active: true,
                                materialName: flagVendorEvaluation.requestForQuotation.projectTakeOffList.description,
                                materialCode: flagVendorEvaluation.requestForQuotation.projectTakeOffList.code,
                                unit: "",
                                price: row.purchaseOrderItemList[0].price,
                                priceCurrency: row.purchaseOrderItemList[0].currency,
                                discount: row.purchaseOrderItemList[0].discount,
                                projectTakeOffList: flagVendorEvaluation.requestForQuotation.projectTakeOffList,
                                projectTakeOffListSub: null,
                                quantity: flagVendorEvaluation.requestForQuotation.projectTakeOffLists[0].quantity,
                                deliveryDate: flagVendorEvaluation.requestForQuotation.projectTakeOffLists[0].deliveryDate
                            });
                        }
                    } else
                        _rfqItemList = [];
                }
                else
                    _rfqItemList = [];
            }

            let qvCheck = false;
            if (row.quoteValidityCheck === 1) {
                qvCheck = true;
            }

            let vCheck = false;
            if (row.vatCheck === 1) {
                vCheck = true;
            }

            _termCondRows = row.purchaseOrderNewTermList;
            _noteRows = row.purchaseOrderNoteList;

            let diffCurrencies = [];

            for (let index = 0; index < _rfqItemList.length; index++) {
                if ((!diffCurrencies.includes(_rfqItemList[index].priceCurrency)) && _rfqItemList[index].priceCurrency !== "" && _rfqItemList[index].priceCurrency !== null) {
                    diffCurrencies.push(_rfqItemList[index].priceCurrency);
                }
            }

            let flagSubTotals = Array(diffCurrencies.length).fill(0);

            for (let index = 0; index < diffCurrencies.length; index++) {
                for (let index1 = 0; index1 < _rfqItemList.length; index1++) {
                    if (diffCurrencies[index] === _rfqItemList[index1].priceCurrency) {
                        flagSubTotals[index] += ((_rfqItemList[index1].price * _rfqItemList[index1].quantity) - (((_rfqItemList[index1].price * _rfqItemList[index1].quantity) * _rfqItemList[index1].discount) / 100));
                    }
                }
            }

            let subTotalsState = [];
            let subDiscountTotalState = [];
            let totalState = [];

            for (let index = 0; index < diffCurrencies.length; index++) {
                subTotalsState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] });
                if (row.discount !== "") {
                    let discountValues = (flagSubTotals[index] * row.discount) / 100;
                    subDiscountTotalState.push({ index: index + 1, total: discountValues.toFixed(2), currency: diffCurrencies[index] })
                    totalState.push({ index: index + 1, total: (flagSubTotals[index] - discountValues).toFixed(2), currency: diffCurrencies[index] })
                } else {
                    subDiscountTotalState.push({ index: index + 1, total: "0", currency: diffCurrencies[index] })
                    totalState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] })
                }
            }

            let stCheck = false;
            if (row.subTotalCheck === 1) {
                stCheck = true;
            }

            let dCheck = false;
            if (row.discountCheck === 1) {
                dCheck = true;
            }

            let tCheck = false;
            if (row.totalCheck === 1) {
                tCheck = true;
            }

            let pCheck = false;
            if (row.paymentCheck === 1) {
                pCheck = true;
            }

            _paymentRows = row.purchaseOrderPaymentList;

            var updatedRequestedDocuments = [];

            for (let index = 0; index < row.purchaseOrderRequiredDocuments.length; index++) {
                updatedRequestedDocuments.push({
                    id: row.purchaseOrderRequiredDocuments[index].id,
                    active: row.purchaseOrderRequiredDocuments[index].active,
                    requiredDocumentIndex: index + 1,
                    checked: row.purchaseOrderRequiredDocuments[index].checked,
                    description: row.purchaseOrderRequiredDocuments[index].description
                });
            }

            _requiredDocumentRows = updatedRequestedDocuments;

            this.setState({
                modalShowResponse: false,
                modalEdit: true,
                isRevise: true,
                isApprovedAsChanges: false,
                selectedRow: row,
                quoteId: row.id,
                subTotals: subTotalsState,
                subTotalDiscount: subDiscountTotalState,
                total: totalState,
                subTotalCheck: stCheck,
                discountCheck: dCheck,
                subDiscount: row.discount,
                totalCheck: tCheck,
                quoteValidityCheck: qvCheck,
                quoteValidityValue: row.quoteValidityValue,
                quoteValidityTimeType: row.quoteValidityDateType,
                paymentCheck: pCheck,
                paymentRows: row.purchaseOrderPaymentList,
                VATTaxCheck: vCheck,
                VATTaxValue: row.vatValue,
                termCondRows: row.purchaseOrderNewTermList,
                noteRows: row.purchaseOrderNoteList,
                quoteNo: row.quoteNo,

                vendorEvaluation: row.vendorEvaluationSelectedId,
                vendorFullName: flagVendorEvaluation.vendorLog.fullName,
                vendorShortName: flagVendorEvaluation.vendorLog.shortName,
                imageUrl: _imageUrl,
                address: _contact.address,
                country: _contact.country,
                phone: _phones,
                fax: _faxes,
                email: _mails,
                web: _contact.web,
                name: _representative.name,
                midname: _representative.midname,
                surname: _representative.lastname,
                position: _representative.position,
                rPhone: _representative.phone,
                rEmail: _representative.email,
                rfqItemList: _rfqItemList,
                requiredDocumentRows: updatedRequestedDocuments,
                requiredDocumentRowsBackup: row.purchaseOrderRequiredDocuments,
                invoiceCheck: row.invoice,
                deliveryNoteCheck: row.deliveryNote,
                packingListCheck: row.packingList,
                testReportCheck: row.testReport,
                manufacturerManualCheck: row.manufacturerManual
            });

            setTimeout(() => {
                document.body.style.overflow = "auto";
            }, 500);
        }
    }

    edit = (row) => {
        let flagIndex = -1;
        for (let index = 0; index < this.state.vendorEvaluations.length; index++) {
            if (this.state.vendorEvaluations[index].id === row.vendorEvaluationSelectedId)
                flagIndex = index;
        }

        let flagVendorEvaluation = null;

        if (flagIndex !== -1)
            if (this.state.vendorEvaluations[flagIndex] !== undefined)
                if (this.state.vendorEvaluations[flagIndex] !== null)
                    flagVendorEvaluation = this.state.vendorEvaluations[flagIndex];

        if (flagIndex !== -1) {
            const _contactDefault = { id: 0, location: "", country: "", address: "", web: "", phones: [], faxes: [], mails: [] };
            const _representativeDefault = { id: 0, name: "", midname: "", lastname: "", position: "", phone: "", email: "" };
            var _representative;
            var _contact;
            var _phones = [];
            var _faxes = [];
            var _mails = [];
            var _rfqItemList = [];

            if (flagVendorEvaluation.vendorLog.contact !== null && flagVendorEvaluation.vendorLog.contact !== undefined) {
                _contact = flagVendorEvaluation.vendorLog.contact;
                if (_contact.phones !== null && _contact.phones !== undefined) {
                    _phones = _contact.phones.replace(",", ", ");
                }
                if (_contact.faxes !== null && _contact.faxes !== undefined)
                    _faxes = _contact.faxes.replace(",", ", ");
                if (_contact.mails !== null && _contact.mails !== undefined)
                    _mails = _contact.mails.replace(",", ", ");
            }
            else _contact = _contactDefault;

            if (flagVendorEvaluation.vendorLog.representative !== null && flagVendorEvaluation.vendorLog.representative !== undefined)
                _representative = flagVendorEvaluation.vendorLog.representative;
            else _representative = _representativeDefault;

            var _imageUrl = "";

            if (flagVendorEvaluation.vendorLog.logoPath !== null && flagVendorEvaluation.vendorLog.logoPath !== undefined && flagVendorEvaluation.vendorLog.logoPath.length > 0) {
                _imageUrl = `${API_BASE}/VendorLog/download/` + flagVendorEvaluation.vendorLog.id
            }

            if (flagVendorEvaluation.requestForQuotation !== undefined) {
                if (flagVendorEvaluation.requestForQuotation !== null) {
                    if (flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs.length > 0) {
                        let a = 0;
                        for (let index1 = 0; index1 < flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs.length; index1++) {
                            if (flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs[index1].quantity.includes(",")) {
                                var flagQuantityLength = flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs[index1].quantity.split(",").length;
                                var flagQuantity = flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs[index1].quantity.split(",");
                                var flagDeliveryDate = flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs[index1].deliveryDate.split(",");
                                for (let index2 = 0; index2 < flagQuantityLength; index2++) {
                                    _rfqItemList.push({
                                        id: row.purchaseOrderItemListSub[a].id,
                                        active: true,
                                        materialName: flagVendorEvaluation.requestForQuotation.projectTakeOffList.projectTakeOffListSubs[index1].description,
                                        materialCode: flagVendorEvaluation.requestForQuotation.projectTakeOffList.projectTakeOffListSubs[index1].code,
                                        unit: "",
                                        price: row.purchaseOrderItemListSub[a].price,
                                        priceCurrency: row.purchaseOrderItemListSub[a].currency,
                                        discount: row.purchaseOrderItemListSub[a].discount,
                                        projectTakeOffList: flagVendorEvaluation.requestForQuotation.projectTakeOffLists[0],
                                        projectTakeOffListSub: flagVendorEvaluation.requestForQuotation.projectTakeOffList.projectTakeOffListSubs[index1],
                                        quantity: flagQuantity[index2],
                                        deliveryDate: flagDeliveryDate[index2]
                                    });
                                    a = a + 1;
                                    console.log(a);
                                }
                            } else {
                                _rfqItemList.push({
                                    id: row.purchaseOrderItemListSub[index1].id,
                                    active: true,
                                    materialName: flagVendorEvaluation.requestForQuotation.projectTakeOffList.projectTakeOffListSubs[index1].description,
                                    materialCode: flagVendorEvaluation.requestForQuotation.projectTakeOffList.projectTakeOffListSubs[index1].code,
                                    unit: "",
                                    price: row.purchaseOrderItemListSub[index1].price,
                                    priceCurrency: row.purchaseOrderItemListSub[index1].currency,
                                    discount: row.purchaseOrderItemListSub[index1].discount,
                                    projectTakeOffList: flagVendorEvaluation.requestForQuotation.projectTakeOffLists[0],
                                    projectTakeOffListSub: flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs[index1],
                                    quantity: flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs[index1].quantity,
                                    deliveryDate: flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs[index1].deliveryDate
                                });
                            }
                        }
                    } else if (flagVendorEvaluation.requestForQuotation.projectTakeOffLists.length > 0) {
                        let a = 0;
                        if (flagVendorEvaluation.requestForQuotation.projectTakeOffLists[0].quantity.includes(",")) {
                            var flagQuantityLength = flagVendorEvaluation.requestForQuotation.projectTakeOffLists[0].quantity.split(",").length;
                            var flagQuantity = flagVendorEvaluation.requestForQuotation.projectTakeOffLists[0].quantity.split(",");
                            var flagDeliveryDate = flagVendorEvaluation.requestForQuotation.projectTakeOffLists[0].deliveryDate.split(",");
                            for (let index2 = 0; index2 < flagQuantityLength; index2++) {
                                _rfqItemList.push({
                                    id: row.purchaseOrderItemList[a].id,
                                    active: true,
                                    materialName: flagVendorEvaluation.requestForQuotation.projectTakeOffList.description,
                                    materialCode: flagVendorEvaluation.requestForQuotation.projectTakeOffList.code,
                                    unit: "",
                                    price: row.purchaseOrderItemList[a].price,
                                    priceCurrency: row.purchaseOrderItemList[a].currency,
                                    discount: row.purchaseOrderItemList[a].discount,
                                    projectTakeOffList: flagVendorEvaluation.requestForQuotation.projectTakeOffList,
                                    projectTakeOffListSub: null,
                                    quantity: flagQuantity[index2],
                                    deliveryDate: flagDeliveryDate[index2]
                                });
                                a = a + 1;
                            }
                        } else {
                            _rfqItemList.push({
                                id: row.purchaseOrderItemList[0].id,
                                active: true,
                                materialName: flagVendorEvaluation.requestForQuotation.projectTakeOffList.description,
                                materialCode: flagVendorEvaluation.requestForQuotation.projectTakeOffList.code,
                                unit: "",
                                price: row.purchaseOrderItemList[0].price,
                                priceCurrency: row.purchaseOrderItemList[0].currency,
                                discount: row.purchaseOrderItemList[0].discount,
                                projectTakeOffList: flagVendorEvaluation.requestForQuotation.projectTakeOffList,
                                projectTakeOffListSub: null,
                                quantity: flagVendorEvaluation.requestForQuotation.projectTakeOffLists[0].quantity,
                                deliveryDate: flagVendorEvaluation.requestForQuotation.projectTakeOffLists[0].deliveryDate
                            });
                        }
                    } else
                        _rfqItemList = [];
                }
                else
                    _rfqItemList = [];
            }

            let qvCheck = false;
            if (row.quoteValidityCheck === 1) {
                qvCheck = true;
            }

            let vCheck = false;
            if (row.vatCheck === 1) {
                vCheck = true;
            }

            _termCondRows = row.purchaseOrderNewTermList;
            _noteRows = row.purchaseOrderNoteList;

            let diffCurrencies = [];

            for (let index = 0; index < _rfqItemList.length; index++) {
                if ((!diffCurrencies.includes(_rfqItemList[index].priceCurrency)) && _rfqItemList[index].priceCurrency !== "" && _rfqItemList[index].priceCurrency !== null) {
                    diffCurrencies.push(_rfqItemList[index].priceCurrency);
                }
            }

            let flagSubTotals = Array(diffCurrencies.length).fill(0);

            for (let index = 0; index < diffCurrencies.length; index++) {
                for (let index1 = 0; index1 < _rfqItemList.length; index1++) {
                    if (diffCurrencies[index] === _rfqItemList[index1].priceCurrency) {
                        flagSubTotals[index] += ((_rfqItemList[index1].price * _rfqItemList[index1].quantity) - (((_rfqItemList[index1].price * _rfqItemList[index1].quantity) * _rfqItemList[index1].discount) / 100));
                    }
                }
            }

            let subTotalsState = [];
            let subDiscountTotalState = [];
            let totalState = [];

            for (let index = 0; index < diffCurrencies.length; index++) {
                subTotalsState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] });
                if (row.discount !== "") {
                    let discountValues = (flagSubTotals[index] * row.discount) / 100;
                    subDiscountTotalState.push({ index: index + 1, total: discountValues.toFixed(2), currency: diffCurrencies[index] })
                    totalState.push({ index: index + 1, total: (flagSubTotals[index] - discountValues).toFixed(2), currency: diffCurrencies[index] })
                } else {
                    subDiscountTotalState.push({ index: index + 1, total: "0", currency: diffCurrencies[index] })
                    totalState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] })
                }
            }

            let stCheck = false;
            if (row.subTotalCheck === 1) {
                stCheck = true;
            }

            let dCheck = false;
            if (row.discountCheck === 1) {
                dCheck = true;
            }

            let tCheck = false;
            if (row.totalCheck === 1) {
                tCheck = true;
            }

            let pCheck = false;
            if (row.paymentCheck === 1) {
                pCheck = true;
            }

            let flagQuoteNo = "";
            if (row.quoteNo === null && row.quoteNo === "") {
                flagQuoteNo = "";
            } else {
                flagQuoteNo = row.quoteNo;
            }

            var updatedRequestedDocuments = [];

            for (let index = 0; index < row.purchaseOrderRequiredDocuments.length; index++) {
                updatedRequestedDocuments.push({
                    id: row.purchaseOrderRequiredDocuments[index].id,
                    active: row.purchaseOrderRequiredDocuments[index].active,
                    requiredDocumentIndex: index + 1,
                    checked: row.purchaseOrderRequiredDocuments[index].checked,
                    description: row.purchaseOrderRequiredDocuments[index].description
                });
            }

            _requiredDocumentRows = updatedRequestedDocuments;

            _paymentRows = row.purchaseOrderPaymentList;

            this.setState({
                modalEdit: true,
                isRevise: false,
                selectedRow: row,
                responseRow: row,
                quoteId: row.id,
                //serviceList: productListTable,
                subTotals: subTotalsState,
                subTotalDiscount: subDiscountTotalState,
                total: totalState,
                subTotalCheck: stCheck,
                discountCheck: dCheck,
                subDiscount: row.discount,
                totalCheck: tCheck,
                quoteValidityCheck: qvCheck,
                quoteValidityValue: row.quoteValidityValue,
                quoteValidityTimeType: row.quoteValidityDateType,
                paymentCheck: pCheck,
                paymentRows: row.purchaseOrderPaymentList,
                VATTaxCheck: vCheck,
                VATTaxValue: row.vatValue,
                termCondRows: row.purchaseOrderNewTermList,
                noteRows: row.purchaseOrderNoteList,
                quoteNo: flagQuoteNo,

                vendorEvaluation: row.vendorEvaluationSelectedId,
                vendorFullName: flagVendorEvaluation.vendorLog.fullName,
                vendorShortName: flagVendorEvaluation.vendorLog.shortName,
                imageUrl: _imageUrl,
                address: _contact.address,
                country: _contact.country,
                phone: _phones,
                fax: _faxes,
                email: _mails,
                web: _contact.web,
                name: _representative.name,
                midname: _representative.midname,
                surname: _representative.lastname,
                position: _representative.position,
                rPhone: _representative.phone,
                rEmail: _representative.email,
                rfqItemList: _rfqItemList,
                requiredDocumentRows: updatedRequestedDocuments,
                requiredDocumentRowsBackup: row.purchaseOrderRequiredDocuments,
                invoiceCheck: row.invoice,
                deliveryNoteCheck: row.deliveryNote,
                packingListCheck: row.packingList,
                testReportCheck: row.testReport,
                manufacturerManualCheck: row.manufacturerManual
            });
        }
    }

    editOK = async () => {
        let list = this.state.rfqItemList;
        let flagMaterialList = [];
        let flagMaterialListSub = [];

        let flagIndex = 0;

        for (let index = 0; index < this.state.vendorEvaluations.length; index++) {
            if (this.state.vendorEvaluations[index].id === this.state.vendorEvaluation)
                flagIndex = index;
        }

        let flagVendorEvaluation = this.state.vendorEvaluations[flagIndex];

        for (let index = 0; index < list.length; index++) {
            if (flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs !== undefined && flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs !== null
                && flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs.length !== 0) {
                if (flagVendorEvaluation.requestForQuotation.projectTakeOffListSubs.length > 0)
                    flagMaterialListSub.push({
                        id: list[index].id,
                        active: list[index].active,
                        materialName: list[index].materialName,
                        materialCode: list[index].materialCode,
                        quantity: list[index].quantity,
                        price: list[index].price,
                        currency: list[index].priceCurrency,
                        discount: list[index].discount,
                        rfqProjectTakeOffListSubId: list[index].projectTakeOffListSub.id,
                        deliveryDate: list[index].deliveryDate
                    });
            }
            else if (flagVendorEvaluation.requestForQuotation.projectTakeOffLists !== undefined && flagVendorEvaluation.requestForQuotation.projectTakeOffLists !== null
                && flagVendorEvaluation.requestForQuotation.projectTakeOffLists.length !== 0) {
                if (flagVendorEvaluation.requestForQuotation.projectTakeOffLists.length > 0)
                    flagMaterialList.push({
                        id: list[index].id,
                        active: list[index].active,
                        materialName: list[index].materialName,
                        materialCode: list[index].materialCode,
                        quantity: list[index].quantity,
                        price: list[index].price,
                        currency: list[index].priceCurrency,
                        discount: list[index].discount,
                        rfqProjectTakeOffListId: list[index].projectTakeOffList.id,
                        deliveryDate: list[index].deliveryDate
                    });
            }
        }


        let discountValue = 0;

        if (this.state.subDiscount === null || this.state.subDiscount === "") {
            discountValue = 0;
        } else {
            discountValue = this.state.subDiscount;
        }

        let qvCheck = 0;

        if (this.state.quoteValidityCheck === true) {
            qvCheck = 1;
        }

        let pCheck = 0;

        if (this.state.paymentCheck === true) {
            pCheck = 1;
        }

        let vCheck = 0;

        if (this.state.VATTaxCheck === true) {
            vCheck = 1;
        }

        let stCheck = 0;

        if (this.state.subTotalCheck === true) {
            stCheck = 1;
        }

        let dCheck = 0;

        if (this.state.discountCheck === true) {
            dCheck = 1;
        }

        let tCheck = 0;

        if (this.state.totalCheck === true) {
            tCheck = 1;
        }

        let paymentList = this.state.paymentRows;

        let flagRequiredDocuments = [];

        for (let index = 0; index < this.state.requiredDocumentRows.length; index++) {
            if (this.state.requiredDocumentRows[index].id !== undefined && this.state.requiredDocumentRows[index].checked) {
                if (this.state.requiredDocumentRows[index].id !== null) {
                    flagRequiredDocuments.push({
                        id: this.state.requiredDocumentRows[index].id,
                        active: true,
                        checked: this.state.requiredDocumentRows[index].checked,
                        description: this.state.requiredDocumentRows[index].description
                    });
                }
            } else {
                flagRequiredDocuments.push({
                    checked: this.state.requiredDocumentRows[index].checked,
                    description: this.state.requiredDocumentRows[index].description
                });
            }
        }

        for (let index = 0; index < this.state.requiredDocumentRowsBackup.length; index++) {
            let whichIndexRequiredDocument = this.state.requiredDocumentRows.findIndex(i => Boolean(i.id) && i.checked && i.id === this.state.requiredDocumentRowsBackup[index].id);

            if (whichIndexRequiredDocument === -1) {
                flagRequiredDocuments.push({
                    id: this.state.requiredDocumentRowsBackup[index].id,
                    active: false,
                    checked: this.state.requiredDocumentRowsBackup[index].checked,
                    description: this.state.requiredDocumentRowsBackup[index].description
                });
            }
        }

        if (this.state.isRevise) {
            let flagRevNo = 0;
            if (this.state.selectedRow.revNo === null) {
                flagRevNo = 1;
            } else {
                flagRevNo = this.state.selectedRow.revNo + 1;
            }

            var today = new Date();
            var dd = String(today.getDate()).padStart(2, "0");
            var mm = String(today.getMonth() + 1).padStart(2, "0");
            var yyyy = today.getFullYear();

            today = dd + "/" + mm + "/" + yyyy;

            let flagMaterialList2 = [];
            let flagMaterialListSub2 = [];

            for (let index = 0; index < flagMaterialList.length; index++) {
                flagMaterialList2.push({
                    materialName: list[index].materialName,
                    materialCode: list[index].materialCode,
                    quantity: list[index].quantity,
                    price: list[index].price,
                    currency: list[index].priceCurrency,
                    discount: list[index].discount,
                    rfqProjectTakeOffListId: list[index].projectTakeOffList.id,
                    deliveryDate: list[index].deliveryDate
                });
            }

            for (let index = 0; index < flagMaterialListSub.length; index++) {
                flagMaterialListSub2.push({
                    materialName: list[index].materialName,
                    materialCode: list[index].materialCode,
                    quantity: list[index].quantity,
                    price: list[index].price,
                    currency: list[index].priceCurrency,
                    discount: list[index].discount,
                    rfqProjectTakeOffListSubId: list[index].projectTakeOffListSub.id,
                    deliveryDate: list[index].deliveryDate
                });
            }

            const updateOldSalesQuote = {
                id: this.state.quoteId,
                responseDate: today,
                status: "Revised"
            };

            await handleRequest("POST", "/PurchaseOrder/updateOld", updateOldSalesQuote);

            const revisedSalesQuote = {
                id: this.state.quoteId,
                vendorLogId: this.props.row.id,
                discount: discountValue,
                subTotalCheck: stCheck,
                discountCheck: dCheck,
                totalCheck: tCheck,
                quoteDate: this.state.selectedRow.quoteDate,
                quoteValidityCheck: qvCheck,
                quoteValidityValue: this.state.quoteValidityValue,
                quoteValidityDateType: this.state.quoteValidityTimeType,
                paymentCheck: pCheck,
                purchaseOrderPaymentList: paymentList,
                vatCheck: vCheck,
                vatValue: this.state.VATTaxValue,
                purchaseOrderNewTermList: this.state.termCondRows,
                purchaseOrderNoteList: this.state.noteRows,
                quoteNo: this.state.quoteNo,
                revNo: flagRevNo,
                quotePdfPath: "",
                responseDate: "",
                status: "Pending",
                needsForContract: false,
                rfqId: this.state.vendorEvaluations[flagIndex].requestForQuotation.id,
                vendorEvaluationSelectedId: this.state.vendorEvaluations[flagIndex].id,
                purchaseOrderItemList: flagMaterialList2,
                purchaseOrderItemListSub: flagMaterialListSub2,
                invoice: this.state.invoiceCheck,
                deliveryNote: this.state.deliveryNoteCheck,
                packingList: this.state.packingListCheck,
                testReport: this.state.testReportCheck,
                manufacturerManual: this.state.manufacturerManualCheck,
                purchaseOrderRequiredDocuments: flagRequiredDocuments
            };

            await handleRequest("POST", "/PurchaseOrder/add", revisedSalesQuote);

            this.setState({
                modalEdit: false,
                isRevise: false,
            });

            this.restartTable();
            this.resetInputs();
        } else if (this.state.isApprovedAsChanges) {
            let flagRevNo = 0;
            if (this.state.selectedRow.revNo === null) {
                flagRevNo = 1;
            } else {
                flagRevNo = this.state.selectedRow.revNo + 1;
            }

            const approvedAsChangesSalesQuote = {
                id: this.state.responseRow.id,
                responseDate: this.state.approvedAsChangesDate,
                status: "Revised",
            };

            await handleRequest(
                "POST",
                "/PurchaseOrder/updateOld",
                approvedAsChangesSalesQuote
            );

            let flagMaterialList2 = [];
            let flagMaterialListSub2 = [];

            for (let index = 0; index < flagMaterialList.length; index++) {
                flagMaterialList2.push({
                    materialName: list[index].materialName,
                    materialCode: list[index].materialCode,
                    quantity: list[index].quantity,
                    price: list[index].price,
                    currency: list[index].priceCurrency,
                    discount: list[index].discount,
                    rfqProjectTakeOffListId: list[index].projectTakeOffList.id,
                    deliveryDate: list[index].deliveryDate
                });
            }

            for (let index = 0; index < flagMaterialListSub.length; index++) {
                flagMaterialListSub2.push({
                    materialName: list[index].materialName,
                    materialCode: list[index].materialCode,
                    quantity: list[index].quantity,
                    price: list[index].price,
                    currency: list[index].priceCurrency,
                    discount: list[index].discount,
                    rfqProjectTakeOffListSubId: list[index].projectTakeOffListSub.id,
                    deliveryDate: list[index].deliveryDate
                });
            }

            const revisedSalesQuote = {
                id: this.state.quoteId,
                vendorLogId: this.props.row.id,
                discount: discountValue,
                subTotalCheck: stCheck,
                discountCheck: dCheck,
                totalCheck: tCheck,
                quoteDate: this.state.selectedRow.quoteDate,
                quoteValidityCheck: qvCheck,
                quoteValidityValue: this.state.quoteValidityValue,
                quoteValidityDateType: this.state.quoteValidityTimeType,
                paymentCheck: pCheck,
                purchaseOrderPaymentList: paymentList,
                vatCheck: vCheck,
                vatValue: this.state.VATTaxValue,
                purchaseOrderNewTermList: this.state.termCondRows,
                purchaseOrderNoteList: this.state.noteRows,
                quoteNo: this.state.quoteNo,
                revNo: flagRevNo,
                quotePdfPath: this.state.approvedAsChangesPath,
                responseDate: this.state.approvedAsChangesDate,
                status: "Approved with",
                needsForContract: this.state.approvedAsChangesNeedContract,
                rfqId: this.state.vendorEvaluations[flagIndex].requestForQuotation.id,
                vendorEvaluationSelectedId: this.state.vendorEvaluations[flagIndex].id,
                purchaseOrderItemList: flagMaterialList2,
                purchaseOrderItemListSub: flagMaterialListSub2,
                invoice: this.state.invoiceCheck,
                deliveryNote: this.state.deliveryNoteCheck,
                packingList: this.state.packingListCheck,
                testReport: this.state.testReportCheck,
                manufacturerManual: this.state.manufacturerManualCheck,
                purchaseOrderRequiredDocuments: flagRequiredDocuments
            };

            await handleRequest("POST", "/PurchaseOrder/add", revisedSalesQuote);

            this.setState({
                modalEdit: false,
                isApprovedAsChanges: false,
            });

            this.restartTable();
            this.resetInputs();
        } else {
            let flagRevNo = 0;
            if (this.state.selectedRow.revNo === null) {
                flagRevNo = 0;
            } else {
                flagRevNo = this.state.selectedRow.revNo;
            }

            let flagQuotePdfPath = "";
            if (this.state.selectedRow.quotePdfPath === null) {
                flagQuotePdfPath = "";
            } else {
                flagQuotePdfPath = this.state.selectedRow.quotePdfPath;
            }

            let flagResponseDate = "";
            if (this.state.selectedRow.responseDate === null) {
                flagResponseDate = "";
            } else {
                flagResponseDate = this.state.selectedRow.responseDate;
            }

            let flagStatus = "";
            if (this.state.selectedRow.status === null) {
                flagStatus = "";
            } else {
                flagStatus = this.state.selectedRow.status;
            }

            const editedSalesQuote = {
                id: this.state.quoteId,
                vendorLogId: this.props.row.id,
                discount: discountValue,
                subTotalCheck: stCheck,
                discountCheck: dCheck,
                totalCheck: tCheck,
                quoteDate: this.state.selectedRow.quoteDate,
                quoteValidityCheck: qvCheck,
                quoteValidityValue: this.state.quoteValidityValue,
                quoteValidityDateType: this.state.quoteValidityTimeType,
                paymentCheck: pCheck,
                purchaseOrderPaymentList: paymentList,
                vatCheck: vCheck,
                vatValue: this.state.VATTaxValue,
                purchaseOrderNewTermList: this.state.termCondRows,
                purchaseOrderNoteList: this.state.noteRows,
                quoteNo: this.state.quoteNo,
                revNo: flagRevNo,
                quotePdfPath: flagQuotePdfPath,
                responseDate: flagResponseDate,
                status: flagStatus,
                needsForContract: this.state.selectedRow.needsForContract,
                rfqId: this.state.vendorEvaluations[flagIndex].requestForQuotation.id,
                vendorEvaluationSelectedId: this.state.vendorEvaluations[flagIndex].id,
                purchaseOrderItemList: flagMaterialList,
                purchaseOrderItemListSub: flagMaterialListSub,
                invoice: this.state.invoiceCheck,
                deliveryNote: this.state.deliveryNoteCheck,
                packingList: this.state.packingListCheck,
                testReport: this.state.testReportCheck,
                manufacturerManual: this.state.manufacturerManualCheck,
                purchaseOrderRequiredDocuments: flagRequiredDocuments
            };

            await handleRequest("POST", "/PurchaseOrder/update", editedSalesQuote);

            this.setState({
                modalEdit: false,
            });

            this.restartTable();
            this.resetInputs();
        }

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalPdfViewer = () => {
        this.setState({
            modalPdfViewer: !this.state.modalPdfViewer,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalDelete = () => {
        this.setState({
            modalDelete: !this.state.modalDelete,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    deleteModal = (row) => {
        this.setState({
            modalDelete: true,
            quoteId: row.id,
        });
    }

    deleteOK = async () => {
        const deletedItem = {
            id: this.state.quoteId,
        };

        await handleRequest("POST", "/PurchaseOrder/delete", deletedItem);

        this.setState({
            modalDelete: false,
            quoteId: null,
        });

        this.restartTable();
        this.resetInputs();

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    selectMaterial = (value) => {
        var list = this.state.materials;
        var filteredList = list.filter(p => p.value === value);

        if (filteredList.length > 0) {
            this.setState({
                material: value
            });
        }
    }

    addProduct = () => {
        var list = this.state.serviceList;

        if (this.state.material !== "" && this.state.unit !== ""
            && this.state.price !== "" && this.state.currency !== "") {
            var _key = list.length + 1;
            let discountValue = 0;

            if (this.state.discount === null || this.state.discount === "") {
                discountValue = 0
            } else {
                discountValue = this.state.discount
            }

            const newItem = {
                key: _key,
                material: this.state.material,
                quantity: this.state.quantity,
                unit: this.state.unit,
                price: this.state.price,
                currency: this.state.currency,
                discount: discountValue,
                date: this.state.date,
            }

            list.push(newItem);

            let diffCurrencies = [];

            for (let index = 0; index < list.length; index++) {
                if (!diffCurrencies.includes(list[index].currency)) {
                    diffCurrencies.push(list[index].currency);
                }
            }

            let flagSubTotals = Array(diffCurrencies.length).fill(0);

            for (let index = 0; index < diffCurrencies.length; index++) {
                for (let index1 = 0; index1 < list.length; index1++) {
                    if (diffCurrencies[index] === list[index1].currency) {
                        flagSubTotals[index] += ((list[index1].price * list[index1].quantity) - (((list[index1].price * list[index1].quantity) * list[index1].discount) / 100));
                    }
                }
            }

            let subTotalsState = [];
            let subDiscountTotalState = [];
            let totalState = [];

            for (let index = 0; index < diffCurrencies.length; index++) {
                subTotalsState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] });
                if (this.state.subDiscount != "") {
                    let discountValues = (flagSubTotals[index] * this.state.subDiscount) / 100;
                    subDiscountTotalState.push({ index: index + 1, total: discountValues.toFixed(2), currency: diffCurrencies[index] })
                    totalState.push({ index: index + 1, total: (flagSubTotals[index] - discountValues).toFixed(2), currency: diffCurrencies[index] })
                } else {
                    subDiscountTotalState.push({ index: index + 1, total: "0", currency: diffCurrencies[index] })
                    totalState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] })
                }
            }

            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0');
            var yyyy = today.getFullYear();

            today = dd + '/' + mm + '/' + yyyy;

            this.setState({
                serviceList: list.slice(),
                material: "",
                quantity: "1",
                unit: "",
                price: "",
                currency: "",
                discount: "",
                date: today,
                subTotals: subTotalsState,
                subTotalDiscount: subDiscountTotalState,
                total: totalState
            });
        } else {
            this.setState({
                modalError: true
            });
        }
    }

    showOrHideModalDeleteProduct = () => {
        this.setState({
            modalDeleteProduct: !this.state.modalDeleteProduct,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    deleteProductModal = (row) => {
        this.setState({
            productId: row.key,
            modalDeleteProduct: true,
        });
    }

    deleteProductModalOK = () => {
        let list = this.state.serviceList;
        let flagProductId = this.state.productId;

        list.splice(list.findIndex(function (i) {
            return i.key === flagProductId;
        }), 1);

        let diffCurrencies = [];

        for (let index = 0; index < list.length; index++) {
            if (!diffCurrencies.includes(list[index].currency)) {
                diffCurrencies.push(list[index].currency);
            }
        }

        let flagSubTotals = Array(diffCurrencies.length).fill(0);

        for (let index = 0; index < diffCurrencies.length; index++) {
            for (let index1 = 0; index1 < list.length; index1++) {
                if (diffCurrencies[index] === list[index1].currency) {
                    flagSubTotals[index] += ((list[index1].price * list[index1].quantity) - (((list[index1].price * list[index1].quantity) * list[index1].discount) / 100));
                }
            }
        }

        let subTotalsState = [];
        let subDiscountTotalState = [];
        let totalState = [];

        for (let index = 0; index < diffCurrencies.length; index++) {
            subTotalsState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] });
            if (this.state.subDiscount != "") {
                let discountValues = (flagSubTotals[index] * this.state.subDiscount) / 100;
                subDiscountTotalState.push({ index: index + 1, total: discountValues.toFixed(2), currency: diffCurrencies[index] })
                totalState.push({ index: index + 1, total: (flagSubTotals[index] - discountValues).toFixed(2), currency: diffCurrencies[index] })
            } else {
                subDiscountTotalState.push({ index: index + 1, total: "0", currency: diffCurrencies[index] })
                totalState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] })
            }
        }

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        this.setState({
            serviceList: list.slice(),
            productId: null,
            modalDeleteProduct: false,
            material: "",
            quantity: "1",
            unit: "",
            price: "",
            currency: "",
            discount: "",
            date: today,
            productUpdateButtonHidden: "none",
            productAddButtonHidden: "block",
            subTotals: subTotalsState,
            subTotalDiscount: subDiscountTotalState,
            total: totalState
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    editService = (row) => {
        this.setState({
            productId: row.key,
            material: row.material,
            quantity: row.quantity,
            unit: row.unit,
            price: row.price,
            currency: row.currency,
            discount: row.discount,
            date: row.date,
            productUpdateButtonHidden: "block",
            productAddButtonHidden: "none"
        });
    }

    updateProduct = () => {
        let list = this.state.serviceList;
        let flagProductId = this.state.productId;

        let objIndex = list.findIndex((obj => obj.key === flagProductId));

        let discountValue = 0;

        if (this.state.discount === null || this.state.discount === "") {
            discountValue = 0
        } else {
            discountValue = this.state.discount
        }

        list[objIndex].material = this.state.material;
        list[objIndex].quantity = this.state.quantity;
        list[objIndex].unit = this.state.unit;
        list[objIndex].price = this.state.price;
        list[objIndex].currency = this.state.currency;
        list[objIndex].discount = discountValue;
        list[objIndex].date = this.state.date;

        let diffCurrencies = [];

        for (let index = 0; index < list.length; index++) {
            if (!diffCurrencies.includes(list[index].currency)) {
                diffCurrencies.push(list[index].currency);
            }
        }

        let flagSubTotals = Array(diffCurrencies.length).fill(0);

        for (let index = 0; index < diffCurrencies.length; index++) {
            for (let index1 = 0; index1 < list.length; index1++) {
                if (diffCurrencies[index] === list[index1].currency) {
                    flagSubTotals[index] += ((list[index1].price * list[index1].quantity) - (((list[index1].price * list[index1].quantity) * list[index1].discount) / 100));
                }
            }
        }

        let subTotalsState = [];
        let subDiscountTotalState = [];
        let totalState = [];

        for (let index = 0; index < diffCurrencies.length; index++) {
            subTotalsState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] });
            if (this.state.subDiscount != "") {
                let discountValues = (flagSubTotals[index] * this.state.subDiscount) / 100;
                subDiscountTotalState.push({ index: index + 1, total: discountValues.toFixed(2), currency: diffCurrencies[index] })
                totalState.push({ index: index + 1, total: (flagSubTotals[index] - discountValues).toFixed(2), currency: diffCurrencies[index] })
            } else {
                subDiscountTotalState.push({ index: index + 1, total: "0", currency: diffCurrencies[index] })
                totalState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] })
            }
        }

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        this.setState({
            serviceList: list.slice(),
            productId: null,
            material: "",
            quantity: "1",
            unit: "",
            price: "",
            currency: "",
            discount: "",
            date: today,
            productUpdateButtonHidden: "none",
            productAddButtonHidden: "block",
            subTotals: subTotalsState,
            subTotalDiscount: subDiscountTotalState,
            total: totalState
        });
    }

    cancelProduct = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        this.setState({
            productId: null,
            material: "",
            quantity: "1",
            unit: "",
            price: "",
            currency: "",
            discount: "",
            date: today,
            productUpdateButtonHidden: "none",
            productAddButtonHidden: "block"
        });
    }

    addNewPaymentLine = () => {
        _paymentRows.push({
            paymentIndex: _paymentRows.length + 1,
            title: (_paymentRows.length + 1).toString() + ". payment",
            description: "",
            paymentValue: "",
            paymentValueType: "Select",
        });

        this.setState({
            paymentRows: _paymentRows,
        });
    }

    removeLastPaymentLine = () => {
        if (_paymentRows.length !== 1) {
            _paymentRows.pop();
        }

        this.setState({
            paymentRows: _paymentRows,
        });
    }

    addNewTermCond = () => {
        _termCondRows.push({
            title: this.state.termCondName,
            description: "",
            checked: false,
        });

        this.setState({
            termCondRows: _termCondRows,
            termCondName: "",
        });
    }

    handleChangeTermCond = (index, e) => {
        e.preventDefault();
        let list = this.state.termCondRows;
        list[index].description = e.target.value;

        this.setState({
            termCondRows: list.slice(),
        });
    }

    handleChangePaymentDesc = (index, e) => {
        e.preventDefault();
        let list = this.state.paymentRows;
        list[index].description = e.target.value;

        this.setState({
            paymentRows: list.slice(),
        });
    }

    handleChangePaymentValue = (index, value) => {
        let list = this.state.paymentRows;
        list[index].paymentValue = value;

        this.setState({
            paymentRows: list.slice(),
        });
    }

    handleChangePaymentValueType = (index, e) => {
        e.preventDefault();
        let list = this.state.paymentRows;
        list[index].paymentValueType = e.target.value;

        this.setState({
            paymentRows: list.slice(),
        });
    }

    onChangeTermCond = (index, e) => {
        let list = this.state.termCondRows;
        list[index].checked = e.target.checked;

        this.setState({
            termCondRows: list.slice(),
        });
    }

    addNewNote = () => {
        _noteRows.push({
            title: this.state.noteName,
            description: "",
            checked: false,
        });

        this.setState({
            noteRows: _noteRows,
            noteName: "",
        });
    }

    handleChangeNote = (index, e) => {
        e.preventDefault();
        let list = this.state.noteRows;
        list[index].description = e.target.value;

        this.setState({
            noteRows: list.slice(),
        });
    }

    onChangeNote = (index, e) => {
        let list = this.state.noteRows;
        list[index].checked = e.target.checked;

        this.setState({
            noteRows: list.slice(),
        });
    }

    showOrHideModalError = () => {
        this.setState({
            modalError: !this.state.modalError,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    applyDiscount = () => {
        let list = this.state.serviceList;
        let diffCurrencies = [];

        for (let index = 0; index < list.length; index++) {
            if (!diffCurrencies.includes(list[index].currency)) {
                diffCurrencies.push(list[index].currency);
            }
        }

        let flagSubTotals = Array(diffCurrencies.length).fill(0);

        for (let index = 0; index < diffCurrencies.length; index++) {
            for (let index1 = 0; index1 < list.length; index1++) {
                if (diffCurrencies[index] === list[index1].currency) {
                    flagSubTotals[index] +=
                        list[index1].price * list[index1].quantity -
                        (list[index1].price *
                            list[index1].quantity *
                            list[index1].discount) /
                        100;
                }
            }
        }

        let subTotalsState = [];
        let subDiscountTotalState = [];
        let totalState = [];

        for (let index = 0; index < diffCurrencies.length; index++) {
            subTotalsState.push({
                index: index + 1,
                total: flagSubTotals[index].toFixed(2),
                currency: diffCurrencies[index],
            });
            if (this.state.subDiscount != "") {
                let discountValues =
                    (flagSubTotals[index] * this.state.subDiscount) / 100;
                subDiscountTotalState.push({
                    index: index + 1,
                    total: discountValues.toFixed(2),
                    currency: diffCurrencies[index],
                });
                totalState.push({
                    index: index + 1,
                    total: (flagSubTotals[index] - discountValues).toFixed(2),
                    currency: diffCurrencies[index],
                });
            } else {
                subDiscountTotalState.push({
                    index: index + 1,
                    total: "0",
                    currency: diffCurrencies[index],
                });
                totalState.push({
                    index: index + 1,
                    total: flagSubTotals[index].toFixed(2),
                    currency: diffCurrencies[index],
                });
            }
        }

        this.setState({
            subTotals: subTotalsState,
            subTotalDiscount: subDiscountTotalState,
            total: totalState,
        });
    }

    handleChange = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    onChangeSubTotalCheck = (e) => {
        this.setState({
            subTotalCheck: e.target.checked,
        });
    }

    onChangeValidityCB = (e) => {
        this.setState({
            quoteValidityCheck: e.target.checked,
        });
    }

    onChangeDiscountCheck = (e) => {
        this.setState({
            discountCheck: e.target.checked,
        });
    }

    onChangeTotalCheck = (e) => {
        this.setState({
            totalCheck: e.target.checked,
        });
    }

    onChangePaymentCB = (e) => {
        this.setState({
            paymentCheck: e.target.checked,
        });
    }

    onChangeVATTaxCB = (e) => {
        this.setState({
            VATTaxCheck: e.target.checked,
        });
    }

    response = (row) => {
        this.setState({
            modalShowResponse: true,
            responseRow: row,
        });
    }

    showOrHideModalReponse = () => {
        this.setState({
            modalShowResponse: !this.state.modalShowResponse,
            responseRow: null,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalApprovedAsIs = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0");
        var yyyy = today.getFullYear();

        today = dd + "/" + mm + "/" + yyyy;

        this.setState({
            modalShowApprovedAsIs: !this.state.modalShowApprovedAsIs,
            approvedAsIsPhotoPath: null,
            approvedAsIsDate: today,
            approvedAsIsNeedContract: false,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    onApprovedAsIs = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0");
        var yyyy = today.getFullYear();

        today = dd + "/" + mm + "/" + yyyy;

        this.setState({
            modalShowResponse: false,
            modalShowApprovedAsIs: true,
            approvedAsIsPath: null,
            approvedAsIsDate: today,
            approvedAsIsNeedContract: false,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    saveApprovedAsIs = async () => {
        const approvedAsIsSalesQuote = {
            id: this.state.responseRow.id,
            responseDate: this.state.approvedAsIsDate,
            quotePdfPath: this.state.approvedAsIsPath,
            needsForContract: this.state.approvedAsIsNeedContract,
            status: "Approved",
        };

        await handleRequest("POST", "/PurchaseOrder/updateAsIs", approvedAsIsSalesQuote);

        this.setState({
            modalShowApprovedAsIs: false,
        });

        this.restartTable();
        this.resetInputs();

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    beforeUpload = (file) => {
        const isPDF =
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "application/pdf"; //TODO: Test yapılmalı, gerçekten pdf, jpeg ve jpg ayırıyor mu?
        if (!isPDF) {
            //jpeg, jpg, pdf. dosyanın download edilmesi gerekiyor.
            message.error("You can only upload JPEG, PNG or PDF file!");
            $(".ant-upload-list").empty();
        }
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
            message.error("Image must smaller than 10MB!");
            $(".ant-upload-list").empty();
        }
        return isPDF && isLt10M;
    }

    handlePhotoChange = (info) => {
        if (info.file.status !== "uploading") {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);

            console.log(info.file.response[0]);

            if (info.file.response.length > 0) {
                this.setState({
                    approvedAsIsPath: info.file.response[0],
                });
            }
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    handlePhotoChange2 = (info) => {
        if (info.file.status !== "uploading") {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);

            console.log(info.file.response[0]);

            if (info.file.response.length > 0) {
                this.setState({
                    approvedAsChangesPath: info.file.response[0],
                });
            }
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    onChangeDate = async (date, dateString) => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + "/" + mm + "/" + yyyy;

        if (dateString !== "") {
            this.setState({
                approvedAsIsDate: dateString,
            });
        } else {
            this.setState({
                approvedAsIsDate: today,
            });
        }
    }

    onChangeDateAsChanges = async (date, dateString) => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + "/" + mm + "/" + yyyy;

        if (dateString !== "") {
            this.setState({
                approvedAsChangesDate: dateString,
            });
        } else {
            this.setState({
                approvedAsChangesDate: today,
            });
        }
    }

    onChangeDateRejection = async (date, dateString) => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + "/" + mm + "/" + yyyy;

        if (dateString !== "") {
            this.setState({
                rejectionDate: dateString,
            });
        } else {
            this.setState({
                rejectionDate: today,
            });
        }
    }

    showOrHideModalApprovedAsChanges = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0");
        var yyyy = today.getFullYear();

        today = dd + "/" + mm + "/" + yyyy;

        this.setState({
            modalShowApprovedAsChanges: !this.state.modalShowApprovedAsChanges,
            approvedAsChangesPhotoPath: null,
            approvedAsChangesDate: today,
            approvedAsChangesNeedContract: false,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    saveApprovedAsChanges = () => {
        this.setState({
            modalEdit: true,
            modalShowApprovedAsChanges: false,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalRejected = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0");
        var yyyy = today.getFullYear();

        today = dd + "/" + mm + "/" + yyyy;

        this.setState({
            modalShowRejected: !this.state.modalShowRejected,
            rejectionDate: today,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    onRejected = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0");
        var yyyy = today.getFullYear();

        today = dd + "/" + mm + "/" + yyyy;

        this.setState({
            modalShowResponse: false,
            modalShowRejected: true,
            rejectionDate: today,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    saveRejection = async () => {
        const rejectedSalesQuote = {
            id: this.state.responseRow.id,
            responseDate: this.state.rejectionDate,
            status: "Rejected",
        };

        await handleRequest("POST", "/PurchaseOrder/updateOld", rejectedSalesQuote);

        this.setState({
            modalShowRejected: false,
        });

        this.restartTable();
        this.resetInputs();

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    disabledDate(current) {
        return current > moment().endOf("day");
    }

    download = (record) => {
        if (record.quotePdfPath !== null && record.quotePdfPath !== "")
            window.location.href =
                `${API_BASE}/PurchaseOrder/download/` + record.id;
        else alert("file cant find.");
    }

    showModalPriceDiscount = (row, index) => {
        this.setState({
            selectedPriceDiscountRow: row,
            selectedPriceDiscountRowIndex: index,
            setPriceModal: row.price,
            setPriceCurrencyModal: row.priceCurrency,
            setDiscountModal: row.discount,
            priceDiscountModal: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalPriceDiscount = () => {
        this.setState({
            priceDiscountModal: !this.state.priceDiscountModal,
            selectedPriceDiscountRow: null,
            setPriceModal: "",
            setPriceCurrencyModal: "",
            setDiscountModal: ""
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    setPriceDiscount = () => {
        if (this.state.setPriceModal !== null && this.state.setPriceModal !== 0 && this.state.setPriceModal !== ""
            && this.state.setPriceCurrencyModal !== null && this.state.setPriceCurrencyModal !== "") {

            let list = this.state.rfqItemList;
            let flagIndex = this.state.selectedPriceDiscountRowIndex;
            list[flagIndex].price = this.state.setPriceModal;
            list[flagIndex].priceCurrency = this.state.setPriceCurrencyModal;

            if (this.state.setDiscountModal === null || this.state.setDiscountModal === 0 || this.state.setDiscountModal === "")
                list[flagIndex].discount = 0;
            else
                list[flagIndex].discount = this.state.setDiscountModal;

            let filledList = [];

            for (let index = 0; index < list.length; index++) {
                if (list[index].price !== null && list[index].price !== 0) {
                    filledList.push(list[index]);
                }
            }

            let discountValue = 0;
            if (this.state.discount === null || this.state.discount === "") {
                discountValue = 0
            } else {
                discountValue = this.state.discount
            }

            let diffCurrencies = [];

            for (let index = 0; index < filledList.length; index++) {
                if ((!diffCurrencies.includes(filledList[index].priceCurrency)) && filledList[index].priceCurrency !== "" && filledList[index].priceCurrency !== null) {
                    diffCurrencies.push(filledList[index].priceCurrency);
                }
            }

            let flagSubTotals = Array(diffCurrencies.length).fill(0);

            for (let index = 0; index < diffCurrencies.length; index++) {
                for (let index1 = 0; index1 < filledList.length; index1++) {
                    if (diffCurrencies[index] === filledList[index1].priceCurrency) {
                        flagSubTotals[index] += ((filledList[index1].price * filledList[index1].quantity) - (((filledList[index1].price * filledList[index1].quantity) * filledList[index1].discount) / 100));
                    }
                }
            }

            let subTotalsState = [];
            let subDiscountTotalState = [];
            let totalState = [];

            for (let index = 0; index < diffCurrencies.length; index++) {
                subTotalsState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] });
                if (this.state.subDiscount != "") {
                    let discountValues = (flagSubTotals[index] * this.state.subDiscount) / 100;
                    subDiscountTotalState.push({ index: index + 1, total: discountValues.toFixed(2), currency: diffCurrencies[index] })
                    totalState.push({ index: index + 1, total: (flagSubTotals[index] - discountValues).toFixed(2), currency: diffCurrencies[index] })
                } else {
                    subDiscountTotalState.push({ index: index + 1, total: "0", currency: diffCurrencies[index] })
                    totalState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] })
                }
            }

            this.setState({
                rfqItemList: list.slice(),
                subTotals: subTotalsState,
                subTotalDiscount: subDiscountTotalState,
                total: totalState,
                priceDiscountModal: false
            });
        } else {
            this.showWarning();
        }
    }

    applyDiscount2 = () => {
        let list = this.state.rfqItemList;
        let filledList = [];

        for (let index = 0; index < list.length; index++) {
            if (list[index].price !== null && list[index].price !== 0) {
                filledList.push(list[index]);
            }
        }

        let diffCurrencies = [];

        for (let index = 0; index < filledList.length; index++) {
            if ((!diffCurrencies.includes(filledList[index].priceCurrency)) && filledList[index].priceCurrency !== "" && filledList[index].priceCurrency !== null) {
                diffCurrencies.push(filledList[index].priceCurrency);
            }
        }

        let flagSubTotals = Array(diffCurrencies.length).fill(0);

        for (let index = 0; index < diffCurrencies.length; index++) {
            for (let index1 = 0; index1 < filledList.length; index1++) {
                if (diffCurrencies[index] === filledList[index1].priceCurrency) {
                    flagSubTotals[index] += ((filledList[index1].price * filledList[index1].quantity) - (((filledList[index1].price * filledList[index1].quantity) * filledList[index1].discount) / 100));
                }
            }
        }

        let subTotalsState = [];
        let subDiscountTotalState = [];
        let totalState = [];

        for (let index = 0; index < diffCurrencies.length; index++) {
            subTotalsState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] });
            if (this.state.subDiscount != "") {
                let discountValues = (flagSubTotals[index] * this.state.subDiscount) / 100;
                subDiscountTotalState.push({ index: index + 1, total: discountValues.toFixed(2), currency: diffCurrencies[index] })
                totalState.push({ index: index + 1, total: (flagSubTotals[index] - discountValues).toFixed(2), currency: diffCurrencies[index] })
            } else {
                subDiscountTotalState.push({ index: index + 1, total: "0", currency: diffCurrencies[index] })
                totalState.push({ index: index + 1, total: flagSubTotals[index].toFixed(2), currency: diffCurrencies[index] })
            }
        }

        this.setState({
            subTotals: subTotalsState,
            subTotalDiscount: subDiscountTotalState,
            total: totalState
        });
    }

    addNewRequiredDocument = () => {
        if (this.state.requiredDocumentRows.length === 0) {
            _requiredDocumentRows.push(
                {
                    requiredDocumentIndex: _requiredDocumentRows.length + 1,
                    description: "",
                    checked: false
                }
            );

            this.setState({
                requiredDocumentRows: _requiredDocumentRows
            });
        }

        if (this.state.requiredDocumentRows.length > 0)
            if (this.state.requiredDocumentRows[this.state.requiredDocumentRows.length - 1].description !== ""
                && this.state.requiredDocumentRows[this.state.requiredDocumentRows.length - 1].description !== null) {
                _requiredDocumentRows.push(
                    {
                        requiredDocumentIndex: _requiredDocumentRows.length + 1,
                        description: "",
                        checked: false
                    }
                );

                this.setState({
                    requiredDocumentRows: _requiredDocumentRows
                });
            }
    }

    removeRequiredDocument = (index, e) => {
        _requiredDocumentRows.splice(index, 1);

        this.setState({
            requiredDocumentRows: _requiredDocumentRows
        });
    }

    handleChangeRequiredDocument = (index, e) => {
        e.preventDefault();
        let list = this.state.requiredDocumentRows;
        list[index].description = e.target.value;

        this.setState({
            requiredDocumentRows: list.slice()
        });
    }

    onChangeRequiredDocument = (index, e) => {
        let list = this.state.requiredDocumentRows;
        list[index].checked = e.target.checked;

        this.setState({
            requiredDocumentRows: list.slice()
        });
    }

    render() {

        let optionsMaterials = this.state.materials.map((data) =>
            <Select.Option
                key={data.value}
                value={data.value}
            >
                {data.materialName}
            </Select.Option>
        );

        return (
            <div style={{ marginTop: "2rem" }}>

                <Modal
                    size="xl"
                    show={this.state.modalPdfViewer}
                    onHide={this.showOrHideModalPdfViewer}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            PDF Report Viewer
               </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ textAlign: "right", marginBottom: "1rem" }}>
                            <FacebookShareButton url={this.state.pdfFile} quote="Purchase Order PDF File Link">
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <EmailShareButton style={{ marginLeft: "1rem" }} url={this.state.pdfFile} body="Purchase Order PDF File Link is here: " subject="Purchase Order PDF File Link">
                                <EmailIcon size={32} round />
                            </EmailShareButton>
                            <WhatsappShareButton
                                style={{ marginLeft: "1rem" }}
                                url={this.state.pdfFile}
                                title="Purchase Order PDF File Link"
                                separator=": "
                            >
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                        </div>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
                            <div style={{ height: "900px" }}>
                                <Viewer fileUrl={this.state.pdfFile} />
                            </div>
                        </Worker>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.showOrHideModalPdfViewer}
                        >
                            Close
               </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalDelete}
                    onHide={this.showOrHideModalDelete}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Delete Order?
                   </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            All informations about order will be deleted. Are you sure?
                   </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.showOrHideModalDelete}
                        >
                            Close
                   </Button>
                        <Button variant="danger" onClick={this.deleteOK}>
                            Delete
                   </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalDeleteProduct}
                    onHide={this.showOrHideModalDeleteProduct}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Delete Material Record?
                   </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.showOrHideModalDeleteProduct}
                        >
                            Close
                   </Button>
                        <Button variant="danger" onClick={this.deleteProductModalOK}>
                            Delete
                   </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalError}
                    onHide={this.showOrHideModalError}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Missing Values!
                   </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Please, be sure to enter all values.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.showOrHideModalError}
                        >
                            Close
                   </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.priceDiscountModal}
                    onHide={this.showOrHideModalPriceDiscount}
                    aria-labelledby="contained-modal-title-vcenter"
                    backdrop="static"
                    centered >
                    <Modal.Header style={{ backgroundColor: "#f3f3f3" }} closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <FormattedMessage id="RFQFixItem" defaultMessage="Set Price & Discount" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ backgroundColor: "#f3f3f3" }}>
                        <Row style={{ marginBottom: '1rem' }}>
                            <Col xs={4} style={{ textAlign: "right" }}>
                                <Form.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                                    Price:
                                    </Form.Label>
                            </Col>
                            <Col xs={4}>
                                <InputNumber min={0} style={{ width: '100%' }} onChange={(e) => this.setState({ setPriceModal: e })}
                                    value={this.state.setPriceModal} />
                            </Col>
                            <Col xs={4}>
                                <Select value={this.state.setPriceCurrencyModal} placeholder="Select a Currency" onChange={(value) => this.setState({ setPriceCurrencyModal: value })} >
                                    {/*<Option value="$">$</Option>
                                        <Option value="€">€</Option>*/}
                                    <Option value="SR">SR</Option>
                                </Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={4} style={{ textAlign: "right" }}>
                                <Form.Label style={{ color: "black", marginTop: "0.5rem", fontWeight: "bold" }}>
                                    Discount:
                                </Form.Label>
                            </Col>
                            <Col xs={4}>
                                <InputNumber min={0} style={{ width: '100%' }} onChange={(e) => this.setState({ setDiscountModal: e })}
                                    value={this.state.setDiscountModal} />
                            </Col>
                            <Col xs={4}>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer style={{ backgroundColor: "#f3f3f3" }}>
                        <ReactButton variant="light" onClick={this.showOrHideModalPriceDiscount}>
                            <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                        </ReactButton>
                        <ReactButton variant="primary" onClick={this.setPriceDiscount}>
                            <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                        </ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalEdit}
                    onHide={this.showOrHideModalEdit}
                    aria-labelledby="contained-modal-title-vcenter"
                    size="xl"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Editing Selected Purchase Order
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container style={{ maxWidth: "none", marginBottom: "15px" }}>
                            <Row style={{ marginBottom: '1rem' }}>
                                <Col xs={2} style={{ textAlign: 'right' }}>
                                    <Form.Label style={{ color: 'black', marginTop: '0.75rem', fontWeight: 'bold' }}>Evaluation:</Form.Label>
                                </Col>
                                <Col xs={4}>
                                    <Select name="Evaluation"
                                        style={{ width: '100%' }}
                                        disabled
                                        value={this.state.vendorEvaluation}>
                                        {this.state.vendorEvaluations.map(i => (
                                            <Option key={i.id} value={i.id}>{i.vendorLog.shortName + " / " + i.requestForQuotation.rfqNo + " / " + i.requestForQuotation.projectTakeOffList.description}</Option>
                                        ))}
                                    </Select>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={2} style={{ textAlign: "right" }}>
                                    <Form.Label style={{ color: "black", marginTop: "0.75rem", fontWeight: "bold" }}>
                                        Purchase Order No:
                                    </Form.Label>
                                </Col>
                                <Col xs={4}>
                                    <Form.Control readOnly={this.state.isRevise || this.state.isApprovedAsChanges}
                                        name="quoteNo" value={this.state.quoteNo} onChange={this.handleChange} />
                                </Col>
                            </Row>
                            <br />
                            <Accordion defaultActiveKey="1">
                                <Card>
                                    <Accordion.Toggle style={{ backgroundColor: "#E0E4FF" }} as={Card.Header} eventKey="0">
                                        <Card.Header style={{ backgroundColor: "#E0E4FF", color: "#5d78ff", margin: "15px", fontWeight: "bold" }} as="h5">
                                            Vendor Info
                                        </Card.Header>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse id="servicesAccordion" eventKey="0">
                                        <Card.Body>
                                            <Form.Group
                                                style={{ marginBottom: "1rem" }}
                                                as={Row}
                                            >
                                                <Form.Label
                                                    style={{ color: "black" }}
                                                    column
                                                    sm="3"
                                                >
                                                    Company's Full Name:
                                                </Form.Label>
                                                <Form.Label style={{ color: 'black' }} column sm="4">
                                                    {this.state.vendorFullName}
                                                </Form.Label>
                                            </Form.Group>
                                            <Form.Group
                                                style={{ marginBottom: "1rem" }}
                                                as={Row}
                                            >
                                                <Form.Label
                                                    style={{ color: "black" }}
                                                    column
                                                    sm="3"
                                                >
                                                    Company's Short Name:
                                                </Form.Label>
                                                <Form.Label style={{ color: 'black' }} column sm="4">
                                                    {this.state.vendorShortName}
                                                </Form.Label>
                                            </Form.Group>
                                            <Form.Group
                                                style={{ marginBottom: "1rem" }}
                                                as={Row}
                                            >
                                                <Form.Label
                                                    style={{ color: "black" }}
                                                    column
                                                    sm="3"
                                                >
                                                    Company Logo:
                                                </Form.Label>
                                                <Col sm="2">
                                                    {this.state.imageUrl ? (
                                                        <img
                                                            src={this.state.imageUrl}
                                                            alt="avatar"
                                                            style={{ width: "100%" }}
                                                        />
                                                    ) : (
                                                            <div>No Image</div>
                                                        )}
                                                </Col>
                                            </Form.Group>

                                            <Form.Group
                                                style={{ marginBottom: "1rem" }}
                                                as={Row}
                                            >
                                                <Form.Label
                                                    style={{ fontWeight: "bold", color: "black" }}
                                                    column
                                                    sm="3"
                                                >
                                                    Head Office
                                                </Form.Label>
                                                <Col sm="4"></Col>
                                            </Form.Group>

                                            <Form.Group
                                                style={{ marginBottom: "1rem" }}
                                                as={Row}
                                            >
                                                <Form.Label
                                                    style={{ textAlign: "right", color: "black" }}
                                                    column
                                                    sm="3"
                                                >
                                                    Address:
                                                </Form.Label>
                                                <Form.Label
                                                    style={{ color: "black" }}
                                                    column
                                                    sm="4"
                                                >
                                                    {this.state.address}
                                                </Form.Label>
                                            </Form.Group>

                                            <Form.Group
                                                style={{ marginBottom: "1rem" }}
                                                as={Row}
                                            >
                                                <Form.Label
                                                    style={{ textAlign: "right", color: "black" }}
                                                    column
                                                    sm="3"
                                                >
                                                    Country:
                                                </Form.Label>
                                                <Form.Label
                                                    style={{ color: "black" }}
                                                    column
                                                    sm="4"
                                                >
                                                    {this.state.country}
                                                </Form.Label>
                                            </Form.Group>

                                            <Form.Group
                                                style={{ marginBottom: "1rem" }}
                                                as={Row}
                                            >
                                                <Form.Label
                                                    style={{ textAlign: "right", color: "black" }}
                                                    column
                                                    sm="3"
                                                >
                                                    Phone:
                                                </Form.Label>
                                                <Form.Label
                                                    style={{ color: "black" }}
                                                    column
                                                    sm="4"
                                                >
                                                    {this.state.phone}
                                                </Form.Label>
                                            </Form.Group>

                                            <Form.Group
                                                style={{ marginBottom: "1rem" }}
                                                as={Row}
                                            >
                                                <Form.Label
                                                    style={{ textAlign: "right", color: "black" }}
                                                    column
                                                    sm="3"
                                                >
                                                    Fax:
                                                </Form.Label>
                                                <Form.Label
                                                    style={{ color: "black" }}
                                                    column
                                                    sm="4"
                                                >
                                                    {this.state.fax}
                                                </Form.Label>
                                            </Form.Group>

                                            <Form.Group
                                                style={{ marginBottom: "1rem" }}
                                                as={Row}
                                            >
                                                <Form.Label
                                                    style={{ textAlign: "right", color: "black" }}
                                                    column
                                                    sm="3"
                                                >
                                                    Web:
                                                </Form.Label>
                                                <Form.Label
                                                    style={{ color: "black" }}
                                                    column
                                                    sm="4"
                                                >
                                                    {this.state.web}
                                                </Form.Label>
                                            </Form.Group>

                                            <Form.Group
                                                style={{ marginBottom: "1rem" }}
                                                as={Row}
                                            >
                                                <Form.Label
                                                    style={{ textAlign: "right", color: "black" }}
                                                    column
                                                    sm="3"
                                                >
                                                    E-Mail:
                                                </Form.Label>
                                                <Form.Label
                                                    style={{ color: "black" }}
                                                    column
                                                    sm="4"
                                                >
                                                    {this.state.email}
                                                </Form.Label>
                                            </Form.Group>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                            <br />
                            <Accordion defaultActiveKey="1">
                                <Card>
                                    <Accordion.Toggle
                                        style={{ backgroundColor: "#E0E4FF" }}
                                        as={Card.Header}
                                        eventKey="0"
                                    >
                                        <Card.Header
                                            style={{
                                                backgroundColor: "#E0E4FF",
                                                color: "#5d78ff",
                                                margin: "15px",
                                                fontWeight: "bold",
                                            }}
                                            as="h5"
                                        >
                                            Vendor Representative
                                        </Card.Header>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse
                                        id="servicesAccordion"
                                        eventKey="0"
                                    >
                                        <Card.Body>
                                            <Form.Group
                                                style={{ marginBottom: "1rem" }}
                                                as={Row}
                                            >
                                                <Form.Label
                                                    style={{ color: "black" }}
                                                    column
                                                    sm="3"
                                                >
                                                    Name
                                                </Form.Label>
                                                <Form.Label
                                                    style={{ color: "black" }}
                                                    column
                                                    sm="4"
                                                >
                                                    {this.state.name}
                                                </Form.Label>
                                            </Form.Group>

                                            <Form.Group
                                                style={{ marginBottom: "1rem" }}
                                                as={Row}
                                            >
                                                <Form.Label
                                                    style={{ color: "black" }}
                                                    column
                                                    sm="3"
                                                >
                                                    Midname
                                                </Form.Label>
                                                <Form.Label
                                                    style={{ color: "black" }}
                                                    column
                                                    sm="4"
                                                >
                                                    {this.state.midname}
                                                </Form.Label>
                                            </Form.Group>

                                            <Form.Group
                                                style={{ marginBottom: "1rem" }}
                                                as={Row}
                                            >
                                                <Form.Label
                                                    style={{ color: "black" }}
                                                    column
                                                    sm="3"
                                                >
                                                    Surname
                                                </Form.Label>
                                                <Form.Label
                                                    style={{ color: "black" }}
                                                    column
                                                    sm="4"
                                                >
                                                    {this.state.surname}
                                                </Form.Label>
                                            </Form.Group>

                                            <Form.Group
                                                style={{ marginBottom: "1rem" }}
                                                as={Row}
                                            >
                                                <Form.Label
                                                    style={{ color: "black" }}
                                                    column
                                                    sm="3"
                                                >
                                                    Position
                                                </Form.Label>
                                                <Form.Label
                                                    style={{ color: "black" }}
                                                    column
                                                    sm="4"
                                                >
                                                    {this.state.position}
                                                </Form.Label>
                                            </Form.Group>

                                            <Form.Group
                                                style={{ marginBottom: "1rem" }}
                                                as={Row}
                                            >
                                                <Form.Label
                                                    style={{ color: "black" }}
                                                    column
                                                    sm="3"
                                                >
                                                    Phone
                                                </Form.Label>
                                                <Form.Label
                                                    style={{ color: "black" }}
                                                    column
                                                    sm="4"
                                                >
                                                    {this.state.rPhone}
                                                </Form.Label>
                                            </Form.Group>

                                            <Form.Group
                                                style={{ marginBottom: "1rem" }}
                                                as={Row}
                                            >
                                                <Form.Label
                                                    style={{ color: "black" }}
                                                    column
                                                    sm="3"
                                                >
                                                    Email
                                                </Form.Label>
                                                <Form.Label
                                                    style={{ color: "black" }}
                                                    column
                                                    sm="4"
                                                >
                                                    {this.state.rEmail}
                                                </Form.Label>
                                            </Form.Group>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                            <br />
                            <Accordion defaultActiveKey="1">
                                <Card>
                                    <Accordion.Toggle style={{ backgroundColor: "#E0E4FF" }} as={Card.Header} eventKey="0">
                                        <Card.Header style={{ backgroundColor: "#E0E4FF", color: "#5d78ff", margin: "15px", fontWeight: "bold" }} as="h5">Materials and Price</Card.Header>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse id="servicesAccordion" eventKey="0">
                                        <Card.Body>
                                            <Table style={{ marginTop: '1rem' }} size={`small`} dataSource={this.state.rfqItemList} bordered >
                                                <Column title="Material Name" key="materialName" dataIndex="materialName" />
                                                <Column title="Material Code" key="materialCode" dataIndex="materialCode" />
                                                <Column title="Quantity" key="quantity" dataIndex="quantity" />
                                                <Column title="Unit" key="unit" dataIndex="unit" />
                                                <Column title="Price" key="price" render={(value, row, index) => {
                                                    if (row.price !== null && row.priceCurrency !== null)
                                                        return row.price + " " + row.priceCurrency;
                                                    else
                                                        return row.price;
                                                }} />
                                                <Column title="Discount (%)" key="discount" dataIndex="discount" />
                                                <Column title="Delivery Date" key="deliveryDate" dataIndex="deliveryDate" />
                                                <Column title="" width='100px' key="set" render={(value, row, index) => {
                                                    return <AntButton type="primary" onClick={() => this.showModalPriceDiscount(row, index)}>Set Price & Discount</AntButton>
                                                }} />
                                            </Table>

                                            <Form>
                                                <Form.Group style={{ marginTop: '1rem' }} as={Row}>
                                                    <Col sm="5">
                                                    </Col>
                                                    <Col sm="2">
                                                        <Checkbox style={{ color: 'black', marginTop: '0.75rem' }} onChange={this.onChangeSubTotalCheck}
                                                            checked={this.state.subTotalCheck}>Sub Total</Checkbox>
                                                    </Col>
                                                    {this.state.subTotals.length > 0 &&
                                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                                            {this.state.subTotals[0].total} {this.state.subTotals[0].currency}
                                                        </Form.Label>
                                                    }
                                                    {this.state.subTotals.length === 0 &&
                                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                                            N/A Total / Unit
                                            </Form.Label>
                                                    }
                                                    <Col sm="3">
                                                    </Col>
                                                </Form.Group>

                                                {this.state.subTotals.map((item, index) => (
                                                    (item.index !== 1) && (
                                                        <Form.Group key={index} as={Row}>
                                                            <Col sm="7">
                                                            </Col>
                                                            <Form.Label style={{ color: 'black', paddingBottom: '0rem' }} column sm="2">
                                                                {this.state.subTotals[item.index - 1].total} {this.state.subTotals[item.index - 1].currency}
                                                            </Form.Label>
                                                            <Col sm="3">
                                                            </Col>
                                                        </Form.Group>
                                                    )
                                                ))}

                                                <Form.Group as={Row}>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Col sm="1" style={{ textAlign: 'right' }}>
                                                        <InputNumber style={{ marginTop: '0.25rem' }} min={0} value={this.state.subDiscount}
                                                            formatter={value => `${value}%`} parser={value => value.replace('%', '')}
                                                            onChange={(value) => { this.setState({ subDiscount: value }) }} />
                                                    </Col>
                                                    <Col sm="2">
                                                        <Button style={{ width: '100%', marginLeft: "5px" }} onClick={this.applyDiscount2} type="button">Apply Discount</Button>
                                                    </Col>
                                                    <Col sm="2">
                                                        <Checkbox style={{ color: 'black', marginTop: '0.75rem' }} onChange={this.onChangeDiscountCheck}
                                                            checked={this.state.discountCheck}>Discount</Checkbox>
                                                    </Col>
                                                    {this.state.subTotalDiscount.length > 0 &&
                                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                                            {this.state.subTotalDiscount[0].total} {this.state.subTotalDiscount[0].currency}
                                                        </Form.Label>
                                                    }
                                                    {this.state.subTotalDiscount.length === 0 &&
                                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                                            N/A Total / Unit
                                            </Form.Label>
                                                    }
                                                    <Col sm="3">
                                                    </Col>
                                                </Form.Group>

                                                {this.state.subTotalDiscount.map((item, index) => (
                                                    (item.index !== 1) && (
                                                        <Form.Group key={index} as={Row}>
                                                            <Col sm="7">
                                                            </Col>
                                                            <Form.Label style={{ color: 'black', paddingBottom: '0rem' }} column sm="2">
                                                                {this.state.subTotalDiscount[item.index - 1].total} {this.state.subTotalDiscount[item.index - 1].currency}
                                                            </Form.Label>
                                                            <Col sm="3">
                                                            </Col>
                                                        </Form.Group>
                                                    )
                                                ))}

                                                <Form.Group as={Row}>
                                                    <Col sm="5">
                                                    </Col>
                                                    <Col sm="2">
                                                        <Checkbox style={{ color: 'black', marginTop: '0.75rem' }} onChange={this.onChangeTotalCheck}
                                                            checked={this.state.totalCheck}>Total</Checkbox>
                                                    </Col>
                                                    {this.state.total.length > 0 &&
                                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                                            {this.state.total[0].total} {this.state.total[0].currency}
                                                        </Form.Label>
                                                    }
                                                    {this.state.total.length === 0 &&
                                                        <Form.Label style={{ color: 'black' }} column sm="2">
                                                            N/A Total / Unit
                                            </Form.Label>
                                                    }
                                                    <Col sm="3">
                                                    </Col>
                                                </Form.Group>

                                                {this.state.total.map((item, index) => (
                                                    (item.index !== 1) && (
                                                        <Form.Group key={index} as={Row}>
                                                            <Col sm="7">
                                                            </Col>
                                                            <Form.Label style={{ color: 'black', paddingBottom: '0rem' }} column sm="2">
                                                                {this.state.total[item.index - 1].total} {this.state.total[item.index - 1].currency}
                                                            </Form.Label>
                                                            <Col sm="3">
                                                            </Col>
                                                        </Form.Group>
                                                    )
                                                ))}
                                            </Form>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                            <br />
                            <Accordion defaultActiveKey="1">
                                <Card>
                                    <Accordion.Toggle
                                        style={{ backgroundColor: "#E0E4FF" }}
                                        as={Card.Header}
                                        eventKey="0"
                                    >
                                        <Card.Header
                                            style={{
                                                backgroundColor: "#E0E4FF",
                                                color: "#5d78ff",
                                                margin: "15px",
                                                fontWeight: "bold",
                                            }}
                                            as="h5"
                                        >
                                            Terms and Conditions
                           </Card.Header>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse
                                        id="servicesAccordion"
                                        eventKey="0"
                                    >
                                        <Card.Body>
                                            <Form>
                                                <Form.Group
                                                    style={{
                                                        marginTop: "1rem",
                                                        marginBottom: "3rem",
                                                    }}
                                                    as={Row}
                                                >
                                                    <Form.Label
                                                        style={{ color: "black" }}
                                                        column
                                                        sm="3"
                                                    >
                                                        New Terms/Conditions Name:
                                 </Form.Label>
                                                    <Col sm="4">
                                                        <Form.Control
                                                            name="termCondName"
                                                            value={this.state.termCondName}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Col>
                                                    <Col sm="3">
                                                        <Button
                                                            style={{
                                                                width: "100%",
                                                                marginLeft: "5px",
                                                            }}
                                                            onClick={this.addNewTermCond}
                                                            type="button"
                                                        >
                                                            Add
                                   </Button>
                                                    </Col>
                                                    <Col sm="2"></Col>
                                                </Form.Group>

                                                {/*<Form.Group
                                                    style={{ marginBottom: "1rem" }}
                                                    as={Row}
                                                >
                                                    <Col sm="2">
                                                        <Checkbox
                                                            style={{
                                                                color: "black",
                                                                marginTop: "0.25rem",
                                                            }}
                                                            onChange={this.onChangeValidityCB}
                                                            checked={this.state.quoteValidityCheck}
                                                        >
                                                            Quote Validty
                                   </Checkbox>
                                                    </Col>
                                                    <Col sm="2" style={{ textAlign: "right" }}>
                                                        <InputNumber
                                                            style={{ marginTop: "0.25rem" }}
                                                            min={0}
                                                            value={this.state.quoteValidityValue}
                                                            onChange={(value) => {
                                                                this.setState({
                                                                    quoteValidityValue: value,
                                                                });
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                        <Form.Control
                                                            name="quoteValidityTimeType"
                                                            value={this.state.quoteValidityTimeType}
                                                            onChange={this.handleChange}
                                                            as="select"
                                                        >
                                                            <option>Select</option>
                                                            <option>Day</option>
                                                            <option>Week</option>
                                                            <option>Month</option>
                                                            <option>Year</option>
                                                        </Form.Control>
                                                    </Col>
                                                        </Form.Group>*/}

                                                <Form.Group
                                                    style={{ marginBottom: "1rem" }}
                                                    as={Row}
                                                >
                                                    <Col sm="2">
                                                        <Checkbox
                                                            style={{
                                                                color: "black",
                                                                marginTop: "0.25rem",
                                                            }}
                                                            onChange={this.onChangePaymentCB}
                                                            checked={this.state.paymentCheck}
                                                        >
                                                            Payment Terms
                                   </Checkbox>
                                                    </Col>
                                                    <Form.Label
                                                        style={{
                                                            color: "black",
                                                            textAlign: "right",
                                                        }}
                                                        column
                                                        sm="2"
                                                    >
                                                        {this.state.paymentRows[0].title}
                                                    </Form.Label>
                                                    <Form.Label
                                                        style={{
                                                            color: "black",
                                                            textAlign: "right",
                                                        }}
                                                        column
                                                        sm="2"
                                                    >
                                                        Description:
                                 </Form.Label>
                                                    <Col sm="2">
                                                        <Form.Control
                                                            name={this.state.paymentRows[0].title}
                                                            value={
                                                                this.state.paymentRows[0].description
                                                            }
                                                            onChange={(e) =>
                                                                this.handleChangePaymentDesc(0, e)
                                                            }
                                                        />
                                                    </Col>
                                                    <Col sm="1" style={{ textAlign: "right" }}>
                                                        <InputNumber
                                                            style={{ marginTop: "0.25rem" }}
                                                            min={0}
                                                            value={
                                                                this.state.paymentRows[0].paymentValue
                                                            }
                                                            onChange={(value) =>
                                                                this.handleChangePaymentValue(0, value)
                                                            }
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                        <Form.Control
                                                            name={this.state.paymentRows[0].title}
                                                            value={
                                                                this.state.paymentRows[0]
                                                                    .paymentValueType
                                                            }
                                                            onChange={(e) =>
                                                                this.handleChangePaymentValueType(0, e)
                                                            }
                                                            as="select"
                                                        >
                                                            <option>Select</option>
                                                            <option>Currency</option>
                                                            <option>%</option>
                                                        </Form.Control>
                                                    </Col>
                                                </Form.Group>

                                                {this.state.paymentRows.map(
                                                    (item, index) =>
                                                        index !== 0 && (
                                                            <Form.Group
                                                                key={index}
                                                                style={{ marginBottom: "1rem" }}
                                                                as={Row}
                                                            >
                                                                <Col sm="2"></Col>
                                                                <Form.Label
                                                                    style={{
                                                                        color: "black",
                                                                        textAlign: "right",
                                                                    }}
                                                                    column
                                                                    sm="2"
                                                                >
                                                                    {item.title}
                                                                </Form.Label>
                                                                <Form.Label
                                                                    style={{
                                                                        color: "black",
                                                                        textAlign: "right",
                                                                    }}
                                                                    column
                                                                    sm="2"
                                                                >
                                                                    Description:
                                       </Form.Label>
                                                                <Col sm="2">
                                                                    <Form.Control
                                                                        name={item.title}
                                                                        value={item.description}
                                                                        onChange={(e) =>
                                                                            this.handleChangePaymentDesc(
                                                                                index,
                                                                                e
                                                                            )
                                                                        }
                                                                    />
                                                                </Col>
                                                                <Col
                                                                    sm="1"
                                                                    style={{ textAlign: "right" }}
                                                                >
                                                                    <InputNumber
                                                                        style={{ marginTop: "0.25rem" }}
                                                                        min={0}
                                                                        value={
                                                                            this.state.paymentRows[index]
                                                                                .paymentValue
                                                                        }
                                                                        onChange={(value) =>
                                                                            this.handleChangePaymentValue(
                                                                                index,
                                                                                value
                                                                            )
                                                                        }
                                                                    />
                                                                </Col>
                                                                <Col sm="2">
                                                                    <Form.Control
                                                                        name={item.title}
                                                                        value={item.paymentValueType}
                                                                        onChange={(e) =>
                                                                            this.handleChangePaymentValueType(
                                                                                index,
                                                                                e
                                                                            )
                                                                        }
                                                                        as="select"
                                                                    >
                                                                        <option>Select</option>
                                                                        <option>Currency</option>
                                                                        <option>%</option>
                                                                    </Form.Control>
                                                                </Col>
                                                            </Form.Group>
                                                        )
                                                )}

                                                <Form.Group
                                                    style={{ marginBottom: "1rem" }}
                                                    as={Row}
                                                >
                                                    <Col sm="4"></Col>
                                                    <Col sm="2">
                                                        <AntButton
                                                            type="dashed"
                                                            onClick={this.removeLastPaymentLine}
                                                            style={{ width: "100%" }}
                                                        >
                                                            <MinusOutlined /> Remove A Line
                                   </AntButton>
                                                    </Col>
                                                    <Col sm="2">
                                                        <AntButton
                                                            type="dashed"
                                                            onClick={this.addNewPaymentLine}
                                                            style={{ width: "100%" }}
                                                        >
                                                            <PlusOutlined /> Add New Line
                                   </AntButton>
                                                    </Col>
                                                </Form.Group>

                                                <Form.Group
                                                    style={{ marginBottom: "1rem" }}
                                                    as={Row}
                                                >
                                                    <Col sm="2">
                                                        <Checkbox
                                                            style={{
                                                                color: "black",
                                                                marginTop: "0.25rem",
                                                            }}
                                                            onChange={this.onChangeVATTaxCB}
                                                            checked={this.state.VATTaxCheck}
                                                        >
                                                            VAT Tax
                                   </Checkbox>
                                                    </Col>
                                                    <Col sm="2" style={{ textAlign: "right" }}>
                                                        <InputNumber
                                                            style={{ marginTop: "0.25rem" }}
                                                            min={0}
                                                            value={this.state.VATTaxValue}
                                                            formatter={(value) => `${value}%`}
                                                            parser={(value) => value.replace("%", "")}
                                                            onChange={(value) => {
                                                                this.setState({ VATTaxValue: value });
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col sm="2"></Col>
                                                </Form.Group>

                                                {this.state.termCondRows.map((item, index) => (
                                                    <Form.Group
                                                        key={index}
                                                        style={{ marginBottom: "1rem" }}
                                                        as={Row}
                                                    >
                                                        <Col sm="3">
                                                            <Checkbox
                                                                style={{
                                                                    color: "black",
                                                                    marginTop: "0.25rem",
                                                                }}
                                                                onChange={(e) =>
                                                                    this.onChangeTermCond(index, e)
                                                                }
                                                                checked={item.checked}
                                                            >
                                                                {item.title}
                                                            </Checkbox>
                                                        </Col>
                                                        <Col sm="4">
                                                            <Form.Control
                                                                name={item.title}
                                                                value={item.description}
                                                                onChange={(e) =>
                                                                    this.handleChangeTermCond(index, e)
                                                                }
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                ))}
                                            </Form>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                            <br />
                            <Accordion defaultActiveKey="1">
                                <Card>
                                    <Accordion.Toggle
                                        style={{ backgroundColor: "#E0E4FF" }}
                                        as={Card.Header}
                                        eventKey="0"
                                    >
                                        <Card.Header
                                            style={{
                                                backgroundColor: "#E0E4FF",
                                                color: "#5d78ff",
                                                margin: "15px",
                                                fontWeight: "bold",
                                            }}
                                            as="h5"
                                        >
                                            Notes
                           </Card.Header>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse
                                        id="servicesAccordion"
                                        eventKey="0"
                                    >
                                        <Card.Body>
                                            <Form.Group
                                                style={{
                                                    marginTop: "1rem",
                                                    marginBottom: "3rem",
                                                }}
                                                as={Row}
                                            >
                                                <Form.Label
                                                    style={{ color: "black" }}
                                                    column
                                                    sm="3"
                                                >
                                                    New Note Name:
                               </Form.Label>
                                                <Col sm="4">
                                                    <Form.Control
                                                        name="noteName"
                                                        value={this.state.noteName}
                                                        onChange={this.handleChange}
                                                    />
                                                </Col>
                                                <Col sm="3">
                                                    <Button
                                                        style={{ width: "100%", marginLeft: "5px" }}
                                                        onClick={this.addNewNote}
                                                        type="button"
                                                    >
                                                        Add
                                 </Button>
                                                </Col>
                                                <Col sm="2"></Col>
                                            </Form.Group>

                                            {this.state.noteRows.map((item, index) => (
                                                <Form.Group
                                                    key={index}
                                                    style={{ marginBottom: "1rem" }}
                                                    as={Row}
                                                >
                                                    <Col sm="3">
                                                        <Checkbox
                                                            style={{
                                                                color: "black",
                                                                marginTop: "0.25rem",
                                                            }}
                                                            onChange={(e) =>
                                                                this.onChangeNote(index, e)
                                                            }
                                                            checked={item.checked}
                                                        >
                                                            {item.title}
                                                        </Checkbox>
                                                    </Col>
                                                    <Col sm="4">
                                                        <Form.Control
                                                            name={item.title}
                                                            value={item.description}
                                                            onChange={(e) =>
                                                                this.handleChangeNote(index, e)
                                                            }
                                                        />
                                                    </Col>
                                                </Form.Group>
                                            ))}
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                            <br />
                            <Accordion defaultActiveKey="1">
                                <Card>
                                    <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                                        <Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '15px', fontWeight: 'bold' }} as="h5">Required Documents</Card.Header>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse id="servicesAccordion" eventKey="0">
                                        <Card.Body>

                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="1">
                                                    <Checkbox style={{ color: 'black', marginTop: '0.75rem' }}
                                                        checked={this.state.invoiceCheck}
                                                        onChange={(e) => { this.setState({ invoiceCheck: e.target.checked }); }}>
                                                    </Checkbox>
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Invoice
                                                </Form.Label>
                                            </Form.Group>

                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="1">
                                                    <Checkbox style={{ color: 'black', marginTop: '0.75rem' }}
                                                        checked={this.state.deliveryNoteCheck}
                                                        onChange={(e) => { this.setState({ deliveryNoteCheck: e.target.checked }); }}>
                                                    </Checkbox>
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Delivery Note
                                                </Form.Label>
                                            </Form.Group>

                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="1">
                                                    <Checkbox style={{ color: 'black', marginTop: '0.75rem' }}
                                                        checked={this.state.packingListCheck}
                                                        onChange={(e) => { this.setState({ packingListCheck: e.target.checked }); }}>
                                                    </Checkbox>
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Packing List
                                                </Form.Label>
                                            </Form.Group>

                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="1">
                                                    <Checkbox style={{ color: 'black', marginTop: '0.75rem' }}
                                                        checked={this.state.testReportCheck}
                                                        onChange={(e) => { this.setState({ testReportCheck: e.target.checked }); }}>
                                                    </Checkbox>
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Test Report
                                                </Form.Label>
                                            </Form.Group>

                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                                <Col sm="1">
                                                    <Checkbox style={{ color: 'black', marginTop: '0.75rem' }}
                                                        checked={this.state.manufacturerManualCheck}
                                                        onChange={(e) => { this.setState({ manufacturerManualCheck: e.target.checked }); }}>
                                                    </Checkbox>
                                                </Col>
                                                <Form.Label style={{ color: 'black' }} column sm="2">
                                                    Manufacturer Manual
                                                </Form.Label>
                                                <Col sm="1">
                                                    <AntButton type="primary" onClick={this.addNewRequiredDocument} icon={<PlusOutlined />}></AntButton>
                                                </Col>
                                            </Form.Group>

                                            {this.state.requiredDocumentRows.map((item, index) => (
                                                <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                                    <Col sm="1">
                                                        <Checkbox style={{ color: 'black', marginTop: '0.75rem' }}
                                                            onChange={(e) => this.onChangeRequiredDocument(index, e)}
                                                            checked={item.checked}>{item.title}</Checkbox>
                                                    </Col>
                                                    <Col sm="2">
                                                        <Form.Control name={item.description} value={item.description}
                                                            onChange={(e) => this.handleChangeRequiredDocument(index, e)} />
                                                    </Col>
                                                    <Col sm="1">
                                                        <AntButton type="primary" danger onClick={this.removeRequiredDocument} icon={<CloseOutlined />}></AntButton>
                                                    </Col>
                                                </Form.Group>
                                            ))}
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>

                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideModalEdit}>Close</Button>
                        <Button variant="warning" onClick={this.editOK}>Update</Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalShowResponse}
                    onHide={this.showOrHideModalReponse}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Please Choose a Response
                   </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group style={{ width: "100%" }}>
                            <Col sm="12">
                                <Button
                                    variant="primary"
                                    onClick={this.onApprovedAsIs}
                                    style={{ height: "60px", width: "100%", marginBottom: "10px" }}
                                >
                                    Approved as is
                       </Button>
                            </Col>
                        </Form.Group>
                        <Form.Group style={{ width: "100%" }}>
                            <Col sm="12">
                                <Button
                                    variant="primary"
                                    onClick={this.onApprovedAsChanges}
                                    style={{ height: "60px", width: "100%", marginBottom: "10px" }}
                                >
                                    Approved as changes
                       </Button>
                            </Col>
                        </Form.Group>
                        <Form.Group style={{ width: "100%" }}>
                            <Col sm="12">
                                <Button
                                    variant="primary"
                                    onClick={this.onRevise}
                                    style={{ height: "60px", width: "100%", marginBottom: "10px" }}
                                >
                                    Revise
                       </Button>
                            </Col>
                        </Form.Group>
                        <Form.Group style={{ width: "100%" }}>
                            <Col sm="12">
                                <Button
                                    variant="danger"
                                    onClick={this.onRejected}
                                    style={{ height: "60px", width: "100%" }}
                                >
                                    Rejected
                       </Button>
                            </Col>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.showOrHideModalReponse}
                        >
                            Close
                   </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalShowApprovedAsIs}
                    onHide={this.showOrHideModalApprovedAsIs}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Purchase Order Approval
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={4} style={{ textAlign: "right" }}>
                                <Form.Label
                                    style={{
                                        color: "black",
                                        marginTop: "0.5rem",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Date:
                       </Form.Label>
                            </Col>
                            <Col xs={8}>
                                <DatePicker
                                    disabledDate={this.disabledDate}
                                    value={moment(
                                        this.state.approvedAsIsDate,
                                        "DD/MM/YYYY"
                                    )}
                                    format="DD/MM/YYYY"
                                    onChange={this.onChangeDate}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "1rem" }}>
                            <Col xs={4}></Col>
                            <Col xs={8}>
                                <Upload
                                    name="avatar"
                                    className="avatar-uploader"
                                    action={`${API_BASE}/PurchaseOrder/upload`}
                                    beforeUpload={this.beforeUpload}
                                    onChange={this.handlePhotoChange}
                                >
                                    <AntButton>
                                        <UploadOutlined /> Upload Approved P.O.
                         </AntButton>
                                </Upload>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "1rem" }}>
                            <Col xs={4} style={{ textAlign: "right" }}>
                                <Form.Label
                                    style={{ color: "black", fontWeight: "bold" }}
                                >
                                    Needs for Contract:
                       </Form.Label>
                            </Col>
                            <Col xs={8}>
                                <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    checked={this.state.approvedAsIsNeedContract}
                                    defaultChecked
                                    onChange={(value) => {
                                        this.setState({
                                            approvedAsIsNeedContract: value,
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.showOrHideModalApprovedAsIs}
                        >
                            Close
                   </Button>
                        <Button variant="success" onClick={this.saveApprovedAsIs}>
                            Save
                   </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalShowApprovedAsChanges}
                    onHide={this.showOrHideModalApprovedAsChanges}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            P.O. Approval
                   </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={4} style={{ textAlign: "right" }}>
                                <Form.Label
                                    style={{
                                        color: "black",
                                        marginTop: "0.5rem",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Date:
                       </Form.Label>
                            </Col>
                            <Col xs={8}>
                                <DatePicker
                                    disabledDate={this.disabledDate}
                                    value={moment(
                                        this.state.approvedAsChangesDate,
                                        "DD/MM/YYYY"
                                    )}
                                    format="DD/MM/YYYY"
                                    onChange={this.onChangeDateAsChanges}
                                />
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "1rem" }}>
                            <Col xs={4}></Col>
                            <Col xs={8}>
                                <Upload
                                    name="avatar"
                                    className="avatar-uploader"
                                    action={`${API_BASE}/PurchaseOrder/upload`}
                                    beforeUpload={this.beforeUpload}
                                    onChange={this.handlePhotoChange2}
                                >
                                    <AntButton>
                                        <UploadOutlined /> Upload Approved Quote
                         </AntButton>
                                </Upload>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "1rem" }}>
                            <Col xs={4} style={{ textAlign: "right" }}>
                                <Form.Label
                                    style={{ color: "black", fontWeight: "bold" }}
                                >
                                    Needs for Contract:
                       </Form.Label>
                            </Col>
                            <Col xs={8}>
                                <Switch
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    checked={this.state.approvedAsChangesNeedContract}
                                    defaultChecked
                                    onChange={(value) => {
                                        this.setState({
                                            approvedAsChangesNeedContract: value,
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.showOrHideModalApprovedAsChanges}
                        >
                            Close
                   </Button>
                        <Button
                            variant="success"
                            onClick={this.saveApprovedAsChanges}
                        >
                            Save
                   </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalShowRejected}
                    onHide={this.showOrHideModalRejected}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Quote Rejection
                   </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={4} style={{ textAlign: "right" }}>
                                <Form.Label
                                    style={{
                                        color: "black",
                                        marginTop: "0.5rem",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Rejection Date:
                       </Form.Label>
                            </Col>
                            <Col xs={8}>
                                <DatePicker
                                    disabledDate={this.disabledDate}
                                    value={moment(this.state.rejectionDate, "DD/MM/YYYY")}
                                    format="DD/MM/YYYY"
                                    onChange={this.onChangeDateRejection}
                                />
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.showOrHideModalRejected}
                        >
                            Close
                   </Button>
                        <Button variant="success" onClick={this.saveRejection}>
                            Save
                   </Button>
                    </Modal.Footer>
                </Modal>

                <Table dataSource={this.state.purchaseOrders}>
                    <Column
                        width="50px"
                        title="Action"
                        key="action"
                        render={(text, record) => (
                            <div style={{ textAlign: "center" }}>
                                <React.Fragment key={record.id}>
                                    {" "}
                                    <CustomMaterialMenuSQ
                                        row={record}
                                        onDeleteRow={this.deleteModal.bind(this)}
                                        onEditRow={this.edit.bind(this)}
                                        onResponseRow={this.response.bind(this)}
                                    />
                                </React.Fragment>
                            </div>
                        )}
                    />
                    <Column
                        title="Purchase Order No"
                        key="purchaseOrderNo"
                        render={(text, record) => {
                            if (record.quoteNo !== null && record.quoteNo !== undefined) {
                                if (record.status !== "Approved with") {
                                    let flagRevNo;
                                    if (record.revNo.toString().length === 1) {
                                        flagRevNo = "0" + record.revNo.toString();
                                    } else {
                                        flagRevNo = record.revNo.toString();
                                    }
                                    return record.quoteNo.toString() + "/" + flagRevNo;
                                } else {
                                    let flagRevNo;
                                    if (record.revNo.toString().length === 1) {
                                        flagRevNo = "0" + record.revNo.toString();
                                    } else {
                                        flagRevNo = record.revNo.toString();
                                    }
                                    return (
                                        record.quoteNo.toString() + "/" + flagRevNo + "/M"
                                    );
                                }
                            } else {
                                return "";
                            }
                        }}
                    />
                    <Column title="Date" key="date" dataIndex="quoteDate" />
                    {/*<Column title="Order Validity" key="qv" render={(text, record) => (<div>{record.quoteValidityValue}{" "}{record.quoteValidityDateType}</div>)} />*/}
                    <Column title="Materials" key="materials" render={(text, record) => {
                        let productNames = "";
                        let flagRfqList = this.state.rfqList;
                        let rfqIndex = this.state.rfqList.findIndex(p => p.id === record.rfqId);
                        if (rfqIndex !== -1) {
                            let flagList = flagRfqList[rfqIndex];
                            if (record.purchaseOrderItemList !== null && record.purchaseOrderItemList !== undefined && record.purchaseOrderItemList.length !== 0) {
                                productNames = flagList.projectTakeOffList.description;
                                return productNames;
                            } else if (record.purchaseOrderItemListSub !== null && record.purchaseOrderItemListSub !== undefined && record.purchaseOrderItemListSub.length !== 0) {
                                for (let index = 0; index < flagList.projectTakeOffList.projectTakeOffListSubs.length; index++) {
                                    productNames = productNames + (flagList.projectTakeOffList.projectTakeOffListSubs[index].description + ", ");
                                }
                                productNames = productNames.slice(0, -2);
                                return productNames;
                            } else
                                return "";
                        } else
                            return "";
                    }}
                    />
                    <Column
                        title="Quantity"
                        key="quantity"
                        render={(text, record) => {
                            let quantities = "";
                            let flagRfqList = this.state.rfqList;
                            let rfqIndex = this.state.rfqList.findIndex(p => p.id === record.rfqId);
                            if (rfqIndex !== -1) {
                                let flagList = flagRfqList[rfqIndex];
                                if (record.purchaseOrderItemList !== null && record.purchaseOrderItemList !== undefined && record.purchaseOrderItemList.length !== 0) {
                                    quantities = flagList.projectTakeOffLists[0].quantity
                                    return quantities;
                                } else if (record.purchaseOrderItemListSub !== null && record.purchaseOrderItemListSub !== undefined && record.purchaseOrderItemListSub.length !== 0) {
                                    for (let index = 0; index < flagList.projectTakeOffListSubs.length; index++) {
                                        quantities = quantities + (flagList.projectTakeOffListSubs[index].quantity + ", ");
                                    }
                                    quantities = quantities.slice(0, -2);
                                    return quantities;
                                } else
                                    return "";
                            }
                            else
                                return "";
                        }}
                    />
                    <Column
                        title="Unit"
                        key="unit"
                        render={(text, record) => {
                            // let units = "";
                            // if (record.purchaseOrderMaterialList !== null && record.purchaseOrderMaterialList !== undefined) {
                            //     let flagList = record.purchaseOrderMaterialList;
                            //     for (let index = 0; index < flagList.length; index++) {
                            //         units = units + (flagList[index].unit + ", ");
                            //     }
                            //     units = units.slice(0, -2);
                            //     return units;
                            // } else {
                            //     return "";
                            // }
                            return "";
                        }}
                    />
                    <Column
                        title="Price"
                        key="price"
                        render={(text, record) => {
                            let prices = "";
                            let flagList = null;
                            if (record.purchaseOrderItemList !== null && record.purchaseOrderItemList !== undefined && record.purchaseOrderItemList.length !== 0) {
                                flagList = record.purchaseOrderItemList;
                                for (let index = 0; index < flagList.length; index++) {
                                    prices = prices + (flagList[index].price + ", ");
                                }
                                prices = prices.slice(0, -2);
                                return prices;
                            } else if (record.purchaseOrderItemListSub !== null && record.purchaseOrderItemListSub !== undefined && record.purchaseOrderItemListSub.length !== 0) {
                                flagList = record.purchaseOrderItemListSub;
                                for (let index = 0; index < flagList.length; index++) {
                                    prices = prices + (flagList[index].price + ", ");
                                }
                                prices = prices.slice(0, -2);
                                return prices;
                            } else
                                return "";
                        }}
                    />
                    <Column
                        title="Discount (%)"
                        key="dd"
                        render={(text, record) => {
                            let discounts = "";
                            let flagList = null;
                            if (record.purchaseOrderItemList !== null && record.purchaseOrderItemList !== undefined && record.purchaseOrderItemList.length !== 0) {
                                flagList = record.purchaseOrderItemList;
                                for (let index = 0; index < flagList.length; index++) {
                                    discounts = discounts + (flagList[index].discount + ", ");
                                }
                                discounts = discounts.slice(0, -2);
                                return discounts;
                            } else if (record.purchaseOrderItemListSub !== null && record.purchaseOrderItemListSub !== undefined && record.purchaseOrderItemListSub.length !== 0) {
                                flagList = record.purchaseOrderItemListSub;
                                for (let index = 0; index < flagList.length; index++) {
                                    discounts = discounts + (flagList[index].discount + ", ");
                                }
                                discounts = discounts.slice(0, -2);
                                return discounts;
                            } else
                                return "";
                        }}
                    />
                    <Column
                        title="Delivery Date"
                        key="ds"
                        render={(text, record) => {
                            let deliverySchedules = "";
                            let flagRfqList = this.state.rfqList;
                            let rfqIndex = this.state.rfqList.findIndex(p => p.id === record.rfqId);
                            if (rfqIndex !== -1) {
                                let flagList = flagRfqList[rfqIndex];
                                if (record.purchaseOrderItemList !== null && record.purchaseOrderItemList !== undefined && record.purchaseOrderItemList.length !== 0) {
                                    deliverySchedules = flagList.projectTakeOffLists[0].deliveryDate
                                    return deliverySchedules;
                                } else if (record.purchaseOrderItemListSub !== null && record.purchaseOrderItemListSub !== undefined && record.purchaseOrderItemListSub.length !== 0) {
                                    for (let index = 0; index < flagList.projectTakeOffListSubs.length; index++) {
                                        deliverySchedules = deliverySchedules + (flagList.projectTakeOffListSubs[index].deliveryDate + ", ");
                                    }
                                    deliverySchedules = deliverySchedules.slice(0, -2);
                                    return deliverySchedules;
                                } else
                                    return "";
                            }
                            else
                                return "";
                        }}
                    />
                    <Column
                        width="100px"
                        title="Quote"
                        key="index"
                        render={(text, record) => (
                            //bunu alttaki butonun içine al.
                            //onClick={() => this.setState({ imageUrl: `${API_BASE}/Shipment/download2/` + rowData.id, modalPhotoShow: true })}
                            <div style={{ textAlign: "center" }}>
                                <Button
                                    key={record.id}
                                    onClick={() => this.checkReport(record)}
                                    type="button"
                                >
                                    File
                                </Button>
                            </div>
                        )}
                    />
                    <Column
                        width="100px"
                        title="Response Date"
                        key="responseDate"
                        render={(text, record) => {
                            if (
                                record.responseDate !== null &&
                                record.responseDate !== undefined
                            ) {
                                return record.responseDate;
                            } else {
                                return "";
                            }
                        }}
                    />
                    <Column width="100px" title="Status" key="status"
                        render={(text, record) => {
                            if (
                                record.status !== null &&
                                record.status !== undefined
                            ) {
                                return record.status;
                            } else {
                                return "";
                            }
                        }}
                    />
                </Table>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderSub)