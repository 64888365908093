
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { Col, Form, Row } from 'react-bootstrap';
import { Modal } from 'react-bootstrap'; 
import { Button as PrimeButton } from "primereact/button"; 
import { handleRequest } from '../../redux/config/env'; 
import { FormattedMessage } from "react-intl";
import { Collapse, Button as AntdButton, InputNumber, DatePicker, Input } from 'antd';
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import moment from 'moment';
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import 'react-pdfjs-multi/dist/react-pdfjs-multi.css';
import * as Mnd from "../../partials/content/CustomComponents";
import MNDTableSettings from '../MND/MNDTableSettings';
import CustomMaterialMenuDeleteEdit from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import CustomMaterialMenuEdit from '../settings/ForComponents/CustomMaterialMenuEdit';
import CustomMaterialMenuDelete from '../settings/ForComponents/CustomMaterialMenuDelete';

const { Panel } = Collapse;
const expandable = { expandedRowRender: record => <b>{record.id}</b> };
const title = () => 'Here is title';
var footer = () => 'Here is footer';

class Nmr extends Component {
  constructor(props) {
    super(props)
    this.state = {
      documentLogid:-1,
      id:-1,
      createNewButtonVariantNMR601: "info",
      createNewButtonVariantNMR602: "info",
      createNewButtonVariantNMR603: "info",
      createNewButtonNMR601: "Create New", 
      createNewButtonNMR602: "Create New", 
      createNewButtonNMR603: "Create New", 
      hiddenSaveNMR601: false,
      hiddenSaveNMR602: false,
      hiddenSaveNMR603: false,
      hiddenUpdateNMR601: true,
      hiddenUpdateNMR602: true,
      hiddenUpdateNMR603: true,
      hiddenDataGirisNMR601:true,
      hiddenDataGirisNMR602:true,
      hiddenDataGirisNMR603:true,

      dueDatesNMR601: null,
      dueDatesNMR602: null,
      dueDatesNMR603: null,
      titleDisabledNMR601:false,
    };
    
    this.actionTemplateNMR601 = this.actionTemplateNMR601.bind(this);
    this.actionTemplateNMR602 = this.actionTemplateNMR602.bind(this);
    this.actionTemplateNMR603 = this.actionTemplateNMR603.bind(this);
  }

componentDidMount = async () => { 
  var deger=-1;
  if(this.props.location!=null && this.props.location!=undefined && this.props.location!=""){
    deger=this.props.location.pathname.split('/')[2]; 
  }else if(this.props.id!=null && this.props.id!=0 && this.props.id!=""){
    deger=this.props.id; 
  }else{
    deger=-1;
  } 

  if(deger!=null && deger!=undefined && deger!="" && deger!=0 ){
    await this.setState({
      documentLogid:deger
    });
  }else{
    await this.setState({
      documentLogid:-1
    });
  }

  const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "DocumentLog" });
  if (responseDP.data.length > 0) {
    this.setState({
      dynamicAttributes: responseDP.data
    });
  } else {
    this.setState({
      dynamicAttributes: []
    });
  }

  const obj = {
    pageName: "DocumentLog",
  };
  const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
  if (responseDynamicInputs.data.length !== 0) {
    this.setState({
      dynamicInputs: responseDynamicInputs.data,
    });
  } else {
    this.setState({
      dynamicInputs: [],
    });
  }
  
  const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "DocumentLog");
  try {
    this.setState({
      authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
    });
  } catch (error) {
  }
  
  this.deleteNonMaterialRequirementTemp();  
  this.restartTable();  
  this.getTableProperties();  
}

restartTable = async () => { 
  const newObj = {
    documentLogid: this.state.documentLogid
  }  
  const response = await handleRequest("POST", "/NonMaterialRequirement/getNonMaterialRequirementResult", newObj); 
  if (response.data.length !== 0) {   
    var tableNMR601=response.data.filter(m => m.type=="NMR601");
    var tableNMR602=response.data.filter(m => m.type=="NMR602");
    var tableNMR603=response.data.filter(m => m.type=="NMR603");

    if(tableNMR601!=null && tableNMR601!=undefined && tableNMR601.length>0){
      tableNMR601.forEach((element, index) => {
        element.index=index+1;
      });
    }

    if(tableNMR602!=null && tableNMR602!=undefined && tableNMR602.length>0){
      tableNMR602.forEach((element, index) => {
        element.index=index+1;
      });
    }

    if(tableNMR603!=null && tableNMR603!=undefined && tableNMR603.length>0){
      tableNMR603.forEach((element, index) => {
        element.index=index+1;
      });
    }
 
    await this.setState({ 
      NMR601Table:tableNMR601,
      NMR602Table:tableNMR602,
      NMR603Table:tableNMR603,
      hiddenSaveNMR601:true,
      hiddenSaveNMR602:true,
      hiddenSaveNMR603:true,
      hiddenUpdateNMR601:false,
      hiddenUpdateNMR602:false,
      hiddenUpdateNMR603:false, 
    }); 
  }  
  else { 
    await this.setState({ 
      id: -1,
      NMR601Table:[],
      NMR602Table:[],
      NMR603Table:[],
      hiddenSaveNMR601:false,
      hiddenSaveNMR602:false,
      hiddenSaveNMR603:false,
      hiddenUpdateNMR601:true,
      hiddenUpdateNMR602:true,
      hiddenUpdateNMR603:true, 
    });
  }
}

getTableProperties = async () => {
  this.NonMaterialRequirement = JSON.parse(localStorage.getItem('NonMaterialRequirement'));
  if (this.NonMaterialRequirement != null && this.NonMaterialRequirement != undefined && this.NonMaterialRequirement != "") {

      await this.setState({
          bordered: this.NonMaterialRequirement.bordered,
          title: this.NonMaterialRequirement.titleDrm == true ? title : undefined,
          titleDrm: this.NonMaterialRequirement.titleDrm,
          showHeader: this.NonMaterialRequirement.showHeader,
          footer: this.NonMaterialRequirement.footerDrm == true ? footer : undefined,
          footerDrm: this.NonMaterialRequirement.footerDrm,
          expandable: this.NonMaterialRequirement.expandableDrm == true ? expandable : {},
          expandableDrm: this.NonMaterialRequirement.expandableDrm,
          rowSelection: this.NonMaterialRequirement.rowSelection,
          ellipsis: this.NonMaterialRequirement.ellipsis,
          tableLayout: this.NonMaterialRequirement.tableLayout,
          size: this.NonMaterialRequirement.size,
          top: this.NonMaterialRequirement.top,
          bottom: this.NonMaterialRequirement.bottom,
          editBgColor: this.NonMaterialRequirement.editBgColor,
          deleteBgColor: this.NonMaterialRequirement.deleteBgColor,
          selectedNodeKey: null,
          yScroll: this.NonMaterialRequirement.yScroll,
          xScroll: this.NonMaterialRequirement.xScroll,
          paginationDisabled: {
              defaultPageSize: 5, total: this.state.shippingReleaseTable.length,
              showQuickJumper: true, showSizeChanger: true,
              pageSizeOptions: ['5', '10', '25', '50'], total: this.state.shippingReleaseTable.length, showQuickJumper: true,
              position: [this.NonMaterialRequirement.top, this.NonMaterialRequirement.bottom]
          },
          kontrol: true,
      });
  } else {
      await this.setState({
          bordered: false,
          title: undefined,
          titleDrm: false,
          showHeader: true,
          footer: undefined,
          footerDrm: false,
          expandable: false,
          expandableDrm: false,
          rowSelection: undefined,
          ellipsis: false,
          tableLayout: undefined,
          size: 'default',
          top: 'none',
          bottom: 'bottomRight',
          editBgColor: '#fff',
          deleteBgColor: '#fff',
          selectedNodeKey: null,
          xScroll: undefined,
          paginationDisabled: false,
          kontrol: true,
      });
    }
}

deleteNonMaterialRequirementTemp = async () => {
  await handleRequest("GET", "/NonMaterialRequirement/deleteTemp");
};

showSuccess(msg) {
  if (!msg) {
      msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successful"/>;
  }
  this.setState({
      modalMessage:true,
      message:msg,
      messageTip:"Success"
  });
}; 
  
showError(msg) {
  if (!msg) {
      msg =  <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !"/>;
  }
  this.setState({
      modalMessage:true,
      message:msg,
      messageTip:"Error"
  });
};

showWarning(msg) {
  if (!msg) {
      msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs."/>;
  }
  this.setState({
      modalMessage:true,
      message:msg,
      messageTip:"Warning"
  });
};

actionTemplateNMR601(rowData) { 
  if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
    return <React.Fragment>
      <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.deleteModalNMR601.bind(this)}
        onEditRow={this.changeButtonUpdateNMR601.bind(this)} />
      </React.Fragment>;
  } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
    return <React.Fragment>
      <CustomMaterialMenuEdit row={rowData}
        onEditRow={this.changeButtonUpdateNMR601.bind(this)} />
      </React.Fragment>;
  }
  else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
    return <React.Fragment>
      <CustomMaterialMenuDelete row={rowData}
        onDeleteRow={this.deleteModalNMR601.bind(this)} />
      </React.Fragment>;
  }
};

actionTemplateNMR602(rowData) { 
  if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
    return <React.Fragment>
      <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.deleteModalNMR602.bind(this)}
        onEditRow={this.changeButtonUpdateNMR602.bind(this)} />
      </React.Fragment>;
  } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
    return <React.Fragment>
      <CustomMaterialMenuEdit row={rowData}
        onEditRow={this.changeButtonUpdateNMR602.bind(this)} />
      </React.Fragment>;
  }
  else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
    return <React.Fragment>
      <CustomMaterialMenuDelete row={rowData}
        onDeleteRow={this.deleteModalNMR602.bind(this)} />
      </React.Fragment>;
  }
};

actionTemplateNMR603(rowData) { 
  if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
    return <React.Fragment>
      <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.deleteModalNMR603.bind(this)}
        onEditRow={this.changeButtonUpdateNMR603.bind(this)} />
      </React.Fragment>;
  } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
    return <React.Fragment>
      <CustomMaterialMenuEdit row={rowData}
        onEditRow={this.changeButtonUpdateNMR603.bind(this)} />
      </React.Fragment>;
  }
  else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
    return <React.Fragment>
      <CustomMaterialMenuDelete row={rowData}
        onDeleteRow={this.deleteModalNMR603.bind(this)} />
      </React.Fragment>;
  }
};

changeButtonUpdateNMR601 = (rowData) => {
  this.setState({
    id: rowData.id,
    documentLogid: rowData.documentLogid,
    type: "NMR601",
    titleNMR601: rowData.title,
    itemNoNMR601: rowData.itemNo,
    descriptionNMR601: rowData.description,
    copiesRequiredNMR601: rowData.copiesRequired,
    softCopyNMR601: rowData.softCopy,
    hardCopyNMR601: rowData.hardCopy,
    priorityNMR601: rowData.priority,
    numberOfCalenderWeeksNMR601: rowData.numberOfCalenderWeeks,
    dueDatesNMR601: (rowData.dueDates!=null && rowData.dueDates!=undefined && rowData.dueDates!="") == true ? moment(rowData.dueDates, 'YYYY-MM-DD') : null,
    dueDatesAck: rowData.dueDates,

    hiddenSaveNMR601: true,
    hiddenUpdateNMR601: false,
    hiddenDataGirisNMR601: false,
    createNewButtonNMR601: "Cancel",
    createNewButtonVariantNMR601: "danger",
  });
};

changeButtonUpdateNMR602 = (rowData) => {
  this.setState({
    id: rowData.id,
    documentLogid: rowData.documentLogid,
    type: "NMR602",
    titleNMR602: rowData.title,
    itemNoNMR602: rowData.itemNo,
    descriptionNMR602: rowData.description,
    copiesRequiredNMR602: rowData.copiesRequired,
    softCopyNMR602: rowData.softCopy,
    hardCopyNMR602: rowData.hardCopy,
    priorityNMR602: rowData.priority,
    numberOfCalenderWeeksNMR602: rowData.numberOfCalenderWeeks,
    dueDatesNMR602: (rowData.dueDates!=null && rowData.dueDates!=undefined && rowData.dueDates!="") == true ? moment(rowData.dueDates, 'YYYY-MM-DD') : null,
    dueDatesAck: rowData.dueDates,

    hiddenSaveNMR602: true,
    hiddenUpdateNMR602: false,
    hiddenDataGirisNMR602: false,
    createNewButtonNMR602: "Cancel",
    createNewButtonVariantNMR602: "danger",
  });
};

changeButtonUpdateNMR603 = (rowData) => {
  this.setState({
    id: rowData.id,
    documentLogid: rowData.documentLogid,
    type: "NMR603",
    titleNMR603: rowData.title,
    itemNoNMR603: rowData.itemNo,
    descriptionNMR603: rowData.description,
    copiesRequiredNMR603: rowData.copiesRequired,
    softCopyNMR603: rowData.softCopy,
    hardCopyNMR603: rowData.hardCopy,
    priorityNMR603: rowData.priority,
    numberOfCalenderWeeksNMR603: rowData.numberOfCalenderWeeks,
    dueDatesNMR603: (rowData.dueDates!=null && rowData.dueDates!=undefined && rowData.dueDates!="") == true ? moment(rowData.dueDates, 'YYYY-MM-DD') : null,
    dueDatesAck: rowData.dueDates,

    hiddenSaveNMR603: true,
    hiddenUpdateNMR603: false,
    hiddenDataGirisNMR603: false,
    createNewButtonNMR603: "Cancel",
    createNewButtonVariantNMR603: "danger",
  });
};

deleteModalNMR601 = (row) => {
  this.setState({ 
    deleteModalNMR601: true,
    selectedNonMaterialRequirementNMR601: row
  });
}

deleteModalNMR602 = (row) => {
  this.setState({ 
    deleteModalNMR602: true,
    selectedNonMaterialRequirementNMR602: row
  });
}

deleteModalNMR603 = (row) => {
  this.setState({ 
    deleteModalNMR603: true,
    selectedNonMaterialRequirementNMR603: row
  });
}

showOrHideModalDeleteNMR601 = () => {
  this.setState({
    deleteModalNMR601: !this.state.deleteModalNMR601
  });
  setTimeout(() => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
}, 500);
}

showOrHideModalDeleteNMR602 = () => {
  this.setState({
    deleteModalNMR602: !this.state.deleteModalNMR602
  });
  setTimeout(() => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
}, 500);
}

showOrHideModalDeleteNMR603 = () => {
  this.setState({
    deleteModalNMR603: !this.state.deleteModalNMR603
  });
  setTimeout(() => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
}, 500);
}

deleteNonMaterialRequirementNMR601OK = async () => {
  const deleteNonMaterialRequirementNMR601 = {
      id: this.state.selectedNonMaterialRequirementNMR601.id
  }
  var response = await handleRequest("POST", "/NonMaterialRequirement/delete", deleteNonMaterialRequirementNMR601);
  if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS") {
        this.restartTable();
        this.showSuccess();
        this.showOrHideModalDeleteNMR601();
      }
      else {
        this.showError();
      }
  }
  setTimeout(() => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
}, 500);
}

deleteNonMaterialRequirementNMR602OK = async () => {
  const deleteNonMaterialRequirementNMR602 = {
      id: this.state.selectedNonMaterialRequirementNMR602.id
  }
  var response = await handleRequest("POST", "/NonMaterialRequirement/delete", deleteNonMaterialRequirementNMR602);
  if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS") {
        this.restartTable();
        this.showSuccess();
        this.showOrHideModalDeleteNMR602();
      }
      else {
        this.showError();
      }
  }
  setTimeout(() => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
}, 500);
}

deleteNonMaterialRequirementNMR603OK = async () => {
  const deleteNonMaterialRequirementNMR603 = {
      id: this.state.selectedNonMaterialRequirementNMR603.id
  }
  var response = await handleRequest("POST", "/NonMaterialRequirement/delete", deleteNonMaterialRequirementNMR603);
  if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS") {
        this.restartTable();
        this.showSuccess();
        this.showOrHideModalDeleteNMR603();
      }
      else {
        this.showError();
      }
  }
  setTimeout(() => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
}, 500);
}

showOrHideModalMessage = () => {
  this.setState({
    modalMessage: !this.state.modalMessage,
  }); 
  setTimeout(() => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
  }, 500);
}

addNMR601 = async () => {
  if(this.state.titleNMR601!=null && this.state.titleNMR601!=undefined && this.state.titleNMR601!="" && 
  this.state.itemNoNMR601!=null && this.state.itemNoNMR601!=undefined && this.state.itemNoNMR601!="" && 
  this.state.descriptionNMR601!=null && this.state.descriptionNMR601!=undefined && this.state.descriptionNMR601!=""  ){
    var NonMaterialRequirement = {
      documentLogid:this.state.documentLogid,
      type: "NMR601",
      title: this.state.titleNMR601,
      itemNo: this.state.itemNoNMR601,
      description: this.state.descriptionNMR601,
      copiesRequired: this.state.copiesRequiredNMR601,
      softCopy: this.state.softCopyNMR601,
      hardCopy: this.state.hardCopyNMR601,
      priority: this.state.priorityNMR601,
      numberOfCalenderWeeks: this.state.numberOfCalenderWeeksNMR601,
      dueDates: moment(this.state.dueDatesNMR601, 'YYYY-MM-DD'),
      dueDatesAck: this.state.dueDatesNMR601,
    } 
    console.log("NMR", NonMaterialRequirement);
    var response =  await handleRequest("POST", "/NonMaterialRequirement/add", NonMaterialRequirement);
    if (response.data !== null && response.data !== undefined) {
        if (response.data == "SUCCESS") {
          this.showSuccess();
          this.restartTable();
          this.resetInputsNMR601();
          this.createNewClientButtonNMR601();
        }
        else  {
          this.showError();
        }  
    }  
    else  {
      this.showError();
    } 
  }else{
    this.showWarning();
  }
}

updateDoneNMR601 = async () => {
  if(this.state.titleNMR601!=null && this.state.titleNMR601!=undefined && this.state.titleNMR601!="" && 
  this.state.itemNoNMR601!=null && this.state.itemNoNMR601!=undefined && this.state.itemNoNMR601!="" && 
  this.state.descriptionNMR601!=null && this.state.descriptionNMR601!=undefined && this.state.descriptionNMR601!="" &&
  this.state.id!=null && this.state.id!=undefined && this.state.id!="" && this.state.id>0 &&
  this.state.documentLogid!=null && this.state.documentLogid!=undefined && this.state.documentLogid!="" && this.state.documentLogid>0){
    var NonMaterialRequirement = {
      id:this.state.id,
      documentLogid:this.state.documentLogid,
      type: "NMR601",
      title: this.state.titleNMR601,
      itemNo: this.state.itemNoNMR601,
      description: this.state.descriptionNMR601,
      copiesRequired: this.state.copiesRequiredNMR601,
      softCopy: this.state.softCopyNMR601,
      hardCopy: this.state.hardCopyNMR601,
      priority: this.state.priorityNMR601,
      numberOfCalenderWeeks: this.state.numberOfCalenderWeeksNMR601,
      dueDates: moment(this.state.dueDatesNMR601, 'YYYY-MM-DD'),
      dueDatesAck: this.state.dueDatesNMR601,
    }  

  var response =   await handleRequest("POST", "/NonMaterialRequirement/update", NonMaterialRequirement);
  if (response.data !== null && response.data !== undefined) {
      if (response.data == "SUCCESS") {
        this.showSuccess();
        this.restartTable();
        this.resetInputsNMR601();
        this.createNewClientButtonNMR601();
      }
      else {
        this.showError();
      } 
    } 
    else {
      this.showError();  
    }   
  }
  else {
    this.showWarning();
  } 
};

addNMR602 = async () => {
  if(this.state.titleNMR602!=null && this.state.titleNMR602!=undefined && this.state.titleNMR602!="" && 
  this.state.itemNoNMR602!=null && this.state.itemNoNMR602!=undefined && this.state.itemNoNMR602!="" && 
  this.state.descriptionNMR602!=null && this.state.descriptionNMR602!=undefined && this.state.descriptionNMR602!=""  ){
    var NonMaterialRequirement = {
      documentLogid:this.state.documentLogid,
      type: "NMR602",
      title: this.state.titleNMR602,
      itemNo: this.state.itemNoNMR602,
      description: this.state.descriptionNMR602,
      copiesRequired: this.state.copiesRequiredNMR602,
      softCopy: this.state.softCopyNMR602,
      hardCopy: this.state.hardCopyNMR602,
      priority: this.state.priorityNMR602,
      numberOfCalenderWeeks: this.state.numberOfCalenderWeeksNMR602,
      dueDates: moment(this.state.dueDatesNMR602, 'YYYY-MM-DD'),
      dueDatesAck: this.state.dueDatesNMR602,
    } 
    console.log("NMR", NonMaterialRequirement);
    var response =  await handleRequest("POST", "/NonMaterialRequirement/add", NonMaterialRequirement);
    if (response.data !== null && response.data !== undefined) {
        if (response.data == "SUCCESS") {
          this.showSuccess();
          this.restartTable();
          this.resetInputsNMR602();
          this.createNewClientButtonNMR602();
        }
        else  {
          this.showError();
        }  
    }  
    else  {
      this.showError();
    } 
  }else{
    this.showWarning();
  }
}

updateDoneNMR602 = async () => {
  if(this.state.titleNMR602!=null && this.state.titleNMR602!=undefined && this.state.titleNMR602!="" && 
  this.state.itemNoNMR602!=null && this.state.itemNoNMR602!=undefined && this.state.itemNoNMR602!="" && 
  this.state.descriptionNMR602!=null && this.state.descriptionNMR602!=undefined && this.state.descriptionNMR602!="" &&
  this.state.id!=null && this.state.id!=undefined && this.state.id!="" && this.state.id>0 &&
  this.state.documentLogid!=null && this.state.documentLogid!=undefined && this.state.documentLogid!="" && this.state.documentLogid>0){
    var NonMaterialRequirement = {
      id:this.state.id,
      documentLogid:this.state.documentLogid,
      type: "NMR602",
      title: this.state.titleNMR602,
      itemNo: this.state.itemNoNMR602,
      description: this.state.descriptionNMR602,
      copiesRequired: this.state.copiesRequiredNMR602,
      softCopy: this.state.softCopyNMR602,
      hardCopy: this.state.hardCopyNMR602,
      priority: this.state.priorityNMR602,
      numberOfCalenderWeeks: this.state.numberOfCalenderWeeksNMR602,
      dueDates: moment(this.state.dueDatesNMR602, 'YYYY-MM-DD'),
      dueDatesAck: this.state.dueDatesNMR602,
    }  

  var response =   await handleRequest("POST", "/NonMaterialRequirement/update", NonMaterialRequirement);
  if (response.data !== null && response.data !== undefined) {
      if (response.data == "SUCCESS") {
        this.showSuccess();
        this.restartTable();
        this.resetInputsNMR602();
        this.createNewClientButtonNMR602();
      }
      else {
        this.showError();
      } 
    } 
    else {
      this.showError();  
    }   
  }
  else {
    this.showWarning();
  } 
};

addNMR603 = async () => {
  if(this.state.titleNMR603!=null && this.state.titleNMR603!=undefined && this.state.titleNMR603!="" && 
  this.state.itemNoNMR603!=null && this.state.itemNoNMR603!=undefined && this.state.itemNoNMR603!="" && 
  this.state.descriptionNMR603!=null && this.state.descriptionNMR603!=undefined && this.state.descriptionNMR603!=""  ){
    var NonMaterialRequirement = {
      documentLogid:this.state.documentLogid,
      type: "NMR603",
      title: this.state.titleNMR603,
      itemNo: this.state.itemNoNMR603,
      description: this.state.descriptionNMR603,
      copiesRequired: this.state.copiesRequiredNMR603,
      softCopy: this.state.softCopyNMR603,
      hardCopy: this.state.hardCopyNMR603,
      priority: this.state.priorityNMR603,
      numberOfCalenderWeeks: this.state.numberOfCalenderWeeksNMR603,
      dueDates: moment(this.state.dueDatesNMR603, 'YYYY-MM-DD'),
      dueDatesAck: this.state.dueDatesNMR603,
    } 
    console.log("NMR", NonMaterialRequirement);
    var response =  await handleRequest("POST", "/NonMaterialRequirement/add", NonMaterialRequirement);
    if (response.data !== null && response.data !== undefined) {
        if (response.data == "SUCCESS") {
          this.showSuccess();
          this.restartTable();
          this.resetInputsNMR603();
          this.createNewClientButtonNMR603();
        }
        else  {
          this.showError();
        }  
    }  
    else  {
      this.showError();
    } 
  }else{
    this.showWarning();
  }
}

updateDoneNMR603 = async () => {
  if(this.state.titleNMR603!=null && this.state.titleNMR603!=undefined && this.state.titleNMR603!="" && 
  this.state.itemNoNMR603!=null && this.state.itemNoNMR603!=undefined && this.state.itemNoNMR603!="" && 
  this.state.descriptionNMR603!=null && this.state.descriptionNMR603!=undefined && this.state.descriptionNMR603!="" &&
  this.state.id!=null && this.state.id!=undefined && this.state.id!="" && this.state.id>0 &&
  this.state.documentLogid!=null && this.state.documentLogid!=undefined && this.state.documentLogid!="" && this.state.documentLogid>0){
    var NonMaterialRequirement = {
      id:this.state.id,
      documentLogid:this.state.documentLogid,
      type: "NMR603",
      title: this.state.titleNMR603,
      itemNo: this.state.itemNoNMR603,
      description: this.state.descriptionNMR603,
      copiesRequired: this.state.copiesRequiredNMR603,
      softCopy: this.state.softCopyNMR603,
      hardCopy: this.state.hardCopyNMR603,
      priority: this.state.priorityNMR603,
      numberOfCalenderWeeks: this.state.numberOfCalenderWeeksNMR603,
      dueDates: moment(this.state.dueDatesNMR603, 'YYYY-MM-DD'),
      dueDatesAck: this.state.dueDatesNMR603,
    }   

  var response =   await handleRequest("POST", "/NonMaterialRequirement/update", NonMaterialRequirement);
  if (response.data !== null && response.data !== undefined) {
      if (response.data == "SUCCESS") {
        this.showSuccess();
        this.restartTable();
        this.resetInputsNMR603();
        this.createNewClientButtonNMR603();
      }
      else {
        this.showError();
      } 
    } 
    else {
      this.showError();  
    }   
  }
  else {
    this.showWarning();
  } 
};

changeButtonCancelNMR601 = () => {
  this.resetInputsNMR601();

  this.setState({
    hiddenSaveNMR601: false,
    hiddenUpdateNMR601: true,
    hiddenDataGirisNMR601:true,
    createNewButtonNMR601: "Create New",
    createNewButtonVariantNMR601: "info", 
  });
};

changeButtonCancelNMR602 = () => {
  this.resetInputsNMR602();

  this.setState({
    hiddenSaveNMR602: false,
    hiddenUpdateNMR602: true,
    hiddenDataGirisNMR602:true,
    createNewButtonNMR602: "Create New",
    createNewButtonVariantNMR602: "info", 
  });
};

changeButtonCancelNMR603 = () => {
  this.resetInputsNMR603();

  this.setState({
    hiddenSaveNMR603: false,
    hiddenUpdateNMR603: true,
    hiddenDataGirisNMR603:true,
    createNewButtonNMR603: "Create New",
    createNewButtonVariantNMR603: "info", 
  });
};

showReport = async () => {
  try {
    if(this.state.NonMaterialRequirement.jointDetail!=null && this.state.NonMaterialRequirement.jointDetail!=undefined && this.state.NonMaterialRequirement.jointDetail!=""){
      const fileRealUrl = this.state.NonMaterialRequirement.jointDetail;

      if(fileRealUrl.endsWith('.pdf')){
      this.setState({
        pdfFile: fileRealUrl,
        modalPdfViewer: true,
      });
      }else if(fileRealUrl.endsWith('.png') || fileRealUrl.endsWith('.jpg') || fileRealUrl.endsWith('.tiff') || fileRealUrl.endsWith('.jpeg')
          || fileRealUrl.endsWith('.PNG') || fileRealUrl.endsWith('.JPG') || fileRealUrl.endsWith('.TIFF') || fileRealUrl.endsWith('.JPEG')){

      this.setState({
        imageUrl: fileRealUrl,
        modalPhotoShow: true,
      })
      }else{
        window.location.href = fileRealUrl;
      }
    }else{
      this.showError(<FormattedMessage id="FileErrorMessage1" defaultMessage="File can not found" />);
    }
  } catch (error) {
    this.showError(<FormattedMessage id="FileErrorMessage1" defaultMessage="File can not found" />);
  }
};

showOrHideModalPdfViewer = () => {
  this.setState({
  modalPdfViewer: !this.state.modalPdfViewer,
  });
  setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
  }, 500);
};

showOrHideModalTable = async (tableTip) => {
  this.setState({
      modalTable: !this.state.modalTable,
      tableTip:tableTip,
  });
  setTimeout(() => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
}, 500);
}

showOrHidePhotoModal = () => {
  this.setState({
      modalPhotoShow: !this.state.modalPhotoShow,
  });
  setTimeout(() => {
      document.body.style.overflow = "auto";
  }, 500);
};

onChangeDueDatesNMR601 = (value, dateString) => {
  this.setState({
    dueDatesNMR601: dateString,
  });
}

onChangeDueDatesNMR602 = (value, dateString) => {
  this.setState({
    dueDatesNMR602: dateString,
  });
}

onChangeDueDatesNMR603 = (value, dateString) => {
  this.setState({
    dueDatesNMR603: dateString,
  });
}

createNewClientButtonNMR601 = async () => {
  await this.resetInputsNMR601();  

   if (this.state.createNewButtonNMR601=="Create New") {
     this.setState({ 
      hiddenDataGirisNMR601: false, 
      hiddenSaveNMR601:false,
      hiddenUpdateNMR601:true,
      createNewButtonNMR601: "Cancel",
      createNewButtonVariantNMR601: "danger",
     });
  }
  else {
     this.setState({ 
      hiddenDataGirisNMR601: true,
      hiddenSaveNMR601:true,
      hiddenUpdateNMR601:false,
      createNewButtonNMR601: "Create New",
      createNewButtonVariantNMR601: "info",
     });
  }
}

createNewClientButtonNMR602 = async () => {
  await this.resetInputsNMR602();  

   if (this.state.createNewButtonNMR602=="Create New") {
     this.setState({ 
      hiddenDataGirisNMR602: false, 
      hiddenSaveNMR602:false,
      hiddenUpdateNMR602:true,
      createNewButtonNMR602: "Cancel",
      createNewButtonVariantNMR602: "danger",
     });
  }
  else {
     this.setState({ 
      hiddenDataGirisNMR602: true,
      hiddenSaveNMR602:true,
      hiddenUpdateNMR602:false,
      createNewButtonNMR602: "Create New",
      createNewButtonVariantNMR602: "info",
     });
  }
}

createNewClientButtonNMR603 = async () => {
  await this.resetInputsNMR603();  

   if (this.state.createNewButtonNMR603=="Create New") {
     this.setState({ 
      hiddenDataGirisNMR603: false, 
      hiddenSaveNMR603:false,
      hiddenUpdateNMR603:true,
      createNewButtonNMR603: "Cancel",
      createNewButtonVariantNMR603: "danger",
     });
  }
  else {
     this.setState({ 
      hiddenDataGirisNMR603: true,
      hiddenSaveNMR603:true,
      hiddenUpdateNMR603:false,
      createNewButtonNMR603: "Create New",
      createNewButtonVariantNMR603: "info",
     });
  }
}

resetInputsNMR601 = () => {
  var titleDisabledNMR601=false;
  var titleNMR601="";
  var copiesRequiredNMR601="";
  var softCopyNMR601="";
  var hardCopyNMR601="";
  var priorityNMR601="";
  var numberOfCalenderWeeksNMR601="";
  var dueDatesNMR601="";
  if(this.state.NMR601Table.length>0){
    titleDisabledNMR601=true;
    titleNMR601=this.state.NMR601Table[this.state.NMR601Table.length-1].title;
    copiesRequiredNMR601=this.state.NMR601Table[this.state.NMR601Table.length-1].copiesRequired;
    softCopyNMR601=this.state.NMR601Table[this.state.NMR601Table.length-1].softCopy;
    hardCopyNMR601=this.state.NMR601Table[this.state.NMR601Table.length-1].hardCopy;
    priorityNMR601=this.state.NMR601Table[this.state.NMR601Table.length-1].priority;
    numberOfCalenderWeeksNMR601=this.state.NMR601Table[this.state.NMR601Table.length-1].numberOfCalenderWeeks;
    dueDatesNMR601=this.state.NMR601Table[this.state.NMR601Table.length-1].dueDates;
  }

  this.setState({
    id: -1,
    descriptionNMR601: "",

    titleDisabledNMR601:titleDisabledNMR601,
    titleNMR601:titleNMR601,
    itemNoNMR601:this.state.NMR601Table.length + 1,
    copiesRequiredNMR601:copiesRequiredNMR601,
    softCopyNMR601:softCopyNMR601, 
    hardCopyNMR601: hardCopyNMR601,
    priorityNMR601:priorityNMR601,
    numberOfCalenderWeeksNMR601:numberOfCalenderWeeksNMR601,
    dueDatesNMR601: dueDatesNMR601,

    hiddenSaveNMR601: false,
    hiddenUpdateNMR601: true,
    hiddenDataGirisNMR601:true,
  });
}

resetInputsNMR602 = () => {
  var titleDisabledNMR602=false;
  var titleNMR602="";
  var copiesRequiredNMR602="";
  var softCopyNMR602="";
  var hardCopyNMR602="";
  var priorityNMR602="";
  var numberOfCalenderWeeksNMR602="";
  var dueDatesNMR602="";
  if(this.state.NMR602Table.length>0){
    titleDisabledNMR602=true;
    titleNMR602=this.state.NMR602Table[this.state.NMR602Table.length-1].title;
    copiesRequiredNMR602=this.state.NMR602Table[this.state.NMR602Table.length-1].copiesRequired;
    softCopyNMR602=this.state.NMR602Table[this.state.NMR602Table.length-1].softCopy;
    hardCopyNMR602=this.state.NMR602Table[this.state.NMR602Table.length-1].hardCopy;
    priorityNMR602=this.state.NMR602Table[this.state.NMR602Table.length-1].priority;
    numberOfCalenderWeeksNMR602=this.state.NMR602Table[this.state.NMR602Table.length-1].numberOfCalenderWeeks;
    dueDatesNMR602=this.state.NMR602Table[this.state.NMR602Table.length-1].dueDates;
  }

  this.setState({
    id: -1,
    descriptionNMR602: "",

    titleDisabledNMR602:titleDisabledNMR602,
    titleNMR602:titleNMR602,
    itemNoNMR602:this.state.NMR602Table.length + 1,
    copiesRequiredNMR602:copiesRequiredNMR602,
    softCopyNMR602:softCopyNMR602, 
    hardCopyNMR602: hardCopyNMR602,
    priorityNMR602:priorityNMR602,
    numberOfCalenderWeeksNMR602:numberOfCalenderWeeksNMR602,
    dueDatesNMR602: dueDatesNMR602,

    hiddenSaveNMR602: false,
    hiddenUpdateNMR602: true,
    hiddenDataGirisNMR602:true,
  });
}

resetInputsNMR603 = () => {
  var titleDisabledNMR603=false;
  var titleNMR603="";
  var copiesRequiredNMR603="";
  var softCopyNMR603="";
  var hardCopyNMR603="";
  var priorityNMR603="";
  var numberOfCalenderWeeksNMR603="";
  var dueDatesNMR603="";
  if(this.state.NMR603Table.length>0){
    titleDisabledNMR603=true;
    titleNMR603=this.state.NMR603Table[this.state.NMR603Table.length-1].title;
    copiesRequiredNMR603=this.state.NMR603Table[this.state.NMR603Table.length-1].copiesRequired;
    softCopyNMR603=this.state.NMR603Table[this.state.NMR603Table.length-1].softCopy;
    hardCopyNMR603=this.state.NMR603Table[this.state.NMR603Table.length-1].hardCopy;
    priorityNMR603=this.state.NMR603Table[this.state.NMR603Table.length-1].priority;
    numberOfCalenderWeeksNMR603=this.state.NMR603Table[this.state.NMR603Table.length-1].numberOfCalenderWeeks;
    dueDatesNMR603=this.state.NMR603Table[this.state.NMR603Table.length-1].dueDates;
  }

  this.setState({
    id: -1,
    descriptionNMR603: "",

    titleDisabledNMR603:titleDisabledNMR603,
    titleNMR603:titleNMR603,
    itemNoNMR603:this.state.NMR603Table.length + 1,
    copiesRequiredNMR603:copiesRequiredNMR603,
    softCopyNMR603:softCopyNMR603, 
    hardCopyNMR603: hardCopyNMR603,
    priorityNMR603:priorityNMR603,
    numberOfCalenderWeeksNMR603:numberOfCalenderWeeksNMR603,
    dueDatesNMR603: dueDatesNMR603,

    hiddenSaveNMR603: false,
    hiddenUpdateNMR603: true,
    hiddenDataGirisNMR603:true,
  });
}

render() {
  const layout = (
    isSidebarOpened,
    container,
    main,
    toolbar,
    sidebar,
  ) => {
    return defaultLayout(
      isSidebarOpened,
      container,
      main,
      toolbar(renderToolbar),
      sidebar,
    );
  };

  const renderToolbar = (toolbarSlot) => {
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          width: '100%',
        }}
      >
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flexGrow: 1,
            flexShrink: 1,
            justifyContent: 'center',
          }}
        >
          <div style={{ padding: '0 2px' }}>
            {toolbarSlot.previousPageButton}
          </div>
          <div style={{ padding: '0 2px' }}>
            {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
          </div>
          <div style={{ padding: '0 2px' }}>
            {toolbarSlot.nextPageButton}
          </div>
          <div style={{ padding: '0 2px' }}>
            {toolbarSlot.zoomOutButton}
          </div>
          <div style={{ padding: '0 2px' }}>
            {toolbarSlot.zoomPopover}
          </div>
          <div style={{ padding: '0 2px' }}>
            {toolbarSlot.zoomInButton}
          </div>
          <div style={{ padding: '0 2px' }}>
            {toolbarSlot.downloadButton}
          </div>
          <div style={{ padding: '0 2px' }}>
            {toolbarSlot.moreActionsPopover}
          </div>
        </div>
      </div>
    );
  };

  const columnsNMR601 = [
    {
      key: "key",
      title: "",
      render: this.actionTemplateNMR601
    },        
    {
      dataIndex: "index",
      key: "key",
      title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("NonMaterialRequirement")} icon="pi pi-cog"></PrimeButton>,
    },
    {
      dataIndex: "title",
      key: "key",
      title: <FormattedMessage id="NMRNMR601Title" defaultMessage="NMR 601 Title" />,
    },
    {
      dataIndex: "itemNo",
      key: "key",
      title: <FormattedMessage id="NMRItemNo" defaultMessage="Item no" />,
    },
    {
      dataIndex: "description",
      key: "key",
      title: <FormattedMessage id="NMRDescription" defaultMessage="Description" />,
    },
    {
      dataIndex: "copiesRequired",
      key: "key",
      title: <FormattedMessage id="NMRCopiesRequired" defaultMessage="Copies Required" />,
    },
    {
      dataIndex: "softCopy",
      key: "key",
      title: <FormattedMessage id="NMRSoftCopy" defaultMessage="Soft Copy" />,
    },
    {
      dataIndex: "hardCopy",
      key: "key",
      title: <FormattedMessage id="NMRHardCopy" defaultMessage="Hard Copy" />,
    },
    {
      dataIndex: "priority",
      key: "key",
      title: <FormattedMessage id="NMRPriority" defaultMessage="Priority" />,
    },  
    {
      dataIndex: "numberOfCalenderWeeks",
      key: "key",
      title: <FormattedMessage id="NMRNumberOfCalenderWeeks" defaultMessage="Number of Calender Weeks" />,
    },  
    {
      dataIndex: "dueDates",
      key: "key",
      title: <FormattedMessage id="NMRDueDates" defaultMessage="Due Dates" />,
    },
  ];

  const columnsNMR602 = [
    {
      key: "key",
      title: "",
      render: this.actionTemplateNMR602
    },        
    {
      dataIndex: "index",
      key: "key",
      title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("NonMaterialRequirement")} icon="pi pi-cog"></PrimeButton>,
    },
    {
      dataIndex: "title",
      key: "key",
      title: <FormattedMessage id="NMRNMR602Title" defaultMessage="NMR 602 Title" />,
    },
    {
      dataIndex: "itemNo",
      key: "key",
      title: <FormattedMessage id="NMRItemNo" defaultMessage="Item no" />,
    },
    {
      dataIndex: "description",
      key: "key",
      title: <FormattedMessage id="NMRDescription" defaultMessage="Description" />,
    },
    {
      dataIndex: "copiesRequired",
      key: "key",
      title: <FormattedMessage id="NMRCopiesRequired" defaultMessage="Copies Required" />,
    },
    {
      dataIndex: "softCopy",
      key: "key",
      title: <FormattedMessage id="NMRSoftCopy" defaultMessage="Soft Copy" />,
    },
    {
      dataIndex: "hardCopy",
      key: "key",
      title: <FormattedMessage id="NMRHardCopy" defaultMessage="Hard Copy" />,
    },
    {
      dataIndex: "priority",
      key: "key",
      title: <FormattedMessage id="NMRPriority" defaultMessage="Priority" />,
    },  
    {
      dataIndex: "numberOfCalenderWeeks",
      key: "key",
      title: <FormattedMessage id="NMRNumberOfCalenderWeeks" defaultMessage="Number of Calender Weeks" />,
    },  
    {
      dataIndex: "dueDates",
      key: "key",
      title: <FormattedMessage id="NMRDueDates" defaultMessage="Due Dates" />,
    },
  ];

  const columnsNMR603 = [
    {
      key: "key",
      title: "",
      render: this.actionTemplateNMR603
    },        
    {
      dataIndex: "index",
      key: "key",
      title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("NonMaterialRequirement")} icon="pi pi-cog"></PrimeButton>,
    },
    {
      dataIndex: "title",
      key: "key",
      title: <FormattedMessage id="NMRNMR603Title" defaultMessage="NMR 603 Title" />,
    },
    {
      dataIndex: "itemNo",
      key: "key",
      title: <FormattedMessage id="NMRItemNo" defaultMessage="Item no" />,
    },
    {
      dataIndex: "description",
      key: "key",
      title: <FormattedMessage id="NMRDescription" defaultMessage="Description" />,
    },
    {
      dataIndex: "copiesRequired",
      key: "key",
      title: <FormattedMessage id="NMRCopiesRequired" defaultMessage="Copies Required" />,
    },
    {
      dataIndex: "softCopy",
      key: "key",
      title: <FormattedMessage id="NMRSoftCopy" defaultMessage="Soft Copy" />,
    },
    {
      dataIndex: "hardCopy",
      key: "key",
      title: <FormattedMessage id="NMRHardCopy" defaultMessage="Hard Copy" />,
    },
    {
      dataIndex: "priority",
      key: "key",
      title: <FormattedMessage id="NMRPriority" defaultMessage="Priority" />,
    },  
    {
      dataIndex: "numberOfCalenderWeeks",
      key: "key",
      title: <FormattedMessage id="NMRNumberOfCalenderWeeks" defaultMessage="Number of Calender Weeks" />,
    },  
    {
      dataIndex: "dueDates",
      key: "key",
      title: <FormattedMessage id="NMRDueDates" defaultMessage="Due Dates" />,
    },
  ];
  
  const tableColumnsNMR601 = columnsNMR601.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
  if (this.state.xScroll === 'fixed') {
    tableColumnsNMR601[0].fixed = true;
    tableColumnsNMR601[tableColumnsNMR601.length - 1].fixed = 'right';
  }

  const tableColumnsNMR602 = columnsNMR602.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
  if (this.state.xScroll === 'fixed') {
    tableColumnsNMR602[0].fixed = true;
    tableColumnsNMR602[tableColumnsNMR602.length - 1].fixed = 'right';
  }

  const tableColumnsNMR603 = columnsNMR603.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
  if (this.state.xScroll === 'fixed') {
    tableColumnsNMR603[0].fixed = true;
    tableColumnsNMR603[tableColumnsNMR603.length - 1].fixed = 'right';
  }

    return (
      <div>   
        <Collapse defaultActiveKey={['1']} expandIconPosition={this.state.expandIconPosition}>

            <Panel style={{ fontWeight: 'bold' }}  header={<FormattedMessage id="NMRNMR601" defaultMessage="NMR 601" />} key="1"  >
                <Form>
                  <Row>
                    <Col xs={8}></Col>
                    <Col xs={3}>
                        <Button onClick={this.createNewClientButtonNMR601} style={{ width: '100%' }}
                            variant={this.state.createNewButtonVariantNMR601} >{this.state.createNewButtonNMR601}
                        </Button>
                    </Col>
                    <Col xs={1}>
                      <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable("NonMaterialRequirement")} icon="pi pi-cog"></PrimeButton>
                    </Col>
                  </Row>

              <div hidden={this.state.hiddenDataGirisNMR601}>
                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="NMR601Title" defaultMessage="NMR 601 Title"/> 
                  </Form.Label>
                  <Col sm="4">
                  <Input id="NMR601Title" disabled={this.state.titleDisabledNMR601} value={this.state.titleNMR601} onChange={(e) =>
                    this.setState({ titleNMR601: e.target.value })} />
                  </Col>
                </Form.Group>

                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="NMRItemNo" defaultMessage="Item No"/> 
                  </Form.Label>
                  <Col sm="2">
                    <InputNumber id="NMRItemNo" min={0} style={{ width: '100%' }} value={this.state.itemNoNMR601} readOnly={true}/>
                  </Col>
                </Form.Group>

                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="NMRDescription" defaultMessage="Description"/> 
                  </Form.Label>
                  <Col sm="4">
                  <Input id="NMRDescription" value={this.state.descriptionNMR601} onChange={(e) =>
                    this.setState({ descriptionNMR601: e.target.value })} />
                  </Col>
                </Form.Group>
                
                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="NMRCopiesRequired" defaultMessage="Copies Required"/> 
                  </Form.Label>
                  <Col sm="2">
                  <InputNumber id="NMRCopiesRequired" min={0} style={{ width: '100%' }}
                    onChange={(e) => this.setState({ copiesRequiredNMR601: e })} value={this.state.copiesRequiredNMR601}/>
                  </Col>
                </Form.Group>

                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="NMRSoftCopy" defaultMessage="Soft Copy"/> 
                  </Form.Label>
                  <Col sm="2">
                  <InputNumber id="NMRSoftCopy" min={0} style={{ width: '100%' }}
                    onChange={(e) => this.setState({ softCopyNMR601: e })} value={this.state.softCopyNMR601}/>
                  </Col>
                </Form.Group>

                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="NMRHardCopy" defaultMessage="Hard Copy"/> 
                  </Form.Label>
                  <Col sm="2">
                  <InputNumber id="NMRHardCopy" min={0} style={{ width: '100%' }}
                    onChange={(e) => this.setState({ hardCopyNMR601: e })} value={this.state.hardCopyNMR601}/>
                  </Col>
                </Form.Group>  
                
                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="NMRPriority" defaultMessage="Priority"/> 
                  </Form.Label>
                  <Col sm="2">
                  <InputNumber id="NMRPriority" min={0} style={{ width: '100%' }}
                    onChange={(e) => this.setState({ priorityNMR601: e })} value={this.state.priorityNMR601}/>
                  </Col>
                </Form.Group>

                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="NMRNumberOfCalenderWeeks" defaultMessage="Number of Calender Weeks"/> 
                  </Form.Label>
                  <Col sm="4">
                  <Input id="NMRNumberOfCalenderWeeks" value={this.state.numberOfCalenderWeeksNMR601} onChange={(e) =>
                    this.setState({ numberOfCalenderWeeksNMR601: e.target.value })} />
                  </Col>
                </Form.Group> 
                
                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="NMRDueDates" defaultMessage="Due Dates"/> 
                  </Form.Label>
                  <Col sm="4">
                  <DatePicker value={(this.state.dueDatesNMR601!=null && this.state.dueDatesNMR601!=undefined && this.state.dueDatesNMR601!="") && moment(this.state.dueDatesNMR601, 'YYYY-MM-DD')} format="YYYY-MM-DD" onChange={this.onChangeDueDatesNMR601} />
                  </Col>
                </Form.Group>

                <br />
                <div hidden={this.state.hiddenSaveNMR601}>
                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDocCode">
                    <Col sm="2"> 
                    </Col>
                   <Col sm="8"> 
                      <Button onClick={this.addNMR601} 
                      style={{ width: '100%' }}  
                      variant="info"><FormattedMessage id="GeneralButtonAdd" defaultMessage="Add"/></Button>
                    </Col> 
                    <Col sm="2"> 
                    </Col>
                  </Form.Group>
                </div>

                <div hidden={this.state.hiddenUpdateNMR601}>
                  <Row>
                      <Col sm="2"> 
                      </Col>
                      <Col sm="4">
                        <Button onClick={this.changeButtonCancelNMR601} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel"/></Button>
                      </Col>
                      <Col sm="4">
                        <Button onClick={this.updateDoneNMR601} style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update"/></Button>
                      </Col>
                      <Col sm="2"> 
                      </Col>
                  </Row>
                </div>
              </div>

                <div className="datatable-doc-demo">
                  <div className="content-section implementation">
                    <Mnd.MNDTable
                      state={this.state}
                      columns={tableColumnsNMR601} dataSource={this.state.NMR601Table}
                      pagination={this.state.paginationDisabled}
                      expandable={this.state.expandable}
                      footer={this.state.footer}
                      title={this.state.title}
                      scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                    /> 
                  </div>
                </div>
              
                </Form>
            </Panel>

            <Panel style={{ fontWeight: 'bold' }} header={<FormattedMessage id="NMRNMR602" defaultMessage="NMR 602" />} key="2"  >
            <div>
                <Form>
                <Row>
                    <Col xs={8}></Col>
                    <Col xs={3}>
                        <Button onClick={this.createNewClientButtonNMR602} style={{ width: '100%' }}
                            variant={this.state.createNewButtonVariantNMR602} >{this.state.createNewButtonNMR602}
                        </Button>
                    </Col>
                    <Col xs={1}>
                      <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable("NonMaterialRequirement")} icon="pi pi-cog"></PrimeButton>
                    </Col>
                  </Row>
              
              <div hidden={this.state.hiddenDataGirisNMR602}>
                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="NMR602Title" defaultMessage="NMR 602 Title"/> 
                  </Form.Label>
                  <Col sm="4">
                  <Input id="NMR602Title" disabled={this.state.titleDisabledNMR602} value={this.state.titleNMR602} onChange={(e) =>
                    this.setState({ titleNMR602: e.target.value })} />
                  </Col>
                </Form.Group>

                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="NMRItemNo" defaultMessage="Item No"/> 
                  </Form.Label>
                  <Col sm="2">
                    <InputNumber id="NMRItemNo" min={0} style={{ width: '100%' }} value={this.state.itemNoNMR602} readOnly={true}/>
                  </Col>
                </Form.Group>

                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="NMRDescription" defaultMessage="Description"/> 
                  </Form.Label>
                  <Col sm="4">
                  <Input id="NMRDescription" value={this.state.descriptionNMR602} onChange={(e) =>
                    this.setState({ descriptionNMR602: e.target.value })} />
                  </Col>
                </Form.Group>
                
                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="NMRCopiesRequired" defaultMessage="Copies Required"/> 
                  </Form.Label>
                  <Col sm="4">
                  <InputNumber id="NMRCopiesRequired" min={0} style={{ width: '100%' }}
                    onChange={(e) => this.setState({ copiesRequiredNMR602: e })} value={this.state.copiesRequiredNMR602}/>
                  </Col>
                </Form.Group>

                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="NMRSoftCopy" defaultMessage="Soft Copy"/> 
                  </Form.Label>
                  <Col sm="4">
                  <InputNumber id="NMRSoftCopy" min={0} style={{ width: '100%' }}
                    onChange={(e) => this.setState({ softCopyNMR602: e })} value={this.state.softCopyNMR602}/>
                  </Col>
                </Form.Group>

                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="NMRHardCopy" defaultMessage="Hard Copy"/> 
                  </Form.Label>
                  <Col sm="4">
                  <InputNumber id="NMRHardCopy" min={0} style={{ width: '100%' }}
                    onChange={(e) => this.setState({ hardCopyNMR602: e })} value={this.state.hardCopyNMR602}/>
                  </Col>
                </Form.Group>  
                
                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="NMRPriority" defaultMessage="Priority"/> 
                  </Form.Label>
                  <Col sm="4">
                  <InputNumber id="NMRPriority" min={0} style={{ width: '100%' }}
                    onChange={(e) => this.setState({ priorityNMR602: e })} value={this.state.priorityNMR602}/>
                  </Col>
                </Form.Group>

                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="NMRNumberOfCalenderWeeks" defaultMessage="Number of Calender Weeks"/> 
                  </Form.Label>
                  <Col sm="4">
                  <Input id="NMRNumberOfCalenderWeeks" value={this.state.numberOfCalenderWeeksNMR602} onChange={(e) =>
                    this.setState({ numberOfCalenderWeeksNMR602: e.target.value })} />
                  </Col>
                </Form.Group> 
                
                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="NMRDueDates" defaultMessage="Due Dates"/> 
                  </Form.Label>
                  <Col sm="4">
                  <DatePicker value={(this.state.dueDatesNMR602!=null && this.state.dueDatesNMR602!=undefined && this.state.dueDatesNMR602!="") && moment(this.state.dueDatesNMR602, 'YYYY-MM-DD')} format="YYYY-MM-DD" onChange={this.onChangeDueDatesNMR602} />
                  </Col>
                </Form.Group>
                <br />

                <div hidden={this.state.hiddenSaveNMR602}>
                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDocCode">
                  <Col sm="2"> 
                    </Col>
                   <Col sm="8"> 
                      <Button onClick={this.addNMR602} 
                      style={{ width: '100%' }}  
                      variant="info"><FormattedMessage id="GeneralButtonAdd" defaultMessage="Add"/></Button>
                    </Col> 
                    <Col sm="2"> 
                    </Col>
                  </Form.Group>
                </div>

                <div hidden={this.state.hiddenUpdateNMR602}>
                  <Row>
                      <Col sm="2"> 
                      </Col>
                      <Col sm="4">
                        <Button onClick={this.changeButtonCancelNMR602} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel"/></Button>
                      </Col>
                      <Col sm="4">
                        <Button onClick={this.updateDoneNMR602} style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update"/></Button>
                      </Col>
                      <Col sm="2"> 
                      </Col>
                  </Row>
                </div>
                </div>

                <div className="datatable-doc-demo">
                  <div className="content-section implementation">
                    <Mnd.MNDTable
                      state={this.state}
                      columns={tableColumnsNMR602} dataSource={this.state.NMR602Table}
                      pagination={this.state.paginationDisabled}
                      expandable={this.state.expandable}
                      footer={this.state.footer}
                      title={this.state.title}
                      scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                    /> 
                  </div>
                </div>
              
                </Form>
                </div>
            </Panel>
            
            <Panel style={{ fontWeight: 'bold' }} header={<FormattedMessage id="NMRNMR603" defaultMessage="NMR 603" />} key="3"  >
              <div>
                <Form>

                <Row>
                    <Col xs={8}></Col>
                    <Col xs={3}>
                        <Button onClick={this.createNewClientButtonNMR603} style={{ width: '100%' }}
                            variant={this.state.createNewButtonVariantNMR603} >{this.state.createNewButtonNMR603}
                        </Button>
                    </Col>
                    <Col xs={1}>
                      <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable("NonMaterialRequirement")} icon="pi pi-cog"></PrimeButton>
                    </Col>
                  </Row>
              <div hidden={this.state.hiddenDataGirisNMR603}>
                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="NMR603Title" defaultMessage="NMR 603 Title"/> 
                  </Form.Label>
                  <Col sm="4">
                  <Input id="NMR603Title"  disabled={this.state.titleDisabledNMR603} value={this.state.titleNMR603} onChange={(e) =>
                    this.setState({ titleNMR603: e.target.value })} />
                  </Col>
                </Form.Group>

                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                      <FormattedMessage id="NMRItemNo" defaultMessage="Item No"/> 
                  </Form.Label>
                  <Col sm="2">
                    <InputNumber id="NMRItemNo" min={0} style={{ width: '100%' }} value={this.state.itemNoNMR603} readOnly={true}/>
                  </Col>
                </Form.Group>

                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="NMRDescription" defaultMessage="Description"/> 
                  </Form.Label>
                  <Col sm="4">
                  <Input id="NMRDescription" value={this.state.descriptionNMR603} onChange={(e) =>
                    this.setState({ descriptionNMR603: e.target.value })} />
                  </Col>
                </Form.Group>
                
                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="NMRCopiesRequired" defaultMessage="Copies Required"/> 
                  </Form.Label>
                  <Col sm="4">
                  <InputNumber id="NMRCopiesRequired" min={0} style={{ width: '100%' }}
                    onChange={(e) => this.setState({ copiesRequiredNMR603: e })} value={this.state.copiesRequiredNMR603}/>
                  </Col>
                </Form.Group>

                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="NMRSoftCopy" defaultMessage="Soft Copy"/> 
                  </Form.Label>
                  <Col sm="4">
                  <InputNumber id="NMRSoftCopy" min={0} style={{ width: '100%' }}
                    onChange={(e) => this.setState({ softCopyNMR603: e })} value={this.state.softCopyNMR603}/>
                  </Col>
                </Form.Group>

                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="NMRHardCopy" defaultMessage="Hard Copy"/> 
                  </Form.Label>
                  <Col sm="4">
                  <InputNumber id="NMRHardCopy" min={0} style={{ width: '100%' }}
                    onChange={(e) => this.setState({ hardCopyNMR603: e })} value={this.state.hardCopyNMR603}/>
                  </Col>
                </Form.Group>  
                
                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="NMRPriority" defaultMessage="Priority"/> 
                  </Form.Label>
                  <Col sm="4">
                  <InputNumber id="NMRPriority" min={0} style={{ width: '100%' }}
                    onChange={(e) => this.setState({ priorityNMR603: e })} value={this.state.priorityNMR603}/>
                  </Col>
                </Form.Group>

                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="NMRNumberOfCalenderWeeks" defaultMessage="Number of Calender Weeks"/> 
                  </Form.Label>
                  <Col sm="4">
                  <Input id="NMRNumberOfCalenderWeeks" value={this.state.numberOfCalenderWeeksNMR603} onChange={(e) =>
                    this.setState({ numberOfCalenderWeeksNMR603: e.target.value })} />
                  </Col>
                </Form.Group> 
                
                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                  <Form.Label style={{ color: 'black' }} column sm="2">
                    <FormattedMessage id="NMRDueDates" defaultMessage="Due Dates"/> 
                  </Form.Label>
                  <Col sm="4">
                    <DatePicker value={(this.state.dueDatesNMR603!=null && this.state.dueDatesNMR603!=undefined && this.state.dueDatesNMR603!="") && moment(this.state.dueDatesNMR603, 'YYYY-MM-DD')} format="YYYY-MM-DD" onChange={this.onChangeDueDatesNMR603} />
                 </Col>
                </Form.Group>
                <br />

                <div hidden={this.state.hiddenSaveNMR603}>
                  <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDocCode">
                  <Col sm="2"> 
                    </Col>
                   <Col sm="8"> 
                      <Button onClick={this.addNMR603} 
                      style={{ width: '100%' }}  
                      variant="info"><FormattedMessage id="GeneralButtonAdd" defaultMessage="Add"/></Button>
                    </Col> 
                    <Col sm="2"> 
                    </Col>
                  </Form.Group>
                </div>

                <div hidden={this.state.hiddenUpdateNMR603}>
                  <Row>
                      <Col sm="2"> 
                      </Col>
                      <Col sm="4">
                        <Button onClick={this.changeButtonCancelNMR603} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel"/></Button>
                      </Col>
                      <Col sm="4">
                        <Button onClick={this.updateDoneNMR603} style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update"/></Button>
                      </Col>
                      <Col sm="2"> 
                      </Col>
                  </Row>
                </div>
                </div>

                <div className="datatable-doc-demo">
                  <div className="content-section implementation">
                    <Mnd.MNDTable
                      state={this.state}
                      columns={tableColumnsNMR603} dataSource={this.state.NMR603Table}
                      pagination={this.state.paginationDisabled}
                      expandable={this.state.expandable}
                      footer={this.state.footer}
                      title={this.state.title}
                      scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                    /> 
                  </div>
                </div>
              
                </Form>
              </div>
            </Panel>
          
            <br />
        </Collapse> 
        
        <Modal
            show={this.state.modalMessage}
            onHide={this.showOrHideModalMessage}
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered >

            <Modal.Header style={{backgroundColor: "#f3f3f3"}} closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                <Row > 
                  <Col sm="12">
                    {this.state.messageTip=="Success" &&  
                      <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} /> }
                  
                    {this.state.messageTip=="Warning" &&  
                    <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                    {this.state.messageTip=="Error" &&  
                    <i className="fas fa-times fa-2x" style={{ color: '#ff0400'}} />}
                  </Col>
                </Row>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body style={{backgroundColor: "#f3f3f3"}}>
              <Row > 
                <Col sm="12">
                  {this.state.message} 
                </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer style={{backgroundColor: "#f3f3f3"}}>
              <Button style={{ color: "#646c9a", backgroundColor: "#ffffff", borderColor: "#ffffff"}} variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
          </Modal.Footer>
          </Modal>

        <Modal
          show={this.state.modalPhotoShow}
          onHide={this.showOrHidePhotoModal}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered >
          <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"><FormattedMessage id="ModalPhotoText" defaultMessage="Photo"/></Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {this.state.imageUrl !== "" ? (
              <img
              src={this.state.imageUrl}
              alt="avatar"
              style={{ width: "100%" }}
              />
          ) : (
              <p> <FormattedMessage id="UploadedPhotoMessage1" defaultMessage="Photo has not been uploaded."/></p>
              )}
          </Modal.Body>
          <Modal.Footer>
          <AntdButton variant="info" onClick={this.showOrHidePhotoModal}>
              <FormattedMessage id="GeneralTextClose" defaultMessage="Close"/>
          </AntdButton>
          </Modal.Footer>
      </Modal>

        <Modal
        size="lg"
        show={this.state.modalPdfViewer}
        onHide={this.showOrHideModalPdfViewer}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
              <FormattedMessage id="ModalPDFReportText" defaultMessage="PDF Report Viewer"/>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
            <div style={{ height: "900px" }}>
            <Viewer fileUrl={this.state.pdfFile} layout={layout} />
            </div>
        </Worker>
        </Modal.Body>
        <Modal.Footer>
        <AntdButton
            variant="secondary"
            onClick={this.showOrHideModalPdfViewer}
        >
            <FormattedMessage id="GeneralTextClose" defaultMessage="Close"/>
            </AntdButton>
        </Modal.Footer>
    </Modal>

        <Modal
          show={this.state.modalTable}
          onHide={this.showOrHideModalTable}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered size="lg">

          <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                  <Row >
                      <Col sm="12">
                          <FormattedMessage id="GeneralTableSettings" defaultMessage="Table Settings" />
                      </Col>
                  </Row>
              </Modal.Title>
          </Modal.Header>

          <Modal.Body>
              <Row >
                  <Col sm="12">
                      <MNDTableSettings id={this.state.tableTip} />
                  </Col>
              </Row>
          </Modal.Body>
      </Modal>
      
        <Modal
          show={this.state.deleteModalNMR601}
          onHide={this.showOrHideModalDeleteNMR601}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered >
          <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                  <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <p style={{ textAlign: "center", color: "black" }}>
                  <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
              </p>
              <p style={{ textAlign: "center", color: "black" }}>
                  <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
              </p>
          </Modal.Body>
          <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideModalDeleteNMR601}>
                  <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
              </Button>
              <Button variant="danger" onClick={this.deleteNonMaterialRequirementNMR601OK}>
                  <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Modal.Footer>
          </Modal>

        <Modal
          show={this.state.deleteModalNMR602}
          onHide={this.showOrHideModalDeleteNMR602}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered >
          <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                  <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <p style={{ textAlign: "center", color: "black" }}>
                  <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
              </p>
              <p style={{ textAlign: "center", color: "black" }}>
                  <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
              </p>
          </Modal.Body>
          <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideModalDeleteNMR602}>
                  <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
              </Button>
              <Button variant="danger" onClick={this.deleteNonMaterialRequirementNMR602OK}>
                  <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Modal.Footer>
          </Modal>

        <Modal
          show={this.state.deleteModalNMR603}
          onHide={this.showOrHideModalDeleteNMR603}
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          centered >
          <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                  <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <p style={{ textAlign: "center", color: "black" }}>
                  <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
              </p>
              <p style={{ textAlign: "center", color: "black" }}>
                  <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
              </p>
          </Modal.Body>
          <Modal.Footer>
              <Button variant="secondary" onClick={this.showOrHideModalDeleteNMR603}>
                  <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
              </Button>
              <Button variant="danger" onClick={this.deleteNonMaterialRequirementNMR603OK}>
                  <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
              </Button>
            </Modal.Footer>
          </Modal>


      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    i18n: state.i18n
  }
}

const mapDispatchToProps = {
   
}

export default connect(mapStateToProps, mapDispatchToProps)(Nmr)