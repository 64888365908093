import React from "react";
import PropTypes from "prop-types";
/* import DemosList from "../../partials/layout/AnimateLoading"; */
import { Table } from "react-bootstrap";

const MoviesList = (props) => {
  console.log("SelectItemList sayfasından");
	const emptyMessage = (
		<p>There are no movies yet.</p>
	);
console.log("SelectItemListSAYFASIww",props);
	const moviesList  = (
		<div>

			{
				props.cityReducer.error.response
					? <h3>Error retrieving data!</h3>
          :
          <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Country Code</th>
          <th>Population</th>
        </tr>
      </thead>
      <tbody>
        {props.cityReducer.movieList.map(city => (
          <tr key={city.id}>
            <td>{city.id}</td>
            <td>{city.name}</td>
            <td>{city.countryCode}</td>
            <td>{city.population}</td>
          </tr>
        ))}
      </tbody>
    </Table>
								}
		</div>
	);

	return (
		<div>
			{props.cityReducer.length === 0 ? emptyMessage : moviesList}
		</div>
	);
};

MoviesList.propTypes = {
	cityReducer: PropTypes.shape({
		movieList: PropTypes.array.isRequired
	}).isRequired
};

export default MoviesList;
