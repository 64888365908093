/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import { TreeTable } from 'primereact/treetable';
import { Card } from "primereact/card";
import { SplitButton } from 'primereact/splitbutton';
import { Button as PrimeButton } from 'primereact/button';
import { Button as ReactButton } from 'react-bootstrap';
import { Col, Modal, Form, Row } from 'react-bootstrap';
import { InputText } from "primereact/inputtext";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { handleRequest } from '../../redux/config/env';
import { ContextMenu } from 'primereact/contextmenu';
import $ from 'jquery';
import { Toast } from 'primereact/toast';

export class MaterialTypes extends Component {

    constructor() {
        super();
        this.state = {
            nodes: [],
            expandedKeys: {},
            addModal: false,
            addModal2:false,
            deleteModal: false,
            editModal: false,
            materialTypeId: null,
            materialTypeParentId: null,
            modalMaterialChildTypeName: "",
            modalMaterialParentTypeName:"",
            modalEditMaterialTypeName: "",
            rootMaterialTypeName: "",
            selectedNodeKey: null,
            globalFilter: null,
            splitButtonMenu: [
                {
                    label: 'Add',
                    icon: 'pi pi-fw pi-file',
                    items: [
                        {
                            label: 'Child',
                            icon: 'pi pi-fw pi-plus',
                            command: () => {
                                this.addChildMaterialType();
                            }
                        },
                        {
                            label: 'Parent',
                            icon: 'pi pi-fw pi-plus',
                            command: () => {
                                this.addParentMaterialType();
                            }
                        }
                    ]
                }, 
                {
                    label: 'Edit',
                    icon: 'pi pi-fw pi-pencil',
                    command: () => {
                        this.editMaterialType();
                    }
                },
                {
                    label: 'Delete',
                    icon: 'pi pi-fw pi-trash',
                    command: () => {
                        this.deleteMaterialType();
                    }
                },
                {
                    label: 'Toggle',
                    icon: 'pi pi-fw pi-angle-double-right',
                    command: () => {
                        this.toggleMaterialType();
                    }
                }
            ],
            splitButtonMenu2: [
                {
                label: 'Add',
                icon: 'pi pi-fw pi-file',
                items: [
                    {
                        label: 'Child',
                        icon: 'pi pi-fw pi-plus',
                        command: () => {
                            this.addChildMaterialType();
                        }
                    },
                    {
                        label: 'Parent',
                        icon: 'pi pi-fw pi-plus',
                        command: () => {
                            this.addParentMaterialType();
                        }
                    }
                  ]   
                }, 
                {
                    label: 'Edit',
                    icon: 'pi pi-fw pi-pencil',
                    command: () => {
                        this.editMaterialType();
                    }
                },
                {
                    label: 'Delete',
                    icon: 'pi pi-fw pi-trash',
                    command: () => {
                        this.deleteMaterialType();
                    }
                },
                {
                    label: 'Toggle',
                    icon: 'pi pi-fw pi-angle-double-right',
                    command: () => {
                        this.toggleMaterialType();
                    }
                }
            ]
        };

        this.actionTemplate = this.actionTemplate.bind(this);
    }

    componentDidMount = async () => {
        const response = await handleRequest("GET", "/MaterialType/getNodes");

        if (response.data.length === 0) {
            this.setState({
                nodes: []
            });
        }

        if (response.data.length !== 0) {
            this.setState({
                nodes: response.data
            });
        }

        document.addEventListener('contextmenu', this._handleContextMenu);
    }

    _handleContextMenu = (event) => {
        event.preventDefault();
        setTimeout(function () {
            $(".p-contextmenu").css("left", event.clientX - 264 + "px");
            $(".p-contextmenu").css("top", event.clientY - 141 + "px");
        }, 50);
    }

    restartTable = async () => {
        const response = await handleRequest("GET", "/MaterialType/getNodes");

        if (response.data.length === 0) {
            this.setState({
                nodes: []
            });
        }

        if (response.data.length !== 0) {
            this.setState({
                nodes: response.data
            });
        }
    }

    actionTemplate(rowData, column) { 
        return <SplitButton type="button" icon="pi pi-cog" className="p-button-secondary" model={this.state.splitButtonMenu} ></SplitButton>;
    }

    toggleMaterialType = () => {
        var arr = this.state.selectedNodeKey.split('.');

        let expandedKeys = { ...this.state.expandedKeys };

        if (expandedKeys[arr[0]])
            delete expandedKeys[arr[0]];
        else
            expandedKeys[arr[0]] = true;

        this.setState({ expandedKeys: expandedKeys });
    }

    addParentMaterialType = () => {
        var arr = this.state.selectedNodeKey.split('.');

        this.setState({
            addModal2: true,
            materialTypeParentId: arr[0]  //seçili olanın id'si bu.
        });
    }

    addParentMaterialTypeOK = async () => {
        var response = await handleRequest("GET", "/MaterialType/" + this.state.materialTypeParentId);
        var item;

        if (response !== undefined || response !== null)
            item = response.data;

        var itemNew = {
            parentWillChangeId: this.state.materialTypeParentId,
            materialTypeName: this.state.modalMaterialParentTypeName,
            parentId: item.parentId,
        }
  
        if(this.state.modalMaterialParentTypeName!=null && this.state.modalMaterialParentTypeName!=""){

        var responseAdd =  await handleRequest("POST", "/MaterialType/addParent", itemNew);

        if (responseAdd.data !== null && responseAdd.data !== undefined) {
            if (responseAdd.data == "SUCCESS") {
                this.setState({
                    addModal2: false
                });
        
                this.restartControls();
                this.restartTable();
            }
            else  {
                this.showError("An error was occured please try again later !");
            }  
          } 
          else  {
            this.showError("An error was occured please try again later !");
         } 
        } 
        else  {
            this.showError();
        }    
    }

    addChildMaterialType = () => {

        var arr = this.state.selectedNodeKey.split('.');

        this.setState({
            addModal: true,
            materialTypeParentId: arr[0]
        });
    }

    addChildMaterialTypeOK = async () => {
        var item = {
            parentId: this.state.materialTypeParentId,
            materialTypeName: this.state.modalMaterialChildTypeName,
        } 
        if (this.state.materialTypeParentId != null && this.state.modalMaterialChildTypeName != null && this.state.modalMaterialChildTypeName != "") {
            await handleRequest("POST", "/MaterialType/add", item);

            this.setState({
                addModal: false
            });
    
            this.showSuccess();
            this.restartControls();
            this.restartTable();
        }else {
            this.showError();
        }
    }

    editMaterialType = async () => {
        var arr = this.state.selectedNodeKey.split('.');

        var response = await handleRequest("GET", "/MaterialType/" + arr[0]);

        this.setState({
            editModal: true,
            materialTypeId: response.data.id,
            materialTypeParentId: response.data.parentId,
            modalEditMaterialTypeName: response.data.materialTypeName
        });
    }

    editMaterialTypeOK = async () => {
        var item = {
            parentId: this.state.materialTypeParentId,
            id: this.state.materialTypeId,
            materialTypeName: this.state.modalEditMaterialTypeName,
        }

        if (this.state.materialTypeId != null && this.state.modalEditMaterialTypeName != "") {
            await handleRequest("POST", "/MaterialType/update", item);

            this.setState({
                editModal: false
            });
    
            this.showSuccess();
            this.restartTable();
            this.restartControls();
        }else {
            this.showError();
        }
    }

    deleteMaterialType = () => {
        var arr = this.state.selectedNodeKey.split('.');

        this.setState({
            deleteModal: true,
            materialTypeId: arr[0]
        });
    }

    deleteMaterialTypeOK = async () => {
        const deletedItem = {
            id: this.state.materialTypeId
        }

        await handleRequest("POST", "/MaterialType/delete", deletedItem);

        this.setState({
            deleteModal: false
        });

        this.restartControls();
        this.restartTable();
    }

    showOrHideModalDeleteMaterialType = () => {
        this.setState({
            deleteModal: !this.state.deleteModal
        });
    }

    showOrHideModalAddChildMaterialType = () => {
        this.setState({
            addModal: !this.state.addModal
        });
    }

    showOrHideModalAddParentMaterialType = () => {
        this.setState({
            addModal2: !this.state.addModal2
        });
    }

    showOrHideModalEditMaterialType = () => {
        this.setState({
            editModal: !this.state.editModal
        });
    }

    saveMaterialType = async () => { 
        if (this.state.materialTypeId === null && this.state.rootMaterialTypeName !== "") {
            var item = {
                materialTypeName: this.state.rootMaterialTypeName,
            }

            await handleRequest("POST", "/MaterialType/add", item);

            this.showSuccess();

            this.restartTable();
            this.restartControls();
        }else {
            this.showError();
        } 
    }

    showSuccess() {
        this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Successful.' });
      };
    
      showError(msg) {
        if (!msg) {
          msg = 'Something went wrong. Please check inputs.';
        }
        this.toast.show({ severity: 'error', summary: 'Error Message', detail: msg });
      };

    restartControls = () => {
        this.setState({
            materialTypeId: null,
            materialTypeParentId: null,
            rootMaterialTypeName: "",
            modalMaterialChildTypeName: "",
            modalMaterialParentTypeName:"",
            modalEditMaterialTypeName: ""
        });
    }

    handleSplitMenuChange = e => {
        this.setState({ selectedNodeKey: e.value });
    };

    render() {
        let actionHeader = <PrimeButton type="button" icon="pi pi-cog"></PrimeButton>;

        let header = <div style={{ 'textAlign': 'left' }}>
            <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
            <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Global Search" size="50" />
        </div>;

        return (
            <div>
                <Modal
                    show={this.state.deleteModal}
                    onHide={this.showOrHideModalDeleteMaterialType}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Delete Material Type</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{ textAlign: 'center', color: 'black' }}>
                            Do you want to delete this Material Type?
                    </p>
                        <p style={{ textAlign: 'center', color: 'black' }}>
                            This process will delete all related types.
                    </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalDeleteMaterialType}>Close</ReactButton>
                        <ReactButton variant="danger" onClick={this.deleteMaterialTypeOK}>Delete</ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.addModal}
                    onHide={this.showOrHideModalAddChildMaterialType}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Add Child Material Type</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form style={{ marginTop: '2rem' }}>
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Child Material Type Name:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridCity">
                                    <InputText onChange={(e) => this.setState({ modalMaterialChildTypeName: e.target.value })}
                                        value={this.state.modalMaterialChildTypeName} placeholder="Material Type Name" />
                                </Form.Group>
                            </Form.Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalAddChildMaterialType}>Close</ReactButton>
                        <ReactButton variant="success" onClick={this.addChildMaterialTypeOK}>Add</ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.addModal2}
                    onHide={this.showOrHideModalAddParentMaterialType}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                        
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Add Parent Material Type</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form style={{ marginTop: '2rem' }}>
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Parent Material Type Name:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridCity">
                                    <InputText onChange={(e) => this.setState({ modalMaterialParentTypeName: e.target.value })}
                                        value={this.state.modalMaterialParentTypeName} placeholder="Material Type Name" />
                                </Form.Group>
                            </Form.Row> 
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalAddParentMaterialType}>Close</ReactButton>
                        <ReactButton variant="success" onClick={this.addParentMaterialTypeOK}>Add</ReactButton>
                    </Modal.Footer>
                </Modal> 

                <Modal
                    show={this.state.editModal}
                    onHide={this.showOrHideModalEditMaterialType}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Edit Material Type</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form style={{ marginTop: '2rem' }}>
                            <Form.Row>
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Material Type Name:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridCity">
                                    <InputText onChange={(e) => this.setState({ modalEditMaterialTypeName: e.target.value })}
                                        value={this.state.modalEditMaterialTypeName} placeholder="Material Type Name" />
                                </Form.Group>
                            </Form.Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalEditMaterialType}>Close</ReactButton>
                        <ReactButton variant="success" onClick={this.editMaterialTypeOK}>Save</ReactButton>
                    </Modal.Footer>
                </Modal>
 
                <Card style={{ backgroundColor: "white", width: '700px' }}> 
                    <div className="content-section implementation">
                        <div style={{ backgroundColor: "white", padding: "10px" }} hidden={this.state.hidden} >
                            <div className="p-grid p-fluid">
                                  <div className="p-col-12">
                                        <form>
                                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridMaterialType">
                                                <Form.Label style={{ marginTop: '0.5rem', fontWeight: 'bold', fontSize: '15px' }}>Material Type Name:</Form.Label>
                                                <Col sm="4">
                                                <InputText style={{ marginTop: '0.2rem', width: '100%' }} onChange={(e) => this.setState({ rootMaterialTypeName: e.target.value })} value={this.state.rootMaterialTypeName} placeholder="Material Type Name" />
                                                </Col>
                                              <Col sm="4">
                                                 <ReactButton type="button" style={{ width: '100%' }} onClick={this.saveMaterialType} variant="info">Add to Root</ReactButton>
                                                </Col>
                                            </Form.Group>
                                        </form>
                                    </div>              
                                </div>  
                        </div>

                        <ContextMenu model={this.state.splitButtonMenu2} ref={el => this.cm = el} onHide={() => this.setState({ selectedNodeKey: null })} />
                        <Toast ref={(el) => this.toast = el}></Toast>
                        <TreeTable value={this.state.nodes} expandedKeys={this.state.expandedKeys} emptyMessage="No Material Types Found"
                            selectionMode="single" selectionKeys={this.state.selectedNodeKey} onSelectionChange={this.handleSplitMenuChange}
                            onToggle={e => this.setState({ expandedKeys: e.value })} globalFilter={this.state.globalFilter} header={header}
                            contextMenuSelection={this.state.selectedNodeKey} onContextMenuSelectionChange={e => this.setState({ selectedNodeKey: e.value })}
                            onContextMenu={e => this.cm.show(e.originalEvent)}>
                            {/*<Column header={actionHeader} body={this.actionTemplate} headerStyle={{ width: '8em', textAlign: 'center' }} bodyStyle={{ textAlign: 'center', overflow: 'visible' }} />*/}
                            <Column field="materialTypeName" header="Material Type" bodyStyle={{ fontWeight: 'bold' }} expander />
                        </TreeTable>
                    </div>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        i18n: state.i18n,
        auth: state.auth
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(MaterialTypes)