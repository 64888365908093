import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Toast } from 'primereact/toast';
import { handleRequest } from '../../redux/config/env';
import { Modal , Button as BootButton} from 'react-bootstrap';
import CustomMaterialMenu from './ForComponents/CustomMaterialMenu';


export class ManagementLevel  extends Component {

    constructor() {
        super();
        this.state = {
            modalShow: false, 
            hidden: true,
            managementLevel: "",
            selectedManagementLevel: {id:0}, 
            buttonName: "Add",
            buttonClassName: "p-button-success",
            gelenData: null,
            tableList: [],
            siraNo: [  {"id": "1"},
            {"id": "1"},
            {"id": "1"}]
        };
        this.actionTemplate = this.actionTemplate.bind(this);
        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);
    };

    componentDidMount = async () => {
        const response = await handleRequest("GET", "/ManagementLevel/getallByCompany"); 

        console.log(this.props.prm)

       
        if (response.data.length !== 0){
            this.setState({
                tableList: response.data,
            });
        }
    };

    showSuccess() {
        this.toast.show({severity: 'success', summary: 'Success Message', detail: 'Successful.'});
    };

    showError(msg) {
        if (!msg) {
            msg = 'Something went wrong. Please check inputs.';
        }
        this.toast.show({ severity: 'error', summary: 'Error Message', detail: msg });
    };

  
  

    actionTemplate(rowData, column) {
        return  <React.Fragment> <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.changeButtonUpdate.bind(this)} />   </React.Fragment> ;
    };

    deleteModal = (row) => {
        
        this.setState({
            modalShow: true,
            selectedManagementLevel: row
        });
    }

    showOrHideModal = () => {
        this.setState({
            modalShow: !this.state.modalShow
        });
    }

    saveManagementModal = async () => {
        if (this.state.managementLevel !== ""){

            const obj = {
                managementLevelName : this.state.managementLevel,
                id: this.state.selectedManagementLevel.id 
            }
            var response  ;
            if(obj.id===0)
                response = await handleRequest("POST", "/ManagementLevel/add",obj);   
            else 
                 response = await handleRequest("POST", "/ManagementLevel/update",obj);    
    
            if(response.data!==null && response.data!==undefined){
                if(response.data==="SUCCESS-MANAGEMENTLEVEL-001")
               {
                this.changeButtonSave();
                this.restartTable();
                this.showSuccess();
               } 
               else  if(response.data==="ERROR-MANAGEMENTLEVEL-001") {
                this.showError("An error was occured please try again later !"); 
               }  else  if(response.data==="ERROR-MANAGEMENTLEVEL-002") { 
                this.showError("This record added before please change management level name !"); 
               }
            }
            
        }

        else{
            this.showError();
        }
    };

    restartTable = async () => {
        
      
        const response = await handleRequest("GET", "/ManagementLevel/getallByCompany");    


        if (response.data.length !== 0){
            this.setState({
                tableList: response.data,
            });
        }
    }



    delete = async (name) => {

        const obj = {
            id : this.state.selectedManagementLevel.id
        }

        await handleRequest("PUT", "/ManagementLevel/delete",obj);    

        this.restartTable();
        this.showSuccess();
        this.showOrHideModal();
    };

    changeButtonUpdate = (row) => {
        this.setState({
            managementLevel: row.managementLevelName,
            selectedManagementLevel: row,
            hidden: false,
            buttonName: "Update",
            buttonClassName: "p-button-warning"
        });
    };

    changeButtonSave = () => {
        this.setState({
            managementLevel: "",
            hidden: true,
            buttonName: "Add",
            buttonClassName: "p-button-success"
        });
       
    };

    changeButtonCancel = () => {
        this.setState({
            managementLevel: "",
            hidden: true,
            buttonName: "Add",
            buttonClassName: "p-button-success"
        });
        //this.showError();
    };

    render() {
        let actionHeader = <Button type="button" icon="pi pi-cog"></Button>
        return (
            <div>
                <Card style={{ width: '500px' }}>
                <div className="p-grid p-fluid">
                        <div className="p-col-12 p-md-4">
                            <div className="p-inputgroup">
                                <InputText onChange={(e) => this.setState({ managementLevel: e.target.value })} value={this.state.managementLevel} placeholder="Management Level" />
                                <Button id='save-managementlevel' className={this.state.buttonClassName} onClick={this.saveManagementModal} label={this.state.buttonName} />
                                <Button hidden={this.state.hidden} id='cancel-managementlevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />
                            </div>
                        </div>
                    </div>
                    <div className="content-section implementation">
                        <Toast ref={(el) => this.toast = el} />
                        <DataTable ref={(el) => this.dt = el} value={this.state.tableList} paginator={true} responsive={true} rowsPerPageOptions={[5,10,20]} rows={10} className="p-datatable-tableList"
                            selectionMode="single" selection={this.state.selectedManagementLevel} onSelectionChange={e => this.setState({ selectedManagementLevel: e.value })}>
                            <Column field="index" style={{ textAlign: 'center' }} sortable header="S/N" />                          
                            <Column field="managementLevelName" header="Management Level" filter={true} sortable /> 
                            <Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />
                        </DataTable>
                    </div>

                        <Modal
                                show={this.state.modalShow}
                                onHide={this.showOrHideModal}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">Delete  Management Level ?</Modal.Title>
                                </Modal.Header> 
                                <Modal.Body>
                                    <p>Selected management level will be deleted. Are you sure?</p> 
                                </Modal.Body> 
                                <Modal.Footer>
                                    <BootButton variant="secondary" onClick={this.showOrHideModal}>Cancel</BootButton>
                                    <BootButton variant="danger" onClick={this.delete} >Delete</BootButton>  
                                </Modal.Footer>
                            </Modal>
                   
                </Card>
            </div>
        );
    }
}

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagementLevel)