import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchSelectItem } from "../../../redux/actions/selectItemAction";
import { Table } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

export class ItemsTable extends Component {
  static propTypes = {
    selectItemReducer: PropTypes.shape({
      selectItemList: PropTypes.array.isRequired
    })
  };
  componentDidMount() {
    this.props.fetchSelectItem();
  }
  render() {
    return (
      <div>
        <Table responsive striped bordered hover size="sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Text ID</th>
              <th>Local Text</th>
            </tr>
          </thead>
          <tbody>
            {this.props.selectItemReducer.selectItemList.map((e, key) => {
              return (
                <tr key={key}>
                  <td>{key + 1}</td>
                  <td>{e.name}</td>
                  <FormattedMessage
                    id={e.name}
                    key={e.id}
                    defaultMessage={e.text}
                  >
                    {message => <td value={e.id}>{message}</td>}
                  </FormattedMessage>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = ({ selectItemReducer }) => {
  return {
    selectItemReducer
  };
};

const mapDispatchToProps = {
  fetchSelectItem
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemsTable);
