import React from 'react';
import { IconButton  } from "@material-ui/core";
import { Menu } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { ListItemIcon } from "@material-ui/core";
import DvrIcon from '@material-ui/icons/Dvr';
import { Typography } from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { FormattedMessage } from "react-intl";

export default ({ row, onSummaryRow, size, editBgColor, deleteBgColor }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const summaryRow = () => {
        if (onSummaryRow) {
            onSummaryRow(row);
            setAnchorEl(null);
        }
    };

    return (
        <div>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                size={size}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="menu"
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose} >

                <MenuItem onClick={summaryRow}>
                    <ListItemIcon>
                        <DvrIcon fontSize="small" color="primary" />
                    </ListItemIcon>
                    <Typography variant="inherit">
                    <FormattedMessage id="CustomMaterialMenuSummary" defaultMessage="Summary" />
                    </Typography>
                </MenuItem>
            </Menu>
        </div>

    );
};