/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card, Accordion } from 'react-bootstrap';
import { Table as TableBootstrap, Container, Col, Form, Row } from 'react-bootstrap';
import { Button as PrimeButton } from 'primereact/button';
import { Button as ReactButton } from 'react-bootstrap';
import { Button as AntdButton, Table } from 'antd';
import { InputText } from "primereact/inputtext";
import "react-toggle/style.css";
import { handleRequest } from '../../redux/config/env';
import { MultiSelect } from 'primereact/components/multiselect/MultiSelect';
import "../settings/css/DataTable.scss";
import { Modal } from "react-bootstrap";
import StoreLogSub from "./StoreLogSub";
import { Cascader, message, Upload } from "antd";
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import { InputNumber, Select, Divider, Input } from 'antd';
import { UploadOutlined, DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import { FilterOutlined } from '@ant-design/icons';
import { defineMessages, FormattedMessage } from "react-intl";

const { Option } = Select;

var messages = [];

class StoreLog extends Component {
  constructor(props) {
    super(props);

    this.state = {

      StockIdentifications: [],
      StockIdentificationsKayit: 0,

      StockAddressings: [],
      StockAddressingsKayit: 0,

      StockLogFileUpload: [],
      stockFilePath: "",
      AdressingPhotoFileUpload: [],
      adressingPhotoFilePath: "",
      LayoutFileUpload: [],
      layoutFilePath: "",

      fileList: [],

      stockGroupId: 0,
      stockCode: "",
      stockDescription: "",
      stockLocation: "",
      materialGroupId: 0,
      stockFilePath: "",
      barCode: "",
      qrCode: "",
      stockAddressing: [],

      StoringEquipments: [],

      id: 0,
      storeLogId: 0,
      sectionId: 0,
      shelfno: "",
      addressingCode: "",
      easting1: "",
      easting2: "",
      northing1: "",
      northing2: "",
      coordinatesAck: "",
      height: 0,
      heightTip: 0,
      widht: 0,
      widhtTip: 0,
      depth: 0,
      depthTip: 0,
      dimensionAck: "",
      capacity: 0,
      capacityTip: 0,
      storingEquipment: 0,
      storingEquipmentAck: "",
      barCode: "",
      qrCode: "",
      adressingPhotoFilePath: "",

      nodes: [],
      nodeSections: [],
      Sections: [],
      StockTypes: [],

      stockCode: "",
      stockDescription: "",
      stockLocation: "",
      stockFilePath: "",

      treeData: [],

      globalFilter: null,
      selectedDepartments: null,
      selectedDisciplines: null,
      disciplinesForFilter: [],
      storeLogId: "",
      StoreLogs: [],
      expandedRows: null,
      addModal: false,
      promptModal: false,
      department: "Select Department",
      discipline: "Select Discipline",
      visible: false,
      values: [],
      selectedItems: [],
      valueSections: [],
      selectedSectionItems: [],
      newItem: "",

      hiddenSave: false,
      hiddenUpdate: true,
      hiddenDataGiris: true,
      createNewButton: "Create New",
      createNewButtonAddressing: "Create New",

      //modalKontrol:true,
      StockAddressings: [],
      StockAddressingsKayit: 0,
      StockIdentifications: [],
      StockIdentificationsKayit: 0,
      hiddenDataGirisStockAddressing: true,
      hiddenDataGirisStockIdentification: false,

      StockLogFileUpload: [],
      stockFilePath: "",
      AdressingPhotoFileUpload: [],
      adressingPhotoFilePath: "",

      materialSelect: [],
      materialNames: [],
      optionMaterialSelects: [],

      pagination: {
        current: 1,
        pageSize: 5,
      },
      loading: false,

      kontrol:false,
    };

    this.onMaterialChange = this.onMaterialChange.bind(this);
    this.actionTemplate = this.actionTemplate.bind(this);
    this.renderDownloadFile = this.renderDownloadFile.bind(this);
    this.renderDownloadFileAdressingPhoto = this.renderDownloadFileAdressingPhoto.bind(this);
    this.renderDownloadFileLayout = this.renderDownloadFileLayout.bind(this);
  }

  componentDidMount = async () => {
    const { pagination } = this.state;
    this.fetch({ pagination });

    //this.getLanguageValue();
    this.getStockGroup();
    this.getSection();
    this.getMaterials();
    //this.getTreeMaterialType();
    this.getStockIdentifications();
    this.getStockAddressings();
    this.fillCodes();
  };

  getTreeMaterialType = async () => {
    const response = await handleRequest("GET", "/MaterialType/getTreeMaterialType");

    if (response.data.length === 0) {
      this.setState({
        treeData: [],
      });
    }

    if (response.data.length !== 0) {
      this.setState({
        treeData: response.data,
      });
    }
  }

  getLanguageValue = async () => {
    const newObj = {
      langAck: this.props.i18n.lang
    }
    const response = await handleRequest("POST", "/Language/getLanguageResult", newObj);
    if (response.data.length !== 0) {
      const columns = response.data;
      var options = [];
      var message = {};
      for (let index = 0; index < columns.length; index++) {
        options.push({ id: columns[index].stateId.toString(), defaultMessage: columns[index].stateValue.toString() });
        message[options[index].id] = options[index];
      } 
      messages = defineMessages(
        { message }
      );

      console.log("messages");
      console.log(messages);

      this.setState({
        kontrol:true,
      });
    }else{
        this.setState({
        kontrol:false,
      });
    }
  }

  getStockGroup = async () => {
    const response = await handleRequest("GET", "/StockGroup/getNodes");

    if (response.data.length === 0) {
      this.setState({
        nodes: []
      });
    }

    if (response.data.length !== 0) {
      this.setState({
        nodes: response.data
      });
    }
  }

  getSection = async () => {
    const response = await handleRequest("GET", "/StockSectionLog/getNodes");

    if (response.data.length === 0) {
      this.setState({
        nodeSections: []
      });
    }

    if (response.data.length !== 0) {
      this.setState({
        nodeSections: response.data
      });
    }
  }

  getStockIdentifications = async () => {
    var storeLogId = "-1";
    if (this.state.storeLogId != null && this.state.storeLogId != "") {
      storeLogId = this.state.storeLogId;
    }
    const newObj = {
      id: storeLogId
    }
    const response = await handleRequest("POST", "/StoreLog/getStoreLogResult", newObj);

    if (response.data.length !== 0) {

      this.setState({
        StockIdentifications: response.data,
        StockIdentificationsKayit: response.data.length,
        hiddenDataGirisStockIdentification: true
      });
    } else {
      this.setState({
        StockIdentifications: [],
        StockIdentificationsKayit: 0,
        hiddenDataGirisStockIdentification: false
      });
    }
  }

  getStockAddressings = async () => {
    var storeLogId = "-1";
    if (this.state.storeLogId != null && this.state.storeLogId != "") {
      storeLogId = this.state.storeLogId;
    }
    const newObj = {
      storeLogId: storeLogId
    }
    const response = await handleRequest("POST", "/StockAddressing/getStockAddressingResult", newObj);

    if (response.data.length !== 0) {
      this.setState({
        StockAddressings: response.data,
        StockAddressingsKayit: response.data.length,
      });
    } else {
      this.setState({
        StockAddressings: [],
        StockAddressingsKayit: 0,
      });
    }
  }

  createNewClientButton = async () => {
    if (this.state.createNewButton == "Create New") {
      this.setState({
        addModal: false,
        hiddenSave: false,
        hiddenUpdate: true,
        hiddenDataGiris: false,
        createNewButton: "Cancel",
        lastButton: "Save",
        createNewButtonVariant: "danger",
        hiddenDataGirisStockIdentification: false
      });
      this.refreshAccordions("StockIdentification");
    }
    else {
      this.setState({
        addModal: false,
        hiddenSave: true,
        hiddenUpdate: false,
        hiddenDataGiris: true,
        createNewButton: "Create New",
        lastButton: "Save",
        createNewButtonVariant: "info",
        hiddenDataGirisStockIdentification: false
      });
      this.refreshAccordions("StockIdentification");
    }
  }

  changeButtonCancel = () => {
    this.setState({
      hiddenSave: true,
      hiddenUpdate: false,
      hiddenDataGiris: true,
      createNewButton: "Create New",
      lastButton: "Save",
      createNewButtonVariant: "info",
    });
    this.resetInputs();
    this.refreshAccordions();
  };

  updateDone = async () => {
    if (this.state.StockIdentificationsKayit > 0) {
      var updateStoreLog = {
        id: this.state.StockIdentifications[0].id,
        stockGroupId: this.state.StockIdentifications[0].stockGroupId,
        stockCode: this.state.StockIdentifications[0].stockCode,
        stockDescription: this.state.StockIdentifications[0].stockDescription,
        stockLocation: this.state.StockIdentifications[0].stockLocation,
        materialGroupId: this.state.StockIdentifications[0].materialGroupId,
        stockFilePath: this.state.StockIdentifications[0].stockFilePath,
        barCode: this.state.StockIdentifications[0].barCode,
        qrCode: this.state.StockIdentifications[0].qrCode,
        stockAddressing: this.state.StockAddressings,
        materials: this.state.materialSelect,
      };

        var response = await handleRequest("POST", "/StoreLog/update", updateStoreLog);

      if (response.data !== null && response.data !== undefined) {
        if (response.data == "SUCCESS") {
          this.createNewClientButton();

          this.resetInputs();
          this.showSuccess();
          this.restartTable();
        }
        else {
          this.showError("An error was occured please try again later !");
        }
      }
      else {
        this.showError();
      }
    }
    else {
      this.showError();
    }
  };

  // rowExpansionTemplate() { 
  //   return <MNDTable row={this.state.StoreLogs} />; 
  // }

  actionTemplate(rowData) {
    return <React.Fragment> <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteStoreLog.bind(this)} onEditRow={this.editStoreLog.bind(this)} />   </React.Fragment>;
  };

  deleteStoreLog = (rowData) => {
    this.setState({
      deleteModal: true,
      storeLogId: rowData.id,
    });
  };

  deleteStoreLogOK = async () => {
    const deletedStoreLog = {
      id: this.state.storeLogId,
    };

    var response = await handleRequest("POST", "/StoreLog/delete", deletedStoreLog);

    if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS") {
        this.setState({
          deleteModal: false,
        });
        this.restartTable();
        this.showSuccess();
      }
      else {
        this.showError("An error was occured please try again later !");
      }
    } else {
      this.showError();
    }
  };

  restartTable = async () => {
    const { pagination } = this.state;
    this.fetch({ pagination });
  };

  showOrHideModalDeleteStoreLog = () => {
    this.setState({
      deleteModal: !this.state.deleteModal,
    });
  };

  showOrHideModalAddMaterialLog = () => {
    this.resetInputs();

    if (this.state.createNewButton != "Cancel") {
      this.setState({
        addModal: !this.state.addModal,
        hiddenDataGiris: true,
      });

      // if(this.state.modalKontrol==true){
      //   this.setState({
      //     addModal: true,
      //     hiddenDataGiris:true,
      //     modalKontrol:false,
      //   }); 
      // }else{
      //   this.createNewClientButton();
      // }
    } else {
      this.setState({
        createNewButton: "Create New",
        lastButton: "Save",
        createNewButtonVariant: "info",
        hiddenDataGiris: true,
      });
    }
  };

  showOrHideModalPromptStockLog = () => {
    this.resetInputs();
    this.setState({
      addModal: false,
      promptModal: !this.state.promptModal,
      department: "Select Department",
      discipline: "Select Discipline",
      createNewButton: "Create New",
    });
  };

  editStoreLog = async (row) => {
    this.createNewClientButton();

    await this.setState({
      addModal: false,
      hiddenSave: true,
      hiddenUpdate: false,
      hiddenDataGiris: false,
      hiddenCreateNew: false,
      createNewButton: "Cancel",
      createNewButtonVariant: "danger",
      hiddenDataGirisStockIdentification: false,
      createNewButtonAddressing: "Create New",
      createNewButtonAddressingVariant: "info",
      hiddenDataGirisStockAddressing: true,

      id: row.id,
      storeLogId: row.id,
      stockGroupId: row.stockGroupId,
      stockCode: row.stockCode,
      stockDescription: row.stockDescription,
      stockLocation: row.stockLocation,
      materialGroupId: row.materialGroupId,
      stockFilePath: row.stockFilePath,
      barCode: row.barCode,
      qrCode: row.qrCode,
      //materialSelect:row.materials
    });

    this.getStockIdentifications();
    this.getStockAddressings();
  };

  handleChangeStockType = (e) => {
    e.preventDefault();
    var idValue = 0;

    for (var z = 0; z < this.state.StockTypes.length; z++) {
      if (this.state.StockTypes[z].name === e.target.value) {
        idValue = this.state.StockTypes[z].id;
      }
    }

    this.setState({
      [e.target.name]: e.target.value,
      stockTypeId: idValue,
    });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({ visible: false });
    this.saveItem();
  };

  handleCancel = () => {
    this.setState({ visible: false });
    this.resetInputs();
  };

  saveItem = async () => {
    if (this.state.newItem.trim() !== "") {
      const newObj = {
        description: this.state.newItem,
      };
      await handleRequest("POST", "/StockGroup/add", newObj);
      this.getStockGroup();
      this.resetInputs();
    }
  };

  addStockIdentification = async () => {
    if (this.state.values.length != 0 && this.state.stockCode != "" && this.state.stockDescription != "") {
      var flag = true;
      const StockIdentifications = [];

      if (StockIdentifications.length > 0)
        flag = true;

      for (let index = 0; index < StockIdentifications.length; index++) {
        if (StockIdentifications[index].checkitem.length > 0) {
          flag = true;
        } else {
          flag = false;
          break;
        }
      }

      if (flag) {
        await this.setState({
          StockIdentificationsKayit: this.state.StockIdentificationsKayit + 1,
        })
        await this.setState(state => ({
          StockIdentifications: [...state.StockIdentifications, {
            key: this.state.StockIdentificationsKayit,
            id: this.state.storeLogId,
            index: this.state.StockIdentificationsKayit,
            stockGroupId: this.state.values[this.state.values.length - 1],
            stockCode: this.state.stockCode,
            stockDescription: this.state.stockDescription,
            stockLocation: this.state.stockLocation,
            materialGroupId: this.state.materialGroupId,
            stockFilePath: this.state.stockFilePath,
            barCode: this.state.barCode,
            qrCode: this.state.qrCode,
            materialNames: this.state.materialNames,
            stockGroupDescription: this.state.stockGroup,
          }]
        }));

        await this.setState({
          stockGroup: "",
          stockGroupDescription: "",
          stockGroupId: 0,
          stockCode: "",
          stockDescription: "",
          stockLocation: "",
          materialGroupId: 0,
          barCode: "",
          qrCode: "",

          materialNames: [],
          values: [],
          selectedItems: [],
          hiddenDataGirisStockIdentification: true,
          stockFilePath: "",
          StockLogFileUpload: [],
          fileList: [],
        });
      }
      this.showSuccess();
      this.refreshAccordions("StockPreservation");
    } else {
      this.showError();
    }
  };

  addStockAddressing = async () => {
    if (this.state.valueSections.length != 0 && this.state.shelfno != null && this.state.shelfno != "" &&
      this.state.addressingCode != null && this.state.addressingCode != "") {

      var flag = true;
      const StockAddressings = [];

      if (StockAddressings.length > 0)
        flag = true;

      for (let index = 0; index < StockAddressings.length; index++) {
        if (StockAddressings[index].checkitem.length > 0) {
          flag = true;
        } else {
          flag = false;
          break;
        }
      }

      if (flag) {
        await this.setState({
          StockAddressingsKayit: this.state.StockAddressingsKayit + 1,
        })

        var coordinatesAck = this.state.easting1 + ",  " + this.state.easting2 + ",  " + this.state.northing1 + ",  " + this.state.northing2;
        if (coordinatesAck == ",  ,  ,  ") {
          coordinatesAck = "";
        }

        var dimensionAck = "H: " + this.state.height + "   W: " + this.state.widht + "   D: " + this.state.depth;
        if (dimensionAck == "H: 0   W: 0   D: 0") {
          dimensionAck = "";
        }

        await this.setState(state => ({
          StockAddressings: [...state.StockAddressings, {
            key: this.state.StockAddressingsKayit,
            // id: this.state.storeLogId,
            index: this.state.StockAddressingsKayit,
            storeLogId: this.state.storeLogId,
            sectionId: parseInt(this.state.valueSections[this.state.valueSections.length - 1]),
            section: this.state.selectedSectionItems[this.state.selectedSectionItems.length - 1].label,
            shelfno: this.state.shelfno,
            addressingCode: this.state.addressingCode,
            easting1: this.state.easting1,
            easting2: this.state.easting2,
            northing1: this.state.northing1,
            northing2: this.state.northing2,
            coordinatesAck: coordinatesAck,
            height: this.state.height,
            heightTip: this.state.heightTip,
            widht: this.state.widht,
            widhtTip: this.state.widhtTip,
            depth: this.state.depth,
            depthTip: this.state.depthTip,
            dimensionAck: dimensionAck,
            capacity: this.state.capacity,
            capacityTip: this.state.capacityTip,
            storingEquipment: this.state.storingEquipment,
            storingEquipmentAck: this.state.storingEquipmentAck,
            barCode: this.state.barCode,
            qrCode: this.state.qrCode,
            adressingPhotoFilePath: this.state.adressingPhotoFilePath,
            layoutFilePath: this.state.layoutFilePath,
          }]
        }));

        await this.setState({
          sectionId: 0,
          shelfno: "",
          addressingCode: "",
          easting1: "",
          easting2: "",
          northing1: "",
          northing2: "",
          height: 0,
          heightTip: 0,
          widht: 0,
          widhtTip: 0,
          depth: 0,
          depthTip: 0,
          capacity: 0,
          capacityTip: 0,
          storingEquipment: 0,
          storingEquipmentAck: "",
          coordinatesAck: "",
          dimensionAck: "",
          adressingPhotoFilePath: "",
          layoutFilePath: "",
          barCode: "",
          qrCode: "",
          
          AdressingPhotoFileUpload: [],
          LayoutFileUpload: [],
          fileList: [],
        });
      }
      this.showSuccess();
    } else {
      this.showError();
    }
  };

  onChangeInput = ({ target: { value } }) => {
    this.setState({ newItem: value });
  };

  resetInputs = async () => {
    await this.setState({
      values: [],
      selectedItems: [],
      valueSections: [],
      selectedSectionItems: [],
      StockIdentifications: [],
      StockIdentificationsKayit: 0,

      StockAddressings: [],
      StockAddressingsKayit: 0,

      StockLogFileUpload: [],
      stockFilePath: "",
      AdressingPhotoFileUpload: [],
      adressingPhotoFilePath: "",
      LayoutFileUpload: [],
      layoutFilePath: "",

      fileList: [],

      stockGroupId: 0,
      stockCode: "",
      stockDescription: "",
      stockLocation: "",
      materialGroupId: 0,
      stockFilePath: "",
      barCode: "",
      qrCode: "",
      stockAddressing: [],

      id: 0,
      storeLogId: 0,
      sectionId: 0,
      shelfno: "",
      addressingCode: "",
      easting1: "",
      easting2: "",
      northing1: "",
      northing2: "",
      coordinatesAck: "",
      height: 0,
      heightTip: "",
      widht: 0,
      widhtTip: "",
      depth: 0,
      dimensionAck: "",
      depthTip: 0,
      capacity: 0,
      capacityTip: 0,
      storingEquipment: 0,
      storingEquipmentAck: "",
      barCode: "",
      qrCode: "",
      adressingPhotoFilePath: "",

      materialSelect: [],
      materialNames: [],
    });
  };

  saveStoreLogOK = async () => {
    if (this.state.values.length != 0 && this.state.stockCode != "" && this.state.stockDescription != "") {
      const newStockLog = {
        stockGroupId: this.state.values[this.state.values.length - 1],
        stockCode: this.state.stockCode,
        stockDescription: this.state.stockDescription,
        stockLocation: this.state.stockLocation,
        materialGroupId: this.state.materialGroupId,
        stockFilePath: this.state.stockFilePath,
        barCode: this.state.barCode,
        qrCode: this.state.qrCode,
        materials: this.state.materialSelect,
      };

      var response = await handleRequest("POST", "/StoreLog/add", newStockLog);

      if (response.data !== null && response.data !== undefined) {
        if (response.data === "SUCCESS") {
          this.resetInputs();
          this.showSuccess();
          this.restartTable();
        }
        else {
          this.showError("An error was occured please try again later !");
        }
      } else {
        this.showError();
      }
      this.setState({
        promptModal: false,
      });
    } else {
      this.showError();
    }
  };

  addStoreLog = async () => {
    if (this.state.StockIdentificationsKayit > 0) {
      var newStoreLog = {
        stockGroupId: this.state.StockIdentifications[0].stockGroupId,
        stockCode: this.state.StockIdentifications[0].stockCode,
        stockDescription: this.state.StockIdentifications[0].stockDescription,
        stockLocation: this.state.StockIdentifications[0].stockLocation,
        materialGroupId: this.state.StockIdentifications[0].materialGroupId,
        stockFilePath: this.state.StockIdentifications[0].stockFilePath,
        barCode: this.state.StockIdentifications[0].barCode,
        qrCode: this.state.StockIdentifications[0].qrCode,
        stockAddressing: this.state.StockAddressings,
        materials: this.state.materialSelect,
      };

      var response = await handleRequest("POST", "/StoreLog/add", newStoreLog);
      if (response.data !== null && response.data !== undefined) {
        if (response.data === "SUCCESS") {
          this.setState({
            hiddenDataGiris: true,
            createNewButton: "Create New",
            lastButton: "Save",
            createNewButtonVariant: "info",
          });
          this.showSuccess();
          this.refreshAccordions();
          this.resetInputs();
          this.restartTable();
        }
        else {
          this.showError("An error was occured please try again later !");
        }
      } else {
        this.showError();
      }
      this.setState({
        promptModal: false,
      });
    } else {
      this.showError();
    }
  };

  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeHeightTip = (e) => {
    e.preventDefault();

    var heightTipAck = "";
    if (Boolean(e.target.value)) {
      if (e.target.value == "1") {
        heightTipAck = "Limitless";
      } else if (e.target.value == "2") {
        heightTipAck = "Cm";
      } else if (e.target.value == "3") {
        heightTipAck = "Meter";
      }
    }

    this.setState({
      [e.target.name]: e.target.value,
      heightTipAck: heightTipAck,
    });
  };

  handleChangeWidhtTip = (e) => {
    e.preventDefault();

    var widhtTipAck = "";
    if (Boolean(e.target.value)) {
      if (e.target.value == "1") {
        widhtTipAck = "Limitless";
      } else if (e.target.value == "2") {
        widhtTipAck = "Cm";
      } else if (e.target.value == "3") {
        widhtTipAck = "Meter";
      }
    }

    this.setState({
      [e.target.name]: e.target.value,
      widhtTipAck: widhtTipAck,
    });
  };

  handleChangeDepthTip = (e) => {
    e.preventDefault();

    var depthTipAck = "";
    if (Boolean(e.target.value)) {
      if (e.target.value == "1") {
        depthTipAck = "Limitless";
      } else if (e.target.value == "2") {
        depthTipAck = "Cm";
      } else if (e.target.value == "3") {
        depthTipAck = "Meter";
      }
    }

    this.setState({
      [e.target.name]: e.target.value,
      depthTipAck: depthTipAck,
    });
  };

  handleChangeCapacityTip = (e) => {
    e.preventDefault();

    var capacityTipAck = "";
    if (Boolean(e.target.value)) {
      if (e.target.value == "1") {
        capacityTipAck = "Kg";
      } else if (e.target.value == "2") {
        capacityTipAck = "Ton";
      } else if (e.target.value == "3") {
        capacityTipAck = "Liter";
      }
    }

    this.setState({
      [e.target.name]: e.target.value,
      capacityTipAck: capacityTipAck,
    });
  };

  handleChangeStoringEquipment = (e) => {
    var deger = "";
    for (var z = 0; z < this.state.StoringEquipments.length; z++) {
      if (this.state.StoringEquipments[z].id == e) {
        deger = this.state.StoringEquipments[z].name;
      }
    }
    this.setState({
      storingEquipmentAck: deger,
      storingEquipment: e,
    });
  };

  addStockAddressingShow = () => {
    if (this.state.createNewButtonAddressing == "Create New") {
      this.setState({
        createNewButtonAddressing: "Cancel",
        createNewButtonAddressingVariant: "danger",
        hiddenDataGirisStockAddressing: false
      });
    } else {
      this.setState({
        createNewButtonAddressing: "Create New",
        createNewButtonAddressingVariant: "info",
        hiddenDataGirisStockAddressing: true
      });
    }
  }

  showSuccess() {
    message.success("Successful")
  };

  showError(msg) {
    if (!msg) {
      msg = 'Something went wrong. Please check inputs.';
    }
    message.error(msg)
  };

  refreshAccordions = (deger) => {
    this.setState({
      hiddenDataGirisStockAddressing: true,
    });
    if (deger == 'StockIdentification') {
      document.getElementById('StockIdentification').className = "collapse show";
    } else {
      document.getElementById('StockIdentification').className = "collapse";
    }

    if (deger == 'StockPreservation') {
      document.getElementById('StockPreservation').className = "collapse show";
    } else {
      document.getElementById('StockPreservation').className = "collapse";
    }

    document.getElementById('StockAddressing').className = "collapse";
  }

  setDateFormat(d) { //01/02/2011 ->2011-02-01
    if (d != null) {
      var dt = new Date(d);
      return ('' + dt.getFullYear().toString().padStart(4, '0') + '-' + (dt.getMonth() + 1).toString().padStart(2, '0') + '-' + dt.getDate().toString().padStart(2, '0'));
    } else return '';
  }

  renderDownloadFile(rowData) {
    if (rowData.stockFilePath !== null && rowData.stockFilePath !== "" && rowData.stockFilePath !== undefined) {
      return <React.Fragment>
        <AntdButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.downloadFile(rowData.stockFilePath)} type="primary" shape="round" icon={<DownloadOutlined />} >
        </AntdButton>
      </React.Fragment>
    }
    return "";
  };

  renderDownloadFileLayout(rowData) {
    if (rowData.layoutFilePath !== null && rowData.layoutFilePath !== "" && rowData.layoutFilePath !== undefined) {
      return <React.Fragment>
        <AntdButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.downloadFile(rowData.layoutFilePath)} type="primary" shape="round" icon={<DownloadOutlined />} >
        </AntdButton>
      </React.Fragment>
    }
    return "";
  };

  renderDownloadFileAdressingPhoto(rowData) {
    if (rowData.adressingPhotoFilePath !== null && rowData.adressingPhotoFilePath !== "" && rowData.adressingPhotoFilePath !== undefined) {
      return <React.Fragment>
        <AntdButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.downloadFile(rowData.adressingPhotoFilePath)} type="primary" shape="round" icon={<DownloadOutlined />} >
        </AntdButton>
      </React.Fragment>
    }
    return "";
  };

  downloadFile = async (url) => {
    window.location.href = url;
  }

  onMaterialChange(event) {
    var selectNames = [];
    var flagArray = event.value;

    flagArray.forEach(element => {
      for (var z = 0; z < this.state.optionMaterialSelects.length; z++) {
        if (this.state.optionMaterialSelects[z].value == element) {
          selectNames.push(this.state.optionMaterialSelects[z].label + ", ");
        }
      }
    });

    this.setState({
      materialSelect: event.value,
      materialNames: selectNames
    });
  }

  async getMaterials() {
    const response = await handleRequest("GET", "/MaterialIdentification/getAll");
    if (response.data.length !== 0) {
      const columns = response.data;
      const options = columns.map(function (row) {
        return { label: row.materialName, value: row.id }
      })
      this.setState({
        optionMaterialSelects: options
      });
    } else {
      this.setState({
        optionMaterialSelects: [],
      });
    }
  }

  addStoringEquipments = async () => {
    const { itemStoringEquipments } = this.state;
    if (itemStoringEquipments != null && itemStoringEquipments != "" && itemStoringEquipments != undefined) {
      const newItem = {
        name: itemStoringEquipments,
        type: "StoringEquipment"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
        itemStoringEquipments: '',
      });
      this.fillCodes();
    } else {
      this.showError();
    }
  };

  fillCodes = async () => {
    const response = await handleRequest("GET", "/Code/type/StoringEquipment"); // Storing Equipment  

    if (response.data !== null && response.data !== undefined) {
      this.setState({
        StoringEquipments: response.data
      });
    }
  }

  onChangeStoreGroup = (value, data) => {
    this.setState({
      values: value,
      selectedItems: data,
    });
  };

  onChangeSection = (value, data) => {
    this.setState({
      valueSections: value,
      selectedSectionItems: data,
    });
  };

  onChangeStoreGroupFilter = async (value, data) => {
    this.setState({
      values: value,
      selectedItems: data,
    });

    if (value.length != 0) {
      await this.setState({
        stockGroupId: value[value.length - 1],
        stockGroupDescriptionColor: '#1890ff',
      })
    } else {
      await this.setState({
        stockGroupId: 0,
        stockGroupDescriptionColor: "#bfbfbf",
      })
    }

    const { pagination } = this.state;
    this.fetch({ pagination });
  };

  onChangeStockCode = async (event) => {
    await this.setState({ stockCode: event.target.value })

    if (this.state.stockCode != null && this.state.stockCode != undefined && this.state.stockCode != "") {
      this.setState({ stockCodeColor: '#1890ff' })
    } else {
      this.setState({ stockCodeColor: '#bfbfbf' })
    }

    const { pagination } = this.state;
    this.fetch({ pagination });
  };

  onChangeStockDescription = async (event) => {
    await this.setState({ stockDescription: event.target.value })

    if (this.state.stockDescription != null && this.state.stockDescription != undefined && this.state.stockDescription != "") {
      this.setState({ stockDescriptionColor: '#1890ff' })
    } else {
      this.setState({ stockDescriptionColor: '#bfbfbf' })
    }

    const { pagination } = this.state;
    this.fetch({ pagination });
  };

  onChangeStockLocation = async (event) => {
    await this.setState({ stockLocation: event.target.value })

    if (this.state.stockLocation != null && this.state.stockLocation != undefined && this.state.stockLocation != "") {
      this.setState({ stockLocationColor: '#1890ff' })
    } else {
      this.setState({ stockLocationColor: '#bfbfbf' })
    }

    const { pagination } = this.state;
    this.fetch({ pagination });
  };

  onMaterialChangeFilter = async (event) => {
    await this.setState({
      materialSelect: event.value,
      pagination: {
        current: 1,
      },
    });

    if (this.state.materialSelect.length > 0) {
      this.setState({ materialSelectColor: '#1890ff' })
    } else {
      this.setState({ materialSelectColor: '#bfbfbf' })
    }

    const { pagination } = this.state;
    this.fetch({ pagination });
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.fetch({ sortField: sorter.field, sortOrder: sorter.order, pagination, filters, });
  };

  fetch = async (params = {}) => {
    this.setState({ loading: true });
    const newObj = {
      id: 0,
      firstResult: params.pagination.current,
      maxResults: params.pagination.pageSize,
      stockGroupId: this.state.stockGroupId,
      stockCode: this.state.stockCode,
      stockDescription: this.state.stockDescription,
      stockLocation: this.state.stockLocation,
      materials: this.state.materialSelect,

    }
    const response = await handleRequest("POST", "/StoreLog/getStoreLogPaging", newObj);

    if (response.data.length !== 0) {
      this.setState({
        loading: false,
        StoreLogs: response.data.data,
        pagination: {
          ...params.pagination,
          total: response.data.totalRecords,
        },
      });
    } else {
      this.setState({
        loading: false,
        StoreLogs: [],
        pagination: {
          ...params.pagination,
          total: 0,
        },
      });
    }
  };

  render() {
    const props = {
      action: `${API_BASE}/File/upload`,
      listType: 'picture',
      defaultFileList: [...this.state.fileList],
    };

    let actionHeader = (
      <PrimeButton type="button" icon="pi pi-cog"></PrimeButton>
    );

    const expandedRowRender = (data) => {
      console.log("Data");
      console.log(data);
      return <StoreLogSub row={data} />;
    }

    const renderDownloadFileRender = (rowData) => {
      if (rowData !== null && rowData !== "" && rowData !== undefined) {
        return <React.Fragment>
          <AntdButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.downloadFile(rowData)} type="primary" shape="round" icon={<DownloadOutlined />} >
          </AntdButton>
        </React.Fragment>
      }
      return "";
    }


    const columns = [ 
      {
        key: "id",
        title: "",
        render: this.actionTemplate
      },
      {
        dataIndex: "index",
        key: "index",
        title: "S/N",
      },
      {
        dataIndex: "stockGroupDescription",
        key: "stockGroupDescription",
        title: "Stock Group",
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Cascader
              style={{ width: "100%" }}
              value={this.state.values}
              options={this.state.nodes}
              onChange={this.onChangeStoreGroupFilter}
              changeOnSelect
            />
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.stockGroupDescriptionColor }} />
      },
      {
        dataIndex: "stockCode",
        key: "stockCode",
        title: "Stock Code",
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input style={{ flex: 'auto' }} value={this.state.stockCode} placeholder="Stock Code"
              onChange={this.onChangeStockCode}
            />
          </div>),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.stockCodeColor }} />
      },

      {
        dataIndex: "stockDescription",
        key: "stockDescription",
        title: "Stock Description",
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input style={{ flex: 'auto' }} value={this.state.stockDescription} placeholder="Stock Description"
              onChange={this.onChangeStockDescription}
            />
          </div>),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.stockDescriptionColor }} />
      },
      {
        dataIndex: "stockTypeId",
        key: "stockTypeId",
        title: "Stocking Type",
      },
      {
        dataIndex: "stockLocation",
        key: "stockLocation",
        title: "Location",
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <Input style={{ flex: 'auto' }} value={this.state.stockLocation} placeholder="Stock Location"
              onChange={this.onChangeStockLocation}
            />
          </div>),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.stockLocationColor }} />
      },
      {
        dataIndex: "materialGroupId",
        key: "materialGroupId",
        title: "Material Group",
      },
      {
        dataIndex: "materialNames",
        key: "materialNames",
        title: "Materials",
        filterDropdown: () => (
          <div style={{ padding: 8 }}>
            <MultiSelect className="p-column-filter" value={this.state.materialSelect}
              options={this.state.optionMaterialSelects} onChange={this.onMaterialChangeFilter} />
          </div>
        ),
        filterIcon: filtered => <FilterOutlined style={{ color: this.state.materialSelectColor }} />
      },
      // {
      //   dataIndex: "qrCode",
      //   key: "qrCode",
      //   title: "QR Code",
      // },
      {
        dataIndex: "stockFilePath",
        key: "stockFilePath",
        title: "Photo", 
        render: (record) => (renderDownloadFileRender(record))
      },
    ];

    return (
      <div style={{ backgroundColor: "white", padding: "10px" }}>

        <Modal
          show={this.state.deleteModal}
          onHide={this.showOrHideModalDeleteStoreLog}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Delete This?
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
              Do you want to delete this Stock Log Data?
              </p>
            <p style={{ textAlign: "center", color: "black" }}>
              This process will delete all related datas.
              </p>
          </Modal.Body>
          <Modal.Footer>
            <ReactButton
              variant="secondary"
              onClick={this.showOrHideModalDeleteStoreLog}
            >
              Close
              </ReactButton>
            <ReactButton variant="danger" onClick={this.deleteStoreLogOK}>
              Delete
              </ReactButton>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.addModal}
          onHide={this.showOrHideModalAddMaterialLog}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              New Record
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ReactButton
              onClick={this.showOrHideModalPromptStockLog}
              style={{ width: "100%", marginBottom: "2rem" }}
              variant="success"
            >
              Prompt Record
              </ReactButton>
            <ReactButton
              onClick={this.createNewClientButton}
              style={{ width: "100%", marginBottom: "1rem" }}
              variant="warning"
            >
              Normal Record
              </ReactButton>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.visible}
          onHide={this.handleCancel}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              New Stock Group
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form style={{ marginTop: "2rem" }}>
              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label style={{ marginTop: "0.5rem" }}>
                    New Stock Group:
                    </Form.Label>
                </Form.Group>

                <Form.Group as={Col}>
                  <InputText
                    style={{ width: "100%" }}
                    onChange={this.onChangeInput}
                    value={this.state.newItem}
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <ReactButton variant="secondary" onClick={this.handleCancel}>
              Close
              </ReactButton>
            <ReactButton variant="info" onClick={this.handleOk}>
              Add
              </ReactButton>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.promptModal}
          onHide={this.showOrHideModalPromptStockLog}
          aria-labelledby="contained-modal-title-vcenter"
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              New Record
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ backgroundColor: "white", padding: "10px" }} hidden={this.state.hiddenModalIdentification} >
              <div className="p-grid p-fluid">
                <div className="p-col-12">
                  <form>
                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Form.Label column sm="3">
                        Stock Group:
                      </Form.Label>
                      <Col sm="5">
                        <Cascader
                          style={{ width: "100%" }}
                          value={this.state.values}
                          options={this.state.nodes}
                          onChange={this.onChangeStoreGroup}
                          changeOnSelect
                        />
                      </Col>
                      <Col sm="2">
                        {/* <ReactButton onClick={this.showModal} type="primary" style={{ width: "100%", marginTop: "0.2rem" }}> 
                        Add New
                      </ReactButton> */}
                      </Col>
                      <Col sm="2">
                      </Col>
                    </Form.Group>

                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Form.Label column sm="3">
                        Stock Code:
                      </Form.Label>
                      <Col sm="5">
                        <InputText
                          style={{ width: "100%" }}
                          onChange={(e) =>
                            this.setState({ stockCode: e.target.value })
                          }
                          value={this.state.stockCode} />
                      </Col>
                      <Col sm="4">
                      </Col>
                    </Form.Group>

                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Form.Label column sm="3">
                        Stock Description:
                      </Form.Label>
                      <Col sm="5">
                        <InputText
                          style={{ width: "100%" }}
                          onChange={(e) =>
                            this.setState({
                              stockDescription: e.target.value,
                            })
                          }
                          value={this.state.stockDescription}
                        />
                      </Col>
                      <Col sm="4">
                      </Col>
                    </Form.Group>

                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Form.Label column sm="3">
                        Location:
                      </Form.Label>
                      <Col sm="5">
                        <InputText
                          style={{ width: "100%" }}
                          onChange={(e) =>
                            this.setState({
                              stockLocation: e.target.value,
                            })
                          }
                          value={this.state.stockLocation}
                        />
                      </Col>
                      <Col sm="4">
                      </Col>
                    </Form.Group>

                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Form.Label column sm="3">
                        Materials:
                      </Form.Label>
                      <Col sm="5">
                        <MultiSelect className="p-column-filter" value={this.state.materialSelect}
                          options={this.state.optionMaterialSelects} onChange={this.onMaterialChange} />
                      </Col>
                      <Col sm="4">
                      </Col>
                    </Form.Group>

                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Col sm="3"></Col>
                      <Col sm="5">
                        <Upload {...props}
                          showUploadList={true}
                          fileList={this.state.StockLogFileUpload}
                          onChange={({ fileList }) => {
                            if (fileList.length == 1) {
                              var stockFilePath = "";
                              if (Boolean(fileList[0].response)) {
                                stockFilePath = fileList[0].response.url;
                              }
                              this.setState({
                                StockLogFileUpload: fileList,
                                stockFilePath: stockFilePath,
                              });
                            }
                          }}
                          beforeUpload={(fileList) => {
                            if (this.state.StockLogFileUpload.length > 0) {
                              message.error('You have been already choose a file! For change firstly remove the uploaded file.');
                              return false;
                            }
                            return true;
                          }}
                          onRemove={({ fileList }) => {
                            this.setState({
                              StockLogFileUpload: [],
                              stockFilePath: "",
                            })
                          }
                          }>
                          <AntdButton>
                            <UploadOutlined /> Attach photo of material
                    </AntdButton>
                        </Upload>
                      </Col>
                      <Col sm="4">
                      </Col>
                    </Form.Group>

                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Col sm="3"></Col>
                      <Col sm="5">
                        <ReactButton variant="success" style={{ width: "100%" }}>Barcode</ReactButton>
                      </Col>
                      <Col sm="4">
                      </Col>
                    </Form.Group>

                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Col sm="3"></Col>
                      <Col sm="5">
                        <ReactButton variant="success" style={{ width: "100%" }}>QR Code</ReactButton>
                      </Col>
                      <Col sm="4">
                      </Col>
                    </Form.Group>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <ReactButton
              variant="secondary"
              onClick={this.showOrHideModalPromptStockLog}
            >
              Close
              </ReactButton>
            <ReactButton variant="info" onClick={this.saveStoreLogOK}>
              Save
              </ReactButton>
          </Modal.Footer>
        </Modal>

        <div style={{ padding: "8px 16px 8px 24px" }}>
          <Row>
            <Col xs={9}></Col>
            <Col xs={3}>
              <ReactButton onClick={this.showOrHideModalAddMaterialLog} style={{ width: '100%' }} variant={this.state.createNewButtonVariant} >
                {this.state.createNewButton}
              </ReactButton>
            </Col>
          </Row>
        </div>
        <br />
        <div hidden={this.state.hiddenDataGiris} className="col-sm-12">
          <br />
          <Accordion defaultActiveKey="0">
            <Card>
              <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row>
                  <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }} as="h5">&#x25bc; Stock Identification</Card.Header></Col>
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse id="StockIdentification" eventKey="0" >
                <Card.Body>
                  <Form>
                    <div style={{ backgroundColor: "white", padding: "10px" }} >
                      <div className="p-grid p-fluid">
                        <div className="p-col-12">
                          <form>
                            <div hidden={this.state.hiddenDataGirisStockIdentification}>

                              <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Form.Label column sm="3">
                                <FormattedMessage id="StoreLog.StokGroup" />
                                </Form.Label>
                                <Col sm="5">
                                  <Cascader
                                    style={{ width: "100%" }}
                                    value={this.state.values}
                                    options={this.state.nodes}
                                    onChange={this.onChangeStoreGroup}
                                    changeOnSelect
                                  />
                                </Col>
                                <Col sm="2">
                                  {/* <ReactButton onClick={this.showModal} type="primary" style={{ width: "100%", marginTop: "0.2rem" }}> 
                        Add New
                      </ReactButton> */}
                                </Col>
                                <Col sm="2">
                                </Col>
                              </Form.Group>

                              <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Form.Label column sm="3">   
                                {/* <FormattedMessage {...messages.message.TEST} />  */}
                                  <FormattedMessage id="StoreLog.StockCode"/>  
                                </Form.Label>
                                <Col sm="5">
                                  <InputText
                                    style={{ width: "100%" }}
                                    onChange={(e) =>
                                      this.setState({ stockCode: e.target.value })
                                    }
                                    value={this.state.stockCode} />
                                </Col>
                                <Col sm="4">
                                </Col>
                              </Form.Group>

                              <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Form.Label column sm="3">
                                  Stock Description:
                      </Form.Label>
                                <Col sm="5">
                                  <InputText
                                    style={{ width: "100%" }}
                                    onChange={(e) =>
                                      this.setState({
                                        stockDescription: e.target.value,
                                      })
                                    }
                                    value={this.state.stockDescription}
                                  />
                                </Col>
                                <Col sm="4">
                                </Col>
                              </Form.Group>

                              <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Form.Label column sm="3">
                                  Location:
                      </Form.Label>
                                <Col sm="5">
                                  <InputText
                                    style={{ width: "100%" }}
                                    onChange={(e) =>
                                      this.setState({
                                        stockLocation: e.target.value,
                                      })
                                    }
                                    value={this.state.stockLocation}
                                  />
                                </Col>
                                <Col sm="4">
                                </Col>
                              </Form.Group>

                              <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Form.Label column sm="3">
                                  Materials:
                      </Form.Label>
                                <Col sm="5">
                                  <MultiSelect className="p-column-filter" value={this.state.materialSelect}
                                    options={this.state.optionMaterialSelects} onChange={this.onMaterialChange} />
                                </Col>
                                <Col sm="4">
                                </Col>
                              </Form.Group>

                              <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="3"></Col>
                                <Col sm="5">
                                  <Upload {...props}
                                    showUploadList={true}
                                    fileList={this.state.StockLogFileUpload}
                                    onChange={({ fileList }) => {
                                      if (fileList.length == 1) {
                                        var stockFilePath = "";
                                        if (Boolean(fileList[0].response)) {
                                          stockFilePath = fileList[0].response.url;
                                        }
                                        this.setState({
                                          StockLogFileUpload: fileList,
                                          stockFilePath: stockFilePath,
                                        });
                                      }
                                    }}
                                    beforeUpload={(fileList) => {
                                      if (this.state.StockLogFileUpload.length > 0) {
                                        message.error('You have been already choose a file! For change firstly remove the uploaded file.');
                                        return false;
                                      }
                                      return true;
                                    }}
                                    onRemove={({ fileList }) => {
                                      this.setState({
                                        StockLogFileUpload: [],
                                        stockFilePath: "",
                                      })
                                    }
                                    }>
                                    <AntdButton>
                                      <UploadOutlined /> Attach photo of material
                    </AntdButton>
                                  </Upload>
                                </Col>
                                <Col sm="4">
                                </Col>
                              </Form.Group>

                              <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="3"></Col>
                                <Col sm="5">
                                  <ReactButton variant="info" style={{ width: "100%" }}>Barcode</ReactButton>
                                </Col>
                                <Col sm="4">
                                </Col>
                              </Form.Group>

                              <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="3"></Col>
                                <Col sm="5">
                                  <ReactButton variant="info" style={{ width: "100%" }}>QR Code</ReactButton>
                                </Col>
                                <Col sm="4">
                                </Col>
                              </Form.Group>

                              <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridAdds">
                                <Col sm="3">
                                </Col>
                                <Col sm="5">
                                  <ReactButton onClick={this.addStockIdentification} style={{ width: '100%' }} variant="success">Add</ReactButton>
                                </Col>
                                <Col sm="3">
                                </Col>
                              </Form.Group>
                            </div>

                            <div className="content-section implementation">
                              <TableBootstrap responsive striped bordered hover>
                                <thead style={{ textAlign: 'center' }}>
                                  <tr>
                                    <th>S/N</th>
                                    <th>Stock Group</th>
                                    <th>Stock Code</th>
                                    <th>Stock Description</th>
                                    <th>Location</th>
                                    <th>Materials</th>
                                    <th>Photo of Stock</th>
                                  </tr>
                                </thead>
                                <tbody style={{ textAlign: 'center' }}>
                                  {this.state.StockIdentifications.map(idetification => (
                                    <tr key={idetification.index}>
                                      <td>{idetification.index}</td>
                                      <td>{idetification.stockGroupDescription}</td>
                                      <td>{idetification.stockCode}</td>
                                      <td>{idetification.stockDescription}</td>
                                      <td>{idetification.stockLocation}</td>
                                      <td>{idetification.materialNames}</td>
                                      <td>{this.renderDownloadFile(idetification)}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </TableBootstrap>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </Form>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <br />
          <Accordion defaultActiveKey="1">
            <Card>
              <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row>
                  <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }}
                    as="h5">&#x25bc; Stock Preservation</Card.Header></Col>
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse id="StockPreservation" eventKey="0">
                <Card.Body>
                  <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>

                  </Container>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <br />
          <Accordion defaultActiveKey="2">
            <Card>
              <Accordion.Toggle style={{ backgroundColor: '#E0E4FF' }} as={Card.Header} eventKey="0">
                <Row>
                  <Col><Card.Header style={{ backgroundColor: '#E0E4FF', color: '#5d78ff', margin: '10px', fontWeight: 'bold' }}
                    as="h5">&#x25bc; Stock Addressing</Card.Header></Col>
                </Row>
              </Accordion.Toggle>
              <Accordion.Collapse id="StockAddressing" eventKey="0">
                <Card.Body>
                  <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                    <Row>
                      <Col xs={9}></Col>
                      <Col xs={3}>
                        <ReactButton onClick={this.addStockAddressingShow} style={{ width: '100%' }} variant={this.state.createNewButtonAddressingVariant} >
                          {this.state.createNewButtonAddressing}  </ReactButton>
                      </Col>
                    </Row>
                    <br />
                    <br />

                    <div hidden={this.state.hiddenDataGirisStockAddressing}>
                      <div style={{ backgroundColor: "white", padding: "10px", border: '1px solid #1ab394' }}>
                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridSectionAdds">
                          <Form.Label column sm="2" >Section</Form.Label>
                          <Col sm="4">

                            <Cascader
                              style={{ width: '100%' }}
                              value={this.state.valueSections}
                              options={this.state.nodeSections}
                              onChange={this.onChangeSection}
                              changeOnSelect
                            />
                          </Col>
                          <Col sm="2">
                            <Upload {...props}
                              showUploadList={true}
                              fileList={this.state.AdressingPhotoFileUpload}
                              onChange={({ fileList }) => {
                                if (fileList.length == 1) {
                                  var adressingPhotoFilePath = "";
                                  if (Boolean(fileList[0].response)) {
                                    adressingPhotoFilePath = fileList[0].response.url;
                                  }
                                  this.setState({
                                    AdressingPhotoFileUpload: fileList,
                                    adressingPhotoFilePath: adressingPhotoFilePath,
                                  });
                                }
                              }}
                              beforeUpload={(fileList) => {
                                if (this.state.AdressingPhotoFileUpload.length > 0) {
                                  message.error('You have been already choose a file! For change firstly remove the uploaded file.');
                                  return false;
                                }
                                return true;
                              }}
                              onRemove={({ fileList }) => {
                                this.setState({
                                  AdressingPhotoFileUpload: [],
                                  adressingPhotoFilePath: "",
                                })
                              }
                              }>
                              <AntdButton>
                                <UploadOutlined /> Photo
                    </AntdButton>
                            </Upload>
                          </Col>
                          <Col sm="1">
                          </Col>
                          <Col sm="3">
                            <Upload {...props}
                              showUploadList={true}
                              fileList={this.state.LayoutFileUpload}
                              onChange={({ fileList }) => {
                                if (fileList.length == 1) {
                                  var layoutFilePath = "";
                                  if (Boolean(fileList[0].response)) {
                                    layoutFilePath = fileList[0].response.url;
                                  }
                                  this.setState({
                                    LayoutFileUpload: fileList,
                                    layoutFilePath: layoutFilePath,
                                  });
                                }
                              }}
                              beforeUpload={(fileList) => {
                                if (this.state.LayoutFileUpload.length > 0) {
                                  message.error('You have been already choose a file! For change firstly remove the uploaded file.');
                                  return false;
                                }
                                return true;
                              }}
                              onRemove={({ fileList }) => {
                                this.setState({
                                  LayoutFileUpload: [],
                                  layoutFilePath: "",
                                })
                              }
                              }>
                              <AntdButton>
                                <UploadOutlined />Attach Layout
                      </AntdButton>
                            </Upload>
                          </Col>
                        </Form.Group>

                        <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridAdds">
                          <Form.Label column sm="2" >Shelf no</Form.Label>
                          <Col sm="4">
                            <Form.Control name="shelfno" value={this.state.shelfno} onChange={this.handleChange} />
                          </Col>
                          <Col sm="3">
                          </Col>
                        </Form.Group>

                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                          <Form.Label column sm="2">
                            Code
                      </Form.Label>
                          <Col sm="4">
                            <Form.Control name="addressingCode" value={this.state.addressingCode} onChange={this.handleChange} />
                          </Col>
                          <Col sm="3">
                          </Col>
                        </Form.Group>
                      </div>

                      <br />
                      <Form.Label column sm="2" style={{ fontWeight: 'bold' }} >
                        Coordinates
                  </Form.Label>
                      <div style={{ backgroundColor: "white", padding: "10px", border: '1px solid #1ab394' }}>
                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                          <Form.Label column sm="2">
                            Easting1
                        </Form.Label>
                          <Col sm="4">
                            <Form.Control name="easting1" value={this.state.easting1} onChange={this.handleChange} />
                          </Col>
                          <Col sm="3">
                          </Col>
                        </Form.Group>

                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                          <Form.Label column sm="2">
                            Easting2
                        </Form.Label>
                          <Col sm="4">
                            <Form.Control name="easting2" value={this.state.easting2} onChange={this.handleChange} />
                          </Col>
                          <Col sm="3">
                          </Col>
                        </Form.Group>

                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                          <Form.Label column sm="2">
                            Northing1
                        </Form.Label>
                          <Col sm="4">
                            <Form.Control name="northing1" value={this.state.northing1} onChange={this.handleChange} />
                          </Col>
                          <Col sm="3">
                          </Col>
                        </Form.Group>

                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                          <Form.Label column sm="2">
                            Northing2
                        </Form.Label>
                          <Col sm="4">
                            <Form.Control name="northing2" value={this.state.northing2} onChange={this.handleChange} />
                          </Col>
                          <Col sm="3">
                          </Col>
                        </Form.Group>
                      </div>
                      <br />
                      <Form.Label column sm="2" style={{ fontWeight: 'bold' }} >
                        Dimension
                  </Form.Label>
                      <div style={{ backgroundColor: "white", padding: "10px", border: '1px solid #1ab394' }}>
                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                          <Form.Label column sm="2">
                            Height
                        </Form.Label>
                          <Col sm="4">
                            <InputNumber min={0} style={{ width: '100%' }}
                              onChange={(e) => this.setState({ height: e })} value={this.state.height} placeholder="Height" />
                          </Col>
                          <Col sm="3">
                            <Form.Control name="heightTip" value={this.state.heightTip}
                              onChange={this.handleChangeHeightTip} as="select"  >
                              <option value="0">
                                Select
                            </option>
                              <option value="1">
                                Limitless
                            </option>
                              <option value="2">
                                Cm
                            </option>
                              <option value="3">
                                Meter
                            </option>
                            </Form.Control>
                          </Col>
                        </Form.Group>

                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                          <Form.Label column sm="2">
                            Widht
                        </Form.Label>
                          <Col sm="4">
                            <InputNumber min={0} style={{ width: '100%' }}
                              onChange={(e) => this.setState({ widht: e })} value={this.state.widht} placeholder="Widht" />
                          </Col>
                          <Col sm="3">
                            <Form.Control name="widhtTip" value={this.state.widhtTip}
                              onChange={this.handleChangeWidhtTip} as="select"  >
                              <option value="0">
                                Select
                            </option>
                              <option value="1">
                                Limitless
                            </option>
                              <option value="2">
                                Cm
                            </option>
                              <option value="3">
                                Meter
                            </option>
                            </Form.Control>
                          </Col>
                        </Form.Group>

                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                          <Form.Label column sm="2">
                            Depth
                        </Form.Label>
                          <Col sm="4">
                            <InputNumber min={0} style={{ width: '100%' }}
                              onChange={(e) => this.setState({ depth: e })} value={this.state.depth} placeholder="Depth" />
                          </Col>
                          <Col sm="3">
                            <Form.Control name="depthTip" value={this.state.depthTip}
                              onChange={this.handleChangeDepthTip} as="select"  >
                              <option value="0">
                                Select
                            </option>
                              <option value="1">
                                Limitless
                            </option>
                              <option value="2">
                                Cm
                            </option>
                              <option value="3">
                                Meter
                            </option>
                            </Form.Control>
                          </Col>
                        </Form.Group>
                      </div>
                      <br />
                      <div style={{ backgroundColor: "white", padding: "10px", border: '1px solid #1ab394' }}>
                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                          <Form.Label column sm="2">
                            Load Capacity (Max.)
                        </Form.Label>
                          <Col sm="4">
                            <InputNumber min={0} style={{ width: '100%' }}
                              onChange={(e) => this.setState({ capacity: e })} value={this.state.capacity} placeholder="Load Capacity (Max.)" />
                          </Col>
                          <Col sm="3">
                            <Form.Control name="capacityTip" value={this.state.capacityTip}
                              onChange={this.handleChangeCapacityTip} as="select"  >
                              <option value="0">
                                Select
                            </option>
                              <option value="1">
                                Kg
                            </option>
                              <option value="2">
                                Ton
                            </option>
                              <option value="3">
                                Liter
                            </option>
                            </Form.Control>
                          </Col>
                        </Form.Group>

                        <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                          <Form.Label column sm="2">
                            Storing equipment:
                      </Form.Label>
                          <Col sm="4">

                            <Select
                              name="storingEquipment"
                              style={{ width: '100%' }}
                              placeholder="Storing equipment"
                              value={this.state.storingEquipmentAck}
                              onChange={this.handleChangeStoringEquipment}

                              dropdownRender={menu => (
                                <div>
                                  {menu}
                                  <Divider style={{ margin: '4px 0' }} />
                                  <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                    <Input style={{ flex: 'auto' }} value={this.state.itemStoringEquipments}
                                      onChange={(event) => { this.setState({ itemStoringEquipments: event.target.value }) }} />
                                    <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                      onClick={this.addStoringEquipments} >
                                      <PlusOutlined /> Add item  </a>
                                  </div>
                                </div>
                              )}
                            >
                              {this.state.StoringEquipments.map(i => (
                                <Option key={i.id} value={i.id}>{i.name}</Option>
                              ))}
                            </Select>
                          </Col>
                          <Col sm="6">
                          </Col>
                        </Form.Group>

                      </div>
                      <br />
                      <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <Col sm="2"></Col>
                        <Col sm="4">
                          <ReactButton variant="info" style={{ width: "100%", marginTop: "0.2rem" }}>Barcode</ReactButton>
                        </Col>
                        <Col sm="4">
                          <ReactButton variant="info" style={{ width: "100%", marginTop: "0.2rem" }}>QR Code</ReactButton>
                        </Col>
                        <Col sm="2">
                        </Col>
                      </Form.Group>
                      <br />
                      <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridAdds">
                        <Col sm="2">
                        </Col>
                        <Col sm="8">
                          <ReactButton onClick={this.addStockAddressing} style={{ width: '100%' }} variant="success">Add</ReactButton>
                        </Col>
                        <Col sm="2">
                        </Col>
                      </Form.Group>
                    </div>
                    <br />
                    <div className="content-section implementation">
                      <TableBootstrap responsive striped bordered hover>
                        <thead style={{ textAlign: 'center' }}>
                          <tr>
                            <th>S/N</th>
                            <th>Section</th>
                            <th>Shelf no</th>
                            <th>Code</th>
                            <th>Coordinates</th>
                            <th>Dimension</th>
                            <th>Load Capacity</th>
                            <th>Storing Equipment</th>
                            <th>Photo</th>
                            <th>Layout</th>
                          </tr>
                        </thead>
                        <tbody style={{ textAlign: 'center' }}>
                          {this.state.StockAddressings.map(stockAddressing => (
                            <tr key={stockAddressing.index}>
                              <td>{stockAddressing.index}</td>
                              <td>{stockAddressing.section}</td>
                              <td>{stockAddressing.shelfno}</td>
                              <td>{stockAddressing.addressingCode}</td>
                              <td>{stockAddressing.coordinatesAck}</td>
                              <td>{stockAddressing.dimensionAck}</td>
                              <td>{stockAddressing.capacity}</td>
                              <td>{stockAddressing.storingEquipmentAck}</td>
                              <td>{this.renderDownloadFileAdressingPhoto(stockAddressing)}</td>
                              <td>{this.renderDownloadFileLayout(stockAddressing)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </TableBootstrap>
                    </div>
                    <br />
                  </Container>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <br />
          <div hidden={this.state.hiddenSave}>
            <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
              <Row>
                <ReactButton onClick={this.addStoreLog} style={{ width: '100%' }} variant="success">{this.state.lastButton}</ReactButton>
              </Row>
            </Container>
          </div>

          <div hidden={this.state.hiddenUpdate}>
            <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
              <Row>
                <Col sm="3">
                </Col>
                <Col sm="2">
                  <ReactButton onClick={this.changeButtonCancel} style={{ width: '100%' }} variant="danger">Cancel</ReactButton>
                </Col>
                <Col sm="3">
                  <ReactButton onClick={this.updateDone} style={{ width: '100%' }} variant="warning">Update</ReactButton>
                </Col>
                <Col sm="3">
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className="datatable-doc-demo">
          <Card>
            <div className="content-section implementation">
              {/* <MNDTable ></MNDTable> */}
              <Table columns={columns} dataSource={this.state.StoreLogs} pagination={this.state.pagination}
                responsive striped bordered hover
                loading={this.state.loading} onChange={this.handleTableChange} 
                expandable={{ expandedRowRender }} 
              />
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    i18n: state.i18n
  }
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(StoreLog);
