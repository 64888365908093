/* eslint-disable */

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import { handleRequest } from '../../redux/config/env';
import * as Mnd from "../../partials/content/CustomComponents";
import MNDTableSettings from '../MND/MNDTableSettings';
import { Col, Form, Row, Modal as ModalReact, Button } from 'react-bootstrap';
import { InputText } from "primereact/inputtext";
import { TreeSelect, Select, Divider, Input } from 'antd';
import { Button as PrimeButton } from "primereact/button";
import { PlusOutlined } from '@ant-design/icons';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';

const { SHOW_PARENT } = TreeSelect;
const { Option } = Select;

const expandable = { expandedRowRender: record => <b>{record.disciplineName}</b> };
const title = () => 'Here is title';
var footer = () => 'Here is footer';

class WeldList extends Component {

    constructor(props) {
        super(props)

        this.state = {
            hiddenDataGiris: true,
            hiddenSave: true,
            hiddenUpdate: true,
            isometricData: [],
            selectedIsometric: "",
            checkedIsometric: [],
            createNewButton: "Create New",
            createNewButtonVariant: "info",
            WeldList: [],
            kontrol: false,
            WELDSizes: [],
            WELDWallThicks: [],
            WELDMark1s: [],
            WELDMark2s: [],
            revno: "",
            spoolSbt: "",
            spool: "",
        }

        this.actionTemplate = this.actionTemplate.bind(this);

    }

    componentDidMount = async () => {
        this.getWeldList();
        await this.getTableProperties();
        this.getIsometric();
        this.getCodesWELDSize();
        this.getCodesWELDWallThick();
        this.getCodesWELDMark1();
        this.getCodesWELDMark2();
    }

    getWeldList = async () => {
        const newObj = {
            id: 0,
        }
        const response = await handleRequest("POST", "/WeldList/getWeldListPaging", newObj);
        if (response.length === 0 || response.data.length === 0) {
            this.setState({
                WeldList: []
            });
        } else if (response.data.length !== 0) {
            this.setState({
                WeldList: response.data.data
            });
        }
    }

    getTableProperties = async () => {
        //MNDTableSettings.getTableProperties("weldList");

        this.weldList = JSON.parse(localStorage.getItem('weldList'));
        console.log("GETTABLE", this.weldList);
        if (this.weldList != null && this.weldList != undefined && this.weldList != "") {

            this.setState({
                bordered: this.weldList.bordered,
                title: this.weldList.titleDrm == true ? title : undefined,
                titleDrm: this.weldList.titleDrm,
                showHeader: this.weldList.showHeader,
                footer: this.weldList.footerDrm == true ? footer : undefined,
                footerDrm: this.weldList.footerDrm,
                expandable: this.weldList.expandableDrm == true ? expandable : {},
                expandableDrm: this.weldList.expandableDrm,
                rowSelection: this.weldList.rowSelection,
                ellipsis: this.weldList.ellipsis,
                tableLayout: this.weldList.tableLayout,
                size: this.weldList.size,
                top: this.weldList.top,
                bottom: this.weldList.bottom,
                editBgColor: this.weldList.editBgColor,
                deleteBgColor: this.weldList.deleteBgColor,
                selectedNodeKey: null,
                yScroll: this.weldList.yScroll,
                xScroll: this.weldList.xScroll,
                paginationDisabled: {
                    defaultPageSize: 5, total: this.state.WeldList.length,
                    showQuickJumper: true, showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '25', '50'], total: this.state.WeldList.length, showQuickJumper: true,
                    position: [this.weldList.top, this.weldList.bottom]
                },
                kontrol: true,
            });
        } else {
            this.setState({
                bordered: false,
                title: undefined,
                titleDrm: false,
                showHeader: true,
                footer: undefined,
                footerDrm: false,
                expandable: false,
                expandableDrm: false,
                rowSelection: undefined,
                ellipsis: false,
                tableLayout: undefined,
                size: 'default',
                top: 'none',
                bottom: 'bottomRight',
                editBgColor: '#fff',
                deleteBgColor: '#fff',
                selectedNodeKey: null,
                xScroll: undefined,
                paginationDisabled: false,
                kontrol: true,
            });
        }
    }

    showOrHideModalTable = async () => {
        this.setState({
            modalTable: !this.state.modalTable,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
        await this.getTableProperties();
    }

    createNewClientButton = async () => {
        await this.resetInputs();

        if (this.state.createNewButton == "Create New") {
            this.setState({
                hiddenDataGiris: false,
                createNewButton: "Cancel",
                createNewButtonVariant: "danger",
                hiddenSave: false,
                hiddenUpdate: true,
            });
        }
        else {
            this.setState({
                hiddenDataGiris: true,
                createNewButton: "Create New",
                createNewButtonVariant: "info",
                hiddenSave: true,
                hiddenUpdate: true,
            });
        }
    }

    resetInputs = () => {
        this.setState({
            id: -1,
            selectedIsometric: "",
            checkedIsometric: "",
            revno: "",
            spool: "",
            spoolSbt: "",
            weld: "",
            repExt: 0,
            pwhtExt: 0,
            tracer: 0,
            ip: 0,
            rwReason: 0,
            pipingClass: "",
            service: "",
            materialClass: 0,
            weldSize: 0,
            weldSizeAck: "",
            wallThick: 0,
            wallThickAck: "",
            category: 0,
            type: 0,
            mark1: 0,
            mark1Ack: "",
            mark2: 0,
            mark2Ack: "",
        });
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalDeleteWeldList = () => {
        this.setState({
            deleteModal: !this.state.deleteModal,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    };

    showSuccess(msg) {
        if (!msg) {
            msg = 'Successfull';
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    };

    showError(msg) {
        if (!msg) {
            msg = 'An error was occured please try again later !';
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    };

    showWarning(msg) {
        if (!msg) {
            msg = 'Something went wrong. Please check inputs.';
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };

    getIsometric = async () => {
        var response = [];
        var item = { page: "Drawing" };
        response = await handleRequest("POST", "/DocumentLog/getResourceDocuments", item)
        console.log(response.data);
        if (response.data.length !== 0) {
            this.setState({
                isometricData: response.data
            });
        }
    }

    getDocumentLog = async (documentLogId, value, label) => {
        var response = [];
        var item = { id: documentLogId };
        response = await handleRequest("POST", "/DocumentLog/getDocumentLogResult", item)
        console.log("TTT", response.data);
        if (response.data.length !== 0) {
            this.setState({
                revno: response.data[0].revno,
                spool: response.data[0].code,
                spoolSbt: response.data[0].code,
                selectedIsometric: value,
                checkedIsometric: label,
            });
        } else {
            this.setState({
                revno: "",
                spool: "",
                spoolSbt: "",
                selectedIsometric: value,
                checkedIsometric: label,
            });
        }
    }

    getDocumentLogspoolSbt = async (documentLogId) => {
        var response = [];
        var item = { id: documentLogId };
        response = await handleRequest("POST", "/DocumentLog/getDocumentLogResult", item)
        console.log("TTT", response.data);
        if (response.data.length !== 0) {
            this.setState({
                spoolSbt: response.data[0].code,
            });
        } else {
            this.setState({
                spoolSbt: "",
            });
        }
    }

    onIsometricChange = async (value, label, extra) => {
        console.log("RRR", value);
        console.log(label);
        console.log(extra);
        var documentLogId = -1;
        if (Boolean(value) && value.length > 0) {
            documentLogId = value;
        }
        this.getDocumentLog(documentLogId, value, label);
    };

    handleChangeSelect = e => {
        e.preventDefault();

        this.setState({
            [e.target.name]: e.target.value,
        });
    };

    handleChangeSpool = e => {
        e.preventDefault();
        var deger = "";
        if (!e.target.value.includes(this.state.spoolSbt)) {
            deger = this.state.spoolSbt;
        } else {
            deger = e.target.value;
        }
        this.setState({
            spool: deger,
        });
    };

    handleChangeWELDSize = (e) => {
        var deger = "";
        for (var z = 0; z < this.state.WELDSizes.length; z++) {
            if (this.state.WELDSizes[z].id == e) {
                deger = this.state.WELDSizes[z].name;
            }
        }
        this.setState({
            weldSizeAck: deger,
            weldSize: e,
        });
    };

    addWELDSizes = async () => {
        const { itemWELDSizes } = this.state;
        if (itemWELDSizes != null && itemWELDSizes != "" && itemWELDSizes != undefined) {
            const newItem = {
                name: itemWELDSizes,
                type: "WELDSize"
            }

            await handleRequest("POST", "/Code/add", newItem);
            this.setState({
                itemWELDSizes: '',
            });
            this.getCodesWELDSize();
        } else {
            this.showWarning();
        }
    };

    getCodesWELDSize = async () => {
        const response = await handleRequest("GET", "/Code/type/WELDSize");

        if (response.data !== null && response.data !== undefined) {
            this.setState({
                WELDSizes: response.data
            });
        }
    }

    handleChangeWELDWallThick = (e) => {
        var deger = "";
        for (var z = 0; z < this.state.WELDWallThicks.length; z++) {
            if (this.state.WELDWallThicks[z].id == e) {
                deger = this.state.WELDWallThicks[z].name;
            }
        }
        this.setState({
            wallThickAck: deger,
            wallThick: e,
        });
    };

    addWELDWallThicks = async () => {
        const { itemWELDWallThicks } = this.state;
        if (itemWELDWallThicks != null && itemWELDWallThicks != "" && itemWELDWallThicks != undefined) {
            const newItem = {
                name: itemWELDWallThicks,
                type: "WELDWallThick"
            }

            await handleRequest("POST", "/Code/add", newItem);
            this.setState({
                itemWELDWallThicks: '',
            });
            this.getCodesWELDWallThick();
        } else {
            this.showWarning();
        }
    };

    getCodesWELDWallThick = async () => {
        const response = await handleRequest("GET", "/Code/type/WELDWallThick");

        if (response.data !== null && response.data !== undefined) {
            this.setState({
                WELDWallThicks: response.data
            });
        }
    }

    handleChangeWELDMark1 = (e) => {
        var deger = "";
        for (var z = 0; z < this.state.WELDMark1s.length; z++) {
            if (this.state.WELDMark1s[z].id == e) {
                deger = this.state.WELDMark1s[z].name;
            }
        }
        this.setState({
            mark1Ack: deger,
            mark1: e,
        });
    };

    addWELDMark1s = async () => {
        const { itemWELDMark1s } = this.state;
        if (itemWELDMark1s != null && itemWELDMark1s != "" && itemWELDMark1s != undefined) {
            const newItem = {
                name: itemWELDMark1s,
                type: "WELDMark1"
            }

            await handleRequest("POST", "/Code/add", newItem);
            this.setState({
                itemWELDMark1s: '',
            });
            this.getCodesWELDMark1();
        } else {
            this.showWarning();
        }
    };

    getCodesWELDMark1 = async () => {
        const response = await handleRequest("GET", "/Code/type/WELDMark1");

        if (response.data !== null && response.data !== undefined) {
            this.setState({
                WELDMark1s: response.data
            });
        }
    }

    handleChangeWELDMark2 = (e) => {
        var deger = "";
        for (var z = 0; z < this.state.WELDMark2s.length; z++) {
            if (this.state.WELDMark2s[z].id == e) {
                deger = this.state.WELDMark2s[z].name;
            }
        }
        this.setState({
            mark2Ack: deger,
            mark2: e,
        });
    };

    addWELDMark2s = async () => {
        const { itemWELDMark2s } = this.state;
        if (itemWELDMark2s != null && itemWELDMark2s != "" && itemWELDMark2s != undefined) {
            const newItem = {
                name: itemWELDMark2s,
                type: "WELDMark2"
            }

            await handleRequest("POST", "/Code/add", newItem);
            this.setState({
                itemWELDMark2s: '',
            });
            this.getCodesWELDMark2();
        } else {
            this.showWarning();
        }
    };

    getCodesWELDMark2 = async () => {
        const response = await handleRequest("GET", "/Code/type/WELDMark2");

        if (response.data !== null && response.data !== undefined) {
            this.setState({
                WELDMark2s: response.data
            });
        }
    }

    actionTemplate(rowData, column) {
        return <React.Fragment>
            <CustomMaterialMenu row={rowData}
                onDeleteRow={this.deleteWeldList.bind(this)}
                onEditRow={this.changeButtonWeldList.bind(this)} />
        </React.Fragment>;
    };

    deleteWeldList = (rowData) => {
        console.log("RR", rowData);
        this.setState({
            deleteModal: true,
            weldListId: rowData.id,
        });
    };

    deleteWeldListOK = async () => {
        const deletedWeldList = {
            id: this.state.weldListId,
        };
        console.log("RR", deletedWeldList);

        var response = await handleRequest("POST", "/WeldList/delete", deletedWeldList);

        if (response.data !== null && response.data !== undefined) {
            if (response.data === "SUCCESS") {
                this.setState({
                    deleteModal: false,
                });
                this.getWeldList();
                this.showSuccess();
            }
            else {
                this.showError();
            }
        } else {
            this.showError();
        }
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    };

    changeButtonWeldList = async (row) => {
        console.log("CHG", row);
        this.getDocumentLogspoolSbt(row.isometricId);
        await this.setState({
            hiddenDataGiris: false,
            hiddenSave: true,
            hiddenUpdate: false,
            createNewButton: "Cancel",
            createNewButtonVariant: "danger",

            id: row.id,
            isometricId: row.isometricId,
            selectedIsometric: row.isometricId + "",
            revno: row.revno,
            spool: row.spool,
            weld: row.weld,
            repExt: row.repExt,
            repExtAck: row.repExtAck,
            pwhtExt: row.pwhtExt,
            pwhtExtAck: row.pwhtExtAck,
            tracer: row.tracer,
            tracerAck: row.tracerAck,
            ip: row.ip,
            ipAck: row.ipAck,
            rwReason: row.rwReason,
            rwReasonAck: row.rwReasonAck,
            pipingClass: row.pipingClass,
            pipingClassAck: row.pipingClassAck,
            service: row.service,
            serviceAck: row.serviceAck,
            materialClass: row.materialClass,
            materialClassAck: row.materialClassAck,
            weldSize: row.weldSize,
            weldSizeAck: (row.codeWeldSize != null && row.codeWeldSize != undefined) ? row.codeWeldSize.name : "",
            wallThick: row.wallThick,
            wallThickAck: (row.codeWallThick != null && row.codeWallThick != undefined) ? row.codeWallThick.name : "",
            category: row.category,
            categoryAck: row.categoryAck,
            type: row.type,
            typeAck: row.typeAck,
            mark1: row.mark1,
            mark1Ack: (row.codeMark1 != null && row.codeMark1 != undefined) ? row.codeMark1.name : "",
            mark2: row.mark2,
            mark2Ack: (row.codeMark2 != null && row.codeMark2 != undefined) ? row.codeMark2.name : "",
        });
    };

    changeButtonCancel = () => {
        this.setState({
            hiddenSave: true,
            hiddenUpdate: true,
            hidden: true,
            createNewButton: "Create New",
            createNewButtonVariant: "info",
            hiddenDataGiris: true,
        });
        this.resetInputs();
    };

    updateDone = async () => {
        if (this.state.id != null && this.state.id != undefined && this.state.id != "" && this.state.id != 0 &&
            this.state.selectedIsometric != null && this.state.selectedIsometric != undefined && this.state.selectedIsometric != "" &&
            this.state.revno != null && this.state.revno != undefined) {
            const updatedWeldListLogLog = {
                id: this.state.id,
                isometricId: this.state.selectedIsometric,
                revno: this.state.revno,
                spool: this.state.spool,
                weld: this.state.weld,
                repExt: this.state.repExt,
                pwhtExt: this.state.pwhtExt,
                tracer: this.state.tracer,
                ip: this.state.ip,
                rwReason: this.state.rwReason,
                pipingClass: this.state.pipingClass,
                service: this.state.service,
                materialClass: this.state.materialClass,
                weldSize: this.state.weldSize,
                wallThick: this.state.wallThick,
                category: this.state.category,
                type: this.state.type,
                mark1: this.state.mark1,
                mark2: this.state.mark2,
            }

            console.log("update", updatedWeldListLogLog);
            var response = await handleRequest("POST", "/WeldList/update", updatedWeldListLogLog);
            if (response.data !== null && response.data !== undefined) {
                if (response.data == "SUCCESS") {
                    this.getWeldList();
                    this.createNewClientButton();
                    this.showSuccess();
                    this.resetInputs();
                }
                else {
                    this.showError();
                }
            }
            else {
                this.showError();
            }
        }
        else {
            this.showWarning();
        }
    };

    addWeldList = async () => {
        if (this.state.selectedIsometric != null && this.state.selectedIsometric != undefined && this.state.selectedIsometric != "" &&
            this.state.revno != null && this.state.revno != undefined) {
            const prm = {
                isometricId: this.state.selectedIsometric,
                revno: this.state.revno,
                spool: this.state.spool,
                weld: this.state.weld,
                repExt: this.state.repExt,
                pwhtExt: this.state.pwhtExt,
                tracer: this.state.tracer,
                ip: this.state.ip,
                rwReason: this.state.rwReason,
                pipingClass: this.state.pipingClass,
                service: this.state.service,
                materialClass: this.state.materialClass,
                weldSize: this.state.weldSize,
                wallThick: this.state.wallThick,
                category: this.state.category,
                type: this.state.type,
                mark1: this.state.mark1,
                mark2: this.state.mark2,
            }

            console.log("add", prm);
            const response = await handleRequest("POST", "/WeldList/add", prm);
            if (response.data !== null && response.data !== undefined) {
                if (response.data == "SUCCESS") {
                    this.getWeldList();
                    this.createNewClientButton();
                    this.showSuccess();
                    this.resetInputs();
                } else {
                    this.showError();
                }
            } else {
                this.showError();
            }
        } else {
            this.showWarning();
        }
    }

    render() {
        const isometricProps = {
            treeData: this.state.isometricData,
            value: this.state.selectedIsometric,
            onChange: this.onIsometricChange,
            treeCheckable: false,
            showCheckedStrategy: SHOW_PARENT,
            placeholder: 'Please select',
            style: {
                width: '100%',
            },
        };

        const columns = [
            {
                key: "key",
                title: "",
                render: this.actionTemplate
            },
            {
                dataIndex: "index",
                key: "key",
                title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>,
            },
            {
                key: "key",
                title: <FormattedMessage id="WELDIsometric" defaultMessage="Isometric#" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        // var nameOfIsometric = this.state.isometricData.findIndex(p => p.children[0].data.id === data.isometricId);
                        // return nameOfIsometric.children[0].label;
                        return data.spool + "/";
                    }
                    return "";
                }
            },
            {
                dataIndex: "revno",
                key: "key",
                title: <FormattedMessage id="WELDRevno" defaultMessage="Rev." />,
            },
            {
                dataIndex: "spool",
                key: "key",
                title: <FormattedMessage id="WELDSpool" defaultMessage="Spool#" />,
            },
            {
                dataIndex: "weld",
                key: "key",
                title: <FormattedMessage id="WELDWeld" defaultMessage="Weld #" />,
            },
            {
                dataIndex: "repExtAck",
                key: "key",
                title: <FormattedMessage id="WELDRepExt" defaultMessage="REP Ext" />,
            },
            {
                dataIndex: "pwhtExtAck",
                key: "key",
                title: <FormattedMessage id="WELDPWHTExt" defaultMessage="PWHT Ext" />,
            },
            {
                dataIndex: "tracerAck",
                key: "key",
                title: <FormattedMessage id="WELDTracer" defaultMessage="Tracer" />,
            },
            {
                dataIndex: "ipAck",
                key: "key",
                title: <FormattedMessage id="WELDIP" defaultMessage="I.P."/>,
            },
            {
                dataIndex: "rwReasonAck",
                key: "key",
                title: <FormattedMessage id="WELDRWReason" defaultMessage="RW Reason"/>,
            },
            {
                dataIndex: "pipingClassAck",
                key: "key",
                title: <FormattedMessage id="WELDPipingClass" defaultMessage="Piping Class"/>,
            },
            {
                dataIndex: "serviceAck",
                key: "key",
                title: <FormattedMessage id="WELDService" defaultMessage="Service" />,
            },
            {
                dataIndex: "materialClassAck",
                key: "key",
                title: <FormattedMessage id="WELDMaterialClass" defaultMessage="Material Class" />,
            },
            {
                dataIndex: "codeWeldSize",
                key: "key",
                title: <FormattedMessage id="WELDSize" defaultMessage="Size" />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        return data.name;
                    }
                    return "";
                }
            },
            {
                dataIndex: "codeWallThick",
                key: "key",
                title: <FormattedMessage id="WELDWallThick" defaultMessage="Wall Thick." />,
                render: (data) => {
                    if (data != null && data != undefined) {
                        return data.name;
                    }
                    return "";
                }
            },
            {
                dataIndex: "categoryAck",
                key: "key",
                title: <FormattedMessage id="WELDCategory" defaultMessage="Weld Category" />,
            },
            {
                dataIndex: "typeAck",
                key: "key",
                title: <FormattedMessage id="WELDType" defaultMessage="Weld Type" />,
            },
            // {
            //     dataIndex: "codeMark1",
            //     key: "key",
            //     title: <FormattedMessage id="WELDMark1" defaultMessage="Mark 1" />,
            //     render: (data) => {
            //         if (data != null && data != undefined) {
            //             return data.name;
            //         }
            //         return "";
            //     }
            // },
            // {
            //     dataIndex: "codeMark2",
            //     key: "key",
            //     title: <FormattedMessage id="WELDMark2" defaultMessage="Mark 2"  />,
            //     render: (data) => {
            //         if (data != null && data != undefined) {
            //             return data.name;
            //         }
            //         return "";
            //     }
            // },
        ];

        const tableColumns = columns.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
        if (this.state.xScroll === 'fixed') {
            tableColumns[0].fixed = true;
            tableColumns[tableColumns.length - 1].fixed = 'right';
        }


        return (
            <div>
                <Form>
                    <Row>
                        <Col xs={8}></Col>
                        <Col xs={3}>
                            <Button onClick={this.createNewClientButton} style={{ width: '100%' }}
                                variant={this.state.createNewButtonVariant} ><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" />
                            </Button>
                        </Col>
                        <Col xs={1}>
                            <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>
                        </Col>
                    </Row>

                    <div hidden={this.state.hiddenDataGiris}>
                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                            <Col sm="2"></Col>
                            <Form.Label style={{ color: 'black' }} column sm="2">
                                <FormattedMessage id="WELDIsometric" defaultMessage="Isometric#"/>
                            </Form.Label>
                            <Col sm="4">
                                <TreeSelect  {...isometricProps} />
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                            <Col sm="2"></Col>
                            <Form.Label style={{ color: 'black' }} column sm="2">
                                <FormattedMessage id="WELDRevNo" defaultMessage="Rev."/>
                            </Form.Label>
                            <Col sm="4">
                                <InputText readOnly={true} style={{ width: '100%' }} value={this.state.revno}
                                    onChange={(e) =>
                                        this.setState({
                                            revno: e.target.value,
                                        })} />
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                            <Col sm="2"></Col>
                            <Form.Label style={{ color: 'black' }} column sm="2">
                                <FormattedMessage id="WELDSpool" defaultMessage="Spool#"/>
                            </Form.Label>
                            <Col sm="4">
                                <InputText readOnly={this.state.spool == ""} style={{ width: '100%' }} value={this.state.spool}
                                    onChange={this.handleChangeSpool}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                            <Col sm="2"></Col>
                            <Form.Label style={{ color: 'black' }} column sm="2">
                                <FormattedMessage id="WELDWeld" defaultMessage="Weld #"/>
                            </Form.Label>
                            <Col sm="4">
                                <InputText style={{ width: '100%' }} value={this.state.weld}
                                    onChange={(e) =>
                                        this.setState({
                                            weld: e.target.value,
                                        })} />
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                            <Col sm="2"></Col>
                            <Form.Label style={{ color: 'black' }} column sm="2">
                                <FormattedMessage id="WELDRepExt" defaultMessage="REP Ext"/>
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control name="repExt" value={this.state.repExt}
                                    onChange={this.handleChangeSelect} as="select"  >
                                    <option value="0"> Select </option>
                                    <option value="1"> R1 </option>
                                    <option value="2"> R2 </option>
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                            <Col sm="2"></Col>
                            <Form.Label style={{ color: 'black' }} column sm="2">
                                <FormattedMessage id="WELDPWHTExt" defaultMessage="PWHT Ext"/>
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control name="pwhtExt" value={this.state.pwhtExt}
                                    onChange={this.handleChangeSelect} as="select"  >
                                    <option value="0"> Select </option>
                                    <option value="1"> H </option>
                                    <option value="2"> H2 </option>
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                            <Col sm="2"></Col>
                            <Form.Label style={{ color: 'black' }} column sm="2">
                                <FormattedMessage id="WELDTracer" defaultMessage="Tracer"/>
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control name="tracer" value={this.state.tracer}
                                    onChange={this.handleChangeSelect} as="select"  >
                                    <option value="0"> Select </option>
                                    <option value="1"> T1 </option>
                                    <option value="2"> T2 </option>
                                    <option value="3"> T3 </option>
                                    <option value="4"> T4 </option>
                                    <option value="5"> T5 </option>
                                    <option value="6"> T6 </option>
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                            <Col sm="2"></Col>
                            <Form.Label style={{ color: 'black' }} column sm="2">
                                <FormattedMessage id="WELDIP" defaultMessage="I.P."/>
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control name="ip" value={this.state.ip}
                                    onChange={this.handleChangeSelect} as="select"  >
                                    <option value="0"> Select </option>
                                    <option value="1"> Yes </option>
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                            <Col sm="2"></Col>
                            <Form.Label style={{ color: 'black' }} column sm="2">
                                <FormattedMessage id="WELDRWReason" defaultMessage="RW Reason"/>
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control name="rwReason" value={this.state.rwReason}
                                    onChange={this.handleChangeSelect} as="select"  >
                                    <option value="0"> Select </option>
                                    <option value="1"> CDM </option>
                                    <option value="2"> CFER </option>
                                    <option value="3"> CFER IP </option>
                                    <option value="4"> CFER T </option>
                                    <option value="5"> CWO </option>
                                    <option value="6"> CWO IP </option>
                                    <option value="7"> CWO T </option>
                                    <option value="8"> NT </option>
                                    <option value="9"> NT IP </option>
                                    <option value="10"> NT T </option>
                                    <option value="11"> RC </option>
                                    <option value="12"> RC IP </option>
                                    <option value="13"> RC T </option>
                                    <option value="14"> TM </option>
                                    <option value="15"> TM-IP </option>
                                    <option value="16"> TM-T </option>
                                    <option value="17"> TS </option>
                                    <option value="18"> WM </option>
                                    <option value="19"> WM IP </option>
                                    <option value="20"> WM T </option>
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                            <Col sm="2"></Col>
                            <Form.Label style={{ color: 'black' }} column sm="2">
                                <FormattedMessage id="WELDPipingClass" defaultMessage="Piping Class"/>
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control name="pipingClass" value={this.state.pipingClass}
                                    onChange={this.handleChangeSelect} as="select"  >
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                            <Col sm="2"></Col>
                            <Form.Label style={{ color: 'black' }} column sm="2">
                                <FormattedMessage id="WELDService" defaultMessage="Service"/>
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control name="service" value={this.state.service}
                                    onChange={this.handleChangeSelect} as="select"  >
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                            <Col sm="2"></Col>
                            <Form.Label style={{ color: 'black' }} column sm="2">
                                <FormattedMessage id="WELDMaterialClass" defaultMessage="Material Class" />
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control name="materialClass" value={this.state.materialClass}
                                    onChange={this.handleChangeSelect} as="select"  >
                                    <option value="0"> Select </option>
                                    <option value="1"> AS </option>
                                    <option value="2"> CS </option>
                                    <option value="3"> SS </option>
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                            <Col sm="2"></Col>
                            <Form.Label style={{ color: 'black' }} column sm="2">
                                <FormattedMessage id="WELDSize" defaultMessage="Size" />
                            </Form.Label>
                            <Col sm="4">
                                <Select
                                    name="weldSize"
                                    style={{ width: '100%' }}
                                    value={this.state.weldSizeAck}
                                    onChange={this.handleChangeWELDSize}

                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <Divider style={{ margin: '4px 0' }} />
                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                <Input style={{ flex: 'auto' }} value={this.state.itemWELDSizes}
                                                    onChange={(event) => { this.setState({ itemWELDSizes: event.target.value }) }} />
                                                <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                    onClick={this.addWELDSizes} >
                                                    <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add"/>  </a>
                                            </div>
                                        </div>
                                    )}>
                                    {this.state.WELDSizes.map(i => (
                                        <Option key={i.id} value={i.id}>{i.name}</Option>
                                    ))}
                                </Select>
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                            <Col sm="2"></Col>
                            <Form.Label style={{ color: 'black' }} column sm="2">
                                <FormattedMessage id="WELDWallThick" defaultMessage="Wall Thick." />
                            </Form.Label>
                            <Col sm="4">
                                <Select
                                    name="wallThick"
                                    style={{ width: '100%' }}
                                    value={this.state.wallThickAck}
                                    onChange={this.handleChangeWELDWallThick}

                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <Divider style={{ margin: '4px 0' }} />
                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                <Input style={{ flex: 'auto' }} value={this.state.itemWELDWallThicks}
                                                    onChange={(event) => { this.setState({ itemWELDWallThicks: event.target.value }) }} />
                                                <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                    onClick={this.addWELDWallThicks} >
                                                    <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add"/>  </a>
                                            </div>
                                        </div>
                                    )}>
                                    {this.state.WELDWallThicks.map(i => (
                                        <Option key={i.id} value={i.id}>{i.name}</Option>
                                    ))}
                                </Select>
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                            <Col sm="2"></Col>
                            <Form.Label style={{ color: 'black' }} column sm="2">
                                <FormattedMessage id="WELDCategory" defaultMessage="Weld Category" />
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control name="category" value={this.state.category}
                                    onChange={this.handleChangeSelect} as="select"  >
                                    <option value="0"> Select </option>
                                    <option value="1"> Field </option>
                                    <option value="2"> Shop </option>
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                            <Col sm="2"></Col>
                            <Form.Label style={{ color: 'black' }} column sm="2">
                                <FormattedMessage id="WELDType" defaultMessage="Weld Type" />
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control name="type" value={this.state.type}
                                    onChange={this.handleChangeSelect} as="select"  >
                                    <option value="0"> Select </option>
                                    <option value="1"> FW </option>
                                    <option value="2"> LET </option>
                                    <option value="3"> RPD </option>
                                    <option value="4"> SOB </option>
                                    <option value="5"> SW </option>
                                    <option value="6"> THD </option>
                                    <option value="7"> TRN </option>
                                    <option value="8"> TSW </option>
                                </Form.Control>
                            </Col>
                        </Form.Group>

                        {/*<Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                            <Col sm="2"></Col>
                            <Form.Label style={{ color: 'black' }} column sm="2">
                                <FormattedMessage id="WELDMark1" defaultMessage="Mark 1"/>
                            </Form.Label>
                            <Col sm="4">
                                <Select
                                    name="mark1"
                                    style={{ width: '100%' }}
                                    value={this.state.mark1Ack}
                                    onChange={this.handleChangeWELDMark1}

                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <Divider style={{ margin: '4px 0' }} />
                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                <Input style={{ flex: 'auto' }} value={this.state.itemWELDMark1s}
                                                    onChange={(event) => { this.setState({ itemWELDMark1s: event.target.value }) }} />
                                                <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                    onClick={this.addWELDMark1s} >
                                                    <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add" />  </a>
                                            </div>
                                        </div>
                                    )}>
                                    {this.state.WELDMark1s.map(i => (
                                        <Option key={i.id} value={i.id}>{i.name}</Option>
                                    ))}
                                </Select>
                            </Col>
                        </Form.Group>

                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}  >
                            <Col sm="2"></Col>
                            <Form.Label style={{ color: 'black' }} column sm="2">
                                <FormattedMessage id="WELDMark2" defaultMessage="Mark 2"/>
                            </Form.Label>
                            <Col sm="4">
                                <Select
                                    name="mark2"
                                    style={{ width: '100%' }}
                                    value={this.state.mark2Ack}
                                    onChange={this.handleChangeWELDMark2}

                                    dropdownRender={menu => (
                                        <div>
                                            {menu}
                                            <Divider style={{ margin: '4px 0' }} />
                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                <Input style={{ flex: 'auto' }} value={this.state.itemWELDMark2s}
                                                    onChange={(event) => { this.setState({ itemWELDMark2s: event.target.value }) }} />
                                                <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                    onClick={this.addWELDMark2s} >
                                                    <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add"/>  </a>
                                            </div>
                                        </div>
                                    )}>
                                    {this.state.WELDMark2s.map(i => (
                                        <Option key={i.id} value={i.id}>{i.name}</Option>
                                    ))}
                                </Select>
                            </Col>
                        </Form.Group>*/}
                    </div>

                    <div hidden={this.state.hiddenSave}>
                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}>
                            <Col sm="4">
                            </Col>
                            <Col sm="4">
                                <Button onClick={this.addWeldList}
                                    style={{ width: '100%' }}
                                    variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save"/></Button>
                            </Col>
                            <Col sm="4">
                            </Col>
                        </Form.Group>
                    </div>

                    <div hidden={this.state.hiddenUpdate}>
                        <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row}>
                            <Col sm="4">
                            </Col>
                            <Col sm="2">
                                <Button onClick={this.changeButtonCancel} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel"/></Button>
                            </Col>
                            <Col sm="2">
                                <Button onClick={this.updateDone} style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update"/></Button>
                            </Col>
                            <Col sm="4">
                            </Col>
                        </Form.Group>
                    </div>
                    <br />
                    <br />
                    {this.state.kontrol === true && <Mnd.MNDTable
                        state={this.state}
                        columns={tableColumns} dataSource={this.state.WeldList}
                        pagination={this.state.paginationDisabled}
                        expandable={this.state.expandable}
                        footer={this.state.footer}
                        title={this.state.title}
                        scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                    />}

                    <ModalReact
                        show={this.state.modalTable}
                        onHide={this.showOrHideModalTable}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered size="lg">

                        <ModalReact.Header closeButton>
                            <ModalReact.Title id="contained-modal-title-vcenter">
                                <Row >
                                    <Col sm="12">
                                        <FormattedMessage id="GeneralTableSettings" defaultMessage="Table Settings" />
                                    </Col>
                                </Row>
                            </ModalReact.Title>
                        </ModalReact.Header>

                        <ModalReact.Body>
                            <Row >
                                <Col sm="12">
                                    <MNDTableSettings id="weldList" />
                                </Col>
                            </Row>
                        </ModalReact.Body>
                    </ModalReact>

                    <ModalReact
                        show={this.state.modalMessage}
                        onHide={this.showOrHideModalMessage}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>

                        <ModalReact.Header closeButton>
                            <ModalReact.Title id="contained-modal-title-vcenter">
                                <Row >
                                    <Col sm="12">
                                        {this.state.messageTip == "Success" &&
                                            <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                        {this.state.messageTip == "Warning" &&
                                            <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                        {this.state.messageTip == "Error" &&
                                            <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                    </Col>
                                </Row>
                            </ModalReact.Title>
                        </ModalReact.Header>

                        <ModalReact.Body>
                            <Row >
                                <Col sm="12">
                                    {this.state.message}
                                </Col>
                            </Row>
                        </ModalReact.Body>
                        <ModalReact.Footer>
                            <Button variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close"/></Button>
                        </ModalReact.Footer>
                    </ModalReact>

                    <ModalReact
                        show={this.state.deleteModal}
                        onHide={this.showOrHideModalDeleteWeldList}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <ModalReact.Header closeButton>
                            <ModalReact.Title id="contained-modal-title-vcenter">
                                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                            </ModalReact.Title>
                        </ModalReact.Header>
                        <ModalReact.Body>
                            <p style={{ textAlign: "center", color: "black" }}>
                                <FormattedMessage id="DeleteMessage1" />
                            </p>
                            <p style={{ textAlign: "center", color: "black" }}>
                                <FormattedMessage id="DeleteMessage2" />
                            </p>
                        </ModalReact.Body>
                        <ModalReact.Footer>
                            <Button
                                variant="secondary"
                                onClick={this.showOrHideModalDeleteWeldList}
                            >
                                <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                            </Button>
                            <Button variant="danger" onClick={this.deleteWeldListOK}>
                                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete"/>
                            </Button>
                        </ModalReact.Footer>
                    </ModalReact>

                </Form>
            </div>
        )
    }
}

const mapStateToProps = () => {
    return {

    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(WeldList)
