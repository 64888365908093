/* eslint-disable */

import React, { Component } from 'react' 
import { connect } from 'react-redux'; 
import { FormattedMessage } from "react-intl";
import { handleRequest } from '../../redux/config/env';
import { API_BASE } from '../../redux/config/env';
import * as Mnd from "../../partials/content/CustomComponents";
import MNDTableSettings from '../MND/MNDTableSettings';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Button, Form as FormBoot , Col, Modal, Row } from 'react-bootstrap'; 
import { Form, Input, Select, Upload, Divider, Button as AntdButton, DatePicker, Radio} from 'antd';
import { Button as PrimeButton } from "primereact/button";
import CustomMaterialMenuDeleteEdit from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import CustomMaterialMenuEdit from '../settings/ForComponents/CustomMaterialMenuEdit';
import CustomMaterialMenuDelete from '../settings/ForComponents/CustomMaterialMenuDelete';
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css"; 
import { PdfMultiViewer } from 'react-pdfjs-multi';
import 'react-pdfjs-multi/dist/react-pdfjs-multi.css';
import moment from 'moment';
import { Redirect } from "react-router-dom";
import ToDoListItems from "./ToDoListItems";
import { PlusOutlined, UploadOutlined } from '@ant-design/icons'; 
import { MultiSelect } from 'primereact/multiselect';
  
const { Option } = Select;

const dataList = [];
const generateList = data => {
    for (let i = 0; i < data.length; i++) {
        const node = data[i];

        dataList.push({ key: node.value, lineage: node.data.lineage });
        if (node.children) {  
            generateList(node.children); 
        }
    }
};
   
const expandable = { expandedRowRender: data => <ToDoListItems row={data} />};
const title = () => 'Here is title';
var footer = () => 'Here is footer';

class ToDoList extends Component {
  constructor(props) {
    super(props)
   
    this.state = {
      hiddenSave: false,
      hiddenDataGiris:true,
      hiddenUpdate: true,
      nodes: [],
      pdfFiles:[],
      toDoListTable:[],
      dynamicAttributes: [],
      dynamicInputs: [],
      authorizations: [],
      date: null,
      createNewButtonVariant: "info",
      createNewButton: "Create New",
      assigneds:[],
      messageAssigneds:[],
      CCs:[],
      messageCCs:[],
      ToDoListMessageAssigneds:[],
      ToDoListMessageCCs:[],
      fileList: [],
      FileUpload:[],
      filePath:"",
      checkEnabled:false,
      hiddenNextStep:false,
      toDoListItems:[],

    };

    this.actionTemplateDelete = this.actionTemplateDelete.bind(this);  
    this.onCCChange = this.onCCChange.bind(this);

  }

  formRef = React.createRef();

componentDidMount = async () => { 
  const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "ToDoList" });
  if (responseDP.data.length > 0) {
      this.setState({
          dynamicAttributes: responseDP.data
      });
  } else {
      this.setState({
          dynamicAttributes: []
      });
  }

  const obj = {
      pageName: "ToDoList",
  };
  const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
  if (responseDynamicInputs.data.length !== 0) {
      this.setState({
          dynamicInputs: responseDynamicInputs.data,
      });
  } else {
      this.setState({
          dynamicInputs: [],
      });
  }
  
  const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "ToDoList");
  try {
    this.setState({
      authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
    });
  } catch (error) {
  }
    
  this.restartTable();
  this.getCodesToDoListMessageAssigned();
  this.getCodesToDoListMessageCC();
  this.getTableProperties();
  this.getAssigneds();
} 

getTableProperties = async () => {
  this.toDoList = JSON.parse(localStorage.getItem('toDoList'));
  if (this.toDoList != null && this.toDoList != undefined && this.toDoList != "") {

      await this.setState({
          bordered: this.toDoList.bordered,
          title: this.toDoList.titleDrm == true ? title : undefined,
          titleDrm: this.toDoList.titleDrm,
          showHeader: this.toDoList.showHeader,
          footer: this.toDoList.footerDrm == true ? footer : undefined,
          footerDrm: this.toDoList.footerDrm,
          expandable: this.toDoList.expandableDrm == true ? expandable : {},
          expandableDrm: this.toDoList.expandableDrm,
          rowSelection: this.toDoList.rowSelection,
          ellipsis: this.toDoList.ellipsis,
          tableLayout: this.toDoList.tableLayout,
          size: this.toDoList.size,
          top: this.toDoList.top,
          bottom: this.toDoList.bottom,
          editBgColor: this.toDoList.editBgColor,
          deleteBgColor: this.toDoList.deleteBgColor,
          selectedNodeKey: null,
          yScroll: this.toDoList.yScroll,
          xScroll: this.toDoList.xScroll,
          paginationDisabled: {
              defaultPageSize: 5, total: this.state.toDoListTable.length,
              showQuickJumper: true, showSizeChanger: true,
              pageSizeOptions: ['5', '10', '25', '50'], total: this.state.toDoListTable.length, showQuickJumper: true,
              position: [this.toDoList.top, this.toDoList.bottom]
          },
          kontrol: true,
      });
  } else {
      await this.setState({
          bordered: false,
          title: undefined,
          titleDrm: false,
          showHeader: true,
          footer: undefined,
          footerDrm: false,
          expandable: expandable,
          expandableDrm: true,
          rowSelection: undefined,
          ellipsis: false,
          tableLayout: undefined,
          size: 'default',
          top: 'none',
          bottom: 'bottomRight',
          editBgColor: '#fff',
          deleteBgColor: '#fff',
          selectedNodeKey: null,
          xScroll: undefined,
          paginationDisabled: false,
          kontrol: true,
      });
    }
}

getAssigneds = async () => {  
  const response = await handleRequest("GET", "/User/getCompanyItems"); 
  if (response.data.length !== 0) {
    const columns = response.data;
    const options = columns.map(function (user) {
      if(user.staff!=null && user.staff!=undefined){
        return { 
          label: user.staff.name.toString() + " " + user.staff.lastName.toString() , value: user.id.toString() 
        }           
      }
    }) 
    this.setState({
      assigneds: response.data,
      CCs: options,
    });
  }else{
    this.setState({
      assigneds: [],
      CCs:[]
    });
  }
} 
  
showOrHideModalPdfMultiViewer = () => {
    if(this.state.pdfFiles.length>0){
      this.setState({
        modalShowPdfMultiViewer: !this.state.modalShowPdfMultiViewer 
      });
      setTimeout(() => {
        document.body.style.overflow = "auto";
        document.body.style.paddingRight = "0px";
      }, 500);
    }else{
      this.showError("File cant found.");
    }
};

actionTemplateDelete(rowData) { 
  // if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
  //   return <React.Fragment>
  //     <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.deleteModal.bind(this)}
  //       onEditRow={this.changeButtonUpdate.bind(this)} />
  //     </React.Fragment>;
  // } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
  //   return <React.Fragment>
  //     <CustomMaterialMenuEdit row={rowData}
  //       onEditRow={this.changeButtonUpdate.bind(this)} />
  //     </React.Fragment>;
  // }
  // else 
  if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
    return <React.Fragment>
      <CustomMaterialMenuDelete row={rowData}
        onDeleteRow={this.deleteModal.bind(this)} />
      </React.Fragment>;
  }
};

deleteModal = (row) => {
  this.setState({ 
    deleteModal: true,
    selectedToDoList: row
  });
}

showOrHideModalDelete = () => {
  this.setState({
    deleteModal: !this.state.deleteModal
  });
  setTimeout(() => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
}, 500);
}

saveToDoList = async () => {
  var toDoListItems = [];
  var knt=true;
  if(this.state.toDoListItems.length>0 || this.state.hiddenNextStep==false){
    if(this.state.hiddenNextStep==false){
      if(this.state.action!=null && this.state.action!=undefined && this.state.action!="" &&
      this.state.assignedId!=null && this.state.assignedId!=undefined && this.state.assignedId!="" &&
      this.state.messageAssignedId!=null && this.state.messageAssignedId!=undefined && this.state.messageAssignedId!="" &&
      this.state.ccId!=null && this.state.ccId!=undefined && this.state.ccId!="" &&
      this.state.messageCCId!=null && this.state.messageCCId!=undefined && this.state.messageCCId!="" &&
      this.state.reminderDateTime!=null && this.state.reminderDateTime!=undefined && this.state.reminderDateTime!=""){
        const listItems = {
            action: this.state.action,
            assignedId: this.state.assignedId,
            messageAssignedId: this.state.messageAssignedId,
            ccIds: this.state.ccId,
            messageCCId: this.state.messageCCId,
            reminder: this.state.reminder,
            reminderType: this.state.reminderType,
            reminderDateTime: this.state.reminderDateTime,
            filePath: this.state.filePath,
        }
        toDoListItems.push(listItems);
      }else{
        knt=false;
        this.showWarning();
      }
    }
    if(knt){
      const addToDoList = {
        task: this.state.task,
        date: moment(this.state.date),
        status: this.state.status,

        toDoListItems: this.state.hiddenNextStep==false ? toDoListItems : this.state.toDoListItems,
      }
      var response = await handleRequest("POST", "/ToDoList/add", addToDoList);
      if (response.data !== null && response.data !== undefined) {
        if (response.data === "SUCCESS") {
          this.resetInputs();
          this.restartTable();
          this.showSuccess();
          this.createNewClientButton();
        }
        else {
          this.showError();
        }
      }
    }
  }else{
    this.showWarning();
  }
}

createNextStepButton = async () => { 
  if(this.state.action!=null && this.state.action!=undefined && this.state.action!="" &&
  this.state.assignedId!=null && this.state.assignedId!=undefined && this.state.assignedId!="" &&
  this.state.messageAssignedId!=null && this.state.messageAssignedId!=undefined && this.state.messageAssignedId!="" &&
  this.state.ccId!=null && this.state.ccId!=undefined && this.state.ccId!="" &&
  this.state.messageCCId!=null && this.state.messageCCId!=undefined && this.state.messageCCId!="" &&
  this.state.reminderDateTime!=null && this.state.reminderDateTime!=undefined && this.state.reminderDateTime!=""){
    const toDoListItems = {
      action: this.state.action,
      assignedId: this.state.assignedId,
      messageAssignedId: this.state.messageAssignedId,
      ccIds: this.state.ccId,
      messageCCId: this.state.messageCCId,
      reminder: this.state.reminder,
      reminderType: this.state.reminderType,
      reminderDateTime: this.state.reminderDateTime,
      filePath: this.state.filePath,
    }
   
    this.setState(prevState => ({
      toDoListItems: [...prevState.toDoListItems, toDoListItems]
    }));
  
    this.setState({
      checkEnabled:true,
  
      action: "",
      assignedId: "",
      messageAssignedId: "",
      ccIds: "",
      messageCCId: "",
      reminder: "",
      reminderType: "",
      reminderDateTime: "",
      FileUpload:[], 
      filePath:"",
    });
  
    this.formRef.current.setFieldsValue({
      Action:"",
      AssignedTo:"",
      MessageAssignedTo:"",
      CC:"",
      CCMessage:"",
      ReminderDateTime:null, 
    });
    this.showSuccess();
  }else{
    this.showWarning();
  } 
}

updateToDoList = async () => {
  // const updatedToDoList = {
  //   id: this.state.selectedToDoList.id,
  //   discipline: this.state.discipline,
  //   activity: this.state.values[this.state.values.length - 1],
  //   date: this.state.date,
  // }
  // var response2 = await handleRequest("POST", "/ToDoList/update", updatedToDoList);
  // if (response2.data !== null && response2.data !== undefined) {
  //     if (response2.data === "SUCCESS") {
  //       this.resetInputs();
  //       this.restartTable();
  //       this.showSuccess();
  //     }
  //     else{
  //       this.showError();
  //     }
  // }
}

restartTable = async () => {
  const response = await handleRequest("GET", "/ToDoList/getallByCompany");
  if (response.data.length === 0) {
    this.setState({
      toDoListTable: [],
    });
  }

  if (response.data.length !== 0) {
    this.setState({
      toDoListTable: response.data,
    });
  }
}

deleteToDoListOK = async () => {
  const deleteToDoList = {
      id: this.state.selectedToDoList.id
  }
  var response = await handleRequest("POST", "/ToDoList/delete", deleteToDoList);
  if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS") {
        this.restartTable();
        this.showSuccess();
        this.showOrHideModalDelete();
      }
      else {
          this.showError();
      }
  }
  setTimeout(() => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
}, 500);
}

changeButtonUpdate = (row) => {
  var date=null;
  if(row.date!=null && row.date!=undefined && row.date!=""){
    date=row.date;
  }

  this.formRef.current.setFieldsValue({
    Date: date!=null ? moment(date, 'YYYY-MM-DD') : null,
    ToDoTask:row.task,

  });
    
  this.setState({
    selectedToDoList: row,
    date: date,
    task: row.task,

    hiddenSave: true,
    hiddenUpdate: false,
    hiddenDataGiris: false,
    createNewButton: "Cancel",
    createNewButtonVariant: "danger",
  });
}

resetInputs = () => {
  this.setState({
    task:"",
    date:"",
    toDoListItems:[],
    action: "",
    assignedId: "",
    messageAssignedId: "",
    ccIds: "",
    messageCCId: "",
    reminder: "",
    reminderType: "",
    reminderDateTime: "",
    FileUpload:[], 
    filePath:"",

    checkEnabled:false,
    hiddenNextStep:false,
    hiddenSave: false,
    hiddenUpdate: true,
    hiddenDataGiris:true
  });

  this.formRef.current.setFieldsValue({
    ToDoTask:"",
    Date:"",
    Action:"",
    AssignedTo:"",
    MessageAssignedTo:"",
    CC:"",
    CCMessage:"",
    ReminderDateTime:null, 
  }); 
}
  
changeButtonCancel = () => {
  this.formRef.current.setFieldsValue({
    ToDoTask:"",
    Date:"",
    Action:"",
    AssignedTo:"",
    MessageAssignedTo:"",
    CC:"",
    CCMessage:"",
    ReminderDateTime:null, 
  });

  this.setState({
    task:"",
    date:"",
    toDoListItems:[],
    action: "",
    assignedId: "",
    messageAssignedId: "",
    ccIds: "",
    messageCCId: "",
    reminder: "",
    reminderType: "",
    reminderDateTime: "",
    FileUpload:[], 
    filePath:"",
    
    hiddenSave: false,
    hiddenUpdate: true,
    hiddenDataGiris:true,
    createNewButton: "Create New",
    createNewButtonVariant: "info", 
  });
}

showOrHideModalDeleteMessage = () => {
  this.setState({
      modalMessage: !this.state.modalMessage,
  });
  setTimeout(() => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
}, 500);
}

createNewClientButton = async () => {
  await this.resetInputs();  

   if (this.state.createNewButton=="Create New") {
     this.setState({ 
      hiddenDataGiris: false, 
      hiddenSave:false,
      hiddenUpdate:true,
      hiddenCreateNewButton:false,
      createNewButton: "Cancel",
      createNewButtonVariant: "danger",
     });
  }
  else {
     this.setState({ 
      hiddenDataGiris: true,
      hiddenSave:true,
      hiddenUpdate:false,
      hiddenCreateNewButton:false,
      createNewButton: "Create New",
      createNewButtonVariant: "info",
     });
  }
}
 
showOrHideModalPdfViewer = () => {
  this.setState({
    modalPdfViewer: !this.state.modalPdfViewer,
  });
  setTimeout(() => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
}, 500);
};

showSuccess(msg) {
    if (!msg) {
        msg = 'Successful';
    }
    this.setState({
        modalMessage:true,
        message:msg,
        messageTip:"Success"
    });
};

showError(msg) {
    if (!msg) {
        msg = 'An error was occured please try again later !';
    }
    this.setState({
        modalMessage:true,
        message:msg,
        messageTip:"Error"
    });
};

showWarning(msg) {
  if (!msg) {
      msg = 'Something went wrong. Please check inputs.';
  }
  this.setState({
      modalMessage:true,
      message:msg,
      messageTip:"Warning"
  });
};

showOrHideModalMessage = () => {
  this.setState({
      modalMessage: !this.state.modalMessage,
  }); 
  setTimeout(() => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
}, 500);
}

showOrHidePhotoModal = () => {
  this.setState({
    modalPhotoShow: !this.state.modalPhotoShow,
  });
  setTimeout(() => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
}, 500);
};

showOrHideModalTable = async (tableTip) => {
  this.setState({
      modalTable: !this.state.modalTable,
      tableTip:tableTip,
  });
  setTimeout(() => {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
}, 500);
}

onChangeDate = (value, dateString) => {
  this.setState({
    date: dateString,
  });
}

onChangeReminderDateTime = (value, dateString) => {
  this.setState({
    reminderDateTime: dateString,
  });
}

disabledDate(current) {
  return moment().add(-1, 'days').endOf('day') > current ;
}

onAssignedToChange = (value) => {
  this.setState({
    assignedId: value, 
  });
}
  
onMessageCCChange = (value) => {
  this.setState({
    ccId: value, 
  });
}
  
handleChangeToDoListMessageAssigned = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.ToDoListMessageAssigneds.length; z++) {
    if (this.state.ToDoListMessageAssigneds[z].id == e) {
      deger = this.state.ToDoListMessageAssigneds[z].name;
    }
  }
  this.setState({
      messageAssignedAck: deger,
      messageAssignedId: e,
  });
};

addToDoListMessageAssigneds = async () => {
  const { itemToDoListMessageAssigneds } = this.state;
  if (itemToDoListMessageAssigneds != null && itemToDoListMessageAssigneds != "" && itemToDoListMessageAssigneds != undefined) {
      const newItem = {
      name: itemToDoListMessageAssigneds,
      type: "ToDoListMessageAssigned"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemToDoListMessageAssigneds: '',
      });
      this.getCodesToDoListMessageAssigned();
  } else {
      this.showWarning();
  }
};

getCodesToDoListMessageAssigned = async () => {
  const response = await handleRequest("GET", "/Code/type/ToDoListMessageAssigned");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      ToDoListMessageAssigneds: response.data
    });
  }
} 

handleChangeToDoListMessageCC = (e) => {
  var deger = "";
  for (var z = 0; z < this.state.ToDoListMessageCCs.length; z++) {
    if (this.state.ToDoListMessageCCs[z].id == e) {
      deger = this.state.ToDoListMessageCCs[z].name;
    }
  }
  this.setState({
      messageCCAck: deger,
      messageCCId: e,
  });
};

addToDoListMessageCCs = async () => {
  const { itemToDoListMessageCCs } = this.state;
  if (itemToDoListMessageCCs != null && itemToDoListMessageCCs != "" && itemToDoListMessageCCs != undefined) {
      const newItem = {
      name: itemToDoListMessageCCs,
      type: "ToDoListMessageCC"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
      itemToDoListMessageCCs: '',
      });
      this.getCodesToDoListMessageCC();
  } else {
      this.showWarning();
  }
};

getCodesToDoListMessageCC = async () => {
  const response = await handleRequest("GET", "/Code/type/ToDoListMessageCC");   

  if (response.data !== null && response.data !== undefined) {
    this.setState({
      ToDoListMessageCCs: response.data
    });
  }
} 

onCCChange(event) {
  this.setState({ 
    ccId: event.value 
  });
}

render() {
  const props = {
    action: `${API_BASE}/File/upload`,
    listType: 'picture',
    defaultFileList: [...this.state.fileList],
  };

  var itemTaskAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Task");
  var itemActionAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Action");
  var itemAssignedToAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "AssignedTo");
  var itemMessageAssignedToAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "MessageAssignedTo");
  var itemCCAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "CC");
  var itemCCMessageAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "CCMessage");
  var itemDateAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Date");
  var itemAddFileAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "AddFile");
  var itemReminderDateTimeAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ReminderDateTime");

  var itemTaskRequired = this.state.dynamicInputs.find(p => p.inputKey === "Task");
  var itemActionRequired = this.state.dynamicInputs.find(p => p.inputKey === "Action");
  var itemAssignedToRequired = this.state.dynamicInputs.find(p => p.inputKey === "AssignedTo");
  var itemMessageAssignedToRequired = this.state.dynamicInputs.find(p => p.inputKey === "MessageAssignedTo");
  var itemCCRequired = this.state.dynamicInputs.find(p => p.inputKey === "CC");
  var itemCCMessageRequired = this.state.dynamicInputs.find(p => p.inputKey === "CCMessage");
  var itemDateRequired = this.state.dynamicInputs.find(p => p.inputKey === "Date");
  var itemAddFileRequired = this.state.dynamicInputs.find(p => p.inputKey === "AddFile");
  var itemReminderDateTimeRequired = this.state.dynamicInputs.find(p => p.inputKey === "ReminderDateTime");

const columns = [
    {
      key: "key",
      title: "",
      render: this.actionTemplateDelete
    },        
    {
      dataIndex: "index",
      key: "key",
      title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("toDoList")} icon="pi pi-cog"></PrimeButton>,
    },
    {
      dataIndex: "task",
      key: "key",
      title: <FormattedMessage id="ToDoTask" defaultMessage="Task" />,
    },
    {
      dataIndex: "date",
      key: "key",
      title: <FormattedMessage id="ToDoDate" defaultMessage="Date" />,
    },
    {
      dataIndex: "user",
      key: "key",
      title: <FormattedMessage id="ToDoBy" defaultMessage="By" />,
      render: (data) =>   
      {  
        if(data!=null && data!=undefined){
            return data.staff.name + " " + data.staff.lastName;
        } 
        return "";
      }
    },
    {
      dataIndex: "status",
      key: "key",
      title: <FormattedMessage id="ToDoStatus" defaultMessage="Status" />,
    },
];

const tableColumns = columns.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
if (this.state.xScroll === 'fixed') {
  tableColumns[0].fixed = true;
  tableColumns[tableColumns.length - 1].fixed = 'right';
}

const layoutView = (
  isSidebarOpened,
  container,
  main,
  toolbar,
  sidebar,
) => {
  return defaultLayout(
    isSidebarOpened,
    container,
    main,
    toolbar(renderToolbar),
    sidebar,
  );
};

const onFinish = values => {
  console.log('Success:', values);
};

const onFinishFailed = errorInfo => {
  console.log('Failed:', errorInfo);
};

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8 },
};

const renderToolbar = (toolbarSlot) => {
  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        width: '100%',
      }}
    >
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          flexShrink: 1,
          justifyContent: 'center',
        }}
      >
        <div style={{ padding: '0 2px' }}>
          {toolbarSlot.previousPageButton}
        </div>
        <div style={{ padding: '0 2px' }}>
          {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
        </div>
        <div style={{ padding: '0 2px' }}>
          {toolbarSlot.nextPageButton}
        </div>
        <div style={{ padding: '0 2px' }}>
          {toolbarSlot.zoomOutButton}
        </div>
        <div style={{ padding: '0 2px' }}>
          {toolbarSlot.zoomPopover}
        </div>
        <div style={{ padding: '0 2px' }}>
          {toolbarSlot.zoomInButton}
        </div>
        <div style={{ padding: '0 2px' }}>
          {toolbarSlot.downloadButton}
        </div>
        <div style={{ padding: '0 2px' }}>
          {toolbarSlot.moreActionsPopover}
        </div>
      </div>
    </div>
  );
};

  return this.state.authorizations.length > 0 && (this.state.authorizations.findIndex(p => p.action === "view") == -1 ? (<div><Redirect to="/error/error-v6" /></div>) : (
    <div>
        {(this.state.dynamicAttributes.length > 0 && this.state.dynamicInputs.length > 0) && 
         <Form
                {...layout}
                initialValues={{ remember: false }}
                onFinish={this.state.hiddenUpdate == false ? this.updateToDoList : this.saveToDoList}
                onFinishFailed={onFinishFailed}
                ref={this.formRef} >
 
            <Row>
                <Col xs={8}></Col>
                <Col xs={3}>
                    <Button onClick={this.createNewClientButton} style={{ width: '100%' }}
                        variant={this.state.createNewButtonVariant} >{this.state.createNewButton}
                    </Button>
                </Col>
                <Col xs={1}>
                  <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable("toDoList")} icon="pi pi-cog"></PrimeButton>
                </Col>
            </Row>
     
            <div hidden={this.state.hiddenDataGiris}>
              <br/>
                {(itemTaskAttributes.visible) &&
                  <Form.Item
                      label={<FormattedMessage id="ToDoTask" defaultMessage="Task" />}
                      name="ToDoTask"
                      rules={[{ required: itemTaskRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemTaskRequired.translateRequired)==true ? itemTaskRequired.translateRequired : -1} defaultMessage="Please input your Task!" /> }]} >
                       <Input id="ToDoTask" value={this.state.task} onChange={(e) =>
                            this.setState({ task: e.target.value })
                        } />
                  </Form.Item>}

                {(itemDateAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="ToDoDate" defaultMessage="Date" />}
                  name="Date"
                  rules={[{ required: itemDateRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemDateRequired.translateRequired)==true ? itemDateRequired.translateRequired : -1} defaultMessage="Please input your Date!" /> }]} >
                    <DatePicker disabledDate={this.disabledDate} value={moment(this.state.date, 'DD/MM/YYYY')}
                      format="DD/MM/YYYY" onChange={this.onChangeDate} />
                </Form.Item>} 

                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2">
                    </Col>
                      <Col sm="3">
                        <FormBoot.Check 
                          onChange={(e) => { 
                            this.setState({ 
                              hiddenNextStep: e.target.checked
                            })
                          }}
                          disabled={this.state.checkEnabled}
                          type="checkbox"
                          label={<FormattedMessage id="ToDoSteppedActions" defaultMessage="Stepped Actions" />} />
                      </Col>
                  </FormBoot.Group> 

                {(itemActionAttributes.visible) &&
                  <Form.Item
                      label={<FormattedMessage id="ToDoAction" defaultMessage="Action" />}
                      name="Action"
                      rules={[{ required: itemActionRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemActionRequired.translateRequired)==true ? itemActionRequired.translateRequired : -1} defaultMessage="Please input your Action!" /> }]} >
                      <Input id="ToDoAction" value={this.state.action} onChange={(e) =>
                              this.setState({ action: e.target.value })
                          } />
                  </Form.Item>}  

                {(itemAssignedToAttributes.visible) &&
                  <Form.Item
                      label={<FormattedMessage id="ToDoAssignedTo" defaultMessage="Assigned to" />}
                      name="AssignedTo"
                      rules={[{ required: itemAssignedToRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemAssignedToRequired.translateRequired)==true ? itemAssignedToRequired.translateRequired : -1} defaultMessage="Please input your Assigned to!" /> }]} >
                      <Select size='large'
                        style={{ width: '100%' }} 
                        value={this.state.assignedId}
                        onChange={this.onAssignedToChange}
                        >
                        {this.state.assigneds.map(user => ( 
                         (user.staff!=null && user.staff!=undefined) && 
                         <Option key={user.id} value={user.id}>{
                          user.staff.name + " " + 
                          user.staff.lastName
                         }</Option>
                        ))}
                    </Select>
                  </Form.Item>}   

                {(itemMessageAssignedToAttributes.visible) &&
                <Form.Item
                      label={<FormattedMessage id="ToDoMessageAssignedTo" defaultMessage="Message" />}
                      name="MessageAssignedTo"
                      rules={[{ required: itemMessageAssignedToRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemMessageAssignedToRequired.translateRequired)==true ? itemMessageAssignedToRequired.translateRequired : -1} defaultMessage="Please input your Message!" /> }]} >
                      <Select
                        name="messageAssignedId"
                        style={{ width: '100%' }}
                        value={this.state.messageAssignedAck}
                        onChange={this.handleChangeToDoListMessageAssigned}

                        dropdownRender={menu => (
                        <div>
                            {menu}
                            <Divider style={{ margin: '4px 0' }} />
                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                            <Input style={{ flex: 'auto' }} value={this.state.itemToDoListMessageAssigneds}
                                onChange={(event) => { this.setState({ itemToDoListMessageAssigneds: event.target.value }) }} />
                            <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                onClick={this.addToDoListMessageAssigneds} >
                                <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add Item"/>  </a>
                            </div>
                        </div>
                        )}>
                        {this.state.ToDoListMessageAssigneds.map(i => (
                            <Option key={i.id} value={i.id}>{i.name}</Option>
                        ))}
                      </Select>
                  </Form.Item>}    
                  
                {(itemCCAttributes.visible) &&
                <Form.Item
                    label={<FormattedMessage id="ToDoCC" defaultMessage="CC" />}
                    name="CC"
                    rules={[{ required: itemCCRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemCCRequired.translateRequired)==true ? itemCCRequired.translateRequired : -1} defaultMessage="Please input your CC!" /> }]} >
                  
                  <MultiSelect id="ToDoCC" className="p-column-filter" value={this.state.ccId}
                    options={this.state.CCs} onChange={this.onCCChange} />
                </Form.Item>}   

                {(itemCCMessageAttributes.visible) &&
                <Form.Item
                    label={<FormattedMessage id="ToDoCCMessage" defaultMessage="Message" />}
                    name="CCMessage"
                    rules={[{ required: itemCCMessageRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemCCMessageRequired.translateRequired)==true ? itemCCMessageRequired.translateRequired : -1} defaultMessage="Please input your Message!" /> }]} >
                    
                    <Select
                      name="messageCCId"
                      style={{ width: '100%' }}
                      value={this.state.messageCCAck}
                      onChange={this.handleChangeToDoListMessageCC}

                      dropdownRender={menu => (
                      <div>
                          {menu}
                          <Divider style={{ margin: '4px 0' }} />
                          <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                          <Input style={{ flex: 'auto' }} value={this.state.itemToDoListMessageCCs}
                              onChange={(event) => { this.setState({ itemToDoListMessageCCs: event.target.value }) }} />
                          <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                              onClick={this.addToDoListMessageCCs} >
                              <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add Item"/>  </a>
                          </div>
                      </div>
                      )}>
                      {this.state.ToDoListMessageCCs.map(i => (
                          <Option key={i.id} value={i.id}>{i.name}</Option>
                      ))}
                    </Select>
                </Form.Item>}    
 
                {(itemReminderDateTimeAttributes.visible) &&
                <Form.Item
                  label={<FormattedMessage id="ToDoReminderDateTime" defaultMessage="Reminder Date Time" />}
                  name="ReminderDateTime"
                  rules={[{ required: itemReminderDateTimeRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemReminderDateTimeRequired.translateRequired)==true ? itemReminderDateTimeRequired.translateRequired : -1} defaultMessage="Please input your Reminder Date Time!" /> }]} >
                    <DatePicker disabledDate={this.disabledDate} value={moment(this.state.reminderDateTime, 'DD/MM/YYYY HH:mm')}
                      showTime={{ format: 'HH:mm' }} format="DD/MM/YYYY HH:mm" onChange={this.onChangeReminderDateTime} />
                </Form.Item>}      
                
                {(itemAddFileAttributes.visible) &&
                <Form.Item
                  label="File"
                  name="AddFile"
                  rules={[{ required: itemAddFileRequired.canBeRequired, message: <FormattedMessage id={Boolean(itemAddFileRequired.translateRequired)==true ? itemAddFileRequired.translateRequired : -1} defaultMessage="Please input your File!" /> }]} >
                    <Upload {...props}
                      showUploadList={true}
                      fileList={this.state.FileUpload}
                      onChange={({ fileList }) => {
                        if (fileList.length == 1) {  
                          var filePath="";
                          if(Boolean(fileList[0].response)){
                            filePath=fileList[0].response.url;
                          }
                            this.setState({
                              FileUpload:fileList,
                              filePath:filePath,
                            });
                        }
                    }}
                    beforeUpload={(fileList) => {
                      if (this.state.FileUpload.length > 0) {
                        this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a icon! For change firstly remove the uploaded icon."/>);
                        return false;
                      }
                      return true;
                    }}
                    onRemove={({ fileList }) => {
                      this.setState( {
                        FileUpload:[], 
                        filePath:"",
                      })
                    }
                  }>
                    <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                      <UploadOutlined /> <FormattedMessage id="ToDoAddFile" defaultMessage="Add File"/>
                    </AntdButton>
                  </Upload>
                  
                </Form.Item>} 

                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="2">
                    </Col>
                    <Col sm="4">
                      <Button hidden={!this.state.hiddenNextStep} onClick={this.createNextStepButton} style={{ width: '100%' }}
                          variant="info" > <FormattedMessage id="ToDoNextStep" defaultMessage="Next Step"/>
                      </Button> 
                    </Col>
                    <Col sm="2">
                    </Col>
                </FormBoot.Group>
                  
                <div hidden={this.state.hiddenSave}>
                  {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                    <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                        <Col sm="2">
                        </Col>
                        <Col sm="4">
                            <Button id="ToDoListSaveButton"
                                style={{ width: '100%' }} type="submit"
                                variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" /></Button>
                        </Col>
                        <Col sm="2">
                        </Col>
                    </FormBoot.Group>
                  }
                </div>

                <div hidden={this.state.hiddenUpdate}>
                  {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 &&
                      <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                          <Col sm="2"></Col>
                          <Col sm="2">
                              <Button id="ToDoListCancelButton" onClick={this.changeButtonCancel} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></Button>
                          </Col>
                          <Col sm="2">
                              <Button id="ToDoListUpdateButton" type="submit" style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></Button>
                          </Col>
                          <Col sm="2">
                          </Col>
                      </FormBoot.Group>
                  }
                </div>
          
            </div> 
            <br />
            <br />

            <div className="datatable-doc-demo">
                <div className="content-section implementation">
                  <Mnd.MNDTable
                    state={this.state}
                    columns={tableColumns} dataSource={this.state.toDoListTable}
                    pagination={this.state.paginationDisabled}
                    expandable={this.state.expandable}
                    footer={this.state.footer}
                    title={this.state.title}
                    scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}
                  /> 
                 </div>
              </div>
    
        </Form> }

      <Modal
          show={this.state.modalMessage}
          onHide={this.showOrHideModalMessage}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          >

          <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
              <Row > 
                <Col sm="12">
                  {this.state.messageTip=="Success" &&  
                    <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} /> }
                
                  {this.state.messageTip=="Warning" &&  
                  <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                  {this.state.messageTip=="Error" &&  
                  <i className="fas fa-times fa-2x" style={{ color: '#ff0400'}} />}
                </Col>
              </Row>
              </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row > 
              <Col sm="12">
                {this.state.message} 
              </Col>
              </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
        </Modal.Footer>
        </Modal>

      <Modal
        size="xl"
        show={this.state.modalPhotoShow}
        onHide={this.showOrHidePhotoModal}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Photo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.imageUrl !== "" ? (
            <img
              src={this.state.imageUrl}
              alt="avatar"
              style={{ width: "100%" }}
            />
          ) : (
              <p>Photo has not been uploaded.</p>
            )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={this.showOrHidePhotoModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="xl"
        show={this.state.modalPdfViewer}
        onHide={this.showOrHideModalPdfViewer}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            PDF Report Viewer
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
            <div style={{ height: "900px" }}>
              <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
            </div>
          </Worker> 

        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={this.showOrHideModalPdfViewer}
          >
            Close
            </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={this.state.modalShowPdfMultiViewer} size="xl" 
        onHide={this.showOrHideModalPdfMultiViewer} 
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered  >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">PDF Report Viewer</Modal.Title>
        </Modal.Header>
          <Modal.Body>

            <PdfMultiViewer
              pdfs={this.state.pdfFiles}
              i18nData={{
                download: 'Download',
                scaleDown: 'Zoom Out',
                scaleUp: 'Zoom In',
                originalSize: 'Original Size',
                pages: 'Pages',
                zoom: 'Automatic Zoom',
              }}
            />  

          </Modal.Body>
          <Modal.Footer>
          <Button variant="secondary" onClick={this.showOrHideModalPdfMultiViewer}>Close</Button> 
        </Modal.Footer>
    </Modal> 

      <Modal
        show={this.state.modalTable}
        onHide={this.showOrHideModalTable}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered size="lg">

        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                <Row >
                    <Col sm="12">
                        <FormattedMessage id="GeneralTableSettings" defaultMessage="Table Settings" />
                    </Col>
                </Row>
            </Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Row >
                <Col sm="12">
                    <MNDTableSettings id={this.state.tableTip} />
                </Col>
            </Row>
        </Modal.Body>
    </Modal>

      <Modal
        show={this.state.deleteModal}
        onHide={this.showOrHideModalDelete}
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
                <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
            </p>
            <p style={{ textAlign: "center", color: "black" }}>
                <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
            </p>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={this.showOrHideModalDelete}>
                <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
            </Button>
            <Button variant="danger" onClick={this.deleteToDoListOK}>
                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
            </Button>
          </Modal.Footer>
        </Modal>

       </div>
    ));
    }
}

const mapStateToProps = state => {
  return {
      auth: state.auth,
      i18n: state.i18n
  }
}

const mapDispatchToProps = {
   
}

export default connect(mapStateToProps, mapDispatchToProps)(ToDoList)