/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Accordion } from 'react-bootstrap';
import { Container, Col, Form, Row } from 'react-bootstrap';
import { Modal, Image } from 'react-bootstrap';
import "react-toggle/style.css";
import { Row as PrimeRow } from 'primereact/row';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { handleRequest } from '../../redux/config/env';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import { IconButton  } from "@material-ui/core";
import Add from '@material-ui/icons/Add';
import { Toast } from 'primereact/toast';
import { Chips } from 'primereact/chips';
import { Checkbox } from 'primereact/checkbox';
import { Switch, InputNumber, Space } from 'antd';
import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { COUNTRIES } from '../settings/JsonDatas/Datas';
// import classes from "../styles/PrimeDataTable.scss";  
import { Button as PrimeBotton } from "primereact/button";
// import classNames from 'classnames';
import { Table, Tag } from 'antd';
import { Select, Divider, Input } from 'antd';
import { Avatar } from 'antd';
import { API_BASE } from '../../redux/config/env';
import { TEMPLATE_Dynamia_ClientID } from '../MND/TableColumns';

const { Option } = Select;


const { Column, ColumnGroup } = Table;

const sortIcon = <ArrowDownward />;
const actions = (
    <IconButton color="primary">
        <Add />
    </IconButton>
);



export class MNDClientLog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modalShow: false,
            companyId: 0,
            selectedRow: {},
            companyList: [],
            hidden: true,
            createNewButton: "Create New",
            createNewButtonVariant: "info",
            lastButton: "Save",
            lastButtonVariant: "success",
            companyFullName: "",
            companyShortName: "",
            companyLogoName: "",
            aboutCompanyName: "",
            aboutCompanyText: "",
            missionName: "",
            missionText: "",
            visionName: "",
            visionText: "",
            itemsContacts: [],
            location: "Select",
            address: "",
            phone: [],
            fax: [],
            web: [],
            email: [],
            social: [],
            inputP: '',
            inputF: '',
            inputW: '',
            inputE: '',
            inputS: '',
            itemsCertificates: [],
            nameOfCertificate: "Select",
            validity: "",
            respDepartment: "",
            certificate: "",
            date: new Date(),
            loading: false,
            imageUrl: null,
            logoPath: "",

            products: [],
            defaultProducts: [],

            contactId: 0,
            representativeId: 0,

            name: "",
            midname: "",
            surname: "",
            position: "",
            rPhone: "",
            rEmail: "",
            country: "",
            projectNames: [],
            projectList: [],
            oldCompany: null,
            headOffice: null,
            selectOption: '',

        };

        // #region tags functions


        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);

        // #endregion tags functions

    }

    componentDidMount = async () => {

        await this.restartTable();
        await this.getProducts();
    }



    getProducts = async () => {

        const response = await handleRequest("GET", "/MNDProducts/getAll");

        if (response.data.length > 0) {
            var list = [];
            response.data.forEach(element => {
                var obj = {
                    id: 0,

                    name: element.productName,
                    productId: element.id,
                    single: true,
                    projectCount: 1,
                    product: false,
                    projects: []
                    //menus:element.menus  
                }
                list.push(obj);
            });


            this.setState({
                products: list,
                defaultProducts: list.slice()

            });
        }

    }

    restartTable = async () => {

        const response = await handleRequest("GET", "/Company/getAll");

        if (response.data.length === 0) {
            this.setState({
                companyList: []

            });
        }

        if (response.data.length !== 0) {

            const _contactDefault = [{ id: 0, location: "", country: "", address: "", web: "", phones: [], faxes: [], mails: [] }];
            const _representativeDefault = { id: 0, name: "", midname: "", lastname: "", position: "", phone: "", email: "" };

            var rData = response.data;
            console.log(rData);
            if (!rData.contacts)
                rData.contacts = _contactDefault;
            if (!rData.representative)
                rData.representative = _representativeDefault;

            if (!rData.products)
                rData.products = this.state.products;

            rData.forEach(element => {
                element.key = element.id;
            });

            this.setState({
                companyList: rData
            });
        }
    }

    createNew = () => {
        if (this.state.createNewButton === "Create New") {
            this.setState({
                hidden: false,
                createNewButton: "Stop Adding New",
                lastButton: "Save",
                lastButtonVariant: "success",
                createNewButtonVariant: "danger",
                companyId: 0,
                companyFullName: "",
                companyShortName: "",
                aboutCompanyText: "",
                visionText: "",
                missionText: "",
                logoPath: ""

            });
            //this.refreshAccordions();
        } else {
            this.getProducts();

            this.setState({
                hidden: true,
                createNewButton: "Create New",
                lastButton: "Save",
                lastButtonVariant: "success",
                createNewButtonVariant: "info",
                companyId: 0,
                companyFullName: "",
                companyShortName: "",
                aboutCompanyText: "",
                visionText: "",
                missionText: "",

                address: "",
                country: "Select Country ",
                phone: [],
                fax: [],
                email: [],
                web: "",
                name: "",
                midname: "",
                surname: "",
                position: "",
                rPhone: "",
                rEmail: "",
                logoPath: "",


                oldCompany: null,
                contactId: 0,
                representativeId: 0,



            });
            //this.refreshAccordions();
        }

    };

    saveCompany = async () => {


        if (this.state.companyFullName.trim() !== "") {

            var _phoneList = [];

            this.state.phone.forEach(element => {
                const item = { phone: element };
                _phoneList.push(item);
            });

            var _faxList = [];

            this.state.fax.forEach(element => {
                const item = { fax: element };
                _faxList.push(item);
            });

            var _mailList = [];

            this.state.email.forEach(element => {
                const item = { mail: element };
                _mailList.push(item);
            });

            const _contacts = [{ id: this.state.contactId, location: "Head Office", country: this.state.country, address: this.state.address, web: this.state.web, phones: _phoneList, faxes: _faxList, mails: _mailList }];
            const _representative = { id: this.state.representativeId, name: this.state.name, midname: this.state.midname, lastname: this.state.surname, position: this.state.position, phone: this.state.rPhone, email: this.state.rEmail };

            var _projects = [];
            const _projectList = this.state.projectList;

            _projectList.forEach(element => {

                element.products.forEach(p => {
                    p.active = false;
                });
            });

            if (this.state.products) {

                this.state.products.forEach(element => {

                    if (element.projects != null && element.projects !== undefined)
                        element.projects.forEach(pName => {
                            var index = _projects.findIndex(p => p.fullname === pName);
                            if (index >= 0) {
                                let proObj = { id: 0, productId: element.productId };

                                let pro = _projects[index];

                                let product = pro.products.find(p => p.productId == element.productId);
                                if (Boolean(product))
                                    product.active = true;
                                else pro.products.push(proObj);


                            } else {

                                let proObj2 = { productId: element.productId };
                                let pro = _projectList.find(p => p.fullname === pName);

                                if (Boolean(pro)) {
                                    var obj = pro; // { id: pro.id, fullname: pName, products:  };
                                    let product = obj.products.find(p => p.productId == element.productId);
                                    if (Boolean(product))
                                        product.active = true;
                                    else obj.products.push(proObj2);

                                }
                                else {
                                    obj = { id: 0, fullname: pName, products: [] };

                                    obj.products.push(proObj2);

                                }
                                _projects.push(obj);

                            }

                        });

                });

                _projectList.forEach(e => {
                    let snc = _projects.find(p => p.id === e.id);
                    if (!Boolean(snc))
                        _projects.push(e);

                });
            }

            if (Boolean(this.state.headOffice)) {
                _projects.push(this.state.headOffice)
            }


            const newItem = {
                id: this.state.companyId,
                fullName: this.state.companyFullName,
                shortName: this.state.companyShortName,
                contacts: _contacts,
                representative: _representative,
                products: this.state.products,
                oldCompany: this.state.oldCompany,
                representativeId: this.state.representativeId,
                projects: _projects,
                logoPath: this.state.logoPath
            }

            if (newItem.id === 0)
                await handleRequest("POST", "/Company/add", newItem);
            else await handleRequest("POST", "/Company/update", newItem);

            this.resetInputs();
            this.restartTable();
            this.showSuccess();
        } else {
            this.showError();
        }
    };

    resetInputs = () => {
        this.getProducts();
        this.setState({
            hidden: true,
            modalShow: false,
            createNewButton: "Create New",
            lastButton: "Save",
            lastButtonVariant: "success",
            createNewButtonVariant: "info",
            companyId: 0,
            companyFullName: "",
            companyShortName: "",

            address: "",
            country: "Select Country ",
            phone: [],
            fax: [],
            email: [],
            web: "",
            name: "",
            midname: "",
            surname: "",
            position: "",
            rPhone: "",
            rEmail: "",

            oldCompany: null,
            contactId: 0,
            representativeId: 0,
        });
    };


    edit = async (row) => {


        // var _productDynamia = { product: false, single: true, projectCount: 1 };
        // var _productSwim = { product: false, single: true, projectCount: 1 };
        // var _productTruckSM = { product: false, single: true, projectCount: 1 };

        const _contactDefault = { id: 0, location: "", country: "", address: "", web: "", phones: [], faxes: [], mails: [] };
        const _representativeDefault = { id: 0, name: "", midname: "", lastname: "", position: "", phone: "", email: "" };
        var _representative;
        var _contact;
        var _phones = [];
        var _faxes = [];
        var _mails = [];
        if (row.contacts !== null && row.contacts !== undefined) {
            var index = row.contacts.findIndex(p => p.location === "Head Office");

            if (index >= 0) {
                _contact = row.contacts[index];

                if (_contact.phones !== null && _contact.phones !== undefined)
                    _contact.phones.forEach(element => {
                        _phones.push(element.phone);
                    });

                if (_contact.faxes !== null && _contact.faxes !== undefined)
                    _contact.faxes.forEach(element => {
                        _faxes.push(element.fax);
                    });

                if (_contact.mails !== null && _contact.mails !== undefined)
                    _contact.mails.forEach(element => {
                        _mails.push(element.mail);
                    });


            }
            else _contact = _contactDefault;


        } else _contact = _contactDefault;

        if (row.representative !== null && row.representative !== undefined)
            _representative = row.representative;
        else _representative = _representativeDefault;

        await this.getProducts();
        var _productsDef = this.state.defaultProducts.slice();

        if (row.products !== null && row.products !== undefined && row.products.length > 0) {




            row.products.forEach(element => {
                var index = _productsDef.findIndex(p => p.productId === element.productId);

                if (index >= 0) {
                    _productsDef[index].id = element.id;
                    _productsDef[index].single = element.single;
                    _productsDef[index].product = element.product;
                    _productsDef[index].projectCount = element.projectCount;
                    _productsDef[index].companyId = element.companyId;
                    _productsDef[index].projects = this.getProjectsString(row.projects.filter(p => p.fullname != "Head Office"), element.productId);
                }

            });


        }

        if (row.projects !== null && row.projects !== undefined && row.projects.length > 0) {

            const options = row.projects.filter(p => p.fullname != "Head Office").map(function (element) {
                return { key: element.fullname, value: element.fullname }
            })



            this.setState({
                projectNames: options,
                projectList: row.projects.filter(p => p.fullname != "Head Office"),
                headOffice: row.projects.find(p => p.fullname == "Head Office"),
            });

        }


        var _imageUrl = "";

        if (row.logoPath !== null && row.logoPath !== undefined && row.logoPath.length > 0) {
            _imageUrl = `${API_BASE}/Company/download/` + row.id
        }


        this.setState({
            hidden: false,
            createNewButton: "Stop Updating",
            lastButton: "Update",
            lastButtonVariant: "warning",
            createNewButtonVariant: "danger",
            companyId: row.id,
            companyFullName: row.fullName,
            companyShortName: row.shortName,
            aboutCompanyText: row.about,
            visionText: row.vision,
            missionText: row.mision,

            contactId: _contact.id,
            address: _contact.address,
            country: _contact.country,
            phone: _phones,
            fax: _faxes,
            email: _mails,
            web: _contact.web,

            representativeId: _representative.id,
            name: _representative.name,
            midname: _representative.midname,
            surname: _representative.lastname,
            position: _representative.position,
            rPhone: _representative.phone,
            rEmail: _representative.email,
            products: _productsDef,
            imageUrl: _imageUrl,
            logoPath: row.logoPath,

            oldCompany: row


        });


        document.getElementById('kt_scrolltop').click();
    }

    getProjectsString = (projectList, productId) => {

        console.log(projectList);
        console.log(productId);
        var result = [];
        if (projectList !== null && projectList !== undefined) {
            projectList.forEach(element => {


                if (element.products !== null && element.products !== undefined) {
                    var index = element.products.findIndex(p => p.productId == productId);
                    if (index >= 0) {
                        result.push(element.fullname);
                    }

                }

            });


        }

        console.log(result);
        return result;

    }

    deleteModal = (row) => {

        this.setState({
            companyId: row.id,
            modalShow: true
        });
    }

    delete = async () => {


        const deletedItem = {
            id: this.state.companyId

        }

        await handleRequest("POST", "/Company/delete", deletedItem);

        this.restartTable();
        this.resetInputs();
        this.showSuccess();

    }


    showSuccess() {
        this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Successful' });
    };

    showError(message) {
        this.toast.show({ severity: 'error', summary: 'Error Message', detail: message });
    };



    // #endregion tags

    onChange = date => this.setState({ date })

    addContact = () => {
        this.addContactItem();
        this.setState({
            location: "Select",
            address: "",
            phone: [],
            fax: [],
            webs: [],
            email: [],
            socials: []
        });
    }

    addContactItem = () => {
        this.setState(state => ({
            itemsContacts: [...state.itemsContacts, {
                contactId: this.state.itemsContacts.length,
                location: this.state.location,
                address: this.state.address,
                phone: this.state.phone,
                fax: this.state.fax,
                web: this.state.webs,
                email: this.state.email,
                soccAcc: this.state.socials
            }]
        }));
    }

    addCertificate = () => {
        this.addCertificateItem();
        this.setState({
            nameOfCertificate: "Select",
            validity: "",
            respDepartment: "",
            certificate: ""
        });
    }



    addCertificateItem = () => {
        this.setState(state => ({
            itemsCertificates: [...state.itemsCertificates, {
                certificateId: this.state.itemsCertificates.length,
                name: this.state.nameOfCertificate,
                validity: this.state.validity,
                respDepartment: this.state.respDepartment,
                certificate: this.state.certificate
            }]
        }));
    }

    showOrHideModal = () => {
        this.setState({
            modalShow: !this.state.modalShow
        });
    }

    handleChange = e => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    onEditClick(e) {
        if (e.target.value === undefined)
            e.stopPropagation();
    }

    companyLogoChange = e => {
        this.setState({
            companyLogoName: e.target.files[0].name
        });
    }

    aboutCompanyChange = e => {
        this.setState({
            aboutCompanyName: e.target.files[0].name
        });
    }

    missionChange = e => {
        this.setState({
            missionName: e.target.files[0].name
        });
    }

    visionChange = e => {
        this.setState({
            visionName: e.target.files[0].name
        });
    }

    certificateChange = e => {
        this.setState({
            certificate: e.target.files[0].name
        });
    }

    getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 4;
        if (!isLt2M) {
            message.error('Image must smaller than 4MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    handlePhotoChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {

            if (info.file.response.length > 0) {
                this.setState({
                    logoPath: info.file.response[0]
                })
            }

            this.getBase64(info.file.originFileObj, imageUrl =>
                this.setState({
                    imageUrl,
                    loading: false,
                }),
            );
        }
    };

    actionTemplate(rowData, column) {
        return <React.Fragment> <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />   </React.Fragment>;
    };

    numberOfProjects(rowData, column) {


        var result = 0;
        if (rowData.products !== null && rowData.products !== undefined) {


            rowData.products.forEach(element => {
                if (element.product)
                    if (element.single) {
                        result = result + 1;
                    }
                    else result = result + element.projectCount

            });

        }

        return result;
    };

    numberOfProjects2(record) {

        var rowData = record;
        var result = 0;
        if (rowData.products !== null && rowData.products !== undefined) {

            for (let index = 0; index < rowData.products.length; index++) {
                const element = rowData.products[index];

                if (element.product) {
                    if (element.single) {
                        result++;
                    }
                    else result = result + element.projectCount
                }
            }


        }

        return result;
    };

    productList(rowData, column) {


        var result = [];
        if (rowData.products !== null && rowData.products !== undefined) {

            rowData.products.forEach(element => {
                if (element.product)
                    result.push(element.name);

            });

        }

        return result.toString();
    };

    productList2(record) {

        var rowData = record;

        var result = [];
        if (rowData.products !== null && rowData.products !== undefined) {

            rowData.products.forEach(element => {
                if (element.product)
                    result.push(element.name);

            });

        }

        return result.toString();
    };

    phones(value) {

        var rowData = value;

        var result = [];
        if (rowData !== null && rowData !== undefined && rowData.length > 0 && rowData[0].phones !== null && rowData[0].phones !== undefined) {

            rowData[0].phones.forEach(e => {

                result.push(e.phone);

            });

        }

        return result.toString();
    };

    faxes(value) {

        var rowData = value;

        var result = [];
        if (rowData !== null && rowData !== undefined && rowData.length > 0 && rowData[0].faxes !== null && rowData[0].faxes !== undefined) {

            rowData[0].faxes.forEach(e => {

                result.push(e.fax);

            });

        }

        return result.toString();
    };

    mails(value) {

        var rowData = value;

        var result = [];
        if (rowData !== null && rowData !== undefined && rowData.length > 0 && rowData[0].mails !== null && rowData[0].mails !== undefined) {

            rowData[0].mails.forEach(e => {

                result.push(e.mail);

            });

        }

        return result.toString();
    };


    onSelectOptionChange = event => {
        this.setState({
            selectOption: event.target.value,
        });
    };

    addItem = () => {
        const { projectNames, selectOption } = this.state;
        if (selectOption.length > 0) {
            const obj = { key: selectOption, value: selectOption };
            this.setState({
                projectNames: [...projectNames, obj],
                selectOption: '',
            });


        }

    };


    render() {
        const styles = {
            container: {
                border: '1px solid #ddd',
                padding: '5px',
                borderRadius: '5px',
            },
            items: {
                display: 'inline-block',
                padding: '2px',
                border: '1px solid blue',
                fontFamily: 'Helvetica, sans-serif',
                borderRadius: '5px',
                marginRight: '5px',
                cursor: 'pointer'
            },
            input: {
                outline: 'none',
                border: 'none',
                fontSize: '14px',
                fontFamily: 'Helvetica, sans-serif'
            }
        };

        let actionHeader = <PrimeBotton type="button" icon="pi pi-cog"></PrimeBotton>

        let headerGroup = <ColumnGroup>
            <PrimeRow>
                <Column header={actionHeader} rowSpan={2} />
                <Column header="S/N" rowSpan={2} />
                <Column header="Company Code" rowSpan={2} />
                <Column header="Number Of Project" rowSpan={2} />
                <Column header="Product Codes" rowSpan={2} />
                <Column header="Company's Full Name" rowSpan={2} />
                <Column header="Company's Short Name" rowSpan={2} />
                <Column header="Head Office" colSpan={6} />
                <Column header="Company Representative" colSpan={6} />
            </PrimeRow>
            <PrimeRow>
                <Column header="Address" />
                <Column header="City/Country" />
                <Column header="Phone" />
                <Column header="Fax" />
                <Column header="Web" />
                <Column header="E-mail" />

                <Column header="Name" />
                <Column header="Midname" />
                <Column header="Surname" />
                <Column header="Position" />
                <Column header="Phone" />
                <Column header="Email" />
            </PrimeRow>

        </ColumnGroup>;

        const uploadButton = (
            <div>
                {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div className="ant-upload-text">Upload</div>
            </div>
        );

        let optionsCountry = COUNTRIES.map((data) =>
            <option
                key={data.value}
                value={data.name}
            >
                {data.name}
            </option>
        );

        //const ExpanableComponent = ({ data }) => <CompanyDetail row={data} />; 
        return (
            <div>
                <div style={{ padding: "4px 16px 4px 24px" }}>
                    <Row>
                        <Col xs={3}> <Toast ref={(el) => this.toast = el} /></Col>
                        <Col xs={3}></Col>
                        <Col xs={3}></Col>
                        <Col xs={3}><Button onClick={this.createNew} style={{ width: '100%' }} variant={this.state.createNewButtonVariant} >{this.state.createNewButton}</Button></Col>
                    </Row>
                </div>
                <div hidden={this.state.hidden} style={{ marginLeft: '2rem', marginRight: '2rem', marginTop: '1rem' }} className="content-section implementation">
                    <Card>
                        <Card.Body>
                            <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                                <Form>
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Company's Full Name:
                                                    </Form.Label>
                                        <Col sm="4">
                                            <Form.Control name="companyFullName" value={this.state.companyFullName}
                                                onChange={this.handleChange} />
                                        </Col>

                                    </Form.Group>
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyShortName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Company's Short Name:
                                                    </Form.Label>
                                        <Col sm="4">
                                            <Form.Control name="companyShortName" value={this.state.companyShortName}
                                                onChange={this.handleChange} />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyFullName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Company Logo:
                                                    </Form.Label>

                                        <Col sm="2">
                                            <Upload
                                                name="avatar"
                                                listType="picture-card"
                                                className="avatar-uploader"
                                                showUploadList={false}
                                                action={`${API_BASE}/Company/upload`}
                                                beforeUpload={this.beforeUpload}
                                                onChange={this.handlePhotoChange}
                                            >
                                                {this.state.imageUrl ? <img src={this.state.imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                            </Upload></Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyShortName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Head Office:
                                                    </Form.Label>
                                        <Col sm="4">

                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyShortName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Address
                                                    </Form.Label>
                                        <Col sm="4">
                                            <Form.Control as="textarea" rows="3" name="address" value={this.state.address}
                                                onChange={this.handleChange} />
                                        </Col>
                                    </Form.Group>


                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyShortName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Country
                                                    </Form.Label>
                                        <Col sm="4">
                                            <Form.Control name="country" value={this.state.country}
                                                onChange={this.handleChange} as="select">
                                                <option value="">
                                                    Select Country
                                            </option>
                                                {optionsCountry}
                                            </Form.Control>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyShortName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Phone
                                                    </Form.Label>
                                        <Col sm="4">
                                            {/* <Form.Control  value={this.state.phone}  onChange={(e) => this.setState({ phone: e.target.value })}
                                               />    */}
                                            <div className="content-section implementation p-fluid">

                                                <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.phone} onChange={(e) => this.setState({ phone: e.value })}></Chips>
                                            </div>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyShortName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Fax
                                                    </Form.Label>
                                        <Col sm="4">
                                            {/* <Form.Control  value={this.state.phone}  onChange={(e) => this.setState({ phone: e.target.value })}
                                               />    */}
                                            <div className="content-section implementation p-fluid">
                                                <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.fax} onChange={(e) => this.setState({ fax: e.value })}></Chips>

                                            </div>

                                        </Col>
                                    </Form.Group>
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyShortName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Web
                                                    </Form.Label>
                                        <Col sm="4">
                                            <Form.Control value={this.state.web} onChange={(e) => this.setState({ web: e.target.value })}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyShortName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            E-Mail
                                                    </Form.Label>
                                        <Col sm="4">
                                            {/* <Form.Control  value={this.state.email}  onChange={(e) => this.setState({ email: e.target.value })}
                                               />    */}
                                            <div className="content-section implementation p-fluid">
                                                <Chips style={{ width: '100%' }} placeholder="Press enter after typing" value={this.state.email} onChange={(e) => this.setState({ email: e.value })}></Chips>

                                            </div>

                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyShortName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Company Representative :
                                                    </Form.Label>
                                        <Col sm="4">

                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyShortName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Name
                                                    </Form.Label>
                                        <Col sm="4">
                                            <Form.Control value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })}
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyShortName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Midname
                                                    </Form.Label>
                                        <Col sm="4">
                                            <Form.Control value={this.state.midname} onChange={(e) => this.setState({ midname: e.target.value })}
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyShortName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Surname
                                                    </Form.Label>
                                        <Col sm="4">
                                            <Form.Control value={this.state.surname} onChange={(e) => this.setState({ surname: e.target.value })}
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyShortName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Position
                                                    </Form.Label>
                                        <Col sm="4">
                                            <Form.Control value={this.state.position} onChange={(e) => this.setState({ position: e.target.value })}
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyShortName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Phone
                                                    </Form.Label>
                                        <Col sm="4">
                                            <Form.Control value={this.state.rPhone} onChange={(e) => this.setState({ rPhone: e.target.value })}
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyShortName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Email
                                        </Form.Label>

                                        <Col sm="4">
                                            <Form.Control value={this.state.rEmail} onChange={(e) => this.setState({ rEmail: e.target.value })}
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlCompanyShortName">
                                        <Form.Label style={{ color: 'black' }} column sm="3">
                                            Products and Project Type
                                                    </Form.Label>
                                        <Col sm="4">

                                        </Col>
                                    </Form.Group>

                                    {this.state.products.map(item => (

                                        <Form.Group key={item.productId} style={{ marginBottom: '1rem' }} as={Row} >
                                            <Col sm="3"></Col>

                                            <Col sm="2">
                                                <div>
                                                    <Checkbox onChange={(e) => {
                                                        this.setState(prevState => ({
                                                            products: prevState.products.map(
                                                                el => el.name === item.name ? { ...el, product: e.checked } : el
                                                            )
                                                        }))
                                                    }} checked={item.product}></Checkbox>
                                                    <label htmlFor="cb2" className="p-checkbox-label">{item.name}</label>
                                                </div>
                                            </Col>
                                            <Col sm="2">
                                                <div hidden={!item.product} >
                                                    <Switch checkedChildren="Single" unCheckedChildren="Multiple" checked={item.single} defaultChecked onChange={(value) => {
                                                        var _projectCount = item.projectCount;
                                                        if (value)
                                                            _projectCount = 1;
                                                        this.setState(prevState => ({
                                                            products: prevState.products.map(
                                                                el => el.name === item.name ? { ...el, single: value, projectCount: _projectCount } : el
                                                            )
                                                        }))
                                                    }} />
                                                </div>
                                            </Col>
                                            <Col sm="1">
                                                <div hidden={!(item.product && !item.single)} >
                                                    Project Count :  </div>
                                            </Col>
                                            <Col sm="1">
                                                <div hidden={!(item.product && !item.single)} >
                                                    <InputNumber min={1} onChange={(value) => {
                                                        this.setState(prevState => ({
                                                            products: prevState.products.map(
                                                                el => el.name === item.name ? { ...el, projectCount: value } : el
                                                            )
                                                        }))
                                                    }} value={item.projectCount} />
                                                </div>
                                            </Col>

                                            <Col sm="2">
                                                <div hidden={!item.product} >
                                                    <Select
                                                        mode="tags"
                                                        style={{ width: '100%' }}
                                                        placeholder="Projects"
                                                        defaultOpen={item.projects}
                                                        value={item.projects}
                                                        onChange={(value) => {


                                                            if (value.length <= item.projectCount)
                                                                this.setState(prevState => ({
                                                                    products: prevState.products.map(
                                                                        el => el.name === item.name ? { ...el, projects: value } : el
                                                                    )
                                                                }))
                                                        }}

                                                        dropdownRender={menu => (
                                                            <div>
                                                                {menu}
                                                                <Divider style={{ margin: '4px 0' }} />
                                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                                    <Input style={{ flex: 'auto' }} value={this.state.selectOption} onChange={this.onSelectOptionChange} />
                                                                    <a
                                                                        style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                        onClick={this.addItem}
                                                                    >
                                                                        <PlusOutlined /> Add item
                                                                  </a>
                                                                </div>
                                                            </div>
                                                        )}
                                                    >
                                                        {this.state.projectNames.map(i => (
                                                            <Option key={i.key} value={i.key}>{i.value}</Option>
                                                        ))}
                                                    </Select>
                                                </div>
                                            </Col>



                                        </Form.Group>
                                    ))}


                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="controlVision">

                                        <Col sm="3">

                                        </Col>
                                        <Col sm="4">
                                            <Button type="button" style={{ width: '100%' }} onClick={this.saveCompany} variant={this.state.lastButtonVariant} >{this.state.lastButton}</Button>
                                        </Col>
                                    </Form.Group>
                                </Form>

                            </Container></Card.Body>
                    </Card>
                </div>



                {this.state.companyList.length > 0 && <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.companyList} bordered scroll={{ x: 1300, scrollToFirstRowOnChange: true }}>

                    <Column
                        title="Action"
                        key="action"
                        render={(text, record) => {
                            if(record.id==TEMPLATE_Dynamia_ClientID){
                                return "";
                            }else{
                                return <React.Fragment key={record.id}> <CustomMaterialMenu row={record} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />   </React.Fragment>
                            }
                        }}
                    />
                    <Column title="S/N" key="sn" dataIndex="index" />
                    <Column title="Logo" key="logo" render={(value, row, index) => {
                        if (row.logoPath != null && row.logoPath !== undefined)
                            return <Avatar shape="square" size={64} src={`${API_BASE}/Company/download/` + row.id} />;
                        else return "";

                    }} />
                    <Column title="Company Code" key="id" dataIndex="id" />
                    <Column title="Number Of Project" key="nop" render={(record) => (this.numberOfProjects2(record))} />
                    <Column title="Product Codes" key="pc" render={(record) => (this.productList2(record))} />
                    <Column title="Company's Full Name" key="cfn" dataIndex="fullName" />
                    <Column title="Company's short Name" key="csn" dataIndex="shortName" />
                    <ColumnGroup title="Head Office" key="ho" >
                        <Column width='30%' title="Address" key="address" render={(value, row, index) => {
                            if (row.contacts != null && row.contacts !== undefined && row.contacts.length > 0)
                                return row.contacts[0].address;

                            return '';
                        }} />
                        <Column title="Country" key="country" render={(value, row, index) => {
                            if (row.contacts != null && row.contacts !== undefined && row.contacts.length > 0)
                                return row.contacts[0].country;

                            return '';
                        }} />
                        <Column title="Phones" key="phones" render={(row) => (this.phones(row.contacts))} /> 
                     <Column title="Faxes" key="faxes" render={(row) => (this.faxes(row.contacts))} /> 
                     <Column title="Web" key="web" render={(value, row, index) => {
                            if (row.contacts != null && row.contacts !== undefined && row.contacts.length > 0)
                                return row.contacts[0].web;

                            return '';
                        }} />
                        <Column title="Emails" key="emails" render={(row) => (this.mails(row.contacts))} /> 
                 </ColumnGroup>
                    <ColumnGroup title="Company Representative" key="cr" >
                        <Column title="Name" key="name" render={(value, row, index) => {
                            if (row.representative != null && row.representative !== undefined)
                                return row.representative.name;

                            return '';
                        }} />

                        <Column title="MidName" key="midname" render={(value, row, index) => {
                            if (row.representative != null && row.representative !== undefined)
                                return row.representative.midname;

                            return '';
                        }} />

                        <Column title="Surname" key="surname" render={(value, row, index) => {
                            if (row.representative != null && row.representative !== undefined)
                                return row.representative.lastname;

                            return '';
                        }} />
                        <Column title="Position" key="position" render={(value, row, index) => {
                            if (row.representative != null && row.representative !== undefined)
                                return row.representative.position;

                            return '';
                        }} />
                        <Column title="Phone" key="rphone" render={(value, row, index) => {
                            if (row.representative != null && row.representative !== undefined)
                                return row.representative.phone;

                            return '';
                        }} />
                        <Column title="Email" key="remail" render={(value, row, index) => {
                            if (row.representative != null && row.representative !== undefined)
                                return row.representative.email;

                            return '';
                        }} />
                    </ColumnGroup>

                </Table>}

                <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                    <Row>
                        <Col xs={2}>
                            {/* <Button onClick={this.showOrHideModal} style={{ width: '100%' }} variant="info">Test Update Information</Button> */}
                            <Modal
                                show={this.state.modalShow}
                                onHide={this.showOrHideModal}
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="contained-modal-title-vcenter">Delete Company Info ?</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>All informations about company will be deleted. Are you sure?</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.showOrHideModal}>Close</Button>
                                    <Button variant="danger" onClick={this.delete} >Delete</Button>
                                </Modal.Footer>
                            </Modal></Col>
                        <Col xs={8}></Col>

                    </Row>
                </Container>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(MNDClientLog)
