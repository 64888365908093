import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import { API_BASE, handleRequest } from '../../redux/config/env';
import { Modal, Button as ReactButton, Col, Form, Form as FormBoot, Row } from 'react-bootstrap';
import { CloseOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Card, Checkbox, Radio, Select, message, Input, Table } from 'antd';
import { DatePicker, Space, Upload, Button as AntButton, InputNumber as AntInputNumber } from 'antd';
import { InputNumber } from 'primereact/inputnumber';
import { IconButton  } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';
import SettingsIcon from '@material-ui/icons/Settings';
import 'react-contexify/dist/ReactContexify.min.css';
import { Menu, Item, MenuProvider } from 'react-contexify';
import { Redirect } from "react-router-dom";
import CustomMaterialMenuSummary from '../settings/ForComponents/CustomMaterialMenuSummary';
import CustomMaterialMenuEditSummary from '../settings/ForComponents/CustomMaterialMenuEditSummary';
import CustomMaterialMenuDeleteSummary from '../settings/ForComponents/CustomMaterialMenuDeleteSummary';
import CustomMaterialMenuDeleteEditSummary from '../settings/ForComponents/CustomMaterialMenuDeleteEditSummary';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import {
    FacebookShareButton, WhatsappShareButton, EmailShareButton,
    FacebookIcon, WhatsappIcon, EmailIcon
} from "react-share";
import moment from 'moment';
import $ from "jquery";

var special = ['zeroth', 'first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seventh', 'eighth',
    'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth',
    'seventeenth', 'eighteenth', 'nineteenth'];
var deca = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];

var doc = new jsPDF('p', 'pt');

const { Column } = Table;

var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0');
var yyyy = today.getFullYear();

today = dd + '/' + mm + '/' + yyyy;

var _numberOfDeliveryRows = [];
var _PQCSDateRows = [
    {
        PQCSIndex: 1,
        date: today
    }
];
var _companyPersonRows = [
    {
        id: 0,
        companyPersonIndex: 1,
        staffId: ""
    }
];
var _vendorPersonRows = [
    {
        id: 0,
        vendorPersonIndex: 1,
        personName: "",
        designationName: "",
        phoneNo: "",
        email: ""
    }
];
var _clientPersonRows = [
    {
        id: 0,
        clientPersonIndex: 1,
        personName: "",
        designationName: "",
        phoneNo: "",
        email: ""
    }
];

export class InspectionDates extends Component {
    constructor() {
        super();
        this.state = {
            hidden: true,
            hiddenSave: false,
            hiddenUpdate: true,
            deleteModal: false,
            modalEdit: false,
            modalShow1: false,
            modalShow2: false,
            modalShow3: false,
            modalPdfViewer: false,
            docData: null,
            pdfFilePath: "",

            inspectionDatesId: null,
            inspectionDates: [],
            oldInspectionDate: null,
            IAP: "",
            PIM: false,
            PIMDate: null,
            PIMReportPath: "",
            productionStartDate: null,
            selectedDelivery: "",
            IPI: false,
            IPINumber: 0,
            numberOfDelivery: 0,
            numberOfDeliveryRows: [],
            finalFat: true,
            PQCSDateRows: [],
            staffList: [],
            designations: [],
            companyPersonRows: [],
            vendorPersonRows: [],
            clientPersonRows: [],
            inspectionAssignmentTable: [],
            minimumDaysForNotification: "",
            workingDaysCheck: false,
            rfqList: [],
            mrList: [],
            earliestDate: "",
            modalPIMDateMessage: false,
            PIMDateCheck: false,
            IPIDateCheck: false,
            modalIPIDateMessage: false,

            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            modalHowToUse: false,
            howToUseThis: "",
            modalMNDSettings: false,
            modalSettings: false,
            howToUseSetting: false,
            whatIsSetting: false,
            visibleWhatis: false,
            visibleHowToUse: false,
            modalShowWhatIs: false,
            topX: 0,
            leftY: 0,
            selectID: "",
            selectURI: "",
            inputDescription: "",
            userSettings: null,
            formId: null
        };

        this.dynamicInputs = this.dynamicInputs.bind(this);
    };

    formRef = React.createRef();

    componentDidMount = async () => {
        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {
                var flagEmail = this.props.auth.user.email.split('@')[0].split('_');
                var flagEmail2 = this.props.auth.user.email.split('@')[1].split('.');

                var adminControl1 = "";
                var adminControl2 = "";

                if (flagEmail.length > 0)
                    adminControl1 = flagEmail[flagEmail.length - 1];

                if (flagEmail2.length > 0)
                    adminControl2 = flagEmail2[0];

                if (adminControl1 === "99" && adminControl2 === "mnd") {
                    this.setState({
                        isAdmin: true
                    });
                }
            }

        document.addEventListener('contextmenu', this._handleContextMenu);

        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "InspectionDates" });

        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "InspectionDates",
        };

        const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseDynamicInputs.data.length !== 0) {
            this.setState({
                dynamicInputs: responseDynamicInputs.data,
            });
        } else {
            this.setState({
                dynamicInputs: [],
            });
        }

        const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "InspectionDates");

        console.log(menuObj);

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
            });
        } catch (error) {

        }

        const responseStaff = await handleRequest("GET", "/Staff/getCompanyItems");

        if (responseStaff.data.length > 0) {
            this.setState({
                staffList: responseStaff.data,
            });
        } else {
            this.setState({
                staffList: [],
            });
        }

        const responseDes = await handleRequest("GET", "/Designation/getallByCompany");

        if (responseDes.data.length !== 0) {
            this.setState({
                designations: responseDes.data
            });
        } else {
            this.setState({
                designations: []
            });
        }

        const responseIAP = await handleRequest("GET", "/InspectionAssignmentPackage/getallByCompany");
        if (responseIAP.data.length === 0) {
            this.setState({
                inspectionAssignmentTable: []
            });
        }

        if (responseIAP.data.length !== 0) {
            this.setState({
                inspectionAssignmentTable: responseIAP.data
            });
        }

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        this.setState({
            PIMDate: today,
            productionStartDate: today,
            numberOfDeliveryRows: _numberOfDeliveryRows,
            PQCSDateRows: _PQCSDateRows,
            companyPersonRows: _companyPersonRows,
            vendorPersonRows: _vendorPersonRows,
            clientPersonRows: _clientPersonRows
        });

        this.restartTable();
    }

    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

            this.getUserSettings(result.data.id);
        } else {
            this.setState({
                formId: null
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('contextmenu', this._handleContextMenu);
    }

    _handleContextMenu = (event) => {
        this.setState({
            inputDescription: "",
        });

        const clickX = event.clientX - 260;
        const clickY = event.clientY - 180;

        this.setState({ topX: clickY, leftY: clickX });

        console.log('basti ' + clickX + ' ' + clickY);

        try {
            this.setState({ selectID: event.toElement.id, selectURI: event.toElement.baseURI });
        }
        catch (ex) {
            this.setState({ selectID: event.srcElement.id, selectURI: event.srcElement.baseURI });
        }
    }

    dynamicInputs = async (e) => {
        const { selectID } = this.state;
        const { selectURI } = this.state;
        this.setState({
            modalShowWhatIs: true,
            inputDescription: "",
        });

        if (selectURI != null && selectURI.length > 0) {
            var s = selectURI.split('/');
            const newselectURI = s[s.length - 1];

            const prm = {
                inputKey: selectID,
                pageName: newselectURI,
            }

            try {
                const response = await handleRequest("POST", "/DynamicInputs/getResult", prm);

                if (response.data.length > 0) {
                    this.setState({
                        inputDescription: <FormattedMessage id={response.data[0].translateWhatis} defaultMessage={response.data[0].inputDescription} />,
                    });
                } else {
                    this.setState({
                        inputDescription: <FormattedMessage id="GeneralMessageNoDataFound" defaultMessage="No data found" />,
                    });
                }
            } catch (error) {
                console.log('hata' + error)
            }
        }
    }

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs"
                defaultMessage="Something went wrong. Please check dates and inputs. Set inspection dates in sequence. Inputs can't be empty or 0." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    }

    showWarning2(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs2"
                defaultMessage="Something went wrong. Please set inspection dates in sequence." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    }

    showWarning3(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs3"
                defaultMessage="Something went wrong. Production start date cannot be earlier or the same as the date entered." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalWhatIs = () => {
        this.setState({
            modalShowWhatIs: !this.state.modalShowWhatIs,
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }

    showHowToUse = async () => {
        if (this.state.formId !== null) {
            const responseHowToUse = await handleRequest("GET", "/HowToUse/getByFormId/" + this.state.formId);

            if (responseHowToUse.data.length !== 0) {
                this.setState({
                    howToUseThis: responseHowToUse.data.howToUse
                });
            } else {
                this.setState({
                    howToUseThis: ""
                });
            }
        }

        this.setState({
            modalHowToUse: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showSettings = () => {
        this.setState({
            modalSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showMNDSettings = () => {
        this.setState({
            modalMNDSettings: true
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalHowToUse = () => {
        this.setState({
            modalHowToUse: !this.state.modalHowToUse
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalSettings = () => {
        this.setState({
            modalSettings: !this.state.modalSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalMNDSettings = () => {
        this.setState({
            modalMNDSettings: !this.state.modalMNDSettings
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    goLanguage = () => {
        window.location.href = '/LanguageSettings';
    }

    goHowToUse = () => {
        window.location.href = '/HowToUse';
    }

    goWhatIs = () => {
        window.location.href = '/DynamicMenu';
    }

    goDynamicPage = () => {
        window.location.href = '/DynamicPage';
    }

    getUserSettings = async (formId) => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({
                userSettings: null,
                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }

    setUserSettings = async () => {
        if (this.state.userSettings === null) {
            const newUserSettings = {
                formId: this.state.formId,
                howToUseSetting: this.state.howToUseSetting,
                whatIsSetting: this.state.whatIsSetting
            }

            await handleRequest("POST", "/UserPageSettings/add", newUserSettings);
        } else {
            const updatedUserSettings = {
                id: this.state.userSettings.id,
                formId: this.state.formId,
                howToUseSetting: this.state.howToUseSetting,
                whatIsSetting: this.state.whatIsSetting
            }

            await handleRequest("POST", "/UserPageSettings/update", updatedUserSettings);
        }

        this.setState({
            modalSettings: false
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);

        this.showSuccess("All data has been saved successfully.");
    }

    createNew = async () => {
        if (this.state.hidden) {
            this.setState({
                hidden: false
            });
        } else {
            this.resetInputs();
        }
    }

    stopProcess = () => {
        this.resetInputs();
    }

    restartTable = async () => {
        const responseID = await handleRequest("GET", "/InspectionDates/getAllByCompany");

        if (responseID.data.length === 0) {
            this.setState({
                inspectionDates: [],
                minimumDaysForNotification: "",
                workingDaysCheck: false
            });
        } else {
            this.setState({
                inspectionDates: responseID.data
            });

            if (responseID.data[responseID.data.length - 1].minimumDaysForNotification !== undefined)
                if (responseID.data[responseID.data.length - 1].minimumDaysForNotification !== null) {
                    this.setState({
                        minimumDaysForNotification: responseID.data[responseID.data.length - 1].minimumDaysForNotification,
                        workingDaysCheck: responseID.data[responseID.data.length - 1].workingDaysCheck
                    });
                }
        }

        this.getRFQs();
        this.getMRs();
    }

    getRFQs = async () => {
        const responseRFQ = await handleRequest("GET", "/RequestForQuotation/getAllByCompany");
        if (responseRFQ.data.length === 0) {
            this.setState({
                rfqList: []
            });
        } else {
            this.setState({
                rfqList: responseRFQ.data
            });
        }
    }

    getMRs = async () => {
        const response = await handleRequest("GET", "/MaterialRequisition/getallByCompany");
        if (response.data.length === 0) {
            this.setState({
                mrList: []
            });
        }

        if (response.data.length !== 0) {
            this.setState({
                mrList: response.data
            });
        }
    }

    resetInputs = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        _numberOfDeliveryRows = [];
        _PQCSDateRows = [
            {
                PQCSIndex: 1,
                date: today
            }
        ];
        _companyPersonRows = [
            {
                id: 0,
                companyPersonIndex: 1,
                staffId: ""
            }
        ];
        _vendorPersonRows = [
            {
                id: 0,
                vendorPersonIndex: 1,
                personName: "",
                designationName: "",
                phoneNo: "",
                email: "",
            }
        ];
        _clientPersonRows = [
            {
                id: 0,
                clientPersonIndex: 1,
                personName: "",
                designationName: "",
                phoneNo: "",
                email: "",
            }
        ];

        this.setState({
            hidden: true,
            hiddenSave: false,
            hiddenUpdate: true,
            inspectionDatesId: null,
            oldInspectionDate: null,
            IAP: "",
            PIM: false,
            PIMDate: today,
            PIMReportPath: "",
            productionStartDate: today,
            selectedDelivery: "",
            IPI: false,
            IPINumber: 0,
            numberOfDelivery: 0,
            numberOfDeliveryRows: [],
            finalFat: true,
            PIMDateCheck: false,
            IPIDateCheck: false,
            PQCSDateRows: _PQCSDateRows,
            companyPersonRows: _companyPersonRows,
            vendorPersonRows: _vendorPersonRows,
            clientPersonRows: _clientPersonRows,
        });
    }

    dbDateToString = (dateString) => {
        var flagDate = moment(dateString, "YYYY/MM/DD")._d;
        var dd = String(flagDate.getDate()).padStart(2, '0');
        var mm = String(flagDate.getMonth() + 1).padStart(2, '0');
        var yyyy = flagDate.getFullYear();
        flagDate = dd + '/' + mm + '/' + yyyy;

        return flagDate;
    }

    arraysEqualDates = (a, b) => {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;

        for (var i = 0; i < a.length; ++i) {
            if (a[i].getTime() !== b[i].getTime()) return false;
        }
        return true;
    }

    changeButtonUpdate = (row) => {
        var flagNumberOfDelivery = [];
        var flagIPIDates = [];

        if (row.deliveryOfGoods === "One Time") {
            flagIPIDates = row.inspections[0].ipiDates.split(",");
            flagNumberOfDelivery.push([flagIPIDates, row.inspections[0].fatDate]);
        } else if (row.deliveryOfGoods === "Multiple") {
            for (let index = 0; index < Number(row.numberOfDelivery); index++) {
                flagIPIDates = row.inspections[index].ipiDates.split(",");
                flagNumberOfDelivery.push([flagIPIDates, row.inspections[index].fatDate]);
            }
        }

        _numberOfDeliveryRows = flagNumberOfDelivery;

        var flagCompanyPersonRows = [];

        for (let index = 0; index < row.companyPeople.length; index++) {
            flagCompanyPersonRows.push(
                {
                    id: row.companyPeople[index].id,
                    companyPersonIndex: flagCompanyPersonRows.length + 1,
                    staffId: row.companyPeople[index].staffId
                }
            );
        }

        _companyPersonRows = flagCompanyPersonRows;

        var flagVendorPersonRows = [];

        for (let index = 0; index < row.vendorPeople.length; index++) {
            flagVendorPersonRows.push(
                {
                    id: row.vendorPeople[index].id,
                    vendorPersonIndex: flagVendorPersonRows.length + 1,
                    personName: row.vendorPeople[index].name,
                    designationName: row.vendorPeople[index].designationName,
                    phoneNo: row.vendorPeople[index].phoneNo,
                    email: row.vendorPeople[index].email
                }
            );
        }

        _vendorPersonRows = flagVendorPersonRows;

        var flagClientPersonRows = [];

        for (let index = 0; index < row.clientPeople.length; index++) {
            flagClientPersonRows.push(
                {
                    id: row.clientPeople[index].id,
                    clientPersonIndex: flagClientPersonRows.length + 1,
                    personName: row.clientPeople[index].name,
                    designationName: row.clientPeople[index].designationName,
                    phoneNo: row.clientPeople[index].phoneNo,
                    email: row.clientPeople[index].email
                }
            );
        }

        _clientPersonRows = flagClientPersonRows;

        var flagPQCSDateRows = [];
        var PQCSDates = row.pqcsVisitDates.split(",");

        for (let index = 0; index < PQCSDates.length; index++) {
            flagPQCSDateRows.push(
                {
                    PQCSIndex: flagPQCSDateRows.length + 1,
                    date: PQCSDates[index],
                }
            );
        }

        _PQCSDateRows = flagPQCSDateRows;

        let PIMCheck = false;

        let iapIndex = this.state.inspectionAssignmentTable.findIndex(iap => iap.id === row.iapId);
        if (iapIndex !== -1) {
            let flagIAP = this.state.inspectionAssignmentTable[iapIndex];
            let rfqIndex = this.state.rfqList.findIndex(rfq => rfq.id === flagIAP.rfqId);
            if (rfqIndex !== -1) {
                let flagRfq = this.state.rfqList[rfqIndex];
                let mrIndex = this.state.mrList.findIndex(mr => mr.projectTakeOffList.id === flagRfq.projectTakeOffList.id);
                if (mrIndex !== -1) {
                    let flagMr = this.state.mrList[mrIndex];
                    if (flagMr.pim === "Yes") {
                        PIMCheck = true;
                    }
                }
            }
        }

        if (row.iap.date !== null) {
            this.setState({
                PIMDate: this.dbDateToString(row.iap.pimdate)
            });
        } else {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear();
            today = dd + '/' + mm + '/' + yyyy;

            this.setState({
                PIMDate: today
            });
        }

        this.setState({
            hidden: false,
            hiddenSave: true,
            hiddenUpdate: false,
            oldInspectionDate: row,
            inspectionDatesId: row.id,
            IAP: row.iapId,
            minimumDaysForNotification: row.minimumDaysForNotification,
            workingDaysCheck: row.workingDaysCheck,
            PIM: PIMCheck,
            PIMLocation: row.iap.pimlocation,
            PIMReportPath: row.pimReportFilePath,
            productionStartDate: row.productionStartDate,
            selectedDelivery: row.deliveryOfGoods,
            IPI: row.ipi,
            IPINumber: row.numberOfIpi,
            numberOfDelivery: row.numberOfDelivery,
            numberOfDeliveryRows: flagNumberOfDelivery,
            finalFat: row.finalFat,
            PQCSDateRows: _PQCSDateRows,
            companyPersonRows: _companyPersonRows,
            vendorPersonRows: _vendorPersonRows,
            clientPersonRows: _clientPersonRows,
        });

        setTimeout(() => {
            if (document.getElementsByClassName('p-inputtext')[0] !== undefined)
                document.getElementsByClassName('p-inputtext')[0].style.textAlign = "center";

            if (document.getElementsByClassName('p-inputtext')[1] !== undefined)
                document.getElementsByClassName('p-inputtext')[1].style.textAlign = "center";
        }, 100);
    }

    saveInpectionDates = async () => {
        if (this.state.hiddenUpdate) {
            var flagCompanyPersonRows = [];
            var flagVendorPersonRows = [];
            var flagClientPersonRows = [];
            var flagInspectionRows = [];
            var flagPQCSDateRows = [];

            for (let index = 0; index < this.state.companyPersonRows.length; index++) {
                flagCompanyPersonRows.push({
                    staffId: Number(this.state.companyPersonRows[index].staffId)
                });
            }

            for (let index = 0; index < this.state.vendorPersonRows.length; index++) {
                flagVendorPersonRows.push({
                    name: this.state.vendorPersonRows[index].personName,
                    designationName: this.state.vendorPersonRows[index].designationName,
                    phoneNo: this.state.vendorPersonRows[index].phoneNo,
                    email: this.state.vendorPersonRows[index].email,
                });
            }

            for (let index = 0; index < this.state.clientPersonRows.length; index++) {
                flagClientPersonRows.push({
                    name: this.state.clientPersonRows[index].personName,
                    designationName: this.state.clientPersonRows[index].designationName,
                    phoneNo: this.state.clientPersonRows[index].phoneNo,
                    email: this.state.clientPersonRows[index].email,
                });
            }

            for (let index = 0; index < this.state.numberOfDeliveryRows.length; index++) {
                flagInspectionRows.push({
                    ipiDates: this.state.numberOfDeliveryRows[index][0].join(),
                    fatDate: this.state.numberOfDeliveryRows[index][1]
                });
            }

            for (let index = 0; index < this.state.PQCSDateRows.length; index++) {
                flagPQCSDateRows.push(this.state.PQCSDateRows[index].date);
            }

            var productionStartDateCheck = true;
            var date1 = moment(this.state.productionStartDate, 'DD/MM/YYYY')._d;

            for (let index = 0; index < this.state.PQCSDateRows.length; index++) {
                let date2 = moment(this.state.PQCSDateRows[index].date, 'DD/MM/YYYY')._d;
                if (date1.getTime() >= date2.getTime()) {
                    productionStartDateCheck = false;
                }
            }

            for (let index = 0; index < this.state.numberOfDeliveryRows.length; index++) {
                let date3 = moment(this.state.numberOfDeliveryRows[index][1], 'DD/MM/YYYY')._d;
                if (date1.getTime() >= date3.getTime()) {
                    productionStartDateCheck = false;
                }

                for (let index1 = 0; index1 < this.state.numberOfDeliveryRows[index][0].length; index1++) {
                    let date4 = moment(this.state.numberOfDeliveryRows[index][0][index1], 'DD/MM/YYYY')._d;
                    if (date1.getTime() >= date4.getTime()) {
                        productionStartDateCheck = false;
                    }
                }
            }

            var PQCSDates = [];
            var PQCSDates2 = [];
            for (let index = 0; index < this.state.PQCSDateRows.length; index++) {
                PQCSDates2.push(moment(this.state.PQCSDateRows[index].date, 'DD/MM/YYYY')._d);
                PQCSDates.push(moment(this.state.PQCSDateRows[index].date, 'DD/MM/YYYY')._d);
            }
            PQCSDates.sort((a, b) => a - b);
            var dateOrderVisitDate = this.arraysEqualDates(PQCSDates2, PQCSDates);

            var dateOrderIPIFATDate = true;
            for (let index = 0; index < this.state.numberOfDeliveryRows.length; index++) {
                var IPIDates = [];
                var IPIDates2 = [];
                for (let index1 = 0; index1 < this.state.numberOfDeliveryRows[index][0].length; index1++) {
                    IPIDates.push(moment(this.state.numberOfDeliveryRows[index][0][index1], 'DD/MM/YYYY')._d);
                    IPIDates2.push(moment(this.state.numberOfDeliveryRows[index][0][index1], 'DD/MM/YYYY')._d);
                }

                IPIDates.sort((a, b) => a - b);
                var dateOrderIPIDate = this.arraysEqualDates(IPIDates, IPIDates2);

                if (dateOrderIPIDate) {
                    let IPIDatesLength = this.state.numberOfDeliveryRows[index][0].length - 1;
                    let date1 = moment(this.state.numberOfDeliveryRows[index][1], 'DD/MM/YYYY')._d;
                    let date2 = moment(this.state.numberOfDeliveryRows[index][0][IPIDatesLength], 'DD/MM/YYYY')._d;
                    if (date1.getTime() < date2.getTime()) {
                        dateOrderIPIFATDate = false;
                    }
                } else {
                    dateOrderIPIFATDate = false;
                }
            }

            let flagIPIDateCheck = true;
            let flagFATDateCheck = true;
            let totalDateCheck = false;
            let today1 = new Date();
            today1.setDate(today1.getDate() + this.state.minimumDaysForNotification);

            for (let index = 0; index < this.state.numberOfDeliveryRows.length; index++) {
                for (let index1 = 0; index1 < this.state.numberOfDeliveryRows[index][0].length; index1++) {
                    let date1 = moment(this.state.numberOfDeliveryRows[index][0][index1], "DD/MM/YYYY")._d;
                    if (date1 < today1)
                        flagIPIDateCheck = false;
                }
            }

            for (let index = 0; index < this.state.numberOfDeliveryRows.length; index++) {
                let date2 = moment(this.state.numberOfDeliveryRows[index][1], "DD/MM/YYYY")._d;
                if (date2 < today1)
                    flagFATDateCheck = false;
            }

            console.log("IPI:" + flagIPIDateCheck);
            console.log("FAT:" + flagFATDateCheck);
            console.log("IPI-Total:" + this.state.IPIDateCheck);

            totalDateCheck = ((flagIPIDateCheck && flagFATDateCheck) || this.state.IPIDateCheck);

            console.log("Total:" + totalDateCheck);
            console.log("PIM:" + this.state.PIMDateCheck);

            if (this.state.IAP !== "" && dateOrderVisitDate && dateOrderIPIFATDate && productionStartDateCheck
                && !(this.state.PIMDateCheck) && totalDateCheck) {

                const newID = {
                    iapId: this.state.IAP,
                    minimumDaysForNotification: this.state.minimumDaysForNotification,
                    workingDaysCheck: this.state.workingDaysCheck,
                    companyPeople: flagCompanyPersonRows,
                    vendorPeople: flagVendorPersonRows,
                    clientPeople: flagClientPersonRows,
                    pimReportFilePath: this.state.PIMReportPath,
                    productionStartDate: this.state.productionStartDate,
                    deliveryOfGoods: this.state.selectedDelivery,
                    numberOfDelivery: this.state.numberOfDelivery,
                    ipi: this.state.IPI,
                    numberOfIpi: this.state.IPINumber,
                    finalFat: this.state.finalFat,
                    inspections: flagInspectionRows,
                    pqcsVisitDates: flagPQCSDateRows.join()
                }

                var response = await handleRequest("POST", "/InspectionDates/add", newID);
                if (response.data !== null && response.data !== undefined) {
                    if (response.data === "SUCCESS") {
                        this.resetInputs();
                        this.restartTable();
                        this.showSuccess("All data has been saved successfully.");
                    }
                    else {
                        this.showError();
                    }
                }
                else {
                    this.showError();
                }
            } else if (this.state.PIMDateCheck) {
                let earliestDate = new Date();
                earliestDate.setDate(earliestDate.getDate() + this.state.minimumDaysForNotification + 1);
                let dd1 = String(earliestDate.getDate()).padStart(2, '0');
                let mm1 = String(earliestDate.getMonth() + 1).padStart(2, '0');
                let yyyy1 = earliestDate.getFullYear();
                earliestDate = dd1 + '/' + mm1 + '/' + yyyy1;
                this.setState({
                    earliestDate: earliestDate
                });
                this.showOrHideModalPIMDateMessage();
            } else if (!(totalDateCheck)) {
                let earliestDate = new Date();
                earliestDate.setDate(earliestDate.getDate() + this.state.minimumDaysForNotification + 1);
                let dd1 = String(earliestDate.getDate()).padStart(2, '0');
                let mm1 = String(earliestDate.getMonth() + 1).padStart(2, '0');
                let yyyy1 = earliestDate.getFullYear();
                earliestDate = dd1 + '/' + mm1 + '/' + yyyy1;

                this.setState({
                    earliestDate: earliestDate
                });
                this.showOrHideModalIPIDateMessage();
            } else {
                this.showWarning();
            }
        } else if (this.state.hiddenSave) {
            var flagCompanyPersonRows = [];
            var flagVendorPersonRows = [];
            var flagClientPersonRows = [];
            var flagInspectionRows = [];
            var flagPQCSDateRows = [];

            for (let index = 0; index < this.state.companyPersonRows.length; index++) {
                flagCompanyPersonRows.push({
                    id: this.state.companyPersonRows[index].id,
                    staffId: Number(this.state.companyPersonRows[index].staffId)
                });
            }

            if (this.state.oldInspectionDate.companyPeople.length > 0) {
                for (let index = 0; index < this.state.oldInspectionDate.companyPeople.length; index++) {
                    let whichIndexCompanyPeople = this.state.companyPersonRows.findIndex(i => Number(i.id) === this.state.oldInspectionDate.companyPeople[index].id);

                    if (whichIndexCompanyPeople === -1) {
                        flagCompanyPersonRows.push({
                            id: this.state.oldInspectionDate.companyPeople[index].id,
                            active: false,
                            staffId: this.state.oldInspectionDate.companyPeople[index].staffId
                        });
                    }
                }
            }

            for (let index = 0; index < this.state.vendorPersonRows.length; index++) {
                flagVendorPersonRows.push({
                    id: this.state.vendorPersonRows[index].id,
                    name: this.state.vendorPersonRows[index].personName,
                    designationName: this.state.vendorPersonRows[index].designationName,
                    phoneNo: this.state.vendorPersonRows[index].phoneNo,
                    email: this.state.vendorPersonRows[index].email,
                });
            }

            if (this.state.oldInspectionDate.vendorPeople.length > 0) {
                for (let index = 0; index < this.state.oldInspectionDate.vendorPeople.length; index++) {
                    let whichIndexVendorPeople = this.state.vendorPersonRows.findIndex(i => Number(i.id) === this.state.oldInspectionDate.vendorPeople[index].id);

                    if (whichIndexVendorPeople === -1) {
                        flagVendorPersonRows.push({
                            id: this.state.oldInspectionDate.vendorPeople[index].id,
                            active: false,
                            name: this.state.oldInspectionDate.vendorPeople[index].name,
                            designationName: this.state.oldInspectionDate.vendorPeople[index].designationName,
                            phoneNo: this.state.oldInspectionDate.vendorPeople[index].phoneNo,
                            email: this.state.oldInspectionDate.vendorPeople[index].email,
                        });
                    }
                }
            }

            for (let index = 0; index < this.state.clientPersonRows.length; index++) {
                flagClientPersonRows.push({
                    id: this.state.clientPersonRows[index].id,
                    name: this.state.clientPersonRows[index].personName,
                    designationName: this.state.clientPersonRows[index].designationName,
                    phoneNo: this.state.clientPersonRows[index].phoneNo,
                    email: this.state.clientPersonRows[index].email,
                });
            }

            if (this.state.oldInspectionDate.clientPeople.length > 0) {
                for (let index = 0; index < this.state.oldInspectionDate.clientPeople.length; index++) {
                    let whichIndexClientPeople = this.state.clientPersonRows.findIndex(i => Number(i.id) === this.state.oldInspectionDate.clientPeople[index].id);

                    if (whichIndexClientPeople === -1) {
                        flagClientPersonRows.push({
                            id: this.state.oldInspectionDate.clientPeople[index].id,
                            active: false,
                            name: this.state.oldInspectionDate.clientPeople[index].name,
                            designationName: this.state.oldInspectionDate.clientPeople[index].designationName,
                            phoneNo: this.state.oldInspectionDate.clientPeople[index].phoneNo,
                            email: this.state.oldInspectionDate.clientPeople[index].email,
                        });
                    }
                }
            }

            for (let index = 0; index < this.state.numberOfDeliveryRows.length; index++) {
                flagInspectionRows.push({
                    ipiDates: this.state.numberOfDeliveryRows[index][0].join(),
                    fatDate: this.state.numberOfDeliveryRows[index][1]
                });
            }

            for (let index = 0; index < this.state.PQCSDateRows.length; index++) {
                flagPQCSDateRows.push(this.state.PQCSDateRows[index].date);
            }

            var productionStartDateCheck = true;
            var date1 = moment(this.state.productionStartDate, 'DD/MM/YYYY')._d;

            for (let index = 0; index < this.state.PQCSDateRows.length; index++) {
                let date2 = moment(this.state.PQCSDateRows[index].date, 'DD/MM/YYYY')._d;
                if (date1.getTime() >= date2.getTime()) {
                    productionStartDateCheck = false;
                }
            }

            for (let index = 0; index < this.state.numberOfDeliveryRows.length; index++) {
                let date3 = moment(this.state.numberOfDeliveryRows[index][1], 'DD/MM/YYYY')._d;
                if (date1.getTime() >= date3.getTime()) {
                    productionStartDateCheck = false;
                }

                for (let index1 = 0; index1 < this.state.numberOfDeliveryRows[index][0].length; index1++) {
                    let date4 = moment(this.state.numberOfDeliveryRows[index][0][index1], 'DD/MM/YYYY')._d;
                    if (date1.getTime() >= date4.getTime()) {
                        productionStartDateCheck = false;
                    }
                }
            }

            var PQCSDates = [];
            var PQCSDates2 = [];
            for (let index = 0; index < this.state.PQCSDateRows.length; index++) {
                PQCSDates2.push(moment(this.state.PQCSDateRows[index].date, 'DD/MM/YYYY')._d);
                PQCSDates.push(moment(this.state.PQCSDateRows[index].date, 'DD/MM/YYYY')._d);
            }
            PQCSDates.sort((a, b) => a - b);
            var dateOrderVisitDate = this.arraysEqualDates(PQCSDates2, PQCSDates);

            var dateOrderIPIFATDate = true;
            for (let index = 0; index < this.state.numberOfDeliveryRows.length; index++) {
                var IPIDates = [];
                var IPIDates2 = [];
                for (let index1 = 0; index1 < this.state.numberOfDeliveryRows[index][0].length; index1++) {
                    IPIDates.push(moment(this.state.numberOfDeliveryRows[index][0][index1], 'DD/MM/YYYY')._d);
                    IPIDates2.push(moment(this.state.numberOfDeliveryRows[index][0][index1], 'DD/MM/YYYY')._d);
                }

                IPIDates.sort((a, b) => a - b);
                var dateOrderIPIDate = this.arraysEqualDates(IPIDates, IPIDates2);

                if (dateOrderIPIDate) {
                    let IPIDatesLength = this.state.numberOfDeliveryRows[index][0].length - 1;
                    let date1 = moment(this.state.numberOfDeliveryRows[index][1], 'DD/MM/YYYY')._d;
                    let date2 = moment(this.state.numberOfDeliveryRows[index][0][IPIDatesLength], 'DD/MM/YYYY')._d;
                    if (date1.getTime() < date2.getTime()) {
                        dateOrderIPIFATDate = false;
                    }
                } else {
                    dateOrderIPIFATDate = false;
                }
            }

            if (this.state.inspectionDatesId !== null && this.state.IAP !== "" && dateOrderVisitDate && dateOrderIPIFATDate && productionStartDateCheck) {
                const updatedID = {
                    id: this.state.inspectionDatesId,
                    minimumDaysForNotification: this.state.minimumDaysForNotification,
                    workingDaysCheck: this.state.workingDaysCheck,
                    iapId: this.state.IAP,
                    companyPeople: flagCompanyPersonRows,
                    vendorPeople: flagVendorPersonRows,
                    clientPeople: flagClientPersonRows,
                    pimReportFilePath: this.state.PIMReportPath,
                    productionStartDate: this.state.productionStartDate,
                    deliveryOfGoods: this.state.selectedDelivery,
                    numberOfDelivery: this.state.numberOfDelivery,
                    ipi: this.state.IPI,
                    numberOfIpi: this.state.IPINumber,
                    finalFat: this.state.finalFat,
                    inspections: flagInspectionRows,
                    pqcsVisitDates: flagPQCSDateRows.join()
                }

                var response = await handleRequest("POST", "/InspectionDates/update", updatedID);
                if (response.data !== null && response.data !== undefined) {
                    if (response.data === "SUCCESS") {
                        const updatedIAP = {
                            id: this.state.IAP,
                            pimdate: moment(this.state.PIMDate, 'DD/MM/YYYY'),
                            pimdateAck: this.state.PIMDate,
                        }
                        var response2 = await handleRequest("POST", "/InspectionAssignmentPackage/update2", updatedIAP);
                        this.setState({
                            modalEdit: false,
                            inspectionDatesId: null
                        });
                        this.resetInputs();
                        this.restartTable();
                        this.showSuccess("All data has been updated successfully.");
                    }
                    else {
                        this.showError();
                    }
                }
                else {
                    this.showError();
                }
            } else {
                this.showWarning();
            }
        }
    }

    showOrHideModalPdfViewer = () => {
        this.setState({
            modalPdfViewer: !this.state.modalPdfViewer,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    deleteModal = (row) => {
        this.setState({
            inspectionDatesId: row.id,
            deleteModal: true
        });
    }

    showOrHideModalDelete = () => {
        this.setState({
            deleteModal: !this.state.deleteModal
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    deleteInspectionDates = async () => {
        if (this.state.inspectionDatesId !== null) {
            const selectedPA = {
                id: this.state.inspectionDatesId
            }
            var response = await handleRequest("POST", "/InspectionDates/delete", selectedPA);
            if (response.data !== null && response.data !== undefined) {
                if (response.data === "SUCCESS") {
                    this.setState({
                        deleteModal: false,
                        inspectionDatesId: null
                    });
                    this.restartTable();
                    this.showSuccess("Data has been deleted successfully.");
                }
                else {
                    this.showError();
                }
            } else {
                this.showError();
            }

            setTimeout(() => {
                document.body.style.overflow = "auto";
                document.body.style.paddingRight = "0px";
            }, 500);
        }
    }

    cancelProcess = () => {
        this.resetInputs();
    }

    disabledDate = (current) => {
        return current < moment().endOf('day');
    }

    onChangePIMDate = async (date, dateString) => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();
        today = dd + '/' + mm + '/' + yyyy;

        if (dateString !== "") {
            let today1 = new Date();
            let date1 = moment(dateString, "DD/MM/YYYY")._d;
            today1.setDate(today1.getDate() + this.state.minimumDaysForNotification);

            if (date1 < today1)
                this.setState({
                    PIMDateCheck: true,
                    PIMDate: dateString,
                    modalPIMDateMessage: true
                });
            else
                this.setState({
                    PIMDateCheck: false,
                    PIMDate: dateString
                });
        } else {
            let today1 = new Date();
            let date1 = moment(today, "DD/MM/YYYY")._d;
            today1.setDate(today1.getDate() + this.state.minimumDaysForNotification);

            if (date1 < today1)
                this.setState({
                    PIMDateCheck: true,
                    PIMDate: today,
                    modalPIMDateMessage: true
                });
            else
                this.setState({
                    PIMDateCheck: false,
                    PIMDate: today
                });
        }
    }

    yesToPIMDate = () => {
        this.setState({
            PIMDateCheck: true,
            modalPIMDateMessage: false
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    noToPIMDate = () => {
        this.setState({
            PIMDateCheck: false,
            modalPIMDateMessage: false
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalPIMDateMessage = () => {
        this.setState({
            modalPIMDateMessage: !this.state.modalPIMDateMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    yesToIPIDate = () => {
        this.setState({
            IPIDateCheck: false,
            modalIPIDateMessage: false
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    noToIPIDate = () => {
        this.setState({
            IPIDateCheck: true,
            modalIPIDateMessage: false
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalIPIDateMessage = () => {
        this.setState({
            modalIPIDateMessage: !this.state.modalIPIDateMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    beforeUpload = (file) => {
        const isPDF = file.type === "image/jpeg" || file.type === "image/png" || file.type === "application/pdf";
        if (!isPDF) {
            message.error("You can only upload JPEG, PNG or PDF file!");
            $(".ant-upload-list").empty();
        }
        const isLt10M = file.size / 1024 / 1024 < 10;
        if (!isLt10M) {
            message.error("Image must smaller than 10MB!");
            $(".ant-upload-list").empty();
        }
        return isPDF && isLt10M;
    }

    handlePIMReportFileChange = (info) => {
        if (info.file.status !== "uploading") {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);

            console.log(info.file);
            if (info.file.response.length > 0) {
                this.setState({
                    PIMReportPath: info.file.response[0]
                });
            }
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
        }
    }

    onChangeProductionStartDate = async (date, dateString) => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (dateString !== "") {
            this.setState({
                productionStartDate: dateString
            });
        } else {
            this.setState({
                productionStartDate: today
            });
        }
    }

    onChangeDeliveryOptions = e => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        this.setState({
            selectedDelivery: e.target.value,
        });

        var numberOfDelivery = [];
        var IPINumber = [];

        for (let index = 0; index < this.state.IPINumber; index++) {
            IPINumber.push(today);
        }

        if (e.target.value === "One Time") {
            numberOfDelivery.push([IPINumber, today]);
        } else if (e.target.value === "Multiple") {
            for (let index = 0; index < this.state.numberOfDelivery; index++) {
                numberOfDelivery.push([IPINumber, today]);
            }
        }

        this.setState({
            numberOfDeliveryRows: numberOfDelivery
        });

        setTimeout(() => {
            if (document.getElementsByClassName('p-inputtext')[0] !== undefined)
                document.getElementsByClassName('p-inputtext')[0].style.textAlign = "center";

            if (document.getElementsByClassName('p-inputtext')[1] !== undefined)
                document.getElementsByClassName('p-inputtext')[1].style.textAlign = "center";
        }, 100);
    }

    onChangeIPICheck = e => {
        this.setState({
            IPI: e.target.checked
        });

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        var numberOfDelivery = [];
        var IPINumber = [];

        if (e.target.checked) {
            for (let index = 0; index < this.state.IPINumber; index++) {
                IPINumber.push(today);
            }

            if (this.state.selectedDelivery === "One Time") {
                numberOfDelivery.push([IPINumber, today]);
            } else if (this.state.selectedDelivery === "Multiple") {
                for (let index = 0; index < this.state.numberOfDelivery; index++) {
                    numberOfDelivery.push([IPINumber, today]);
                }
            }
        } else {
            this.setState({
                IPINumber: 0
            });

            if (this.state.selectedDelivery === "One Time") {
                numberOfDelivery.push([IPINumber, today]);
            } else if (this.state.selectedDelivery === "Multiple") {
                for (let index = 0; index < this.state.numberOfDelivery; index++) {
                    numberOfDelivery.push([IPINumber, today]);
                }
            }
        }

        this.setState({
            numberOfDeliveryRows: numberOfDelivery
        });

        setTimeout(() => {
            if (document.getElementsByClassName('p-inputtext')[0] !== undefined)
                document.getElementsByClassName('p-inputtext')[0].style.textAlign = "center";

            if (document.getElementsByClassName('p-inputtext')[1] !== undefined)
                document.getElementsByClassName('p-inputtext')[1].style.textAlign = "center";
        }, 100);
    }

    onChangeNumberOfDelivery = (e) => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        var flagNumberOfDeliveryRows = this.state.numberOfDeliveryRows;

        if (e.value === "") {
            this.setState({
                numberOfDelivery: 0,
                numberOfDeliveryRows: []
            });
        } else {
            var numberOfDelivery = [];
            var IPINumber = [];

            for (let index = 0; index < this.state.IPINumber; index++) {
                IPINumber.push(today);
            }

            for (let index = 0; index < e.value; index++) {
                numberOfDelivery.push([IPINumber, today]);
            }

            for (let index = 0; index < e.value; index++) {
                for (let index1 = 0; index1 < this.state.IPINumber; index1++) {
                    if (flagNumberOfDeliveryRows[index] !== undefined)
                        if (flagNumberOfDeliveryRows[index][0] !== undefined)
                            if (flagNumberOfDeliveryRows[index][0][index1] !== undefined && flagNumberOfDeliveryRows[index][0][index1] !== null)
                                numberOfDelivery[index][0][index1] = flagNumberOfDeliveryRows[index][0][index1];
                            else
                                numberOfDelivery[index][0][index1] = today;
                }

                if (flagNumberOfDeliveryRows[index] !== undefined)
                    if (flagNumberOfDeliveryRows[index][1] !== undefined && flagNumberOfDeliveryRows[index][1] !== null)
                        numberOfDelivery[index][1] = flagNumberOfDeliveryRows[index][1];
                    else
                        numberOfDelivery[index][1] = today;
            }

            this.setState({
                numberOfDelivery: e.value,
                numberOfDeliveryRows: numberOfDelivery
            });
        }
    }

    onChangeIPINumber = (e) => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        var flagNumberOfDeliveryRows = this.state.numberOfDeliveryRows;

        if (e.value === "") {
            this.setState({
                IPINumber: 0,
                numberOfDeliveryRows: []
            });
        } else {
            var numberOfDelivery = [];
            var IPINumber = [];

            for (let index = 0; index < e.value; index++) {
                IPINumber.push(today);
            }

            if (this.state.selectedDelivery === "One Time") {
                numberOfDelivery.push([IPINumber, today]);
            } else if (this.state.selectedDelivery === "Multiple") {
                for (let index = 0; index < this.state.numberOfDelivery; index++) {
                    numberOfDelivery.push([IPINumber, today]);
                }
            }

            for (let index = 0; index < this.state.numberOfDelivery; index++) {
                for (let index1 = 0; index1 < e.value; index1++) {
                    if (flagNumberOfDeliveryRows[index] !== undefined)
                        if (flagNumberOfDeliveryRows[index][0] !== undefined)
                            if (flagNumberOfDeliveryRows[index][0][index1] !== undefined && flagNumberOfDeliveryRows[index][0][index1] !== null)
                                numberOfDelivery[index][0][index1] = flagNumberOfDeliveryRows[index][0][index1];
                            else
                                numberOfDelivery[index][0][index1] = today;
                }

                if (flagNumberOfDeliveryRows[index] !== undefined)
                    if (flagNumberOfDeliveryRows[index][1] !== undefined && flagNumberOfDeliveryRows[index][1] !== null)
                        numberOfDelivery[index][1] = flagNumberOfDeliveryRows[index][1];
                    else
                        numberOfDelivery[index][1] = today;
            }

            this.setState({
                IPINumber: e.value,
                numberOfDeliveryRows: numberOfDelivery
            });
        }
    }

    stringifyNumber = (n) => {
        if (n < 20) return special[n];
        if (n % 10 === 0) return deca[Math.floor(n / 10) - 2] + 'ieth';
        return deca[Math.floor(n / 10) - 2] + 'y-' + special[n % 10];
    }

    pad = (d) => {
        return (d < 10) ? '0' + d.toString() : d.toString();
    }

    addZero = (i) => {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }

    summaryModal = (row) => {
        console.log(row);
        doc = new jsPDF('p', 'pt');

        if (row.iap !== undefined && row.inspections !== undefined)
            if (row.iap.projectTakeOffList !== undefined) {
                var headerText = row.iap.iap + " - " + row.iap.projectTakeOffList.description + " Summary";

                var columnsPIM = [
                    {
                        title: "PIM",
                        dataKey: "pimLocation"
                    },
                    {
                        title: "",
                        dataKey: "pimDate"
                    },
                    {
                        title: "",
                        dataKey: "pimStatus"
                    },
                    {
                        title: "",
                        dataKey: "pimFeedback"
                    }
                ];

                var rowsPIM = [{
                    "pimLocation": row.iap.pimlocation,
                    "pimDate": this.dbDateToString(row.iap.pimdate),
                    "pimStatus": "Planned",
                    "pimFeedback": ""
                }
                ];

                var finalY = doc.lastAutoTable.finalY || 10;
                doc.setFontSize(18);
                doc.setTextColor(40);

                var textWidth = doc.getStringUnitWidth(headerText) * doc.internal.getFontSize() / doc.internal.scaleFactor;
                var textOffset = (doc.internal.pageSize.width - textWidth) / 2;
                doc.text(textOffset, finalY + 15, headerText);

                var today = new Date();
                var dd = String(today.getDate()).padStart(2, '0');
                var mm = String(today.getMonth() + 1).padStart(2, '0');
                var yyyy = today.getFullYear();
                var hh = this.addZero(today.getHours());
                var minute = this.addZero(today.getMinutes());
                var ss = this.addZero(today.getSeconds());
                today = dd + '/' + mm + '/' + yyyy + " " + hh + ":" + minute + ":" + ss;
                var newdat = "Date: " + today;

                doc.setFontSize(8);
                doc.text(newdat, 555, 40, 'right');

                doc.autoTable(columnsPIM, rowsPIM, {
                    startY: finalY + 40,
                    columnStyles: {
                        0: {
                            cellWidth: 150
                        },
                        1: {
                            cellWidth: 'auto'
                        },
                        2: {
                            cellWidth: 'auto'
                        },
                        3: {
                            cellWidth: 'auto'
                        }
                    },
                });
                finalY = doc.lastAutoTable.finalY;

                var ipiNumber = 1;

                for (let index = 0; index < row.inspections.length; index++) {
                    var columnsParty = [
                        {
                            title: this.stringifyNumber(index + 1).charAt(0).toUpperCase() + this.stringifyNumber(index + 1).slice(1) + " Party Delivery",
                            dataKey: "ipiNumber"
                        },
                        {
                            title: "",
                            dataKey: "ipiDate"
                        },
                        {
                            title: "",
                            dataKey: "ipiStatus"
                        },
                        {
                            title: "",
                            dataKey: "ipiFeedback"
                        }
                    ];

                    var rowsParty = [];
                    var ipiLength = row.inspections[index].ipiDates.split(",").length;
                    var ipiDates = row.inspections[index].ipiDates.split(",");

                    for (let index1 = 0; index1 < ipiLength; index1++) {
                        rowsParty.push({
                            "ipiNumber": "IPI-" + this.pad(ipiNumber),
                            "ipiDate": ipiDates[index1],
                            "ipiStatus": "Planned",
                            "ipiFeedback": ""
                        });

                        ipiNumber++;
                    }

                    rowsParty.push({
                        "ipiNumber": "FAT-" + this.pad(index + 1),
                        "ipiDate": row.inspections[index].fatDate,
                        "ipiStatus": "Planned",
                        "ipiFeedback": ""
                    })

                    doc.autoTable(columnsParty, rowsParty, {
                        startY: finalY,
                        columnStyles: {
                            0: {
                                cellWidth: 150
                            },
                            1: {
                                cellWidth: 'auto'
                            },
                            2: {
                                cellWidth: 'auto'
                            },
                            3: {
                                cellWidth: 'auto'
                            }
                        },
                    });

                    finalY = doc.lastAutoTable.finalY;
                }

                this.setState({
                    docData: doc.output('datauristring'),
                    modalPdfViewer: true
                });

                //docData = doc.output('datauri').data;
                //doc.save('table.pdf');
            }
    }

    printPdf = () => {
        window.open(this.state.docData);
    }

    onChangeIPIDate = async (index0, index1, date, dateString) => {
        let list = this.state.numberOfDeliveryRows;
        let listIPI = this.state.numberOfDeliveryRows[index0][0];
        let dateOrderIPIDate = true;

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (dateString !== "") {
            let canDate = true;
            let date1 = moment(this.state.productionStartDate, 'DD/MM/YYYY')._d;
            let date2 = moment(dateString, 'DD/MM/YYYY')._d;
            if (date1.getTime() >= date2.getTime()) {
                canDate = false;
            }

            if (canDate) {
                if ((index1 - 1) >= 0) {
                    let date3 = moment(dateString, 'DD/MM/YYYY')._d;
                    let date4 = moment(this.state.numberOfDeliveryRows[index0][0][index1 - 1], 'DD/MM/YYYY')._d;

                    if (date3.getTime() <= date4.getTime()) {
                        dateOrderIPIDate = false;
                    }
                }

                if (dateOrderIPIDate) {
                    listIPI[index1] = dateString;
                } else {
                    listIPI[index1] = today;
                    this.showWarning2();
                }
            }
            else {
                listIPI[index1] = today;
                this.showWarning3();
            }
        } else {
            listIPI[index1] = today;
        }

        list[index0][0] = listIPI.slice();

        this.setState({
            numberOfDeliveryRows: list.slice()
        });
    }

    onChangeFATDate = async (index0, date, dateString) => {
        let list = this.state.numberOfDeliveryRows;

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        if (dateString !== "") {
            let canDate = true;
            let date1 = moment(this.state.productionStartDate, 'DD/MM/YYYY')._d;
            let date2 = moment(dateString, 'DD/MM/YYYY')._d;
            if (date1.getTime() >= date2.getTime()) {
                canDate = false;
            }

            if (canDate) {
                var dateOrderIPIFATDate = true;
                var IPIDates = [];
                var IPIDates2 = [];
                for (let index1 = 0; index1 < this.state.numberOfDeliveryRows[index0][0].length; index1++) {
                    IPIDates.push(moment(this.state.numberOfDeliveryRows[index0][0][index1], 'DD/MM/YYYY')._d);
                    IPIDates2.push(moment(this.state.numberOfDeliveryRows[index0][0][index1], 'DD/MM/YYYY')._d);
                }

                IPIDates.sort((a, b) => a - b);
                var dateOrderIPIDate = this.arraysEqualDates(IPIDates, IPIDates2);

                if (dateOrderIPIDate) {
                    let IPIDatesLength = this.state.numberOfDeliveryRows[index0][0].length - 1;
                    let date1 = moment(dateString, 'DD/MM/YYYY')._d;
                    let date2 = moment(this.state.numberOfDeliveryRows[index0][0][IPIDatesLength], 'DD/MM/YYYY')._d;

                    if (date1.getTime() <= date2.getTime()) {
                        dateOrderIPIFATDate = false;
                    }
                } else {
                    dateOrderIPIFATDate = false;
                }

                if (dateOrderIPIFATDate) {
                    list[index0][1] = dateString;
                } else {
                    list[index0][1] = today;
                    this.showWarning2();
                }
            }
            else {
                list[index0][1] = today;
                this.showWarning3();
            }
        } else {
            list[index0][1] = today;
        }

        this.setState({
            numberOfDeliveryRows: list.slice()
        });
    }

    addNewPQCSDateLine = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        _PQCSDateRows.push(
            {
                PQCSIndex: _PQCSDateRows.length + 1,
                date: today,
            }
        );

        this.setState({
            PQCSDateRows: _PQCSDateRows
        });
    }

    removePQCSDateLine = (index, e) => {
        _PQCSDateRows.splice(index, 1);

        this.setState({
            PQCSDateRows: _PQCSDateRows
        });
    }

    onChangePQCSDate = async (index, date, dateString) => {
        let list = this.state.PQCSDateRows;
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();
        today = dd + '/' + mm + '/' + yyyy;

        if (dateString !== "") {
            let canDate = true;
            let date1 = moment(this.state.productionStartDate, 'DD/MM/YYYY')._d;
            let date2 = moment(dateString, 'DD/MM/YYYY')._d;
            if (date1.getTime() >= date2.getTime()) {
                canDate = false;
            }

            if (canDate)
                list[index].date = dateString;
            else {
                list[index].date = today;
                this.showWarning3();
            }
        } else {
            list[index].date = today;
        }

        this.setState({
            PQCSDateRows: list.slice()
        });
    }

    showOrHideModalShow1 = () => {
        this.setState({
            modalShow1: !this.state.modalShow1,
            modalShow2: false,
            modalShow3: false
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalShow2 = () => {
        this.setState({
            modalShow1: false,
            modalShow2: !this.state.modalShow2,
            modalShow3: false
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalShow3 = () => {
        this.setState({
            modalShow1: false,
            modalShow2: false,
            modalShow3: !this.state.modalShow3
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    handleChangeIAP = (value) => {
        this.setState({ IAP: value });

        let iapIndex = this.state.inspectionAssignmentTable.findIndex(iap => iap.id === value);
        if (iapIndex !== -1) {
            let flagIAP = this.state.inspectionAssignmentTable[iapIndex];
            let rfqIndex = this.state.rfqList.findIndex(rfq => rfq.id === flagIAP.rfqId);
            if (rfqIndex !== -1) {
                let flagRfq = this.state.rfqList[rfqIndex];
                let mrIndex = this.state.mrList.findIndex(mr => mr.projectTakeOffList.id === flagRfq.projectTakeOffList.id);
                if (mrIndex !== -1) {
                    let flagMr = this.state.mrList[mrIndex];
                    if (flagMr.pim === "Yes") {
                        this.setState({
                            PIM: true,
                        });
                    } else {
                        this.setState({
                            PIM: false,
                        });
                    }
                }
            }
        }

        for (let index = 0; index < this.state.inspectionAssignmentTable.length; index++) {
            if (value === this.state.inspectionAssignmentTable[index].id) {
                if (this.state.inspectionAssignmentTable[index].pimdate !== null) {
                    var flagDate = moment(this.state.inspectionAssignmentTable[index].pimdate, "YYYY/MM/DD")._d;
                    var dd = String(flagDate.getDate()).padStart(2, '0');
                    var mm = String(flagDate.getMonth() + 1).padStart(2, '0');
                    var yyyy = flagDate.getFullYear();
                    flagDate = dd + '/' + mm + '/' + yyyy;

                    let today1 = new Date();
                    let date1 = moment(this.state.inspectionAssignmentTable[index].pimdate, "YYYY/MM/DD")._d;
                    today1.setDate(today1.getDate() + this.state.minimumDaysForNotification);

                    let earliestDate = new Date();
                    earliestDate.setDate(earliestDate.getDate() + this.state.minimumDaysForNotification + 1);
                    let dd1 = String(earliestDate.getDate()).padStart(2, '0');
                    let mm1 = String(earliestDate.getMonth() + 1).padStart(2, '0');
                    let yyyy1 = earliestDate.getFullYear();
                    earliestDate = dd1 + '/' + mm1 + '/' + yyyy1;

                    console.log(earliestDate);

                    if (date1 < today1)
                        this.setState({
                            PIMDateCheck: true
                        });
                    else
                        this.setState({
                            PIMDateCheck: false
                        });

                    this.setState({
                        PIMDate: flagDate,
                        PIMLocation: this.state.inspectionAssignmentTable[index].pimlocation,
                        earliestDate: earliestDate
                    });
                } else {
                    var today = new Date();
                    var dd = String(today.getDate()).padStart(2, '0');
                    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                    var yyyy = today.getFullYear();
                    today = dd + '/' + mm + '/' + yyyy;

                    this.setState({
                        PIMDate: today,
                        PIMLocation: this.state.inspectionAssignmentTable[index].pimlocation,
                        PIMDateCheck: false
                    });
                }
            }
        }
    }

    addNewCompany = () => {
        _companyPersonRows.push(
            {
                id: 0,
                companyPersonIndex: _companyPersonRows.length + 1,
                staffId: ""
            }
        );

        this.setState({
            companyPersonRows: _companyPersonRows
        });
    }

    removeThisCompany = (index) => {
        _companyPersonRows.splice(index, 1);

        this.setState({
            companyPersonRows: _companyPersonRows
        });
    }

    handleChangeCompanyPerson = (index, value) => {
        let list = this.state.companyPersonRows;
        list[index].staffId = value;

        for (let index1 = 0; index1 < this.state.staffList.length; index1++) {
            if (value === this.state.staffList[index1].id) {
                list[index].designationId = this.state.staffList[index1].designationId;
                list[index].phoneNo = this.state.staffList[index1].residencePersonelPhone;
                list[index].email = this.state.staffList[index1].residenceCompanyEmail;
            }
        }

        this.setState({
            companyPersonRows: list.slice()
        });
    }

    addNewVendor = () => {
        _vendorPersonRows.push(
            {
                id: 0,
                vendorPersonIndex: _vendorPersonRows.length + 1,
                personName: "",
                designationName: "",
                phoneNo: "",
                email: "",
            }
        );

        this.setState({
            vendorPersonRows: _vendorPersonRows
        });
    }

    removeThisVendor = (index) => {
        _vendorPersonRows.splice(index, 1);

        this.setState({
            vendorPersonRows: _vendorPersonRows
        });
    }

    handleChangeVendorPersonName = (index, e) => {
        let list = this.state.vendorPersonRows;
        list[index].personName = e.target.value;

        this.setState({
            vendorPersonRows: list.slice()
        });
    }

    handleChangeVendorDesignationName = (index, e) => {
        let list = this.state.vendorPersonRows;
        list[index].designationName = e.target.value;

        this.setState({
            vendorPersonRows: list.slice()
        });
    }

    handleChangeVendorPhoneNo = (index, e) => {
        let list = this.state.vendorPersonRows;
        list[index].phoneNo = e.target.value;

        this.setState({
            vendorPersonRows: list.slice()
        });
    }

    handleChangeVendorEmail = (index, e) => {
        let list = this.state.vendorPersonRows;
        list[index].email = e.target.value;

        this.setState({
            vendorPersonRows: list.slice()
        });
    }

    addNewClient = () => {
        _clientPersonRows.push(
            {
                id: 0,
                clientPersonIndex: _clientPersonRows.length + 1,
                personName: "",
                designationName: "",
                phoneNo: "",
                email: "",
            }
        );

        this.setState({
            clientPersonRows: _clientPersonRows
        });
    }

    removeThisClient = (index) => {
        _clientPersonRows.splice(index, 1);

        this.setState({
            clientPersonRows: _clientPersonRows
        });
    }

    handleChangeClientPersonName = (index, e) => {
        let list = this.state.clientPersonRows;
        list[index].personName = e.target.value;

        this.setState({
            clientPersonRows: list.slice()
        });
    }

    handleChangeClientDesignationName = (index, e) => {
        let list = this.state.clientPersonRows;
        list[index].designationName = e.target.value;

        this.setState({
            clientPersonRows: list.slice()
        });
    }

    handleChangeClientPhoneNo = (index, e) => {
        let list = this.state.clientPersonRows;
        list[index].phoneNo = e.target.value;

        this.setState({
            clientPersonRows: list.slice()
        });
    }

    handleChangeClientEmail = (index, e) => {
        let list = this.state.clientPersonRows;
        list[index].email = e.target.value;

        this.setState({
            clientPersonRows: list.slice()
        });
    }

    render() {
        const { selectID } = this.state;
        const howToUseThis = this.state.howToUseThis;

        const layoutView = (
            isSidebarOpened,
            container,
            main,
            toolbar,
            sidebar,
        ) => {
            return defaultLayout(
                isSidebarOpened,
                container,
                main,
                toolbar(renderToolbar),
                sidebar,
            );
        };

        const renderToolbar = (toolbarSlot) => {
            return (
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            flexGrow: 1,
                            flexShrink: 1,
                            justifyContent: 'center',
                        }}
                    >
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.previousPageButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.nextPageButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomOutButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomPopover}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.zoomInButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.downloadButton}
                        </div>
                        <div style={{ padding: '0 2px' }}>
                            {toolbarSlot.moreActionsPopover}
                        </div>
                    </div>
                </div>
            );
        };

        // var itemPADesignationAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "PurchasingAuthorityDesignation");
        // var itemPAItemGroupAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "PurchasingAuthorityItemGroup");

        // var itemPADesignationRequired = this.state.dynamicInputs.find(p => p.inputKey === "PurchasingAuthorityDesignation");
        // var itemPAItemGroupRequired = this.state.dynamicInputs.find(p => p.inputKey === "PurchasingAuthorityItemGroup");

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 16 },
        };

        const tailLayout = {
            wrapperCol: {
                offset: 4,
                span: 16,
            },
        };

        return <div>
                <MenuProvider id="menu_id">

                    <Modal
                        show={this.state.modalMessage}
                        onHide={this.showOrHideModalMessage}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>

                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <Row >
                                    <Col sm="12">
                                        {this.state.messageTip == "Success" &&
                                            <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                        {this.state.messageTip == "Warning" &&
                                            <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                        {this.state.messageTip == "Error" &&
                                            <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                    </Col>
                                </Row>
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <Row >
                                <Col sm="12">
                                    {this.state.message}
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalShowWhatIs}
                        onHide={this.showOrHideModalWhatIs}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered  >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">{<FormattedMessage id="RightClickModalTitle" defaultMessage="What is This?" />}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormBoot>
                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <FormBoot.Label style={{ color: 'black' }} column sm="12">
                                        {this.state.inputDescription}
                                    </FormBoot.Label>
                                </FormBoot.Group>
                            </FormBoot>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalWhatIs} title={selectID}>Close</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalMNDSettings}
                        onHide={this.showOrHideModalMNDSettings}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">MND Settings</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormBoot>
                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="1">
                                    </Col>
                                    <Col sm="3">
                                        <ReactButton variant="outline-primary" style={{ marginTop: "1rem" }} block onClick={this.goLanguage}>Language</ReactButton>
                                    </Col>
                                    <Col sm="2">
                                        <ReactButton variant="outline-dark" style={{ marginTop: "1rem" }} block onClick={this.goHowToUse}>How To Use</ReactButton>
                                    </Col>
                                    <Col sm="2">
                                        <ReactButton variant="outline-warning" style={{ marginTop: "1rem" }} block onClick={this.goWhatIs}>What is</ReactButton>
                                    </Col>
                                    <Col sm="3">
                                        <ReactButton variant="outline-danger" style={{ marginTop: "1rem" }} block onClick={this.goDynamicPage}>Dynamic Page</ReactButton>
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                </FormBoot.Group>
                            </FormBoot>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="info" onClick={this.showOrHideModalMNDSettings}>OK</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalSettings}
                        onHide={this.showOrHideModalSettings}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Settings</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormBoot>
                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <FormBoot.Label style={{ color: 'black' }} column sm="4">
                                        How To Use:
                                </FormBoot.Label>
                                    <Col sm="4">
                                        <Checkbox style={{ marginTop: '1rem' }} checked={this.state.howToUseSetting} onChange={(e) => {
                                            this.setState({
                                                howToUseSetting: e.target.checked,
                                            });
                                        }}>Don't Show Again</Checkbox>
                                    </Col>
                                </FormBoot.Group>

                                <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <FormBoot.Label style={{ color: 'black' }} column sm="4">
                                        What is:
                                </FormBoot.Label>
                                    <Col sm="4">
                                        <Checkbox style={{ marginTop: '1rem' }} checked={this.state.whatIsSetting} onChange={(e) => {
                                            this.setState({
                                                whatIsSetting: e.target.checked,
                                            });
                                        }}>Don't Show Again</Checkbox>
                                    </Col>
                                </FormBoot.Group>
                            </FormBoot>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalSettings}>Cancel</ReactButton>
                            <ReactButton variant="primary" onClick={this.setUserSettings}>Save Changes</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalHowToUse}
                        onHide={this.showOrHideModalHowToUse}
                        aria-labelledby="contained-modal-title-vcenter"
                        size="xl"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">How To Use This Page?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ backgroundColor: "white", color: "black", padding: "10px" }} dangerouslySetInnerHTML={{ __html: howToUseThis }}></div>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="info" onClick={this.showOrHideModalHowToUse}>OK</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.deleteModal}
                        onHide={this.showOrHideModalDelete}
                        aria-labelledby="contained-modal-title-vcenter"
                        backdrop="static"
                        centered >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p style={{ textAlign: "center", color: "black" }}>
                                <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this data?" />
                            </p>
                            <p style={{ textAlign: "center", color: "black" }}>
                                <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideModalDelete}>
                                <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                            </ReactButton>
                            <ReactButton variant="danger" onClick={this.deleteInspectionDates}>
                                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                            </ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.modalShow1}
                        onHide={this.showOrHideModalShow1}
                        aria-labelledby="contained-modal-title-vcenter"
                        backdrop="static"
                        size="lg"
                        centered >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Company</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.state.companyPersonRows.map((item, index) => (
                                <div>
                                    <Form>
                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="4">
                                                <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                                    Name:
                                                </FormBoot.Label>
                                            </Col>
                                            <Col sm="4">
                                                <Select value={item.staffId} placeholder="Select" style={{ width: 200 }}
                                                    onChange={(value) => this.handleChangeCompanyPerson(index, value)} >
                                                    {this.state.staffList.map((i) => (
                                                        <Select.Option key={i.id} value={i.id}>
                                                            {i.name + ` ` + i.lastName}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                            <Col sm="4">
                                                {(index !== 0) && (<ReactButton id="PurchasingAuthoritySaveButton"
                                                    style={{ width: '100%' }} onClick={(e) => this.removeThisCompany(index, e)} variant="danger">Remove This Company Person
                                                </ReactButton>)}
                                            </Col>
                                        </Form.Group>

                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="4">
                                                <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                                    Designation:
                                                </FormBoot.Label>
                                            </Col>
                                            <Col sm="8">
                                                <Select value={item.designationId} disabled placeholder="Select" style={{ width: 200 }}>
                                                    {this.state.designations.map((i) => (
                                                        <Select.Option key={i.id} value={i.id}>
                                                            {i.designationName}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                        </Form.Group>

                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="4">
                                                <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                                    Phone No:
                                                </FormBoot.Label>
                                            </Col>
                                            <Col sm="8">
                                                <Input value={item.phoneNo} disabled style={{ width: "100%" }} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="4">
                                                <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                                    Email:
                                                </FormBoot.Label>
                                            </Col>
                                            <Col sm="8">
                                                <Input value={item.email} disabled style={{ width: "100%" }} />
                                            </Col>
                                        </Form.Group>

                                        <hr />

                                        {(this.state.companyPersonRows.length === (index + 1)) && <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="4">
                                            </Col>
                                            <Col sm="4">
                                                <ReactButton id="PurchasingAuthoritySaveButton"
                                                    style={{ width: '100%' }} onClick={this.addNewCompany} variant="primary">Add New Company Person
                                            </ReactButton>
                                            </Col>
                                            <Col sm="4">
                                            </Col>
                                        </Form.Group>}
                                    </Form>
                                </div>
                            ))}
                        </Modal.Body>
                        <Modal.Footer style={{ padding: "0px", marginTop: "1rem" }}>
                            <img onClick={this.showOrHideModalShow2} src="/media/next1.png" alt="" height="35px"></img>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.modalShow2}
                        onHide={this.showOrHideModalShow2}
                        aria-labelledby="contained-modal-title-vcenter"
                        backdrop="static"
                        size="lg"
                        centered >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Vendor</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.state.vendorPersonRows.map((item, index) => (
                                <div>
                                    <Form>
                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="4">
                                                <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                                    Name:
                                                </FormBoot.Label>
                                            </Col>
                                            <Col sm="5">
                                                <Input value={item.personName} style={{ width: "100%" }}
                                                    onChange={(e) => this.handleChangeVendorPersonName(index, e)} />
                                            </Col>
                                            <Col sm="3">
                                                {(index !== 0) && (<ReactButton id="PurchasingAuthoritySaveButton"
                                                    style={{ width: '100%' }} onClick={(e) => this.removeThisVendor(index, e)} variant="danger">Remove This Vendor
                                                </ReactButton>)}
                                            </Col>
                                        </Form.Group>

                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="4">
                                                <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                                    Designation:
                                                </FormBoot.Label>
                                            </Col>
                                            <Col sm="8">
                                                <Input value={item.designationName} style={{ width: "100%" }}
                                                    onChange={(e) => this.handleChangeVendorDesignationName(index, e)} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="4">
                                                <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                                    Phone No:
                                                </FormBoot.Label>
                                            </Col>
                                            <Col sm="8">
                                                <Input value={item.phoneNo} style={{ width: "100%" }}
                                                    onChange={(e) => this.handleChangeVendorPhoneNo(index, e)} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="4">
                                                <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                                    Email:
                                                </FormBoot.Label>
                                            </Col>
                                            <Col sm="8">
                                                <Input value={item.email} style={{ width: "100%" }}
                                                    onChange={(e) => this.handleChangeVendorEmail(index, e)} />
                                            </Col>
                                        </Form.Group>

                                        <hr />

                                        {(this.state.vendorPersonRows.length === (index + 1)) && <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="4">
                                            </Col>
                                            <Col sm="4">
                                                <ReactButton id="PurchasingAuthoritySaveButton"
                                                    style={{ width: '100%' }} onClick={this.addNewVendor} variant="primary">Add New Vendor Person
                                                </ReactButton>
                                            </Col>
                                            <Col sm="4">
                                            </Col>
                                        </Form.Group>}
                                    </Form>
                                </div>
                            ))}
                        </Modal.Body>
                        <Modal.Footer style={{ padding: "0px" }}>
                            <Space>
                                <img onClick={this.showOrHideModalShow1} src="/media/back1.png" alt="" height="35px"></img>
                                <img onClick={this.showOrHideModalShow3} src="/media/next1.png" alt="" height="35px"></img>
                            </Space>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.modalShow3}
                        onHide={this.showOrHideModalShow3}
                        aria-labelledby="contained-modal-title-vcenter"
                        backdrop="static"
                        size="lg"
                        centered >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Client</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.state.clientPersonRows.map((item, index) => (
                                <div>
                                    <Form>
                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="4">
                                                <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                                    Name:
                                            </FormBoot.Label>
                                            </Col>
                                            <Col sm="5">
                                                <Input value={item.personName} style={{ width: "100%" }}
                                                    onChange={(e) => this.handleChangeClientPersonName(index, e)} />
                                            </Col>
                                            <Col sm="3">
                                                {(index !== 0) && (<ReactButton id="PurchasingAuthoritySaveButton"
                                                    style={{ width: '100%' }} onClick={(e) => this.removeThisClient(index, e)} variant="danger">Remove This Client
                                                </ReactButton>)}
                                            </Col>
                                        </Form.Group>

                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="4">
                                                <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                                    Designation:
                                            </FormBoot.Label>
                                            </Col>
                                            <Col sm="8">
                                                <Input value={item.designationName} style={{ width: "100%" }}
                                                    onChange={(e) => this.handleChangeClientDesignationName(index, e)} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="4">
                                                <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                                    Phone No:
                                            </FormBoot.Label>
                                            </Col>
                                            <Col sm="8">
                                                <Input value={item.phoneNo} style={{ width: "100%" }}
                                                    onChange={(e) => this.handleChangeClientPhoneNo(index, e)} />
                                            </Col>
                                        </Form.Group>

                                        <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="4">
                                                <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                                    Email:
                                            </FormBoot.Label>
                                            </Col>
                                            <Col sm="8">
                                                <Input value={item.email} style={{ width: "100%" }}
                                                    onChange={(e) => this.handleChangeClientEmail(index, e)} />
                                            </Col>
                                        </Form.Group>

                                        <hr />

                                        {(this.state.clientPersonRows.length === (index + 1)) && <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="4">
                                            </Col>
                                            <Col sm="4">
                                                <ReactButton id="PurchasingAuthoritySaveButton"
                                                    style={{ width: '100%' }} onClick={this.addNewClient} variant="primary">Add New Client Person
                                            </ReactButton>
                                            </Col>
                                            <Col sm="4">
                                            </Col>
                                        </Form.Group>}
                                    </Form>
                                </div>
                            ))}
                        </Modal.Body>
                        <Modal.Footer style={{ padding: "0px" }}>
                            <Space>
                                <img onClick={this.showOrHideModalShow2} src="/media/back1.png" alt="" height="35px"></img>
                                <img onClick={this.showOrHideModalShow3} src="/media/next1.png" alt="" height="35px"></img>
                            </Space>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        size="xl"
                        show={this.state.modalPdfViewer}
                        onHide={this.showOrHideModalPdfViewer}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                PDF Report Viewer
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ textAlign: "right", marginBottom: "1rem" }}>
                                <ReactButton style={{ marginLeft: "1rem" }}
                                    onClick={this.printPdf}
                                >
                                    Print
                                </ReactButton>
                                <FacebookShareButton style={{ marginLeft: "1rem" }} url={this.state.pdfFile} quote="Voucher PDF File Link">
                                    <FacebookIcon size={32} round />
                                </FacebookShareButton>
                                <EmailShareButton style={{ marginLeft: "1rem" }} url={this.state.pdfFile} body="Voucher PDF File Link is here: " subject="Voucher PDF File Link">
                                    <EmailIcon size={32} round />
                                </EmailShareButton>
                                <WhatsappShareButton
                                    style={{ marginLeft: "1rem" }}
                                    url={this.state.pdfFile}
                                    title="Voucher PDF File Link"
                                    separator=": "
                                >
                                    <WhatsappIcon size={32} round />
                                </WhatsappShareButton>
                            </div>
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
                                <div style={{ height: "900px" }}>
                                    <Viewer fileUrl={this.state.docData} layout={layoutView} />
                                </div>
                            </Worker>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton
                                variant="secondary"
                                onClick={this.showOrHideModalPdfViewer}
                            >
                                Close
                            </ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalPIMDateMessage}
                        onHide={this.showOrHideModalPIMDateMessage}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered size="lg"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <Row>
                                    <Col sm="12">
                                        <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />
                                    </Col>
                                </Row>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Row>
                                    <Col sm="12">
                                        Please, check PIM Date. The inspection date can't be proceed. The easliest inspection date can be {this.state.earliestDate}. Do you want to change the date?
                                    </Col>
                                </Row>
                                <Form.Group style={{ marginTop: '1rem', marginBottom: '1rem' }} as={Row}>
                                    <Col sm="4">
                                        <ReactButton style={{ width: '100%' }} onClick={this.yesToPIMDate}>
                                            Yes
                                        </ReactButton>
                                    </Col>
                                    <Col sm="4">
                                        <ReactButton style={{ width: '100%' }} onClick={this.noToPIMDate}>
                                            No, proceed the date
                                        </ReactButton>
                                    </Col>
                                    <Col sm="4">
                                        <ReactButton style={{ width: '100%' }} onClick={this.showOrHideModalPIMDateMessage}>
                                            Cancel
                                        </ReactButton>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                    </Modal>

                    <Modal
                        show={this.state.modalIPIDateMessage}
                        onHide={this.showOrHideModalIPIDateMessage}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered size="lg"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <Row>
                                    <Col sm="12">
                                        <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />
                                    </Col>
                                </Row>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Row>
                                    <Col sm="12">
                                        Please, check Party Date(s). The inspection date can't be proceed. The easliest inspection date can be {this.state.earliestDate}. Do you want to change the date?
                                    </Col>
                                </Row>
                                <Form.Group style={{ marginTop: '1rem', marginBottom: '1rem' }} as={Row}>
                                    <Col sm="4">
                                        <ReactButton style={{ width: '100%' }} onClick={this.yesToIPIDate}>
                                            Yes
                                        </ReactButton>
                                    </Col>
                                    <Col sm="4">
                                        <ReactButton style={{ width: '100%' }} onClick={this.noToIPIDate}>
                                            No, proceed the date
                                        </ReactButton>
                                    </Col>
                                    <Col sm="4">
                                        <ReactButton style={{ width: '100%' }} onClick={this.showOrHideModalIPIDateMessage}>
                                            Cancel
                                        </ReactButton>
                                    </Col>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                    </Modal>

                    <div>
                        <Row>
                            <Col sm="1">
                                {this.state.isAdmin ?
                                    <IconButton color="primary" onClick={this.showMNDSettings}>
                                        <SettingsIcon />
                                    </IconButton>
                                    :
                                    <IconButton color="primary" onClick={this.showSettings}>
                                        <SettingsIcon />
                                    </IconButton>
                                }
                            </Col>
                            <Col sm="10">
                            </Col>
                            <Col sm="1" style={{ textAlign: "right" }}>
                                {!this.state.howToUseSetting && <IconButton color="primary" onClick={this.showHowToUse}>
                                    <HelpIcon />
                                </IconButton>
                                }
                            </Col>
                        </Row>
                    </div>

                    <Card>

                        <div style={{ padding: "4px 16px 4px 16px" }}>
                            <Row>
                                <Col xs={3}></Col>
                                <Col xs={3}></Col>
                                <Col xs={3}></Col>
                                <Col xs={3}>
                                    {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                        <div>
                                            {this.state.hidden &&
                                                <ReactButton id="PurchasingAuthorityCreateNewButton" style={{ width: '100%', marginBottom: '1rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                            }
                                            {!this.state.hidden &&
                                                <ReactButton id="PurchasingAuthorityStopProcessButton" style={{ width: '100%', marginBottom: '1rem' }} variant="danger" onClick={this.stopProcess}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the Process" /></ReactButton>
                                            }
                                        </div>
                                    }
                                </Col>
                            </Row>
                        </div>

                        <div hidden={this.state.hidden}>
                            <Form>
                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="2">
                                    </Col>
                                    <Col sm="2">
                                        <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                            Inspection Assignment Package:
                                        </FormBoot.Label>
                                    </Col>
                                    <Col sm="3">
                                        <Select value={this.state.IAP} placeholder="Select" style={{ width: 200 }}
                                            onChange={this.handleChangeIAP} >
                                            {this.state.inspectionAssignmentTable.map((i) => (
                                                <Select.Option key={i.id} value={i.id}>
                                                    {i.iap}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Col>
                                    <Col sm="3">
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="2">
                                    </Col>
                                    <Col sm="2">
                                        <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                            Minimum Days for Notification:
                                            </FormBoot.Label>
                                    </Col>
                                    <Col sm="2">
                                        <AntInputNumber min={0} style={{ width: '100%' }}
                                            onChange={(e) => this.setState({ minimumDaysForNotification: e })} value={this.state.minimumDaysForNotification} />
                                    </Col>
                                    <Col sm="3">
                                        <Checkbox style={{ color: 'black' }} onChange={(e) => { this.setState({ workingDaysCheck: e.target.checked }) }}
                                            checked={this.state.workingDaysCheck}>Working Days</Checkbox>
                                    </Col>
                                </Form.Group>

                                {(this.state.PIM) && (<div style={{ backgroundColor: "white", padding: "10px", marginBottom: "1rem", border: '1px solid #1ab394' }}>
                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Col sm="2">
                                            <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                                PIM Date:
                                            </FormBoot.Label>
                                        </Col>
                                        <Col sm="3">
                                            <DatePicker value={moment(this.state.PIMDate, 'DD/MM/YYYY')} disabledDate={this.disabledDate} format="DD/MM/YYYY" onChange={this.onChangePIMDate} />
                                        </Col>
                                        {(this.state.PIMDateCheck) && <Col sm="3">
                                            <FormBoot.Label style={{ marginTop: "0.5rem", color: "red" }}>
                                                This inspection date is less than {this.state.minimumDaysForNotification} days.
                                            </FormBoot.Label>
                                        </Col>}
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Col sm="2">
                                            <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                                PIM Location:
                                            </FormBoot.Label>
                                        </Col>
                                        <Col sm="3">
                                            <Input disabled value={this.state.PIMLocation} style={{ width: "100%" }} onChange={(e) => { this.setState({ PIMLocation: e.target.value }) }} />
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Col sm="2">
                                            <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                                Attandees:
                                            </FormBoot.Label>
                                        </Col>
                                        <Col sm="2">
                                            <ReactButton id="PurchasingAuthoritySaveButton"
                                                style={{ width: '100%' }} onClick={this.showOrHideModalShow1} variant="primary">Set Attandees
                                            </ReactButton>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>

                                    <Form.Group style={{ marginBottom: '0px' }} as={Row}>
                                        <Col sm="2">
                                        </Col>
                                        <Col sm="2">
                                            <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                                PIM Report:
                                                    </FormBoot.Label>
                                        </Col>
                                        <Col sm="3">
                                            <Upload name="avatar" className="avatar-uploader" action={`${API_BASE}/InspectionDates/upload`} beforeUpload={this.beforeUpload}
                                                onChange={this.handlePIMReportFileChange}>
                                                <AntButton><UploadOutlined />Upload File</AntButton>
                                            </Upload>
                                        </Col>
                                        <Col sm="3">
                                        </Col>
                                    </Form.Group>

                                </div>)}

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="2">
                                    </Col>
                                    <Col sm="2">
                                        <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                            Production Start Date:
                                            </FormBoot.Label>
                                    </Col>
                                    <Col sm="3">
                                        <DatePicker value={moment(this.state.productionStartDate, 'DD/MM/YYYY')}
                                            disabledDate={this.disabledDate} format="DD/MM/YYYY" onChange={this.onChangeProductionStartDate} />
                                    </Col>
                                    <Col sm="3">
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="2">
                                    </Col>
                                    <Col sm="2">
                                        <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                            Required Documents:
                                        </FormBoot.Label>
                                    </Col>
                                    <Col sm="3">

                                    </Col>
                                    <Col sm="3">
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="2">
                                    </Col>
                                    <Col sm="2">
                                        <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                            Delivery of Goods:
                                        </FormBoot.Label>
                                    </Col>
                                    <Col sm="3">
                                        <Radio.Group onChange={this.onChangeDeliveryOptions} value={this.state.selectedDelivery}>
                                            <Radio value="One Time">One Time</Radio>
                                            <Radio value="Multiple">Multiple</Radio>
                                        </Radio.Group>
                                    </Col>
                                    <Col sm="3">
                                    </Col>
                                </Form.Group>

                                {(this.state.selectedDelivery === "Multiple") && (<Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="2">
                                    </Col>
                                    <Col sm="2">
                                        <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                            Number of delivery:
                                        </FormBoot.Label>
                                    </Col>
                                    <Col sm="3">
                                        <InputNumber
                                            size={15}
                                            id="horizontal"
                                            value={this.state.numberOfDelivery}
                                            onValueChange={this.onChangeNumberOfDelivery}
                                            showButtons
                                            buttonLayout="horizontal"
                                            step={1}
                                            min={0}
                                            max={10}
                                            decrementButtonClassName="p-button-danger"
                                            incrementButtonClassName="p-button-success"
                                            incrementButtonIcon="pi pi-plus"
                                            decrementButtonIcon="pi pi-minus"
                                        />
                                    </Col>
                                    <Col sm="3">
                                    </Col>
                                </Form.Group>)}

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="2">
                                    </Col>
                                    <Col sm="2">
                                        <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                            Inspections:
                                        </FormBoot.Label>
                                    </Col>
                                    <Col sm="3">
                                        <Space>
                                            <Checkbox style={{ color: 'black', marginTop: "0.5rem" }} onChange={this.onChangeIPICheck}
                                                checked={this.state.IPI}>In process (IPI)</Checkbox>
                                            {(this.state.IPI) && (<InputNumber
                                                size={15}
                                                id="horizontal"
                                                value={this.state.IPINumber}
                                                onValueChange={this.onChangeIPINumber}
                                                showButtons
                                                buttonLayout="horizontal"
                                                step={1}
                                                min={0}
                                                max={10}
                                                decrementButtonClassName="p-button-danger"
                                                incrementButtonClassName="p-button-success"
                                                incrementButtonIcon="pi pi-plus"
                                                decrementButtonIcon="pi pi-minus"
                                            />)}
                                        </Space>
                                    </Col>
                                    <Col sm="3">
                                    </Col>
                                </Form.Group>

                                <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                                    <Col sm="2">
                                    </Col>
                                    <Col sm="2">
                                    </Col>
                                    <Col sm="3">
                                        <Checkbox style={{ color: 'black' }} checked={this.state.finalFat}>Final (FAT)</Checkbox>
                                    </Col>
                                    <Col sm="3">
                                    </Col>
                                </Form.Group>

                                {this.state.numberOfDeliveryRows.map((item, index) => (
                                    <div>
                                        {this.state.numberOfDeliveryRows[index][0].map((item1, index1) => (
                                            <div>
                                                <Form.Group key={index1} style={{ marginBottom: '1rem' }} as={Row}>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Col sm="2">
                                                        <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                                            {index + 1}. Party IPI {index1 + 1} Date:
                                                        </FormBoot.Label>
                                                    </Col>
                                                    <Col sm="4">
                                                        <DatePicker value={moment(item1, 'DD/MM/YYYY')} disabledDate={this.disabledDate} format="DD/MM/YYYY"
                                                            onChange={(date, dateString) => { this.onChangeIPIDate(index, index1, date, dateString) }} />
                                                    </Col>
                                                    <Col sm="3">
                                                    </Col>
                                                </Form.Group>
                                            </div>))}

                                        <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="2">
                                            </Col>
                                            <Col sm="2">
                                                <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                                    {index + 1}. Party FAT Date:
                                                </FormBoot.Label>
                                            </Col>
                                            <Col sm="4">
                                                <DatePicker value={moment(item[1], 'DD/MM/YYYY')} disabledDate={this.disabledDate} format="DD/MM/YYYY"
                                                    onChange={(date, dateString) => { this.onChangeFATDate(index, date, dateString) }} />
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Form.Group>
                                    </div>
                                ))}

                                {this.state.PQCSDateRows.map((item, index) => (
                                    <div>
                                        <Form.Group key={index} style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="2">
                                            </Col>
                                            <Col sm="2">
                                                <FormBoot.Label style={{ marginTop: "0.5rem" }}>
                                                    PQCS Visit Date - {index + 1}:
                                                </FormBoot.Label>
                                            </Col>
                                            <Col sm="3">
                                                <Space>
                                                    <DatePicker value={moment(item.date, 'DD/MM/YYYY')} disabledDate={this.disabledDate} format="DD/MM/YYYY"
                                                        onChange={(date, dateString) => { this.onChangePQCSDate(index, date, dateString) }} />
                                                    {(this.state.PQCSDateRows.length === (index + 1)) && (<Col sm="3">
                                                        <AntButton type="primary" onClick={this.addNewPQCSDateLine} icon={<PlusOutlined />}></AntButton>
                                                    </Col>)}
                                                    {(this.state.PQCSDateRows.length === (index + 1)) && (this.state.PQCSDateRows.length > 1) && (<AntButton type="primary" danger
                                                        onClick={(e) => this.removePQCSDateLine(index, e)} icon={<CloseOutlined />}></AntButton>)}
                                                </Space>
                                            </Col>
                                        </Form.Group>
                                    </div>
                                ))}

                                <div hidden={this.state.hiddenSave}>
                                    {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "CreateNew") >= 0 &&
                                        <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="2">
                                            </Col>
                                            <Col sm="2">
                                            </Col>
                                            <Col sm="3">
                                                <ReactButton id="PurchasingAuthoritySaveButton"
                                                    style={{ width: '100%' }} onClick={this.saveInpectionDates} variant="success"><FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                                                </ReactButton>
                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </FormBoot.Group>
                                    }
                                </div>

                                <div hidden={this.state.hiddenUpdate}>
                                    {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 &&
                                        <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="3">
                                                <ReactButton id="PurchasingAuthorityCancelButton"
                                                    style={{ width: '100%' }} onClick={this.cancelProcess} variant="secondary"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" />
                                                </ReactButton>
                                            </Col>
                                            <Col sm="3">
                                                <ReactButton id="PurchasingAuthorityUpdateButton"
                                                    style={{ width: '100%' }} onClick={this.saveInpectionDates} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" />
                                                </ReactButton>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </FormBoot.Group>
                                    }
                                </div>

                            </Form>
                        </div>

                        <Table style={{ padding: "4px 1rem 0px 4px" }} dataSource={this.state.inspectionDates} bordered>
                            <Column width='50px' title="S/N" key="sn" dataIndex="index" />
                            <Column title="IAP" key="iap" render={(text, record) => {
                                if (record.iapId !== undefined)
                                    if (record.iapId !== 0)
                                        return record.iap.iap;
                                    else
                                        return "";
                                else return "";
                            }} />
                            <Column title="PIM Date" key="pimDate" render={(text, record) => {
                                if (record.iapId !== undefined)
                                    if (record.iapId !== 0) {
                                        if (record.iap.pimdate !== null) {
                                            var flagDate = moment(record.iap.pimdate, "YYYY/MM/DD")._d;
                                            var dd = String(flagDate.getDate()).padStart(2, '0');
                                            var mm = String(flagDate.getMonth() + 1).padStart(2, '0');
                                            var yyyy = flagDate.getFullYear();
                                            flagDate = dd + '/' + mm + '/' + yyyy;

                                            return flagDate;
                                        }
                                        else
                                            return "";
                                    }
                                    else
                                        return "";
                                else return "";
                            }} />
                            <Column title="PIM Attandees" key="pimAttandees" render={(text, record) => {
                                var flagAttStr = "";
                                var flagAttStrCompany = "";
                                var flagAttStrVendor = "";
                                var flagAttStrClient = "";

                                if (record.companyPeople !== undefined) {
                                    if (record.companyPeople.length > 0) {
                                        for (let index = 0; index < record.companyPeople.length; index++) {
                                            if (record.companyPeople[index].staff !== undefined)
                                                flagAttStrCompany += (record.companyPeople[index].staff.name + " " + record.companyPeople[index].staff.lastName + ", ");
                                            if ((record.companyPeople.length - 1) === index)
                                                flagAttStrCompany = flagAttStrCompany.slice(0, -2);
                                        }
                                    }
                                }

                                if (record.vendorPeople !== undefined) {
                                    if (record.vendorPeople.length > 0) {
                                        for (let index = 0; index < record.vendorPeople.length; index++) {
                                            flagAttStrVendor += (record.vendorPeople[index].name + ", ");
                                            if ((record.vendorPeople.length - 1) === index)
                                                flagAttStrVendor = flagAttStrVendor.slice(0, -2);
                                        }
                                    }
                                }

                                if (record.clientPeople !== undefined) {
                                    if (record.clientPeople.length > 0) {
                                        for (let index = 0; index < record.clientPeople.length; index++) {
                                            flagAttStrClient += (record.clientPeople[index].name + ", ");
                                            if ((record.clientPeople.length - 1) === index)
                                                flagAttStrClient = flagAttStrClient.slice(0, -2);
                                        }
                                    }
                                }

                                return (
                                    <p style={{ marginBottom: "0px" }}><b>Company: </b>{flagAttStrCompany}<b><br />Vendor: </b>{flagAttStrVendor}<b><br />Client: </b>{flagAttStrClient}</p>
                                );
                            }} />
                            <Column title="Production Start Date" key="productionStartDate" dataIndex="productionStartDate" />
                            <Column title="Inspection Dates" key="inspectionDates" render={(text, record) => {
                                var flagDateStr = "";
                                if (record.inspections !== undefined)
                                    if (record.inspections.length > 0) {
                                        for (let index = 0; index < record.inspections.length; index++) {
                                            flagDateStr += (record.inspections[index].ipiDates.replace(/,/g, ", ") + ", ");
                                        }
                                        flagDateStr = flagDateStr.slice(0, -2);
                                    }
                                return flagDateStr;
                            }} />
                            <Column title="PQCS Visit Dates" key="pqcsVisitDates" render={(text, record) => {
                                if (record.pqcsVisitDates !== undefined) {
                                    var flagStr = record.pqcsVisitDates.replace(/,/g, ", ");
                                    return flagStr;
                                }
                                else return "";
                            }} />
                            <Column
                                width='50px'
                                title="Action"
                                key="action"
                                render={(text, record) => {
                                    if ((this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) ||
                                        (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0)) {

                                        if ((this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) &&
                                            (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0)) {
                                            return (<div style={{ textAlign: "center" }}>
                                                <React.Fragment key={record.id} >
                                                    <CustomMaterialMenuDeleteEditSummary row={record}
                                                        onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.changeButtonUpdate.bind(this)} onSummaryRow={this.summaryModal.bind(this)} />
                                                </React.Fragment>
                                            </div>);
                                        }
                                        else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
                                            return (<React.Fragment key={record.id} >
                                                <CustomMaterialMenuEditSummary row={record}
                                                    onEditRow={this.changeButtonUpdate.bind(this)} onSummaryRow={this.summaryModal.bind(this)} />
                                            </React.Fragment>);
                                        } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
                                            return (<React.Fragment key={record.id} >
                                                <CustomMaterialMenuDeleteSummary row={record}
                                                    onDeleteRow={this.deleteModal.bind(this)} onSummaryRow={this.summaryModal.bind(this)} />
                                            </React.Fragment>);
                                        }
                                    }
                                    else {
                                        return (<React.Fragment key={record.id} >
                                            <CustomMaterialMenuSummary row={record}
                                                onEditRow={this.changeButtonUpdate.bind(this)} onSummaryRow={this.summaryModal.bind(this)} />
                                        </React.Fragment>);
                                    }
                                }}
                            />
                        </Table>

                    </Card>
               </MenuProvider>
                {
                    !this.state.whatIsSetting &&
                    <Menu id='menu_id'>
                        <Item onClick={this.dynamicInputs}>What is This?</Item>
                    </Menu>
                }
            </div>
      
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(InspectionDates)