import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    fetchMovies,
    //deleteMovie
} from '../../redux/actions/cityAction'; //'../ ../../actions/movies';
import MoviesList from './SelectItemList'; //'../SelectItemList';
console.log("MoviesList sayfasındasın");
class MoviesPage extends Component {
    static propTypes = {
        cityReducer: PropTypes.object.isRequired,
        //deleteMovie: PropTypes.func.isRequired
    };

    componentDidMount() {
        this.props.fetchMovies();
    }

    render() {
        return (
            <div>
                <h2>Movies</h2>
                <MoviesList
                    //deleteMovie={this.props.deleteMovie}
                    cityReducer={this.props.cityReducer} />
            </div>
        );
    }
}

const mapStateToProps = ({ cityReducer }) => {
    return {
        cityReducer
    }
};

const mapDispatchToProps = {
    fetchMovies,
    //deleteMovie
};

export default connect(mapStateToProps,mapDispatchToProps)(MoviesPage);
