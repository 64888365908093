/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { handleRequest } from '../../redux/config/env';
import { FormattedMessage } from "react-intl";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Checkbox } from 'primereact/checkbox';
import { Modal, Form, Col, Row, Button } from 'react-bootstrap';
import { Redirect } from "react-router-dom";
import { Cascader } from 'antd';

const dataList = [];
const generateList = data => {
    for (let i = 0; i < data.length; i++) {
        const node = data[i];

        dataList.push({ key: node.data.id, lineage: node.data.lineage });
        if (node.children) {
            generateList(node.children);
        }
    }
};

function formIdBul(formId){
    if(formId=="General"){
        return -1;
    }else if(formId=="What Is"){
        return -2;
    }else if(formId=="Required"){
        return -3;
    }else if(formId=="Login"){
        return -4;
    }else if(formId=="Text To Para"){
        return -5;
    }else if(formId=="WPS"){
        return -6;
    }
    else if(formId=="Personal Info"){
        return -7;
    }
    return formId;
}

export class DynamicPage extends Component {
    constructor() {
        super();
        this.state = {
            formName: "Select Page",
            forms: [],

            companies: [],
            companyId: 0,
            companyName: "Select Company",

            attributes: [],
            selectedAttributes: [],

            dblist: [],
            authorizations: [],

            dynamicMenuCascader: [],
            formId: [],

        };

        this.onAttributeChange = this.onAttributeChange.bind(this);
    }

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    };

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    };

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };

    componentWillMount = async () => {

        const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && Boolean(p.dynamicMenu.page) && p.dynamicMenu.page === "DynamicPage");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
            });

        } catch (error) {
        }

        var response = await handleRequest("GET", "/DynamicPage/getAll");

        if (response.data.length > 0) {
            this.setState({
                dblist: response.data
            });
        } else {
            this.setState({
                dblist: []
            });
        }

        const defaultSelected = [...this.state.selectedAttributes];
        this.state.attributes.forEach(element => {
            if (!element.canBeDynamic && !defaultSelected.includes(element.id))
                defaultSelected.push(element.id);
        });

        this.setState({
            selectedAttributes: defaultSelected
        });
        
        this.getDynamicMenu();
    }

    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModelLanguage");
        if (responseTree.data.length !== 0) {
            generateList(responseTree.data.treeTable);
            this.setState({
                dynamicMenuCascader: responseTree.data.cascader
            });
        }
    }

    getDynamicInputs = async (dynamicMenuId) => {
        if (dynamicMenuId == null || dynamicMenuId == undefined || dynamicMenuId == "" || dynamicMenuId == 0) {
            dynamicMenuId = -1;
        }
        
        dynamicMenuId=formIdBul(dynamicMenuId);

        const obj = {
            dynamicMenuId: dynamicMenuId,
        };
        const response = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (response.data.length !== 0) {
            var _selectedVal = response.data;
            const defaultSelected = [];
            if (_selectedVal !== null && _selectedVal !== undefined) {
                if (this.state.dblist.length > 0) {
                    var filteredList = this.state.dblist.filter(p => p.formId == dynamicMenuId)
                    filteredList.forEach(element => {
                        if (element.visible)
                            defaultSelected.push(element.attributeKey);
                    });
                }
                var attributes = [];
                _selectedVal.forEach(element => {
                    if (!element.canBeDynamic && !defaultSelected.includes(element.id)) {
                        defaultSelected.push(element.id);
                    }

                    var abc = {};
                    abc.canBeDynamic = element.canBeDynamic;
                    abc.desc = element.inputValue;
                    abc.id = element.id;

                    attributes.push(abc);
                });

                var formName = [];
                formName.push(_selectedVal[0].inputValue);

                this.setState({
                    formName: formName,
                    attributes: attributes,
                    selectedAttributes: defaultSelected
                });
            }
        } else {
            this.setState({
                formName: "",
                attributes: [],
                selectedAttributes: []
            });
        }
    }

    saveChanges = async () => {
        var list = [];
        if (this.state.formId.length > 0 && this.state.selectedAttributes.length > 0 && this.state.attributes.length > 0) {
            this.state.selectedAttributes.forEach(element => {
                var formId=formIdBul( this.state.formId[this.state.formId.length - 1]);
                const newItem = {
                    formId: formId,
                    attributeValue: this.state.attributes.find(p => p.id === element).desc,
                    attributeKey: element,
                    visible: true
                };
                list.push(newItem);
            });

            this.state.attributes.forEach(element => {
                if (!this.state.selectedAttributes.includes(element.id)) {
                    var formId=formIdBul( this.state.formId[this.state.formId.length - 1]);
                    const newItem = {
                        formId: formId,
                        attributeValue: element.desc,
                        attributeKey: element.id,
                        visible: false
                    };
                    list.push(newItem);
                }
            });

            var response = await handleRequest("POST", "/DynamicPage/saveAll", list);
            if (response.data !== null && response.data !== undefined) {
                if (response.data == "SUCCESS") {
                    this.showSuccess();
                }
                else {
                    this.showError();
                }
            }
            else {
                this.showWarning();
            }
        } else {
            this.showWarning(<FormattedMessage id="DYNPMessageWarning1" defaultMessage="You must chose one or more inputs !" />);
        }
    };

    onDynamicMenuCascaderChange = (value) => {
        this.setState({
            formId: value,
        })

        if (Boolean(value) && value.length > 0) {
            this.getDynamicInputs(value[value.length - 1]);
        } else {
            this.setState({
                formName: "",
                attributes: [],
                selectedAttributes: []
            });
        }
    }

    onAttributeChange(e) {
        let selectedAttributesFlag = [...this.state.selectedAttributes];
        if (e.checked)
            selectedAttributesFlag.push(e.value);
        else
            selectedAttributesFlag.splice(selectedAttributesFlag.indexOf(e.value), 1);

        this.setState({ selectedAttributes: selectedAttributesFlag });
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    render() {

        return <div>
                <Modal
                    show={this.state.modalMessage}
                    onHide={this.showOrHideModalMessage}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>

                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <Row >
                                <Col sm="12">
                                    {this.state.messageTip == "Success" &&
                                        <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                    {this.state.messageTip == "Warning" &&
                                        <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                    {this.state.messageTip == "Error" &&
                                        <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                </Col>
                            </Row>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Row >
                            <Col sm="12">
                                {this.state.message}
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
                    </Modal.Footer>
                </Modal>

                <Card>
                    <Row>

                        <Col sm="12">
                            <Form>
                                <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} >
                                    <Form.Label style={{ color: 'black' }} column sm="2">
                                        <FormattedMessage id="DYNPFormName" defaultMessage="Form Name" />
                                    </Form.Label>
                                    <Col sm="3">
                                        <Cascader style={{ width: '100%' }} value={this.state.formId}
                                            options={this.state.dynamicMenuCascader}
                                            onChange={this.onDynamicMenuCascaderChange} />
                                    </Col>
                                    <Col sm="7"></Col>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        {this.state.attributes.map(atr => (
                            <Col sm="3">
                                <Checkbox style={{ marginTop: '1rem' }} value={atr.id} disabled={!atr.canBeDynamic} onChange={this.onAttributeChange} checked={this.state.selectedAttributes.includes(atr.id)}></Checkbox>
                                <label style={{ marginTop: '1rem' }} htmlFor="cb2" className="p-checkbox-label">{atr.desc}</label>
                            </Col>
                        ))}
                    </Row>
                    <Row>

                        <Col sm="2"></Col>
                        <Col sm="3">
                            <Button onClick={this.saveChanges}
                                style={{ width: '100%', marginTop: '5rem' }} variant="success">
                                <FormattedMessage id="GeneralButtonSave" defaultMessage="Save" />
                            </Button>
                        </Col>
                    </Row>
                </Card>
            </div>
        
    }
}


const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(DynamicPage)  