/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { handleRequest } from '../../redux/config/env';
import { FormattedMessage } from "react-intl";
import MNDTableSettings from '../MND/MNDTableSettings';
import * as Mnd from "../../partials/content/CustomComponents";
import CustomMaterialMenuInventory from '../settings/ForComponents/CustomMaterialMenuInventory';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import { Col, Row, Modal, Button} from 'react-bootstrap';
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { Button as PrimeButton } from "primereact/button";
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css"; 
import { InputText } from 'primereact/inputtext';

const expandable = { expandedRowRender: record => <b>{record.id}</b> };
const title = () => 'Here is title';
var footer = () => 'Here is footer';

export class InventoryItems extends Component {
    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            inventoryItemsList: [],
            type: '',
            abbreviation: '',
            departmentSelectItems:[],
            departmentId:0,
            disciplineSelectItems:[],
            disciplineId:0,
            selectedDisciplineIds:[],
            relatedDisciplines:[],

            dynamicAttributes: [],
            authorizations: [],
            Inputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: null,
            departmentCascader: [],
            selectedInventory:[],

        };

        this.actionTemplate = this.actionTemplate.bind(this); 

    }

    formRef = React.createRef();

    componentDidMount = async () => {
        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {

                this.setState({
                    isAdmin: this.props.auth.user.userType === 2 ? true : false
                });

            }

        this.setStandardizations();
        this.restartTable();
        this.getTableProperties();
        this.getDepartment();
    }

    getTableProperties = async () => {
        this.inventoryItems = JSON.parse(localStorage.getItem('inventoryItems'));
        if (this.inventoryItems != null && this.inventoryItems != undefined && this.inventoryItems != "") {
    
            await this.setState({
                bordered: this.inventoryItems.bordered,
                title: this.inventoryItems.titleDrm == true ? title : undefined,
                titleDrm: this.inventoryItems.titleDrm,
                showHeader: this.inventoryItems.showHeader,
                footer: this.inventoryItems.footerDrm == true ? footer : undefined,
                footerDrm: this.inventoryItems.footerDrm,
                expandable: this.inventoryItems.expandableDrm == true ? expandable : {},
                expandableDrm: this.inventoryItems.expandableDrm,
                rowSelection: this.inventoryItems.rowSelection,
                ellipsis: this.inventoryItems.ellipsis,
                tableLayout: this.inventoryItems.tableLayout,
                size: this.inventoryItems.size,
                top: this.inventoryItems.top,
                bottom: this.inventoryItems.bottom,
                editBgColor: this.inventoryItems.editBgColor,
                deleteBgColor: this.inventoryItems.deleteBgColor,
                selectedNodeKey: null,
                yScroll: this.inventoryItems.yScroll,
                xScroll: this.inventoryItems.xScroll,
                paginationDisabled: {
                    defaultPageSize: 5, total: this.state.inventoryItemsList.length,
                    showQuickJumper: true, showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '25', '50'], total: this.state.inventoryItemsList.length, showQuickJumper: true,
                    position: [this.inventoryItems.top, this.inventoryItems.bottom]
                },
                kontrol: true,
            });
        } else {
            await this.setState({
                bordered: false,
                title: undefined,
                titleDrm: false,
                showHeader: true,
                footer: undefined,
                footerDrm: false,
                expandable: false,
                expandableDrm: false,
                rowSelection: undefined,
                ellipsis: false,
                tableLayout: undefined,
                size: 'default',
                top: 'none',
                bottom: 'bottomRight',
                editBgColor: '#fff',
                deleteBgColor: '#fff',
                selectedNodeKey: null,
                xScroll: undefined,
                paginationDisabled: false,
                kontrol: true,
            });
          }
    }

    restartTable = async () => {
        console.log("RRR", this.props);
        var inventoryLogId=-1;
        if(this.props.row.id!=null && this.props.row.id!="" ){
            inventoryLogId=this.props.row.id;
        }
        const newObj = {
            inventoryLogId: inventoryLogId
        } 
        const response = await handleRequest("POST", "/InventoryItems/getResult", newObj);
        console.log("RRR", response.data);
        if (response.data.length !== 0) {
            const list =  response.data;
            list.forEach(element => {
                element.key=element.id
            });
            this.setState({
                inventoryItemsList: list, 
            });
        }
        if (response.data.length === 0) {
            this.setState({
                inventoryItemsList: [],
            });
        }
    }
  
    getDepartment = async () => {
        const responseTree = await handleRequest("GET", "/Department/getTreeModel");

        if (responseTree.data.length !== 0) {
            this.setState({
                departmentCascader: responseTree.data.cascader
            });
        }else{
            this.setState({
                departmentCascader: []
            });
        }
    }

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    }; 

    setStandardizations = async () => {
        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "InventoryItems" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "InventoryItems"
        };

        const responseInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseInputs.data.length !== 0) {
            this.setState({
                Inputs: responseInputs.data,
            });
        } else {
            this.setState({
                Inputs: [],
            });
        }

        //const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "InventoryItems");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => p.menuId === this.state.formId || p.form === "All")
            });

        } catch (error) {

        }

    }

    getDynamicMenu = async () => {
        try {
            console.log("TTT", this.props.location);
            const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
            if (responseTree.data.length !== 0) {
                var element = responseTree.data.treeTable;
    
                for (let index = 0; index < element.length; index++) {
                    var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());
    
                    if (result !== null)
                        break;
                }
    
                this.setState({
                    formId: result.data.id
                });
    
                this.getUserSettings();
            } else {
                this.setState({
                    formId: null
                });
            }
        } catch (error) {
            console.log("EEE", error);
        }
    }

    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }

    getUserSettings = async () => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + this.state.formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({

                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        }); 
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalTable = async () => {
        this.setState({
            modalTable: !this.state.modalTable,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHidePhotoModal = () => {
        this.setState({
          modalPhotoShow: !this.state.modalPhotoShow,
        });
        setTimeout(() => {
          document.body.style.overflow = "auto";
      }, 500);
    };

    showOrHideModalPdfViewer = () => {
        this.setState({
          modalPdfViewer: !this.state.modalPdfViewer,
        });
        setTimeout(() => {
          document.body.style.overflow = "auto";
      }, 500);
    };
    
    showOrHideReleaseModal = () => {
        this.setState({
            modalRelease: !this.state.modalRelease,
        });
        setTimeout(() => {
          document.body.style.overflow = "auto";
      }, 500);
    };

    showReport = async (fileRealUrl) => {
        try { 
          if (fileRealUrl.endsWith('.pdf')) {
            this.setState({
              pdfFile: fileRealUrl,
              modalPdfViewer: true,
            });
          } else if (fileRealUrl.endsWith('.png') || fileRealUrl.endsWith('.jpg') || fileRealUrl.endsWith('.tiff') || fileRealUrl.endsWith('.jpeg')
            || fileRealUrl.endsWith('.PNG') || fileRealUrl.endsWith('.JPG') || fileRealUrl.endsWith('.TIFF') || fileRealUrl.endsWith('.JPEG')) {
            this.setState({
              imageUrl: fileRealUrl,
              modalPhotoShow: true,
            })
          } else {
            window.location.href = fileRealUrl;
          }
        } catch (error) { 
            this.showError(<FormattedMessage id="GeneralMessageFileCantFound" defaultMessage="File cant found."/>);
        }
    };

    actionTemplate(rowData, column) {
        return <React.Fragment>
        <CustomMaterialMenuInventory row={rowData} onInventoryRow={this.releaseClick.bind(this)}/>
        </React.Fragment>;
    };

    releaseClick  = (row) => {
        this.setState({
          modalRelease: true,
          selectedInventory: row,
        });
    }; 

    onDepartmentCascaderChange = (value) => {
        console.log("VVV", value);
        this.setState({
            values: value
        });
    }

    render() {
        const columns = [
            {
              dataIndex: "index",
              key: "index",
              title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>,
            },
            {
              dataIndex: "date",
              key: "date",
              title: <FormattedMessage id="InventoryItemsDate" defaultMessage="Date" />,
            },
            {
              dataIndex: "action",
              key: "action",
              title: <FormattedMessage id="InventoryItemsAction" defaultMessage="Action" />,
            },
            {
                dataIndex: "department",
                key: "key",
                title:  <FormattedMessage id="InventoryItemsFromTo" defaultMessage="From/to" />,
                render: (data) =>   
                {  
                    if(data!=null && data!=undefined){
                    return data.departmentName;
                    } 
                    return "";
                }
            },
            {
              dataIndex: "quantity",
              key: "quantity",
              title: <FormattedMessage id="InventoryItemsQuantity" defaultMessage="Quantity" />,
            },
            {
                dataIndex: "unit",
                key: "key",
                title: <FormattedMessage id="InventoryItemsUnit" defaultMessage="Unit" />,
            },
        ];
        
        const tableColumns = columns.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
        if (this.state.xScroll === 'fixed') {
          tableColumns[0].fixed = true;
          tableColumns[tableColumns.length - 1].fixed = 'right';
        }

        const layoutView = (
            isSidebarOpened,
            container,
            main,
            toolbar,
            sidebar,
          ) => {
            return defaultLayout(
              isSidebarOpened,
              container,
              main,
              toolbar(renderToolbar),
              sidebar,
            );
        };

        const renderToolbar = (toolbarSlot) => {
            return (
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexGrow: 1,
                    flexShrink: 1,
                    justifyContent: 'center',
                  }}
                >
                  <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.previousPageButton}
                  </div>
                  <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
                  </div>
                  <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.nextPageButton}
                  </div>
                  <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.zoomOutButton}
                  </div>
                  <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.zoomPopover}
                  </div>
                  <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.zoomInButton}
                  </div>
                  <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.downloadButton}
                  </div>
                  <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.moreActionsPopover}
                  </div>
                </div>
              </div>
            );
        };

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };

        return <div>
                <MenuProvider id="menu_id">
                    <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>
                    {this.state.inventoryItemsList.length > 0 && 
                        <Mnd.MNDTable
                        state={this.state}
                        columns={tableColumns} dataSource={this.state.inventoryItemsList}
                        pagination={this.state.paginationDisabled}
                        expandable={this.state.expandable}
                        footer={this.state.footer}
                        title={this.state.title}
                        scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}/> 
                    }
               </MenuProvider>
                {!this.state.whatIsSetting && <Whatis  ></Whatis>
                }

                <Modal
                    show={this.state.modalMessage}
                    onHide={this.showOrHideModalMessage}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    >

                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                        <Row > 
                        <Col sm="12">
                            {this.state.messageTip=="Success" &&  
                            <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} /> }
                        
                            {this.state.messageTip=="Warning" &&  
                            <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                            {this.state.messageTip=="Error" &&  
                            <i className="fas fa-times fa-2x" style={{ color: '#ff0400'}} />}
                        </Col>
                        </Row>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                    <Row > 
                        <Col sm="12">
                        {this.state.message} 
                        </Col>
                        </Row>
                    </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
                </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalTable}
                    onHide={this.showOrHideModalTable}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered size="lg">

                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <Row >
                                <Col sm="12">
                                    <FormattedMessage id="GeneralTableSettings" defaultMessage="Table Settings" />
                                </Col>
                            </Row>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Row >
                            <Col sm="12">
                                <MNDTableSettings id="inventoryItems" />
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>

                <Modal
                    size="md"
                    show={this.state.modalPhotoShow}
                    onHide={this.showOrHidePhotoModal}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Photo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {this.state.imageUrl !== "" ? (
                        <img
                        src={this.state.imageUrl}
                        alt="avatar"
                        style={{ width: "100%" }}
                        />
                    ) : (
                        <p>Photo has not been uploaded.</p>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={this.showOrHidePhotoModal}>
                        <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                    </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    size="xl"
                    show={this.state.modalPdfViewer}
                    onHide={this.showOrHideModalPdfViewer}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        PDF Report Viewer
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
                        <div style={{ height: "900px" }}>
                        <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
                        </div>
                    </Worker> 

                    </Modal.Body>
                    <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={this.showOrHideModalPdfViewer}
                    >
                          <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                        </Button>
                    </Modal.Footer>
                </Modal>
 
            </div>
       
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryItems)