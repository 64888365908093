import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Toast } from 'primereact/toast';
import { Button as ReactButton } from 'react-bootstrap';
import { Col, Modal, Form } from 'react-bootstrap';
import { Column } from 'primereact/column';
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { DataTable } from 'primereact/datatable';
import { Button } from "primereact/button";
import { handleRequest } from '../../redux/config/env';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';

export class PersonelDevice extends Component {

    constructor(props) {
        super(props)
        this.state = {
            deleteModal: false,
            editModal: false,
            matchups: [],
            deviceId: "",
            personels: [],
            personal: "Select Personal",
            editPersonal: "Select Personal",
            personalId: null,
            editPersonalId: null,
            editDeviceId: ""
        };

        this.actionTemplate = this.actionTemplate.bind(this);

        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);
    }

    componentDidMount = async () => {

        const response = await handleRequest("GET", "/Staff/getCompanyItems");

        console.log(response.data);

        if (response.data.length !== 0) {

            var columns = response.data;

            var options = [];

            for (let index = 0; index < columns.length; index++) {
                options.push({
                    name: columns[index].name.toString() + " " + columns[index].lastName.toString(),
                    id: columns[index].id.toString()
                });
            }

            this.setState({
                personels: options
            });
        }

        if (response.data.length === 0) {
            this.setState({
                personels: [],
            });
        }

        const response1 = await handleRequest("GET", "/StaffDevice/getAll");

        console.log(response1);

        if (response1.data.length === 0) {
            this.setState({
                matchups: []
            });
        } else {
            this.setState({
                matchups: response1.data
            });
        }
    }

    actionTemplate(rowData, column) {
        return <React.Fragment> <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.editModal.bind(this)} />   </React.Fragment>;
    }

    deleteModal = (row) => {
        this.setState({
            deleteModal: true,
            editId: row.id
        });
    }

    deleteOK = async () => {
        const deletedMatch = {
            id: this.state.editId
        }

        await handleRequest("POST", "/StaffDevice/delete", deletedMatch);

        this.showOrHideModalDelete();
        this.showSuccess();
        this.refreshStates();
    }

    showOrHideModalDelete = () => {
        this.setState({
            deleteModal: !this.state.deleteModal
        });
    }

    editModal = async (row) => {
        this.setState({
            editModal: true,
            editId: row.id,
            editPersonal: row.staff.name + " " + row.staff.lastName,
            editPersonalId: row.staffId,
            editDeviceId: row.deviceId
        });
    }

    editOK = async () => {

        if(this.state.editPersonalId !== null && this.state.editPersonal !== "Select Personal" && this.state.editDeviceId !== "")
        {
            const editMatch = {
                id: this.state.editId,
                staffId: this.state.editPersonalId,
                deviceId: this.state.editDeviceId
            }
    
            await handleRequest("POST", "/StaffDevice/update", editMatch);

            this.setState({
                editPersonal: "Select Personal",
                editPersonalId: null,
                editDeviceId: "",
                editModal: !this.state.editModal
            })
    
            this.showSuccess();
            this.refreshStates();
        } else {
            this.showError();
            this.refreshStates();
        }
    }

    showOrHideModalEdit = () => {
        this.setState({
            editModal: !this.state.editModal
        });
    }

    saveMatchup = async () => {

        const newMatch = {
            staffId: this.state.personalId,
            deviceId: this.state.deviceId
        }

        await handleRequest("POST", "/StaffDevice/add", newMatch);

        this.showSuccess();
        this.refreshStates();
    }

    refreshStates = async () => {

        const response1 = await handleRequest("GET", "/StaffDevice/getAll");

        if (response1.data.length === 0) {
            this.setState({
                matchups: []
            });
        } else {
            this.setState({
                matchups: response1.data
            });
        }

        this.setState({
            deviceId: "",
            personalId: null,
            personal: "Select Personal"
        });

    }

    showSuccess() {
        this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Successful' });
    }

    showError() {
        this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Something went wrong. Please check inputs.' });
    }

    handleChangePersonal = e => {
        e.preventDefault();
        var idValue;

        for (var z = 0; z < this.state.personels.length; z++) {
            if (this.state.personels[z].name === e.target.value) {
                idValue = this.state.personels[z].id
            }
        }

        console.log(idValue);

        this.setState({
            [e.target.name]: e.target.value,
            personalId: idValue
        });
    }

    handleChangeEditPersonal = e => {
        e.preventDefault();
        var idValue;

        for (var z = 0; z < this.state.personels.length; z++) {
            if (this.state.personels[z].name === e.target.value) {
                idValue = this.state.personels[z].id
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            editPersonalId: idValue
        });
    }

    nameTemplate(rowData, column) {
        return rowData.staff.name + " " + rowData.staff.lastName;
    }

    render() {
        let actionHeader = <Button type="button" icon="pi pi-cog"></Button>

        let optionsPersonels = this.state.personels.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        )

        return (
            <div>
                <Modal
                    show={this.state.deleteModal}
                    onHide={this.showOrHideModalDelete}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Delete Personal Device Match</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{ textAlign: 'center', color: 'black' }}>
                            Do you want to delete this Personal Device Match?
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalDelete}>Close</ReactButton>
                        <ReactButton variant="danger" onClick={this.deleteOK}>Delete</ReactButton>
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.editModal}
                    onHide={this.showOrHideModalEdit}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">Delete Personal Device Match</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form style={{ marginTop: '2rem' }}>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Personal Name:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Control name="editPersonal" value={this.state.editPersonal}
                                        onChange={this.handleChangeEditPersonal} as="select">
                                        <option value="">
                                            Select Personal
                                        </option>
                                        {optionsPersonels}
                                    </Form.Control>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} >
                                    <Form.Label style={{ marginTop: '0.5rem' }}>Personal Device ID:</Form.Label>
                                </Form.Group>

                                <Form.Group as={Col} >
                                    <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ editDeviceId: e.target.value })}
                                        value={this.state.editDeviceId} placeholder="Personal Device ID" />
                                </Form.Group>
                            </Form.Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <ReactButton variant="secondary" onClick={this.showOrHideModalEdit}>Close</ReactButton>
                        <ReactButton variant="success" onClick={this.editOK}>Save</ReactButton>
                    </Modal.Footer>
                </Modal>

                <Toast ref={(el) => this.toast = el} />
                <Card style={{ backgroundColor: "white", width: '1000px' }}>
                    <Form style={{ padding: '5px 0px 5px 5px' }}>
                        <Form.Row>
                            <Form.Group as={Col}>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label style={{ marginTop: '0.3rem', fontWeight: 'bold', fontSize: '14px' }}>Personal Name:</Form.Label>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Control name="personal" value={this.state.personal}
                                    onChange={this.handleChangePersonal} as="select">
                                    <option value="">
                                        Select Personal
                                </option>
                                    {optionsPersonels}
                                </Form.Control>
                            </Form.Group>

                            <Form.Group as={Col}>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col}>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label style={{ marginTop: '0.3rem', fontWeight: 'bold', fontSize: '14px' }}>Personal Device ID:</Form.Label>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <InputText style={{ width: '100%' }} onChange={(e) => this.setState({ deviceId: e.target.value })}
                                    value={this.state.deviceId} placeholder="Personal Device ID" />
                            </Form.Group>

                            <Form.Group as={Col}>
                            </Form.Group>
                        </Form.Row>

                        <Form.Row>
                            <Form.Group as={Col}>
                            </Form.Group>

                            <Form.Group as={Col}>
                                <ReactButton type="button" style={{ width: '100%' }} onClick={this.saveMatchup} variant="info">Save</ReactButton>
                            </Form.Group>

                            <Form.Group as={Col}>
                            </Form.Group>
                        </Form.Row>
                    </Form>

                    <div className="content-section implementation">
                        <Toast ref={(el) => this.toast = el} />
                        <DataTable value={this.state.matchups} paginator={true} responsive={true}
                            rowsPerPageOptions={[5, 10, 20]} rows={10} emptyMessage="No personel-device match data found" >
                            <Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />
                            <Column field="index" style={{ textAlign: 'center', width: '8em' }} sortable header="S/N" />
                            <Column body={this.nameTemplate} style={{ textAlign: 'center' }} sortable header="Personal Name" />
                            <Column field="deviceId" style={{ textAlign: 'center' }} sortable header="Device ID" />
                        </DataTable>
                    </div>
                </Card>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonelDevice)