/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { handleRequest } from '../../redux/config/env';
import { API_BASE } from '../../redux/config/env';
import { FormattedMessage } from "react-intl";
import MNDTableSettings from '../MND/MNDTableSettings';
import * as Mnd from "../../partials/content/CustomComponents";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Col, Row, Modal, Button, Form as FormBoot } from 'react-bootstrap';
import 'react-contexify/dist/ReactContexify.min.css';
import { Item, MenuProvider } from 'react-contexify';
import Whatis from '../standardization/Whatis';
import { Button as PrimeButton } from "primereact/button";
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css"; 
import { DownloadOutlined } from '@ant-design/icons';
import { Button as AntdButton, DatePicker, Select, Input, Divider, Upload } from 'antd';
import CustomMaterialMenuDeleteEdit from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import CustomMaterialMenuEdit from '../settings/ForComponents/CustomMaterialMenuEdit';
import CustomMaterialMenuDelete from '../settings/ForComponents/CustomMaterialMenuDelete';
import moment from 'moment'; 
import { PlusOutlined, UploadOutlined } from '@ant-design/icons'; 
import { MultiSelect } from 'primereact/multiselect';

const { Option } = Select;

const expandable = { expandedRowRender: record => <b>{record.id}</b> };
const title = () => 'Here is title';
var footer = () => 'Here is footer';

export class ToDoListItems extends Component {
    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
            hiddenDataGiris:true,

            toDoListItemsList: [],
            type: '',
            abbreviation: '',
            departmentSelectItems:[],
            departmentId:0,
            disciplineSelectItems:[],
            disciplineId:0,
            selectedDisciplineIds:[],
            relatedDisciplines:[],

            dynamicAttributes: [],
            dynamicInputs: [],
            fileList:[],
            FileUpload:[],
            filePath:"",
            
            authorizations: [],
            Inputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: null,
            selectedToDoListItems:[],
            assigneds:[],
            ToDoListMessageAssigneds:[],
            ToDoListMessageCCs:[],
        };
        this.actionTemplate = this.actionTemplate.bind(this);  
        this.onCCChange = this.onCCChange.bind(this);

    }

    componentDidMount = async () => {
        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {
                this.setState({
                    isAdmin: this.props.auth.user.userType === 2 ? true : false
                });
            }

        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "ToDoList" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }
        
        const obj = {
            pageName: "ToDoList",
        };
        const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseDynamicInputs.data.length !== 0) {
            this.setState({
                dynamicInputs: responseDynamicInputs.data,
            });
        } else {
            this.setState({
                dynamicInputs: [],
            });
        }

        const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "ToDoList");
        try {
            this.setState({
            authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
            });
        } 
        catch (error) {
        }

        this.restartTable();
        this.getTableProperties();
        this.getCodesToDoListMessageAssigned();
        this.getCodesToDoListMessageCC();
        this.getAssigneds();
    }

    getTableProperties = async () => {
        this.toDoListItems = JSON.parse(localStorage.getItem('toDoListItems'));
        if (this.toDoListItems != null && this.toDoListItems != undefined && this.toDoListItems != "") {
    
            await this.setState({
                bordered: this.toDoListItems.bordered,
                title: this.toDoListItems.titleDrm == true ? title : undefined,
                titleDrm: this.toDoListItems.titleDrm,
                showHeader: this.toDoListItems.showHeader,
                footer: this.toDoListItems.footerDrm == true ? footer : undefined,
                footerDrm: this.toDoListItems.footerDrm,
                expandable: this.toDoListItems.expandableDrm == true ? expandable : {},
                expandableDrm: this.toDoListItems.expandableDrm,
                rowSelection: this.toDoListItems.rowSelection,
                ellipsis: this.toDoListItems.ellipsis,
                tableLayout: this.toDoListItems.tableLayout,
                size: this.toDoListItems.size,
                top: this.toDoListItems.top,
                bottom: this.toDoListItems.bottom,
                editBgColor: this.toDoListItems.editBgColor,
                deleteBgColor: this.toDoListItems.deleteBgColor,
                selectedNodeKey: null,
                yScroll: this.toDoListItems.yScroll,
                xScroll: this.toDoListItems.xScroll,
                paginationDisabled: {
                    defaultPageSize: 5, total: this.state.toDoListItemsList.length,
                    showQuickJumper: true, showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '25', '50'], total: this.state.toDoListItemsList.length, showQuickJumper: true,
                    position: [this.toDoListItems.top, this.toDoListItems.bottom]
                },
                kontrol: true,
            });
        } else {
            await this.setState({
                bordered: false,
                title: undefined,
                titleDrm: false,
                showHeader: true,
                footer: undefined,
                footerDrm: false,
                expandable: false,
                expandableDrm: false,
                rowSelection: undefined,
                ellipsis: false,
                tableLayout: undefined,
                size: 'default',
                top: 'none',
                bottom: 'bottomRight',
                editBgColor: '#fff',
                deleteBgColor: '#fff',
                selectedNodeKey: null,
                xScroll: undefined,
                paginationDisabled: false,
                kontrol: true,
            });
          }
    }

    restartTable = async () => {
        console.log("RRR", this.props);
        var toDoListId=-1;
        if(this.props.row.id!=null && this.props.row.id!="" ){
            toDoListId=this.props.row.id;
        }
        const newObj = {
            toDoListId: toDoListId
        } 
        const response = await handleRequest("POST", "/ToDoListItems/getResult", newObj);
        console.log("RRR", response.data);
        if (response.data.length !== 0) {
            const list =  response.data;
            list.forEach(element => {
                element.key=element.id
            });
            this.setState({
                toDoListItemsList: list, 
            });
        }
        if (response.data.length === 0) {
            this.setState({
                toDoListItemsList: [],
            });
        }
    }
  
    actionTemplate(rowData) { 
        if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
          return <React.Fragment>
            <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.deleteModal.bind(this)}
              onEditRow={this.changeButtonUpdate.bind(this)} />
            </React.Fragment>;
        } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
          return <React.Fragment>
            <CustomMaterialMenuEdit row={rowData}
              onEditRow={this.changeButtonUpdate.bind(this)} />
            </React.Fragment>;
        }
        else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
          return <React.Fragment>
            <CustomMaterialMenuDelete row={rowData}
              onDeleteRow={this.deleteModal.bind(this)} />
            </React.Fragment>;
        }
    };

    changeButtonUpdate = (row) => { 
        var reminderDateTime=null;
        if(row.reminderDateTime!=null && row.reminderDateTime!=undefined && row.reminderDateTime!=""){
            reminderDateTime=row.reminderDateTime;
        }

        var ccId=[];
        if(row.toDoListItemsCCs!=null && row.toDoListItemsCCs!=undefined){
            row.toDoListItemsCCs.forEach(element => {
                ccId.push(element.ccId + "");
            });
        }

        this.setState({
          selectedToDoListItems: row,
          reminderDateTime: reminderDateTime,
          action:row.action,
          assignedId: row.assignedId, 
          messageAssignedId: row.messageAssignedId, 
          messageAssignedAck: (row.codeMessageAssigned!=null && row.codeMessageAssigned!=undefined  ) ? row.codeMessageAssigned.name : "" ,
          messageCCId: row.messageCCId, 
          messageCCAck: (row.codeMessageCC!=null && row.codeMessageCC!=undefined  ) ? row.codeMessageCC.name : "" ,
          ccId:ccId,
          filePath:row.filePath,
          hiddenUpdate: false,
          hiddenDataGiris: false,
          createNewButton: "Cancel",
          createNewButtonVariant: "danger",
        });
    }

    changeButtonCancel = () => {
        this.setState({
          hiddenUpdate: true,
          hiddenDataGiris:true,
          createNewButton: "Create New",
          createNewButtonVariant: "info", 
        });
    }

    deleteModal = (row) => {
        this.setState({ 
          deleteModal: true,
          selectedToDoListItems: row
        });
    }
      
    showOrHideModalDelete = () => {
        this.setState({
          deleteModal: !this.state.deleteModal
        });
        setTimeout(() => {
          document.body.style.overflow = "auto";
          document.body.style.paddingRight = "0px";
      }, 500);
    }

    updateToDoList = async () => {
        if(this.state.action!=null && this.state.action!=undefined && this.state.action!="" &&
        this.state.assignedId!=null && this.state.assignedId!=undefined && this.state.assignedId!="" &&
        this.state.messageAssignedId!=null && this.state.messageAssignedId!=undefined && this.state.messageAssignedId!="" &&
        this.state.ccId!=null && this.state.ccId!=undefined && this.state.ccId!="" &&
        this.state.messageCCId!=null && this.state.messageCCId!=undefined && this.state.messageCCId!="" &&
        this.state.reminderDateTime!=null && this.state.reminderDateTime!=undefined && this.state.reminderDateTime!=""){
            const updatedToDoListItems = {
                id: this.state.selectedToDoListItems.id,
                toDoListId: this.props.row.id,
                action: this.state.action,
                assignedId: this.state.assignedId,
                messageAssignedId: this.state.messageAssignedId,
                ccIds: this.state.ccId,
                messageCCId: this.state.messageCCId,
                reminder: this.state.reminder,
                reminderType: this.state.reminderType,
                reminderDateTime: this.state.reminderDateTime,
                filePath: this.state.filePath,
            }
            var response2 = await handleRequest("POST", "/ToDoListItems/update", updatedToDoListItems);
            if (response2.data !== null && response2.data !== undefined) {
                if (response2.data === "SUCCESS") {
                    this.restartTable();
                    this.showSuccess();
                    this.setState({
                        hiddenDataGiris:true,
                    });
                }
                else{
                    this.showError();
                }
            }
        }   
        else{
            this.showWarning();
        }
    }
    
    deleteToDoListOK = async () => {
    const deleteToDoList = {
        id: this.state.selectedToDoListItems.id
    }
    var response = await handleRequest("POST", "/ToDoListItems/delete", deleteToDoList);
    if (response.data !== null && response.data !== undefined) {
        if (response.data === "SUCCESS") {
            this.restartTable();
            this.showSuccess();
            this.showOrHideModalDelete();
        }
        else {
            this.showError();
        }
    }
    setTimeout(() => {
        document.body.style.overflow = "auto";
        document.body.style.paddingRight = "0px";
    }, 500);
    }

    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    }; 

    getDynamicMenu = async () => {
        try {
            console.log("TTT", this.props.location);
            const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
            if (responseTree.data.length !== 0) {
                var element = responseTree.data.treeTable;
    
                for (let index = 0; index < element.length; index++) {
                    var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());
    
                    if (result !== null)
                        break;
                }
    
                this.setState({
                    formId: result.data.id
                });
    
                this.getUserSettings();
            } else {
                this.setState({
                    formId: null
                });
            }
        } catch (error) {
            console.log("EEE", error);
        }
    }

    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }

    getUserSettings = async () => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + this.state.formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({

                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        }); 
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHideModalTable = async () => {
        this.setState({
            modalTable: !this.state.modalTable,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    showOrHidePhotoModal = () => {
        this.setState({
          modalPhotoShow: !this.state.modalPhotoShow,
        });
        setTimeout(() => {
          document.body.style.overflow = "auto";
      }, 500);
    };

    showOrHideModalPdfViewer = () => {
        this.setState({
          modalPdfViewer: !this.state.modalPdfViewer,
        });
        setTimeout(() => {
          document.body.style.overflow = "auto";
      }, 500);
    };
    
    showOrHideReleaseModal = () => {
        this.setState({
            modalRelease: !this.state.modalRelease,
        });
        setTimeout(() => {
          document.body.style.overflow = "auto";
      }, 500);
    };

    showReport = async (fileRealUrl) => {
        try { 
          if (fileRealUrl.endsWith('.pdf')) {
            this.setState({
              pdfFile: fileRealUrl,
              modalPdfViewer: true,
            });
          } else if (fileRealUrl.endsWith('.png') || fileRealUrl.endsWith('.jpg') || fileRealUrl.endsWith('.tiff') || fileRealUrl.endsWith('.jpeg')
            || fileRealUrl.endsWith('.PNG') || fileRealUrl.endsWith('.JPG') || fileRealUrl.endsWith('.TIFF') || fileRealUrl.endsWith('.JPEG')) {
            this.setState({
              imageUrl: fileRealUrl,
              modalPhotoShow: true,
            })
          } else {
            window.location.href = fileRealUrl;
          }
        } catch (error) { 
            this.showError(<FormattedMessage id="GeneralMessageFileCantFound" defaultMessage="File cant found."/>);
        }
    };

    disabledDate(current) {
        return moment().add(-1, 'days').endOf('day') > current ;
    }

    getAssigneds = async () => {  
        const response = await handleRequest("GET", "/User/getCompanyItems"); 
        if (response.data.length !== 0) {
          const columns = response.data;
          const options = columns.map(function (user) {
            if(user.staff!=null && user.staff!=undefined){
              return { 
                label: user.staff.name.toString() + " " + user.staff.lastName.toString() , value: user.id.toString() 
              }           
            }
          }) 
          this.setState({
            assigneds: response.data,
            CCs: options,
          });
        }else{
          this.setState({
            assigneds: [],
            CCs:[]
          });
        }
    } 

    
handleChangeToDoListMessageAssigned = (e) => {
    var deger = "";
    for (var z = 0; z < this.state.ToDoListMessageAssigneds.length; z++) {
      if (this.state.ToDoListMessageAssigneds[z].id == e) {
        deger = this.state.ToDoListMessageAssigneds[z].name;
      }
    }
    this.setState({
        messageAssignedAck: deger,
        messageAssignedId: e,
    });
  };
  
  addToDoListMessageAssigneds = async () => {
    const { itemToDoListMessageAssigneds } = this.state;
    if (itemToDoListMessageAssigneds != null && itemToDoListMessageAssigneds != "" && itemToDoListMessageAssigneds != undefined) {
        const newItem = {
        name: itemToDoListMessageAssigneds,
        type: "ToDoListMessageAssigned"
        }
  
        await handleRequest("POST", "/Code/add", newItem);
        this.setState({
        itemToDoListMessageAssigneds: '',
        });
        this.getCodesToDoListMessageAssigned();
    } else {
        this.showWarning();
    }
  };
  
  getCodesToDoListMessageAssigned = async () => {
    const response = await handleRequest("GET", "/Code/type/ToDoListMessageAssigned");   
  
    if (response.data !== null && response.data !== undefined) {
      this.setState({
        ToDoListMessageAssigneds: response.data
      });
    }
  } 
  
  handleChangeToDoListMessageCC = (e) => {
    var deger = "";
    for (var z = 0; z < this.state.ToDoListMessageCCs.length; z++) {
      if (this.state.ToDoListMessageCCs[z].id == e) {
        deger = this.state.ToDoListMessageCCs[z].name;
      }
    }
    this.setState({
        messageCCAck: deger,
        messageCCId: e,
    });
  };
  
  addToDoListMessageCCs = async () => {
    const { itemToDoListMessageCCs } = this.state;
    if (itemToDoListMessageCCs != null && itemToDoListMessageCCs != "" && itemToDoListMessageCCs != undefined) {
        const newItem = {
        name: itemToDoListMessageCCs,
        type: "ToDoListMessageCC"
        }
  
        await handleRequest("POST", "/Code/add", newItem);
        this.setState({
        itemToDoListMessageCCs: '',
        });
        this.getCodesToDoListMessageCC();
    } else {
        this.showWarning();
    }
  };
  
    getCodesToDoListMessageCC = async () => {
        const response = await handleRequest("GET", "/Code/type/ToDoListMessageCC");   
    
        if (response.data !== null && response.data !== undefined) {
        this.setState({
            ToDoListMessageCCs: response.data
        });
        }
    } 
        
    onCCChange(event) {
        console.log("HHH", event);
        this.setState({ 
            ccId: event.value 
        });
    }

    onAssignedToChange = (value) => {
        this.setState({
            assignedId: value, 
        });
    }
        
    onMessageCCChange = (value) => {
        this.setState({
            ccId: value, 
        });
    } 

    onChangeReminderDateTime = (value, dateString) => {
        this.setState({
          reminderDateTime: dateString,
        });
    }
  

    render() {
        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
          };
        
          var itemActionAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "Action");
          var itemAssignedToAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "AssignedTo");
          var itemMessageAssignedToAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "MessageAssignedTo");
          var itemCCAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "CC");
          var itemCCMessageAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "CCMessage");
          var itemAddFileAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "AddFile");
          var itemReminderDateTimeAttributes = this.state.dynamicAttributes.find(p => p.dynamicInputs.inputKey === "ReminderDateTime");
        
          var itemActionRequired = this.state.dynamicInputs.find(p => p.inputKey === "Action");
          var itemAssignedToRequired = this.state.dynamicInputs.find(p => p.inputKey === "AssignedTo");
          var itemMessageAssignedToRequired = this.state.dynamicInputs.find(p => p.inputKey === "MessageAssignedTo");
          var itemCCRequired = this.state.dynamicInputs.find(p => p.inputKey === "CC");
          var itemCCMessageRequired = this.state.dynamicInputs.find(p => p.inputKey === "CCMessage");
          var itemAddFileRequired = this.state.dynamicInputs.find(p => p.inputKey === "AddFile");
          var itemReminderDateTimeRequired = this.state.dynamicInputs.find(p => p.inputKey === "ReminderDateTime");
        

        const columns = [
            {
                key: "key",
                title: "",
                render: this.actionTemplate
            },  
            {
              dataIndex: "index",
              key: "index",
              title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>,
            },
            {
              dataIndex: "action",
              key: "action",
              title: <FormattedMessage id="ToDoListItemsAction" defaultMessage="Action" />,
            },
            {
                dataIndex: "userAssigned",
                key: "key",
                title: <FormattedMessage id="ToDoAssignedTo" defaultMessage="Assigned to" />,
                render: (data) =>   
                {  
                  if(data!=null && data!=undefined){
                      return data.staff.name + " " + data.staff.lastName;
                  } 
                  return "";
                }
            },
            {
                dataIndex: "codeMessageAssigned",
                key: "key",
                title: <FormattedMessage id="ToDoMessageAssignedTo" defaultMessage="Message" />,
                render: (data) =>   
                {  
                  if(data!=null && data!=undefined){
                      return data.name;
                  } 
                  return "";
                }
            },
            {
                dataIndex: "ccUsers",
                key: "key",
                title: <FormattedMessage id="ToDoCC" defaultMessage="CC" />,
            },
            {
                dataIndex: "codeMessageCC",
                key: "key",
                title: <FormattedMessage id="ToDoCCMessage" defaultMessage="Message" />,
                render: (data) =>   
                {  
                  if(data!=null && data!=undefined){
                      return data.name;
                  } 
                  return "";
                }
            },
            {
                dataIndex: "reminderDateTime",
                key: "key",
                title: <FormattedMessage id="ToDoReminderDateTime" defaultMessage="Reminder Date Time" />,
            },
            {
                title: <FormattedMessage id="ToDoFile" defaultMessage="File" />,
                render: (row) =>  
                {  
                  if (row.filePath !== "" && row.filePath !== null && row.filePath !== undefined) {
                    return (
                    <React.Fragment>
                        <AntdButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={(e) => this.showReport(row.filePath)} type="primary" shape="round" icon={<DownloadOutlined />} >
                        </AntdButton>
                    </React.Fragment>
                    );
                  } else{
                   return row.documentCode
                  } 
                }
            }
        ];
        
        const tableColumns = columns.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
        if (this.state.xScroll === 'fixed') {
          tableColumns[0].fixed = true;
          tableColumns[tableColumns.length - 1].fixed = 'right';
        }

        const layoutView = (
            isSidebarOpened,
            container,
            main,
            toolbar,
            sidebar,
          ) => {
            return defaultLayout(
              isSidebarOpened,
              container,
              main,
              toolbar(renderToolbar),
              sidebar,
            );
        };

        const renderToolbar = (toolbarSlot) => {
            return (
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexGrow: 1,
                    flexShrink: 1,
                    justifyContent: 'center',
                  }}
                >
                  <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.previousPageButton}
                  </div>
                  <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
                  </div>
                  <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.nextPageButton}
                  </div>
                  <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.zoomOutButton}
                  </div>
                  <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.zoomPopover}
                  </div>
                  <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.zoomInButton}
                  </div>
                  <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.downloadButton}
                  </div>
                  <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.moreActionsPopover}
                  </div>
                </div>
              </div>
            );
        };

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 8 },
        };

        return <div>
                <MenuProvider id="menu_id">
                    <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable()} icon="pi pi-cog"></PrimeButton>

                    <div hidden={this.state.hiddenDataGiris}>
                    <br/>
                        {(itemActionAttributes.visible) &&  
                        <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                            <FormBoot.Label style={{ color: 'black' }} column sm="2"><FormattedMessage id="ToDoAction" defaultMessage="Action" /></FormBoot.Label>
                            <Col sm="4">
                                <Input id="ToDoAction" value={this.state.action} onChange={(e) =>
                                    this.setState({ action: e.target.value })
                                } />
                            </Col> 
                            <Col sm="6">
                            </Col>
                        </FormBoot.Group>
                        }  

                        {(itemAssignedToAttributes.visible) &&
                        <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                            <FormBoot.Label style={{ color: 'black' }} column sm="2"><FormattedMessage id="ToDoAssignedTo" defaultMessage="Assigned to" /></FormBoot.Label>
                            <Col sm="4">
                                <Select size='large'
                                    style={{ width: '100%' }} 
                                    value={this.state.assignedId}
                                    onChange={this.onAssignedToChange}
                                    >
                                    {this.state.assigneds.map(user => ( 
                                    (user.staff!=null && user.staff!=undefined) && 
                                    <Option key={user.id} value={user.id}>{
                                    user.staff.name + " " + 
                                    user.staff.lastName
                                    }</Option>
                                    ))}
                                </Select>
                            </Col> 
                            <Col sm="6">
                            </Col>                                              
                        </FormBoot.Group>
                        }                                                               

                        {(itemMessageAssignedToAttributes.visible) &&
                        <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                            <FormBoot.Label style={{ color: 'black' }} column sm="2"><FormattedMessage id="ToDoMessageAssignedTo" defaultMessage="Message" /></FormBoot.Label>
                            <Col sm="4">
                            <Select
                                name="messageAssignedId"
                                style={{ width: '100%' }}
                                value={this.state.messageAssignedAck}
                                onChange={this.handleChangeToDoListMessageAssigned}

                                dropdownRender={menu => (
                                <div>
                                    {menu}
                                    <Divider style={{ margin: '4px 0' }} />
                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                    <Input style={{ flex: 'auto' }} value={this.state.itemToDoListMessageAssigneds}
                                        onChange={(event) => { this.setState({ itemToDoListMessageAssigneds: event.target.value }) }} />
                                    <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                        onClick={this.addToDoListMessageAssigneds} >
                                        <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add Item"/>  </a>
                                    </div>
                                </div>
                                )}>
                                {this.state.ToDoListMessageAssigneds.map(i => (
                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                ))}
                            </Select>
                            </Col> 
                            <Col sm="6">
                            </Col>
                        </FormBoot.Group>
                        }    
                        
                        {(itemCCAttributes.visible) &&
                        <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                            <FormBoot.Label style={{ color: 'black' }} column sm="2"><FormattedMessage id="ToDoCC" defaultMessage="CC" /></FormBoot.Label>
                            <Col sm="4">
                            <MultiSelect id="ToDoCC" className="p-column-filter" value={this.state.ccId}
                            options={this.state.CCs} onChange={this.onCCChange} />
                            </Col> 
                            <Col sm="6">
                            </Col>
                        </FormBoot.Group>
                        }   

                        {(itemCCMessageAttributes.visible) &&
                        <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                            <FormBoot.Label style={{ color: 'black' }} column sm="2"><FormattedMessage id="ToDoCCMessage" defaultMessage="Message" /></FormBoot.Label>
                            <Col sm="4">
                            <Select
                                name="messageCCId"
                                style={{ width: '100%' }}
                                value={this.state.messageCCAck}
                                onChange={this.handleChangeToDoListMessageCC}

                                dropdownRender={menu => (
                                <div>
                                    {menu}
                                    <Divider style={{ margin: '4px 0' }} />
                                    <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                    <Input style={{ flex: 'auto' }} value={this.state.itemToDoListMessageCCs}
                                        onChange={(event) => { this.setState({ itemToDoListMessageCCs: event.target.value }) }} />
                                    <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                        onClick={this.addToDoListMessageCCs} >
                                        <PlusOutlined /> <FormattedMessage id="GeneralButtonAddItem" defaultMessage="Add Item"/>  </a>
                                    </div>
                                </div>
                                )}>
                                {this.state.ToDoListMessageCCs.map(i => (
                                    <Option key={i.id} value={i.id}>{i.name}</Option>
                                ))}
                                </Select>
                            </Col> 
                            <Col sm="6">
                            </Col>
                        </FormBoot.Group>
                        }    
        
                        {(itemReminderDateTimeAttributes.visible) &&
                        <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                            <FormBoot.Label style={{ color: 'black' }} column sm="2"><FormattedMessage id="ToDoReminderDateTime" defaultMessage="Reminder Date Time" /></FormBoot.Label>
                            <Col sm="4">
                                <DatePicker disabledDate={this.disabledDate} disabled={true} value={this.state.reminderDateTime!="" ? moment(this.state.reminderDateTime, 'DD/MM/YYYY HH:mm') : ""}
                                showTime={{ format: 'HH:mm' }} format="DD/MM/YYYY HH:mm" onChange={this.onChangeReminderDateTime} />
                            </Col> 
                            <Col sm="6">
                            </Col>
                        </FormBoot.Group>
                        }      
                        
                        {(itemAddFileAttributes.visible) && 
                        <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                            <FormBoot.Label style={{ color: 'black' }} column sm="2"><FormattedMessage id="ToDoAddFile" defaultMessage="Add File"/></FormBoot.Label>
                            <Col sm="4">
                            <Upload {...props}
                                showUploadList={true}
                                fileList={this.state.FileUpload}
                                onChange={({ fileList }) => {
                                    if (fileList.length == 1) {  
                                    var filePath="";
                                    if(Boolean(fileList[0].response)){
                                        filePath=fileList[0].response.url;
                                    }
                                        this.setState({
                                        FileUpload:fileList,
                                        filePath:filePath,
                                        });
                                    }
                                }}
                                beforeUpload={(fileList) => {
                                if (this.state.FileUpload.length > 0) {
                                    this.showWarning(<FormattedMessage id="GeneralUploadMessageWarning1" defaultMessage="You have been already choose a icon! For change firstly remove the uploaded icon."/>);
                                    return false;
                                }
                                return true;
                                }}
                                onRemove={({ fileList }) => {
                                this.setState( {
                                    FileUpload:[], 
                                    filePath:"",
                                })}}>
                                <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                                    <UploadOutlined /> <FormattedMessage id="ToDoAddFile" defaultMessage="Add File"/>
                                </AntdButton>
                            </Upload>
                            </Col> 
                            <Col sm="6">
                            </Col>
                        </FormBoot.Group>
                        } 

                        <div hidden={this.state.hiddenUpdate}>
                        {this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 &&
                            <FormBoot.Group style={{ marginBottom: '1rem' }} as={Row}>
                                <Col sm="2"></Col>
                                <Col sm="2">
                                    <Button id="ToDoListCancelButton" onClick={this.changeButtonCancel} style={{ width: '100%' }} variant="danger"><FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></Button>
                                </Col>
                                <Col sm="2">
                                    <Button id="ToDoListUpdateButton" onClick={this.updateToDoList} style={{ width: '100%' }} variant="warning"><FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></Button>
                                </Col>
                                <Col sm="2">
                                </Col>
                            </FormBoot.Group>
                        }
                        </div>
                
                    </div> 
                    <br />
                    <br />
                    
                    {this.state.toDoListItemsList.length > 0 && 
                        <Mnd.MNDTable
                        state={this.state}
                        columns={tableColumns} dataSource={this.state.toDoListItemsList}
                        pagination={this.state.paginationDisabled}
                        expandable={this.state.expandable}
                        footer={this.state.footer}
                        title={this.state.title}
                        scroll={this.state.xScroll == "scroll" && { x: 1300, scrollToFirstRowOnChange: true }}/> 
                    }
               </MenuProvider>
                {!this.state.whatIsSetting && <Whatis  ></Whatis>
                }

                <Modal
                    show={this.state.modalMessage}
                    onHide={this.showOrHideModalMessage}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    >

                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                        <Row > 
                        <Col sm="12">
                            {this.state.messageTip=="Success" &&  
                            <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} /> }
                        
                            {this.state.messageTip=="Warning" &&  
                            <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                            {this.state.messageTip=="Error" &&  
                            <i className="fas fa-times fa-2x" style={{ color: '#ff0400'}} />}
                        </Col>
                        </Row>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                    <Row > 
                        <Col sm="12">
                        {this.state.message} 
                        </Col>
                        </Row>
                    </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
                </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.modalTable}
                    onHide={this.showOrHideModalTable}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered size="lg">

                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <Row >
                                <Col sm="12">
                                    <FormattedMessage id="GeneralTableSettings" defaultMessage="Table Settings" />
                                </Col>
                            </Row>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Row >
                            <Col sm="12">
                                <MNDTableSettings id="toDoListItems" />
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>

                <Modal
                    size="md"
                    show={this.state.modalPhotoShow}
                    onHide={this.showOrHidePhotoModal}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Photo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {this.state.imageUrl !== "" ? (
                        <img
                        src={this.state.imageUrl}
                        alt="avatar"
                        style={{ width: "100%" }}
                        />
                    ) : (
                        <p>Photo has not been uploaded.</p>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={this.showOrHidePhotoModal}>
                        <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                    </Button>
                    </Modal.Footer>
                </Modal>

                <Modal
                    size="xl"
                    show={this.state.modalPdfViewer}
                    onHide={this.showOrHideModalPdfViewer}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        PDF Report Viewer
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
                        <div style={{ height: "900px" }}>
                        <Viewer fileUrl={this.state.pdfFile} layout={layoutView} />
                        </div>
                    </Worker> 

                    </Modal.Body>
                    <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={this.showOrHideModalPdfViewer}
                    >
                          <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                        </Button>
                    </Modal.Footer>
                </Modal>
 
                <Modal
                    show={this.state.deleteModal}
                    onHide={this.showOrHideModalDelete}
                    aria-labelledby="contained-modal-title-vcenter"
                    backdrop="static"
                    centered >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p style={{ textAlign: "center", color: "black" }}>
                            <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
                        </p>
                        <p style={{ textAlign: "center", color: "black" }}>
                            <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.showOrHideModalDelete}>
                            <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                        </Button>
                        <Button variant="danger" onClick={this.deleteToDoListOK}>
                            <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                        </Button>
                    </Modal.Footer>
                    </Modal>

            </div>
        
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ToDoListItems)