/* eslint-disable */

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Button, Form, Col, Modal, Row } from 'react-bootstrap';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Dropdown } from 'primereact/dropdown';
import { handleRequest } from '../../redux/config/env';
import { EmailShareButton, EmailIcon, WhatsappShareButton, WhatsappIcon, LinkedinShareButton, LinkedinIcon, FacebookShareButton, FacebookIcon } from 'react-share'

export default class ChecklistReport extends Component {

  constructor(props) {
    super(props)
    this.state = {
      modalShow: true,
      modalShow2: false,
      hidden: true,
      uyari: true,



      disciplinesOfDepartments: [],
      itpCode: [],
      itpName: [],
      iptCodeVal: null,
      iptNameVal: null,
      disciplineVal: null,
      itpID: 0,
      checkListID: 0,
      checkListDesc: [],
      checkListCode: [],
      checkListDescVal: null,
      checkListCodeVal: null,

      hangiPopup: 0,
      modalName: "",
      modalName2: "",
      itpDATA: [],
      checklistDATA: [],


    };

    this.onChangedisciplineVal = this.onChangedisciplineVal.bind(this);
    this.onChangeItpCode = this.onChangeItpCode.bind(this);
    this.onChangeItpName = this.onChangeItpName.bind(this);
    this.onChangecheckListCode = this.onChangecheckListCode.bind(this);
    this.onChangecheckListDesc = this.onChangecheckListDesc.bind(this);

  }

  componentDidMount = async () => {
    // this.getITP(1);
    this.getDsicipline();
    // this.getCheckListLog();
    console.log(this.props.match.params)

  }


  getITP = async (p) => {
    console.log(p)
    var param = { disciplineid: p }
    const response = await handleRequest("POST", "/Itp/getAll2", param);
    console.log(response)
    if (response.data.length !== 0) {


      var dt = response.data;

      var rows = [];
      var rows2 = [];
      for (var i = 0; i < dt.length; i++) {

        rows.push({ label: dt[i].itpcode, value: dt[i].id });
        rows2.push({ label: dt[i].itpname, value: dt[i].id });
      }

      this.setState({ itpCode: rows, itpName: rows2, itpDATA: dt });


    } else { this.setState({ itpAll: [] }); }
  }

  getCheckListLog = async (p) => {

    var param = { itpid: p }
    const response = await handleRequest("POST", "/CheckListLog/getAll2", param);
    console.log('datatableValuexxx', response)
    if (response.data.length !== 0) {
      var dt = response.data;

      var rows = [];
      var rows2 = [];
      for (var i = 0; i < dt.length; i++) {

        rows.push({ label: dt[i].iclistno, value: dt[i].id });
        rows2.push({ label: dt[i].iclist, value: dt[i].id });
      }

      this.setState({ checkListCode: rows, checkListDesc: rows2, checklistDATA: dt });


    } else { this.setState({ checkListCode: [], checkListDesc: [] }); }
  }

  getDsicipline = async () => {

    const response = await handleRequest("GET", "/Discipline/getAll");
    console.log(response)
    if (response.data.length !== 0) {
      var rows = [];
      for (var i = 0; i < response.data.length; i++) {

        rows.push({ label: response.data[i].disciplineName, value: response.data[i].id });
      }

      this.setState({ disciplinesOfDepartments: rows });
    } else { this.setState({ disciplinesOfDepartments: [] }); }
  }




  onChangedisciplineVal = e => {

    console.log(e.target.value);

    this.setState({ disciplineVal: e.target.value });

    if (e.target.value != null)
      this.getITP(e.target.value);


  }

  onChangeItpCode = e => {

    console.log(e.target.value);
    this.setState({ iptCodeVal: e.target.value, iptNameVal: e.target.value, itpID: e.target.value });
    if (e.target.value != null)
      this.getCheckListLog(e.target.value)


  }

  onChangeItpName = e => {
    console.log(e.target.value);
    this.setState({ iptCodeVal: e.target.value, iptNameVal: e.target.value, itpID: e.target.value });
    if (e.target.value != null)
      this.getCheckListLog(e.target.value)

  }

  onChangecheckListCode = e => {
    console.log(e.target.value);
    this.setState({ checkListDescVal: e.target.value, checkListCodeVal: e.target.value, checkListID: e.target.value });
  }

  onChangecheckListDesc = e => {
    console.log(e.target.value);
    this.setState({ checkListDescVal: e.target.value, checkListCodeVal: e.target.value, checkListID: e.target.value });

  }

  checkListClick = () => {

    this.setState({
      modalShow: !this.state.modalShow, modalShow2: !this.state.modalShow2, hangiPopup: 1, hidden: false,
      modalName: "Checklist Selection", iptCodeVal: null, iptNameVal: null, disciplineVal: null, checkListCodeVal: null, checkListDescVal: null
    })


  };

  passClick = () => {
    window.location.href = "/InspectionResult";
  };

  itpClick = () => {

    this.setState({
      modalShow: !this.state.modalShow, modalShow2: !this.state.modalShow2, hangiPopup: 2, hidden: true,
      modalName: "Inspection and Test Plan Selection", iptCodeVal: null, iptNameVal: null, disciplineVal: null, checkListCodeVal: null, checkListDescVal: null
    })

  };

  gooo = () => {

    const { hangiPopup } = this.state;
    const { checkListCodeVal } = this.state;
    const { iptCodeVal } = this.state;
    const { checklistDATA } = this.state;
    const { itpDATA } = this.state;

    if (hangiPopup == 1 && checkListCodeVal > 0) {
      var dt = checklistDATA.filter(x => x.id == this.state.checkListCodeVal);
      this.setState({
        modalShow3: !this.state.modalShow3, modalShow2: !this.state.modalShow2,
        modalName2: dt[0].iclistno + " - " + dt[0].iclist
      })
    }
    else if (hangiPopup == 2 && iptCodeVal > 0) {
      var dt = itpDATA.filter(x => x.id == this.state.iptCodeVal);
      this.setState({
        modalShow3: !this.state.modalShow3, modalShow2: !this.state.modalShow2,
        modalName2: dt[0].itpcode + " - " + dt[0].itpname
      })
    }
    else { this.setState({ uyari: false }); setTimeout(() => { this.setState({ uyari: true }); }, 2000); }

  };

  showReport = async () => {


    const response = await handleRequest("GETBLOB", "/inspectionRequest/getReport/1", { method: 'GET', responseType: 'blob' });
    try {
      const file = new Blob([response.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (error) {

    }


  };

  shareClick = () => {

    this.setState({ modalShow4: !this.state.modalShow4, modalShow3: !this.state.modalShow3 });

  };

  render() {
    return (
      <div>
        <div>
          <Button variant="info" onClick={() => this.setState({ modalShow: !this.state.modalShow })} >Open Pop-up</Button>
        </div>

        <Modal show={this.state.modalShow}
          onHide={() => this.setState({ modalShow: !this.state.modalShow })}
          aria-labelledby="contained-modal-title-vcenter"
          centered  >
          <Modal.Header closeButton style={{ padding: "0px" }}>
            <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group style={{ width: "100%" }}   >
              <Col sm="12">
                <Button variant="success" onClick={this.passClick} style={{ width: "100%" }}>My Inspections</Button>
              </Col>
            </Form.Group>
            <Form.Group style={{ width: "100%" }}    >
              <Col sm="12">
                <Button variant="warning" onClick={this.checkListClick} style={{ width: "100%" }}>Checklists</Button>
              </Col>
            </Form.Group>
            <Form.Group style={{ width: "100%" }}    >
              <Col sm="12">
                <Button variant="info" onClick={this.itpClick} style={{ width: "100%" }}> Inspection and Test Plans</Button>
              </Col>
            </Form.Group>
          </Modal.Body>
          {/* <Modal.Footer>
              <Button variant="secondary" onClick={() => this.setState({ modalShow: !this.state.modalShow  })} >Close</Button> &nbsp;&nbsp;&nbsp;
             <Button variant="success" onClick={this.modal1Accept}  >Select</Button> 
            </Modal.Footer> */}
        </Modal>

        <Modal show={this.state.modalShow2}
          onHide={() => this.setState({ modalShow2: !this.state.modalShow2, modalShow: !this.state.modalShow })}
          aria-labelledby="contained-modal-title-vcenter"
          centered  >
          <Modal.Header closeButton  >
            <Modal.Title id="contained-modal-title-vcenter">{this.state.modalName} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div hidden={this.state.uyari} style={{ backgroundColor: "#F4B7A8" }} className="col-md-12">  Please Select All items</div>
            <Form.Group style={{ marginBottom: '1rem' }}    >
              <Col sm="12">
                <div className="col-md-12">
                  <label className=" control-label">Discipline</label>
                  <div >
                    <Dropdown
                      value={this.state.disciplineVal} options={this.state.disciplinesOfDepartments}
                      onChange={this.onChangedisciplineVal} style={{ width: '100%' }}
                      filter={true} filterPlaceholder="Select ..." filterBy="label,value" showClear={true} />
                  </div>
                </div>
              </Col>
            </Form.Group>

            <Form.Group style={{ marginBottom: '1rem' }}    >
              <Col sm="12">
                <div className="col-md-12">
                  <label className=" control-label">Itp Code   </label>
                  <div >
                    <Dropdown
                      value={this.state.iptCodeVal} options={this.state.itpCode}
                      onChange={this.onChangeItpCode} style={{ width: '100%' }}
                      filter={true} filterPlaceholder="Select ..." filterBy="label,value" showClear={true} />
                  </div>
                </div>
              </Col>
            </Form.Group>

            <Form.Group style={{ marginBottom: '1rem' }}    >
              <Col sm="12">
                <div className="col-md-12">
                  <label className=" control-label">Itp Name   </label>
                  <div >
                    <Dropdown
                      value={this.state.iptNameVal} options={this.state.itpName}
                      onChange={this.onChangeItpName} style={{ width: '100%' }}
                      filter={true} filterPlaceholder="Select ..." filterBy="label,value" showClear={true} />
                  </div>
                </div>
              </Col>
            </Form.Group>

            <Form.Group style={{ marginBottom: '1rem' }} hidden={this.state.hidden} >
              <Col sm="12">
                <div className="col-md-12">
                  <label className=" control-label">CheckList Code </label>
                  <div >
                    <Dropdown
                      value={this.state.checkListCodeVal} options={this.state.checkListCode}
                      onChange={this.onChangecheckListCode} style={{ width: '100%' }}
                      filter={true} filterPlaceholder="Select ..." filterBy="label,value" showClear={true} />
                  </div>
                </div>
              </Col>
            </Form.Group>

            <Form.Group style={{ marginBottom: '1rem' }} hidden={this.state.hidden}  >
              <Col sm="12">
                <div className="col-md-12">
                  <label className=" control-label">CheckList Description </label>
                  <div >
                    <Dropdown
                      value={this.state.checkListDescVal} options={this.state.checkListDesc}
                      onChange={this.onChangecheckListDesc} style={{ width: '100%' }}
                      filter={true} filterPlaceholder="Select ..." filterBy="label,value" showClear={true} />
                  </div>
                </div>
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer style={{ padding: "0px" }}>
            <img onClick={this.gooo} src="/media/dar.png" alt="" height="50px"></img>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.modalShow3}
          onHide={() => this.setState({ modalShow3: !this.state.modalShow3, modalShow: !this.state.modalShow })}
          aria-labelledby="contained-modal-title-vcenter"
          centered  >
          <Modal.Header closeButton  >
            <Modal.Title id="contained-modal-title-vcenter">{this.state.modalName2} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group style={{ width: "100%" }}   >
              <Col md={{ span: 8, offset: 2 }}>
                <Button variant="outline-warning" onClick={this.showReport} style={{ width: "100%" }}>
                  <span className="fa fa-neuter" style={{ width: "50px", fontSize: "20px" }}></span>
                  Preview</Button>
              </Col>
            </Form.Group>
            <Form.Group style={{ width: "100%" }}    >
              <Col md={{ span: 8, offset: 2 }}>
                <Button variant="outline-dark" onClick={this.showReport} style={{ width: "100%" }}>
                  <span className="fa fa-print" style={{ width: "50px", fontSize: "20px" }}></span>Print</Button>
              </Col>
            </Form.Group>
            <Form.Group style={{ width: "100%" }}    >
              <Col md={{ span: 8, offset: 2 }}>
                <Button variant="outline-success" onClick={this.shareClick} style={{ width: "100%" }}>
                  <span className="fa fa-share-alt" style={{ width: "50px", fontSize: "20px" }}></span>Share</Button>
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer style={{ padding: "0px" }}>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.modalShow4}
          onHide={() => this.setState({ modalShow4: !this.state.modalShow4, modalShow3: !this.state.modalShow3 })}
          aria-labelledby="contained-modal-title-vcenter"
          centered  >
          <Modal.Body>
            <Form.Group style={{ width: "100%" }}    >
              <Col md={{ span: 6, offset: 3 }}>
                <EmailShareButton
                  url="http://81.169.196.60:3000"
                  subject="Inspection"
                  body={"hey there, pls share my link" + <a href="http://81.169.196.60:3000">Link</a>} className="Demo__some-network__share-button">
                  <EmailIcon size={40} round />
                </EmailShareButton>

                <WhatsappShareButton url="http://81.169.196.60:3000" className="button is-outlined is-rounded whatsapp" title="Inspection">
                  <WhatsappIcon size={40} round />
                </WhatsappShareButton>

                <LinkedinShareButton url="http://81.169.196.60:3000" className="button is-outlined is-rounded linkedin" title="Inspection">
                  <LinkedinIcon size={40} round />
                </LinkedinShareButton>

                <FacebookShareButton url="http://81.169.196.60:3000" className="button is-outlined is-rounded facebook">
                  <FacebookIcon size={40} round />
                </FacebookShareButton>
              </Col>
            </Form.Group>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
