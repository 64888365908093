import { Checkbox, DatePicker, Divider, Form as FormAnt, Select, Table, Input, InputNumber, Tag } from 'antd';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/nova-accent/theme.css';
import React, { Component, useContext, useState, useEffect, useRef } from 'react';
import { Button as ReactButton, Form, Col, Modal, Row } from 'react-bootstrap';
import { Item, MenuProvider } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import { FormattedMessage } from "react-intl";
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { handleRequest } from '../../redux/config/env';
import { getDynamicMenu, getUserSettings, setStandardization } from '../../redux/config/imu.js';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';
import { CheckOutlined } from '@ant-design/icons';
import './AntdEditableTable.css';

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
    const [form] = FormAnt.useForm();
    return (
        <FormAnt form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </FormAnt>
    );
};

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing && record[dataIndex + "Checked"]) {
            inputRef.current.focus();
        }
    }, [editing && record[dataIndex + "Checked"]]);

    const toggleEdit = () => {
        setEditing(!(editing && record[dataIndex + "Checked"]));
        form.setFieldsValue({
            [dataIndex + "Point"]: record[dataIndex + "Point"],

        });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            record[dataIndex] = Boolean(record[dataIndex + "Description"]) && record[dataIndex + "Description"].length > 0 ? record[dataIndex + "Description"] + '  (' + values[dataIndex + "Point"] + ')' : (record[dataIndex + "Checked"] ? <div>  <CheckOutlined style={{ fontSize: '24px', color: '#08c', marginRight: '1rem' }} ></CheckOutlined>  ({values[dataIndex + "Point"]}) </div> : '');
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;
    if (editable) {
        childNode = editing && record[dataIndex + "Checked"] ? (
            <FormAnt.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex + "Point"}
                rules={[
                    {
                        required: false,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <InputNumber max={record.point} ref={inputRef} onPressEnter={save} onBlur={save} />
            </FormAnt.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

const { RangePicker } = DatePicker;
const { Column } = Table;
const { Option } = Select;

const pageName = "VendorResponse";

export class VendorResponse extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            groupList: [],
            rfqSelectItems: [],
            rfqId: null,
            vendorSelectItems: [],
            vendorLogId: null,
            tableList: [],
            columns: [{
                key: "key",
                title: "Group Criteria",
                dataIndex: "groupName",
                totalPoint: -3,
                render: (value, row, index) => {
            
                    if (Boolean(row.tag))
                        return (
                            <Tag color="volcano" style={{ fontSize: '16pt' }} key={row.tag}>
                                {row.tag.toUpperCase()}
                            </Tag>
                        );
                    else return row.groupName;
                }
            
            },
            {
                key: "key",
                title: "Item Criteria",
                dataIndex: "name",
                totalPoint: -2
            },
            {
                key: "key",
                title: "Point",
                dataIndex: "point",
                totalPoint: -1
            }],
            dynamicAttributes: [],
            authorizations: [],
            dynamicInputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: 0

        };


    }

    formRef = React.createRef();


    componentDidMount = async () => {
        var isAdmin = false;
        let dynamicMenu = await getDynamicMenu(pageName);
        this.getUserSettings(dynamicMenu.formId);
        if (Boolean(this.props.auth) && Boolean(this.props.auth.user) && this.props.auth.user.userType === 2) {
            isAdmin = true;
        }

        let standardization = await setStandardization(pageName);
        this.setState({
            dynamicAttributes: standardization.dynamicAttributes,
            dynamicInputs: standardization.dynamicInputs,
            isAdmin: isAdmin,
            formId: dynamicMenu.formId,
            authorizations: dynamicMenu.authorizations
        })

        this.setComboboxes();

    }

    getUserSettings = async (formId) => {

        let userSettings = await getUserSettings(formId);
        this.setState(
            {
                howToUseSetting: userSettings.howToUseSetting,
                whatIsSetting: userSettings.whatIsSetting
            }
        )
    }

    save = async () => {

        const groupList = this.state.groupList;
        const { rfqId, vendorLogId } = this.state;
        let result = true;
        var list = [];
        if (Boolean(groupList) && groupList.length > 0) {

            groupList.forEach(element => {

                if (Boolean(element.itemList)) {
                    element.itemList.forEach(item => {
                        item.rfqId = rfqId;
                        item.vendorLogId = vendorLogId;
                        list.push(item);
                        if (item.necessary === true && item.checked === false) {
                            result = false;

                        }

                    });

                }


            });

            console.log(list);

            if (result) {
                let response = await handleRequest("POST", "/RFQVendorResponse/saveAll", list);

                if (response.data === "SUCCESS") {
                    this.showSuccess();
                    this.resetInputs();
                    this.restartTable();
                }
                else
                    this.showError("An error was occured");
            }
            else
                this.showError("This vendor cannot quotation");

        }
        else
            this.showError("There is no evaluation criteria for selected RFQ ");

    }

    saveScoring = async () => {


        const { rfqId, vendorSelectItems, tableList } = this.state;

        let result = true;
        var list = [];
        if (Boolean(tableList) && tableList.length > 0) {

            tableList.forEach(item => {


                item.rfqId = rfqId;
                vendorSelectItems.forEach(element => {
                    let obj = { ...item, id: item[element.id + "ID"], description: item[element.id + 'Description'], checked: item[element.id + 'Checked'], point: item[element.id + "Point"], vendorLogId: element.id };
                    list.push(obj);

                });




            });

            console.log(list);

            if (result) {
                let response = await handleRequest("POST", "/RFQVendorResponse/saveAll", list);

                if (response.data === "SUCCESS") {
                    this.showSuccess();
                    this.resetInputs();
                    this.restartTable();
                }
                else
                    this.showError("An error was occured");
            }
            else
                this.showError("This vendor cannot quotation");

        }
        else
            this.showError("There is no evaluation criteria for selected RFQ ");

    }

    updateVendor = async (e) => {


        let obj = { id: e.target.value, selected: e.target.checked };


        let response = await handleRequest("POST", "/RFQVendorLog/update", obj);

        if (response.data === "SUCCESS") {
            this.showSuccess();
            this.resetInputs();
            this.getVendors();
        }
        else
            this.showError("An error was occured");


    }

    addPeriod = async () => {

        const obj = {
            startDate: this.state.startDate,
            endDate: this.state.endDate
        }


        await handleRequest("POST", "/BusinessTripPeriod/add", obj);


        this.showSuccess();

        this.setState({
            startDate: null,
            endDate: null,
            moments: null,
            hidePeriodModal: true
        })

        this.setPeriods();

    }


    setComboboxes = async () => {
        const resDepList = await handleRequest("GET", "/RequestForQuotation/getAllByCompany");
        if (resDepList.data.length > 0) {
            this.setState({
                rfqSelectItems: resDepList.data
            });
        } else {
            this.setState({
                rfqSelectItems: []
            });
        }

    }

    initializeGroupList = async () => {
        let rfqId = this.state.rfqId;
        let vendorLogId = this.state.vendorLogId;
        let groupList = this.state.groupList;

        if (Boolean(rfqId) && Boolean(vendorLogId)) {
            const response = await handleRequest("POST", "/RFQVendorResponse/getByVendorandRfq", { rfqId: Number(rfqId), vendorLogId: Number(vendorLogId) });
            if (Boolean(response.data) && response.data.length > 0) {
                let data = response.data;
                if (Boolean(groupList) && groupList.length > 0) {

                    groupList.forEach(element => {
                        if (Boolean(element.itemList) && element.itemList.length > 0)
                            element.itemList.forEach(item => {
                                let obj = data.find(p => p.type === item.type && p.termId === item.termId);
                                if (Boolean(obj)) {
                                    item.id = obj.id;
                                    item.checked = obj.checked;
                                    item.description = obj.description;
                                    //item.point = obj.point;
                                }
                            });
                    });
                }



            } else

                groupList.forEach(element => {
                    if (Boolean(element.itemList) && element.itemList.length > 0)
                        element.itemList.forEach((item, index) => {
                            item.id = index * -1;
                            item.checked = false;
                        });
                });
        } else groupList.forEach(element => {
            if (Boolean(element.itemList) && element.itemList.length > 0)
                element.itemList.forEach((item, index) => {
                    item.id = index * -1;
                    item.checked = false;
                });
        });

        this.setState({
            groupList: groupList
        });

    }
    getVendors = async () => {
        let rfqId = this.state.rfqId;
        let groupList = [];
        if (Boolean(rfqId)) {
            const resDepList = await handleRequest("GET", "/RequestForQuotation/" + rfqId);
            console.log(resDepList);
            if (Boolean(resDepList.data)) {
                let data = resDepList.data;
                console.log("data", data);
                let list = [];
                if (Boolean(data.vendorLogs)) {

                    data.vendorLogs.forEach(element => {
                        element.vendorLog.rfqVendorId = element.id;
                        element.vendorLog.selected = element.selected;
                        list.push(element.vendorLog)
                    });
                }

                if (Boolean(data.generalTerms) && data.generalTerms.length > 0) {
                    let obj = { type: "RequestForQuotationGeneralTerm", name: "General Terms and Conditions", itemList: [] };
                    data.generalTerms.forEach((element, index) => {
                        if (element.rfqGeneralTermCheck) {
                            obj.itemList.push({ id: index * -1, defaultPoint: element.point, point: element.point, name: element.rfqGeneralTerm, type: "RequestForQuotationGeneralTerm", vendorLogId: null, termId: element.id, rfqId: rfqId, checked: false, necessary: element.necessary, description: null });
                        }
                    });
                    groupList.push(obj);

                }


                if (Boolean(data.techRequirements) && data.techRequirements.length > 0) {
                    let obj = { type: "RequestForQuotationTechRequirement", name: "Technical Requirements", itemList: [] };
                    data.techRequirements.forEach((element, index) => {
                        if (element.rfqTechRequirementCheck) {
                            obj.itemList.push({ id: index * -1, defaultPoint: element.point, point: element.point, description: null, name: element.rfqTechRequirement, type: "RequestForQuotationTechRequirement", vendorLogId: null, termId: element.id, rfqId: rfqId, checked: false, necessary: element.necessary });
                        }
                    });
                    groupList.push(obj);

                }


                if (Boolean(data.prices) && data.prices.length > 0) {
                    let obj = { type: "RequestForQuotationPrice", name: "Price", itemList: [] };
                    data.prices.forEach((element, index) => {
                        if (element.rfqPriceCheck) {
                            obj.itemList.push({ id: index * -1, defaultPoint: element.point, point: element.point, description: null, name: element.rfqPrice, type: "RequestForQuotationPrice", vendorLogId: null, termId: element.id, rfqId: rfqId, checked: false, necessary: element.necessary });
                        }
                    });
                    groupList.push(obj);

                }


                if (Boolean(data.paymentTerms) && data.paymentTerms.length > 0) {
                    let obj = { type: "RequestForQuotationPaymentTerm", name: "Payment Terms", itemList: [] };
                    data.paymentTerms.forEach((element, index) => {
                        if (element.rfqPaymentTermCheck) {
                            obj.itemList.push({ id: index * -1, defaultPoint: element.point, point: element.point, description: null, name: element.rfqPaymentTerm, type: "RequestForQuotationPaymentTerm", vendorLogId: null, termId: element.id, rfqId: rfqId, checked: false, necessary: element.necessary });
                        }
                    });
                    groupList.push(obj);

                }

                if (Boolean(data.deliveryPlaceCheck) && Boolean(data.deliveryPlace) && data.deliveryPlace.length > 0) {
                    let obj = { type: "deliveryPlace", name: "Delivery Place", itemList: [] };

                    obj.itemList.push({ id: -1, defaultPoint: data.deliveryPlacePoint, point: data.deliveryPlacePoint, description: null, name: data.deliveryPlace, type: "deliveryPlace", vendorLogId: null, termId: data.id, rfqId: rfqId, checked: false, necessary: data.necessaryDeliveryPlace });
                    groupList.push(obj);

                }

                if (Boolean(data.quoteValidityCheck) && Boolean(data.quoteValidityValue) && Boolean(data.quoteValidityDateType)) {
                    let obj = { type: "quoteValidity", name: "Validity of Quotation", itemList: [] };

                    obj.itemList.push({ id: -1, defaultPoint: data.quoteValidityPoint, point: data.quoteValidityPoint, description: null, name: data.quoteValidityValue + " " + data.quoteValidityDateType, type: "quoteValidity", vendorLogId: null, termId: data.id, rfqId: rfqId, checked: false, necessary: data.quoteValidityNecessary });

                    groupList.push(obj);

                }


                if (Boolean(data.evaCriterias) && data.evaCriterias.length > 0) {
                    let obj = { type: "RequestForQuotationEvaCriteria", name: "Evaluation Criterias", itemList: [] };
                    data.evaCriterias.forEach((element, index) => {
                        if (element.rfqEvaCriteriaCheck) {
                            obj.itemList.push({ id: index * -1, defaultPoint: element.point, point: element.point, description: null, name: element.rfqEvaCriteria, type: "RequestForQuotationEvaCriteria", vendorLogId: null, termId: element.id, rfqId: rfqId, checked: false, necessary: element.necessary });
                        }
                    });
                    groupList.push(obj);

                }

                if (Boolean(data.warranties) && data.warranties.length > 0) {

                    let obj = { type: "RequestForQuotationWarranty", name: "Warranty", itemList: [] };
                    data.warranties.forEach((element, index) => {
                        if (element.rfqWarrantyCheck) {
                            obj.itemList.push({ id: index * -1, defaultPoint: element.point, point: element.point, description: null, name: element.rfqWarranty, type: "RequestForQuotationWarranty", vendorLogId: null, termId: element.id, rfqId: rfqId, checked: false, necessary: element.necessary });
                        }
                    });
                    groupList.push(obj);

                }

                if (Boolean(data.languages) && data.languages.length > 0) {
                    let obj = { type: "RequestForQuotationLanguage", name: "Language", itemList: [] };
                    data.languages.forEach((element, index) => {
                        if (element.rfqLanguageCheck) {
                            obj.itemList.push({ id: index * -1, defaultPoint: element.point, point: element.point, description: null, name: element.rfqLanguage, type: "RequestForQuotationLanguage", vendorLogId: null, termId: element.id, rfqId: rfqId, checked: false, necessary: element.necessary });
                        }
                    });
                    groupList.push(obj);

                }

                if (Boolean(data.requestedDocuments) && data.requestedDocuments.length > 0) {
                    let obj = { type: "RequestForQuotationRequestedDocument", name: "Requested Documents", itemList: [] };
                    data.requestedDocuments.forEach((element, index) => {
                        if (element.rfqRequestedDocumentCheck) {
                            obj.itemList.push({ id: index * -1, defaultPoint: element.point, point: element.point, description: null, name: element.rfqRequestedDocument, type: "RequestForQuotationRequestedDocument", vendorLogId: null, termId: element.id, rfqId: rfqId, checked: false, necessary: element.necessary });
                        }
                    });
                    groupList.push(obj);
                }

                if (Boolean(data.others) && data.others.length > 0) {
                    let obj = { type: "RequestForQuotationOther", name: "Others", itemList: [] };
                    data.others.forEach((element, index) => {
                        if (element.rfqOtherCheck) {
                            obj.itemList.push({ id: index * -1, defaultPoint: element.point, point: element.point, description: null, name: element.rfqOther, type: "RequestForQuotationOther", vendorLogId: null, termId: element.id, rfqId: rfqId, checked: false, necessary: element.necessary });
                        }
                    });
                    groupList.push(obj);
                }

                const columns = this.state.columns;
                list.forEach(element => {

                    if (Boolean(columns)) {
                        let oldCol = columns.find(p => p.key === element.id);
                        if (!Boolean(oldCol)) {
                            let col = {
                                key: element.id,
                                editable: true,
                                title: <Checkbox value={element.rfqVendorId} onChange={e => this.updateVendor(e)

                                } checked={element.selected} > {element.fullName} </Checkbox>,
                                align: "center",
                                dataIndex: element.id + '',
                                totalPoint: 0,
                                render: (value, row, index) => {

                                    if (Boolean(row.tag))
                                        return (
                                            <Tag color="geekblue" style={{ fontSize: '16pt' }} key={index}>
                                                {value}
                                            </Tag>
                                        );
                                    else return value;
                                }
                            }
                            columns.push(col);
                        }
                        else oldCol.title = <Checkbox value={element.rfqVendorId} onChange={e => this.updateVendor(e)} checked={element.selected} > {element.fullName} </Checkbox>
                    }
                });


                this.setState({
                    vendorSelectItems: list,
                    groupList: groupList,
                    columns: columns
                }, this.restartTable);
            } else {

                this.setState({
                    vendorSelectItems: []
                });
            }
        }

    }


    edit = (row) => {

        this.setState({
            statusId: row.id,
            status: row.status,
            abbreviation: row.abbreviation,
            hideSave: true,
            hideUpdate: false,
            hideInputs: false,

        });

    }


    delete = async () => {
        const obj = {
            id: this.state.statusId
        }

        await handleRequest("POST", "/RFQVendorResponse/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            statusId: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;


    }






    restartTable = async () => {
        let rfqId = this.state.rfqId;
        let groupList = this.state.groupList;
        let vendorSelectItems = this.state.vendorSelectItems;
        let columns = this.state.columns;
        let tableList = [];
        let totalObj = { groupName: 'Total', tag: "Total" };

        columns.forEach((element, i) => {
            if (i >= 3)
                element.totalPoint = 0;
        })

        if (Boolean(rfqId)) {
            const response = await handleRequest("POST", "/RFQVendorResponse/getByVendorandRfq", { rfqId: rfqId });

            if (Boolean(response.data) && response.data.length > 0) {
                const list = response.data;

                if (Boolean(groupList) && groupList.length > 0) {

                    groupList.forEach(element => {
                        if (Boolean(element.itemList) && element.itemList.length > 0)
                            element.itemList.forEach(item => {
                                item.groupName = element.name;
                                item.key = item.type + item.termId;
                                if (Boolean(vendorSelectItems) && vendorSelectItems.length > 0) {

                                    vendorSelectItems.forEach(vendor => {
                                        let obj = list.find(p => p.type === item.type && p.termId === item.termId && p.vendorLogId === vendor.id);
                                        if (Boolean(obj)) {
                                            item[vendor.id + ""] = Boolean(obj.description) && obj.description.length > 0 ? obj.description + '  (' + obj.point + ')' : (obj.checked ? <div>  <CheckOutlined style={{ fontSize: '24px', color: '#08c', marginRight: '1rem' }} ></CheckOutlined>  ({obj.point}) </div> : '');
                                            item[vendor.id + "Point"] = obj.point;
                                            item[vendor.id + "Description"] = obj.description;
                                            item[vendor.id + "Checked"] = obj.checked;
                                            item[vendor.id + "ID"] = obj.id;
                                            if (obj.checked) {

                                                let ind = columns.findIndex(p => p.dataIndex === vendor.id + "");
                                                totalObj[vendor.id + ""] = columns[ind].totalPoint + obj.point;
                                                columns[ind].totalPoint = columns[ind].totalPoint + obj.point;
                                            }
                                        }
                                    });

                                }
                                tableList.push(item);

                            });
                    });
                }

                if (tableList.length > 0) {
                    tableList.push(totalObj);
                    columns.sort(function (a, b) {
                        var p1 = a.totalPoint;
                        var p2 = b.totalPoint;
                        if (p1 < 0 || p2 < 0) {
                            if (p1 < p2) {
                                return -1;
                            }
                            if (p1 > p2) {
                                return 1;
                            }
                        }

                        if (p1 < p2) {
                            return 1;
                        }
                        if (p1 > p2) {
                            return -1;
                        }

                        return 0;
                    });

                    // columns.forEach(element => {
                    //     delete element.dataIndex;  
                    // });

                }

                list.forEach(element => {
                    element.key = element.id
                });
                this.setState({
                    tableList: tableList,
                    columns: columns
                });
            } else {
                this.setState({
                    tableList: [],
                });
            }
        }
    }











    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            status: '',
            abbreviation: '',
            statusId: 0,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });

    }


    stop = () => {

        this.setState({
            status: '',
            abbreviation: '',
            statusId: 0,
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });

    }



    resetInputs = () => {
        this.setState({
            status: '',
            abbreviation: '',
            statusId: 0,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,


        });
    }


    cancel = () => {

        this.resetInputs();
    }




    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    deleteGroup = async (group) => {

        const obj = {

            id: group.id
        }

        await handleRequest("POST", "/BusinessTripGroup/delete", obj);

        this.showSuccess();


    }


    handleSave = (row) => {
        const newData = [...this.state.tableList];
        const index = newData.findIndex((item) => row.key === item.key);
        const item = newData[index];
        console.log(row);
        newData.splice(index, 1, { ...item, ...row });
        this.setState({
            tableList: newData,
        });
    };




    render() {

        const onFinish = values => {
            console.log('Success:', values);
        };

        const components = {
            body: {
                row: EditableRow,
                cell: EditableCell,
            },
        };

        const columns = this.state.columns.map((col) => {
            if (!col.editable) {
                return col;
            }

            return {
                ...col,
                onCell: (record) => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            };
        });

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
        };


        return (this.state.formId > 0) ? (
            (this.state.authorizations.length > 0 && this.state.authorizations.findIndex(p => p.action === "view") >= 0)
                ? (<div>

                    <MenuProvider id="menu_id">


                        <Modal
                            show={this.state.modalDeleteShow}
                            onHide={this.showOrHideDeleteModal}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">Chosen status will be deleted !</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Are you sure?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                                <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                            </Modal.Footer>
                        </Modal>



                        <Card>

                            <div>
                                <Row>
                                    <Col sm="1">
                                        <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                    </Col>
                                    <Col sm="10">
                                    </Col>
                                    <Col sm="1" style={{ textAlign: "right" }}>
                                        {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>

                                        }
                                    </Col>
                                </Row>
                            </div>

                            <div>
                                <Row>
                                    <Col sm="10">
                                    </Col>
                                    {/* <Col sm="2">
                                    {this.state.hideInputs &&
                                        <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                    }

                                    {!this.state.hideInputs &&
                                        <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={this.stop}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                    }

                                </Col> */}
                                </Row>
                            </div>

                            <div style={{ marginBottom: '2rem' }}>

                                <FormAnt
                                    {...layout}
                                    initialValues={{ remember: false }}
                                    onFinish={this.save}
                                    onFinishFailed={onFinishFailed}
                                    ref={this.formRef}
                                >

                                    {
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="RFQ" />}
                                            name="OrganizationalLevelRoleGroup"
                                            id="OrganizationalLevelRoleGroup"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="6">
                                                    <Select placeholder="" value={this.state.rfqId} onChange={(value) => {
                                                        this.setState({
                                                            rfqId: value
                                                        }, this.getVendors)
                                                    }}
                                                    >
                                                        <Option key={null} value={null}>Select</Option>
                                                        {this.state.rfqSelectItems.map(i => (
                                                            <Option key={i.id} value={i.id}>{i.rfqNo}</Option>
                                                        ))}
                                                    </Select>

                                                </Col>
                                                <Col sm="1">

                                                </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }

                                    {
                                        <FormAnt.Item
                                            label={<FormattedMessage id="todo" defaultMessage="Vendor" />}
                                            name="OrganizationalLevelDesignation"
                                            rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                                <Col sm="6">
                                                    <Select placeholder="" value={this.state.vendorLogId} onChange={(value) => {

                                                        this.setState({
                                                            vendorLogId: value,
                                                        }, this.initializeGroupList)
                                                    }}
                                                    >
                                                        <Option key={null} value={null}>Select</Option>
                                                        {this.state.vendorSelectItems.map(i => (
                                                            <Option key={i.id} value={i.id}>{i.fullName}</Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                                <Col sm="6">
                                                </Col>
                                            </Form.Group>
                                        </FormAnt.Item>
                                    }




                                    <Divider></Divider>

                                    {this.state.groupList.length > 0 && this.state.groupList.map((group, i) => (
                                        <div style={{ backgroundColor: "white", padding: "10px", marginTop: "10px", border: '1px solid #1ab394' }}>
                                            {
                                                <FormAnt.Item

                                                    name="OrganizationalLevelDesignation"
                                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >


                                                    <Form.Group style={{ marginBottom: '1rem' }} as={Row} >
                                                        <Col sm="1">
                                                        </Col>
                                                        <Col sm="6">
                                                            <Form.Label style={{ color: '#0070C0', font: 'bold', fontSize: '16pt' }} >
                                                                {group.name}
                                                            </Form.Label>
                                                        </Col>
                                                        <Col sm="5">

                                                        </Col>


                                                    </Form.Group>

                                                </FormAnt.Item>}
                                            { Boolean(group.itemList) && group.itemList.map((item, j) => (
                                                <div>

                                                    {  <FormAnt.Item

                                                        name="OrganizationalLevelDesignation"
                                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select " /> }]} >


                                                        <Form.Group key={group.id} style={{ marginBottom: '1rem' }} as={Row} >
                                                            <Col sm="2"></Col>
                                                            <Col sm="2">
                                                                <div>
                                                                    <Checkbox onChange={(e) => {
                                                                        let groupList = this.state.groupList;
                                                                        let obj = this.state.groupList.find(p => p.type === group.type);
                                                                        let subObj = obj.itemList.find(p => p.id === item.id);
                                                                        subObj.checked = e.target.checked;

                                                                        this.setState({
                                                                            groupList: groupList
                                                                        })
                                                                    }} checked={item.checked}> {item.name} </Checkbox>
                                                                </div>
                                                            </Col>

                                                            {item.checked && <Col sm="2">
                                                                <div>
                                                                    <Input placeholder="Description" value={item.description} onChange={(e) => {
                                                                        let groupList = this.state.groupList;
                                                                        let obj = this.state.groupList.find(p => p.type === group.type);
                                                                        let subObj = obj.itemList.find(p => p.id === item.id);
                                                                        subObj.description = e.target.value;

                                                                        this.setState({
                                                                            groupList: groupList
                                                                        })
                                                                    }} />
                                                                </div>
                                                            </Col>}

                                                            {/* {item.checked && <Col sm="2">
                                                                <div>
                                                                    <InputNumber placeholder="Point" value={item.point} min={0} max={item.defaultPoint} onChange={(value) => {
                                                                        let groupList = this.state.groupList;
                                                                        let obj = this.state.groupList.find(p => p.type === group.type);
                                                                        let subObj = obj.itemList.find(p => p.id === item.id);
                                                                        subObj.point = value;

                                                                        this.setState({
                                                                            groupList: groupList
                                                                        })
                                                                    }} />
                                                                </div>
                                                            </Col>} */}


                                                        </Form.Group>
                                                    </FormAnt.Item>}

                                                </div>
                                            ))}

                                            <Divider style={{ margin: '4px 0' }} />
                                        </div>))
                                    }



                                    <div >
                                        {
                                            <Row>
                                                <Col sm="3">
                                                </Col>
                                                <Col sm="5">
                                                    {/*<Button style={{ width: '100%' }} id='save-orgLevel' className="p-button-success" onClick={this.saveOrganizationalLevel} label="Save" />*/}
                                                    <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%', marginTop: '10px' }}
                                                        variant="success" ><FormattedMessage id="GeneralButtonSaveAll" defaultMessage="Save All" /></ReactButton>
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Row>
                                        }
                                    </div>



                                    <div hidden={true}>
                                        {
                                            <Row>
                                                <Col sm="3">
                                                </Col>
                                                <Col sm="2">
                                                    {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                    <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                                        <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                                </Col>
                                                <Col sm="3">
                                                    {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                    <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                                        <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                                </Col>
                                                <Col sm="3">
                                                </Col>
                                            </Row>
                                        }
                                    </div>
                                    <Modal
                                        show={this.state.modalMessage}
                                        onHide={this.showOrHideModalMessage}
                                        backdrop="static"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered>

                                        <Modal.Header closeButton>
                                            <Modal.Title id="contained-modal-title-vcenter">
                                                <Row >
                                                    <Col sm="12">
                                                        {this.state.messageTip == "Success" &&
                                                            <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                                        {this.state.messageTip == "Warning" &&
                                                            <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                                        {this.state.messageTip == "Error" &&
                                                            <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                                    </Col>
                                                </Row>
                                            </Modal.Title>
                                        </Modal.Header>

                                        <Modal.Body>
                                            <Row >
                                                <Col sm="12">
                                                    {this.state.message}
                                                </Col>
                                            </Row>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                                        </Modal.Footer>
                                    </Modal>
                                </FormAnt>
                            </div>

                            {this.state.tableList.length > 0 && <Table
                                components={components}
                                rowClassName={() => 'editable-row'}
                                style={{ marginTop: '3rem' }} pagination={false} size={`small`} columns={columns} dataSource={this.state.tableList} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }} >

                            </Table>}

                            {this.state.tableList.length > 0 &&

                                <div >
                                    {
                                        <Row>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="5">
                                                {/*<Button style={{ width: '100%' }} id='save-orgLevel' className="p-button-success" onClick={this.saveOrganizationalLevel} label="Save" />*/}
                                                <ReactButton onClick={this.saveScoring} style={{ width: '100%', marginTop: '10px' }}
                                                    variant="success" ><FormattedMessage id="GeneralButtonSaveAllScoring" defaultMessage="Save Scoring" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Row>
                                    }
                                </div>}

                        </Card>

                   </MenuProvider>
                    {!this.state.whatIsSetting && <Whatis  ></Whatis>


                    }
                </div>
                ) : (
                    <div>
                        <Redirect to="/error/error-v6" />
                    </div>
                )
        ) : (
            <div></div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorResponse)