/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'antd/dist/antd.css';
import { TreeTable } from 'primereact/treetable';
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { Calendar } from 'primereact/calendar';
import { Form, Col, Row, Modal, Button as ButtonReact } from 'react-bootstrap';
import { Cascader } from 'antd';
import { Button as AntdButton } from 'antd';
import { handleRequest } from '../../redux/config/env';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { DownloadOutlined } from '@ant-design/icons';
import { API_BASE } from '../../redux/config/env';
import CustomMaterialMenuCreate from '../settings/ForComponents/CustomMaterialMenuDocumentLogCreate';
import CustomMaterialMenuEdit from '../settings/ForComponents/CustomMaterialMenuDocumentLogEdit';
import moment from 'moment';
import { InputNumber } from 'antd';
import { TreeSelect } from 'antd';
import { MultiSelect } from 'primereact/multiselect';
import ItpLog from "./ItpLog";
import MaintenancePlan from "../Equipment/MaintenancePlan";
import RoleDocumentItem from "../Documentation/RoleDocumentItem";
import ChecklistLog from './ChecklistLog';
import MaintenanceChecklist from '../Equipment/MaintenanceChecklist';
import TextToPara from './TextToPara';
import Wps from './Wps';
import Nmr from '../Documentation/Nmr';
import { ExcelReader } from '../excel/ExcelReader';
import Viewer, { Worker, defaultLayout } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import {
    FacebookShareButton,
    WhatsappShareButton,
    EmailShareButton,
    FacebookIcon,
    WhatsappIcon,
    EmailIcon
} from "react-share";

const dataList = [];
const nonEditableDocumentTypes = ["SA","SAEP","SAES","SAMSS","SA-175"]; 

const generateList = data => {
    for (let i = 0; i < data.length; i++) {
        const node = data[i];

        dataList.push({ key: node.value, lineage: node.data.lineage });
        if (node.children) {
            generateList(node.children);
        }
    }
};

const renderToolbar = (toolbarSlot) => {
    return (
        <div
            style={{
                alignItems: 'center',
                display: 'flex',
                width: '100%',
            }}
        >
            <div
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    flexGrow: 1,
                    flexShrink: 1,
                    justifyContent: 'center',
                }}
            >
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.previousPageButton}
                </div>
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
                </div>
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.nextPageButton}
                </div>
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.zoomOutButton}
                </div>
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.zoomPopover}
                </div>
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.zoomInButton}
                </div>
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.downloadButton}
                </div>
                <div style={{ padding: '0 2px' }}>
                    {toolbarSlot.moreActionsPopover}
                </div>
            </div>
        </div>
    );
};

const layout = (
    isSidebarOpened,
    container,
    main,
    toolbar,
    sidebar,
) => {
    return defaultLayout(
        isSidebarOpened,
        container,
        main,
        toolbar(renderToolbar),
        sidebar,
    );
};


export class DocumentLog extends Component {

    constructor() {
        super();
        this.state = {
            expandIconPosition: 'left',
            loading: false,
            visible: false,
            dtNodes: [],
            values: [],
            selectedItems: [],
            newItem: '',
            treeData: [],
            displayBasic: false,
            id: null,
            documentTypeId: null,
            departmentCustodian: "Select Department",
            departmentCustodianId: null,
            departmentCustodians: [],

            disciplineCustodian: "Nill",
            disciplineCustodianId: null,
            disciplineCustodians: [],

            disciplineSelect: [],
            disciplineSelects: [],
            departmentSelect: "Select Department",
            departmentSelectId: [],
            documentTreeTable: [],

            parentId: 0,
            parentIdValue: "",

            user: [],
            users: [],
            code: "",
            description: "",
            date: null,
            revnoId: null,
            revno: 0,
            id: null,
            hiddenCreateNew: true,
            hiddenCreateNewButton: false,
            hiddenSave: false,
            hiddenUpdate: true,
            hiddenDisciplineSelect: true,
            hiddenUser: true,
            createNewButton: "Create New",
            createDocumentButton: "Create Document",
            createNewButtonVariant: "p-button-success",
            authorizations: [],
            fileName: "",
            filePath: "",
            documentTypePage: "",

            FileUpload: [],
            fileList: [],
            hiddenDescriptionKnt: true,
            hiddenDocCodeKnt: true,

            parentDocument: [],

            modalITPCreateDoc: false,
            modalMaintenancePlanCreateDoc: false,
            modalRoleDocumentCreateDoc: false,
            modalMaintenanceChecklistCreateDoc: false,
            modalChecklistCreateDoc: false,
            modalEditDocument: false,
            modalWPSCreateDoc: false,
            modalNMRCreateDoc: false,

            hiddenCreateITP: true,
            hiddenCreateMaintenancePlan: true,
            hiddenCreateRoleDocument: true,
            hiddenCreateMaintenanceChecklist: true,
            hiddenCreateChecklist: true,
            hiddenEditDocument: true,
            createDocument: 0,
            modalMessage: false,
            modalEditDocument: false,
            modalPdfViewer: false,
            pdfFile: "",
            hiddenCreateWPS: true,
            hiddenCreateNMR: true,
        };

        this.onUserChange = this.onUserChange.bind(this);
        this.onDisciplineChange = this.onDisciplineChange.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
        this.renderDownloadButton = this.renderDownloadButton.bind(this);
        this.onClickModal = this.onClickModal.bind(this);
        this.onHideModal = this.onHideModal.bind(this);
        this.showSuccess = this.showSuccess.bind(this);
        this.showError = this.showError.bind(this);

        this.dCodeBodyTemplate = this.dCodeBodyTemplate.bind(this);
    }

    showSuccess(msg) {
        if (!msg) {
            msg = 'Successful';
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    };

    showError(msg) {
        if (!msg) {
            msg = 'Something went wrong. Please check inputs.';
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    };

    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    onClickModal(name, rowData) {
        this.setState({
            [`${name}`]: true
        });
    };

    onHideModal(name) {
        this.setState({
            [`${name}`]: false
        });
    }

    renderFooter(name) {
        return (
            <div>
                <Button label="Yes" icon="pi pi-check" onClick={() => this.deleteDocumentLog(name)} className="p-button-danger" />
                <Button label="No" icon="pi pi-times" onClick={() => this.onHideModal(name)} className="p-button-secondary" />
            </div>
        );
    }

    actionTemplate(rowData, column) {
        if (rowData.data.type === "log" || rowData.data.type === "child") {
            if (rowData.data.createDocument != null && rowData.data.createDocument != undefined) {
                if (rowData.data.createDocument == "ItpLogUpdate" || rowData.data.createDocument == "ChecklistLogUpdate" ||
                    rowData.data.createDocument == "EditDocumentUpdate" || rowData.data.createDocument == "WPSUpdate" ||
                    rowData.data.createDocument == "NMRUpdate" || rowData.data.createDocument == "MaintenancePlanUpdate" ||
                    rowData.data.createDocument == "MaintenanceChecklistUpdate" || rowData.data.createDocument == "RoleDocumentUpdate") {
                    return <React.Fragment>
                        <CustomMaterialMenuEdit row={rowData}
                            onDeleteRow={this.deleteDocumentModal.bind(this)}
                            onAmendRow={this.changeButtonUpdate.bind(this, "Amend")}
                            onEditDocRow={this.onClickEditDocument.bind(this)}
                            onViewRow={this.onClickView.bind(this)}
                        />
                    </React.Fragment>;
                } else if (rowData.data.createDocument == "ItpLogInsert" || rowData.data.createDocument == "ChecklistLogInsert" ||
                    rowData.data.createDocument == "EditDocumentInsert" || rowData.data.createDocument == "WPSInsert" ||
                    rowData.data.createDocument == "NMRInsert" || rowData.data.createDocument == "MaintenancePlanInsert" ||
                    rowData.data.createDocument == "MaintenanceChecklistInsert" || rowData.data.createDocument == "RoleDocumentInsert") {
                    return <React.Fragment>
                        <CustomMaterialMenuCreate row={rowData}
                            onDeleteRow={this.deleteDocumentModal.bind(this)}
                            onAmendRow={this.changeButtonUpdate.bind(this, "Insert")}
                            onCreateDocRow={this.onClickCreateDocument.bind(this)}
                            onViewRow={this.onClickView.bind(this)} />
                    </React.Fragment>;
                }
            }
            return <React.Fragment>
                <CustomMaterialMenuEdit row={rowData}
                    onDeleteRow={this.deleteDocumentModal.bind(this)}
                    onAmendRow={this.changeButtonUpdate.bind(this, "Amend")}
                    onEditDocRow={this.changeButtonUpdate.bind(this, "Edit")}
                    onViewRow={this.onClickView.bind(this)}
                />
            </React.Fragment>;
        }
        return '';
    };

    renderDownloadButton(rowData, column) {
        if (rowData !== null && rowData !== undefined && rowData.data !== null && rowData.data !== undefined && rowData.data.filePath !== null && rowData.data.filePath !== undefined && rowData.data.filePath.length > 0)
            return <React.Fragment>
                <AntdButton style={{ marginLeft: '2px', marginTop: '-2px', alignContent: 'center' }} onClick={() => this.downloadFile(rowData)} type="primary" shape="round" icon={<DownloadOutlined />} >

                </AntdButton>

            </React.Fragment>

        return "";
    };

    renderDate(rowData, column) {
        var _date = '';
        if (rowData.data.date !== null && rowData.data.date !== undefined) {

            _date = moment(rowData.data.date).format("DD/MM/YYYY");
            return _date;
        }

        return _date;

    };

    renderStatus(rowData, column) {
        if (rowData.data.type === "log")
            return 'Active';

        if (rowData.data.type === "revision")
            return 'Revised';

        if (rowData.data.type === "child")
            return 'Child';

        return '';

    };

    renderRevno(rowData) {
        if (Boolean(rowData.data.id) && rowData.data.id > 0) {
            return rowData.data.revno;
        }
        return '';
    };

    // renderExpander(rowData) { 
    //  if(Boolean(rowData.data.id) && rowData.data.id>0){
    //     return true;
    //  }
    //     return false;
    // };

    componentDidMount = async () => {
        this.getNodes();
        this.getNodes2();
        //  const response = await axios.get(`${API_BASE}/Designation/getAll`);
        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => p.form === "DocumentLog")
            });

        } catch (error) {

        }

        const response = await handleRequest("GET", "/Department/getallByCompany");

        if (response.data.length !== 0) {

            const columns = response.data;

            const options = columns.map(function (row) {
                return { name: row.departmentName.toString(), id: row.id.toString() }
            })

            this.setState({
                departmentCustodians: options,
                nodState: true,
            });
        }

        const responseDis = await handleRequest("GET", "/Discipline/getallByCompany");

        if (responseDis.data.length !== 0) {

            const columnsDis = responseDis.data;

            const optionsDis = columnsDis.map(function (row) {
                return { name: row.disciplineName.toString(), id: row.id.toString() }
            })

            this.setState({
                disciplineCustodians: optionsDis,
            });
        }


        const responseDepartmentSelect = await handleRequest("GET", "/Department/getTreeDepartment");

        if (responseDepartmentSelect.data.length === 0) {
            this.setState({
                treeData: [],
            });
        }

        if (responseDepartmentSelect.data.length !== 0) {
            this.setState({
                treeData: responseDepartmentSelect.data,
            });
        }
    };

    getDocumentLog = async () => {
        if (this.props.location.pathname.split('/')[2] == null) {
            this.setState({
                discipline: "",
                disciplineVal: 0,

                itpcode: "",
                itpname: "",
                approvaldate: "",
                approvalDateVal: null,
                revno: "",
            })
        } else {
            const deger = this.props.location.pathname.split('/')[2];
            const newObj = {
                id: deger
            }
            const response = await handleRequest("POST", "/DocumentLog/getDocumentLogResult", newObj);
            if (response.data.length !== 0) {
                this.setState({
                    discipline: response.data[0].discipline == null ? "" : response.data[0].discipline.disciplineName,
                    disciplineVal: response.data[0].discipline == null ? "" : response.data[0].discipline.id,

                    itpcode: response.data[0].code,
                    itpname: response.data[0].description,
                    approvaldate: this.setDateFormat(response.data[0].date),
                    approvalDateVal: new Date(response.data[0].date),
                    revno: response.data[0].revno,
                });
            }
            else {
                this.setState({
                    discipline: "",
                    disciplineVal: 0,

                    itpcode: "",
                    itpname: "",
                    approvaldate: "",
                    approvalDateVal: null,
                    revno: "",
                })
            }
        }
    }

    changeButtonUpdate = (tip, rowData) => {
        if (rowData.data.documentTypeId == 0) {
            this.getParentDocument(-1);
        } else {
            this.getParentDocument(rowData.data.documentTypeId);
        }
        //var _values = dataList.filter(p=>p.key==rowData.data.id)[0].lineage;  
        var deger = dataList.filter(p => p.key == rowData.data.documentTypeId)
        if (deger != null && deger != 'undefined' && deger != '') {
            this.setState({
                values: deger[0].lineage
            });
        } else {
            this.setState({
                values: []
            });
        }

        var parentIdValue = "";
        if (rowData.data.parentId != 0) {
            parentIdValue = rowData.data.description;
        }

        var departmentName = "";
        var departmentId = 0;
        if (rowData.data.department != null) {
            departmentName = rowData.data.department.departmentName;
            departmentId = rowData.data.department.id;
        }

        if (rowData.data.createDocument == "ItpLogUpdate" || rowData.data.createDocument == "ItpLogInsert") {
            this.setState({
                hiddenCreateITP: false,
                hiddenCreateMaintenancePlan: true,
                hiddenCreateRoleDocument: true,
                hiddenCreateMaintenanceChecklist: true,
                hiddenCreateChecklist: true,
                hiddenEditDocument: true,
                hiddenCreateWPS: true,
                hiddenCreateNMR: true,
            });
        } else if (rowData.data.createDocument == "ChecklistLogUpdate" || rowData.data.createDocument == "ChecklistLogInsert") {
            this.setState({
                hiddenCreateChecklist: false,
                hiddenCreateITP: true,
                hiddenCreateMaintenancePlan: true,
                hiddenCreateRoleDocument: true,
                hiddenCreateMaintenanceChecklist: true,
                hiddenEditDocument: true,
                hiddenCreateWPS: true,
                hiddenCreateNMR: true,
            });
        }
        else if (rowData.data.createDocument == "EditDocumentUpdate" || rowData.data.createDocument == "EditDocumentInsert") {
            this.setState({
                hiddenEditDocument: false,
                hiddenCreateITP: true,
                hiddenCreateMaintenancePlan: true,
                hiddenCreateRoleDocument: true,
                hiddenCreateMaintenanceChecklist: true,
                hiddenCreateChecklist: true,
                hiddenCreateWPS: true,
                hiddenCreateNMR: true,
            });
        }
        else if (rowData.data.createDocument == "WPSUpdate" || rowData.data.createDocument == "WPSInsert") {
            this.setState({
                hiddenEditDocument: true,
                hiddenCreateITP: true,
                hiddenCreateMaintenancePlan: true,
                hiddenCreateRoleDocument: true,
                hiddenCreateMaintenanceChecklist: true,
                hiddenCreateChecklist: true,
                hiddenCreateWPS: false,
                hiddenCreateNMR: true,
            });
        }
        else if (rowData.data.createDocument == "NMRUpdate" || rowData.data.createDocument == "NMRInsert") {
            this.setState({
                hiddenEditDocument: true,
                hiddenCreateITP: true,
                hiddenCreateMaintenancePlan: true,
                hiddenCreateRoleDocument: true,
                hiddenCreateChecklist: true,
                hiddenCreateWPS: true,
                hiddenCreateNMR: false,
            });
        }
        else if (rowData.data.createDocument == "MaintenancePlanUpdate" || rowData.data.createDocument == "MaintenancePlanInsert") {
            this.setState({
                hiddenCreateMaintenancePlan: false,
                hiddenCreateRoleDocument: true,
                hiddenCreateMaintenanceChecklist: true,
                hiddenEditDocument: true,
                hiddenCreateITP: true,
                hiddenCreateChecklist: true,
                hiddenCreateWPS: true,
                hiddenCreateNMR: true,
            });
        }
        else if (rowData.data.createDocument == "MaintenanceChecklistUpdate" || rowData.data.createDocument == "MaintenanceChecklistInsert") {
            this.setState({
                hiddenCreateMaintenanceChecklist: false,
                hiddenCreateMaintenancePlan: true,
                hiddenCreateRoleDocument: true,
                hiddenEditDocument: true,
                hiddenCreateITP: true,
                hiddenCreateChecklist: true,
                hiddenCreateWPS: true,
                hiddenCreateNMR: true,
            });
        } else if (rowData.data.createDocument == "RoleDocumentUpdate" || rowData.data.createDocument == "RoleDocumentInsert") {
            this.setState({
                hiddenCreateRoleDocument: false,
                hiddenCreateMaintenancePlan: true,
                hiddenCreateMaintenanceChecklist: true,
                hiddenEditDocument: true,
                hiddenCreateITP: true,
                hiddenCreateChecklist: true,
                hiddenCreateWPS: true,
                hiddenCreateNMR: true,
            });
        }
        else {
            this.setState({
                hiddenCreateITP: true,
                hiddenCreateMaintenancePlan: true,
                hiddenCreateRoleDocument: true,
                hiddenCreateMaintenanceChecklist: true,
                hiddenCreateChecklist: true,
                hiddenEditDocument: true,
                hiddenCreateWPS: true,
                hiddenCreateNMR: true,
            });
        }

        if (rowData.data.createDocument == "ItpLogUpdate" || rowData.data.createDocument == "ChecklistLogUpdate" ||
            rowData.data.createDocument == "EditDocumentUpdate" || rowData.data.createDocument == "WPSUpdate" ||
            rowData.data.createDocument == "NMRUpdate" || rowData.data.createDocument == "MaintenancePlanUpdate" ||
            rowData.data.createDocument == "MaintenanceChecklistUpdate" || rowData.data.createDocument == "RoleDocumentUpdate") {
            this.setState({
                createDocumentButton: "Amend Document",
            });
        } else if (rowData.data.createDocument == "ItpLogInsert" || rowData.data.createDocument == "ChecklistLogInsert" ||
            rowData.data.createDocument == "EditDocumentInsert" || rowData.data.createDocument == "WPSInsert" ||
            rowData.data.createDocument == "NMRInsert" || rowData.data.createDocument == "MaintenancePlanInsert" ||
            rowData.data.createDocument == "MaintenanceChecklistInsert" || rowData.data.createDocument == "RoleDocumentInsert") {
            this.setState({
                createDocumentButton: "Create Document",
            });
        }

        var revno = 0;
        if (tip == "Amend") {
            revno = rowData.data.revno + 1;
        } else {
            revno = rowData.data.revno;
        }

        this.setState({
            hiddenUpdate: false,
            hiddenSave: true,
            hiddenCreateNew: false,
            hiddenCreateNewButton: true,
            id: rowData.data.id,
            department: departmentName,
            documentTypeId: rowData.data.documentTypeId,
            code: rowData.data.code,
            description: rowData.data.description,
            date: new Date(rowData.data.date), //moment(rowData.data.date).format("DD/MM/YYYY"),
            revno: revno,
            filePath: rowData.data.filePath,
            user: rowData.data.staffs,
            documentTypePage: rowData.data.documentTypePage,

            departmentCustodian: departmentName,
            departmentCustodianId: departmentId,

            disciplineCustodianId: rowData.data.disciplineId,
            disciplineCustodian: rowData.data.discipline,

            parentId: rowData.data.parentId,
            parentIdValue: parentIdValue,
        });
    };

    createNew = () => {
        this.resetInputs();

        this.setState({
            hiddenUser: true,
            hiddenDisciplineSelect: true,
        });

        if (this.state.createNewButton === "Create New") {
            this.setState({
                createNewButton: "Stop Adding New",
                createNewButtonVariant: "p-button-danger",
                createDocumentButton: "Create Document",
            });
        }
        else {
            this.setState({
                createNewButton: "Create New",
                createDocumentButton: "Create Document",
                createNewButtonVariant: "p-button-success"
            });
        }

        if (this.state.hiddenCreateNew) {
            this.setState({
                hiddenCreateNew: false,
            });
        }
        else {
            this.setState({
                hiddenUpdate: true,
                hiddenSave: false,
                hiddenCreateNew: true,
                createNewButton: "Create New",
                createDocumentButton: "Create Document",
                createNewButtonVariant: "p-button-success",
            });
        }
    };

    changeButtonCancel = () => {
        this.setState({
            hiddenUpdate: true,
            hiddenSave: false,
            hiddenCreateNew: true,
            hiddenCreateNewButton: false,
            createNewButton: "Create New",
            createDocumentButton: "Create Document",
            createNewButtonVariant: "p-button-success",
        });
        this.resetInputs();
    };

    handleChange = e => {
        e.preventDefault();
        var IdValue;

        for (var z = 0; z < this.state.departmentCustodians.length; z++) {
            if (this.state.departmentCustodians[z].name === e.target.value) {
                IdValue = this.state.departmentCustodians[z].id
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            departmentCustodianId: IdValue,
            nodState: false
        });
    };

    handleChangeDis = e => {
        e.preventDefault();
        var disciplineIdValue;
        for (var z = 0; z < this.state.disciplineCustodians.length; z++) {

            if (this.state.disciplineCustodians[z].name === e.target.value) {
                disciplineIdValue = this.state.disciplineCustodians[z].id
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            disciplineCustodianId: disciplineIdValue,
        });
    };

    handleChangeParentDocument = e => {
        e.preventDefault();
        var parentId;
        for (var z = 0; z < this.state.parentDocument.length; z++) {
            if (this.state.parentDocument[z].name === e.target.value) {
                parentId = this.state.parentDocument[z].id
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            parentId: parentId,
        });
    };

    onDisciplineChange(event) {
        var disciplineIdValue = event.value;

        var deger;
        if (disciplineIdValue == '') {
            deger = true;
        } else {
            deger = false;
        }

        this.setState({
            disciplineSelect: disciplineIdValue,
            hiddenUser: deger,
        });

        if (disciplineIdValue != '' && this.state.departmentSelectId != '') {
            this.GetUsers(disciplineIdValue, this.state.departmentSelectId);
        } else {
            this.setState({
                users: [],
                user: []
            });
        }
    }

    async GetUsers(disIDs, depIDs) {
        const newObj = {
            disciplineIDs: disIDs.toString(),
            departmentIDs: depIDs.toString()
        }

        const responseDis = await handleRequest("POST", "/Staff/getResultStaff", newObj);

        if (responseDis.data.length !== 0) {

            const columnsDis = responseDis.data;
            const optionsDis = columnsDis.map(function (row) {
                return { label: row.name.toString(), value: row.id.toString() }
            })

            this.setState({
                users: optionsDis
            });
        } else {
            this.setState({
                users: [],
                user: []
            });
        }
    }

    onUserChange(event) {
        this.setState({
            user: event.value
        });
    }

    onChangeDocType = (value, data) => {
        if (data.length > 0) {
            this.setState({
                values: value,
                documentTypeId: data[data.length - 1].value,
                selectedItems: data
            })
            this.getParentDocument(data[data.length - 1].value);
            this.getDocumentTypePage(data[data.length - 1].value);
        } else {
            this.setState({
                values: [],
                documentTypeId: null,
                selectedItems: [],
                hiddenCreateITP: true,
                hiddenCreateMaintenancePlan: true,
                hiddenCreateRoleDocument: true,
                hiddenCreateMaintenanceChecklist: true,
                hiddenCreateChecklist: true,
                hiddenEditDocument: true,
                hiddenCreateWPS: true,
                hiddenCreateNMR: true,
            })
            this.getParentDocument(-1);
        }
    }

    showModal = () => {
        this.setState({
            visible: true,
            newItem: ''
        });
    };

    handleOk = () => {
        this.setState({ loading: true });
        const length = this.state.values.length;

        if (length > 0)
            this.setState({ parentId: this.state.values[length - 1] });

        setTimeout(() => {

            this.setState({ loading: false, visible: false });
            this.saveItem();

        }, 1000);
    };

    handleCancel = () => {
        this.setState({ visible: false });
        this.resetInputs();
    };

    getNodes = async () => {

        var response = [];

        response = await handleRequest("GET", "/DocumentLog/getNodesDocumentLogs");

        if (response.data.length !== 0) {
            generateList(response.data);
            this.setState({
                dtNodes: response.data
            });

        }
    }

    getNodes2 = async () => {
        //var responseTree = await handleRequest("GET", "/DocumentLog/getNodesDocumentLogs2");
        //var responseTree = await handleRequest("GET", "/DocumentLog/getTreeDocumentLog");
        var responseTree = await handleRequest("GET", "/DocumentLog/getTypeDocumentLogs");

        if (responseTree.data.length !== 0) {
            this.setState({
                documentTreeTable: responseTree.data,
            });
        } else {
            this.setState({
                documentTreeTable: [],
            });
        }
    }

    getDocumentTypePage = async (documentTypeId) => {
        const newObj = {
            id: documentTypeId,
        }
        var response = await handleRequest("POST", "/DocumentType/getDocumentTypePage", newObj);
        if (response.data.length !== 0) {
            if (response.data == "Itp") {
                this.setState({
                    hiddenCreateITP: false,
                    hiddenCreateChecklist: true,
                    hiddenEditDocument: true,
                    hiddenCreateWPS: true,
                    hiddenCreateNMR: true,
                    hiddenCreateMaintenancePlan: true,
                    hiddenCreateRoleDocument: true,
                    hiddenCreateMaintenanceChecklist: true,
                });
            } else if (response.data == "Checklist") {
                this.setState({
                    hiddenCreateITP: true,
                    hiddenCreateChecklist: false,
                    hiddenEditDocument: true,
                    hiddenCreateWPS: true,
                    hiddenCreateNMR: true,
                    hiddenCreateMaintenancePlan: true,
                    hiddenCreateRoleDocument: true,
                    hiddenCreateMaintenanceChecklist: true,
                });
            } else if (response.data == "EditDocument") {
                this.setState({
                    hiddenCreateITP: true,
                    hiddenCreateChecklist: true,
                    hiddenEditDocument: false,
                    hiddenCreateWPS: true,
                    hiddenCreateNMR: true,
                    hiddenCreateMaintenancePlan: true,
                    hiddenCreateRoleDocument: true,
                    hiddenCreateMaintenanceChecklist: true,
                });
            }
            else if (response.data == "Wps") {
                this.setState({
                    hiddenCreateITP: true,
                    hiddenCreateChecklist: true,
                    hiddenEditDocument: true,
                    hiddenCreateWPS: false,
                    hiddenCreateNMR: true,
                    hiddenCreateMaintenancePlan: true,
                    hiddenCreateRoleDocument: true,
                    hiddenCreateMaintenanceChecklist: true,
                });
            }
            else if (response.data == "Nmr") {
                this.setState({
                    hiddenCreateITP: true,
                    hiddenCreateChecklist: true,
                    hiddenEditDocument: true,
                    hiddenCreateWPS: true,
                    hiddenCreateNMR: false,
                    hiddenCreateMaintenancePlan: true,
                    hiddenCreateRoleDocument: true,
                    hiddenCreateMaintenanceChecklist: true,
                });
            }
            else if (response.data == "MaintenancePlan") {
                this.setState({
                    hiddenCreateMaintenancePlan: false,
                    hiddenCreateRoleDocument: true,
                    hiddenCreateMaintenanceChecklist: true,
                    hiddenCreateITP: true,
                    hiddenCreateChecklist: true,
                    hiddenEditDocument: true,
                    hiddenCreateWPS: true,
                    hiddenCreateNMR: true,
                });
            }
            else if (response.data == "MaintenanceChecklist") {
                this.setState({
                    hiddenCreateMaintenanceChecklist: false,
                    hiddenCreateMaintenancePlan: true,
                    hiddenCreateRoleDocument: true,
                    hiddenCreateITP: true,
                    hiddenCreateChecklist: true,
                    hiddenEditDocument: true,
                    hiddenCreateWPS: true,
                    hiddenCreateNMR: true,
                });
            } else if (response.data == "RoleDocumentItem") {
                this.setState({
                    hiddenCreateRoleDocument: false,
                    hiddenCreateMaintenancePlan: true,
                    hiddenCreateMaintenanceChecklist: true,
                    hiddenCreateITP: true,
                    hiddenCreateChecklist: true,
                    hiddenEditDocument: true,
                    hiddenCreateWPS: true,
                    hiddenCreateNMR: true,
                });
            }
            else {
                this.setState({
                    hiddenEditDocument: true,
                    hiddenCreateITP: true,
                    hiddenCreateMaintenancePlan: true,
                    hiddenCreateRoleDocument: true,
                    hiddenCreateMaintenanceChecklist: true,
                    hiddenCreateChecklist: true,
                    hiddenCreateWPS: true,
                    hiddenCreateNMR: true,
                });
            }
        } else {
            this.setState({
                hiddenCreateITP: true,
                hiddenCreateMaintenancePlan: true,
                hiddenCreateRoleDocument: true,
                hiddenCreateMaintenanceChecklist: true,
                hiddenCreateChecklist: true,
                hiddenEditDocument: true,
                hiddenCreateWPS: true,
                hiddenCreateNMR: true,
            });
        }
    }

    getParentDocument = async (documentTypeId) => {
        const newObj = {
            documentTypeId: documentTypeId,
        }
        var response = await handleRequest("POST", "/DocumentLog/getParentDocumentDistinct", newObj);
        if (response.data.length !== 0) {
            const columns = response.data;

            const options = columns.map(function (row) {
                return { name: row.code.toString() + "/" + row.description.toString(), id: row.id }
            })

            this.setState({
                parentDocument: options,
            });

        } else {
            this.setState({
                parentDocument: [],
            });
        }
    }

    saveDocumentLog = async () => {
        if (this.state.description.trim() != "" && this.state.departmentCustodianId != null && this.state.documentTypeId != null) {
            if (this.state.departmentCustodianId != 0 && this.state.documentTypeId != 0) {
                if (this.state.hiddenDescriptionKnt == true) {
                    if (this.state.hiddenDocCodeKnt == true) {
                        const newDocumentLog = {
                            departmentId: this.state.departmentCustodianId,
                            documentTypeId: this.state.documentTypeId,
                            code: this.state.code,
                            description: this.state.description,
                            date: this.state.date,
                            revno: this.state.revno,
                            filePath: this.state.filePath,
                            disciplineId: this.state.disciplineCustodianId,  //+
                            staffs: this.state.user,                        //+ 
                            parentId: this.state.parentId,
                            createDocument: this.state.createDocument,
                        }

                        var response = await handleRequest("POST", "/DocumentLog/LogAdd", newDocumentLog);

                        if (response.data !== null && response.data !== undefined) {
                            if (response.data == "SUCCESS-DOCUMENT-001") {
                                this.getNodes();
                                this.getNodes2();
                                this.createNew();
                                this.showSuccess();
                            }
                            else {
                                this.showError("An error was occured please try again later !");
                            }
                        }
                    } else {
                        this.showError("The Doc Code you want to add has been used before. Please use a different Doc Code !");
                    }
                } else {
                    this.showError("The Document Description you want to add has been used before. Please use a different Document Description !");
                }
            } else {
                this.showError();
            }
        } else {
            this.showError();
        }
    };

    onClickCreateDocument = async (rowData) => {
        var page = rowData.data.documentTypePage;
        var id = rowData.data.id;
        if (page != "" && page != null && id != "" && id != null) {
            if (page == "Itp" || page == "Checklist") {
                window.location.href = "/" + page + "Log/" + id;
            }
            else if (page == "EditDocument") {
                window.location.href = "/TextToPara/" + id;
            }
            else {
                window.location.href = "/" + page + "/" + id;
            }
        } else {
            this.showError("Something went wrong. Please check Document Type Form Action Inputs.");
        }
    };

    onClickEditDocument = async (rowData) => {
        var page = rowData.data.documentTypePage;
        var id = rowData.data.id;
        if (page != "" && page != null && id != "" && id != null) {
            if (page == "Itp" || page == "Checklist") {
                window.location.href = "/" + page + "Log/" + id;
            }
            else if (page == "EditDocument") {
                window.location.href = "/TextToPara/" + id;
            }
            else if (page == "Nmr") {
                window.location.href = "/NonMaterialRequirement/" + id;
            }
            else {
                window.location.href = "/" + page + "/" + id;
            }
        } else {
            this.showError("Something went wrong. Please check Document Type Form Action Inputs.");
        }
    };

    onClickView = async (rowData) => {

    };

    saveItem = async () => {

        if (this.state.newItem.trim() !== "") {

            const newObj = {
                documentType: this.state.newItem,
                parentId: this.state.parentId
            }

            await handleRequest("POST", "/DocumentLog/add", newObj);

            this.getNodes();
            this.getNodes2();
            this.resetInputs();
            this.showSuccess("New item added successfully");
        } else {
            this.showError();
            // message.error('Please type input.');
        }
    };

    updateDone = async () => {

        if (this.state.description.trim() != "" && this.state.departmentCustodianId != null && this.state.documentTypeId != null) {
            if (this.state.departmentCustodianId != 0 && this.state.documentTypeId != 0) {

                const updatedDocumentLog = {
                    id: this.state.id,
                    departmentId: this.state.departmentCustodianId,
                    documentTypeId: this.state.documentTypeId,
                    code: this.state.code,
                    description: this.state.description,
                    date: this.state.date,
                    revno: this.state.revno,
                    filePath: this.state.filePath,
                    disciplineId: this.state.disciplineCustodianId,  //-
                    staffs: this.state.user,                      //- 
                    parentId: this.state.parentId,
                }

                var response = await handleRequest("POST", "/DocumentLog/update", updatedDocumentLog);

                if (response.data !== null && response.data !== undefined) {
                    if (response.data == "SUCCESS-DOCUMENT-001") {
                        this.getNodes();
                        this.getNodes2();
                        this.createNew();
                        this.showSuccess();
                        this.setState({
                            hiddenCreateNewButton: false
                        });
                    }
                    else {
                        this.showError("An error was occured please try again later !");
                    }
                }
            }
            else {
                this.showError();
            }
        }
        else {
            this.showError();
        }
    };

    deleteDocumentLog = async (pId) => {
        const deleteDocumentLog = {
            id: pId
        }

        // await axios.post(`${API_BASE}/Designation/delete`, deleteDocumentLog);
        await handleRequest("POST", "/DocumentLog/delete", deleteDocumentLog);

        this.getNodes();
        this.getNodes2();
        this.showSuccess();
    }

    onChangeInput = ({ target: { value } }) => {
        this.setState({ newItem: value });
    };

    resetInputs = () => {
        this.setState({
            parentId: 0,
            newItem: '',
            documentTypeId: null,
            id: 0,
            documentType: [],
            code: "",
            description: "",
            date: null,
            revno: 0,
            departmentCustodianId: null,
            departmentCustodian: "Select Department",
            filePath: "",
            disciplineCustodianId: null,
            disciplineCustodian: "Nill",
            user: [],
            values: [],
            disciplineSelects: [],
            disciplineSelect: [],
            hiddenUser: true,
            hiddenDisciplineSelect: true,
            departmentSelectId: [],
            departmentSelect: "Select Department",
            documentTypePage: "",
            fileName: "",
            filePath: "",

            FileUpload: [],
            fileList: [],

            parentId: 0,
            parentIdValue: "",

            hiddenCreateITP: true,
            hiddenCreateMaintenancePlan: true,
            hiddenCreateRoleDocument: true,
            hiddenCreateMaintenanceChecklist: true,
            hiddenCreateChecklist: true,
            hiddenEditDocument: true,
            hiddenCreateWPS: true,
            hiddenCreateNMR: true,
            createDocument: 0,
        });
    }

    // onChangeUpload = (info) => {
    //     if (info.file.status !== 'uploading') {
    //     }
    //     if (info.file.status === 'done') {
    //         message.success(`${info.file.name} file uploaded successfully`);

    //         if (info.file.response.length > 0) {
    //             this.setState({
    //                 fileName: info.file.name,
    //                 filePath: info.file.response[0]
    //             })
    //         }

    //     } else if (info.file.status === 'error') {
    //         message.error(`${info.file.name} file upload failed.`);
    //     }
    // }

    downloadFile = async (rowData) => {
        if (rowData.data.filePath.includes(API_BASE)) {
            window.location.href = rowData.data.filePath;
        } else {
            window.location.href = `${API_BASE}/DocumentLog/download/` + rowData.data.type + `/` + rowData.data.id;
        }
    }

    deleteDocumentModal = (row) => {
        this.setState({
            deleteModal: true,
            selectedDocumentLog: row.data
        });


        // const that = this;

        // confirm({
        //     title: 'Are you sure delete this document?',
        //     icon: <ExclamationCircleOutlined />,
        //     content: 'Selected document will be deleted !',
        //     okText: 'Yes',
        //     okType: 'danger',
        //     cancelText: 'No',
        //     onOk() { 
        //         that.deleteDocumentLog(row.data.id);
        //         setTimeout(() => {
        //             document.body.style.overflow = "auto";
        //         }, 500);
        //      }, 
        //     onCancel() {
        //         setTimeout(() => {
        //             document.body.style.overflow = "auto";
        //         }, 500);
        //     },
        // }); 
    }

    showOrHideModalDelete = () => {
        this.setState({
            deleteModal: !this.state.deleteModal
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    deleteDocumentLogOK = async () => {
        const deleteDocumentLog = {
            id: this.state.selectedDocumentLog.id
        }
        var response = await handleRequest("POST", "/DocumentLog/delete", deleteDocumentLog);
        if (response.data !== null && response.data !== undefined) {
            if (response.data === "SUCCESS") {
                this.getNodes();
                this.getNodes2();
                this.showSuccess();
                this.showOrHideModalDelete();
            }
            else {
                this.showError();
            }
        }
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    onChangeDepartmentSelect = (value) => {
        {
            var deger;
            if (value == '') {
                deger = true;
                this.setState({
                    disciplineSelects: [],
                    disciplineSelect: [],
                    users: [],
                    user: [],
                    hiddenUser: deger,
                });
            } else {
                deger = false;
                this.GetDiscipline(value);
            }
        }

        this.setState({
            hiddenDisciplineSelect: deger,
            departmentSelectId: value
        });
    };

    async GetDiscipline(value) {
        const newObj = {
            departmentIDs: value.toString(),
        }

        const responseDis = await handleRequest("POST", "/DepartmentDiscipline/getResultDepartmentDiscipline", newObj);

        if (responseDis.data.length !== 0) {
            const columnsDis = responseDis.data;
            const optionsDis = columnsDis.map(function (row) {
                return { label: row.discipline.disciplineName.toString(), value: row.discipline.id.toString() }
            })
            this.setState({
                disciplineSelects: optionsDis
            });
        } else {
            this.setState({
                disciplineSelects: [],
                disciplineSelect: [],
                users: [],
                user: [],
                hiddenUser: true,
            });
        }
    }

    handleChangeDescription = async (e) => {
        e.preventDefault();
        this.setState({
            description: e.target.value
        });

        if (Boolean(e.target.value)) {
            const kntDescription = {
                description: e.target.value
            };

            var response = await handleRequest("POST", "/DocumentLog/KontrolCodeDescription", kntDescription);

            if (response.data !== null && response.data !== undefined) {
                if (response.data === "SUCCESS") {
                    this.setState({
                        hiddenDescriptionKnt: true
                    });
                } else if (response.data === "WARNING") {
                    this.setState({
                        hiddenDescriptionKnt: false
                    });
                }
                else {
                    this.showError("An error was occured please try again later !");
                }
            } else {
                this.showError("An error was occured please try again later !");
            }
        } else {
            this.setState({
                hiddenDescriptionKnt: true
            });
        }
    };

    handleChangeDocCode = async (e) => {
        e.preventDefault();
        this.setState({
            code: e.target.value
        });

        if (Boolean(e.target.value)) {
            const kntCode = {
                code: e.target.value
            };

            var response = await handleRequest("POST", "/DocumentLog/KontrolCodeDescription", kntCode);

            if (response.data !== null && response.data !== undefined) {
                if (response.data === "SUCCESS") {
                    this.setState({
                        hiddenDocCodeKnt: true
                    });
                } else if (response.data === "WARNING") {
                    this.setState({
                        hiddenDocCodeKnt: false
                    });
                }
                else {
                    this.showError("An error was occured please try again later !");
                }
            } else {
                this.showError("An error was occured please try again later !");
            }
        } else {
            this.setState({
                hiddenDocCodeKnt: true
            });
        }
    };

    showOrHideModalITPCreateDoc = () => {
        this.setState({
            modalITPCreateDoc: !this.state.modalITPCreateDoc,
            createDocument: 1,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalMaintenancePlanCreateDoc = () => {
        this.setState({
            modalMaintenancePlanCreateDoc: !this.state.modalMaintenancePlanCreateDoc,
            createDocument: 1,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalMaintenanceChecklistCreateDoc = () => {
        this.setState({
            modalMaintenanceChecklistCreateDoc: !this.state.modalMaintenanceChecklistCreateDoc,
            createDocument: 1,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalRoleDocumentCreateDoc = () => {
        this.setState({
            modalRoleDocumentCreateDoc: !this.state.modalRoleDocumentCreateDoc,
            createDocument: 1,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalChecklistCreateDoc = () => {
        this.setState({
            modalChecklistCreateDoc: !this.state.modalChecklistCreateDoc,
            createDocument: 1,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalEditDocument = () => {
        this.setState({
            modalEditDocument: !this.state.modalEditDocument,
            createDocument: 1,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalWPSCreateDoc = () => {
        this.setState({
            modalWPSCreateDoc: !this.state.modalWPSCreateDoc,
            createDocument: 1,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    showOrHideModalNMRCreateDoc = () => {
        this.setState({
            modalNMRCreateDoc: !this.state.modalNMRCreateDoc,
            createDocument: 1,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    handleExcelData = async (excelJson) => {

        var list = [];
        if (excelJson === null)
            this.showError("Select an excel file");
        else {
            var currentList = excelJson.filter(p => p.Status == "Current");
            var supersededList = excelJson.filter(p => p.Status == "Superseded");

            currentList.forEach(element => {

                const obj = {

                    departmentId: 55,//30, //55
                    documentTypeId: 71,//5,//71
                    disciplineId: 29,
                    code: element["Document Code"],
                    description: element["Document Description"],
                    date: moment(element[" Received Date"]).toDate(),
                    revision: element["Revision no"],
                    sheet: element["Sheet"],
                    revisions: []

                }

                let revList = supersededList.filter(p => p["Document Code"] === element["Document Code"])

                if (Boolean(revList))
                    revList.forEach(rev => {
                        const altObj = {

                            departmentId: 55,//30, //55
                            documentTypeId: 71,//5,//71
                            disciplineId: 29,
                            code: rev["Document Code"],
                            description: rev["Document Description"],
                            date: moment(rev[" Received Date"]).toDate(),
                            revision: rev["Revision no"],
                            sheet: rev["Sheet"],

                        }
                        obj.revisions.push(altObj);

                    });
                list.push(obj);

            });

            if (Boolean(list) && list.length > 0) {

                var response = await handleRequest("POST", "/DocumentLog/import", list);
                if (response.data) {

                    if (response.data === "ERROR") {
                        this.showError("An error was occured !");
                    }
                    if (response.data === "SUCCESS") {
                        this.getNodes();
                        this.getNodes2();
                        this.createNew();
                        this.showSuccess();

                    }

                }

            } else this.showError("An error was occured about list");
        }
    }

    dCodeBodyTemplate(rowData) {

        if (Boolean(rowData.data.filePath) && rowData.data.filePath.length>0) {
            return (
                <React.Fragment>
                    <div style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        fontWeight: "bold",
                    }}
                        onClick={(e) => this.showVoucherReport(rowData.data)}>
                        {rowData.data.code}
                    </div>
                </React.Fragment>
            );
        } else {
            if (rowData.data.code === null)
                return "";
            else
                return rowData.data.code;
        }
    }

    showVoucherReport = async (rowData) => {
        try {
            const fileRealUrl = rowData.filePath;

            this.setState({
                pdfFile: fileRealUrl,
                modalPdfViewer: true,
            });

        } catch (error) {
            alert("File cant found.");
        }
    }

    showOrHideModalPdfViewer = () => {
        this.setState({
            modalPdfViewer: !this.state.modalPdfViewer,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        }, 500);
    }

    printPdf = () => {
        window.open(this.state.pdfFile);
    }

    render() {

        const props = {
            action: `${API_BASE}/File/upload`,
            listType: 'picture',
            defaultFileList: [...this.state.fileList],
        };

        let actionHeader = <Button type="button" icon="pi pi-cog"></Button>;

        let optionsCustodianDepartment = this.state.departmentCustodians.map((data) =>
            <option
                key={data.id}
                value={data.name}
            >
                {data.name}
            </option>
        );

        let optionsParentDocument = this.state.parentDocument.map((data) =>
            <option
                key={data.id}
                value={data.name}>
                {data.name}
            </option>
        );

        let optionsDisciplineCustodian = this.state.disciplineCustodians.map((data) =>
            <option
                key={data.id}
                value={data.name}>
                {data.name}
            </option>
        );

        const { SHOW_PARENT } = TreeSelect;

        const tProps = {
            treeData: this.state.treeData,
            onChange: this.onChangeDepartmentSelect,
            treeCheckable: true,
            showCheckedStrategy: SHOW_PARENT,
            value: this.state.departmentSelectId,
            placeholder: 'Select Distribution',
            style: {
                width: '100%',
            },
        };

        return (


            <div>

                <Modal
                    size="xl"
                    show={this.state.modalPdfViewer}
                    onHide={this.showOrHideModalPdfViewer}
                    backdrop="static"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            PDF Report Viewer
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ textAlign: "right", marginBottom: "1rem" }}>
                            <ButtonReact style={{ marginLeft: "1rem" }}
                                onClick={this.printPdf}
                            >
                                Print
                            </ButtonReact>
                            <FacebookShareButton style={{ marginLeft: "1rem" }} url={this.state.pdfFile} quote="PDF File Link">
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <EmailShareButton style={{ marginLeft: "1rem" }} url={this.state.pdfFile} body="PDF File Link is here: " subject="PDF File Link">
                                <EmailIcon size={32} round />
                            </EmailShareButton>
                            <WhatsappShareButton
                                style={{ marginLeft: "1rem" }}
                                url={this.state.pdfFile}
                                title="PDF File Link"
                                separator=": "
                            >
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                        </div>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js">
                            <div style={{ height: "900px" }}>
                                <Viewer fileUrl={this.state.pdfFile} layout={layout} />
                            </div>
                        </Worker>
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonReact
                            variant="secondary"
                            onClick={this.showOrHideModalPdfViewer}
                        >
                            Close
                        </ButtonReact>
                    </Modal.Footer>
                </Modal>

                <div style={{ padding: "4px 16px 4px 24px" }}>
                    <Row>
                        <Col xs={2}>
                            <ExcelReader onCreateJson={this.handleExcelData.bind(this)}></ExcelReader>
                        </Col>

                    </Row>
                </div>
                <Card>

                    <Modal
                        show={this.state.modalMessage}
                        onHide={this.showOrHideModalMessage}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered >

                        <Modal.Header style={{ backgroundColor: "#f3f3f3" }} closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <Row >
                                    <Col sm="12">
                                        {this.state.messageTip == "Success" &&
                                            <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                        {this.state.messageTip == "Warning" &&
                                            <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                        {this.state.messageTip == "Error" &&
                                            <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                    </Col>
                                </Row>
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body style={{ backgroundColor: "#f3f3f3" }}>
                            <Row >
                                <Col sm="12">
                                    {this.state.message}
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer style={{ backgroundColor: "#f3f3f3" }}>
                            <Button style={{ color: "#646c9a", backgroundColor: "#ffffff", borderColor: "#ffffff" }} variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalITPCreateDoc}
                        onHide={this.showOrHideModalITPCreateDoc}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        size="xl">

                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {this.state.createDocumentButton != "Amend Document" ? "Creating New Itp" : "Amendment"}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ItpLog id={this.state.id} />
                        </Modal.Body>
                    </Modal>

                    <Modal
                        show={this.state.modalChecklistCreateDoc}
                        onHide={this.showOrHideModalChecklistCreateDoc}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        size="xl"
                    >

                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {this.state.createDocumentButton != "Amend Document" ? "Creating New Checklist" : "Amendment"}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ChecklistLog id={this.state.id} />
                        </Modal.Body>
                    </Modal>

                    <Modal
                        show={this.state.modalEditDocument}
                        onHide={this.showOrHideModalEditDocument}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        size="xl"
                    >

                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {/* {this.state.createDocumentButton!="Amend Document" ? "Creating New Checklist" : "Amendment"}  */}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <TextToPara id={this.state.id} />
                        </Modal.Body>
                    </Modal>

                    <Modal
                        show={this.state.modalWPSCreateDoc}
                        onHide={this.showOrHideModalWPSCreateDoc}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        size="xl">

                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {/* {this.state.createDocumentButton!="Amend Document" ? "Creating New Itp" : "Amendment"}  */}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Wps id={this.state.id} />
                        </Modal.Body>
                    </Modal>

                    <Modal
                        show={this.state.modalNMRCreateDoc}
                        onHide={this.showOrHideModalNMRCreateDoc}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        size="xl">

                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {"Non-Material Requirement"}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Nmr id={this.state.id} />
                        </Modal.Body>
                    </Modal>

                    <Modal
                        show={this.state.deleteModal}
                        onHide={this.showOrHideModalDelete}
                        aria-labelledby="contained-modal-title-vcenter"
                        backdrop="static"
                        centered >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p style={{ textAlign: "center", color: "black" }}>
                                <FormattedMessage id="DeleteMessage1" defaultMessage="Do you want to delete this Data?" />
                            </p>
                            <p style={{ textAlign: "center", color: "black" }}>
                                <FormattedMessage id="DeleteMessage2" defaultMessage="This process will delete all related datas." />
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <ButtonReact variant="secondary" onClick={this.showOrHideModalDelete}>
                                <FormattedMessage id="GeneralTextClose" defaultMessage="Close" />
                            </ButtonReact>
                            <ButtonReact variant="danger" onClick={this.deleteDocumentLogOK}>
                                <FormattedMessage id="GeneralButtonDelete" defaultMessage="Delete" />
                            </ButtonReact>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={this.state.modalMaintenancePlanCreateDoc}
                        onHide={this.showOrHideModalMaintenancePlanCreateDoc}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        size="xl">

                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {this.state.createDocumentButton != "Amend Document" ? "Creating New Maintenance Plan" : "Amendment"}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <MaintenancePlan id={this.state.id} />
                        </Modal.Body>
                    </Modal>

                    <Modal
                        show={this.state.modalMaintenanceChecklistCreateDoc}
                        onHide={this.showOrHideModalMaintenanceChecklistCreateDoc}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        size="xl">

                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {this.state.createDocumentButton != "Amend Document" ? "Creating New Maintenance Checklist" : "Amendment"}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <MaintenanceChecklist id={this.state.id} />
                        </Modal.Body>
                    </Modal>

                    <Modal
                        show={this.state.modalRoleDocumentCreateDoc}
                        onHide={this.showOrHideModalRoleDocumentCreateDoc}
                        backdrop="static"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        size="xl">

                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {this.state.createDocumentButton != "Amend Document" ? "Creating New Role Descripiton" : "Amendment"}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <RoleDocumentItem id={this.state.id} />
                        </Modal.Body>
                    </Modal>

                    <div>
                        <Row>
                            <Col sm="9">
                            </Col>
                            <Col sm="2">
                                <Button hidden={this.state.hiddenCreateNewButton} style={{ width: '100%' }} id='create-new' icon="pi pi-plus" onClick={this.createNew} className={this.state.createNewButtonVariant} label={this.state.createNewButton} />
                            </Col>
                        </Row>
                    </div>
                    <br></br>
                    <div hidden={this.state.hiddenCreateNew}>
                        <Form>
                            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} controlId="formGriddocumenttype">
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Document Type
                        </Form.Label>
                                <Col sm="3">
                                    <Cascader
                                        style={{ width: "100%" }}
                                        options={this.state.dtNodes}
                                        value={this.state.values}
                                        onChange={this.onChangeDocType} changeOnSelect />
                                </Col>
                                <Col sm="2">
                                    <AntdButton onClick={this.showModal} type="primary" style={{ width: "100%", marginTop: "0.2rem" }}>Add New</AntdButton>
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginTop: '2rem', marginBottom: '1rem' }} as={Row} controlId="formParentDocument">
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Parent Document:
                    </Form.Label>
                                <Col sm="3">
                                    <Form.Control name="parentIdValue" value={this.state.parentIdValue}
                                        onChange={this.handleChangeParentDocument} as="select"  >
                                        <option value="">
                                            Parent Document
                                </option>
                                        {optionsParentDocument}
                                    </Form.Control>
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDocumentDescription">
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Document Description
                        </Form.Label>
                                <Col sm="3">
                                    <InputText readOnly={this.state.id > 0} style={{ width: '100%' }} onChange={this.handleChangeDescription} value={this.state.description} placeholder="Document Description" />
                                </Col>
                                <Col sm="4">
                                </Col>

                            </Form.Group>

                            <Form.Group hidden={this.state.hiddenDescriptionKnt} style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDocCode">
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'red' }} column sm="10">
                                    The Document Description you want to add has been used before. Please use a different Document Description !
                    </Form.Label>
                            </Form.Group>

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDocCode">
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Doc Code
                        </Form.Label>
                                <Col sm="3">
                                    <InputText readOnly={this.state.id > 0} style={{ width: '100%' }} onChange={this.handleChangeDocCode} value={this.state.code} placeholder="Doc Code" />
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>

                            <Form.Group hidden={this.state.hiddenDocCodeKnt} style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDocCode">
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'red' }} column sm="10">
                                    The Doc Code you want to add has been used before. Please use a different Doc Code !
                    </Form.Label>
                            </Form.Group>

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDepartment">
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Custodian Department
                        </Form.Label>
                                <Col sm="3">
                                    <Form.Control name="departmentCustodian" value={this.state.departmentCustodian}
                                        onChange={this.handleChange} as="select"  >

                                        <option value="">
                                            Select Department
                                </option>
                                        {optionsCustodianDepartment}
                                    </Form.Control>
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="1">
                                    Discipline
                        </Form.Label>
                                <Col sm="3">
                                    <Form.Control name="disciplineCustodian" value={this.state.disciplineCustodian}
                                        onChange={this.handleChangeDis} as="select"  >

                                        <option value="">
                                            Nill
                                </option>
                                        {optionsDisciplineCustodian}
                                    </Form.Control>
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDate">
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Date
                        </Form.Label>
                                <Col sm="3">
                                    <Calendar style={{ width: '100%' }} dateFormat="dd/mm/yy" value={this.state.date} onChange={(e) => this.setState({ date: e.value })} monthNavigator={true} yearNavigator={true} yearRange="1000:3000" />
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridRevNo">
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Rev No
                        </Form.Label>
                                <Col sm="3">
                                    <InputNumber min={0} readOnly={Boolean(this.state.id)} style={{ width: '100%' }} onChange={(e) => this.setState({ revno: e })} value={this.state.revno} placeholder="Rev No" />
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDepartment">
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Distribution
                        </Form.Label>
                                <Col sm="3">

                                    <TreeSelect {...tProps} />

                                </Col>
                            </Form.Group>

                            <Form.Group hidden={this.state.hiddenDisciplineSelect} style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDisciplineSelect">
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    Discipline
                        </Form.Label>
                                <Col sm="3">

                                    <MultiSelect className="p-column-filter" value={this.state.disciplineSelect}
                                        options={this.state.disciplineSelects} onChange={this.onDisciplineChange} />
                                </Col>
                            </Form.Group>

                            <Form.Group hidden={this.state.hiddenUser} style={{ marginBottom: '1rem' }} as={Row} controlId="formGridUser">
                                <Col sm="2">
                                </Col>
                                <Form.Label style={{ color: 'black' }} column sm="2">
                                    User
                        </Form.Label>
                                <Col sm="3">
                                    <MultiSelect className="p-column-filter" value={this.state.user}
                                        options={this.state.users} onChange={this.onUserChange} />
                                </Col>
                            </Form.Group>

                            <Form.Group style={{ marginBottom: '1rem' }} as={Row} controlId="formGridUser">
                                <Col sm="4">
                                </Col>
                                <Col sm="3">
                                    <Upload {...props}
                                        showUploadList={true}
                                        fileList={this.state.FileUpload}
                                        onChange={({ fileList }) => {
                                            if (fileList.length == 1) {
                                                var filePath = "";
                                                if (Boolean(fileList[0].response)) {
                                                    filePath = fileList[0].response.url;
                                                    this.showSuccess("file uploaded successfully");
                                                    // fileName: info.file.name, 
                                                }
                                                this.setState({
                                                    FileUpload: fileList,
                                                    filePath: filePath,
                                                });
                                            }
                                        }}
                                        beforeUpload={(fileList) => {
                                            if (this.state.FileUpload.length > 0) {
                                                this.showError("You have been already choose a file! For change firstly remove the uploaded file.");
                                                return false;
                                            }
                                            return true;
                                        }}
                                        onRemove={({ fileList }) => {
                                            this.setState({
                                                FileUpload: [],
                                                filePath: "",
                                            })
                                        }
                                        }>
                                        <AntdButton style={{ width: "100%", marginTop: "0.2rem" }}>
                                            <UploadOutlined />Upload File
                            </AntdButton>
                                    </Upload>
                                </Col>
                                <Col sm="3">
                                    <AntdButton type="default" hidden={this.state.hiddenCreateITP}
                                        onClick={this.showOrHideModalITPCreateDoc}>{this.state.createDocumentButton}</AntdButton>

                                    <AntdButton type="default" hidden={this.state.hiddenCreateChecklist}
                                        onClick={this.showOrHideModalChecklistCreateDoc}>{this.state.createDocumentButton}</AntdButton>

                                    <AntdButton type="default" hidden={this.state.hiddenEditDocument}
                                        onClick={this.showOrHideModalEditDocument}>{this.state.createDocumentButton}</AntdButton>

                                    <AntdButton type="default" hidden={this.state.hiddenCreateWPS}
                                        onClick={this.showOrHideModalWPSCreateDoc}>{this.state.createDocumentButton}</AntdButton>

                                    <AntdButton type="default" hidden={this.state.hiddenCreateNMR}
                                        onClick={this.showOrHideModalNMRCreateDoc}>{this.state.createDocumentButton}</AntdButton>

                                    <AntdButton type="default" hidden={this.state.hiddenCreateMaintenancePlan}
                                        onClick={this.showOrHideModalMaintenancePlanCreateDoc}>{this.state.createDocumentButton}</AntdButton>

                                    <AntdButton type="default" hidden={this.state.hiddenCreateMaintenanceChecklist}
                                        onClick={this.showOrHideModalMaintenanceChecklistCreateDoc}>{this.state.createDocumentButton}</AntdButton>

                                    <AntdButton type="default" hidden={this.state.hiddenCreateRoleDocument}
                                        onClick={this.showOrHideModalRoleDocumentCreateDoc}>{this.state.createDocumentButton}</AntdButton>

                                </Col>
                            </Form.Group>
                        </Form>

                        <div hidden={this.state.hiddenSave}>
                            <Row>
                                <Col sm="2">
                                </Col>
                                <Col sm="5">
                                    <Button style={{ width: '100%' }} id='save-rolegroup' className="p-button-success" onClick={this.saveDocumentLog} label="Save" />
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Row>
                        </div>

                        <div hidden={this.state.hiddenUpdate}>
                            <Row>
                                <Col sm="2">
                                </Col>
                                <Col sm="2">
                                    <Button style={{ width: '100%' }} id='cancel-rolegroup' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />
                                </Col>
                                <Col sm="3">
                                    <Button style={{ width: '100%' }} id='update-rolegroup' onClick={this.updateDone} className="p-button-warning" label="Update" />
                                </Col>
                                <Col sm="3">
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <br></br>

                    <div className="content-section implementation">
                        <TreeTable value={this.state.documentTreeTable}>
                            {/**<Column field="id" style={{ textAlign: 'center', display: 'none' }} header="Document Log Id" />
                            <Column field="documentTypeId" style={{ textAlign: 'center', display: 'none' }} header="Document Type Id" />**/}
                            <Column field="documentType" header="Document Type" expander filter sortable></Column>
                            <Column field="description" header="Document Description" filter sortable></Column>
                            <Column field="department.departmentName" header="Custodian Department" filter ></Column>
                            <Column field="discipline" header="Discipline" filter sortable></Column>
                            <Column body={this.dCodeBodyTemplate} header="Doc. Code" filter></Column>
                            <Column body={this.renderDate} header="Date" filter ></Column>
                            <Column body={this.renderRevno} header="Rev. No." filter ></Column>
                            <Column body={this.renderStatus} header="Status" filter ></Column>
                            <Column field="staffNames" style={{ textAlign: 'center' }} header="Users" filter sortable />
                            <Column body={this.renderDownloadButton} header="File" filter ></Column>
                            <Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />
                        </TreeTable>
                    </div>

                </Card>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentLog)