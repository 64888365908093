/* eslint-disable */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from "react-intl";
import MNDTableSettings from '../MND/MNDTableSettings';
import 'primereact/resources/themes/nova-accent/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import { Card } from 'react-bootstrap';
import { Container, Col, Form, Row, Modal } from 'react-bootstrap';
import { Button as PrimeButton } from 'primereact/button';
import { Button as ReactButton } from 'react-bootstrap';
import { InputText } from "primereact/inputtext";
import "react-toggle/style.css";
import { handleRequest } from '../../redux/config/env';
import "../settings/css/DataTable.scss";
import { InputNumber, Select, Divider, Input, Checkbox, Table, Cascader, Button as AntButton } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import CustomMaterialMenuDeleteEdit from '../settings/ForComponents/CustomMaterialMenuDeleteEdit';
import CustomMaterialMenuEdit from '../settings/ForComponents/CustomMaterialMenuEdit';
import CustomMaterialMenuDelete from '../settings/ForComponents/CustomMaterialMenuDelete';

const { Option } = Select;

const dataList = [];
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i];

    dataList.push({ key: node.value, lineage: node.data.lineage });
    if (node.children) {
      generateList(node.children);
    }
  }
};

const expandable = { expandedRowRender: record => <b>{record.id}</b> };
const title = () => 'Here is title';
var footer = () => 'Here is footer';

class StockSectionLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenSave: false,
      hiddenDataGiris: true,
      hiddenStockSection:false,
      hiddenDataGirisSelf: true,
      hiddenCreateNewButtonSelf:false,
      hiddenDataGirisRock: true,
      hiddenUpdate: true,
      hiddenSelfs: true,
      disabledSelf:false,
      hiddenRocks: true,
      disabledRocks:false,
      createNewButtonVariant: "info",
      createNewButton: "Create New",
      createNewButtonSelfVariant: "info",
      createNewButtonSelf: "Create New",
      createNewButtonRockVariant: "info",
      createNewButtonRock: "Create New",
      id: -1,
      parentId: "",
      location: "",
      sectionDescription: "",
      sectionCode: "",
      purposeUseId: "",
      shelfNo: "",
      heightSelfs: "",
      heightTipSelfs: "",
      widhtSelfs: "",
      widhtTipSelfs: "",
      depthSelfs: "",
      depthTipSelfs: "",
      northingSelfs: "",
      eastingSelfs: "",
      elevationSelfs: "",
      rockNo: "",
      heightRock: "",
      heightTipRock: "",
      widhtRock: "",
      widhtTipRock: "",
      depthRock: "",
      depthTipRock: "",
      northingRock: "",
      eastingRock: "",
      elevationRock: "",
      materialSelect: [],
      materialNames: [],
      optionMaterialSelects: [],
      StockSectionLogs: [],
      Locations: [],
      PurposeUses: [],

      dynamicAttributes: [],
      dynamicInputs: [],
      authorizations: [],
      table: [],
      dtNodes: [],
      values: [],
      stockSectionShelfNos:[],
      stockSectionRockNos:[],
      hiddenRockNoKnt:true,
      hiddenSelfNoKnt:true,
      locationAck:"",
      purposeUseAck:"",
    };

    this.actionTemplate = this.actionTemplate.bind(this);
    this.actionTemplateSelf = this.actionTemplateSelf.bind(this);
    this.actionTemplateRock = this.actionTemplateRock.bind(this);

    this.actionTemplateSelfLst = this.actionTemplateSelfLst.bind(this);
    this.actionTemplateRockLst = this.actionTemplateRockLst.bind(this);
  }

  componentDidMount = async () => {
    const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "StockSectionLog" });
    if (responseDP.data.length > 0) {
      this.setState({
        dynamicAttributes: responseDP.data
      });
    } else {
      this.setState({
        dynamicAttributes: []
      });
    }

    const obj = {
      pageName: "StockSectionLog",
    };
    const responseDynamicInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
    if (responseDynamicInputs.data.length !== 0) {
      this.setState({
        dynamicInputs: responseDynamicInputs.data,
      });
    } else {
      this.setState({
        dynamicInputs: [],
      });
    }

    const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "StockSectionLog");
    try {
      this.setState({
        authorizations: this.props.auth.user.authList.filter(p => (Boolean(p.dynamicAction) && p.dynamicAction.dynamicMenuId === menuObj.menuId) || p.menuId === menuObj.menuId || p.form === "All")
      });
    } catch (error) {
    }
    this.restartTable();
    this.getPurposeToUse();
    this.getCodesLocation();
    this.getTableProperties();
  };

  restartTable = async () => {
    const response = await handleRequest("GET", "/StockSectionLog/getTreeStockSectionLogs");
    if (response.data.length === 0) {
      this.setState({
        StockSectionLogs: [],
        StockSectionLogKayit:0,
      });
    }

    if (response.data.length !== 0) {
      response.data.forEach(record => {
        this.deleteChildren(record);
      });
      this.setState({
        StockSectionLogs: response.data,
        StockSectionLogKayit: response.data.length,
      });
    }
    this.getNodes();
  };
  
  getStockSectionShelfNo = async (stockSectionLogId) => { 
    if(stockSectionLogId==null || stockSectionLogId==undefined && stockSectionLogId==0){
      stockSectionLogId=-1;
    }
    var newObj={
      stockSectionLogId: stockSectionLogId,
    }
    const response = await handleRequest("POST", "/StockSectionShelfNo/getStockSectionShelfNoResult", newObj);
    if (response.data.length === 0) {
      this.setState({
        stockSectionShelfNos: [],
        ShelfNoKayit:0,
        hiddenSelfs:true,
        hiddenRocks:true,
        disabledSelf:false,
      });
    }
    if (response.data.length !== 0) {
      this.setState({
        stockSectionShelfNos: response.data,
        ShelfNoKayit: response.data.length,
        hiddenSelfs:false,
        disabledSelf:true,
      });
    }
  };

  getStockSectionRockNo = async (stockSectionShelfId, row) => {
    if(stockSectionShelfId==null || stockSectionShelfId==undefined && stockSectionShelfId==0){
      stockSectionShelfId=-1;
    }
    var newObj={
      stockSectionShelfId: stockSectionShelfId,
    }
    const response = await handleRequest("POST", "/StockSectionRockNo/getStockSectionRockNoResult", newObj);
    if (response.data.length === 0) {
      this.setState({
        stockSectionRockNos: [],
        RockNoKayit:0,
        hiddenRocks:true,
        disabledRocks:false,
      });
      if(row!=null){
        row.stockSectionRockNos=[];
      }
    }
    if (response.data.length !== 0) {
      this.setState({
        stockSectionRockNos: response.data,
        RockNoKayit:response.data.length,
        hiddenRocks:false,
        disabledRocks:true,
      });
      if(row!=null){
        row.stockSectionRockNos=response.data;
      }
    }
  };

  getTableProperties = async () => {
    this.StockSectionLog = JSON.parse(localStorage.getItem('StockSectionLog'));
    if (this.StockSectionLog != null && this.StockSectionLog != undefined && this.StockSectionLog != "") {

      await this.setState({
        bordered: this.StockSectionLog.bordered,
        title: this.StockSectionLog.titleDrm == true ? title : undefined,
        titleDrm: this.StockSectionLog.titleDrm,
        showHeader: this.StockSectionLog.showHeader,
        footer: this.StockSectionLog.footerDrm == true ? footer : undefined,
        footerDrm: this.StockSectionLog.footerDrm,
        expandable: this.StockSectionLog.expandableDrm == true ? expandable : {},
        expandableDrm: this.StockSectionLog.expandableDrm,
        rowSelection: this.StockSectionLog.rowSelection,
        ellipsis: this.StockSectionLog.ellipsis,
        tableLayout: this.StockSectionLog.tableLayout,
        size: this.StockSectionLog.size,
        top: this.StockSectionLog.top,
        bottom: this.StockSectionLog.bottom,
        editBgColor: this.StockSectionLog.editBgColor,
        deleteBgColor: this.StockSectionLog.deleteBgColor,
        selectedNodeKey: null,
        yScroll: this.StockSectionLog.yScroll,
        xScroll: this.StockSectionLog.xScroll,
        paginationDisabled: {
          defaultPageSize: 5, total: this.state.StockSectionLogs.length,
          showQuickJumper: true, showSizeChanger: true,
          pageSizeOptions: ['5', '10', '25', '50'], total: this.state.StockSectionLogs.length, showQuickJumper: true,
          position: [this.StockSectionLog.top, this.StockSectionLog.bottom]
        },
        kontrol: true,
      });
    } else {
      await this.setState({
        bordered: false,
        title: undefined,
        titleDrm: false,
        showHeader: true,
        footer: undefined,
        footerDrm: false,
        expandable: false,
        expandableDrm: false,
        rowSelection: undefined,
        ellipsis: false,
        tableLayout: undefined,
        size: 'default',
        top: 'none',
        bottom: 'bottomRight',
        editBgColor: '#fff',
        deleteBgColor: '#fff',
        selectedNodeKey: null,
        xScroll: undefined,
        paginationDisabled: false,
        kontrol: true,
      });
    }
  }

  showSuccess(msg) {
    if (!msg) {
      msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Success"
    });
  }

  showError(msg) {
    if (!msg) {
      msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Error"
    });
  }

  showWarning(msg) {
    if (!msg) {
      msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
    }
    this.setState({
      modalMessage: true,
      message: msg,
      messageTip: "Warning"
    });
  };

  showOrHideModalMessage = () => {
    this.setState({
      modalMessage: !this.state.modalMessage,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  createNewClientButton = async () => {
    if (this.state.createNewButton == "Create New") {
      this.setState({
        hiddenSave: false,
        hiddenUpdate: true,
        hiddenDataGiris: false,
        hiddenStockSection:false,
        createNewButton: "Cancel",
        lastButton: "Save",
        createNewButtonVariant: "danger",
      });
    }
    else {
      this.setState({
        hiddenSave: true,
        hiddenUpdate: false,
        hiddenDataGiris: true,
        hiddenStockSection:false,
        hiddenDataGirisSelf: true,
        hiddenDataGirisRock: true,
        createNewButton: "Create New",
        lastButton: "Save",
        createNewButtonVariant: "info",
      });
    }
    this.resetInputs();
    this.resetInputsSelf();
    this.resetInputsRock();
    this.getStockSectionShelfNo(-1);
    this.getStockSectionRockNo(-1, null);
  }

  createNewClientSelfButton = async () => {
    if (this.state.createNewButtonSelf == "Create New") {
      this.setState({
        hiddenSaveSelf: false,
        hiddenUpdateSelf: true,
        hiddenDataGirisSelf: false,
        createNewButtonSelf: "Cancel",
        createNewButtonSelfVariant: "danger", 
      });
    }
    else {
      this.setState({
        hiddenSaveSelf: true,
        hiddenUpdateSelf: false,
        hiddenDataGirisSelf: true,
        createNewButtonSelf: "Create New",
        createNewButtonSelfVariant: "info", 
      });
    }
    this.resetInputsSelf();
  }

  createNewClientRockButton = async () => {
    if (this.state.createNewButtonRock == "Create New") {
      this.setState({
        hiddenSaveRock: false,
        hiddenUpdateRock: true,
        hiddenDataGirisRock: false,
        createNewButtonRock: "Cancel",
        createNewButtonRockVariant: "danger",
      });
    }
    else {
      this.setState({
        hiddenSaveRock: true,
        hiddenUpdateRock: false,
        hiddenDataGirisRock: true,
        createNewButtonRock: "Create New",
        createNewButtonRockVariant: "info",
      });
    }
    this.resetInputsRock();
  }

  changeButtonCancel = () => {
    this.setState({
      hiddenSave: true,
      hiddenUpdate: true,
      hiddenDataGiris: true,
      hiddenStockSection:false,
      createNewButton: "Create New",
      lastButton: "Save",
      createNewButtonVariant: "info",
    });
    this.resetInputs();
  };

  changeButtonCancelSelf = () => {
    this.setState({
      hiddenSaveSelf: true,
      hiddenUpdateSelf: true,
      hiddenDataGirisSelf: true,
      createNewButtonSelf: "Create New",
      createNewButtonSelfVariant: "info",
    });
    this.resetInputsSelf();
  };

  changeButtonCancelRock = () => {
    this.setState({
      hiddenSaveRock: true,
      hiddenUpdateRock: true,
      hiddenDataGirisRock: true,
      createNewButtonRockSelf: "Create New",
      createNewButtonRockSelfVariant: "info",
    });
    this.resetInputsRock();
  };
  
  addStockSectionLog = async () => {
    if (this.state.location != null && this.state.location != undefined && this.state.location > 0 && 
      this.state.sectionDescription != null && this.state.sectionDescription != undefined && this.state.sectionDescription != "" && 
      this.state.sectionCode != null && this.state.sectionCode != undefined && this.state.sectionCode != "") {
      var newStockSectionLog = {
        parentId: this.state.parentId,
        location: this.state.location,
        sectionDescription: this.state.sectionDescription,
        sectionCode: this.state.sectionCode,
        purposeUseId: this.state.purposeUseId,
        stockSectionShelfNos:this.state.stockSectionShelfNos, 
      };

      var response = await handleRequest("POST", "/StockSectionLog/add", newStockSectionLog);
      if (response.data !== null && response.data !== undefined) {
        if (response.data === "SUCCESS") {
          this.setState({
            hiddenDataGiris: true,
            hiddenStockSection:false,
            hiddenDataGirisSelf: true,
            hiddenDataGirisRock: true,
            createNewButton: "Create New",
            lastButton: "Save",
            createNewButtonVariant: "info",
          });
          this.showSuccess();
          this.resetInputs();
          this.resetInputsSelf();
          this.resetInputsRock();
        }
        else {
          this.showError();
        }
      } else {
        this.showError();
      }
      this.restartTable();
    } else {
      this.showWarning();
    }
  }; 
  
  addStockSectionLogTable = async () => {
    if (this.state.location != null && this.state.location != undefined && this.state.location > 0 && 
      this.state.sectionDescription != null && this.state.sectionDescription != undefined && this.state.sectionDescription != "" && 
      this.state.sectionCode != null && this.state.sectionCode != undefined && this.state.sectionCode != "") {
        await this.setState({
          StockSectionLogKayit:this.state.StockSectionLogKayit+1,
        })
        var newStockSectionLog = {
          key:this.state.StockSectionLogKayit,
          index:this.state.StockSectionLogKayit,
          parentId: this.state.parentId,
          location: this.state.location,
          codeLocation: {name: this.state.locationAck},
          sectionDescription: this.state.sectionDescription,
          sectionCode: this.state.sectionCode,
          purposeUseId: this.state.purposeUseId,
          codePurposeUse: {name: this.state.purposeUseAck},
        };
        this.setState(prevState => ({
          StockSectionLogs: [...prevState.StockSectionLogs, {data: newStockSectionLog}]
        })) 

    }else{
      this.showWarning();
    }
  };

  addShelfNo = async () => {
    if (this.state.shelfNo != null && this.state.shelfNo != undefined && this.state.shelfNo != "") {
      if(this.SelfNoKontrol()){
        await this.setState({
          ShelfNoKayit:this.state.ShelfNoKayit+1,
        })
        var newStockSectionShelfNo = {
          active:true,
          key:this.state.ShelfNoKayit,
          index:this.state.ShelfNoKayit,
          stockSectionLogId: this.state.stockSectionLogId,
          shelfNo: this.state.shelfNo,
          heightSelfs: this.state.heightSelfs,
          heightTipSelfs: this.state.heightTipSelfs,
          widhtSelfs: this.state.widhtSelfs,
          widhtTipSelfs: this.state.widhtTipSelfs,
          depthSelfs: this.state.depthSelfs,
          depthTipSelfs: this.state.depthTipSelfs,
          northingSelfs: this.state.northingSelfs,
          eastingSelfs: this.state.eastingSelfs,
          elevationSelfs: this.state.elevationSelfs,
        };
        this.setState(prevState => ({
          stockSectionShelfNos: [...prevState.stockSectionShelfNos, newStockSectionShelfNo]
        }))
      }else{
        this.showWarning("The Self No you want to add has been used before. Please use a different Self No !");
      }
    } else {
      this.showWarning();
    }
  };  
  
  addRockNo = async () => {
    if (this.state.rockNo != null && this.state.rockNo != undefined && this.state.rockNo != "" &&
    this.state.shelfNo != "" && this.state.shelfNo != null && this.state.shelfNo != undefined) {
      if(this.RockNoKontrol()){
        var newStockSectionRockNo = {
          active:true,
          rockNo: this.state.rockNo,
          heightRock: this.state.heightRock,
          heightTipRock: this.state.heightTipRock,
          widhtRock: this.state.widhtRock,
          widhtTipRock: this.state.widhtTipRock,
          depthRock: this.state.depthRock,
          depthTipRock: this.state.depthTipRock,
          northingRock: this.state.northingRock,
          eastingRock: this.state.eastingRock,
          elevationRock: this.state.elevationRock,
        }; 
        await this.setState(prevState => ({
          stockSectionRockNos: [...prevState.stockSectionRockNos, newStockSectionRockNo]
        }))
        var SectionShelf = this.state.stockSectionShelfNos.find(p => p.shelfNo == this.state.shelfNo);
        if(Boolean(SectionShelf)){
          SectionShelf.stockSectionRockNos=this.state.stockSectionRockNos;
        }else{
          this.showError();
        }
      }else{
        this.showWarning("The Rock No you want to add has been used before. Please use a different Rock No !");
      } 
    } else {
      this.showWarning();
    }
  };
 
  updateDone = async () => {
    if (this.state.id != null && this.state.id != undefined && this.state.id > 0 && 
      this.state.location != null && this.state.location != undefined && this.state.location != "" &&
      this.state.sectionDescription != null && this.state.sectionDescription != undefined && this.state.sectionDescription != "" && 
      this.state.sectionCode != null && this.state.sectionCode != undefined && this.state.sectionCode != "") {
  
      var updateStockSectionLog = {
        id: this.state.id,
        parentId: this.state.parentId,
        location: this.state.location,
        sectionDescription: this.state.sectionDescription,
        sectionCode: this.state.sectionCode,
        purposeUseId: this.state.purposeUseId,
        stockSectionShelfNos:this.state.stockSectionShelfNos, 
      };

      var response = await handleRequest("POST", "/StockSectionLog/update", updateStockSectionLog);

      if (response.data !== null && response.data !== undefined) {
        if (response.data == "SUCCESS") {
          this.createNewClientButton();

          this.showSuccess();
          this.restartTable();
          this.resetInputs();
          this.resetInputsSelf();
          this.resetInputsRock();
        }
        else {
          this.showError();
        }
      }
      else {
        this.showError();
      }
    }
    else {
      this.showWarning();
    }
  };

  updateDoneSelf = async () => {
    if (this.state.shelfNo != "" && this.state.shelfNo != null && this.state.shelfNo != undefined) {
      if(this.SelfNoKontrol()){ 
        var SectionShelf = this.state.stockSectionShelfNos.filter(p => p.key == this.state.stockSectionShelfId);
        SectionShelf.forEach(element => {
          element.shelfNo= this.state.shelfNo;
          element.heightSelfs= this.state.heightSelfs;
          element.heightTipSelfs= this.state.heightTipSelfs;
          element.widhtSelfs= this.state.widhtSelfs;
          element.widhtTipSelfs= this.state.widhtTipSelfs;
          element.depthSelfs= this.state.depthSelfs;
          element.depthTipSelfs= this.state.depthTipSelfs;
          element.northingSelfs= this.state.northingSelfs;
          element.eastingSelfs= this.state.eastingSelfs;
          element.elevationSelfs= this.state.elevationSelfs;
        }); 
        this.createNewClientSelfButton();
        this.resetInputsSelf();
      }else{
        this.showWarning("The Self No you want to add has been used before. Please use a different Self No !");
      } 
    }
    else {
      this.showWarning();
    }
  };

  updateDoneRock = async () => {
    if (this.state.stockSectionShelfId > 0 && this.state.stockSectionShelfId != null && this.state.stockSectionShelfId != undefined &&
      this.state.stockSectionRockId != "" && this.state.stockSectionRockId != null && this.state.stockSectionRockId != undefined) {
      if(this.RockNoKontrol()){
        var StockSectionRock= this.state.selectStockSectionRock;
        StockSectionRock.rockNo= this.state.rockNo;
        StockSectionRock.heightRock= this.state.heightRock;
        StockSectionRock.heightTipRock= this.state.heightTipRock;
        StockSectionRock.widhtRock= this.state.widhtRock;
        StockSectionRock.widhtTipRock= this.state.widhtTipRock;
        StockSectionRock.depthRock= this.state.depthRock;
        StockSectionRock.depthTipRock= this.state.depthTipRock;
        StockSectionRock.northingRock= this.state.northingRock;
        StockSectionRock.eastingRock= this.state.eastingRock;
        StockSectionRock.elevationRock= this.state.elevationRock;
        this.createNewClientRockButton();
        this.resetInputsRock();
      }else{
        this.showWarning("The Rock No you want to add has been used before. Please use a different Rock No !");
      } 
    }
    else {
      this.showWarning();
    }
  }; 
  
  updateDoneSelfLst = async () => {
    if (this.state.shelfNo != "" && this.state.shelfNo != null && this.state.shelfNo != undefined &&
    this.state.stockSectionShelfId != null && this.state.stockSectionShelfId != undefined && this.state.stockSectionShelfId > 0 &&
    this.state.stockSectionLogId != null && this.state.stockSectionLogId != undefined && this.state.stockSectionLogId > 0) {
      if(this.state.hiddenSelfNoKnt){ 
        const updateStockSectionLog = {
          id: this.state.stockSectionShelfId,
          stockSectionLogId: this.state.stockSectionLogId,
          shelfNo: this.state.shelfNo,
          heightSelfs: this.state.heightSelfs,
          heightTipSelfs: this.state.heightTipSelfs,
          widhtSelfs: this.state.widhtSelfs,
          widhtTipSelfs: this.state.widhtTipSelfs,
          depthSelfs: this.state.depthSelfs,
          depthTipSelfs: this.state.depthTipSelfs,
          northingSelfs: this.state.northingSelfs,
          eastingSelfs: this.state.eastingSelfs,
          elevationSelfs: this.state.elevationSelfs,
        };
    
        var response = await handleRequest("POST", "/StockSectionShelfNo/update", updateStockSectionLog);
        if (response.data !== null && response.data !== undefined) {
          if (response.data === "SUCCESS") {
            this.setState({
              updateModalSectionLogSelfLst: false,
            });
            this.restartTable();
            this.showSuccess();
          }
          else {
            this.showError();
          }
        } else {
          this.showError();
        }
      }else{
        this.showWarning("The Self No you want to add has been used before. Please use a different Self No !");
      } 
    }
    else {
      this.showWarning();
    }
  };

  updateDoneRockLst = async () => {
    if (this.state.stockSectionShelfId != null && this.state.stockSectionShelfId != undefined && this.state.stockSectionShelfId > 0 && 
      this.state.stockSectionRockId != null && this.state.stockSectionRockId != undefined && this.state.stockSectionRockId > 0) {
       if(this.state.hiddenRockNoKnt){ 
        const updateStockSectionLog = {
          id: this.state.stockSectionRockId,
          stockSectionShelfId: this.state.stockSectionShelfId,
          rockNo: this.state.rockNo,
          heightRock: this.state.heightRock,
          heightTipRock: this.state.heightTipRock,
          widhtRock: this.state.widhtRock,
          widhtTipRock: this.state.widhtTipRock,
          depthRock: this.state.depthRock,
          depthTipRock: this.state.depthTipRock,
          northingRock: this.state.northingRock,
          eastingRock: this.state.eastingRock,
          elevationRock: this.state.elevationRock,
        };
    
        var response = await handleRequest("POST", "/StockSectionRockNo/update", updateStockSectionLog);
        if (response.data !== null && response.data !== undefined) {
          if (response.data === "SUCCESS") {
            this.setState({
              updateModalSectionLogRockLst: false,
            });
            this.restartTable();
            this.showSuccess();
          }
          else {
            this.showError();
          }
        } else {
          this.showError();
        }
      }else{
        this.showWarning("The Self No you want to add has been used before. Please use a different Self No !");
      } 
    }
    else {
      this.showWarning();
    }
  };

  changeButtonUpdate = async (row) => {
    this.createNewClientButton(); 
 
    var deger = dataList.filter(p => p.key == row.data.parentId) 
    if (deger != null && deger != 'undefined' && deger != '') {
      this.setState({
        values: deger[0].lineage
      });
    } else {
      this.setState({
        values: []
      });
    }

    var purposeUseId = "";
    if (row.data.purposeUseId != 0) {
      purposeUseId = row.data.purposeUseId;
    }

    await this.setState({
      hiddenSave: true,
      hiddenUpdate: false,
      hiddenDataGiris: false,
      hiddenStockSection:false,
      createNewButton: "Cancel",
      createNewButtonVariant: "danger",

      id: row.data.id,
      parentId: row.data.parentId,
      location: row.data.location,
      sectionDescription: row.data.sectionDescription,
      sectionCode: row.data.sectionCode,
      purposeUseId: purposeUseId, 
    });
    this.getStockSectionShelfNo(row.data.id);
  };

  changeButtonUpdateSelf = async (row) => {
    this.createNewClientSelfButton();
 
    this.setState({
      hiddenSaveSelf: true,
      hiddenUpdateSelf: false,
      hiddenDataGirisSelf: false,
      createNewButtonSelf: "Cancel",
      createNewButtonSelfVariant: "danger",

      stockSectionShelfId: row.key,
      stockSectionLogId: row.stockSectionLogId,
      shelfNo: row.shelfNo,
      heightSelfs: row.heightSelfs,
      heightTipSelfs: row.heightTipSelfs,
      widhtSelfs: row.widhtSelfs,
      widhtTipSelfs: row.widhtTipSelfs,
      depthSelfs: row.depthSelfs,
      depthTipSelfs: row.depthTipSelfs,
      northingSelfs: row.northingSelfs,
      eastingSelfs: row.eastingSelfs,
      elevationSelfs: row.elevationSelfs,
    });
    this.getStockSectionRockNo(row.id, row);
  };

  changeButtonUpdateRock = async (row) => {
    this.createNewClientRockButton();
 
    this.setState({
      hiddenSaveRock: true,
      hiddenUpdateRock: false,
      hiddenDataGirisRock: false,
      createNewButtonRock: "Cancel",
      createNewButtonRockVariant: "danger",

      selectStockSectionRock:row,
      id: row.id,
      stockSectionRockId: row.key,
      rockNo: row.rockNo,
      heightRock: row.heightRock,
      heightTipRock: row.heightTipRock,
      widhtRock: row.widhtRock,
      widhtTipRock: row.widhtTipRock,
      depthRock: row.depthRock,
      depthTipRock: row.depthTipRock,
      northingRock: row.northingRock,
      eastingRock: row.eastingRock,
      elevationRock: row.elevationRock,
    });
  };

  changeButtonUpdateSelfLst = async (row) => {
    this.setState({
      hiddenSelfNoKnt:true,
      updateModalSectionLogSelfLst:true,
      stockSectionShelfId: row.key,
      stockSectionLogId: row.stockSectionLogId,
      shelfNo: row.shelfNo,
      heightSelfs: row.heightSelfs,
      heightTipSelfs: row.heightTipSelfs,
      widhtSelfs: row.widhtSelfs,
      widhtTipSelfs: row.widhtTipSelfs,
      depthSelfs: row.depthSelfs,
      depthTipSelfs: row.depthTipSelfs,
      northingSelfs: row.northingSelfs,
      eastingSelfs: row.eastingSelfs,
      elevationSelfs: row.elevationSelfs,
    });
  };

  changeButtonUpdateRockLst = async (row) => {
    this.setState({
      hiddenRockNoKnt:true,
      updateModalSectionLogRockLst:true,
      selectStockSectionRock:row,
      id: row.id,
      stockSectionRockId: row.key,
      stockSectionShelfId: row.stockSectionShelfId,
      rockNo: row.rockNo,
      heightRock: row.heightRock,
      heightTipRock: row.heightTipRock,
      widhtRock: row.widhtRock,
      widhtTipRock: row.widhtTipRock,
      depthRock: row.depthRock,
      depthTipRock: row.depthTipRock,
      northingRock: row.northingRock,
      eastingRock: row.eastingRock,
      elevationRock: row.elevationRock,
    });
  };

  actionTemplate(rowData) {
    if(rowData.data!=null && rowData.data!=undefined && rowData.data.id!=null && rowData.data.id!=undefined && rowData.data.id>0){
      if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
        return <React.Fragment>
          <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.deleteStockSectionLog.bind(this)}
            onEditRow={this.changeButtonUpdate.bind(this)} />
        </React.Fragment>;
      } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
        return <React.Fragment>
          <CustomMaterialMenuEdit row={rowData}
            onEditRow={this.changeButtonUpdate.bind(this)} />
        </React.Fragment>;
      }
      else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
        return <React.Fragment>
          <CustomMaterialMenuDelete row={rowData}
            onDeleteRow={this.deleteStockSectionLog.bind(this)} />
        </React.Fragment>;
      }
    }
  };

  actionTemplateSelf(rowData) {
    if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.deleteStockSectionLogSelf.bind(this)}
          onEditRow={this.changeButtonUpdateSelf.bind(this)} />
      </React.Fragment>;
    } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuEdit row={rowData}
          onEditRow={this.changeButtonUpdateSelf.bind(this)} />
      </React.Fragment>;
    }
    else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuDelete row={rowData}
          onDeleteRow={this.deleteStockSectionLogSelf.bind(this)} />
      </React.Fragment>;
    }
  };

  actionTemplateRock(rowData) {
    if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.deleteStockSectionLogRock.bind(this)}
          onEditRow={this.changeButtonUpdateRock.bind(this)} />
      </React.Fragment>;
    } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuEdit row={rowData}
          onEditRow={this.changeButtonUpdateRock.bind(this)} />
      </React.Fragment>;
    }
    else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuDelete row={rowData}
          onDeleteRow={this.deleteStockSectionLogRock.bind(this)} />
      </React.Fragment>;
    }
  };

  actionTemplateSelfLst(rowData) {
    if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.deleteStockSectionLogSelfLst.bind(this)}
          onEditRow={this.changeButtonUpdateSelfLst.bind(this)} />
      </React.Fragment>;
    } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuEdit row={rowData}
          onEditRow={this.changeButtonUpdateSelfLst.bind(this)} />
      </React.Fragment>;
    }
    else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuDelete row={rowData}
          onDeleteRow={this.deleteStockSectionLogSelfLst.bind(this)} />
      </React.Fragment>;
    }
  };

  actionTemplateRockLst(rowData) {
    if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0 && this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuDeleteEdit row={rowData} onDeleteRow={this.deleteStockSectionLogRockLst.bind(this)}
          onEditRow={this.changeButtonUpdateRockLst.bind(this)} />
      </React.Fragment>;
    } else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Update") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuEdit row={rowData}
          onEditRow={this.changeButtonUpdateRockLst.bind(this)} />
      </React.Fragment>;
    }
    else if (this.state.authorizations.findIndex(p => Boolean(p.dynamicAction) && p.dynamicAction.actionKey === "Delete") >= 0) {
      return <React.Fragment>
        <CustomMaterialMenuDelete row={rowData}
          onDeleteRow={this.deleteStockSectionLogRockLst.bind(this)} />
      </React.Fragment>;
    }
  };

  showOrHideModalTable = async (tableTip) => {
    this.setState({
      modalTable: !this.state.modalTable,
      tableTip: tableTip,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  }

  deleteStockSectionLog = (rowData) => {
    this.setState({
      deleteModal: true,
      id: rowData.data.id,
    });
  };

  deleteStockSectionLogSelf = (rowData) => {
    this.setState({
      deleteModalSectionLogSelf: true,
      stockSectionShelfId: rowData.id,
    });
  };

  deleteStockSectionLogRock = (rowData) => {
    this.setState({
      deleteModalSectionLogRock: true,
      stockSectionRockId: rowData.id,
    });
  };

  deleteStockSectionLogSelfLst = (rowData) => {
    this.setState({
      deleteModalSectionLogSelfLst: true,
      stockSectionShelfId: rowData.id,
    });
  };

  deleteStockSectionLogRockLst = (rowData) => {
    this.setState({
      deleteModalSectionLogRockLst: true,
      stockSectionRockId: rowData.id,
    });
  };

  deleteStockSectionLogOK = async () => {
    const deletedStockSectionLog = {
      id: this.state.id,
    };

    var response = await handleRequest("POST", "/StockSectionLog/delete", deletedStockSectionLog);

    if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS") {
        this.setState({
          deleteModal: false,
        });
        this.restartTable();
        this.showSuccess();
      }
      else {
        this.showError();
      }
    } else {
      this.showError();
    }
  };

  deleteStockSectionSelfOK = async () => {
    if(this.state.stockSectionShelfId!=null && this.state.stockSectionShelfId!=undefined && this.state.stockSectionShelfId!="" && this.state.stockSectionShelfId>0){
      var SectionShelf = this.state.stockSectionShelfNos.find(p => p.id == this.state.stockSectionShelfId);
      if(Boolean(SectionShelf)){
        SectionShelf.active=false;

      this.setState({
        deleteModalSectionLogSelf: false,
      });
      }
    }else{
      this.showWarning();
    }
  };

  deleteStockSectionRockOK = async () => {
    if(this.state.stockSectionRockId!=null && this.state.stockSectionRockId!=undefined && this.state.stockSectionRockId!="" && this.state.stockSectionRockId>0){
      var StockSectionRockNos = this.state.stockSectionRockNos;
      var SectionRock = StockSectionRockNos.find(p => p.id == this.state.stockSectionRockId);
      if(Boolean(SectionRock)){
        SectionRock.active=false;
        var SectionShelf = this.state.stockSectionShelfNos.find(p => p.id == this.state.stockSectionShelfId);

        var SectionRockData = SectionShelf.stockSectionRockNos.find(p => p.id == this.state.stockSectionRockId);

        if(Boolean(SectionRockData)){
          SectionShelf.stockSectionRockNos=StockSectionRockNos;
        }
        this.setState({
          deleteModalSectionLogRock: false,
        });
      }else {
        this.showError();
      }
    }else{
      this.showWarning();
    }
  };

  deleteStockSectionSelfLstOK = async () => {
    const deletedStockSectionLog = {
      id: this.state.stockSectionShelfId,
    };

    var response = await handleRequest("POST", "/StockSectionShelfNo/delete", deletedStockSectionLog);
    if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS") {
        this.setState({
          deleteModalSectionLogSelfLst: false,
        });
        this.restartTable();
        this.showSuccess();
      }
      else {
        this.showError();
      }
    } else {
      this.showError();
    }
  };

  deleteStockSectionRockLstOK = async () => {
    const deletedStockSectionLog = {
      id: this.state.stockSectionRockId,
    };

    var response = await handleRequest("POST", "/StockSectionRockNo/delete", deletedStockSectionLog);
    if (response.data !== null && response.data !== undefined) {
      if (response.data === "SUCCESS") {
        this.setState({
          deleteModalSectionLogRockLst: false,
        });
        this.restartTable();
        this.showSuccess();
      }
      else {
        this.showError();
      }
    } else {
      this.showError();
    }
  };

  showOrHideModalDeleteStockSectionLog = () => {
    this.setState({
      deleteModal: !this.state.deleteModal,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  showOrHideModalDeleteStockSectionSelf = () => {
    this.setState({
      deleteModalSectionLogSelf: !this.state.deleteModalSectionLogSelf,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  showOrHideModalDeleteStockSectionRock = () => {
    this.setState({
      deleteModalSectionLogRock: !this.state.deleteModalSectionLogRock,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  showOrHideModalDeleteStockSectionSelfLst = () => {
    this.setState({
      deleteModalSectionLogSelfLst: !this.state.deleteModalSectionLogSelfLst,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  showOrHideModalDeleteStockSectionRockLst = () => {
    this.setState({
      deleteModalSectionLogRockLst: !this.state.deleteModalSectionLogRockLst,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  showOrHideModalUpdateStockSectionSelfLst = () => {
    this.setState({
      updateModalSectionLogSelfLst: !this.state.updateModalSectionLogSelfLst,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };

  showOrHideModalUpdateStockSectionRockLst = () => {
    this.setState({
      updateModalSectionLogRockLst: !this.state.updateModalSectionLogRockLst,
    });
    setTimeout(() => {
      document.body.style.overflow = "auto";
      document.body.style.paddingRight = "0px";
    }, 500);
  };
 
  handleChangeParentSectionStoreLog = (e) => {
    e.preventDefault();
    var idValue = 0;

    for (var z = 0; z < this.state.StockSectionLogs.length; z++) {
      if (this.state.StockSectionLogs[z].label === e.target.value) {
        idValue = this.state.StockSectionLogs[z].value;
      }
    }

    this.setState({
      [e.target.name]: e.target.value,
      parentId: idValue,
    });
  };

  handleChangePurposeUse = (e) => {
    var purposeFind=this.state.PurposeUses.find(p=> p.id==e);

    var purposeUseAck = "";
    if(purposeFind!=null && purposeFind!=undefined){
      purposeUseAck=purposeFind.name;
    } 

    this.setState({
      purposeUseId: e,
      purposeUseAck:purposeUseAck,
    });
  };

  handleChangeLocation = (e) => {
    var locationFind=this.state.Locations.find(p=> p.id==e);

    var locationAck = "";
    if(locationFind!=null && locationFind!=undefined){
      locationAck=locationFind.name;
    } 

    this.setState({
      location: e,
      locationAck:locationAck,
    });
  };

  resetInputs = async () => {
    await this.setState({
      id: 0,
      parentId: "",
      values: [],
      location: "",
      sectionDescription: "",
      sectionCode: "",
      purposeUseId: "",
     
     
      hiddenRocks: true,
      hiddenSelfs: true,
      disabledSelf:false,
      disabledRocks:false,
    });
  };

  resetInputsSelf = async () => {
    this.setState({
      shelfNo: "",
      heightSelfs: "",
      heightTipSelfs: "",
      widhtSelfs: "",
      widhtTipSelfs: "",
      depthSelfs: "",
      depthTipSelfs: "",
      northingSelfs: "",
      eastingSelfs: "",
      elevationSelfs: "",
      hiddenCreateNewButtonSelf:false,
    });
  }

  resetInputsRock = async () => {
    this.setState({
      rockNo: "",
      heightRock: "",
      heightTipRock: "",
      widhtRock: "",
      widhtTipRock: "",
      depthRock: "",
      depthTipRock: "",
      northingRock: "",
      eastingRock: "",
      elevationRock: "", 
    });
  }

  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleChangeRockNo = (e) => {
    e.preventDefault();
   
    var hiddenRockNoKnt=true;
    if(this.state.stockSectionRockNos!=null && this.state.stockSectionRockNos!=undefined && this.state.stockSectionRockNos.length>0 &&
      e.target.value!=null && e.target.value!=null && e.target.value!=""){
      var knt=this.state.stockSectionRockNos.filter(p=> p.rockNo.toLowerCase() == e.target.value.toLowerCase());
      if(!this.state.hiddenUpdateRock){
        if(this.state.stockSectionRockId!=null && this.state.stockSectionRockId!=null && this.state.stockSectionRockId>0){
          if(knt!=null && knt!=undefined && knt.length>0){
            knt.forEach(element => {
              if(element.id!=this.state.stockSectionRockId){
                hiddenRockNoKnt=false;
              }
            });
          }
        }else{
          hiddenRockNoKnt=false;
        }
      }else{
        if(knt!=null && knt!=undefined){
          if(knt.length>0){
            hiddenRockNoKnt=false;
          }
        }
      }
    }

    this.setState({
      [e.target.name]: e.target.value,
      hiddenRockNoKnt:hiddenRockNoKnt,
    });
  };

  handleChangeRockNoLst = async (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
   
    var hiddenRockNoKnt=true;
    if(Boolean(e.target.value)){
      const kntRockNo = {
        rockNo:  e.target.value,
        stockSectionShelfId:this.state.stockSectionShelfId,
        id: this.state.stockSectionRockId,
      };
      var response = await handleRequest("POST", "/StockSectionRockNo/KontrolRockNo", kntRockNo);
      if(response.data!==null && response.data!==undefined){
        if(response.data==="SUCCESS")
        { 
          hiddenRockNoKnt=true;
        }else if(response.data==="WARNING")
        { 
          hiddenRockNoKnt=false;
        } 
        else  {
          this.showError(); 
        }  
      }else {
        this.showError(); 
      }  
    }
    this.setState({
      hiddenRockNoKnt:hiddenRockNoKnt,
    });
  };

  RockNoKontrol = () => {
    var hiddenRockNoKnt=true;
    if(this.state.stockSectionRockNos!=null && this.state.stockSectionRockNos!=undefined && this.state.stockSectionRockNos.length>0){
      var knt=this.state.stockSectionRockNos.filter(p=> p.rockNo.toLowerCase() == this.state.rockNo.toLowerCase());
      if(!this.state.hiddenUpdateRock){
        if(this.state.stockSectionRockId!=null && this.state.stockSectionRockId!=null && this.state.stockSectionRockId>0){
          if(knt!=null && knt!=undefined && knt.length>0){
            knt.forEach(element => {
              if(element.id!=this.state.stockSectionRockId){
                hiddenRockNoKnt=false;
              }
            });
          }
        }else{
          hiddenRockNoKnt=false;
        }
      }else{
        if(knt!=null && knt!=undefined){
          if(knt.length>0){
            hiddenRockNoKnt=false;
          }
        }
      }
    }
    return hiddenRockNoKnt;
  }

  handleChangeSelfNo = (e) => {
    e.preventDefault();

    var hiddenSelfNoKnt=true;
   
    if(this.state.stockSectionShelfNos!=null && this.state.stockSectionShelfNos!=undefined && this.state.stockSectionShelfNos.length>0 &&
      e.target.value!=null && e.target.value!=null && e.target.value!=""){
      var knt=this.state.stockSectionShelfNos.filter(p=> p.shelfNo.toLowerCase() == e.target.value.toLowerCase());
      if(!this.state.hiddenUpdateSelf){
        if(this.state.stockSectionShelfId!=null && this.state.stockSectionShelfId!=null && this.state.stockSectionShelfId>0){
          if(knt!=null && knt!=undefined && knt.length>0){
            knt.forEach(element => {
              if(element.id!=this.state.stockSectionShelfId){
                hiddenSelfNoKnt=false;
              }
            });
          }
        }else{
          hiddenSelfNoKnt=false;
        }
      }else{
        if(knt!=null && knt!=undefined){
          if(knt.length>0){
            hiddenSelfNoKnt=false;
          }
        }
      }
    }
    
    this.setState({
      [e.target.name]: e.target.value,
      hiddenSelfNoKnt:hiddenSelfNoKnt,
    });
  };
  
  handleChangeSelfNoLst = async (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
   
    var hiddenSelfNoKnt=true;
    if(Boolean(e.target.value)){
      const kntShelfNo = {
        shelfNo:  e.target.value,
        stockSectionLogId: this.state.stockSectionLogId,
        id: this.state.stockSectionShelfId,
      };
      var response = await handleRequest("POST", "/StockSectionShelfNo/KontrolShelfNo", kntShelfNo);
      if(response.data!==null && response.data!==undefined){
        if(response.data==="SUCCESS")
        { 
          hiddenSelfNoKnt=true;
        }else if(response.data==="WARNING")
        { 
          hiddenSelfNoKnt=false;
        } 
        else  {
          this.showError(); 
        }  
      }else {
        this.showError(); 
      }  
    }
    this.setState({
      hiddenSelfNoKnt:hiddenSelfNoKnt,
    });
  };

  SelfNoKontrol = () => {
    var hiddenSelfNoKnt=true;
   
    if(this.state.stockSectionShelfNos!=null && this.state.stockSectionShelfNos!=undefined && this.state.stockSectionShelfNos.length>0){
      var knt=this.state.stockSectionShelfNos.filter(p=> p.shelfNo.toLowerCase() == this.state.shelfNo.toLowerCase());
      if(!this.state.hiddenUpdateSelf){
        if(this.state.stockSectionShelfId!=null && this.state.stockSectionShelfId!=null && this.state.stockSectionShelfId>0){
          if(knt!=null && knt!=undefined && knt.length>0){
            knt.forEach(element => {
              if(element.id!=this.state.stockSectionShelfId){
                hiddenSelfNoKnt=false;
              }
            });
          }
        }else{
          hiddenSelfNoKnt=false;
        }
      }else{
        if(knt!=null && knt!=undefined){
          if(knt.length>0){
            hiddenSelfNoKnt=false;
          }
        }
      }
    }
    return hiddenSelfNoKnt;
  }

  handleChangeHeightTip = (e) => {
    e.preventDefault();

    var heightTipAck = "";
    if (Boolean(e.target.value)) {
      if (e.target.value == "1") {
        heightTipAck = "Limitless";
      } else if (e.target.value == "2") {
        heightTipAck = "Cm";
      } else if (e.target.value == "3") {
        heightTipAck = "Meter";
      }
    }

    this.setState({
      [e.target.name]: e.target.value,
      heightTipAck: heightTipAck,
    });
  };

  handleChangeWidhtTip = (e) => {
    e.preventDefault();

    var widhtTipAck = "";
    if (Boolean(e.target.value)) {
      if (e.target.value == "1") {
        widhtTipAck = "Limitless";
      } else if (e.target.value == "2") {
        widhtTipAck = "Cm";
      } else if (e.target.value == "3") {
        widhtTipAck = "Meter";
      }
    }

    this.setState({
      [e.target.name]: e.target.value,
      widhtTipAck: widhtTipAck,
    });
  };

  handleChangeDepthTip = (e) => {
    e.preventDefault();

    var depthTipAck = "";
    if (Boolean(e.target.value)) {
      if (e.target.value == "1") {
        depthTipAck = "Limitless";
      } else if (e.target.value == "2") {
        depthTipAck = "Cm";
      } else if (e.target.value == "3") {
        depthTipAck = "Meter";
      }
    }

    this.setState({
      [e.target.name]: e.target.value,
      depthTipAck: depthTipAck,
    });
  };

  additemPurposeUses = async () => {
    const { itemPurposeUses } = this.state;
    if (itemPurposeUses != null && itemPurposeUses != "" && itemPurposeUses != undefined) {
      const newItem = {
        name: itemPurposeUses,
        type: "PurposeToUse"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
        itemPurposeUses: '',
      });
      this.getPurposeToUse();
    } else {
      this.showWarning();
    }
  };

  getPurposeToUse = async () => {
    const response = await handleRequest("GET", "/Code/type/PurposeToUse"); // Purpose to use

    if (response.data !== null && response.data !== undefined) {
      this.setState({
        PurposeUses: response.data
      });
    }
  }

  additemLocations = async () => {
    const { itemLocations } = this.state;
    if (itemLocations != null && itemLocations != "" && itemLocations != undefined) {
      const newItem = {
        name: itemLocations,
        type: "StocksLocation"
      }

      await handleRequest("POST", "/Code/add", newItem);
      this.setState({
        itemLocations: '',
      });
      this.getCodesLocation();
    } else {
      this.showWarning();
    }
  };

  getCodesLocation = async () => {
    const response = await handleRequest("GET", "/Code/type/StocksLocation");

    if (response.data !== null && response.data !== undefined) {
      this.setState({
        Locations: response.data
      });
    }
  }

  onChangeParent = (value, data) => {
    if (data.length > 0) {
      this.setState({
        values: value,
        parentId: data[data.length - 1].value,
      })
    } else {
      this.setState({
        values: [],
        parentId: 0,
      })
    }
  }

  getNodes = async () => {
    var response = [];
    response = await handleRequest("GET", "/StockSectionLog/getNodesStockSectionLogs");
    if (response.data.length !== 0) {
      generateList(response.data);
      this.setState({
        dtNodes: response.data
      });
    }
  }

  deleteChildren = (element) => {
    if (Boolean(element.children) && element.children.length > 0) {
      element.children.forEach(el => {
        this.deleteChildren(el);
      });
    } else {
      delete element["children"];
    }
  }

  render() {

    const columns = [ 
      {
        key: "key",
        title: <FormattedMessage id="StockSectionLogStock" defaultMessage="Stock" />,
        render: (record) => {
          if (record != null && record != undefined && record.data != null && record.data != undefined) {
            return record.data.sectionCode + "/" + record.data.sectionDescription;
          }
          return "";
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="StockSectionLogLocation" defaultMessage="Location" />,
        render: (record) => {
          if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.codeLocation != null && record.data.codeLocation != undefined) {
            return record.data.codeLocation.name;
          }
          return "";
        }
      },
      {
        key: "key",
        title: <FormattedMessage id="StockSectionLogPurposeToUse" defaultMessage="Purpose to use" />,
        render: (record) => {
          if (record != null && record != undefined && record.data != null && record.data != undefined && record.data.codePurposeUse != null && record.data.codePurposeUse != undefined) {
            return record.data.codePurposeUse.name;
          }
          return "";
        }
      },
      // {
      //   key: "key",
      //   title: <FormattedMessage id="StockSectionLogShelfNo" defaultMessage="Shelf No" />,
      //   render: (record) => {
      //     if (record != null && record != undefined && record.data != null && record.data != undefined) {
      //       return record.data.shelfNo;
      //     }
      //     return "";
      //   }
      // },
      // {
      //   key: "key",
      //   title: <FormattedMessage id="StockSectionLogShelfNo" defaultMessage="Rock No" />,
      //   render: (record) => {
      //     if (record != null && record != undefined && record.data != null && record.data != undefined) {
      //       return record.data.rockNo;
      //     }
      //     return "";
      //   }
      // },
      {
        key: "key",
        title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("StockSectionLog")} icon="pi pi-cog"></PrimeButton>,
        render: this.actionTemplate
      },
    ];

    const tableColumns = columns.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
    if (this.state.xScroll === 'fixed') {
      tableColumns[0].fixed = true;
      tableColumns[tableColumns.length - 1].fixed = 'right';
    }

    const columnsSelf = [  
      {
        dataIndex: "shelfNo",
        key: "key",
        title: <FormattedMessage id="StockSectionLogShelfNo" defaultMessage="Shelf No" />,
      },
      { 
        key: "key",
        title: <FormattedMessage id="StockSectionLogHeight" defaultMessage="Height" />,
        render: (record) => {
          if (record != null && record != undefined ) {
            if(record.heightTipSelfs==1){
              return record.heightSelfs + " Limitless";
            }else if(record.heightTipSelfs==2){
              return record.heightSelfs + " Cm";
            }else if(record.heightTipSelfs==3){
              return record.heightSelfs + " Meter";
            }else{
              return record.heightSelfs;
            }
          }
          return "";
        }
      },  
      { 
        key: "key",
        title: <FormattedMessage id="StockSectionLogWidht" defaultMessage="Widht" />,
        render: (record) => {
          if (record != null && record != undefined ) {
            if(record.widhtTipSelfs==1){
              return record.widhtSelfs + " Limitless";
            }else if(record.widhtTipSelfs==2){
              return record.widhtSelfs + " Cm";
            }else if(record.widhtTipSelfs==3){
              return record.widhtSelfs + " Meter";
            }else{
              return record.widhtSelfs;
            }
          }
          return "";
        }
      },  
      { 
        key: "key",
        title: <FormattedMessage id="StockSectionLogDepth" defaultMessage="Depth" />,
        render: (record) => {
          if (record != null && record != undefined ) {
            if(record.depthTipSelfs==1){
              return record.depthSelfs + " Limitless";
            }else if(record.depthTipSelfs==2){
              return record.depthSelfs + " Cm";
            }else if(record.depthTipSelfs==3){
              return record.depthSelfs + " Meter";
            }else{
              return record.depthSelfs;
            }
          }
          return "";
        }
      }, 
      {
        dataIndex: "northingSelfs",
        key: "key",
        title: <FormattedMessage id="StockSectionLogNorthing" defaultMessage="Northing" />,
      },
      {
        dataIndex: "eastingSelfs",
        key: "key",
        title: <FormattedMessage id="StockSectionLogEasting" defaultMessage="Easting" />,
      },
      {
        dataIndex: "elevationSelfs",
        key: "key",
        title: <FormattedMessage id="StockSectionLogElevation" defaultMessage="Elevation" />,
      },
      {
        key: "key",
        title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("StockSectionShelfNo")} icon="pi pi-cog"></PrimeButton>,
        render: this.actionTemplateSelf
      },
    ];

    const tableColumnsSelf = columnsSelf.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
    if (this.state.xScroll === 'fixed') {
      tableColumnsSelf[0].fixed = true;
      tableColumnsSelf[tableColumnsSelf.length - 1].fixed = 'right';
    }

    const columnsSelfLst = [  
      {
        dataIndex: "shelfNo",
        key: "key",
        title: <FormattedMessage id="StockSectionLogShelfNo" defaultMessage="Shelf No" />,
      },
      { 
        key: "key",
        title: <FormattedMessage id="StockSectionLogHeight" defaultMessage="Height" />,
        render: (record) => {
          if (record != null && record != undefined ) {
            if(record.heightTipSelfs==1){
              return record.heightSelfs + " Limitless";
            }else if(record.heightTipSelfs==2){
              return record.heightSelfs + " Cm";
            }else if(record.heightTipSelfs==3){
              return record.heightSelfs + " Meter";
            }else{
              return record.heightSelfs;
            }
          }
          return "";
        }
      },  
      { 
        key: "key",
        title: <FormattedMessage id="StockSectionLogWidht" defaultMessage="Widht" />,
        render: (record) => {
          if (record != null && record != undefined ) {
            if(record.widhtTipSelfs==1){
              return record.widhtSelfs + " Limitless";
            }else if(record.widhtTipSelfs==2){
              return record.widhtSelfs + " Cm";
            }else if(record.widhtTipSelfs==3){
              return record.widhtSelfs + " Meter";
            }else{
              return record.widhtSelfs;
            }
          }
          return "";
        }
      },  
      { 
        key: "key",
        title: <FormattedMessage id="StockSectionLogDepth" defaultMessage="Depth" />,
        render: (record) => {
          if (record != null && record != undefined ) {
            if(record.depthTipSelfs==1){
              return record.depthSelfs + " Limitless";
            }else if(record.depthTipSelfs==2){
              return record.depthSelfs + " Cm";
            }else if(record.depthTipSelfs==3){
              return record.depthSelfs + " Meter";
            }else{
              return record.depthSelfs;
            }
          }
          return "";
        }
      }, 
      {
        dataIndex: "northingSelfs",
        key: "key",
        title: <FormattedMessage id="StockSectionLogNorthing" defaultMessage="Northing" />,
      },
      {
        dataIndex: "eastingSelfs",
        key: "key",
        title: <FormattedMessage id="StockSectionLogEasting" defaultMessage="Easting" />,
      },
      {
        dataIndex: "elevationSelfs",
        key: "key",
        title: <FormattedMessage id="StockSectionLogElevation" defaultMessage="Elevation" />,
      },
      {
        key: "key",
        title: "",
        render: this.actionTemplateSelfLst
      },
    ];

    const tableColumnsSelfLst = columnsSelfLst.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
    if (this.state.xScroll === 'fixed') {
      tableColumnsSelfLst[0].fixed = true;
      tableColumnsSelfLst[tableColumnsSelfLst.length - 1].fixed = 'right';
    }

    const columnsRock = [  
      {
        dataIndex: "rockNo",
        key: "key",
        title: <FormattedMessage id="StockSectionLogRockNo" defaultMessage="Rock No" />,
      },
      { 
        key: "key",
        title: <FormattedMessage id="StockSectionLogHeight" defaultMessage="Height" />,
        render: (record) => {
          if (record != null && record != undefined ) {
            if(record.heightTipRock==1){
              return record.heightRock + " Limitless";
            }else if(record.heightTipRock==2){
              return record.heightRock + " Cm";
            }else if(record.heightTipRock==3){
              return record.heightRock + " Meter";
            }else{
              return record.heightRock;
            }
          }
          return "";
        }
      },  
      { 
        key: "key",
        title: <FormattedMessage id="StockSectionLogWidht" defaultMessage="Widht" />,
        render: (record) => {
          if (record != null && record != undefined ) {
            if(record.widhtTipRock==1){
              return record.widhtRock + " Limitless";
            }else if(record.widhtTipRock==2){
              return record.widhtRock + " Cm";
            }else if(record.widhtTipRock==3){
              return record.widhtRock + " Meter";
            }else{
              return record.widhtRock;
            }
          }
          return "";
        }
      },  
      { 
        key: "key",
        title: <FormattedMessage id="StockSectionLogDepth" defaultMessage="Depth" />,
        render: (record) => {
          if (record != null && record != undefined ) {
            if(record.depthTipRock==1){
              return record.depthRock + " Limitless";
            }else if(record.depthTipRock==2){
              return record.depthRock + " Cm";
            }else if(record.depthTipRock==3){
              return record.depthRock + " Meter";
            }else{
              return record.depthRock;
            }
          }
          return "";
        }
      }, 
      {
        dataIndex: "northingRock",
        key: "key",
        title: <FormattedMessage id="StockSectionLogNorthing" defaultMessage="Northing" />,
      },
      {
        dataIndex: "eastingRock",
        key: "key",
        title: <FormattedMessage id="StockSectionLogEasting" defaultMessage="Easting" />,
      },
      {
        dataIndex: "elevationRock",
        key: "key",
        title: <FormattedMessage id="StockSectionLogElevation" defaultMessage="Elevation" />,
      },
      {
        key: "key",
        title: <PrimeButton type="button" onClick={() => this.showOrHideModalTable("StockSectionRockNo")} icon="pi pi-cog"></PrimeButton>,
        render: this.actionTemplateRock
      },
    ];

    const tableColumnsRockNo = columnsRock.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
    if (this.state.xScroll === 'fixed') {
      tableColumnsRockNo[0].fixed = true;
      tableColumnsRockNo[tableColumnsRockNo.length - 1].fixed = 'right';
    }

    const columnsRockLst = [  
      {
        dataIndex: "rockNo",
        key: "key",
        title: <FormattedMessage id="StockSectionLogRockNo" defaultMessage="Rock No" />,
      },
      { 
        key: "key",
        title: <FormattedMessage id="StockSectionLogHeight" defaultMessage="Height" />,
        render: (record) => {
          if (record != null && record != undefined ) {
            if(record.heightTipRock==1){
              return record.heightRock + " Limitless";
            }else if(record.heightTipRock==2){
              return record.heightRock + " Cm";
            }else if(record.heightTipRock==3){
              return record.heightRock + " Meter";
            }else{
              return record.heightRock;
            }
          }
          return "";
        }
      },  
      { 
        key: "key",
        title: <FormattedMessage id="StockSectionLogWidht" defaultMessage="Widht" />,
        render: (record) => {
          if (record != null && record != undefined ) {
            if(record.widhtTipRock==1){
              return record.widhtRock + " Limitless";
            }else if(record.widhtTipRock==2){
              return record.widhtRock + " Cm";
            }else if(record.widhtTipRock==3){
              return record.widhtRock + " Meter";
            }else{
              return record.widhtRock;
            }
          }
          return "";
        }
      },  
      { 
        key: "key",
        title: <FormattedMessage id="StockSectionLogDepth" defaultMessage="Depth" />,
        render: (record) => {
          if (record != null && record != undefined ) {
            if(record.depthTipRock==1){
              return record.depthRock + " Limitless";
            }else if(record.depthTipRock==2){
              return record.depthRock + " Cm";
            }else if(record.depthTipRock==3){
              return record.depthRock + " Meter";
            }else{
              return record.depthRock;
            }
          }
          return "";
        }
      }, 
      {
        dataIndex: "northingRock",
        key: "key",
        title: <FormattedMessage id="StockSectionLogNorthing" defaultMessage="Northing" />,
      },
      {
        dataIndex: "eastingRock",
        key: "key",
        title: <FormattedMessage id="StockSectionLogEasting" defaultMessage="Easting" />,
      },
      {
        dataIndex: "elevationRock",
        key: "key",
        title: <FormattedMessage id="StockSectionLogElevation" defaultMessage="Elevation" />,
      },
      {
        key: "key",
        title: "",
        render: this.actionTemplateRockLst
      },
    ];

    const tableColumnsRockNoLst = columnsRockLst.map(item => ({ ...item, ellipsis: this.state.ellipsis }));
    if (this.state.xScroll === 'fixed') {
      tableColumnsRockNoLst[0].fixed = true;
      tableColumnsRockNoLst[tableColumnsRockNoLst.length - 1].fixed = 'right';
    }

    return (
      <div style={{ backgroundColor: "white", padding: "10px" }}>
        <div style={{ padding: "8px 16px 8px 24px" }}>
          <Row>
            <Col xs={8}></Col>
            <Col xs={3}>
              <ReactButton onClick={this.createNewClientButton} style={{ width: '100%' }} variant={this.state.createNewButtonVariant} >
                {this.state.createNewButton}
              </ReactButton>
            </Col>
            <Col xs={1}>
              <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable("StockSectionLog")} icon="pi pi-cog"></PrimeButton>
            </Col>
          </Row>
        </div>
        <br />
        <div hidden={this.state.hiddenDataGiris} className="col-sm-12">
          <br />
          <Form>
          <div hidden={this.state.hiddenStockSection}>
              <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                <Form.Label column sm="2">
                  Parent Stock:
                          </Form.Label>
                <Col sm="5">
                  <Cascader
                    style={{ width: "100%" }}
                    options={this.state.dtNodes}
                    value={this.state.values}
                    onChange={this.onChangeParent} changeOnSelect />
                </Col>
                <Col sm="4">
                </Col>
              </Form.Group>

              <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                <Form.Label column sm="2">
                  Location:
                </Form.Label>
                <Col sm="5">
                  <Select
                    name="location"
                    style={{ width: '100%' }}
                    placeholder="Select Location"
                    value={this.state.location}
                    onChange={this.handleChangeLocation}

                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                          <Input style={{ flex: 'auto' }} value={this.state.itemLocations}
                            onChange={(event) => { this.setState({ itemLocations: event.target.value }) }} />
                          <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                            onClick={this.additemLocations} >
                            <PlusOutlined /> Add item  </a>
                        </div>
                      </div>
                    )}
                  >
                    {this.state.Locations.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                    ))}
                  </Select>

                </Col>
                <Col sm="4">
                </Col>
              </Form.Group>
 
              <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                <Form.Label column sm="2">
                  Stock Name:
                          </Form.Label>
                <Col sm="5">
                  <InputText
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setState({
                        sectionDescription: e.target.value,
                      })
                    }
                    value={this.state.sectionDescription}
                  />
                </Col>
                <Col sm="4">
                </Col>
              </Form.Group>

              <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                <Form.Label column sm="2">
                  Stock Code:
                          </Form.Label>
                <Col sm="5">
                  <InputText
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setState({
                        sectionCode: e.target.value,
                      })
                    }
                    value={this.state.sectionCode}
                  />
                </Col>
                <Col sm="4">
                </Col>
              </Form.Group>

              <Form.Group style={{ marginBottom: "1rem" }} as={Row}>
                <Form.Label column sm="2">
                  Purpose to use:
                </Form.Label>
                <Col sm="5">
                  <Select
                    name="purposeUseId"
                    style={{ width: '100%' }}
                    placeholder="Select Purpose to use"
                    value={this.state.purposeUseId}
                    onChange={this.handleChangePurposeUse}

                    dropdownRender={menu => (
                      <div>
                        {menu}
                        <Divider style={{ margin: '4px 0' }} />
                        <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                          <Input style={{ flex: 'auto' }} value={this.state.itemPurposeUses}
                            onChange={(event) => { this.setState({ itemPurposeUses: event.target.value }) }} />
                          <a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                            onClick={this.additemPurposeUses} >
                            <PlusOutlined /> Add item  </a>
                        </div>
                      </div>
                    )}
                  >
                    {this.state.PurposeUses.map(i => (
                      <Option key={i.id} value={i.id}>{i.name}</Option>
                    ))}
                  </Select>
                </Col>
                <Col sm="1">
                  {(this.state.hiddenUpdate && this.state.hiddenSelfs) && <AntButton type="primary" onClick={this.addStockSectionLog} icon={<PlusOutlined />}></AntButton>}
                </Col>
              </Form.Group>
            </div>

              <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                <Col sm="2"></Col>
                <Col sm="5">
                  <Checkbox checked={!this.state.hiddenSelfs} disabled={this.state.disabledSelf} onChange={(e) => {
                    var hiddenSelfs = true;
                    var disabledSelf=false;
                    var hiddenStockSection=false;
                    if (e.target.checked == false) {
                      this.setState({
                        hiddenRocks: true,  
                      }); 
                    }else{ 
                      if (this.state.location > 0 && this.state.location != null && this.state.location != undefined &&
                        this.state.sectionDescription != "" && this.state.sectionDescription != null && this.state.sectionDescription != undefined &&
                        this.state.sectionCode != "" && this.state.sectionCode != null && this.state.sectionCode != undefined) {
                          hiddenSelfs = false;
                          disabledSelf=true;
                          hiddenStockSection=true;
                          if(this.state.hiddenUpdate){
                            this.addStockSectionLogTable();
                          }
                          this.setState({
                            hiddenSaveSelf: true,
                            hiddenUpdateSelf: false,
                            hiddenDataGirisSelf: true,
                            createNewButtonSelf: "Create New",
                            createNewButtonSelfVariant: "info", 
    
                            hiddenSaveRock: true,
                            hiddenUpdateRock: false,
                            hiddenDataGirisRock: true,
                            createNewButtonRock: "Create New",
                            createNewButtonRockVariant: "info",
                          });
                      }else{
                        this.showWarning();
                      }
                    }
                    this.setState({
                      hiddenSelfs: hiddenSelfs,
                      disabledSelf:disabledSelf,
                      hiddenStockSection:hiddenStockSection,
                    });
                  }} style={{ color: 'black', marginTop: '0.25rem' }}>{<FormattedMessage id="StockSectionLogIdentifyTheShelfs" defaultMessage="Identify the shelfs" />}</Checkbox>
                </Col>
                <Col sm="4">
                </Col>
              </Form.Group>

              <div hidden={this.state.hiddenSelfs} style={{ backgroundColor: "white", padding: "10px", border: '1px solid #1ab394' }}>
                <div style={{ padding: "8px 16px 8px 24px" }}>
                  <Row>
                    <Col xs={3}>
                      <div style={{color:"red"}}>
                        <FormattedMessage id="StockSectionLogSelf" defaultMessage="Self" />
                      </div>
                      </Col>
                    <Col xs={5}></Col>
                    <Col xs={3}>
                      <ReactButton hidden={this.state.hiddenCreateNewButtonSelf} onClick={this.createNewClientSelfButton} style={{ width: '100%' }} variant={this.state.createNewButtonSelfVariant} >
                        {this.state.createNewButtonSelf}
                      </ReactButton>
                    </Col>
                    <Col xs={1}>
                      <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable("StockSectionShelfNo")} icon="pi pi-cog"></PrimeButton>
                    </Col>
                  </Row>
                </div>
                <br />
                <div hidden={this.state.hiddenDataGirisSelf}>
                  <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="1"></Col>
                    <Form.Label column sm="2">
                      Shelf No
                          </Form.Label>
                    <Col sm="4">
                      <Form.Control name="shelfNo" value={this.state.shelfNo} onChange={this.handleChangeSelfNo} />
                    </Col>
                    <Col sm="3">
                    </Col>
                  </Form.Group>

                  <Form.Group hidden={this.state.hiddenSelfNoKnt} style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDocCode">
                      <Col sm="3">
                      </Col>
                      <Form.Label style={{ color: 'red' }} column sm="9">
                        The Self No you want to add has been used before. Please use a different Self No !
                      </Form.Label>
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Form.Label column sm="2" style={{ fontWeight: 'bold' }} >
                      Dimension
                        </Form.Label>
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="1"></Col>
                    <Form.Label column sm="2">
                      Height
                          </Form.Label>
                    <Col sm="4">
                      <InputNumber min={0} style={{ width: '100%' }}
                        onChange={(e) => this.setState({ heightSelfs: e })} value={this.state.heightSelfs} placeholder="Height" />
                    </Col>
                    <Col sm="3">
                      <Form.Control name="heightTipSelfs" value={this.state.heightTipSelfs}
                        onChange={this.handleChangeHeightTip} as="select"  >
                        <option value="0">
                          Select
                              </option>
                        <option value="1">
                          Limitless
                              </option>
                        <option value="2">
                          Cm
                              </option>
                        <option value="3">
                          Meter
                              </option>
                      </Form.Control>
                    </Col>
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="1"></Col>
                    <Form.Label column sm="2">
                      Widht
                          </Form.Label>
                    <Col sm="4">
                      <InputNumber min={0} style={{ width: '100%' }}
                        onChange={(e) => this.setState({ widhtSelfs: e })} value={this.state.widhtSelfs} placeholder="Widht" />
                    </Col>
                    <Col sm="3">
                      <Form.Control name="widhtTipSelfs" value={this.state.widhtTipSelfs}
                        onChange={this.handleChangeWidhtTip} as="select"  >
                        <option value="0">
                          Select
                              </option>
                        <option value="1">
                          Limitless
                              </option>
                        <option value="2">
                          Cm
                              </option>
                        <option value="3">
                          Meter
                              </option>
                      </Form.Control>
                    </Col>
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="1"></Col>
                    <Form.Label column sm="2">
                      Depth
                          </Form.Label>
                    <Col sm="4">
                      <InputNumber min={0} style={{ width: '100%' }}
                        onChange={(e) => this.setState({ depthSelfs: e })} value={this.state.depthSelfs} placeholder="Depth" />
                    </Col>
                    <Col sm="3">
                      <Form.Control name="depthTipSelfs" value={this.state.depthTipSelfs}
                        onChange={this.handleChangeDepthTip} as="select"  >
                        <option value="0">
                          Select
                              </option>
                        <option value="1">
                          Limitless
                              </option>
                        <option value="2">
                          Cm
                              </option>
                        <option value="3">
                          Meter
                              </option>
                      </Form.Control>
                    </Col>
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Form.Label column sm="2" style={{ fontWeight: 'bold' }} >
                      Coordinates
                      </Form.Label>
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="1"></Col>
                    <Form.Label column sm="2">
                      Northing
                          </Form.Label>
                    <Col sm="4">
                      <Form.Control name="northingSelfs" value={this.state.northingSelfs} onChange={this.handleChange} />
                    </Col>
                    <Col sm="3">
                    </Col>
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="1"></Col>
                    <Form.Label column sm="2">
                      Easting
                          </Form.Label>
                    <Col sm="4">
                      <Form.Control name="eastingSelfs" value={this.state.eastingSelfs} onChange={this.handleChange} />
                    </Col>
                    <Col sm="3">
                    </Col>
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="1"></Col>
                    <Form.Label column sm="2">
                      Elevation
                        </Form.Label>
                    <Col sm="4">
                      <Form.Control name="elevationSelfs" value={this.state.elevationSelfs} onChange={this.handleChange} />
                    </Col>
                    <Col sm="2">
                      <ReactButton variant="secondary" style={{ width: '100%' }}> Load </ReactButton>
                    </Col>
                    <Col sm="2"> 
                    {(this.state.hiddenUpdateSelf && this.state.hiddenRocks) && <AntButton type="primary" onClick={this.addShelfNo} icon={<PlusOutlined />}></AntButton>}
                    </Col>
                  </Form.Group>

                  <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                    <Col sm="3"></Col>
                    <Col sm="5">
                      <Checkbox checked={!this.state.hiddenRocks} disabled={this.state.disabledRocks} onChange={(e) => {
                        var hiddenRocks = true;
                        var disabledRocks=false;
                        if (e.target.checked) {  
                          if (this.state.shelfNo != null && this.state.shelfNo != undefined && this.state.shelfNo != "") {
                            if(this.SelfNoKontrol()){
                              hiddenRocks = false; 
                              disabledRocks=true;
                              if(this.state.hiddenUpdateSelf){
                                this.addShelfNo();
                                this.setState({
                                  hiddenCreateNewButtonSelf:true,
                                  hiddenDataGirisSelf: true,
                                  createNewButtonSelf: "Create New",
                                  createNewButtonSelfVariant: "info", 
                                });
                              }
                              this.setState({  
                                hiddenSaveRock: true,
                                hiddenUpdateRock: false,
                                hiddenDataGirisRock: true,
                                createNewButtonRock: "Create New",
                                createNewButtonRockVariant: "info",
                              });
                            }else{
                              this.showWarning("The Self No you want to add has been used before. Please use a different Self No !");
                            }
                          }else{
                            this.showWarning();
                          }
                        }
                        this.setState({
                          hiddenRocks: hiddenRocks,
                          disabledRocks:disabledRocks,
                        });
                      }} style={{ color: 'black', marginTop: '0.25rem' }}>{<FormattedMessage id="StockSectionLogIdentifyTheRocks" defaultMessage="Identify the rocks" />}</Checkbox>
                    </Col>
                    <Col sm="4">
                    </Col>
                  </Form.Group> 
                  <br /> 

                  <div hidden={this.state.hiddenUpdateSelf}>
                    <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                      <Row>
                        <Col sm="2">
                        </Col>
                        <Col sm="2">
                          <ReactButton onClick={this.changeButtonCancelSelf} style={{ width: '100%' }} variant="danger">Cancel</ReactButton>
                        </Col>
                        <Col sm="3">
                          <ReactButton onClick={this.updateDoneSelf} style={{ width: '100%' }} variant="warning">Update</ReactButton>
                        </Col>
                        <Col sm="4">
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>

                <div className="datatable-doc-demo">
                  <Card>
                    <div className="content-section implementation">
                      <Table columns={tableColumnsSelf} dataSource={this.state.stockSectionShelfNos.filter(m => m.active==true)} pagination={this.state.pagination}
                        responsive striped bordered hover
                        loading={this.state.loading}
                      />
                    </div>
                  </Card>
                </div>

              </div>
              <br />

              <div hidden={this.state.hiddenRocks} style={{ backgroundColor: "white", padding: "10px", border: '1px solid #1ab394' }}>
                <div style={{ padding: "8px 16px 8px 24px" }}>
                  <Row>
                    <Col xs={3}>
                      <div style={{color:"red"}}>
                        <FormattedMessage id="StockSectionLogRock" defaultMessage="Rock" />
                      </div>
                    </Col>
                    <Col xs={5}></Col>
                    <Col xs={3}>
                      <ReactButton onClick={this.createNewClientRockButton} style={{ width: '100%' }} variant={this.state.createNewButtonRockVariant} >
                        {this.state.createNewButtonRock}
                      </ReactButton>
                    </Col>
                    <Col xs={1}>
                      <PrimeButton hidden={this.state.showHeader} type="button" onClick={() => this.showOrHideModalTable("StockSectionRockNo")} icon="pi pi-cog"></PrimeButton>
                    </Col>
                  </Row>
                  </div>
                  <br />
                  <div hidden={this.state.hiddenDataGirisRock}>
                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Col sm="1"></Col>
                      <Form.Label column sm="2">
                        Rock no
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control name="rockNo" value={this.state.rockNo} onChange={this.handleChangeRockNo} />
                      </Col>
                      <Col sm="3">
                      </Col>
                    </Form.Group>

                    <Form.Group hidden={this.state.hiddenRockNoKnt} style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDocCode">
                      <Col sm="3">
                      </Col>
                      <Form.Label style={{ color: 'red' }} column sm="9">
                        The Rock No you want to add has been used before. Please use a different Rock No !
                      </Form.Label>
                    </Form.Group>

                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Form.Label column sm="2" style={{ fontWeight: 'bold' }} >
                        Dimension
                          </Form.Label>
                    </Form.Group>

                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Col sm="1"></Col>
                      <Form.Label column sm="2">
                        Height
                            </Form.Label>
                      <Col sm="4">
                        <InputNumber min={0} style={{ width: '100%' }}
                          onChange={(e) => this.setState({ heightRock: e })} value={this.state.heightRock} placeholder="Height" />
                      </Col>
                      <Col sm="3">
                        <Form.Control name="heightTipRock" value={this.state.heightTipRock}
                          onChange={this.handleChangeHeightTip} as="select"  >
                          <option value="0">
                            Select
                                </option>
                          <option value="1">
                            Limitless
                                </option>
                          <option value="2">
                            Cm
                                </option>
                          <option value="3">
                            Meter
                                </option>
                        </Form.Control>
                      </Col>
                    </Form.Group>

                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Col sm="1"></Col>
                      <Form.Label column sm="2">
                        Widht
                            </Form.Label>
                      <Col sm="4">
                        <InputNumber min={0} style={{ width: '100%' }}
                          onChange={(e) => this.setState({ widhtRock: e })} value={this.state.widhtRock} placeholder="Widht" />
                      </Col>
                      <Col sm="3">
                        <Form.Control name="widhtTipRock" value={this.state.widhtTipRock}
                          onChange={this.handleChangeWidhtTip} as="select"  >
                          <option value="0">
                            Select
                                </option>
                          <option value="1">
                            Limitless
                                </option>
                          <option value="2">
                            Cm
                                </option>
                          <option value="3">
                            Meter
                                </option>
                        </Form.Control>
                      </Col>
                    </Form.Group>

                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Col sm="1"></Col>
                      <Form.Label column sm="2">
                        Depth
                            </Form.Label>
                      <Col sm="4">
                        <InputNumber min={0} style={{ width: '100%' }}
                          onChange={(e) => this.setState({ depthRock: e })} value={this.state.depthRock} placeholder="Depth" />
                      </Col>
                      <Col sm="3">
                        <Form.Control name="depthTipRock" value={this.state.depthTipRock}
                          onChange={this.handleChangeDepthTip} as="select"  >
                          <option value="0">
                            Select
                                </option>
                          <option value="1">
                            Limitless
                                </option>
                          <option value="2">
                            Cm
                                </option>
                          <option value="3">
                            Meter
                                </option>
                        </Form.Control>
                      </Col>
                    </Form.Group>

                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Form.Label column sm="2" style={{ fontWeight: 'bold' }} >
                        Coordinates
                        </Form.Label>
                    </Form.Group>

                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Col sm="1"></Col>
                      <Form.Label column sm="2">
                        Northing
                            </Form.Label>
                      <Col sm="4">
                        <Form.Control name="northingRock" value={this.state.northingRock} onChange={this.handleChange} />
                      </Col>
                      <Col sm="3">
                      </Col>
                    </Form.Group>

                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Col sm="1"></Col>
                      <Form.Label column sm="2">
                        Easting
                            </Form.Label>
                      <Col sm="4">
                        <Form.Control name="eastingRock" value={this.state.eastingRock} onChange={this.handleChange} />
                      </Col>
                      <Col sm="3">
                      </Col>
                    </Form.Group>

                    <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
                      <Col sm="1"></Col>
                      <Form.Label column sm="2">
                        Elevation
                            </Form.Label>
                      <Col sm="4">
                        <Form.Control name="elevationRock" value={this.state.elevationRock} onChange={this.handleChange} />
                      </Col>
                      <Col sm="2">
                        <ReactButton variant="secondary" style={{ width: '100%' }}> Load </ReactButton>
                      </Col>
                      <Col sm="2">
                        {this.state.hiddenSaveRock==false &&  <AntButton type="primary" onClick={this.addRockNo} icon={<PlusOutlined />}></AntButton>}
                      </Col>
                    </Form.Group>
                    <br /> 

                    <div hidden={this.state.hiddenUpdateRock}>
                      <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
                        <Row>
                          <Col sm="2">
                          </Col>
                          <Col sm="2">
                            <ReactButton onClick={this.changeButtonCancelRock} style={{ width: '100%' }} variant="danger">Cancel</ReactButton>
                          </Col>
                          <Col sm="3">
                            <ReactButton onClick={this.updateDoneRock} style={{ width: '100%' }} variant="warning">Update</ReactButton>
                          </Col>
                          <Col sm="4">
                          </Col>
                        </Row>
                      </Container>
                    </div>
                
                  </div>

                <div className="datatable-doc-demo">
                  <Card>
                    <div className="content-section implementation">
                      <Table columns={tableColumnsRockNo} dataSource={this.state.stockSectionRockNos.filter(m => m.active==true)} pagination={this.state.pagination}
                        responsive striped bordered hover
                        loading={this.state.loading}
                      />
                    </div>
                  </Card>
                </div>

              </div>
              <br />
          </Form>
          <br />
          <div hidden={this.state.hiddenSave}>
            <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
              <Row>
                <Col sm="2">
                </Col>
                <Col sm="5">
                  <ReactButton onClick={this.addStockSectionLog} style={{ width: '100%' }} variant="success">{this.state.lastButton}</ReactButton>
                </Col>
                <Col sm="4">
                </Col>
              </Row>
            </Container>
          </div>

          <div hidden={this.state.hiddenUpdate}>
            <Container style={{ maxWidth: 'none', marginBottom: '15px' }}>
              <Row>
                <Col sm="2">
                </Col>
                <Col sm="2">
                  <ReactButton onClick={this.changeButtonCancel} style={{ width: '100%' }} variant="danger">Cancel</ReactButton>
                </Col>
                <Col sm="3">
                  <ReactButton onClick={this.updateDone} style={{ width: '100%' }} variant="warning">Update</ReactButton>
                </Col>
                <Col sm="4">
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className="datatable-doc-demo">
          <Card>
            <div className="content-section implementation">
              <Table columns={tableColumns} dataSource={this.state.StockSectionLogs} pagination={this.state.pagination}
                responsive striped bordered hover
                loading={this.state.loading}
                style={{ padding: "4px 1rem 4px 4px" }}
                expandable={{
                  expandedRowRender: record =>
                  {
                    if(record.data.stockSectionShelfNos!=null && record.data.stockSectionShelfNos!=undefined && record.data.stockSectionShelfNos.length>0){
                      return <Table columns={tableColumnsSelfLst} dataSource={record.data.stockSectionShelfNos} pagination={false} responsive striped bordered hover loading={this.state.loading} 
                      style={{ padding: "4px 1rem 4px 4px" }} 
                      expandable={{
                        expandedRowRender: record => <Table columns={tableColumnsRockNoLst} dataSource={record.stockSectionRockNos} pagination={false} responsive striped bordered hover loading={this.state.loading} />, rowExpandable: record => (record.stockSectionRockNos != null && record.stockSectionRockNos != undefined) && record.stockSectionRockNos.length !== 0,
                      }} />;
                    }
                  }
                  , rowExpandable: record => (record.data!=null && record.data!=undefined && record.data.expandable!=null && record.data.expandable!=undefined) == true ? record.data.expandable : false,
                }}
              />
            </div>
          </Card>
        </div>

        <Modal
          show={this.state.deleteModal}
          onHide={this.showOrHideModalDeleteStockSectionLog}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Delete This?
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
              Do you want to delete this Stocks?
              </p>
            <p style={{ textAlign: "center", color: "black" }}>
              This process will delete all related datas.
              </p>
          </Modal.Body>
          <Modal.Footer>
            <ReactButton
              variant="secondary"
              onClick={this.showOrHideModalDeleteStockSectionLog}
            >
              Close
              </ReactButton>
            <ReactButton variant="danger" onClick={this.deleteStockSectionLogOK}>
              Delete
              </ReactButton>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.deleteModalSectionLogSelf}
          onHide={this.showOrHideModalDeleteStockSectionSelf}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Delete This?
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
              Do you want to delete this Stock Shelf?
              </p>
            <p style={{ textAlign: "center", color: "black" }}>
              This process will delete all related datas.
              </p>
          </Modal.Body>
          <Modal.Footer>
            <ReactButton
              variant="secondary"
              onClick={this.showOrHideModalDeleteStockSectionSelf}
            >
              Close
              </ReactButton>
            <ReactButton variant="danger" onClick={this.deleteStockSectionSelfOK}>
              Delete
              </ReactButton>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.deleteModalSectionLogRock}
          onHide={this.showOrHideModalDeleteStockSectionRock}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Delete This?
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
              Do you want to delete this Stock Rock?
              </p>
            <p style={{ textAlign: "center", color: "black" }}>
              This process will delete all related datas.
              </p>
          </Modal.Body>
          <Modal.Footer>
            <ReactButton
              variant="secondary"
              onClick={this.showOrHideModalDeleteStockSectionRock}
            >
              Close
              </ReactButton>
            <ReactButton variant="danger" onClick={this.deleteStockSectionRockOK}>
              Delete
              </ReactButton>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.deleteModalSectionLogSelfLst}
          onHide={this.showOrHideModalDeleteStockSectionSelfLst}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Delete This?
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
              Do you want to delete this Stock Shelf?
              </p>
            <p style={{ textAlign: "center", color: "black" }}>
              This process will delete all related datas.
              </p>
          </Modal.Body>
          <Modal.Footer>
            <ReactButton
              variant="secondary"
              onClick={this.showOrHideModalDeleteStockSectionSelfLst}
            >
              Close
              </ReactButton>
            <ReactButton variant="danger" onClick={this.deleteStockSectionSelfLstOK}>
              Delete
              </ReactButton>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.deleteModalSectionLogRockLst}
          onHide={this.showOrHideModalDeleteStockSectionRockLst}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Delete This?
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: "center", color: "black" }}>
              Do you want to delete this Stock Rock?
              </p>
            <p style={{ textAlign: "center", color: "black" }}>
              This process will delete all related datas.
              </p>
          </Modal.Body>
          <Modal.Footer>
            <ReactButton
              variant="secondary"
              onClick={this.showOrHideModalDeleteStockSectionRockLst}
            >
              Close
              </ReactButton>
            <ReactButton variant="danger" onClick={this.deleteStockSectionRockLstOK}>
              Delete
              </ReactButton>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.updateModalSectionLogSelfLst}
          onHide={this.showOrHideModalUpdateStockSectionSelfLst}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Update This?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          
            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
              <Col sm="1"></Col>
              <Form.Label column sm="2">
                Shelf No
                    </Form.Label>
              <Col sm="4">
                <Form.Control name="shelfNo" value={this.state.shelfNo} onChange={this.handleChangeSelfNoLst} />
              </Col>
              <Col sm="3">
              </Col>
            </Form.Group>

            <Form.Group hidden={this.state.hiddenSelfNoKnt} style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDocCode">
                <Col sm="3">
                </Col>
                <Form.Label style={{ color: 'red' }} column sm="9">
                  The Self No you want to add has been used before. Please use a different Self No !
                </Form.Label>
            </Form.Group>

            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
              <Form.Label column sm="2" style={{ fontWeight: 'bold' }} >
                Dimension
                  </Form.Label>
            </Form.Group>

            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
              <Col sm="1"></Col>
              <Form.Label column sm="2">
                Height
                    </Form.Label>
              <Col sm="4">
                <InputNumber min={0} style={{ width: '100%' }}
                  onChange={(e) => this.setState({ heightSelfs: e })} value={this.state.heightSelfs} placeholder="Height" />
              </Col>
              <Col sm="3">
                <Form.Control name="heightTipSelfs" value={this.state.heightTipSelfs}
                  onChange={this.handleChangeHeightTip} as="select"  >
                  <option value="0">
                    Select
                  </option>
                  <option value="1">
                    Limitless
                  </option>
                  <option value="2">
                    Cm
                  </option>
                  <option value="3">
                    Meter
                  </option>
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
              <Col sm="1"></Col>
              <Form.Label column sm="2">
                Widht
                    </Form.Label>
              <Col sm="4">
                <InputNumber min={0} style={{ width: '100%' }}
                  onChange={(e) => this.setState({ widhtSelfs: e })} value={this.state.widhtSelfs} placeholder="Widht" />
              </Col>
              <Col sm="3">
                <Form.Control name="widhtTipSelfs" value={this.state.widhtTipSelfs}
                  onChange={this.handleChangeWidhtTip} as="select"  >
                  <option value="0">
                    Select
                  </option>
                  <option value="1">
                    Limitless
                  </option>
                  <option value="2">
                    Cm
                  </option>
                  <option value="3">
                    Meter
                  </option>
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
              <Col sm="1"></Col>
              <Form.Label column sm="2">
                Depth
                    </Form.Label>
              <Col sm="4">
                <InputNumber min={0} style={{ width: '100%' }}
                  onChange={(e) => this.setState({ depthSelfs: e })} value={this.state.depthSelfs} placeholder="Depth" />
              </Col>
              <Col sm="3">
                <Form.Control name="depthTipSelfs" value={this.state.depthTipSelfs}
                  onChange={this.handleChangeDepthTip} as="select"  >
                  <option value="0">
                    Select
                        </option>
                  <option value="1">
                    Limitless
                        </option>
                  <option value="2">
                    Cm
                        </option>
                  <option value="3">
                    Meter
                        </option>
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
              <Form.Label column sm="2" style={{ fontWeight: 'bold' }} >
                Coordinates
                </Form.Label>
            </Form.Group>

            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
              <Col sm="1"></Col>
              <Form.Label column sm="2">
                Northing
                    </Form.Label>
              <Col sm="4">
                <Form.Control name="northingSelfs" value={this.state.northingSelfs} onChange={this.handleChange} />
              </Col>
              <Col sm="3">
              </Col>
            </Form.Group>

            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
              <Col sm="1"></Col>
              <Form.Label column sm="2">
                Easting
                    </Form.Label>
              <Col sm="4">
                <Form.Control name="eastingSelfs" value={this.state.eastingSelfs} onChange={this.handleChange} />
              </Col>
              <Col sm="3">
              </Col>
            </Form.Group>

            <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
              <Col sm="1"></Col>
              <Form.Label column sm="2">
                Elevation
                  </Form.Label>
              <Col sm="4">
                <Form.Control name="elevationSelfs" value={this.state.elevationSelfs} onChange={this.handleChange} />
              </Col>
              <Col sm="2">
                <ReactButton variant="secondary" style={{ width: '100%' }}> Load </ReactButton>
              </Col>
              <Col sm="2"> 
              </Col>
            </Form.Group>

          </Modal.Body>
          <Modal.Footer>
            <ReactButton
              variant="secondary"
              onClick={this.showOrHideModalUpdateStockSectionSelfLst}
            >
              Close
              </ReactButton>
            <ReactButton variant="warning" onClick={this.updateDoneSelfLst}>
              Update
              </ReactButton>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.updateModalSectionLogRockLst}
          onHide={this.showOrHideModalUpdateStockSectionRockLst}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Update This?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          
          <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
            <Col sm="1"></Col>
            <Form.Label column sm="2">
              Rock no
            </Form.Label>
            <Col sm="4">
              <Form.Control name="rockNo" value={this.state.rockNo} onChange={this.handleChangeRockNoLst} />
            </Col>
            <Col sm="3">
            </Col>
          </Form.Group>

          <Form.Group hidden={this.state.hiddenRockNoKnt} style={{ marginBottom: '1rem' }} as={Row} controlId="formGridDocCode">
            <Col sm="3">
            </Col>
            <Form.Label style={{ color: 'red' }} column sm="9">
              The Rock No you want to add has been used before. Please use a different Rock No !
            </Form.Label>
          </Form.Group>

          <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
            <Form.Label column sm="2" style={{ fontWeight: 'bold' }} >
              Dimension
                </Form.Label>
          </Form.Group>

          <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
            <Col sm="1"></Col>
            <Form.Label column sm="2">
              Height
                  </Form.Label>
            <Col sm="4">
              <InputNumber min={0} style={{ width: '100%' }}
                onChange={(e) => this.setState({ heightRock: e })} value={this.state.heightRock} placeholder="Height" />
            </Col>
            <Col sm="3">
              <Form.Control name="heightTipRock" value={this.state.heightTipRock}
                onChange={this.handleChangeHeightTip} as="select"  >
                <option value="0">
                  Select
                      </option>
                <option value="1">
                  Limitless
                      </option>
                <option value="2">
                  Cm
                      </option>
                <option value="3">
                  Meter
                      </option>
              </Form.Control>
            </Col>
          </Form.Group>

          <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
            <Col sm="1"></Col>
            <Form.Label column sm="2">
              Widht
                  </Form.Label>
            <Col sm="4">
              <InputNumber min={0} style={{ width: '100%' }}
                onChange={(e) => this.setState({ widhtRock: e })} value={this.state.widhtRock} placeholder="Widht" />
            </Col>
            <Col sm="3">
              <Form.Control name="widhtTipRock" value={this.state.widhtTipRock}
                onChange={this.handleChangeWidhtTip} as="select"  >
                <option value="0">
                  Select
                      </option>
                <option value="1">
                  Limitless
                      </option>
                <option value="2">
                  Cm
                      </option>
                <option value="3">
                  Meter
                      </option>
              </Form.Control>
            </Col>
          </Form.Group>

          <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
            <Col sm="1"></Col>
            <Form.Label column sm="2">
              Depth
                  </Form.Label>
            <Col sm="4">
              <InputNumber min={0} style={{ width: '100%' }}
                onChange={(e) => this.setState({ depthRock: e })} value={this.state.depthRock} placeholder="Depth" />
            </Col>
            <Col sm="3">
              <Form.Control name="depthTipRock" value={this.state.depthTipRock}
                onChange={this.handleChangeDepthTip} as="select"  >
                <option value="0">
                  Select
                </option>
                <option value="1">
                  Limitless
                </option>
                <option value="2">
                  Cm
                </option>
                <option value="3">
                  Meter
                </option>
              </Form.Control>
            </Col>
          </Form.Group>

          <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
            <Form.Label column sm="2" style={{ fontWeight: 'bold' }} >
              Coordinates
              </Form.Label>
          </Form.Group>

          <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
            <Col sm="1"></Col>
            <Form.Label column sm="2">
              Northing
                  </Form.Label>
            <Col sm="4">
              <Form.Control name="northingRock" value={this.state.northingRock} onChange={this.handleChange} />
            </Col>
            <Col sm="3">
            </Col>
          </Form.Group>

          <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
            <Col sm="1"></Col>
            <Form.Label column sm="2">
              Easting
                  </Form.Label>
            <Col sm="4">
              <Form.Control name="eastingRock" value={this.state.eastingRock} onChange={this.handleChange} />
            </Col>
            <Col sm="3">
            </Col>
          </Form.Group>

          <Form.Group style={{ marginBottom: '1rem' }} as={Row}>
            <Col sm="1"></Col>
            <Form.Label column sm="2">
              Elevation
                  </Form.Label>
            <Col sm="4">
              <Form.Control name="elevationRock" value={this.state.elevationRock} onChange={this.handleChange} />
            </Col>
            <Col sm="2">
              <ReactButton variant="secondary" style={{ width: '100%' }}> Load </ReactButton>
            </Col>
            <Col sm="2">
            </Col>
          </Form.Group>

          </Modal.Body>
          <Modal.Footer>
            <ReactButton
              variant="secondary"
              onClick={this.showOrHideModalUpdateStockSectionRockLst}
            >
              Close
              </ReactButton>
            <ReactButton variant="warning" onClick={this.updateDoneRockLst}>
              Update
              </ReactButton>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalMessage}
          onHide={this.showOrHideModalMessage}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered >

          <Modal.Header style={{ backgroundColor: "#f3f3f3" }} closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  {this.state.messageTip == "Success" &&
                    <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                  {this.state.messageTip == "Warning" &&
                    <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                  {this.state.messageTip == "Error" &&
                    <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body style={{ backgroundColor: "#f3f3f3" }}>
            <Row >
              <Col sm="12">
                {this.state.message}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer style={{ backgroundColor: "#f3f3f3" }}>
            <ReactButton style={{ color: "#646c9a", backgroundColor: "#ffffff", borderColor: "#ffffff" }} variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalTable}
          onHide={this.showOrHideModalTable}
          backdrop="static"
          aria-labelledby="contained-modal-title-vcenter"
          centered size="lg">

          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Row >
                <Col sm="12">
                  <FormattedMessage id="GeneralTableSettings" defaultMessage="Table Settings" />
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row >
              <Col sm="12">
                <MNDTableSettings id={this.state.tableTip} />
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    i18n: state.i18n
  }
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(StockSectionLog);
