/* eslint-disable */

import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import "primereact/resources/themes/nova-accent/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { handleRequest } from "../../redux/config/env";
import { Form, Col, Modal, Row } from "react-bootstrap";

export default class ChecklistReport extends Component {
  constructor(props) {
      super(props);
    this.state = {
      modalShow: false,
    };
  }

    componentDidMount = async () => {};

  showReport = async () => {


    const response = await handleRequest(
      "GETBLOB",
      "/inspectionRequest/getReportChecklist/1",
      { method: "POST", responseType: "blob" }
    );
    try {
      const file = new Blob([response.data], { type: "application/pdf" });
      console.log(file); 
      const fileURL = URL.createObjectURL(file); 
      window.open(fileURL);
    } catch (error) {

    }


  };

  showReport2 = async () => {


    const response = await handleRequest(
      "GETBLOB",
      "/inspectionRequest/getReportITP/1",
      { method: "POST", responseType: "blob" }
    );
    try {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (error) {

    }


  };

  showReport3 = async () => {


    const response = await handleRequest(
      "GETBLOB",
      "/inspectionRequest/getReportWPS",
      { method: "POST", responseType: "blob" }
    );
    try {
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (error) {
      console.log(error);
      }
  };

  showReport4 = async () => {
    const response = await handleRequest("GETBLOB", "/inspectionRequest/getReportQuotation/1", { method: "GET", responseType: "blob" });

    try {
      console.log(response.data);

      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (error) {
      console.log(error);

    }
  };

  showReport5 = async () => {
    const response = await handleRequest(
      "GETBLOB",
      "/Shipment/getReportVoucher/18",
      { method: "POST", responseType: "blob" }
    );

    try {
      //console.log(response.data);

      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (error) {
      console.log(error);

    }
  };

  showReport6 = async () => {
    const response = await handleRequest(
      "GETBLOB",
      "/inspectionRequest/getReportQuote/1",
      { method: "POST", responseType: "blob" }
    );

    try {
      console.log(response.data);

      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (error) {
      console.log(error);

    }
  };


  render() {
      return (
        <div>
          <Form.Group style={{ width: "100%" }} as={Row}>
            <Col sm="3">
              <Button
                onClick={this.showReport}
                label="Show Report Checklist Template"
                className="p-button-success"
              />
            </Col>

            <Col sm="3">
              <Button
                onClick={this.showReport2}
                label="Show Report ITP Template"
                className="p-button-success"
              />
            </Col>

            <Col sm="3">
              <Button
                onClick={this.showReport3}
                label="Show Report WPS Template"
                className="p-button-success"
              />
            </Col>

            <Col sm="3">
              <Button
                onClick={this.showReport4}
                label="Show Quotation Report"
                className="p-button-success"
              />
            </Col>
          </Form.Group>
          <Form.Group style={{ width: "100%" }} as={Row}>
            <Col sm="3">
              <Button
                onClick={this.showReport5}
                label="Show Report Voucher"
                className="p-button-success"
              />
            </Col>

            <Col sm="3">
              <Button
                onClick={this.showReport6}
                label="Show Report Quote"
                className="p-button-success"
              />
            </Col>

            <Col sm="3"></Col>

            <Col sm="3"></Col>
          </Form.Group>
        </div>
      );
  }
}
