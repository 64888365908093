import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button as AntButton, Checkbox, DatePicker, Divider, Form as FormAnt, Input, InputNumber, Select, Table } from 'antd';
import moment from 'moment';
import 'primeicons/primeicons.css';
import { Card } from "primereact/card";
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/nova-accent/theme.css';
import React, { Component } from 'react';
import { Button as ReactButton, Col, Form, Modal, Row } from 'react-bootstrap';
import { Item, MenuProvider } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import { FormattedMessage } from "react-intl";
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { handleRequest } from '../../redux/config/env';
import CustomMaterialMenu from '../settings/ForComponents/CustomMaterialMenu';
import HowtoUse from '../standardization/HowtoUse';
import Settings from '../standardization/Settings';
import Whatis from '../standardization/Whatis';

const { RangePicker } = DatePicker;
const { Column } = Table;
const { Option } = Select;

const moneyUnits = [{ id: 1, value: "SAR" }, { id: 2, value: "$" }, { id: 3, value: "TL" }]

export class BusinessTripPolicy extends Component {

    constructor() {
        super();
        this.state = {
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,

            businessTripPolicyList: [],
            periodSelectItems: [],
            groupList: [],
            periodId: null,
            havePolicyDesignationId: null,

            startDate: null,
            endDate: null,
            moments: null,
            hidePeriodModal: true,
            hideGroupModal: true,
            hideItemModal: true,

            groupName: '',
            itemName: '',
            groupId: null,
            exclusion: false,

            departmentSelectItems: [],
            disciplineSelectItems: [],
            designationSelectItems: [],

            selectedDesignations: [],
            selectedDepartments: [],
            selectedDisciplines: [],


            dynamicAttributes: [],
            authorizations: [],
            Inputs: [],
            isAdmin: false,
            howToUseSetting: false,
            whatIsSetting: false,

            formId: null
        };
        

    }

    formRef = React.createRef();


    componentDidMount = async () => {
        this.getDynamicMenu();

        if (this.props.auth !== undefined)
            if (this.props.auth.user !== null) {

                this.setState({
                    isAdmin: this.props.auth.user.userType === 2 ? true : false
                });

            }

        this.setStandardizations();

        this.restartTable();
        this.setPeriods();
        this.setGroups();
        this.setComboboxes();


    }

    save = async () => {

        const designations = this.state.selectedDesignations;
        const btpList = this.state.businessTripPolicyList;
        var list = [];


        if (Boolean(designations) && designations.length > 0 && Boolean(btpList) && btpList.length > 0) {

            designations.forEach(element => {

                btpList.forEach(item => {

                    let obj = {
                        id: item.id,
                        groupId: item.groupId, itemId: item.itemId, international: item.international,
                        internationalCurrencyId: item.internationalCurrencyId, intercity: item.intercity,
                        intercityCurrencyId: item.intercityCurrencyId, intracity: item.intracity,
                        intracityCurrencyId: item.intracityCurrencyId,
                        designationId: element,
                        periodId: this.state.periodId
                    }
                    list.push(obj);

                });

            });



            await handleRequest("POST", "/BusinessTripPolicy/saveAll", list);

            this.showSuccess();
            this.resetInputs();
            this.restartTable();
        }
        else
            this.showError("Please select designation");

    }

    addPeriod = async () => {

        const obj = {
            startDate: this.state.startDate,
            endDate: this.state.endDate
        }


        await handleRequest("POST", "/BusinessTripPeriod/add", obj);


        this.showSuccess();

        this.setState({
            startDate: null,
            endDate: null,
            moments: null,
            hidePeriodModal: true
        })

        this.setPeriods();

    }
    setPeriods = async () => {

        const resDepList = await handleRequest("GET", "/BusinessTripPeriod/getAllByCompany");
        if (resDepList.data.length > 0) {
            this.setState({
                periodSelectItems: resDepList.data
            });
        } else {
            this.setState({
                periodSelectItems: []
            });
        }

    }

    setComboboxes = async () => {
        const resDepList = await handleRequest("GET", "/Department/getallByCompany");
        if (resDepList.data.length > 0) {
            this.setState({
                departmentSelectItems: resDepList.data
            });
        } else {
            this.setState({
                departmentSelectItems: []
            });
        }

        const resDisList = await handleRequest("GET", "/Discipline/getallByCompany");
        if (resDisList.data.length > 0) {
            this.setState({
                disciplineSelectItems: resDisList.data,
                disciplineSelectItemsAll: resDisList.data
            });
        } else {
            this.setState({
                disciplineSelectItems: []
            });
        }

        const repDesList = await handleRequest("GET", "/Designation/getallByCompany");
        if (repDesList.data.length > 0) {
            this.setState({
                designationSelectItems: repDesList.data
            });
        } else {
            this.setState({
                designationSelectItems: []
            });
        }
    }

    addGroup = async () => {

        const obj = {

            groupName: this.state.groupName,
            exclusion: this.state.exclusion
        }


        await handleRequest("POST", "/BusinessTripGroup/add", obj);


        this.showSuccess();

        this.setState({
            groupName: '',
            exclusion: false,
            hideGroupModal: true

        })

        this.setGroups();

    }

    addItem = async () => {

        const obj = {

            groupId: this.state.groupId,
            itemName: this.state.itemName
        }


        await handleRequest("POST", "/BusinessTripItem/add", obj);


        this.showSuccess();

        this.setState({

            itemName: '',
            hideItemModal: true,
            groupId: null

        })

        this.setGroups();

    }

    setGroups = async () => {

        const resDepList = await handleRequest("GET", "/BusinessTripGroup/getAllByCompany");
        if (resDepList.data.length > 0) {
            const list = resDepList.data;
            const _businessTripPolicyList = [];
            list.forEach(group => {
                if (Boolean(group.itemList) && group.itemList.length > 0) {
                    group.itemList.forEach(item => {
                      //  if (_businessTripPolicyList.findIndex(p => p.groupId === group.id && p.itemId === item.id) === -1) {
                            let obj = { id: 0, groupId: group.id, itemId: item.id, international: 0, internationalCurrencyId: 1, intercity: 0, intercityCurrencyId: 1, intracity: 0, intracityCurrencyId: 1 }
                            _businessTripPolicyList.push(obj);
                     //   }

                    });

                }
            });

            this.setState({
                groupList: list,
                businessTripPolicyList: _businessTripPolicyList
            });
        } else {
            this.setState({
                groupList: []
            });
        }

    }

    edit = (row) => {

        this.setState({
            statusId: row.id,
            status: row.status,
            abbreviation: row.abbreviation,
            hideSave: true,
            hideUpdate: false,
            hideInputs: false,

        });

    }


    delete = async () => {
        const obj = {
            id: this.state.statusId
        }

        await handleRequest("POST", "/BusinessTripPolicy/delete", obj);

        this.restartTable();
        this.showSuccess();
        this.showOrHideDeleteModal();
    }

    deleteModal = (row) => {
        this.setState({
            modalDeleteShow: true,
            statusId: row.id
        });
    }

    showOrHideDeleteModal = () => {
        this.setState({
            modalDeleteShow: !this.state.modalDeleteShow
        });

        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }



    showSuccess(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageSuccess1" defaultMessage="Successfull" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Success"
        });
    }

    showError(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageError1" defaultMessage="An error was occured please try again later !" />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Error"
        });
    }

    showWarning(msg) {
        if (!msg) {
            msg = <FormattedMessage id="GeneralMessageCheckInputs" defaultMessage="Something went wrong. Please check inputs." />;
        }
        this.setState({
            modalMessage: true,
            message: msg,
            messageTip: "Warning"
        });
    };




    actionTemplate(rowData, column) {


        return <React.Fragment>
            <CustomMaterialMenu row={rowData} onDeleteRow={this.deleteModal.bind(this)} onEditRow={this.edit.bind(this)} />
        </React.Fragment>;


    }






    restartTable = async () => {
        // const response = await handleRequest("GET", "/BusinessTripPolicy/getAllByCompany");

        // if (response.data.length !== 0) {
        //     const list = response.data;
        //     list.forEach(element => {
        //         element.key = element.id
        //     });
        //     this.setState({
        //         businessTripPolicyList: list,
        //     });
        // }

        // if (response.data.length === 0) {
        //     this.setState({
        //         businessTripPolicyList: [],
        //     });
        // }
    }


    setStandardizations = async () => {
        const responseDP = await handleRequest("POST", "/DynamicPage/getFormAttributes", { pageName: "BusinessTripPolicy" });
        if (responseDP.data.length > 0) {
            this.setState({
                dynamicAttributes: responseDP.data
            });
        } else {
            this.setState({
                dynamicAttributes: []
            });
        }

        const obj = {
            pageName: "BusinessTripPolicy"
        };

        const responseInputs = await handleRequest("POST", "/DynamicInputs/getResult", obj);
        if (responseInputs.data.length !== 0) {
            this.setState({
                Inputs: responseInputs.data,
            });
        } else {
            this.setState({
                Inputs: [],
            });
        }

        //const menuObj = this.props.auth.user.authList.find(p => Boolean(p.dynamicMenu) && p.dynamicMenu.page === "BusinessTripPolicy");

        try {
            this.setState({
                authorizations: this.props.auth.user.authList.filter(p => p.menuId === this.state.formId || p.form === "All")
            });

        } catch (error) {

        }

    }



    getDynamicMenu = async () => {
        const responseTree = await handleRequest("GET", "/DynamicMenu/getTreeModel");
        if (responseTree.data.length !== 0) {
            var element = responseTree.data.treeTable;

            for (let index = 0; index < element.length; index++) {
                var result = this.searchTree(element[index], this.props.location.pathname.split("/").pop());

                if (result !== null)
                    break;
            }

            this.setState({
                formId: result.data.id
            });

            this.getUserSettings();
        } else {
            this.setState({
                formId: null
            });
        }
    }





    createNew = () => {

        this.setState({
            hideInputs: !this.state.hideInputs,
            status: '',
            abbreviation: '',
            statusId: 0,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,
        });

    }


    stop = () => {

        this.setState({
            status: '',
            abbreviation: '',
            statusId: 0,
            modalDeleteShow: false,
            hideInputs: true,
            hideSave: false,
            hideUpdate: true,
        });

    }



    resetInputs = () => {
        this.setState({
            status: '',
            abbreviation: '',
            statusId: 0,
            modalDeleteShow: false,
            hideSave: false,
            hideUpdate: true,


        });
    }


    cancel = () => {

        this.resetInputs();
    }





    searchTree = (element, matchingTitle) => {
        if (element.data.page == matchingTitle) {
            return element;
        } else if (element.children != null) {
            var i;
            var result = null;
            for (i = 0; result == null && i < element.children.length; i++) {
                result = this.searchTree(element.children[i], matchingTitle);
            }
            return result;
        }
        return null;
    }





    getUserSettings = async () => {
        const responseUserSettings = await handleRequest("GET", "/UserPageSettings/getFindByFormAndUserId/" + this.state.formId);
        if (responseUserSettings.data.length !== 0) {
            this.setState({
                userSettings: responseUserSettings.data,
                howToUseSetting: responseUserSettings.data.howToUseSetting,
                whatIsSetting: responseUserSettings.data.whatIsSetting
            });
        } else {
            this.setState({

                howToUseSetting: false,
                whatIsSetting: false
            });
        }
    }


    showOrHideModalMessage = () => {
        this.setState({
            modalMessage: !this.state.modalMessage,
        });
        setTimeout(() => {
            document.body.style.overflow = "auto";
        }, 500);
    }

    deleteGroup = async (group) => {

        const obj = {

            id: group.id
        }

        await handleRequest("POST", "/BusinessTripGroup/delete", obj);

        this.showSuccess();

        this.setGroups();

    }

    selectDesignations = async (value) => {

        if (Boolean(value) && value.length > 0) {

            if (Boolean(this.state.havePolicyDesignationId))
                return;

            if (Boolean(this.state.periodId) && this.state.periodId > 0) {
                var obj = {

                    designationId: value[value.length - 1],
                    periodId: this.state.periodId,

                }

                const response = await handleRequest("POST", "/BusinessTripPolicy/getResultsByDesignationPeriod", obj);

                if (Boolean(response) && Boolean(response.data) && response.data.length > 0) {
                    var nsi = [];
                    nsi.push(value[value.length - 1]);
                    this.setState({
                        selectedDesignations: nsi,
                        businessTripPolicyList: response.data,
                        havePolicyDesignationId: value[value.length - 1]
                    })
                } else {
                    this.setGroups();
                    this.setState({
                        selectedDesignations: value
                    })
                }
            }
            else {
                this.setGroups();
                this.setState({
                    selectedDesignations: value
                })
            }
        }
        else {
            this.setGroups();
            this.setState({
                selectedDesignations: value,
                havePolicyDesignationId: null
            })
        }
    }

    changePeriod = async (value) => {
        this.setGroups();
        this.setState({
            periodId: value,
            selectedDesignations: [],
            havePolicyDesignationId: null

        })

    }


    render() {

        const onFinish = values => {
            console.log('Success:', values);
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        const layout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
        };


        return <div>

                <MenuProvider id="menu_id">


                    <Modal
                        show={this.state.modalDeleteShow}
                        onHide={this.showOrHideDeleteModal}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Chosen status will be deleted !</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Are you sure?</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={this.showOrHideDeleteModal}>Cancel</ReactButton>
                            <ReactButton variant="danger" onClick={this.delete} >Delete</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={!this.state.hidePeriodModal}
                        onHide={() => {
                            this.setState({
                                hidePeriodModal: true,
                                moments: null,
                                startDate: null,
                                endDate: null

                            })
                        }}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Add Period</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <RangePicker value={this.state.moments}
                                format="YYYY-MM-DD" onChange={(value, dateString) => {

                                    if (Boolean(value) && value.length === 2) {
                                        this.setState({
                                            startDate: value[0],
                                            endDate: value[1]
                                        })
                                    }
                                    this.setState({
                                        moments: value,
                                    })

                                }} />
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={() => {
                                this.setState({
                                    hidePeriodModal: true,
                                    moments: null,
                                    startDate: null,
                                    endDate: null

                                })
                            }}>Cancel</ReactButton>
                            <ReactButton variant="info" onClick={this.addPeriod} >Add</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={!this.state.hideGroupModal}
                        onHide={() => {
                            this.setState({
                                hideGroupModal: true,
                                groupName: '',
                                exclusion: false,

                            })
                        }}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Add Group</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                <Col sm="6">
                                    <Input style={{ width: "100%" }} placeholder="Group Name" value={this.state.groupName} onChange={(e) => this.setState({ groupName: e.target.value })} />

                                </Col>
                                <Col sm="6">

                                    <div>
                                        <Checkbox onChange={(e) => {
                                            this.setState({
                                                exclusion: e.target.checked
                                            })
                                        }} checked={this.state.exclusion}> In case of exclusion </Checkbox>
                                    </div>


                                </Col>

                            </Form.Group>

                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={() => {
                                this.setState({
                                    hideGroupModal: true,
                                    groupName: '',
                                    exclusion: false,

                                })
                            }}>Cancel</ReactButton>
                            <ReactButton variant="info" onClick={this.addGroup} >Add</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        show={!this.state.hideItemModal}
                        onHide={() => {
                            this.setState({
                                hideItemModal: true,
                                itemName: '',
                                groupId: null,

                            })
                        }}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Add Item</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                <Col sm="12">
                                    <Input style={{ width: "100%" }} placeholder="Item Name" value={this.state.itemName} onChange={(e) => this.setState({ itemName: e.target.value })} />

                                </Col>


                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <ReactButton variant="secondary" onClick={() => {
                                this.setState({
                                    hideItemModal: true,
                                    itemName: '',
                                    groupId: null,

                                })
                            }}>Cancel</ReactButton>
                            <ReactButton variant="info" onClick={this.addItem} >Add</ReactButton>
                        </Modal.Footer>
                    </Modal>

                    <Card>

                        <div>
                            <Row>
                                <Col sm="1">
                                    <Settings formId={this.state.formId} onSaveChanges={this.getUserSettings.bind(this)} ></Settings>

                                </Col>
                                <Col sm="10">
                                </Col>
                                <Col sm="1" style={{ textAlign: "right" }}>
                                    {!this.state.howToUseSetting && <HowtoUse formId={this.state.formId} ></HowtoUse>

                                    }
                                </Col>
                            </Row>
                        </div>

                        <div>
                            <Row>
                                <Col sm="10">
                                </Col>
                                {/* <Col sm="2">
                                    {this.state.hideInputs &&
                                        <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="primary" onClick={this.createNew}><FormattedMessage id="GeneralButtonCreateNew" defaultMessage="Create New" /></ReactButton>
                                    }

                                    {!this.state.hideInputs &&
                                        <ReactButton id="OrganizationalLevelCreateNewButton" style={{ width: '100%', marginBottom: '2rem' }} variant="danger" onClick={this.stop}><FormattedMessage id="GeneralButtonStopProcess" defaultMessage="Stop the process" /></ReactButton>
                                    }

                                </Col> */}
                            </Row>
                        </div>

                        <div style={{ marginBottom: '2rem' }}>

                            <FormAnt
                                {...layout}
                                initialValues={{ remember: false }}
                                onFinish={this.save}
                                onFinishFailed={onFinishFailed}
                                ref={this.formRef}
                            >

                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Period" />}
                                        name="OrganizationalLevelRoleGroup"
                                        id="OrganizationalLevelRoleGroup"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type status" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Select
                                                    size="large"
                                                    style={{ width: "100%" }}
                                                    placeholder="Unit"
                                                    value={this.state.periodId} onChange={this.changePeriod}

                                                    dropdownRender={menu => (
                                                        <div>
                                                            {menu}
                                                            <Divider style={{ margin: '4px 0' }} />
                                                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>

                                                                <a
                                                                    style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                                                    onClick={() => { this.setState({ hidePeriodModal: false }) }}
                                                                >
                                                                    <PlusOutlined /> Add Period
                                                               </a>
                                                            </div>
                                                        </div>
                                                    )}
                                                >
                                                    {this.state.periodSelectItems.map(i => (
                                                        <Option key={i.id} value={i.id}>{moment(i.startDate).format("DD.MMM.YY") + '-' + moment(i.endDate).format("DD.MMM.YY")}</Option>
                                                    ))}
                                                </Select>

                                            </Col>
                                            <Col sm="1">

                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }
                                <Divider></Divider>
                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Applicable Areas" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">

                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }
                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Department" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Select placeholder="" mode="tags" value={this.state.selectedDepartments} onChange={(value) => {

                                                    if (Boolean(value) && value.length > 0) {
                                                        var listDep = this.state.departmentSelectItems.filter(p => value.includes(p.id));
                                                        var list = this.state.disciplineSelectItemsAll.filter(p => listDep.findIndex(element => element.disciplineDepartmentList.findIndex(t => t.disciplineId === p.id) >= 0) >= 0);

                                                    }
                                                    else list = this.state.disciplineSelectItemsAll;
                                                    this.setState({
                                                        selectedDepartments: value,
                                                        disciplineSelectItems: list
                                                    })
                                                }}
                                                >
                                                    <Option key={0} value={0}>Select</Option>
                                                    {this.state.departmentSelectItems.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.departmentName}</Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Discipline" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Select placeholder="" mode="tags" value={this.state.selectedDisciplines} onChange={(value) => {
                                                    this.setState({
                                                        selectedDisciplines: value
                                                    })
                                                }}
                                                >
                                                    <Option key={0} value={0}>Select</Option>
                                                    {this.state.disciplineSelectItems.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.disciplineName}</Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }

                                {Boolean(this.state.periodId) &&
                                    <FormAnt.Item
                                        label={<FormattedMessage id="todo" defaultMessage="Designation" />}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>
                                            <Col sm="6">
                                                <Select placeholder="" mode="tags" value={this.state.selectedDesignations} onChange={this.selectDesignations}
                                                >
                                                    <Option key={0} value={0}>Select</Option>
                                                    {this.state.designationSelectItems.map(i => (
                                                        <Option key={i.id} value={i.id}>{i.designationName}</Option>
                                                    ))}
                                                </Select>
                                            </Col>
                                            <Col sm="6">
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }
                                <Divider></Divider>
                                {
                                    <FormAnt.Item
                                        label={""}
                                        name="OrganizationalLevelDesignation"
                                        rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please type abbreviation" /> }]} >
                                        <Form.Group style={{ marginBottom: '0rem' }} as={Row}>

                                            <Col sm="6">
                                                <ReactButton variant="info" onClick={() => {
                                                    this.setState({
                                                        hideGroupModal: false
                                                    })
                                                }} > + Add New Group</ReactButton>
                                            </Col>
                                        </Form.Group>
                                    </FormAnt.Item>
                                }
                                <Divider></Divider>

                                {this.state.groupList.filter(p => p.active === true).map((group, i) => (
                                    <div style={{ backgroundColor: "white", padding: "10px", marginTop: "10px", border: '1px solid #1ab394' }}>
                                        {
                                            <FormAnt.Item

                                                name="OrganizationalLevelDesignation"
                                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >


                                                <Form.Group style={{ marginBottom: '1rem' }} as={Row} >

                                                    <Col sm="6">
                                                        <Form.Label style={{ color: '#0070C0', font: 'bold', fontSize: '16pt' }} >
                                                            {group.groupName}
                                                        </Form.Label>
                                                    </Col>
                                                    <Col sm="5">

                                                    </Col>
                                                    <Col sm="1">
                                                        <AntButton type="dashed" onClick={() => this.deleteGroup(group)} shape="circle" icon={<CloseOutlined />} />
                                                    </Col>

                                                </Form.Group>

                                            </FormAnt.Item>}
                                        { Boolean(group.itemList) && group.itemList.filter(p => p.active === true).map((item, j) => (
                                            <div>

                                                {  <FormAnt.Item
                                                    label={<FormattedMessage id="todoxxxyyzzz" defaultMessage={item.itemName} />}
                                                    name="OrganizationalLevelDesignation"
                                                    rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >


                                                    <Form.Group key={item.id} style={{ marginBottom: '1rem' }} as={Row} >
                                                        <Col sm="4">
                                                            <span>Max. </span>    <InputNumber min={0} value={this.state.businessTripPolicyList.find(p => p.groupId === group.id && p.itemId === item.id).international}
                                                                onChange={(value) => {
                                                                    this.setState(prevState => ({
                                                                        businessTripPolicyList: prevState.businessTripPolicyList.map(
                                                                            el => el.groupId === group.id && el.itemId === item.id ? { ...el, international: value } : el
                                                                        )
                                                                    }))
                                                                }}
                                                            > </InputNumber>
                                                            <Select
                                                                style={{ width: '180px' }}
                                                                placeholder="Currency"
                                                                value={this.state.businessTripPolicyList.find(p => p.groupId === group.id && p.itemId === item.id).internationalCurrencyId} onChange={(value) => {
                                                                    this.setState(prevState => ({
                                                                        businessTripPolicyList: prevState.businessTripPolicyList.map(
                                                                            el => el.groupId === group.id && el.itemId === item.id ? { ...el, internationalCurrencyId: value } : el
                                                                        )
                                                                    }))
                                                                }}
                                                            >
                                                                {moneyUnits.map(i => (
                                                                    <Option key={i.id} value={i.id}>{i.value}</Option>
                                                                ))}
                                                            </Select>


                                                        </Col>

                                                        <Col sm="4">
                                                            <span>Max. </span>    <InputNumber min={0} value={this.state.businessTripPolicyList.find(p => p.groupId === group.id && p.itemId === item.id).intercity}
                                                                onChange={(value) => {
                                                                    this.setState(prevState => ({
                                                                        businessTripPolicyList: prevState.businessTripPolicyList.map(
                                                                            el => el.groupId === group.id && el.itemId === item.id ? { ...el, intercity: value } : el
                                                                        )
                                                                    }))
                                                                }}
                                                            > </InputNumber>
                                                            <Select
                                                                style={{ width: '180px' }}
                                                                placeholder="Currency"
                                                                value={this.state.businessTripPolicyList.find(p => p.groupId === group.id && p.itemId === item.id).intercityCurrencyId} onChange={(value) => {
                                                                    this.setState(prevState => ({
                                                                        businessTripPolicyList: prevState.businessTripPolicyList.map(
                                                                            el => el.groupId === group.id && el.itemId === item.id ? { ...el, intercityCurrencyId: value } : el
                                                                        )
                                                                    }))
                                                                }}
                                                            >
                                                                {moneyUnits.map(i => (
                                                                    <Option key={i.id} value={i.id}>{i.value}</Option>
                                                                ))}
                                                            </Select>


                                                        </Col>

                                                        <Col sm="4">
                                                            <span>Max. </span>    <InputNumber min={0} value={this.state.businessTripPolicyList.find(p => p.groupId === group.id && p.itemId === item.id).intracity}
                                                                onChange={(value) => {
                                                                    this.setState(prevState => ({
                                                                        businessTripPolicyList: prevState.businessTripPolicyList.map(
                                                                            el => el.groupId === group.id && el.itemId === item.id ? { ...el, intracity: value } : el
                                                                        )
                                                                    }))
                                                                }}
                                                            > </InputNumber>
                                                            <Select
                                                                style={{ width: '180px' }}
                                                                placeholder="Currency"
                                                                value={this.state.businessTripPolicyList.find(p => p.groupId === group.id && p.itemId === item.id).intracityCurrencyId} onChange={(value) => {
                                                                    this.setState(prevState => ({
                                                                        businessTripPolicyList: prevState.businessTripPolicyList.map(
                                                                            el => el.groupId === group.id && el.itemId === item.id ? { ...el, intracityCurrencyId: value } : el
                                                                        )
                                                                    }))
                                                                }}
                                                            >
                                                                {moneyUnits.map(i => (
                                                                    <Option key={i.id} value={i.id}>{i.value}</Option>
                                                                ))}
                                                            </Select>


                                                        </Col>


                                                    </Form.Group>

                                                </FormAnt.Item>}

                                            </div>
                                        ))}
                                        {
                                            <FormAnt.Item

                                                name="OrganizationalLevelDesignation"
                                                rules={[{ required: false, message: <FormattedMessage id="todo" defaultMessage="Please select status" /> }]} >


                                                <Form.Group style={{ marginBottom: '1rem' }} as={Row} >

                                                    <Col sm="10">

                                                    </Col>
                                                    <Col sm="2">
                                                        <AntButton type="primary" onClick={() => {
                                                            this.setState({
                                                                hideItemModal: false,
                                                                groupId: group.id
                                                            })
                                                        }} icon={<PlusOutlined />}></AntButton>

                                                    </Col>

                                                </Form.Group>

                                            </FormAnt.Item>}
                                        <Divider style={{ margin: '4px 0' }} />
                                    </div>))
                                }



                                <div >
                                    {
                                        <Row>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="5">
                                                {/*<Button style={{ width: '100%' }} id='save-orgLevel' className="p-button-success" onClick={this.saveOrganizationalLevel} label="Save" />*/}
                                                <ReactButton id="OrganizationalLevelSaveButton" type="submit" style={{ width: '100%', marginTop: '10px' }}
                                                    variant="success" ><FormattedMessage id="GeneralButtonSaveAll" defaultMessage="Save All" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Row>
                                    }
                                </div>



                                <div hidden={true}>
                                    {
                                        <Row>
                                            <Col sm="3">
                                            </Col>
                                            <Col sm="2">
                                                {/*<Button style={{ width: '100%' }} id='cancel-orgLevel' onClick={this.changeButtonCancel} className="p-button-danger" label="Cancel" />*/}
                                                <ReactButton id="OrganizationalLevelCancelButton" style={{ width: '100%' }} variant="secondary" onClick={this.cancel}>
                                                    <FormattedMessage id="GeneralButtonCancel" defaultMessage="Cancel" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                                {/*<Button style={{ width: '100%' }} id='update-orgLevel' onClick={this.updateDone} className="p-button-warning" label="Update" />*/}
                                                <ReactButton id="OrganizationalLevelUpdateButton" style={{ width: '100%' }} variant="warning" type="submit">
                                                    <FormattedMessage id="GeneralButtonUpdate" defaultMessage="Update" /></ReactButton>
                                            </Col>
                                            <Col sm="3">
                                            </Col>
                                        </Row>
                                    }
                                </div>
                                <Modal
                                    show={this.state.modalMessage}
                                    onHide={this.showOrHideModalMessage}
                                    backdrop="static"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered>

                                    <Modal.Header closeButton>
                                        <Modal.Title id="contained-modal-title-vcenter">
                                            <Row >
                                                <Col sm="12">
                                                    {this.state.messageTip == "Success" &&
                                                        <i className="fas fa-check fa-2x" style={{ color: '#00ff2a' }} />}

                                                    {this.state.messageTip == "Warning" &&
                                                        <i className="fas fa-exclamation-circle fa-2x" style={{ color: '#faca2a' }} />}

                                                    {this.state.messageTip == "Error" &&
                                                        <i className="fas fa-times fa-2x" style={{ color: '#ff0400' }} />}
                                                </Col>
                                            </Row>
                                        </Modal.Title>
                                    </Modal.Header>

                                    <Modal.Body>
                                        <Row >
                                            <Col sm="12">
                                                {this.state.message}
                                            </Col>
                                        </Row>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <ReactButton variant="secondary" onClick={this.showOrHideModalMessage}><FormattedMessage id="GeneralTextClose" defaultMessage="Close" /></ReactButton>
                                    </Modal.Footer>
                                </Modal>
                            </FormAnt>
                        </div>

                        {/* {this.state.businessTripPolicyList.length > 0 && <Table style={{ marginTop: '3rem' }} size={`small`} dataSource={this.state.businessTripPolicyList} bordered scroll={{ x: 800, scrollToFirstRowOnChange: true }} >


                            <Column title="S/N" dataIndex="index" />
                            <Column title="BusinessTripPolicy" dataIndex="BusinessTripPolicy" />
                            <Column title="Abbreviation" dataIndex="abbreviation" />

                            <Column title="Action" render={(value, row, index) => {

                                return this.actionTemplate(row);

                            }} />
                        </Table>} */}
                    </Card>

               </MenuProvider>
                {!this.state.whatIsSetting && <Whatis  ></Whatis>


                }
            </div>
        
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        i18n: state.i18n
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessTripPolicy)